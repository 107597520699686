import { Component } from '@angular/core';
import System from 'framework/System';
import ComponentFactory from 'app/ticket/factories/ComponentFactory';
import timer from 'app/managecustomer/Timer';
import permissions from 'app/models/Permissions';
import ITicketComponentActivateParams = app.ticket.components.interfaces.ITicketComponentActivateParams;
import ITicketComponentViewModel = app.ticket.components.interfaces.ITicketComponentViewModel;
import ISaveData = app.ticket.interfaces.ISaveData;
import TicketStatusEnum = Boo.Objects.Enums.TicketStatusEnum;
import TicketTypeEnum = Boo.Objects.Enums.TicketTypeEnum;
import IValidatedResult = app.interfaces.IValidatedResult;
import FloatingPanel = app.components.interfaces.FloatingPanel;
import IFloatingTabPanelPublicApi = app.components.interfaces.IFloatingTabPanelPublicApi;
import KnockoutExtensions from 'app/KnockoutExtensions';
import { ViewLocatorService } from '../../services/view-locator.service';
import { SaveTypes } from 'app/models/enums/SaveTypes';
import { CustomerService } from '../../services/customer.service';
import { TicketService } from '../../services/ticket.service';
import { CustomerNoteService } from '../../services/customer-note.service';
import { SessionStorageService } from '../../services/session-storage.service';
import Utils from '../../shared/utils';

@Component({
    selector: 'app-components-ticket',
    templateUrl: './Ticket.component.html'
})
export class TicketComponent implements app.ticket.interfaces.ITicketViewModel {
    protected static ticketStatusNames: Record<number, string> = {
        1: 'New',
        2: 'InProgress',
        3: 'Completed'
    };

    constructor(
        private viewLocatorService: ViewLocatorService,
        private customerService: CustomerService,
        private ticketService: TicketService,
        private customerNoteService: CustomerNoteService,
        private sessionStorageService: SessionStorageService) { }

    public title = 'Ticket Details'; // title for priority view
    public ticket: TicketObservable;
    public customer: CustomerObservable;
    public canEdit = false;
    public canCancel = false;
    public canComplete = false;
    public canUpdateAndKeepOpen = true;
    public loadingCount: KnockoutObservable<number>;
    public isLoading: KnockoutComputed<boolean>;
    public components: app.ticket.components.interfaces.ITicketComponentViewModel[];
    public componentControlOptions: ICompositionRoute[];
    public floatingTabPanelApi: KnockoutObservable<IFloatingTabPanelPublicApi> = ko.observable();
    public SaveTypes = SaveTypes;

    protected updateValidation: KnockoutObservable<any>;
    protected completeValidation: KnockoutObservable<any>;
    protected priorityViewApi: app.interfaces.IPriorityViewPublicApi;
    protected modifiedDataFlags: app.ticket.interfaces.IModifiedDataFlags = { customer: false };

    private floatingTabPanels: FloatingPanel[] = [];
    private restrictUpdateAndKeepOpen: TicketTypeEnum[] = [];

    public activate(params: app.ticket.interfaces.ITicketActivateParams): JQueryPromise<void> {
        const ticketId: number = params.ticketId;
        if (!ticketId || ticketId <= 0) {
            throw new Error('Invalid ticket id');
        }

        if (params.customer && (ko.isObservable(params.customer) || !ko.isObservable(params.customer.CustomerId))) {
            throw new Error('Optional ticket customer must be a CustomerObservable.');
        }

        // if the customer is passed in, clone it so any changes this vm or components make do not affect the parent control.
        // we should instead close the priority view with IPriorityViewResult.modifiedDataFlags.customer=true
        // so that the parent control can reload its customer.
        this.customer = params.customer ? this.cloneCustomer(params.customer) : null;

        this.priorityViewApi = params.priorityViewApi || null;
        this.loadingCount = ko.observable(0);
        this.isLoading = ko.computed(() => {
            return this.loadingCount() > 0;
        });
        this.floatingTabPanelApi.subscribe(() => {
            this.floatingTabPanels.forEach((floatingTabPanel) => {
                this.floatingTabPanelApi().addPanel(floatingTabPanel);
            });
        });

        this.canCancel = this.priorityViewApi ? true : false;

        this.loadingCount(this.loadingCount() + 1);

        // set up allActivatedPromise so we know when this vm and all components are activated.
        // any methods we expose (triggerTicketSave, triggerTicketCancel) need to wait for allActivatedPromise,
        // or else we can get errors from trying to do stuff when only some components are in the right state.
        const thisActivatedDfd = $.Deferred<void>();
        const componentsActivatedDfd = $.Deferred<void>();
        const allActivatedPromise = $.when(thisActivatedDfd.promise(), componentsActivatedDfd.promise());

        return this.loadTicket(ticketId)
            .then(() => {
                return $.when<any>(
                  Utils.wrapDfd(this.sessionStorageService.getUser()),
                  Utils.wrapDfd(this.sessionStorageService.getPartner()),
                  Utils.wrapDfd(this.sessionStorageService.getPartnerUser(launchpad.config.keys.customerServiceUserLevelId)),
                  Utils.wrapDfd(this.sessionStorageService.getPartnerUsers()),
                    this.customer ? null : this.loadCustomer(this.ticket.CustomerId()) // only load customer if not passed in params
                );
            })
            .then((user: Boo.Objects.User, partner: Boo.Objects.Partner, partnerUser: Boo.Objects.PartnerUser, partnerUsers: Boo.Objects.PartnerUser[]) => {
                this.viewLocatorService.setDisplayedRoute('customerservice/editticket/' + this.ticket.TicketId());
                this.canEdit = this.ticket.StatusId() !== launchpad.config.TicketStatusEnum.Completed;
                this.canComplete = launchpad.hasPermission(partner, partnerUsers, permissions.CanCompleteTickets, user);

                // finally, load all components
                // this.loadComponents() promise resolves when all the component models have been resolved,
                // while componentsActivatedDfd resolves when the components are fully activated.
                return this.loadComponents(
                    ({
                        ticket: this.ticket,
                        customer: this.customer,
                        user: user,
                        partner: partner,
                        partnerUser: partnerUser,
                        partnerUsers: partnerUsers,
                        canEdit: this.canEdit,
                        canComplete: this.canComplete,
                        canCancel: this.canCancel,
                        canUpdateAndKeepOpen: this.canUpdateAndKeepOpen,
                        featureConfig: {},
                        isLoading: this.isLoading,
                        floatingTabPanelApi: this.floatingTabPanelApi,
                        triggerTicketSave: (saveType: SaveTypes, closeTicket: boolean): JQueryPromise<void> => {
                            // only allow save after everything is activated, else we may save garbage.
                            return allActivatedPromise.then(() => this.save(saveType, closeTicket));
                        },
                        triggerTicketCancel: (): void => {
                            // only allow cancel after everything is activated, else BaseComponentViewModel.canCancel may be incorrect.
                            allActivatedPromise.then(() => this.cancel());
                        }
                    } as ITicketComponentActivateParams),
                    componentsActivatedDfd
                );
            })
            .then(() => {
                thisActivatedDfd.resolve();
            })
            .always(() => {
                this.loadingCount(this.loadingCount() - 1);
            })
            .fail((error: string) => {
                thisActivatedDfd.reject();
                toastr.error(error);
                this.closePriorityView(false, error);
            });
    }

    public floatingTabPanelApiCallback(api: IFloatingTabPanelPublicApi): void {
        this.floatingTabPanelApi(api);
    }

    /**
     * Saves the ticket and related objects by doing the following:
     * 1. Validates all components through component.validate().
     * 2. Confirms save on all components through component.confirmSave().
     * 3. Saves all component data through component.save() and collects ISaveData result for each.
     * 4. Merges component ISaveData[] with our vm data (from this.ticket, this.customer)
     * 5. Saves the ticket and any related objects (customer) as needed.
     * 6. Closes the priority view, which will return IPriorityViewResult to parent control.
     *    IPriorityViewResult.modifiedDataFlags tells parent which objects (like customer) were changed.
     *
     * @param {boolean} complete
     * @returns
     */
    public save(saveType: SaveTypes, closeTicket: boolean): JQueryPromise<void> {
        if (this.isLoading()) {
            return;
        }

        this.loadingCount(this.loadingCount() + 1);

        // double check user is allowed to complete, just in case the UI allows them to.
        if (saveType === SaveTypes.Complete && !this.canComplete) {
            this.loadingCount(this.loadingCount() - 1);
            throw new Error('You cannot complete tickets.');
        }

        // figure out if we can save by calling validate() and then confirmSave()
        return this.validate(saveType)
            .then((isValid: boolean) => {
                if (!isValid) {
                    return System.autoRejectedPromise('Ticket cannot be saved.');
                }
                return this.confirmSave(saveType);
            })
            .then((canSave: boolean) => {
                if (!canSave) {
                    // cannot save, so abort silently because validation or confirmation will show errors.
                    return System.autoRejectedPromise('Ticket cannot be saved.');
                }

                // call save on components
                const componentPromises: JQueryPromise<any>[] = _.map(this.components, (c: ITicketComponentViewModel) => c.save(saveType))
                    .concat(this.floatingTabPanelApi().savePanels(saveType));

                return $.when<ISaveData>(...componentPromises)
                    .then((...componentSaveData: ISaveData[]) => {
                        const componentSaveDataByObject = this.groupTicketSaveDataByObject(componentSaveData, closeTicket);
                        const savePromises: JQueryPromise<any>[] = [];

                        // we use this.ticket and extend it with any ISaveData from the components
                        // we do not make any changes to this.ticket because that should remain unchanged on failure.
                        let ticketData: Boo.Objects.Ticket = $.extend.apply(null, [ko.toJS(this.ticket), ...componentSaveDataByObject.ticket]);

                        // set status, and add to completed seconds
                        const ticketSaveData = {
                            StatusId: saveType === SaveTypes.Complete ? TicketStatusEnum.Completed : closeTicket ? TicketStatusEnum.New : TicketStatusEnum.InProgress,
                            StatusName: TicketComponent.ticketStatusNames[ticketData.StatusId],
                            StatusDate: new Date(),
                            CompletedSeconds: ticketData.CompletedSeconds += timer.getElapsedSecondsSinceLastTicketSaveTime()
                        };
                        ticketData = $.extend(ticketData, ticketSaveData);

                        // always save ticket
                        savePromises.push(Utils.wrapDfd(this.ticketService.save(ticketData)));

                        // save customer if needed, getting the current customer first in case changes have been made outside of the ticket
                        if (componentSaveDataByObject.customer.length > 0) {
                            savePromises.push(Utils.wrapDfd(this.customerService.get(this.customer.CustomerId()))
                                .then((currentCustomer: Boo.Objects.Customer) => {
                                    const updatedCustomer = $.extend.apply(null, [ko.toJS(currentCustomer), ...componentSaveDataByObject.customer]);
                                    return Utils.wrapDfd(this.customerService.save(updatedCustomer));
                                }));
                            this.modifiedDataFlags.customer = true;
                        }

                        const ticketDataToUpdate = $.extend.apply(null, [{}, ...componentSaveDataByObject.ticket, ticketSaveData]);

                        return $.when<any>(...[ticketDataToUpdate, ...savePromises]);
                    })
                    .then((ticketData: Boo.Objects.Ticket) => {
                        // finally, on success, reset ticket timer
                        timer.resetTicketSaveTime();
                        if (closeTicket) {
                            this.closePriorityView(true);
                        } else {
                            if (saveType !== SaveTypes.UpdateWithoutTicketNoteValidation) {
                                toastr.success('Ticket note successfully saved');
                            }
                            return this.refreshTicketData(ticketData);
                        }
                    })
                    .then(() => true);
            })
            .always(() => {
                this.loadingCount(this.loadingCount() - 1);
            })
            .fail((error: any) => {
                toastr.error(typeof error === 'string' && $.trim(error).length > 0 ? error : 'Ticket could not be saved.');
            });
    }

    public refreshTicketData(ticket: Boo.Objects.Ticket): JQueryPromise<void> {
        _.each<any>(ticket, (value, prop) => {
            const propObservable: any = (this.ticket as any)[prop];
            if (propObservable && ko.isObservable(propObservable)) {
                propObservable(value);
            }
        });
        return Utils.wrapDfd(this.customerNoteService.getTicketNotes(this.ticket.TicketId()))
            .then((notes: Boo.Objects.CustomerNote[]) => {
                const updatedNoteList = ko.mapping.fromJS(notes);
                this.ticket.TicketNotes(updatedNoteList());
            })
            .fail((displayMessage: string) => {
                toastr.error('Could not retrieve ticket notes');
            });
    }

    public cancel(): void {
        if (this.isLoading()) {
            return;
        }

        if (!this.canCancel) {
            throw new Error('Cancel is an invalid operation.');
        }

        // call cancel on components
        const componentPromises: JQueryPromise<void>[] = _.map(this.components, (c: ITicketComponentViewModel) => c.canCancel ? c.cancel() : System.emptyPromise())
            .concat(this.floatingTabPanelApi().cancelPanels());

        $.when(...componentPromises)
            .then(() => {
                // note: there is no need to deactivate since the Activator will do it after ko.removeNode is called.
                this.closePriorityView(false);
            });
    }

    protected loadComponents(params: ITicketComponentActivateParams, loadedDfd: JQueryDeferred<void>): JQueryPromise<void> {
        const ticketType = ko.toJS((this.ticket.TicketType as any));

        return ComponentFactory.create(ticketType, params).then((componentRoutes: ICompositionRoute[]) => {
            this.components = [];
            this.componentControlOptions = [];
            const activationPromises: JQueryPromise<void>[] = [];

            _.each(componentRoutes, (route) => {
                $.Deferred<void>((dfd) => {
                    route.callback = component => { this.components.push(component); dfd.resolve(); };
                    this.componentControlOptions.push(route);
                    activationPromises.push(dfd.promise());
                });
            });

            // increment loadingCount until components are all activated.
            // we can't wait on all the components to be activated because if we hold up the parent activation,
            // the composition process will never start. we need to wait for the route composition callbacks instead.
            this.loadingCount(this.loadingCount() + 1);
            $.when(...activationPromises)
                .then(() => {
                    this.loadingCount(this.loadingCount() - 1);
                    loadedDfd.resolve();
                })
                .fail(() => {
                    loadedDfd.reject();
                });
        });
    }

    protected loadTicket(id: number): JQueryPromise<void> {
        if (this.ticket) {
            return System.emptyPromise();
        }

        return Utils.wrapDfd(this.ticketService.get(id))
            .then((ticketData: Boo.Objects.Ticket) => {
                this.ticket = ko.mapping.fromJS(ticketData);
                this.canUpdateAndKeepOpen = !(this.restrictUpdateAndKeepOpen.indexOf(this.ticket.TicketTypeId()) > -1);

                if (this.ticket.StatusId() === TicketStatusEnum.NeedsData) {
                    toastr.error('This ticket requires that a fulfillment task be completed before the ticket can be actioned. Please close the ticket and check back later.');
                }
            });
    }

    /**
     * Clones the CustomerObservable using a ko.mapping that matches managecustomer.js
     * @param {CustomerObservable} customer
     * @returns {CustomerObservable}
     */
    protected cloneCustomer(customer: CustomerObservable): CustomerObservable {
        const mapping: any = {
            'Csr': {
                create: (options: KnockoutMappingCreateOptions): any => {
                    return KnockoutExtensions.createNullableChildObject(options);
                }
            },
            'Team': {
                create: (options: KnockoutMappingCreateOptions): any => {
                    return KnockoutExtensions.createNullableChildObject(options);
                }
            }
        };
        return ko.clone(customer, mapping);
    }

    protected loadCustomer(customerId: number): JQueryPromise<void> {
        return Utils.wrapDfd(this.customerService.get(customerId))
            .then((customerData: Boo.Objects.Customer) => {
                this.customer = ko.mapping.fromJS(customerData);
            });
    }

    protected validate(saveType: SaveTypes): JQueryPromise<boolean> {
        const componentPromises: JQueryPromise<IValidatedResult>[] = _.map(this.components, (c: ITicketComponentViewModel) => c.validate(saveType))
            .concat(this.floatingTabPanelApi().validatePanels(saveType));

        return $.when(...componentPromises)
            .then((...results: IValidatedResult[]) => {
                let isValid = true;
                let errorMessages: string[] = [];
                _.each(results, (validationResult: IValidatedResult) => {
                    isValid = isValid ? validationResult.isValid : isValid;
                    if (!validationResult.isValid) {
                        errorMessages = errorMessages.concat(validationResult.errorMessages);
                    }
                });

                if (!isValid) {
                    const hasErrorMessages = errorMessages.length > 0;
                    let errorMessageDisplay = '';
                    if (hasErrorMessages) {
                        for (const errorMessage of errorMessages) {
                            errorMessageDisplay = errorMessageDisplay + '<p>' + errorMessage + '</p>';
                        }
                    }

                    const message = (hasErrorMessages) ? errorMessageDisplay : launchpad.config.ErrorMessages.ValidationFailed;
                    toastr.error(message);
                }

                return isValid;
            });
    }

    protected confirmSave(saveType: SaveTypes): JQueryPromise<boolean> {
        const componentPromises: JQueryPromise<boolean>[] = _.map(this.components, (cm: ITicketComponentViewModel) => cm.confirmSave(saveType))
            .concat(this.floatingTabPanelApi().confirmSavePanels(saveType));

        return $.when(...componentPromises)
            .then((...results: boolean[]) => {
                const isConfirmed: boolean = _.every(results, (r: boolean) => { return r === true; });
                if (!isConfirmed) {
                    return false;
                }
                return saveType !== SaveTypes.Complete || this.confirmComplete();
            })
            .then((isEverythingConfirmed: boolean) => {
                if (!isEverythingConfirmed) {
                    toastr.error('Ticket was not saved.');
                }
                return isEverythingConfirmed;
            }).fail(() => {
                return false;
            });
    }

    /**
     * Confirms that the ticket can be completed (called from confirmSave).
     * This is a collection of random checks that don't belong anywhere else (like in components).
     * If there are new checks, it would be better to add them to a component when possible.
     */
    protected confirmComplete(): JQueryPromise<boolean> {
        const result = true;

        return System.resolvedPromise(result);
    }

    protected confirm(msg: string): JQueryPromise<boolean> {
        return $.Deferred<boolean>((dfd: JQueryDeferred<boolean>) => {
            bootbox.confirm(msg, (result: boolean) => {
                dfd.resolve(result === true);
            });
        }).promise();
    }

    /**
     * Resolves or rejects the priority view (if there is one) with the IPriorityViewResult
     * @param {boolean} resolve If true, will resolve the priority view. If false, will reject it.
     * @param {string} error?
     */
    protected closePriorityView(resolve: boolean, error?: string): void {
        if (!this.priorityViewApi) {
            return;
        }

        const result = {
            error: error || null,
            modifiedDataFlags: this.getModifiedDataFlags()
        } as app.ticket.interfaces.IPriorityViewResult;

        if (resolve) {
            this.priorityViewApi.resolve(result);
        } else {
            this.priorityViewApi.reject(result);
        }
    }

    /**
     * Sorts and groups the saveData into arrays keyed by the affected object name (ticket, customer)
     * @param {ISaveData[]} saveData
     */
    protected groupTicketSaveDataByObject(saveData: ISaveData[], closeTicket: boolean): { ticket: any[], customer: any[] } {
        // sort data by priority so that higher priority data overwrites lower priority data
        const saveDataSorted = (saveData || []).sort((a, b) => { return (a.priority || 0) - (b.priority || 0); });

        // break component save data into arrays for each object so we can save separately
        const saveDataByObject: { ticket: any[], customer: any[] } = {
            ticket: [],
            customer: []
        };

        _.each(saveDataSorted, (data) => {
            if (!data || !!data.onlyOnClose && (!closeTicket && data.onlyOnClose)) {  // Only Unlock and Unassign ticket on Close
                return;
            }
            if (!_.isEmpty(data.ticket)) {
                saveDataByObject.ticket.push(data.ticket);
            }
            if (!_.isEmpty(data.customer)) {
                saveDataByObject.customer.push(data.customer);
            }
        });

        return saveDataByObject;
    }

    /**
     * Gets flags for modified objects that the parent control may want to reload.
     * For example, a component may save the customer, and so the parent control may
     * want to reload the customer when the ticket is saved or canceled.
     */
    protected getModifiedDataFlags(): app.ticket.interfaces.IModifiedDataFlags {
        const allFlags = [
            this.modifiedDataFlags,
            ..._.map(this.components, (c) => { return c.getModifiedDataFlags(); })
        ];
        return {
            customer: _.any(allFlags, (flags) => { return flags.customer; })
        };
    }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import permissions from 'app/models/Permissions';
import { PartnerUserService } from '../../../services/partner-user.service';
import { SessionStorageService } from '../../../services/session-storage.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-components-navigation-customerservice',
  templateUrl: './CustomerService.component.html'
})
export class CustomerServiceComponent implements OnInit, OnDestroy {
  isLoading = false;
  isBillingToolsVisible = false;
  isManagerToolsVisible = false;
  isManageTicketsVisible = false;
  isHelpNextCustomerVisible = false;
  showManagerToolsSubMenu = false;
  showBillingToolsSubMenu = false;
  showManageTicketsSubMenu = false;
  isCsrsVisible = false;
  canAssignTicketTypes = false;
  isCustomerAssignmentsVisible = false;
  isResetCustomerVisible = false;

  private urlChangedHandler: (newHash: string) => void = (newHash: string) => {
    this.urlUpdated(newHash);
  };

  toolsPages: string[] = [];
  managerToolsPages: string[] = [
    'customerservice/managertools',
    'customerservice/csrs',
    'customerservice/customerpriorityassignments'
  ];
  manageTicketsPages: string[] = [
    'customerservice/managetickets',
    'customerservice/assigntickettypes',
    'customerservice/createticketbatch'
  ];

  constructor(
    private partnerUserService: PartnerUserService,
    private sessionStorageService: SessionStorageService) { }

  ngOnInit(): void {
    amplify.subscribe(launchpad.config.events.urlChanged, this.urlChangedHandler);

    forkJoin([
      this.sessionStorageService.getPartner(),
      this.sessionStorageService.getPartnerUser(window.launchpad.config.keys.managerUserLevelId),
      this.sessionStorageService.getPartnerUsers(),
      this.sessionStorageService.getUser(),
      this.sessionStorageService.getStaticData()
    ])
      .subscribe(([partner, partnerUser, allPartnerUsers, user, staticData]) => {
        const hasPermissionToMassAssign = window.launchpad.hasPermission(partner, allPartnerUsers, permissions.CanMassAssignTicketTypes, user);
        const hasAllTicketTypesPermission = window.launchpad.hasPermission(partner, allPartnerUsers, permissions.HasAllTicketTypes, user);
        this.canAssignTicketTypes = hasPermissionToMassAssign;
        this.isHelpNextCustomerVisible = false; // Set the default value
        if (partner.PartnerId === window.launchpad.config.partners.boostability) {
          this.isBillingToolsVisible = true;
          this.isManagerToolsVisible = true;
          this.isResetCustomerVisible = true;
          this.isHelpNextCustomerVisible = true;
          if (_.isObject(partnerUser)) {
            this.isManageTicketsVisible = true;
            this.isCsrsVisible = true;
          }
          if (_.any(allPartnerUsers,
            (pu: any) => {
              return pu.UserLevelId === window.launchpad.config.keys.managerUserLevelId || pu.UserLevelId === window.launchpad.config.keys.adminUserLevelId && !pu.IsReadonly;
            })) {
            this.isCustomerAssignmentsVisible = true;
          }
        } else {
          this.isBillingToolsVisible = false;
          this.isManagerToolsVisible = false;
          this.isManageTicketsVisible = false;
          this.isHelpNextCustomerVisible = false;
          if (window.launchpad.utils.partnerInPartnerGroup(partner.PartnerId, window.launchpad.config.PartnerGroupEnum.Yellow, staticData.PartnerGroups)) {
            if (_.any(allPartnerUsers,
              (pu: any) => {
                return pu.UserLevelId === window.launchpad.config.keys.customerServiceUserLevelId && !pu.IsReadonly;
              })) {
              this.isManagerToolsVisible = true;
              this.isResetCustomerVisible = true;
            }
            if (partnerUser !== null) {
              this.isManagerToolsVisible = true;
              this.isManageTicketsVisible = true;
              this.isCsrsVisible = true;
            }
          }
        }
      });
  }

  ngOnDestroy(): void {
    amplify.unsubscribe(launchpad.config.events.urlChanged, this.urlChangedHandler);
  }

  private urlUpdated(newHash: any): void {
    if (this.toolsPages.indexOf(newHash) > -1) {
      this.showManagerToolsSubMenu = false;
      this.showBillingToolsSubMenu = false;
      this.showManageTicketsSubMenu = false;
    } else if (this.managerToolsPages.indexOf(newHash) > -1) {
      this.showManagerToolsSubMenu = true;
      this.showBillingToolsSubMenu = false;
      this.showManageTicketsSubMenu = false;
    } else if (this.manageTicketsPages.indexOf(newHash) > -1) {
      this.showManagerToolsSubMenu = false;
      this.showBillingToolsSubMenu = false;
      this.showManageTicketsSubMenu = true;
    } else {
      this.showManagerToolsSubMenu = false;
      this.showBillingToolsSubMenu = false;
      this.showManageTicketsSubMenu = false;
    }
  }
}

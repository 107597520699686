import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PreAuditDetailService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/preauditdetails`;

  constructor(private http: HttpClient) { }

  getByCustomerId(customerId: number): Observable<Boo.Objects.PreAuditDetail> {
    const params = new HttpParams().set('customerId', customerId);

    return this.http.get<Boo.Objects.PreAuditDetail>(`${this.baseUrl}/GetByCustomerId`, { params: params });
  }

  updateContentManagementSystem(customerId: number, contentManagementSystemId: number): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/UpdateContentManagementSystem`, {
      customerId: customerId,
      contentManagementSystemId: contentManagementSystemId
    });
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { ActionComponentsModule } from './actioncomponents/actioncomponents.module';
import { SharedComponentsModule } from '../shared/shared-components.module';
import { ActionWorkspaceComponent } from './ActionWorkspace/ActionWorkspace.component';
import { AmazonDeploymentStatusHistoryComponent } from './AmazonDeploymentStatusHistory/AmazonDeploymentStatusHistory.component';
import { AssignedTasksComponent } from './AssignedTasks/AssignedTasks.component';
import { BlogDiagnosticsComponent } from './BlogDiagnostics/BlogDiagnostics.component';
import { BlogMetricsComponent } from './BlogMetrics/BlogMetrics.component';
import { BlogSiteDetailsComponent } from './BlogSiteDetails/BlogSiteDetails.component';
import { BlogSitesComponent } from './BlogSites/BlogSites.component';
import { ContentFeedbackComponent } from './ContentFeedback/ContentFeedback.component';
import { EditBlogComponent } from './EditBlog/EditBlog.component';
import { LockedInternalTasksComponent } from './LockedInternalTasks/LockedInternalTasks.component';
import { SkipCustomerComponent } from './SkipCustomer/SkipCustomer.component';
import { SpecialistWorkspaceComponent } from './SpecialistWorkspace/SpecialistWorkspace.component';
import { WorkblockTaskComponent } from './WorkblockTask/workblock-task.component';
import { ViewTaskWorkSpaceComponent } from './ViewTaskWorkSpace/ViewTaskWorkSpace.component';
import { ViewTaskFeedbackComponent } from './ViewTaskFeedback/ViewTaskFeedback.component';
import { StandardBacklinkFeedbackComponent } from './ViewTaskFeedback/feedbackcomponents/standard-backlink-feedback/standard-backlink-feedback.component';
import { OnsiteRecommendationsFeedbackComponent } from './ViewTaskFeedback/feedbackcomponents/onsite-recommendations-feedback/onsite-recommendations-feedback.component';
import { LockedTasksComponent } from './LockedTasks/LockedTasks.component';
import { ChangeSpecialistScoreComponent } from './change-specialist-score/change-specialist-score.component';
import { RejectTaskComponent } from './reject-task/reject-task.component';
import { QaModule } from '../qa/qa.module';
import { ManageCustomerModule } from '../managecustomer/manage-customer.module';

@NgModule({
  declarations: [
    ActionWorkspaceComponent,
    AmazonDeploymentStatusHistoryComponent,
    AssignedTasksComponent,
    BlogDiagnosticsComponent,
    BlogMetricsComponent,
    BlogSiteDetailsComponent,
    BlogSitesComponent,
    ContentFeedbackComponent,
    EditBlogComponent,
    LockedInternalTasksComponent,
    SkipCustomerComponent,
    SpecialistWorkspaceComponent,
    WorkblockTaskComponent,
    ViewTaskWorkSpaceComponent,
    StandardBacklinkFeedbackComponent,
    OnsiteRecommendationsFeedbackComponent,
    ViewTaskFeedbackComponent,
    LockedTasksComponent,
    ChangeSpecialistScoreComponent,
    RejectTaskComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ActionComponentsModule,
    SharedComponentsModule,
    QaModule,
    ManageCustomerModule
  ]
})
export class SpecialistModule { }

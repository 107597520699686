import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { finalize, forkJoin, Observable, of, switchMap } from 'rxjs';
import { CustomerCampaignService } from '../../../../services/customer-campaign.service';
import { KeywordResearchService } from '../../../../services/keyword-research.service';
import { KeywordConfigService } from '../../../../services/keyword-config.service';
import { HaloKeywordSuggestion, KeywordConfig, KeywordResearchTypes, KeywordSuggestionSources, PageSuggestion, PageSuggestionSources, SaveActions } from '../../../../shared/models/keywords/keywords';
import { TabPanelDisplayMode } from 'app/components/enums/TabPanelDisplayMode';

@Component({
  selector: 'app-components-shared-keyword-research',
  templateUrl: './keyword-research.component.html',
  styleUrls: ['./keyword-research.component.scss']
})
export class KeywordResearchComponent implements OnInit {
  @Input() keywordResearchType: KeywordResearchTypes;
  @Input() customer: Boo.Objects.Customer;
  @Output() keywordReseachRequested = new EventEmitter<void>();
  @Output() newPagesRequested = new EventEmitter<void>();

  tabPanelDisplayMode = TabPanelDisplayMode;
  pageSuggestions: PageSuggestion[];
  haloKeywordSuggestions: HaloKeywordSuggestion[];
  keywordConfigs: KeywordConfig[];
  keywordConfig: KeywordConfig;
  originalIsCampaignNational?: boolean;
  loading = false;

  constructor(
    private customerCampaignService: CustomerCampaignService,
    private keywordResearchService: KeywordResearchService,
    private keywordConfigService: KeywordConfigService) { }

  ngOnInit(): void {
    this.loading = true;
    this.load()
      .pipe(finalize(() => this.loading = false))
      .subscribe({ error: (e) => toastr.error(e) });
  }

  load(): Observable<void> {
    this.originalIsCampaignNational = this.customer.IsCampaignNational;

    if (this.customer.IsCampaignNational == null) {
      this.customer.IsCampaignNational = false; // Default to local if not set
    }

    return forkJoin([
      this.keywordResearchService.getHaloKeywordSuggestions(this.customer.CustomerId),
      this.keywordResearchService.getPageSuggestions(this.customer.CustomerId),
      this.keywordConfigService.getAll(this.customer.CustomerId)
    ]).pipe(
      switchMap(([haloKeywordSuggestions, pageSuggestions, keywordConfigs]) => {
        this.haloKeywordSuggestions = haloKeywordSuggestions;
        this.pageSuggestions = pageSuggestions;
        this.keywordConfigs = keywordConfigs;
        this.getKeywordConfig();
        return of(null);
      })
    );
  }

  getKeywordConfig(): void {
    this.keywordConfig = this.keywordConfigs.find(x => x.IsCampaignNational === this.customer.IsCampaignNational);
  }

  isValid(): boolean {
    return this.validationMessage === '';
  }

  save(saveActions?: SaveActions[]): Observable<void> {
    return this.keywordResearchService.save(
      this.customer.CustomerId,
      this.pageSuggestions.filter(x => x.IsModified && x.IsCampaignNational === this.customer.IsCampaignNational),
      this.haloKeywordSuggestions.filter(x => x.IsModified && x.IsCampaignNational === this.customer.IsCampaignNational),
      saveActions ?? [],
      this.getRequestSource(),
      this.isCampaignTypeModified() ? this.customer.IsCampaignNational : null
    );
  }

  generateReport(): void {
    this.save([SaveActions.GenerateSeoActionPlanDraft])
      .pipe(switchMap(() => this.load()))
      .subscribe();
  }

  researchRequested(): void {
    this.pageSuggestions.filter(x => x.IsModified && x.IsCampaignNational === this.customer.IsCampaignNational && x.PendingKeywordResearch)
      .forEach(x => {
        x.KeywordResearch = [x.PendingKeywordResearch];
        x.KeywordSuggestions = x.KeywordSuggestions.filter(y => y.SourceId === KeywordSuggestionSources.CustomerCampaign); // Only keep CustomerCampaign keywords
      });
    this.save([SaveActions.ProcessKeywordResearch]).subscribe({ complete: () => this.keywordReseachRequested.emit() });
  }

  pagesRequested(): void {
    if (this.keywordResearchType !== KeywordResearchTypes.SeoOnboarding) {
      return;
    }

    this.keywordResearchService.requestNewPageSuggestions(this.customer.CustomerId).subscribe({ complete: () => this.newPagesRequested.emit() });
  }

  get validationMessage(): string {
    switch (this.keywordResearchType) {
      case KeywordResearchTypes.SeoOnboarding:
        return this.getOnboardingValidationMessage();
      case KeywordResearchTypes.StrategyUpdate:
        return this.getStrategyUpdateValidationMessage();
      default:
        return '';
    }
  }

  private getOnboardingValidationMessage(): string {
    if (this.pageSuggestions.some(x => x.IsSelected && x.IsCampaignNational === this.customer.IsCampaignNational && x.Url && !this.customerCampaignService.domainMatches(this.customer.Url, x.Url))) {
      return 'All page domains must match the customer domain.';
    }

    const requiredPages = this.keywordConfig?.Pages || 1;
    const requiredKeywordsPerPage = (this.keywordConfig?.MainKeywords || 1) / requiredPages;

    if (this.pageSuggestions.filter(x => x.IsSelected && x.IsCampaignNational === this.customer.IsCampaignNational).length < requiredPages) {
      return `At least ${requiredPages} pages must be selected.`;
    }

    if (this.pageSuggestions.some(x => x.IsSelected && x.IsCampaignNational === this.customer.IsCampaignNational && x.KeywordSuggestions.filter(y => y.IsSelected && x.IsCampaignNational === this.customer.IsCampaignNational).length < requiredKeywordsPerPage)) {
      return 'All pages must have at least ' + requiredKeywordsPerPage + ' keywords.';
    }

    const requiredHaloKeywords = this.keywordConfig?.HaloKeywords || 1;

    if (this.haloKeywordSuggestions.filter(x => x.IsSelected && x.IsCampaignNational === this.customer.IsCampaignNational).length < requiredHaloKeywords) {
      return `At least ${requiredHaloKeywords} halo keywords must be selected.`;
    }

    const keywordPhrases = this.pageSuggestions.filter(x => x.IsSelected && x.IsCampaignNational === this.customer.IsCampaignNational)
      .flatMap(x => x.KeywordSuggestions)
      .filter(x => x.IsSelected)
      .map(x => x.KeywordPhrase.toLowerCase().trim())
      .concat(this.haloKeywordSuggestions.filter(x => x.IsSelected && x.IsCampaignNational === this.customer.IsCampaignNational).map(x => x.KeywordPhrase.toLowerCase().trim()));

    if (new Set(keywordPhrases).size !== keywordPhrases.length) {
      return 'Keywords can not be duplicated.';
    }

    return '';
  }

  private getStrategyUpdateValidationMessage(): string {
    if (this.pageSuggestions.some(x => x.IsSelected && x.IsCampaignNational === this.customer.IsCampaignNational && x.Url && !this.customerCampaignService.domainMatches(this.customer.Url, x.Url))) {
      return 'All page domains must match the customer domain.';
    }

    const requiredPages = this.keywordConfig?.Pages || 1;
    const requiredKeywordsPerPage = (this.keywordConfig?.MainKeywords || 1) / requiredPages;

    if (this.pageSuggestions.some(x => x.IsSelected && x.IsCampaignNational === this.customer.IsCampaignNational && x.KeywordSuggestions.filter(y => y.IsSelected && x.IsCampaignNational === this.customer.IsCampaignNational).length < requiredKeywordsPerPage)) {
      return 'All pages must have at least ' + requiredKeywordsPerPage + ' keywords.';
    }

    const requiredHaloKeywords = this.keywordConfig?.HaloKeywords || 1;

    if (this.haloKeywordSuggestions.filter(x => x.IsSelected && x.IsCampaignNational === this.customer.IsCampaignNational).length < requiredHaloKeywords) {
      return `At least ${requiredHaloKeywords} halo keywords must be selected.`;
    }

    const keywordPhrases = this.pageSuggestions.filter(x => (x.SourceId === PageSuggestionSources.CustomerCampaign || x.IsSelected) && x.IsCampaignNational === this.customer.IsCampaignNational)
      .flatMap(x => x.KeywordSuggestions)
      .filter(x => x.IsSelected)
      .map(x => x.KeywordPhrase.toLowerCase().trim())
      .concat(this.haloKeywordSuggestions.filter(x => x.IsSelected && x.IsCampaignNational === this.customer.IsCampaignNational).map(x => x.KeywordPhrase.toLowerCase().trim()));

    if (new Set(keywordPhrases).size !== keywordPhrases.length) {
      return 'Keywords can not be duplicated.';
    }

    return '';
  }

  private isCampaignTypeModified(): boolean {
    return this.customer.IsCampaignNational !== this.originalIsCampaignNational;
  }

  private getRequestSource(): Boo.Objects.Enums.RequestSources {
    return this.keywordResearchType === KeywordResearchTypes.SeoOnboarding
      ? Boo.Objects.Enums.RequestSources.SeoOnboarding
      : Boo.Objects.Enums.RequestSources.StrategyUpdate;
  }
}
import { Component, Input } from '@angular/core';
import { WebsiteService } from '../../../services/website.service';
import Utils from '../../../shared/utils';

@Component({
    selector: 'app-boost-site-admin-sso-button',
    templateUrl: './BoostSiteAdminSSOButton.component.html'
})
export class BoostSiteAdminSSOButtonComponent {
    @Input() showSiteInfo = false;
    @Input() hideSsoUrl = false;
    public isLoading: KnockoutObservable<boolean> = ko.observable(false);
    public ssoUrlForAdmin: KnockoutObservable<string> = ko.observable(null);
    public website: app.tsmodels.interfaces.IWebsiteObservable;

    
    @Input() get websiteId(): number {
        return this._websiteId;
    }
    set websiteId(value: number) {
        this._websiteId = value;

        Utils.wrapDfd(this.websiteService.get(this.websiteId))
            .then(website => {
                this.website = ko.mapping.fromJS(website);
            });
    }

    private _websiteId: number;

    constructor(private websiteService: WebsiteService) { }

    public loadSsoUrlForAdmin(): JQueryPromise<string> {
        return this.loadSsoUrl((websiteId) => Utils.wrapDfd(this.websiteService.getSsoUrlForAdmin(websiteId)), this.ssoUrlForAdmin);
    }

    public signOnWithSsoUrlForAdmin(): void {
        $.when(this.ssoUrlForAdmin() || this.loadSsoUrlForAdmin())
            .then((url) => window.open(url));
    }

    private loadSsoUrl(fetchFunc: (websiteId: number) => JQueryPromise<string>, observable: KnockoutObservable<string>): JQueryPromise<string> {
        if (!this.website.WebsiteAccount.AccountId()) {
            toastr.warning(`The Website account has not yet been created with ${this.website.WebsiteAccount.HostName()}. Single sign-on link cannot be loaded.`);
            return $.Deferred<string>((dfd) => dfd.reject()).promise();
        }
        if (!this.website.SiteId()) {
            toastr.warning(`The Site Id for the website is not set. Single sign-on link cannot be loaded.`);
            return $.Deferred<string>((dfd) => dfd.reject()).promise();
        }

        return this.doAsync(() => {
            return fetchFunc(this.website.WebsiteId())
                .then((url) => {
                    observable(url);
                    return observable();
                });
        });
    }

    /**
     * Helper to do async request with standard loading indicator and error handling.
     */
    private doAsync(func: () => JQueryPromise<any>): JQueryPromise<any> {
        this.isLoading(true);
        return func().always(() => {
            this.isLoading(false);
        }).fail((msg) => toastr.error(msg));
    }
}

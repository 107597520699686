import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Notification } from '../shared/models/notifications/notifications';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/customers`;

  constructor(private http: HttpClient) { }

  create(user: Boo.Objects.User, customer: Boo.Objects.Customer, merchantFields: Boo.Objects.CustomerMerchantFields): Observable<Boo.Objects.Customer> {
    return this.http.post<any>(`${this.baseUrl}/Create`, {
        user: user,
        customer: customer,
        merchantFields: merchantFields
    });
  }

  get(customerId: number): Observable<Boo.Objects.Customer> {
    const params = new HttpParams().set('customerId', customerId ? customerId : null);

    return this.http.get<Boo.Objects.Customer>(`${this.baseUrl}/Get`, { params: params });
  }

  getCustomerOnly(customerId: number): Observable<Boo.Objects.Customer> {
    const params = new HttpParams().set('customerId', customerId ? customerId : null);

    return this.http.get<Boo.Objects.Customer>(`${this.baseUrl}/GetCustomerOnly`, { params: params });
  }

  resetUrl(customerId: number, newWebsite: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/ResetUrl`, {
        customerId: customerId,
        newWebsite: newWebsite
    });
  }

  save(customer: Boo.Objects.Customer): Observable<any> {
    return this.http.post(`${this.baseUrl}/Save`, customer);
  }

  changePartner(customerId: number, originatingPartnerId: number, newPartnerId: number): Observable<any> {
    return this.http.post(`${this.baseUrl}/ChangePartner`, {
        customerId: customerId,
        originatingPartnerId: originatingPartnerId,
        newPartnerId: newPartnerId
    });
  }

  getByReferredBy(partnerId: number, referredBy: string): Observable<Boo.Objects.Customer[]> {
    return this.http.post<Boo.Objects.Customer[]>(`${this.baseUrl}/GetByReferredBy`, {
        partnerId: partnerId,
        referredBy: referredBy
    });
  }

  reassignCsr(currentCsrUserId: number, newCsrUserId: number, teamId: number, minSpend: number, maxSpend: number): Observable<boolean> {
    return this.http.post<boolean>(`${this.baseUrl}/ReassignCsr`, {
        currentCsrUserId: currentCsrUserId,
        newCsrUserId: newCsrUserId,
        teamId: teamId,
        minSpend: minSpend,
        maxSpend: maxSpend
    });
  }

  searchByCsrCount(csrUserId: number, teamId: number, minSpend: number, maxSpend: number): Observable<number> {
    const params = new HttpParams()
      .set('csrUserId', csrUserId ? csrUserId : null)
      .set('teamId', teamId)
      .set('minSpend', minSpend)
      .set('maxSpend', maxSpend);

    return this.http.get<number>(`${this.baseUrl}/SearchByCsrCount`, { params: params });
  }

  resetSeoMinutes(customerId: number): Observable<any> {
    return this.http.post(`${this.baseUrl}/ResetSeoMinutes`, customerId);
  }

  getRecurringSeoSpend(customerId: number): Observable<number> {
    const params = new HttpParams().set('customerId', customerId);

    return this.http.get<number>(`${this.baseUrl}/GetRecurringSeoSpend`, { params: params });
  }

  saveBasicInfo(customer: Boo.Objects.Customer): Observable<any> {
    return this.http.post(`${this.baseUrl}/SaveBasicInfo`, customer);
  }

  saveConsiderations(customerId: number, considerations: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/SaveConsiderations`, {
        customerId: customerId,
        considerations: considerations
    });
  }

  saveCampaignType(customerId: number, IsCampaignNational: boolean): Observable<any> {
    return this.http.post(`${this.baseUrl}/SaveCampaignType`, {
        customerId: customerId,
        IsCampaignNational: IsCampaignNational
    });
  }
  
  search(searchString: string, filterByPartnerId: number, page: number, pageSize: number, languageId: number, liveAccountsOnly: boolean, isMyCustomersSearch: boolean, searchAllCustomerStatuses: boolean, searchActiveCustomers = true): Observable<Boo.Objects.CustomerSearchResult[]> {
    const params = new HttpParams()
      .set('searchString', searchString)
      .set('filterByPartnerId', filterByPartnerId, )
      .set('page', page)
      .set('pageSize', pageSize)
      .set('languageId', languageId)
      .set('liveAccountsOnly', liveAccountsOnly)
      .set('isMyCustomersSearch', isMyCustomersSearch)
      .set('searchAllCustomerStatuses', searchAllCustomerStatuses)
      .set('searchActiveCustomers', searchActiveCustomers);

    return this.http.get<Boo.Objects.CustomerSearchResult[]>(`${this.baseUrl}/Search`, { params: params });
  }

  hasHadProductRecently(customer: CustomerObservable, productId: number): boolean {
    return _.contains(customer.RecentProducts(), productId);
  }

  hasOnlyEverHadProduct(customer: CustomerObservable, productId: number): boolean {
    return customer.AllProducts().length === 1 && this.hasEverHadProduct(customer, [productId]); 
  }

  hasEverHadProduct(customer: CustomerObservable, productIds: number[]): boolean {
    return _.intersection(customer.AllProducts(), productIds).length > 0;
  }

  getCustomerCmsId(customerId: number): Observable<any> {
    const params = new HttpParams().set('customerId', customerId);
    return this.http.get<number>(`${this.baseUrl}/GetCustomerCms`, { params: params });
  }

  getCustomerNotifications(customerId: number): Observable<Notification[]> {
    return this.http.get<Notification[]>(`${this.baseUrl}/${customerId}/GetCustomerNotifications`);
  }
}

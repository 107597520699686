import { Component, Input, OnInit } from '@angular/core';
import QaCriteria = Boo.Qa.QaCriteria;
import QaCriteriaGroupEnum = Boo.Qa.QaCriteriaGroupEnum;
import QaCriteriaGroup = Boo.Qa.QaCriteriaGroup;
import QaCriteriaDetail = Boo.Qa.QaCriteriaDetail;
import QaCriteriaDefinition = Boo.Qa.QaCriteriaDefinition;
import QaStockComment = Boo.Qa.QaStockComment;
import QaDisplayConfig = app.specialist.qa.interfaces.QaDisplayConfig
import { QaCriteriaDefinitionProperty } from 'app/specialist/qa/enums/qa-criteria-definition-property';

@Component({ template: '' })
export class QaCriteriaControlComponent implements OnInit {
	@Input() displayConfig: QaDisplayConfig;

	public name: string;
	public description: string;
	public displayItem: QaCriteriaDefinitionProperty;
	public orderAscending: boolean;
	public orderBy: QaCriteriaDefinitionProperty;
	public required: boolean;
	public useSelect: boolean;
	public showComment: boolean;
	public showTitle: boolean;
	public showStockComments: boolean;
	public groups: QaCriteriaGroupEnum[];
	public labeledDefinitions: LabeledDefinition[];
	public selectedDefinition: LabeledDefinition;
	public selectedStockComments: QaStockComment[] = [];
	public comment = '';
	public validated = false;

	ngOnInit(): void {
		this.groups = this.displayConfig.groups.map(x => x.QaCriteriaGroupEnum);
		this.name = this.displayConfig.name ?? this.displayConfig.groups[0].Name;
		this.description = this.displayConfig.description ?? this.displayConfig.groups[0].Description;
		this.displayItem = this.displayConfig.displayItem ?? QaCriteriaDefinitionProperty.Label;
		this.orderAscending = this.displayConfig.orderAscending ?? false;
		this.orderBy = this.displayConfig.orderBy ?? QaCriteriaDefinitionProperty.Points;
		this.required = this.displayConfig.required ?? true;
		this.useSelect = this.displayConfig.useSelect ?? false;
		this.showComment = this.displayConfig.showComment ?? true;
		this.showTitle = this.displayConfig.showTitle ?? true;
		this.showStockComments = this.showComment ? this.displayConfig.groups.some(x => x.QaCriteriaDefinitions.some(y => y.QaStockComments.length > 0)) : false;
		this.labeledDefinitions = this.createLabels(this.displayConfig.groups)
	}

	setDefintion(newValue: LabeledDefinition) {
		this.selectedDefinition = newValue;
		this.definitionChange();
	}

	definitionChange() {
		this.selectedStockComments = this.selectedDefinition.definition.QaStockComments;
	}

	validate(): boolean {
		this.validated = true;
		return this.validateCriteria() && this.validateComment();
	}

	validateCriteria(): boolean {
		return !this.required || Boolean(this.selectedDefinition);
	}

	validateComment(): boolean {
		return !this.showComment || this.comment.length !== 0 || !this.selectedDefinition || this.selectedDefinition.definition.Points === 0;
	}

	createDetail(): QaCriteriaDetail {
		if (!this.selectedDefinition) {
			return null;
		}

		return { QaCriteriaDefinitionId: this.selectedDefinition.definition.QaCriteriaDefinitionId, Comment: this.comment };
	}

	loadCriteria(criteria: QaCriteria) {
		this.comment = criteria.Comment;
		this.selectedDefinition = this.labeledDefinitions.find(x => x.definition.QaCriteriaDefinitionId === criteria.QaCriteriaDefinitionId);
		this.definitionChange();
	}

	insertStockComment(stockComment: string) {
		this.comment = this.comment ? this.comment + '\r\n\r\n' + stockComment : stockComment;
	}

	private createLabels(groups: QaCriteriaGroup[]) {
		const definitionControls: LabeledDefinition[][] = groups.map(x => x.QaCriteriaDefinitions.map(y => {
			return {
				label: this.getLabel(y),
				definition: y,
				group: x.Name
			};
		}));

		definitionControls.forEach(groupDefinitions => {
			switch (this.orderBy) {
				case QaCriteriaDefinitionProperty.Label:
					groupDefinitions.sort((a, b) => a.definition.ControlLabel.localeCompare(b.definition.ControlLabel));
					break;
				case QaCriteriaDefinitionProperty.Name:
					groupDefinitions.sort((a, b) => a.definition.Name.localeCompare(b.definition.Name));
					break;
				case QaCriteriaDefinitionProperty.Points:
					groupDefinitions.sort((a, b) => a.definition.Points - b.definition.Points);
					break;
			}
			if (!this.orderAscending) {
				groupDefinitions.reverse();
			}
		});

		return definitionControls.flat();
	}

	private getLabel(definition: QaCriteriaDefinition): string {
		switch (this.displayItem) {
			case QaCriteriaDefinitionProperty.Label:
				if (definition.ControlLabel === null) {
					throw new Error(`No label found for ${definition.Name}.`);
				}
				return definition.ControlLabel;
			case QaCriteriaDefinitionProperty.Name:
				return definition.ControlLabel ?? definition.Name;
			case QaCriteriaDefinitionProperty.Points:
				return definition.ControlLabel ?? definition.Points.toString();
		}
	}
}

interface LabeledDefinition {
	label: string;
	definition: QaCriteriaDefinition;
	group: string;
}

import { Directive, Input, ElementRef, OnChanges, OnDestroy } from '@angular/core';

@Directive({
  selector: '[dataValidation]'
})
export class DataValidationDirective implements OnChanges, OnDestroy {
  @Input('dataValidation') public Settings: { placement: string, value: IKnockoutValidatedObservable };

  private element: HTMLInputElement;
  private $element: JQuery;
  private subscriptions: any[] = [];
  constructor(elementRef: ElementRef) {
    this.element = elementRef.nativeElement;
    this.$element = $(this.element);
  }

  private disposeSubscriptions(): void {
    for (const subscription of this.subscriptions) {
      subscription.dispose();
    }
    this.subscriptions = [];
  }

  public ngOnDestroy(): void {
    this.disposeSubscriptions();
  }

  public ngOnChanges(): void {
    this.disposeSubscriptions();
    this.Settings.value.extend({ validatable: true });
    this.subscriptions.push(this.Settings.value.error.subscribe(() => {
      this.runValidation();
    }));
    this.subscriptions.push(this.Settings.value.isValid.subscribe(() => {
      this.runValidation();
    }));
    this.subscriptions.push(this.Settings.value.isModified.subscribe(() => {
      this.runValidation();
    }));

    this.runValidation();
  }

  private runValidation(): void {
    this.$element.removeClass('has-error');
    this.$element.tooltip('dispose');
    this.$element.removeClass('validationElement');

    const isModified = this.Settings.value.isModified();
    const isValid = this.Settings.value.isValid();

    if (!isValid) {
      this.$element.attr('title', this.Settings.value.error());
      this.$element.attr('data-orig-title', this.$element.attr('data-orig-title') || this.element.title);
    } else {
      this.$element.attr('title', this.$element.attr('data-orig-title') || this.element.title);
      this.$element.attr('data-orig-title', null);
    }

    if (isModified && !isValid) {
      //this.$element.addClass('validationElement');
      this.$element.addClass('has-error');

      this.$element.tooltip({
        title: 'Validation Error',
        placement: this.Settings.placement,
        container: 'body'
      });

      if (this.$element.is(':focus')) {
        this.$element.tooltip('show');
      }
    }
  }
}


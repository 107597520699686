import { Component, computed, Input, OnInit, WritableSignal, signal, TemplateRef, ViewChild } from '@angular/core';
import { NotificationDefinition, NotificationTemplate, NotificationChannel, ExampleNotificationRequest, NotificationTemplateHistory, NotificationTemplateEditor } from '../../../shared/models/notifications/notifications';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '../../../services/notification.service';
import { finalize, switchMap } from 'rxjs/operators';
import Permissions from '../../../../Scripts/app/models/Permissions';
import { forkJoin } from 'rxjs';
import { PermissionService } from '../../../services/permission.service';

@Component({
  selector: 'app-notification-definition',
  templateUrl: './notification-definition.component.html'
})
export class NotificationDefinitionComponent implements OnInit {
  @Input() definition: NotificationDefinition;
  @Input() partnerId: number | null = null;
  @ViewChild('notificationTemplateEditor') editor: NotificationTemplateEditor;
  currentTemplate: WritableSignal<NotificationTemplate> = signal(null);
  currentTemplateHistory: NotificationTemplateHistory[] = [];
  selectedHistory: WritableSignal<NotificationTemplateHistory> = signal(null);
  channels: typeof NotificationChannel = NotificationChannel;
  recipient: string;
  dfd: any;
  isLoading = false;
  showHistory = false;
  canEdit = computed(() => !!this.currentTemplate()
    && this.definition.Editable
    && (this.currentTemplate().PartnerId === null ? this.canEditDefault() : this.canEditPartner()));
  canEditDefault = signal(false);
  canEditPartner = signal(false);
  defaultString = 'Default';

  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private permissionService: PermissionService
  ) { }

  ngOnInit(): void {
    const template = this.definition.Templates[0];

    if (!template) {
      toastr.error('No templates found for this definition.');
      this.close();
      return;
    }

    this.setTemplate(this.definition.Templates[0]);

    forkJoin([
      this.permissionService.hasPermission(Permissions.CanEditDefaultNotificationTemplates),
      this.permissionService.hasPermission(Permissions.CanEditPartnerNotificationTemplates),
    ]).subscribe(([canEditDefault, canEditPartner]) => 
    {
      this.canEditDefault.set(canEditDefault);
      this.canEditPartner.set(canEditPartner);
    });
  }

  close(): void {
    if (this.dfd) {
      this.dfd.resolve();
    }
  }

  setTemplate(template: NotificationTemplate): void {
    this.currentTemplate.set(template);
    this.currentTemplateHistory = [];
    this.selectedHistory.set(null);
    this.isLoading = true;

    forkJoin([
      this.notificationService.getExampleRecipient(this.partnerId),
      this.notificationService.getTemplateHistory(template.NotificationTemplateId)
    ])
      .pipe(finalize(() => this.isLoading = false))
      .subscribe({
        next: ([exampleRecipient,history]) => {
          this.recipient = exampleRecipient;
          this.currentTemplateHistory = history;
        },
        error: err => toastr.error(err)
      });
  }

  copyTemplate(template: NotificationTemplate): void {
    this.editor.cancel();
    this.editor.editWithValues(template.Title, template.Body);
  }

  createTemplateFrom(template: NotificationTemplate): void {
    this.currentTemplate.set({
      NotificationDefinitionId: template.NotificationDefinitionId,
      LanguageId: template.LanguageId,
      PartnerId: this.partnerId,
      Channel: template.Channel,
      IsActive: true,
      AllowFiles: template.AllowFiles,
      Title: template.Title,
      Body: template.Body
    } as NotificationTemplate);
  }

  openModal(ref: TemplateRef<any>): void {
    this.modalService.open(ref, { size: 'lg' });
  }

  save(template: NotificationTemplate): void {
    this.isLoading = true;

    this.notificationService.updateTemplate(template)
      .pipe(
        finalize(() => this.isLoading = false),
        switchMap(() => {
          this.selectedHistory.set(null);
          return this.notificationService.getTemplateHistory(template.NotificationTemplateId);
        })
      )
      .subscribe({
        next: history => {
          this.currentTemplateHistory = history;
          toastr.success('Template updated successfully.');
        },
        error: err => toastr.error(err)
      });
  }

  create(template: NotificationTemplate): void {
    this.isLoading = true;

    this.notificationService.createTemplate(template)
      .pipe(
        switchMap(() => this.notificationService.getTemplatesForPartner(this.partnerId, this.definition.NotificationDefinitionId)),
        finalize(() => {
          this.isLoading = false;
          this.currentTemplate.set(this.definition.Templates[0]);
        }),
      )
      .subscribe({
        next: templates => {
          this.definition.Templates = templates;
          toastr.success('Template created successfully.');
        },
        error: err => toastr.error(err)
      });
  
  }

  revert(history: NotificationTemplateHistory): void {
    this.isLoading = true;

    this.notificationService.revertToVersion(history)
      .subscribe({
        next: () => {
          toastr.success('Template reverted successfully.');
          this.selectedHistory.set(null);
        },
        error: err => toastr.error(err),
        complete: () => this.isLoading = false
      });
  }

  sendExampleNotification(request: ExampleNotificationRequest): void {
    this.isLoading = true;

    this.notificationService.sendExampleNotification(request)
      .subscribe({
        next: () => toastr.success('Example email sent. Check your email.'),
        error: err => toastr.error(err),
        complete: () => this.isLoading = false
      });
  }

  saveExampleRecipient(recipient: string): void {
    this.isLoading = true;

    this.notificationService.saveFrontExampleRecipient(recipient, this.partnerId)
      .subscribe({
        next: () => {
          this.recipient = recipient;
          toastr.success('Example recipient saved successfully.');
        },
        error: err => toastr.error(err),
        complete: () => this.isLoading = false
      });
  }
}

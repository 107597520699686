import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

type PartnerPricingToolPriceLevel = Boo.Objects.SEOPresence.PartnerPricingToolPriceLevel;

@Injectable({
  providedIn: 'root'
})
export class PartnerPricingToolPriceLevelService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/partnerpricingtoolpricelevels`;

  constructor(private http: HttpClient) { }

  getByPartnerIdAndLanguageId(partnerId: number, languageId: number): Observable<Boo.Objects.SEOPresence.PartnerPricingToolPriceLevel[]> {
    const params = new HttpParams()
      .set('partnerId', partnerId)
      .set('languageId', languageId);

    return this.http.get<Boo.Objects.SEOPresence.PartnerPricingToolPriceLevel[]>(`${this.baseUrl}/GetByPartnerIdAndLanguageId`, { params: params });
  }

  save(levels: PartnerPricingToolPriceLevel | PartnerPricingToolPriceLevel[]): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/Save`, Array.isArray(levels) ? levels : [levels]);
  }

  delete(ids: number | number[]): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/Delete`, Array.isArray(ids) ? ids : [ids]);
  }

  replaceFeatures(partnerPricingToolPriceLevelId: number, partnerPricingToolPriceLevelFeatures: Boo.Objects.SEOPresence.PartnerPricingToolPriceLevelFeature[]): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/ReplaceFeatures`, {
      partnerPricingToolPriceLevelId: partnerPricingToolPriceLevelId,
      partnerPricingToolPriceLevelFeatures: partnerPricingToolPriceLevelFeatures
    });
  }

  getDisplayablePriceLevel(partnerPricingToolPriceLevelId: number, partnerId: number, languageId: number): Observable<PartnerPricingToolPriceLevel> {
    const params = new HttpParams()
      .set('partnerPricingToolPriceLevelId', partnerPricingToolPriceLevelId)
      .set('partnerId', partnerId)
      .set('languageId', languageId);

    return this.http.get<PartnerPricingToolPriceLevel>(`${this.baseUrl}/GetDisplayablePriceLevel`, { params: params });
  }
}

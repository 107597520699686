import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import CustomValidators from '../../../../shared/custom-validators';

@Component({
  selector: 'app-components-shared-page-update',
  templateUrl: './page-update.component.html',
  styleUrls: ['../customer-campaign/customer-campaign.component.scss']
})
export class PageUpdateComponent implements OnInit {
  @Input() websiteUrl: Boo.Objects.WebsiteUrl;
  @Input() websiteUrls: Boo.Objects.WebsiteUrl[];
  @Input() readOnlyReasons: string;
  @Input() restrictions: Boo.Objects.CustomerCampaign.Enums.RestrictionTypes[];

  customerCampaignRestrictionTypes_ReadOnly = Boo.Objects.CustomerCampaign.Enums.RestrictionTypes.ReadOnly;
  customerCampaignRestrictionTypes_DisablePageUpdate = Boo.Objects.CustomerCampaign.Enums.RestrictionTypes.DisablePageUpdate;

  @ViewChild('pageInput') pageInput: ElementRef;

  pageControl: UntypedFormControl;
  updatingPage = false;

  ngOnInit(): void {
    this.setupForm();
  }

  toggleUpdatingPage(): void {
    this.updatingPage = !this.updatingPage;

    if (this.updatingPage) {
      this.focus();
    } else {
      this.pageControl.reset(this.websiteUrl.Url);
    }
  }

  setupForm(): void {
    this.pageControl = new UntypedFormControl(this.websiteUrl.Url, [
      CustomValidators.validUrl(),
      CustomValidators.stringsNotAllowedMethod(this.getUnavailableUrls.bind(this), true, 'This URL is already in use.'),
      CustomValidators.urlMustMatch(this.websiteUrl.Url, 'Only the casing or trailing slash of the URL can be changed'),
      Validators.maxLength(512)
    ]);
  }

  getUnavailableUrls(): string[] {
    return this.websiteUrls ? this.websiteUrls.filter(websiteUrl => websiteUrl != this.websiteUrl).map(websiteUrl => websiteUrl.Url) : [];
  }

  updatePage() {
    this.pageControl.markAsDirty();
    this.pageControl.updateValueAndValidity();

    if (!this.pageControl.valid) {
      toastr.error("Please correct errors before updating a page.");
      return;
    }

    this.websiteUrl.Url = this.pageControl.value.trim();
    this.websiteUrl.IsModified = true;
    this.toggleUpdatingPage();
  }

  private focus()
  {
    setTimeout(() => {
      if (this.pageInput) {
        this.pageInput.nativeElement.focus();
      }
    }, 0);
  }
}
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ProTipService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/protips`;

  constructor(private http: HttpClient) { }

  get(partnerId: number, languageId: number): Observable<Boo.Objects.ProTip[]> {
    const params = new HttpParams()
      .set('partnerId', partnerId)
      .set('languageId', languageId);

    return this.http.get<Boo.Objects.ProTip[]>(this.baseUrl, { params: params });
  }

  save(proTip: Boo.Objects.ProTip): Observable<any> {
    return this.http.post(this.baseUrl, proTip);
  }

  delete(proTipId: number): Observable<any> {
    const params = new HttpParams().set('proTipId', proTipId);

    return this.http.delete(this.baseUrl, { params: params });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PartnerGroupService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/partnergroups`;

  constructor(private http: HttpClient) { }

  getPartnerGroups(): Observable<Boo.Objects.Group[]> {
    return this.http.get<Boo.Objects.Group[]>(`${this.baseUrl}/groups`);
  }

  get(partnerId: number): Observable<Boo.Objects.PartnerGroup[]> {
    return this.http.get<Boo.Objects.PartnerGroup[]>(`${this.baseUrl}/get/${partnerId}`);
  }

  save(partnerId: number, groupIds: number[]): Observable<any> {
    const partnerGroups: Boo.Objects.PartnerGroup[] = groupIds.map((x) => { return { PartnerId: partnerId, GroupId: x }; });
    return this.http.post(`${this.baseUrl}/save`, { partnerId: partnerId, groupIds: groupIds });
  }

  delete(partnerId: number): Observable<any> {
    return this.http.post(`${this.baseUrl}/delete`, partnerId);
  }
}

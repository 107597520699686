import { Component } from '@angular/core';
import System from 'framework/System';
import permissions from 'app/models/Permissions';
import BaseComponent from '../BaseComponent';
import PartnerUser from 'app/models/typescript/PartnerUser';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import IValidatedResult = app.interfaces.IValidatedResult;
import { TaskService } from '../../../services/task.service';
import { SaveTypes } from 'app/models/enums/SaveTypes';
import TicketTypeEnum = Boo.Objects.Enums.TicketTypeEnum;
import { PartnerUserService } from '../../../services/partner-user.service';
import { TicketService } from '../../../services/ticket.service';
import Utils from '../../../shared/utils';
import { ticketTypeConfig } from '../TicketTypeConfig';

@Component({
  selector: 'app-components-ticket-basicinfo',
  templateUrl: './BasicInfo.component.html'
})
export class BasicInfoComponent extends BaseComponent {
  moment: moment.MomentStatic = moment;
  _ = _;
  userOptions: Boo.Objects.User[];
  dateFormat = 'M/DD/YYYY';
  dateTimeFormat = 'M/DD/YYYY h:mm a';
  assignedUserId: KnockoutObservable<number>;
  startDate: KnockoutObservable<moment.Moment>;
  startTime: KnockoutObservable<moment.Moment>;
  customerStartDateTime: KnockoutComputed<moment.Moment>;
  estimatedTime: KnockoutComputed<string>;
  completedTime: KnockoutComputed<string>;
  isChangingDatesAllowed: boolean;
  requireAppointmentToChangeAssignedUser: boolean;
  canChangeAssignedUser: KnockoutComputed<boolean>;
  partnerName: string;
  customActionTaskId: KnockoutObservable<number> = ko.observable(0);
  shouldShowReasonBox = true;
  isOpen = false;

  get isFulfillmentWorkblockTicket(): boolean {
    return this.ticket.TicketTypeId() === TicketTypeEnum.FulfillmentWorkblock;
  }

  constructor(
    private partnerUserService: PartnerUserService,
    private taskService: TaskService,
    private ticketService: TicketService) {
    super();
  }

  activate(params: app.ticket.components.interfaces.IBasicInfoViewModelActivateParams): JQueryPromise<void> {
    this.partnerName = params.customer.Partner.Name();
    this.isChangingDatesAllowed = params.featureConfig.isChangingDatesAllowed;
    this.requireAppointmentToChangeAssignedUser = params.featureConfig.requireAppointmentToChangeAssignedUser;
    return super.activate(params).then(() => {
      if (this.ticket.AssociatedTaskId && this.ticket.AssociatedTaskId()) {
        Utils.wrapDfd(this.taskService.getCustomActionTaskIdForWorkflow(this.ticket.AssociatedTaskId()))
          .then(customActionTaskId => {
            this.customActionTaskId(customActionTaskId);
          });
      }

      this.startDate = ko.observable(moment(this.ticket.ScheduledDate()));
      this.startTime = ko.observable(moment(this.ticket.ScheduledDate()));
      this.customerStartDateTime = ko.computed(() => {
        return this.getCustomerDateTime(this.startDate(), this.startTime());
      });
      this.estimatedTime = ko.computed(() => {
        const minutes = this.ticket.EstimatedMinutes();
        const milliseconds = minutes && !_.isNaN(minutes) ? minutes * 60 * 1000 : 0;
        return launchpad.utils.timeFormatFromDuration(milliseconds);
      });
      this.completedTime = ko.computed(() => {
        const seconds = this.ticket.CompletedSeconds();
        const milliseconds = seconds && !_.isNaN(seconds) ? seconds * 1000 : 0;
        return launchpad.utils.timeFormatFromDuration(milliseconds);
      });

      if (params.featureConfig.requireAppointmentToChangeAssignedUser) {
        this.canChangeAssignedUser = ko.computed(() => {
          return this.ticket.IsAppointment();
        });
        this.ticket.IsAppointment.subscribe((value: boolean) => {
          if (!value) {
            this.ticket.AssignedUserId(this.user.UserId);
          }
        });
      } else {
        this.canChangeAssignedUser = ko.computed(() => {
          return true;
        });
      }

      // clear out AssignedUser when AssignedUserId changes to stop things from referencing old data.
      // we should be using AssignedUserId instead so we can just keep AssignedUser null.
      this.ticket.AssignedUserId.subscribe(() => {
        this.ticket.AssignedUser = null;
      });

      // init validation
      this.shouldValidateOnUpdate = ticketTypeConfig.alwaysRequiresAssignedUser.includes(this.ticket.TicketTypeId());
      this.validation = ko.validatedObservable<any>({
        assignedUserId: this.ticket.AssignedUserId.extend({
          validation: {
            validator: (val: number): boolean => { return val > 0 || !ticketTypeConfig.alwaysRequiresAssignedUser.includes(this.ticket.TicketTypeId()); },
            message: 'Assigned user is required.'
          },
          equal: {
            params: this.partnerUser.UserId,
            onlyIf: (): boolean => { return this.saveType() === SaveTypes.Complete; },
            message: 'To complete a ticket, you must be the assigned user.'
          }
        })
      });

      // load objects before binding
      return this.loadUserOptionsAndAssignedUser();
    });
  }

  confirmSave(saveType: SaveTypes): JQueryPromise<boolean> {
    return this.confirmAppointment();
  }

  save(saveType: SaveTypes): JQueryPromise<app.ticket.interfaces.ISaveData> {
    return System.resolvedPromise(({
      ticket: {
        AssignedUserId: this.ticket.AssignedUserId(),
        IsAppointment: this.ticket.IsAppointment(),
        Reason: this.ticket.Reason(),
        ScheduledDate: this.getDateTime(this.startDate(), this.startTime()).toDate()
      }
    } as app.ticket.interfaces.ISaveData));
  }

  validate(saveType: SaveTypes): JQueryPromise<IValidatedResult> {
    // try to assign the current user to the ticket before validation.
    const currentUserId = this.user.UserId;
    if (saveType === SaveTypes.Complete && this.userHasTicketType() && this.ticket.AssignedUserId() !== currentUserId) {
      // only assign the user if they are in the already filtered dropdown options
      this.userOptions.forEach((user: Boo.Objects.User) => {
        if (user.UserId === currentUserId) {
          this.ticket.AssignedUserId(currentUserId);
        }

      });
    }

    return super.validate(saveType).then((validationResult) => {
      // do some extra validation that requires special toast messages
      if (validationResult.isValid && !this.validateAssociatedTaskDate()) {
        validationResult.isValid = false;
      }

      if (!validationResult.isValid) {
        this.isOpen = true;
      }

      return validationResult;
    });
  }

  protected getDateTime(datePortion: moment.Moment, timePortion: moment.Moment): moment.Moment {
    return moment(datePortion.format('L') + ' ' + timePortion.format('hh:mm:ss a'));
  }

  protected getCustomerDateTime(datePortion: moment.Moment, timePortion: moment.Moment): moment.Moment {
    const timezone: string = this.customer && this.customer.TimeZone() ? this.customer.TimeZone() : null;
    let newDate: moment.Moment = null;

    try {
      newDate = this.getDateTime(datePortion, timePortion);
    } catch (e) {
      toastr.error('Invalid date or time');
    }

    if (newDate && timezone) {
      try {
        newDate = newDate.tz(timezone);
      } catch (e) {
        toastr.error('Customer\'s Time Zone isn\'t set correctly! please go to Basic Information and set it.');
      }
    }

    return newDate ? newDate : moment();
  }

  protected loadUserOptionsAndAssignedUser(): JQueryPromise<void> {
    return Utils.wrapDfd(this.partnerUserService.getByUserLevel(Boo.Objects.Enums.UserLevelEnum.CustomerService))
      .then((assignedUsers: Boo.Objects.PartnerUser[]) => {
        let users: Boo.Objects.User[] = assignedUsers;

        if (this.partner.PartnerId !== 1 && launchpad.hasPermission(this.partner, this.partnerUsers, permissions.CanAssignTickets, this.user)) {
          users = _.filter(users, (user: Boo.Objects.User) => {
            return _.isString(user.Username) && $.trim(user.Username) !== '' && user.Username.toLowerCase().indexOf('boostability.com') < 0;
          });

          const csr: Boo.Objects.User = ko.utils.unwrapObservable(this.customer.Csr);
          if (csr) {
            const csrPartnerUser: Boo.Objects.PartnerUser = new PartnerUser();
            csrPartnerUser.UserId = csr.UserId;
            csrPartnerUser.FullName = csr.FullName;
            csrPartnerUser.FirstName = csr.FirstName;
            csrPartnerUser.LastName = csr.LastName;
            users.push(csrPartnerUser);
          }
        }

        // add the currently-assigned user, if it's not already in the collection
        if (_.isObject(this.ticket.AssignedUser) &&
          ko.utils.unwrapObservable(this.ticket.AssignedUser) &&
          !_.contains(_.pluck(users, 'UserId'), this.ticket.AssignedUserId())
        ) {
          users.push(ko.utils.unwrapObservable((this.ticket.AssignedUser as any)));
        }

        this.userOptions = _.sortBy(users, 'FullName');

        // assign ticket to partnerUser if there is no assignment only if this is an appointment ticket
        if (this.ticket.AssignedUserId() === 0 && this.ticket.StatusId() < Boo.Objects.Enums.TicketStatusEnum.Completed && this.ticket.IsAppointment()) {
          if (this.userHasTeam() && (this.ticket.TicketId() === 0 || this.userHasTicketType())) {
            this.ticket.AssignedUserId(this.partnerUser.UserId);
          }
        }
      });
  }

  protected confirmAppointment(): JQueryPromise<boolean> {
    if (!this.ticket.IsAppointment()) {
      return System.resolvedPromise(true);
    }

    return Utils.wrapDfd(this.ticketService.isTicketAppointmentConflicting(
      this.ticket.TicketId(),
      this.ticket.AssignedUserId(),
      this.getDateTime(this.startDate(), this.startTime()).toDate(),
      this.getDateTime(this.startDate(), this.startTime()).add(this.ticket.EstimatedMinutes(), 'm').toDate()))
      .then((isConflicting: boolean) => {
        // appointment is not conflicting, so it's automatically confirmed
        if (!isConflicting) {
          return true;
        }

        // appointment is conflicting, so return result of user confirmation
        return $.Deferred<boolean>((dfd) => {
          const msg = 'Assigned user already has an appointment during this time block. Are you sure you want to schedule this appointment?';
          bootbox.confirm(msg, (result: boolean) => {
            dfd.resolve(result === true);
          });
        }).promise();
      });
  }

  protected validateAssociatedTaskDate(): boolean {
    if (this.ticket.TicketTypeId() !== Boo.Objects.Enums.TicketTypeEnum.OnsiteCopyReadyForPreview
      || this.ticket.TicketTypeId() !== Boo.Objects.Enums.TicketTypeEnum.OnsiteBlogReadyForPreview
      || !_.isObject(this.ticket.AssociatedTask)) {
      return true;
    }

    // this is a bit of a hack
    const autoApprovalDate =
      (this.ticket.TicketTypeId() === Boo.Objects.Enums.TicketTypeEnum.OnsiteCopyReadyForPreview ?
        (moment(this.ticket.InsertedDate()).add(launchpad.config.onsiteCopyPreviewAutoApprovalDays, 'd'))
        : moment(this.ticket.InsertedDate()).add(launchpad.config.onsiteBlogPreviewAutoApprovalDays, 'd'));
    const scheduledDate = this.getDateTime(this.startDate(), this.startTime()).add(this.ticket.EstimatedMinutes(), 'm');
    const isValid = scheduledDate <= autoApprovalDate;

    if (!isValid) {
      toastr.error('This ticket cannot be scheduled beyond ' + autoApprovalDate.format('MM/DD/YYYY h:mm a') + ' because the piece of copy will be autoapproved by the system on/after that date.');
    }
    return isValid;
  }

  showTaskDetails(taskId: number): void {
    PriorityViewModel.show(
      'app-components-managecustomer-taskdetails',
      {
        taskId: taskId
      }
    );
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SeoMinuteFeatureService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/seominutefeature`;

  constructor(private http: HttpClient) { }

  getTranslated(partnerId: number, languageId: number): Observable<Boo.Objects.SEOPresence.SeoMinuteFeature[]> {
    const params = new HttpParams()
      .set('partnerId', partnerId)
      .set('languageId', languageId);

    return this.http.get<Boo.Objects.SEOPresence.SeoMinuteFeature[]>(this.baseUrl, { params: params });
  }

  replace(seoMinuteFeatures: Boo.Objects.SEOPresence.SeoMinuteFeature[], partnerId: number, languageId: number) {
    return this.http.post<Boo.Objects.SEOPresence.SeoMinuteFeature[]>(`${this.baseUrl}/Replace`, {
      seoMinuteFeatures: seoMinuteFeatures,
      partnerId: partnerId,
      languageId: languageId
    });
  }
}

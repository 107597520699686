import { Component } from '@angular/core';
import Check from 'framework/Check';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import Utils from '../../../shared/utils';
import { CustomReportService } from '../../../services/custom-report.service';

interface ICustomReportWithTokenAndSource extends Boo.Objects.CustomReport {
  token: string;
  source: string;
}

@Component({
  selector: 'app-components-customreports',
  templateUrl: './CustomReports.component.html'
})
export class CustomReportsComponent {
  customer: KnockoutObservable<Boo.Objects.Customer>;
  isLoading: KnockoutObservable<boolean>;
  reports: KnockoutObservableArray<ICustomReportWithTokenAndSource>;
  showAddNew: boolean;
  allowDelete: boolean;

  constructor(private customReportService: CustomReportService) { }

  activate(params: any): JQueryPromise<void> {
    Check.isNotEmpty(params, 'Params is empty');
    Check.isNotEmpty(params.customer, 'Customer is missing');

    this.isLoading = ko.observable(false);
    this.customer = ko.observable(ko.mapping.toJS(params.customer));
    this.showAddNew = params.showAddNew;
    this.allowDelete = params.allowDelete;

    this.reports = ko.observableArray([]);

    return this.loadReports();
  }

  addCustomReport(): void {
    PriorityViewModel.show('app-components-managecustomer-addcustomreport', { customerId: this.customer().CustomerId })
      .then(() => {
        return this.loadReports();
      });
  }

  deleteFile(customReportWithTokenAndSource: ICustomReportWithTokenAndSource): void {
    bootbox.confirm('Would you like to delete this file?', (confirmResult: boolean) => {
      if (confirmResult) {
        customReportWithTokenAndSource.IsDeleted = true;
        this.isLoading(true);
        Utils.wrapDfd(this.customReportService.save(customReportWithTokenAndSource))
          .then(() => {
            toastr.success('Sucessfully updated the custom reports.');
          }).then(() => {
            return this.loadReports();
          })
          .fail((displayMessage: string) => {
            toastr.error(displayMessage);
          }).always(() => {
            this.isLoading(false);
          });
      }
    });
  }

  loadReports(): JQueryPromise<void> {
    return Utils.wrapDfd(this.customReportService.getByCustomerId(this.customer().CustomerId))
      .then((customReports: Boo.Objects.CustomReport[]) => {
        const reports: ICustomReportWithTokenAndSource[] = [];
        _.each(customReports, (item:  Boo.Objects.CustomReport) => {
          if (item && item.S3Url) {
            const i = { ...item, token: URI.parse(item.S3Url).query, source: item.CustomReportId > 0 ? 'User' : 'System' } as ICustomReportWithTokenAndSource;
            reports.push(i);
          }
        });
        this.reports(_.filter(reports, (report: ICustomReportWithTokenAndSource) => {
          return !report.IsDeleted;
        }));
      });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import Utils from '../../app/shared/utils';
import Check from 'framework/Check';
import { delay, map, retryWhen, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AmazonService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/amazon`;

  constructor(private http: HttpClient) { }

  generatePreSignedUrl(request: Boo.Objects.Amazon.PreSignedUrlRequest): Observable<Boo.Objects.Amazon.PreSignedUrl> {
    return this.http.post<Boo.Objects.Amazon.PreSignedUrl>(`${this.baseUrl}/GeneratePreSignedUrl`, request);
  }

  upload(request: Boo.Objects.Amazon.PreSignedUrlRequest, file: File): JQueryPromise<Boo.Objects.Amazon.PreSignedUrl> {
    return Utils.wrapDfd(this.generatePreSignedUrl(request))
      .then((signed: Boo.Objects.Amazon.PreSignedUrl) => {
        return this.uploadFromSignedUrl(signed, file, request.ContentType);
      });
  }

  fileExists(url: string): Observable<any> {
    return this.http.get(url, { responseType: 'text' });
  }

  checkIfFileExists(url: string): Observable<any> {
    return this.fileExists(url).pipe(
      delay(2000),
      retryWhen(errors => errors.pipe(delay(500), take(20))),
      map(response => response ? url : null)
    );
  }

  isUrlForUploadedFile(url: string): boolean {
    const signal = 's3staticfile';
    return url.indexOf(signal) >= 0;
  }

  private uploadFromSignedUrl(preSignedUrl: Boo.Objects.Amazon.PreSignedUrl, file: File, contentType: string): JQueryPromise<Boo.Objects.Amazon.PreSignedUrl> {
    Check.isNotEmpty(preSignedUrl, 'Pre-signed URL is empty.');

    // todo: remove $.ajax
    // We want to manually handle the resolution of the deferred to return the proper object.
    return $.Deferred((uploadDeferred: JQueryDeferred<Boo.Objects.Amazon.PreSignedUrl>) => {
      $.ajax({
        url: preSignedUrl.PreSignedUploadUrl,
        type: 'PUT',
        cache: false,
        timeout: 300000,
        contentType: contentType,
        processData: false,
        data: file,
        success: (): void => {
          preSignedUrl.FileName = file.name;
          uploadDeferred.resolve(preSignedUrl);
        },
        error: (): void => {
          uploadDeferred.reject('Unable to upload file.');
        }
      });
    });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PartnerFileService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/Partnerfiles`;

  constructor(private http: HttpClient) { }

  get(partnerId: number): Observable<Boo.Objects.PartnerFile[]> {
    const params = new HttpParams().set('partnerId', partnerId);

    return this.http.get<Boo.Objects.PartnerFile[]>(this.baseUrl, { params: params });
  }

  save(file: Boo.Objects.PartnerFile): Observable<Boo.Objects.PartnerFile> {
    return this.http.post<Boo.Objects.PartnerFile>(this.baseUrl, file);
  }
}

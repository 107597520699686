import { Component, Input } from '@angular/core';
import { CustomerCampaignService } from '../../../../services/customer-campaign.service';

@Component({
  selector: 'app-components-shared-keyword',
  templateUrl: './keyword.component.html',
  styleUrls: ['../customer-campaign/customer-campaign.component.scss']
})
export class KeywordComponent {
  @Input() customer: Boo.Objects.Customer;
  @Input() websiteUrlKeyword: Boo.Objects.WebsiteUrlKeyword;
  @Input() websiteUrl: Boo.Objects.WebsiteUrl;
  @Input() websiteUrls: Boo.Objects.WebsiteUrl[];
  @Input() haloKeywords: Boo.Objects.WebsiteKeywordTracking[];
  @Input() archivedWebsiteUrls: Boo.Objects.ArchivedWebsiteUrl[];
  @Input() archivedKeywords: Boo.Objects.ArchivedKeyword[];
  @Input() isReadOnly: boolean;
  @Input() firstPageKeywordPhrases: string[];
  @Input() restrictions: Boo.Objects.CustomerCampaign.Enums.RestrictionTypes[];

  customerCampaignRestrictionTypes_ReadOnly = Boo.Objects.CustomerCampaign.Enums.RestrictionTypes.ReadOnly;

  constructor(private customerCampaignService: CustomerCampaignService) { }

  isActiveStatus(websiteUrl: Boo.Objects.WebsiteUrl): boolean {
    return this.customerCampaignService.isActiveStatus(websiteUrl);
  }

  archiveKeyword(websiteUrlKeyword: Boo.Objects.WebsiteUrlKeyword, websiteUrl: Boo.Objects.WebsiteUrl): void {
    this.customerCampaignService.archiveKeyword(this.archivedKeywords, websiteUrlKeyword, websiteUrl);
  }

  moveKeywordToWebsiteUrl(websiteUrlKeyword: Boo.Objects.WebsiteUrlKeyword, destinationWebsiteUrl: Boo.Objects.WebsiteUrl): void {
    this.customerCampaignService.moveKeywordToWebsiteUrl(websiteUrlKeyword, destinationWebsiteUrl);
  }

  moveKeywordToHalo(websiteUrlKeyword: Boo.Objects.WebsiteUrlKeyword): void {
    this.customerCampaignService.moveKeywordToHalo(this.customer.CustomerId, this.haloKeywords, websiteUrlKeyword, this.firstPageKeywordPhrases);
  }

  toggleIsRecommended(websiteUrlKeyword: Boo.Objects.WebsiteUrlKeyword): void {
    if (!this.isReadOnly && !this.restrictions.includes(Boo.Objects.CustomerCampaign.Enums.RestrictionTypes.ReadOnly)) {
      this.customerCampaignService.toggleIsRecommended(websiteUrlKeyword);
    }
  }

  get validationMessage(): string {
    if (this.websiteUrl.StatusId === Boo.Objects.Enums.WebsiteUrlStatuses.Active && this.websiteUrlKeyword.IsActive) {
      const keywordPhrases = this.websiteUrls.filter(x => x.StatusId === Boo.Objects.Enums.WebsiteUrlStatuses.Active && x != this.websiteUrl)
        .flatMap(page => page.Keywords)
        .filter(x => x.IsActive)
        .map(x => x.KeywordPhrase.toLowerCase().trim())
        .concat(this.haloKeywords.filter(x => x.IsActive).map(x => x.KeywordPhrase.toLowerCase().trim()));

      if (keywordPhrases.includes(this.websiteUrlKeyword.KeywordPhrase.toLowerCase().trim())) {
        return 'Duplicate';
      }
    }

    return '';
  }

  get strategyUpdateValidationMessage(): string {
    if (this.validationMessage) {
      return this.validationMessage;
    }

    if (this.websiteUrlKeyword.IsActive) {
      const keywordPhrases = this.websiteUrls.filter(x => x.StatusId !== Boo.Objects.Enums.WebsiteUrlStatuses.Archived && x != this.websiteUrl)
        .flatMap(page => page.Keywords)
        .filter(x => x.IsActive)
        .map(x => x.KeywordPhrase.toLowerCase().trim())
        .concat(this.haloKeywords.filter(x => x.IsActive).map(x => x.KeywordPhrase.toLowerCase().trim()));

      if (keywordPhrases.includes(this.websiteUrlKeyword.KeywordPhrase.toLowerCase().trim())) {
        return 'Duplicate';
      }
    }

    return '';
  }
}

import { Component } from '@angular/core';
import Check from 'framework/Check';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { WebsiteService } from '../../../services/website.service';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-websites-websiteadjustments',
  templateUrl: './WebsiteAdjustments.component.html'
})
export class WebsiteAdjustmentsComponent implements IActivatable {
    public window = window;
    public launchpad = launchpad;
    public adjustments: Boo.Objects.Websites.AdjustmentTicket[];
    public customerId: number;
    public isLoading: KnockoutObservable<boolean>;
    private websiteId: number;

    constructor(private websiteService: WebsiteService) { }

    public activate(params: app.components.websites.interfaces.IWebsiteComponentActivateParams): JQueryPromise<void> {
        Check.isNotEmpty(params.customerId, 'customerId cannot be empty');
        this.customerId = params.customerId;
        this.websiteId = params.website.WebsiteId();
        this.isLoading = ko.observable(false);
        return this.loadAdjustments();
    }

    public loadAdjustments(): JQueryPromise<any> {
        this.isLoading(true);
        return Utils.wrapDfd(this.websiteService.getAdjustments(this.websiteId))
            .then((adjustments: Boo.Objects.Websites.AdjustmentTicket[]) => {
                this.adjustments = adjustments;
            })
            .always(() => {
                this.isLoading(false);
            });
    }

    public editTicket(ticket: Boo.Objects.Ticket): void {
        PriorityViewModel.show('app-components-ticket', { ticketId: ticket.TicketId }, false)
            .then(() => {
                return this.loadAdjustments();
            });
    }

    public addNew(): void {
        if (this.websiteId) {
            Utils.wrapDfd(this.websiteService.orderAdjustment(this.websiteId, this.customerId))
              .then(() => { return Utils.wrapDfd(this.websiteService.getLatestAdjustment(this.customerId)); })
              .then((ticket) => { this.editTicket(ticket); })
              .then(() => { this.loadAdjustments(); });
        }
    }
}

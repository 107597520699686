import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class CustomerContactPreferencesService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/customercontactpreferences`;

  constructor(private http: HttpClient) { }

  get(customerId: number): Observable<Boo.Objects.CustomerContactPreferences> {
    const params = new HttpParams().set('customerId', customerId);

    return this.http.get<Boo.Objects.CustomerContactPreferences>(this.baseUrl, { params: params });
  }

  save(customerContactPreferences: Boo.Objects.CustomerContactPreferences): Observable<Boo.Objects.CustomerContactPreferences> {
    return this.http.post<Boo.Objects.CustomerContactPreferences>(this.baseUrl, customerContactPreferences);
  }
}

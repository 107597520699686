import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SeoContentSurveyService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/seocontentsurvey`;

  constructor(private http: HttpClient) { }

  getByCustomerId(customerId: number): Observable<Boo.Objects.SeoContentSurvey> {
    const params = new HttpParams().set('customerId', customerId);
    return this.http.get<Boo.Objects.SeoContentSurvey>(`${this.baseUrl}/GetByCustomerId`, { params: params });
  }

  save(seoContentSurvey: Boo.Objects.SeoContentSurvey, reasonForUpdate: string, sectionUpdated: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/Save`, {
        seoContentSurvey: seoContentSurvey,
        reasonForUpdate: reasonForUpdate,
        sectionUpdated: sectionUpdated
    });
  }

  getAvailableBlogPath(customerId: number): Observable<string> {
    const params = new HttpParams().set('customerId', customerId);
    return this.http.get<string>(`${this.baseUrl}/GetAvailableBlogPath`, { params: params });
  }
}

import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import TaskHelper from 'app/specialist/TaskHelper';
import System from 'framework/System';
import CustomValidators from '../../../../../shared/custom-validators';
import { finalize } from 'rxjs/operators';
import { TaskRejectionService } from '../../../../../services/task-rejection.service';
import { ITaskFileSource } from '../../../../shared/task-files/task-files.component';
import ITaskFilesPublicApi = app.components.interfaces.ITaskFilesPublicApi;
import ITaskFilesPublicApiCallback = app.components.interfaces.ITaskFilesPublicApiCallback;

@Component({
  selector: 'app-components-specialist-actioncomponents-onsitecopyimplementation',
  templateUrl: './OnsiteCopyImplementation.component.html',
  styleUrls: ['./OnsiteCopyImplementation.component.scss']
})
export class OnsiteCopyImplementationComponent implements OnInit, IActionWorkspace, IActivatable {

  actionViewModel: KnockoutObservable<IActionWorkspace>;
  currentAction: KnockoutObservable<Boo.Objects.Action>;
  taskFileSources: ITaskFileSource[];
  taskFilesApi: ITaskFilesPublicApi;
  taskFilesApiCallback: ITaskFilesPublicApiCallback;
  taskDescription: string;
  taskInstructions: string;
  pageType: string;
  orderReason: string;
  url: string;
  content: string;
  newTitle: string;
  newDescription: string;
  newH1: string;
  newH2: string;
  publishUrlCtrl: UntypedFormControl;
  noteToReviewerCtrl: UntypedFormControl;
  rejection: Boo.Objects.TaskRejection = null;
  isLoading = false;

  private validatedControls: UntypedFormControl[];

  constructor(private taskRejectionService: TaskRejectionService) { }

  ngOnInit(): void {
    this.taskDescription = this.currentAction().Task.TaskTypeSummary.Tips;
    this.taskInstructions = TaskHelper.getOnsiteCopyImplementationInstructions(this.currentAction().TaskDetails.OnsiteCopyDetails.BillingTask);

    this.pageType = TaskHelper.getOnsiteCopyPageType(this.currentAction().TaskDetails.OnsiteCopyDetails.BillingTask);
    this.orderReason = TaskHelper.getOnsiteCopyOrderReason(this.currentAction().TaskDetails.OnsiteCopyDetails.BillingTask);
    if (this.orderReason === 'Other') {
      this.orderReason = 'Other: ' + TaskHelper.getOnsiteCopyOrderReasonOther(this.currentAction().TaskDetails.OnsiteCopyDetails.BillingTask);
    }

    this.url = this.currentAction().TaskDetails.WebsiteUrl.Url;
    this.content = this.currentAction().TaskDetails.TaskPost.Content;
    this.newTitle = this.currentAction().TaskDetails.OnsiteRecommendation.TitleNew;
    this.newDescription = this.currentAction().TaskDetails.OnsiteRecommendation.DescriptionNew;
    this.newH1 = this.currentAction().TaskDetails.OnsiteRecommendation.H1New;
    this.newH2 = this.currentAction().TaskDetails.OnsiteRecommendation.H2New;

    this.taskFileSources = [
      { taskId: this.currentAction().Task.TaskId, source: 'Task' },
      { taskId: this.currentAction().TaskDetails.OnsiteCopyDetails.BillingTask.TaskId, source: 'Work Details' }
    ];
    
    this.taskFilesApiCallback = (api: ITaskFilesPublicApi) => { this.taskFilesApi = api; };

    this.publishUrlCtrl = new UntypedFormControl(
      TaskHelper.getComment(this.currentAction().Task),
      {
        validators: [
          Validators.maxLength(2000),
          Validators.required,
          CustomValidators.validUrl(),
          CustomValidators.domainMustMatch(this.currentAction().Customer.Url, 'The Domain of the URL does not match the Customer\'s Domain.')
        ],
        updateOn: 'blur'
      });
    this.noteToReviewerCtrl = new UntypedFormControl(TaskHelper.getOnsiteImplementationNoteToReviewer(this.currentAction().Task), [Validators.maxLength(2000)]);
    this.validatedControls = [this.publishUrlCtrl, this.noteToReviewerCtrl];

    this.publishUrlCtrl.valueChanges.subscribe(value => {
      if (value.endsWith('/')) {
        this.publishUrlCtrl.setValue(this.removeTrailingForwardSlash(value));
        return;
      }

      if (value !== '' && this.orderReason === 'Existing Page' && value.normalize() !== this.removeTrailingForwardSlash(this.url).normalize()) {
        bootbox.alert('The request was to publish to an existing page, but you\'ve selected a publish URL that\'s different. Please double check that you\'ve published to the correct URL');
      }
    });

    const previousTaskId = TaskHelper.getPreviouslyCompletedImplementationTaskId(this.currentAction().Task);
    
    if (previousTaskId) {
      this.isLoading = true;
      this.taskRejectionService.getLatest(previousTaskId)
        .pipe(finalize(() =>
        { 
          this.isLoading = false;
          toastr.warning('This is an implementation revision');
        }))
        .subscribe(
          (rejection: Boo.Objects.TaskRejection) => this.rejection = rejection,
          (error: any) => toastr.error('Failed to load previous task rejection')
        );
    }
  }

  activate(params: IActionWorkspaceActivationParams): JQueryPromise<any> {
    params.controlViewModel(this);
    this.currentAction = params.currentAction;
    this.actionViewModel = ko.observable(null);
    return System.emptyPromise();
  }

  isStepReadyToReject(): boolean {
    return false;
  }

  isStepReadyToSave(): boolean {
    if (!this.currentAction().Task.TaskTypeSummary.IsPersistable) {
      return false;
    }

    TaskHelper.setComment(this.currentAction().Task, this.publishUrlCtrl.value, this.currentAction().Task.AssignedUserId);
    TaskHelper.maybeSetOnsiteImplementationNoteToReviewer(this.currentAction().Task, this.noteToReviewerCtrl.value, this.currentAction().Task.AssignedUserId);
    return true;
  }

  isStepReadyToDelete(): boolean {
    return false;
  }

  isStepReadyToComplete(): boolean {
    let isValid = true;
    if (!this.validatedControls.every(x => x.valid)) {
      this.validatedControls.forEach(x => x.markAsTouched());
      isValid = false;
    }

    TaskHelper.setComment(this.currentAction().Task, this.publishUrlCtrl.value, this.currentAction().Task.AssignedUserId);
    TaskHelper.maybeSetOnsiteImplementationNoteToReviewer(this.currentAction().Task, this.noteToReviewerCtrl.value, this.currentAction().Task.AssignedUserId);
    return isValid;
  }

  private removeTrailingForwardSlash(value: string): string {
    if (value.endsWith('/')) {
      return value.substring(0, value.length - 1);
    }
    return value;
  }
}

import { Component, Input, OnInit } from '@angular/core';
import System from 'framework/System';
import permissions from 'app/models/Permissions';
import Check from 'framework/Check';
import FileSectionType from 'app/models/typescript/FileSectionType';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { reduceLength } from 'app/extensionmethods/StringExtensions';
import { TicketService } from '../../../services/ticket.service';
import Utils from '../../../shared/utils';
import FileUploader from 'framework/FileUploader';
import PresignedUrlRequest from 'app/models/typescript/PresignedUrlRequest';
import { CustomerFileService } from '../../../services/customer-file.service';
import { SessionStorageService } from '../../../services/session-storage.service';
import { forkJoin } from 'rxjs';

type CustomerFilesOptions = app.components.interfaces.CustomerFilesOptions;

@Component({
    selector: 'app-components-customerfiles',
    templateUrl: './customer-files.component.html'
})
export class CustomerFilesComponent implements OnInit {
    @Input() options: CustomerFilesOptions;
    allowDelete = false;
    allowUpload = false;
    showUsers = false;
    files: KnockoutObservableArray<Boo.Objects.CustomerFile> = ko.observableArray([] as Boo.Objects.CustomerFile[]);
    fileSectionTypeId: number;
    fileSectionTypes: FileSectionType[] = [
        ({ FileSectionTypeId: Boo.Objects.Enums.FileSectionTypesEnum.BoostSite, Name: 'BoostSite' } as FileSectionType),
        ({ FileSectionTypeId: Boo.Objects.Enums.FileSectionTypesEnum.General, Name: 'General' } as FileSectionType),
        ({ FileSectionTypeId: Boo.Objects.Enums.FileSectionTypesEnum.KeywordResearch, Name: 'Keyword Research' } as FileSectionType),
        ({ FileSectionTypeId: Boo.Objects.Enums.FileSectionTypesEnum.LocalProfile, Name: 'Local Profile' } as FileSectionType),
        ({ FileSectionTypeId: Boo.Objects.Enums.FileSectionTypesEnum.Marketing, Name: 'Marketing' } as FileSectionType),
        ({ FileSectionTypeId: Boo.Objects.Enums.FileSectionTypesEnum.Ranks, Name: 'Ranks' } as FileSectionType)
    ];
    maxNameDisplayLength: number;
    filterByTicket: boolean;
    ticketIdToFilterBy: number;
    allowFilteringByTicket: boolean;
    isFileUploadRequired: boolean;
    private requiredFileValidation: KnockoutObservable<any>;

    private customerId: number;
    private user: Boo.Objects.User;

    private showOnlyCurrentFiles: boolean;
    private ticketTypeId: number;
    private latestTicket: Boo.Objects.TicketSummary;

    getPublicApi(): app.components.interfaces.CustomerFilesPublicApi {
        this.requiredFileValidation = ko.validatedObservable([
            this.files.extend({
                validation: [
                    {
                        validator: (files: KnockoutObservableArray<Boo.Objects.CustomerFile>): boolean => {
                            return (!this.isFileUploadRequired || (files && files.length > 0));
                        },
                        message: 'A file must be uploaded in order to complete this ticket.'
                    }
                ]
            }),
        ]);

        return {
            getRequiredFileValidation: (): KnockoutObservable<any> => this.requiredFileValidation,
            addFile: (file: File) => this.autoUpload(file)
        } as app.components.interfaces.CustomerFilesPublicApi;
    }

    constructor(
        private ticketService: TicketService,
        private fileUploader: FileUploader,
        private customerFileService: CustomerFileService,
        private sessionStorageService: SessionStorageService) { }

    ngOnInit(): void {
        Check.isNotEmpty(this.options.customerId, 'Customer ID is required');
        Check.isNotEmpty(this.options.fileSectionTypeId, 'File Section ID is required');
        
        forkJoin([
            this.sessionStorageService.getUser(),
            this.sessionStorageService.getPartner()
        ]).subscribe(([user, partner]) => {
            this.allowDelete = this.options.canDelete
                && launchpad.hasPermission(partner, user.PartnerUsers, permissions.CanDeleteCustomerFile, user);
            this.allowUpload = this.options.canUpload
                && launchpad.hasPermission(partner, user.PartnerUsers, permissions.CanUploadCustomerFile, user);
        });

        if (this.options.ticketFilteringConfig) {
            this.allowFilteringByTicket = true;
            this.filterByTicket = this.options.ticketFilteringConfig.defaultFilterByTicket;
            this.ticketIdToFilterBy = this.options.ticketFilteringConfig.ticketId;
        } else {
            this.allowFilteringByTicket = false;
            this.filterByTicket = false;
        }
        this.customerId = this.options.customerId;
        this.showUsers = this.options.showUsers ?? true;
        this.isFileUploadRequired = this.options.isFileUploadRequired;

        this.showOnlyCurrentFiles = this.options.showOnlyCurrentFiles;
        this.ticketTypeId = this.options.ticketTypeId || 0;

        this.fileSectionTypes = _.filter(this.fileSectionTypes, (t: FileSectionType) => {
            return _.contains(this.options.fileSectionTypeIds, t.FileSectionTypeId);
        });

        this.fileSectionTypeId = this.options.fileSectionTypeId;

        this.maxNameDisplayLength = this.options.maxNameDisplayLength || 80;
        this.loadFiles();

        if (this.options.publicApiCallback) {
            this.options.publicApiCallback(this.getPublicApi());
        }
    }

    loadFiles(): JQueryPromise<any> {
        return Utils.wrapDfd(
            this.allowFilteringByTicket && this.filterByTicket
                ? this.customerFileService.getManyForTicket(this.customerId, this.fileSectionTypeId, this.ticketIdToFilterBy)
                : this.customerFileService.getMany(this.customerId, this.fileSectionTypeId))
            .then((files: Boo.Objects.CustomerFile[]) => {
                files.forEach(file => {
                    file.Name = reduceLength(file.Name, this.maxNameDisplayLength);
                });
                this.files(files);
                if (this.requiredFileValidation) {
                    this.requiredFileValidation.errors.showAllMessages(false);
                }
            })
            .then(() => {
                if (this.showOnlyCurrentFiles) {
                    return Utils.wrapDfd(this.ticketService.getLastCompleted(this.customerId, this.ticketTypeId))
                        .then((ticketSummary: Boo.Objects.TicketSummary) => {
                            this.latestTicket = ticketSummary;
                            if (!this.latestTicket) {
                                return;
                            }
                            this.handleFiles();
                        });
                }
            })
            .fail((displayMessage: string) => {
                toastr.error(displayMessage);
            });
    }

    autoUpload(file: File): void {
        const preSignedUrlRequest = new PresignedUrlRequest();
        preSignedUrlRequest.BucketName = launchpad.config.S3Buckets.s3staticfiles;
        preSignedUrlRequest.Folder = 'customerfiles/' + this.customerId + '/' + this.fileSectionTypeId;
        preSignedUrlRequest.UseUniqueFileName = true;
        this.fileUploader.upload(file, preSignedUrlRequest.Folder, file.name)
            .then((preSignedUrlResult) => {

                const customerFile: Boo.Objects.CustomerFile = {
                    CustomerId: this.customerId,
                    FileSectionTypeId: this.fileSectionTypeId,
                    UploadedByUserId: this.user.UserId,
                    Name: preSignedUrlResult.FileName,
                    S3Url: preSignedUrlResult.FullUrl,
                    Comment: ''
                } as Boo.Objects.CustomerFile;
                return Utils.wrapDfd(this.customerFileService.insert(customerFile));
            })
            .then((customerFile) => {
                this.updateFileList(customerFile);
            });
    }

    upload(): void {
        const file: Boo.Objects.CustomerFile = {
            CustomerId: this.customerId,
            FileSectionTypeId: this.fileSectionTypeId
        } as Boo.Objects.CustomerFile;

        PriorityViewModel.show('app-components-managecustomer-uploadcustomerfile', { customerFile: file, fileSectionTypes: this.fileSectionTypes })
            .done((uploadResult: Boo.Objects.CustomerFile) => {
                if (!uploadResult) {
                    return;
                }
                this.updateFileList(uploadResult);
            });
    }

    delete(file: Boo.Objects.CustomerFile): void {
        if (!this.allowDelete) {
            throw 'Delete is not allowed';
        }

        file.IsDeleted = true;
        Utils.wrapDfd(this.customerFileService.update(file))
            .then(() => {
                this.loadFiles();
            }).fail((displayMessage: string) => {
                toastr.error(displayMessage);
            });
    }

    setFilterByTicket(enabled: boolean) {
        this.filterByTicket = enabled;
        this.loadFiles();
    }

    private handleFiles(): void {
        if (this.showOnlyCurrentFiles) {
            const latestFiles = this.files().filter(x => x.InsertedDate > this.latestTicket.StatusDate);
            this.files(latestFiles);
        }
    }

    private updateFileList(file: Boo.Objects.CustomerFile): void {
        if (!file) {
            return;
        }
        if (this.filterByTicket) {
            Utils.wrapDfd(this.customerFileService.insertForTicket(file, this.ticketIdToFilterBy))
                .then((customerFile: Boo.Objects.CustomerFile) => {
                    if (file.FileSectionTypeId === file.FileSectionTypeId) {
                        this.files.splice(0, 0, customerFile);
                    }
                });
        } else {
            Utils.wrapDfd(this.customerFileService.insert(file))
                .then((customerFile: Boo.Objects.CustomerFile) => {
                    if (file.FileSectionTypeId === file.FileSectionTypeId) {
                        this.files.splice(0, 0, customerFile);
                    }
                });
        }
    }
}

@if(addingKeyword) {
  <div class="row justify-content-start">
    <div class="col-auto">
      <ng-container *ngTemplateOutlet="keyword"></ng-container>
    </div>
    <div class="col-auto" *ngIf="!customer.IsCampaignNational">
      <ng-container *ngTemplateOutlet="area"></ng-container>
    </div>
    <div class="col-auto">
      <button type="button" class="btn btn-primary" (click)="addKeyword()"><i class="fas fa-plus"></i>Keyword</button>
    </div>
    <div class="col-auto">
      <button type="button" class="btn btn-warning" (click)="toggleAddingKeyword()">Cancel</button>
    </div>
  </div>
}
@else {
  <div class="row">
    <div class="col-12">
      @if (canAddKeywords) {
        <button class="btn btn-primary me-4 btn-sm" type="button" data-toggle="dropdown">
          <i class="fas fa-plus"></i>Keyword
        </button>
        <ul class="dropdown-menu" role="menu">
          <li>
            <button class="btn-link dropdown-item" (click)="toggleAddingKeyword()">Custom Keyword</button>
          </li>
          @if (availableKeywordSuggestions.length) {
            <div class="dropdown-divider"></div>
            <app-components-shared-keyword-research-keyword-suggestion-filter
              [keywordSuggestions]="availableKeywordSuggestions"
              [filteredKeywordSuggestions]="filteredKeywordSuggestions"
              (filterChanged)="filterChanged($event)">
            </app-components-shared-keyword-research-keyword-suggestion-filter>
            @if (filteredKeywordSuggestions.length || filteredHaloKeywordSuggestions.length) {
              <li onclick="event.stopPropagation()">
                <div class="dropdown-item">
                  <span class="keyword-suggestion-keyword"><!-- Keyword --></span>
                  <span class="text-end keyword-suggestion-metrics">
                    <span class="badge keyword-suggestion-volume-badge bg-primary">Volume</span> 
                    <span class="badge keyword-suggestion-competition-badge bg-primary">Competition</span>
                    <span class="badge keyword-suggestion-rank-badge bg-primary">Rank</span>
                  </span>
                </div>
              </li>
            }
            <div class="keyword-suggestion-list">
            <ng-container *ngFor="let keywordSuggestion of filteredKeywordSuggestions | orderBy: keywordSort">
              <li>
                <button class="btn-link dropdown-item"
                  (click)="addKeywordSuggestion(keywordSuggestion)">
                  <div>
                    <span class="keyword-suggestion-keyword">
                      {{ keywordSuggestion.KeywordPhrase }}
                    </span>
                    <span class="text-end keyword-suggestion-metrics">
                      @if (keywordSuggestion.Volume !== null) {
                        <span class="badge keyword-suggestion-volume-badge bg-secondary">{{ keywordSuggestion.Volume }}</span> 
                      }
                      @if (keywordSuggestion.CompetitionDescription) {
                        <span class="badge keyword-suggestion-competition-badge" [ngClass]="{
                          'bg-warning': keywordSuggestion.CompetitionDescription === 'High' || keywordSuggestion.CompetitionDescription === 'Medium',
                          'bg-success': keywordSuggestion.CompetitionDescription === 'Low'}">{{ keywordSuggestion.CompetitionDescription }}</span>
                      }
                      @if (keywordSuggestion.Rank) {
                        <span class="badge keyword-suggestion-rank-badge bg-secondary"
                          [ngClass]="{'bg-warning': keywordSuggestion.Rank <= rankThreshold}">{{ keywordSuggestion.Rank }}</span>
                      }
                    </span>
                  </div>
              </button>
              </li>
            </ng-container>
            <ng-container *ngFor="let haloKeywordSuggestion of filteredHaloKeywordSuggestions | orderBy: keywordSort">
              <li>
                <button class="btn-link dropdown-item"
                (click)="addHaloKeywordSuggestion(haloKeywordSuggestion)">
                <div>
                  <span class="keyword-suggestion-keyword">
                    {{ haloKeywordSuggestion.KeywordPhrase }}
                  </span>
                  <span class="text-end keyword-suggestion-metrics">
                    @if (haloKeywordSuggestion.Volume !== null) {
                      <span class="badge keyword-suggestion-volume-badge bg-secondary">{{ haloKeywordSuggestion.Volume }}</span> 
                    }
                    @if (haloKeywordSuggestion.CompetitionDescription) {
                      <span class="badge keyword-suggestion-competition-badge" [ngClass]="{
                        'bg-warning': haloKeywordSuggestion.CompetitionDescription === 'High' || haloKeywordSuggestion.CompetitionDescription === 'Medium',
                        'bg-success': haloKeywordSuggestion.CompetitionDescription === 'Low'}">{{ haloKeywordSuggestion.CompetitionDescription }}</span>
                    }
                    @if (haloKeywordSuggestion.Rank) {
                      <span class="badge keyword-suggestion-rank-badge bg-secondary"
                        [ngClass]="{'bg-warning': haloKeywordSuggestion.Rank <= rankThreshold}">{{ haloKeywordSuggestion.Rank }}</span>
                    }
                  </span>
                </div>
              </button>
              </li>
            </ng-container>
          </div>
          }
        </ul>
      }
      @else {
        <button class="btn btn-primary me-4 btn-sm display-title-when-disabled"
          type="button" disabled title="Request keyword research before adding halo keywords">
          <i class="fas fa-plus"></i>Keyword
        </button>
      }
    </div>
  </div>
}

<ng-template #keyword>
  <div class="input-group mb-0" [formValidation]="formGroup">
    <span class="input-group-text">Keyword</span>
    <input #keywordInput type="text" class="form-control" [formControl]="keywordControl"
      [formValidation]="keywordControl" (keyup.enter)="addKeyword()"/>
  </div>
</ng-template>

<ng-template #area>
  <div class="input-group mb-0" [formValidation]="formGroup">
    <span class="input-group-text">Location</span>
    <input #areaInput type="text" class="form-control" [formControl]="areaControl"
      [formValidation]="areaControl" (keyup.enter)="addKeyword()" />
  </div>
</ng-template>
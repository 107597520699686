import { Component } from '@angular/core';
import System from 'framework/System';
import BaseComponent from '../BaseComponent';
import { SaveTypes } from 'app/models/enums/SaveTypes';

@Component({
	selector: 'app-components-ticket-features',
	templateUrl: './Features.component.html'
})
export class FeaturesComponent extends BaseComponent {
    public isEnabled: boolean;
    public approvalQuestionText: string;
    public isApprovalRequired: boolean;
    isOpen = true;

    public activate(params: app.ticket.components.interfaces.IFeaturesViewModelActivateParams): JQueryPromise<void> {
        this.isApprovalRequired = params.featureConfig.isApprovalRequired;
        this.approvalQuestionText = params.featureConfig.approvalQuestionText;

        return super.activate(params).then(() => {
            this.isEnabled = this.isApprovalRequired || this.canEdit;
            if (!this.isEnabled) {
                return;
            }

            this.shouldValidateOnUpdate = false;
            this.validation = ko.validatedObservable([
                this.ticket.IsApproved.extend({
                    required: {
                        onlyIf: (): boolean => {
                            return this.isApprovalRequired;
                        }
                    }
                })
            ]);
        });
    }

    public confirmSave(saveType: SaveTypes): JQueryPromise<boolean> {
        if (!this.isEnabled) {
            return System.resolvedPromise(true);
        }
        return saveType === SaveTypes.Complete ? this.confirmApproval() : System.resolvedPromise(true);
    }

    public save(saveType: SaveTypes): JQueryPromise<app.ticket.interfaces.ISaveData | void> {
        return System.emptyPromise();
    }

    protected confirmApproval(): JQueryPromise<boolean> {
        if (!this.isApprovalRequired) {
            return System.resolvedPromise(true);
        }

        return $.Deferred<boolean>((dfd) => {
            const msg = '<strong>This ticket requires approval</strong><br/><br/>You have marked that this ticket has ' +
                (this.ticket.IsApproved() === false ? 'not ' : '') +
                'received this approval.  Please ensure this is correct and then confirm to continue saving.';
            bootbox.confirm(msg, (result: boolean) => {
                dfd.resolve(result === true);
            });
        }).promise();
    }
}

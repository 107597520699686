import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Notification } from '../shared/models/notifications/notifications';

@Injectable({
  providedIn: 'root'
})
export class WordpressWebsiteService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/wordpresswebsites`;

  constructor(private http: HttpClient) { }

  getReportingSummary(customerId: number): Observable<Boo.Objects.WordpressWebsiteReportingSummary> {
    const params = new HttpParams().set('customerId', customerId);
    
    return this.http.get<Boo.Objects.WordpressWebsiteReportingSummary>(`${this.baseUrl}/GetReportingSummary`, { params: params });
  }

  isBuildComplete(customerId: number): Observable<boolean> {
    const params = new HttpParams().set('customerId', customerId);

    return this.http.get<boolean>(`${this.baseUrl}/IsBuildComplete`, { params: params });
  }

  getVendorNotifications(customerId: number): Observable<Notification[]> {
    const params = new HttpParams().set('customerId', customerId);

    return this.http.get<Notification[]>(`${this.baseUrl}/GetVendorNotifications`, { params: params });
  }
}

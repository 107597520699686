import { Component, EventEmitter, Input, Output } from '@angular/core';
import { KeywordResearch, PageSuggestion } from '../../../../shared/models/keywords/keywords';
@Component({
  selector: 'app-components-shared-keyword-research-process-keyword-research',
  templateUrl: './process-keyword-research.component.html',
  styleUrls: ['../keyword-research/keyword-research.component.scss']
})
export class ProcessKeywordResearchComponent {
  @Input() customer: Boo.Objects.Customer;
  @Input() pageSuggestions: PageSuggestion[];
  @Output() keywordReseachRequested = new EventEmitter<void>();

  requestSubmitted = false;

  removeKeywordResearch(keywordResearch: KeywordResearch): void {
    this.pageSuggestions.find(x => x.PendingKeywordResearch === keywordResearch).PendingKeywordResearch = null;
  }

  requestResearch(): void {
    this.requestSubmitted = true;
    this.keywordReseachRequested.emit();
  }

  get pendingKeywordResearch(): KeywordResearch[] {
    return this.pageSuggestions.filter(x => x.IsSelected && x.IsCampaignNational === this.customer.IsCampaignNational)
      .flatMap(x => x.PendingKeywordResearch)
      .filter(x => x != null);
  }
}
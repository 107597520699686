interface KnockoutExtenders {
    percentage: (target: any, option: any) => any;
    numeric: (target: any, option: any) => any;
}

class CustomKnockoutExtenders {
    public static register() {
        ko.extenders.percentage = function (target, options) {
            target.percentage = ko.computed({
                read: function () {
                    const theValue = this() * 100;
                    const precision = (options.precision) ? options.precision : 2;
                    const roundingMultiplier = Math.pow(10, precision);
                    const newValueAsNum = isNaN(theValue) ? 0 : parseFloat(+theValue as any);
                    const valueToWrite = Math.round(newValueAsNum * roundingMultiplier) / roundingMultiplier;
                    return Math.ceil(valueToWrite);
                },
                write: function (value) {
                    this((value as any) / 100);
                },
                owner: target
            });
            return target;
        };

        // Knockout Extender to force numeric values in an observable
        ko.extenders.numeric = function (target, options) {
            //create a writeable computed observable to intercept writes to our observable
            const result = ko.computed({
                read: target, //always return the original observables value
                write: function (newValue) {
                    ko.mapping.toJS(options);

                    const precision = (options.precision) ? options.precision : 0;
                    const minValue = (typeof options.minValue != 'undefined') ? options.minValue : null;
                    const maxValue = (typeof options.maxValue != 'undefined') ? options.maxValue : null;
                    let theValue = newValue;

                    if (minValue != null && theValue < minValue) {
                        theValue = minValue;
                    }
                    if (maxValue != null && theValue > maxValue) {
                        theValue = maxValue;
                    }

                    const current = target();
                    const roundingMultiplier = Math.pow(10, precision);
                    const newValueAsNum = isNaN(theValue as any) ? 0 : parseFloat(+theValue as any);
                    const valueToWrite = Math.round(newValueAsNum * roundingMultiplier) / roundingMultiplier;

                    //only write if it changed
                    if (valueToWrite !== current && current != newValue) {
                        target(valueToWrite);
                    } else {
                        //if the rounded value is the same, but a different value was written, force a notification for the current field
                        if (newValue !== current) {
                            target.notifySubscribers(valueToWrite);
                        }
                    }
                }
            }).extend({ notify: 'always' });

            //initialize with current value to make sure it is rounded appropriately
            result(target());

            //return the new computed observable
            return result;
        };
    }
}
CustomKnockoutExtenders.register();
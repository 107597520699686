import { Component, Input, OnInit } from '@angular/core';
import { BusinessCitationService } from '../../../../services/business-citation.service';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SessionStorageService } from '../../../../services/session-storage.service';
import { DayNamePipe } from '../../../../shared/pipes/dayName.pipe';

import ClaimStatuses from 'app/shared/localprofiles/ClaimStatuses';

type LocalProfileSocialProfile = Boo.Objects.LocalProfiles.LocalProfileSocialProfile;
type BusinessCitationProviderSocialProfileType = Boo.BusinessCitations.Models.BusinessCitationProviderSocialProfileType;

@Component({
  selector: 'app-components-shared-view-local-profile',
  templateUrl: './view-local-profile.component.html'
})
export class ViewLocalProfileComponent implements OnInit {
  @Input() localProfile: Boo.Objects.LocalProfiles.LocalProfile;
  @Input() customer: Boo.Objects.Customer;
 
  isLoading = false;
  staticData: any;
  categories: Boo.BusinessCitations.Models.BusinessCitationProviderCategory[];
  socialProfiles: (LocalProfileSocialProfile & BusinessCitationProviderSocialProfileType)[];
  stateName: string;
  countryName: string;
  googleListingClaimedByStatus: string;
  listingClaimedByStatuses: app.shared.localprofiles.interfaces.IClaimStatus[];
  paymentOptions: string;
  languages: string;
  brands: string;

  constructor(
    private businessCitationService: BusinessCitationService,
    private sessionStorageService: SessionStorageService,
    private dayNamePipe: DayNamePipe) { }

  ngOnInit(): void {
    this.isLoading = true;

    forkJoin({
      categories: this.businessCitationService.getBusinessCitationProviderCategories(this.localProfile.BusinessCitationProviderId),
      providerProfileData: this.businessCitationService.getBusinessCitationProviderProfileData(this.localProfile.BusinessCitationProviderId),
      staticData: this.sessionStorageService.getStaticData()
    }).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe(result => {
      this.categories = result.categories;
      this.socialProfiles = result.providerProfileData.SocialProfileTypes.map(x => {
        return {
          Name: x.Name,
          Url:  this.localProfile.SocialProfiles.find(y => y.Name === x.Name)?.Url ?? null,
          BaseUrl: x.BaseUrl,
          AccountPrefix: x.AccountPrefix,
          PrependBaseUrl: x.PrependBaseUrl
        };
      });
      
      this.paymentOptions = this.localProfile.PaymentOptions.join(', ');
      this.languages = this.localProfile.Languages.join(', ');
      this.brands = this.localProfile.Brands.join(', ');
      this.stateName = result.staticData.States.find(x => x.StateId === this.localProfile.StateId)?.Name ?? '';
      this.countryName = result.staticData.Countries.find(x => x.CountryId === this.localProfile.CountryId)?.Name ?? '';
      this.configureStaticOptions();
      this.googleListingClaimedByStatus = this.listingClaimedByStatuses.find(x => x.ListingClaimedByStatusId === this.localProfile.GoogleListingClaimedByStatusId)?.Name ?? '';
      
    },
      (err) => toastr.error(err)
    );
  }

  businessHourToString(businessHour: Boo.Objects.LocalProfiles.LocalProfileBusinessHour): string {
    if (businessHour.IsClosed) {
     return this.dayNamePipe.transform(businessHour.DayOfWeek) + ': Closed';
    }

    if (businessHour.IsOpen24Hours) {
      return this.dayNamePipe.transform(businessHour.DayOfWeek) + ': Open 24 Hours';
    }

    if (businessHour.From1 && businessHour.To1 && !businessHour.From2 && !businessHour.To2) {
      return `${this.dayNamePipe.transform(businessHour.DayOfWeek)}: ${this.convertTo12Hour(businessHour.From1)} to ${this.convertTo12Hour(businessHour.To1)}`;
    }

    if (businessHour.From1 && businessHour.To1 && businessHour.From2 && businessHour.To2) {
      return `${this.dayNamePipe.transform(businessHour.DayOfWeek)}: ${this.convertTo12Hour(businessHour.From1)} to ${this.convertTo12Hour(businessHour.To1)} and ${this.convertTo12Hour(businessHour.From2)} to ${this.convertTo12Hour(businessHour.To2)}`;
    }

    return `${this.dayNamePipe.transform(businessHour.DayOfWeek)}: No hours specified`;
  }

  specialHourToString(specialHour: Boo.Objects.LocalProfiles.LocalProfileSpecialHour): string {
    const description = specialHour.Description ? ` (${specialHour.Description})` : '';

    if (specialHour.IsClosed) {
     return `${specialHour.Date}${description}: Closed`;
    }

    if (specialHour.IsOpen24Hours) {
      return `${specialHour.Date}${description}: Open 24 Hours`;
    }

    if (specialHour.From1 && specialHour.To1 && !specialHour.From2 && !specialHour.To2) {
      return `${specialHour.Date}${description}: ${this.convertTo12Hour(specialHour.From1)} to ${this.convertTo12Hour(specialHour.To1)}`;
    }

    if (specialHour.From1 && specialHour.To1 && specialHour.From2 && specialHour.To2) {
      return `${specialHour.Date}${description}: ${this.convertTo12Hour(specialHour.From1)} to ${this.convertTo12Hour(specialHour.To1)} and ${this.convertTo12Hour(specialHour.From2)} to ${this.convertTo12Hour(specialHour.To2)}`;
    }

    return '';
  }

  private convertTo12Hour(time: string): string {
    return moment(time, 'HH:mm').format('LT'); 
  }

  private configureStaticOptions() {
    this.listingClaimedByStatuses = [
      {
        ListingClaimedByStatusId: ClaimStatuses.NotClaimed,
        Name: 'Not Claimed'
      },
      {
        ListingClaimedByStatusId: ClaimStatuses.Client,
        Name: 'Client'
      },
      {
        ListingClaimedByStatusId: ClaimStatuses.Partner,
        Name: this.customer.Partner.Name
      }
    ];
  }
}
import { ReservedMinutesNode } from "./ReservedMinutesNode";

export class ReservedMinutesDoublyLinkedList {
  private head: ReservedMinutesNode | null;
  private tail: ReservedMinutesNode | null;
  constructor() {
    this.head = null;
    this.tail = null;
  }

  isEmpty(): boolean {
    return this.head == null;
  } 

  load(seoMinuteReservations: Boo.Objects.Work.SeoMinute.SeoMinuteReservation[]) {
    seoMinuteReservations.forEach(x => {
      this.add(x);
    });
  }

  getSeoMinuteReservations(): Boo.Objects.Work.SeoMinute.SeoMinuteReservation[] {
    const seoMinuteReservations: Boo.Objects.Work.SeoMinute.SeoMinuteReservation[] = [];
    let current = this.head;
    while (current) {
      if (current.reservedMinutes) {
        seoMinuteReservations.push(current.reservedMinutes);
      }
      current = current.next;
    }
    return seoMinuteReservations;
  }

  // Add a new node based on period
  add(reservedMinutes: Boo.Objects.Work.SeoMinute.SeoMinuteReservation) {
    const newNode = new ReservedMinutesNode(reservedMinutes);
    if (!this.head) {
      this.head = newNode;
      this.tail = newNode;
    } else {
      let current = this.head;
      let previous = null;
      while (current && current.reservedMinutes.Period < reservedMinutes.Period) {
        previous = current;
        current = current.next;
      }
      if (!current) { // We're at the end of the list
        this.tail.next = newNode;
        newNode.previous = this.tail;
        this.tail = newNode;
      } else { // We're inserting before the current node
        newNode.next = current;
        newNode.previous = previous;
        if (previous) { // If it's not the head
          previous.next = newNode;
        } else { // If it's the head
          this.head = newNode;
        }
        current.previous = newNode;
        if (newNode.next) { // If it's not the tail
          newNode.next.previous = newNode;
        }
      }
    }
  }
  
  removeByPeriod(period: number) {
    let current = this.head;
    while (current) {
      if (current.reservedMinutes.Period === period) {
        // If it's the head
        if (current === this.head) {
          this.head = current.next;
          if (this.head) {
            this.head.previous = null;
          }
        }
        // If it's the tail
        else if (current === this.tail) {
          this.tail = current.previous;
          if (this.tail) {
            this.tail.next = null;
          }
        }
        // If it's in the middle
        else {
          current.previous.next = current.next;
          current.next.previous = current.previous;
          // Check if the next node becomes the new tail
          if (current.next === null) {
            this.tail = current.previous;
          }
        }
        return;
      }
      current = current.next;
    }
  }

  // Find a node by period
  findByPeriod(periodIndex: number) : ReservedMinutesNode {
    let current = this.head;
    while (current) {
      if (current.reservedMinutes.Period === periodIndex) {
        return current;
      }
      current = current.next;
    }
    return null;
  }

  // Find a node by period
  findQtyByPeriod(periodIndex: number) : number {
    let current = this.head;
    while (current) {
      if (current.reservedMinutes.Period === periodIndex) {
        return current.reservedMinutes?.Quantity || 0;
      }
      current = current.next;
    }
    return null;
  }
}
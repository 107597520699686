import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BlacklistService } from '../../../../app/services/blacklist.service';
import { PartnerPricingToolFeatureTranslationService } from '../../../../app/services/partner-pricing-tool-feature-translation.service';
import { PartnerPricingToolHeadingTranslationService } from '../../../../app/services/partner-pricing-tool-heading-translation.service';
import { TaskTypeTranslationService } from '../../../../app/services/task-type-translation.service';
import { VerticalTranslationService } from '../../../../app/services/vertical-translation.service';
import TranslatedTableType = app.admin.config.TranslatedTableType;

@Injectable({
  providedIn: 'root'
})
export default class TranslatedTableConfig {

    constructor(
      private blacklistService: BlacklistService,
      private PartnerPricingToolFeatureTranslationService: PartnerPricingToolFeatureTranslationService,
      private partnerPricingToolHeadingTranslationService: PartnerPricingToolHeadingTranslationService,
      private taskTypeTranslationService: TaskTypeTranslationService,
      private verticalTranslationService: VerticalTranslationService) { }

    public getExportableTables(): ITranslatedTable[] {
        return [
            ({ name: 'Blacklist', translatedTableType: TranslatedTableType.blacklist } as ITranslatedTable),
            ({ name: 'Categories', translatedTableType: TranslatedTableType.categories } as ITranslatedTable),
            ({ name: 'Partner Pricing Tool Features', translatedTableType: TranslatedTableType.partnerPricingToolFeatures } as ITranslatedTable),
            ({ name: 'Partner Pricing Tool Headings', translatedTableType: TranslatedTableType.partnerPricingToolHeadings } as ITranslatedTable),
            ({ name: 'Task Types', translatedTableType: TranslatedTableType.taskTypes } as ITranslatedTable),
            ({ name: 'Verticals', translatedTableType: TranslatedTableType.verticals } as ITranslatedTable)
        ];
    }
    public getConfig(translatedItemType: TranslatedTableType): ITranslatedTableConfig {
        let result: ITranslatedTableConfig = null;
        switch (translatedItemType) {
            case TranslatedTableType.blacklist:
                result = {
                    service: this.blacklistService,
                    columnData: [
                        { Name: 'Name', Value: 'Name' },
                        { Name: 'Blacklist ID', Value: 'BlacklistId' }
                    ],
                    Name: 'Black_List'
                };
                break;
            case TranslatedTableType.partnerPricingToolFeatures:
                result = {
                    service: this.PartnerPricingToolFeatureTranslationService,
                    columnData: [
                        { Name: 'Feature Translation ID', Value: 'PartnerPricingToolFeatureTranslationId' },
                        { Name: 'Feature ID', Value: 'PartnerPricingToolFeatureId' },
                        { Name: 'Title (English)', Value: 'PartnerPricingToolFeature.Title' },
                        { Name: 'Title', Value: 'Title' },
                        { Name: 'Definition (English)', Value: 'PartnerPricingToolFeature.Definition' },
                        { Name: 'Definition', Value: 'Definition' }
                    ],
                    Name: 'Partner_Pricing_Tool_Features'
                };
                break;
            case TranslatedTableType.partnerPricingToolHeadings:
                result = {
                    service: this.partnerPricingToolHeadingTranslationService,
                    columnData: [
                        { Name: 'Heading Translation ID', Value: 'PartnerPricingToolHeadingTranslationId' },
                        { Name: 'Heading ID', Value: 'PartnerPricingToolHeadingId' },
                        { Name: 'Name (English)', Value: 'PartnerPricingToolHeading.Name' },
                        { Name: 'Name', Value: 'Name' },
                    ],
                    Name: 'Partner_Pricing_Tool_Headings'
                };
                break;
            case TranslatedTableType.taskTypes:
                result = {
                    service: this.taskTypeTranslationService,
                    columnData: [
                        { Name: 'Task Type Translation ID', Value: 'TaskTypeTranslationId' },
                        { Name: 'Task Type ID', Value: 'TaskTypeId' },
                        { Name: 'Name (English)', Value: 'TaskType.Name' },
                        { Name: 'Name', Value: 'Name' },
                        { Name: 'Description (English)', Value: 'TaskType.Description' },
                        { Name: 'Description', Value: 'Description' }
                    ],
                    Name: 'Task_Types'
                };
                break;
            case TranslatedTableType.verticals:
                result = {
                    service: this.verticalTranslationService,
                    columnData: [
                        { Name: 'Vertical Translation ID', Value: 'VerticalTranslationId' },
                        { Name: 'Vertical ID', Value: 'VerticalId' },
                        { Name: 'Name (English)', Value: 'Vertical.Name' },
                        { Name: 'Name', Value: 'Name' }
                    ],
                    Name: 'Verticals'
                };
                break;
            default:
                // Do nothing
                break;
        }
        return result;
    }
}

export interface ITranslatedTableService {
  getMany(languageId: number, includeMissing?: boolean): Observable<any>;
  saveMany(translations: any[]): Observable<void>;
}

export interface ITranslatedTableConfig {
  service: ITranslatedTableService;
  columnData: app.interfaces.ICsvColumn[];
  Name: string;
}

export interface ITranslatedTable {
  name: string;
  translatedTableType: app.admin.config.TranslatedTableType;
}

import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import System from 'framework/System';
import { QaGradingAreaComponent } from '../../../../qa/qa-grading-area/qa-grading-area.component';
import TaskHelper from '../../../../../../Scripts/app/specialist/TaskHelper';
import CustomerAccountTypeGroup from '../../../../../../Scripts/app/customeraccount/CustomerAccountTypeGroup';
import { CompletableWorkspace } from 'app/interfaces/completable-workspace';
import { UnlockableWorkspace } from 'app/interfaces/unlockable-workspace';
import { ClosableWorkspace } from 'app/interfaces/closable-workspace';
import { Subject } from 'rxjs';
import { InternalActionToken } from '../../internal-action.token';
import QaCriteriaGroup = Boo.Qa.QaCriteriaGroup;
import FloatingPanel = app.components.interfaces.FloatingPanel;
import IFloatingTabPanelPublicApi = app.components.interfaces.IFloatingTabPanelPublicApi;
import FloatingTabPanelIcon = app.components.enums.FloatingTabPanelIcon;
import FloatingTabPanelSize = app.components.enums.FloatingTabPanelSize;

@Component({
  selector: 'app-components-specialist-internal-tasks-premium-monthly-campain-update-qa',
  templateUrl: './premium-monthly-campaign-update-qa.component.html'
})
export class PremiumMonthlyCampainUpdateQaComponent implements OnInit, CompletableWorkspace, UnlockableWorkspace, ClosableWorkspace {
  showCompleteButton = false;
  showUnlockButton = true;
  showCloseButton = true;
  complete = new Subject<void>();
  unlock = new Subject<void>();
  close = new Subject<void>();

  @ViewChild('gradingArea')
  gradingArea: QaGradingAreaComponent;
  taskType: Boo.Objects.Enums.TaskTypeEnum = Boo.Objects.Enums.TaskTypeEnum.InternalTaskQaPremiumMonthlyCampaignUpdate;
  qaCriteriaGroups: QaCriteriaGroup[] = [];
  floatingTabPanelApi: IFloatingTabPanelPublicApi;

  partner: string;
  websiteUrl: string;
  taskFiles: Boo.Objects.TaskFile[];
  showTaskFiles: boolean;
  tickets: Boo.Objects.Ticket[];
  customerAccountTypes = CustomerAccountTypeGroup.customImplementationLogins;
  notifyOnCompletion: boolean;
  noteToCsr: string;
  customerId: number;

  private floatingTabPanels: FloatingPanel[];
  private firstClickOnTabPanelComponent = false;

  constructor(@Inject(InternalActionToken) public internalAction: Boo.Objects.InternalAction) { }

  ngOnInit(): void {
    //Create the criteria float panel
    this.floatingTabPanels = [
      {
        title: 'Locations',
        titleIcon: FloatingTabPanelIcon.MapMarker,
        size: FloatingTabPanelSize.Large,
        composition: {
            selector: 'app-components-shared-view-local-profiles',
            model: { customer: this.internalAction.InternalTaskDetails.AssociatedTaskCustomer, showInactiveProfiles: true }
        }
      }
    ];
    ($('#internal-task-qa-criteria-float-panel') as any).floatPanel({ collapsed: false });

    this.qaCriteriaGroups = this.internalAction.InternalTaskDetails.QaCriteriaGroups;

    this.partner = this.internalAction.InternalTaskDetails.PartnerName;
    this.websiteUrl = this.internalAction.InternalTaskDetails.WebsiteUrl.Url;
    this.taskFiles = this.internalAction.InternalTaskDetails.TaskFiles.filter(x => x.TaskFileType !== Boo.Objects.Enums.TaskFileTypeEnum.TaskDefinitionFile);
    this.showTaskFiles = (this.taskFiles?.length ?? 0) > 0;
    this.tickets = this.internalAction.InternalTaskDetails.Tickets;
    this.noteToCsr = TaskHelper.getComment(this.internalAction.InternalTaskDetails.AssociatedTask);
    this.customerId = this.internalAction.InternalTaskDetails.AssociatedTask.CustomerId;
  }

  isReadyToComplete() {
    if (!this.gradingArea.validate()) {
      toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
      return false;
    }

    this.internalAction.InternalTaskDetails.QaCriteriaDetails = this.gradingArea.getDetails();
    this.internalAction.InternalTaskDetails.QaGrade.Comment = this.gradingArea.gradeComment;
    return true;
  }

  // This is a hacky way to integrate the two types of tabpanels and keep a semblance of the functionality the "normal" tabpanel component uses
  increaseLocalTabPanelZIndex(): void {
    const localTabPanel = document.getElementById('internal-task-qa-criteria-float-panel');
    localTabPanel.style.zIndex = document.getElementById(this.floatingTabPanels[0].panelId).style.zIndex;
  }

  // Like above, this is a hacky way to emulate how other tab panels work, this time increasing the z index by 1 (note that z-indexes are stored as strings so + 1 doesn't work)
  // So it's brought to the front on the first click.
  increaseTabPanelComponentZIndex(): void {
    if (!this.firstClickOnTabPanelComponent) {
      document.getElementById(this.floatingTabPanels[0].panelId).style.zIndex = '101'; // Default z-index is 100
      this.firstClickOnTabPanelComponent = true;
    }
  } 

  isStepReadyToReject(): JQueryPromise<any> { return System.emptyPromise(); }

  isStepReadyToSaveAndClose(): JQueryPromise<any> { return System.emptyPromise(); }

  floatingTabPanelApiCallback(api: IFloatingTabPanelPublicApi): void {
    this.floatingTabPanelApi = api;
    this.floatingTabPanels.forEach((floatingTabPanel) => {
      this.floatingTabPanelApi.addPanel(floatingTabPanel);
    });
  }
}

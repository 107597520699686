import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { PartnerTaskTypeExclusionService } from '../../../services/partner-tasktype-exclusion.service';
import { SeoWorkService } from '../../../services/seo-work.service';

@Component({
  selector: 'app-components-manager-partner-tasktype-exclusions',
  templateUrl: './partner-tasktype-exclusions.component.html',
  styleUrls: ['./partner-tasktype-exclusions.component.scss']
})
export class PartnerTaskTypeExclusionsComponent implements OnInit {
  dfd: any;
  isLoading = false;
  title = 'Partner Task Type Exclusions';
  partner: Boo.Objects.PartnerMinimal;
  taskTypeExclusions: Boo.Objects.PartnerTaskTypeExclusion[] = [];
  partnerTaskTypeUsages: Boo.Objects.PartnerTaskTypeUsage[];

  private numberOfCustomerIdsToTake = 10;

  constructor(
    private partnerTaskTypeExclusionService: PartnerTaskTypeExclusionService,
    private seoWorkService: SeoWorkService) { }

  ngOnInit(): void {
    this.isLoading = true;
    forkJoin([
      this.partnerTaskTypeExclusionService.get(this.partner.PartnerId),
      this.seoWorkService.getPartnerTaskTypeUsage(this.partner.PartnerId, this.numberOfCustomerIdsToTake)
    ])
    .pipe(finalize(() => this.isLoading = false))
    .subscribe(([taskTypeExclusions, partnerTaskTypeUsages]) => {
      this.partnerTaskTypeUsages = partnerTaskTypeUsages;
      this.taskTypeExclusions = taskTypeExclusions;
    });
    this.title = `Partner Task Type Exclusions - ${this.partner.PartnerName}`;
  }

  activate(options: any): void {
    this.partner = options.partner;
  }

  close(): void {
    if (this.dfd) {
      this.dfd.resolve();
    }
  }

  save(): void {
    this.isLoading = true;

    this.partnerTaskTypeExclusionService.save(this.partner.PartnerId, this.taskTypeExclusions.map(x => x.TaskTypeId))
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(() => {
        toastr.success(`Partner task type exclusions saved`);
        this.isLoading = false;
      }, (err) => toastr.error(err));
  }

  addExclusion(selectedTaskType: Boo.Objects.PartnerTaskTypeUsage): void {
    this.taskTypeExclusions.push({
      PartnerId: this.partner.PartnerId,
      TaskTypeId: selectedTaskType.TaskTypeId
    } as Boo.Objects.PartnerTaskTypeExclusion);
  }

  removeExclusion(taskTypeId: number): void {
    this.taskTypeExclusions = this.taskTypeExclusions.filter(x => x.TaskTypeId !== taskTypeId);
  }

  getTaskTypeName(taskTypeId: number): string {
    if (!taskTypeId) {
      return;
    }

    return this.partnerTaskTypeUsages.find(x => x.TaskTypeId === taskTypeId).Name;
  }
}
export default class customer {
    public Csr = null as any
    public CsrUserId = 0
    public CampaignEndDate = "0001-01-01T00:00:00"
    public CampaignStartDate = "0001-01-01T00:00:00"
    public Considerations = null as any
    public Country = null as any
    public CountryId = 0
    public CustomerId = 0
    public SeoContentSurvey = null as any
    public Description = null as any
    public Email = null as any
    public GoogleAccessToken = null as any
    public GoogleProfileId = null as any
    public GoogleRefreshToken = null as any
    public Heading = null as any
    public HighProfileDate = "0001-01-01T00:00:00"
    public HighProfileReason = null as any
    public IgnoreKeywordMisspellings = false
    public InsertedDate = "0001-01-01T00:00:00"
    public IsGettingStartedFinished = false
    public IsHighProfile = false
    public IsTopWorkPriority = false
    public Language = null as any
    public LanguageId = 0
    public LastBilledDate = "0001-01-01T00:00:00"
    public MinimumContractLength = 0
    public Name = null as any
    public Partner = null as any
    public PartnerCsr = null as any
    public PartnerCsrUserId = 0
    public PartnerId = 0
    public PartnerName = null as any
    public Phone = null as any
    public Products = null as any
    public MerchantFields = null as any
    public SeoMinutesRollover = 0
    public SalesRep = null as any
    public StatusHistory = null as any
    public StatusId = 0
    public Synopsis = null as any
    public SeoMinutesFree = 0
    public SeoMinutesTotal = 0
    public Team = null as any
    public TeamId = 0
    public TimeZone = null as any
    public UnpauseDate = "0001-01-01T00:00:00"
    public Url = null as any
    public UseTwentyFourHourClock = false
    public Users = null as any
    public VerifyUrlsAreAccessible = false
    public Vertical = null as any
    public VerticalId = 0
    public WebsiteMaintenanceHours = 0
}

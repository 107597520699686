import { Component } from '@angular/core';
import System from 'framework/System';
import IWebsitePageObservable = app.tsmodels.interfaces.IWebsitePageObservable;
import IValidatedResult = app.interfaces.IValidatedResult;
import { WebsiteService } from '../../../services/website.service';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-websites-siteinformation',
  templateUrl: './SiteInformation.component.html'
})
export class SiteInformationComponent implements IActivatable {
    public website: app.tsmodels.interfaces.IWebsiteObservable;
    public pagetypes: Boo.Objects.Websites.WebsitePageType[];
    public data: KnockoutObservableArray<JSTreeNode>;
    public options: JSTreeBindingOptions;
    public selected: KnockoutObservable<IWebsitePageObservable> = ko.observable();
    public selectedCanBeDeleted: KnockoutObservable<boolean>;
    public tree: JSTree;
    public isReadOnly: boolean;
    public pageCount: KnockoutComputed<number>;
    public validationErrors: KnockoutValidationErrors;
    public readonly nameMaxCharacters: number = 256;
    public readonly currentUrlMaxCharacters: number = 512;
    public readonly newUrlMaxCharacters: number = 500;
    public readonly redirects301MaxCharacters: number = 150;
    public readonly uniqueSellingPointsMaxCharacters: number = 1024;
    public readonly featuresMaxCharacters: number = 1024;
    public readonly requirementsMaxCharacters: number = 1024;
    public readonly writerInstructionsMaxLength: number = 1024;
    public readonly builderInstructionsMaxLength: number = 1024;
    private plugins: string[];
    private readonly slug: string = '#';
    private readonly treeMap: Record<string, IWebsitePageObservable> = {};


    constructor(
      private websiteService: WebsiteService,
      private sessionStorageService: SessionStorageService) { }

    public activate(params: app.components.websites.interfaces.IWebsiteComponentActivateParams): JQueryPromise<void> {

        this.selectedCanBeDeleted = ko.observable(false);

        return $.when<any>(
          Utils.wrapDfd(this.websiteService.get(params.website.WebsiteId())),
          Utils.wrapDfd(this.sessionStorageService.getStaticData()))
            .then((website: Boo.Objects.Websites.Website, staticData: Boo.Objects.LaunchPadStaticData) => {
                this.pagetypes = staticData.WebsitePageTypes;
                this.website = ko.mapping.fromJS(website);
                this.pageCount = ko.computed(() => {
                    const websitePageCount = _.filter(this.website.WebsitePages(), (websitePage: IWebsitePageObservable) => {
                        return websitePage.IsActive() && websitePage.IsContent();
                    }).length;
                    return !websitePageCount ? this.website.LegacyPageCount() : websitePageCount;
                });
                this.isReadOnly = params.isReadOnly != null ? params.isReadOnly : true;
                this.plugins = this.isReadOnly ? ['wholerow'] : ['dnd', 'wholerow'];
                this.data = ko.observableArray(this.convertToTreeNodes(this.website.WebsitePages()));
                this.options = {
                    data: this.data,
                    options: {
                        plugins: this.plugins,
                        core: {
                            check_callback: true,
                            multiple: false
                        }
                    },
                    callback: (instance): void => {
                        this.tree = instance;
                    }
                };

                this.website.WebsitePages().forEach(page => {
                    this.setupPageValidation(page);
                });

                this.selected.subscribe(() => {
                    this.calculateCanBeDeleted();
                });

                this.validationErrors = ko.validation.group(this.website.WebsitePages(), { deep: true });
            });
    }

    public addContentPage(): void {
        this.add(true);
    }

    public addNavigation(): void {
        this.add(false);
    }

    public deactivate(): void {
        if (!this.tree) {
            return;
        }

        try {
            this.tree.destroy();
        } catch { 
          // noop
        }
    }

    public delete(): void {
        this.calculateCanBeDeleted();
        if (!this.selectedCanBeDeleted()) {
            return;
        }

        bootbox.confirm('Are you sure you would like to delete this page?', (result: boolean) => {
            if (result) {
                const page = this.selected();
                page.IsActive(false);

                this.selected(null);

                const id = this.treeIdFromPage(page);
                const node = _.findWhere(this.data(), { id: id });

                this.data.remove(node);
            }
        });
    }

    public onMove(callee: any, event: Event, n: { node: { id: string }, parent: string, position: number }): void {
        const current = this.treeMap[n.node.id];
        const parent = this.treeMap[n.parent];

        if (!current) {
            return;
        }

        this.reorder(this.tree.get_json());

        if (parent) {
            const node = _.findWhere(this.data(), { id: n.node.id });

            if (node) {
                node.parent = n.parent;
            }

            current.ParentWebsitePageId(parent.WebsitePageId());
        } else {
            current.ParentWebsitePageId(null);
        }
    }

    public onSelected(caller: any, event: Event, node: { selected: string[], data: any }): void {
        this.selected(null);

        const first = _.first(node.selected);
        this.selected(this.treeMap[first]);
    }

    public save(): void {
        this.validate()
            .then(x => {
                if (!x.isValid) {
                    let errorMessageDisplay = '';
                    for (const errorMessage of x.errorMessages) {
                        errorMessageDisplay = errorMessageDisplay + '<p>' + errorMessage + '</p>';
                    }
                    toastr.error(errorMessageDisplay);
                    return System.resolvedPromise(false);
                }

                const website = ko.mapping.toJS(this.website);
                return Utils.wrapDfd(this.websiteService.save(website));
            })
            .then((x: any) => {
                if (x !== false) {
                    toastr.success('Site Information saved');
                }
            });
    }

    public validate(): JQueryPromise<IValidatedResult> {
        if (this.validationErrors().length > 0) {
            this.validationErrors.showAllMessages(true);

            Object.keys(this.treeMap).forEach((key: any, index: any) => {
                const nodeErrors = ko.validation.group(this.treeMap[key], { deep: true });
                const icon = this.tree.get_icon(key).replace('text-danger', '').trim();
                this.tree.set_icon(key, nodeErrors().length > 0 ? icon + ' text-danger' : icon);
            });
            this.tree.open_all();
        } else {
            this.validationErrors.showAllMessages(false);
        }

        return System.resolvedPromise<IValidatedResult>({ isValid: this.isValid(), errorMessages: this.validationErrors().map((message: KnockoutObservable<string>) => { return message(); }) });
    }

    public isValid(): boolean {
        return this.validationErrors().length === 0;
    }

    private calculateCanBeDeleted(): void {
        const page = this.selected();
        const id = this.treeIdFromPage(page);

        const childNode = _.findWhere(this.data(), { parent: id });
        this.selectedCanBeDeleted(!childNode);
    }

    private add(isContent: boolean): void {
        Utils.wrapDfd(this.websiteService.addPage(this.website.WebsiteId(), isContent))
            .then(x => {
                const page = ko.mapping.fromJS(x);
                this.setupPageValidation(page);
                this.website.WebsitePages.push(page);
                this.validationErrors = ko.validation.group(this.website.WebsitePages(), { deep: true });
                this.data(this.convertToTreeNodes(this.website.WebsitePages()));
            })
            .fail(msg => {
                toastr.error(msg);
            });
    }

    private treeIdFromPage(page: IWebsitePageObservable): string {
        for (const key in this.treeMap) {
            if (!Object.prototype.hasOwnProperty.apply(this.treeMap.hasOwnProperty, key)) {
                continue;
            }

            if (page === this.treeMap[key]) {
                return key;
            }
        }

        return this.slug;
    }

    private convertToTreeNodes(pages: IWebsitePageObservable[]): JSTreeNode[] {
        return pages
            .filter(x => x.IsActive())
            .sort((a, b) => a.Position() - b.Position())
            .map(x => this.convertToTreeNode(x));
    }

    private convertToTreeNode(page: IWebsitePageObservable): JSTreeNode {
        const id = page.WebsitePageId().toString();
        this.treeMap[id] = page;

        page.Name.subscribe(text => {
            const key = this.treeIdFromPage(page);
            this.tree.set_text(key, text);
        });

        return {
            id: id,
            text: page.Name(),
            parent: page.ParentWebsitePageId() ? page.ParentWebsitePageId().toString() : this.slug,
            icon: page.IsContent() ? 'fas fa-file-alt' : 'fa fa-file',
            state: {
                selected: false,
                opened: true,
                disabled: false
            }
        } as JSTreeNode;
    }

    private reorder(nodes: JSTreeNode[]): void {
        for (let i = 0; i < nodes.length; i++) {
            this.treeMap[nodes[i].id].Position(i);

            if (nodes[i].children.length) {
                this.reorder(nodes[i].children);
            }
        }
    }

    private setupPageValidation(page: IWebsitePageObservable): void {
        if (!this.isReadOnly && page.IsActive()) {
            page.Name.extend({
                maxLength: this.nameMaxCharacters
            });

            if (page.IsContent()) {
                page.CurrentUrl.extend({
                    maxLength: this.currentUrlMaxCharacters
                });

                page.NewUrl.extend({
                    maxLength: this.newUrlMaxCharacters
                });

                page.Redirects301.extend({
                    maxLength: this.redirects301MaxCharacters
                });

                page.UniqueSellingPoints.extend({
                    maxLength: this.uniqueSellingPointsMaxCharacters
                });

                page.Features.extend({
                    maxLength: this.featuresMaxCharacters
                });

                page.Requirements.extend({
                    maxLength: this.requirementsMaxCharacters
                });

                page.BuilderInstructions.extend({
                    maxLength: this.builderInstructionsMaxLength
                });

                page.WriterInstructions.extend({
                    maxLength: this.writerInstructionsMaxLength
                });
            }
        }
    }
}

<div [loadingOverlay]="isLoading" class="card">
  <div class="card-header">
    System Notifications
  </div>
  <div class="card-body">
    <app-table [items]="notifications">
      <ng-template #tableHeader>
        <tr>
          <th class="col-10" sort asc field="Name">Type</th>
          <th class="col-1">Templates</th>
          <th class="col-1" sort field="IsActive">Is Active?</th>
        </tr>
      </ng-template>
      <ng-template #tableBody let-item>
        <ng-container>
          <tr>
            <td>{{item.Name}}</td>
            <td>
              <button class="btn btn-info" (click)="openTemplates(item)">
                <span><i class="fa fa-magnifying-glass"></i> View</span>
              </button>
            </td>
            <td>
              <shared-components-customchoice type="checkbox" [(ngModel)]="item.IsActive" [disabled]="true"></shared-components-customchoice>
            </td>
          </tr>
        </ng-container>
      </ng-template>
    </app-table>
  </div>
</div>
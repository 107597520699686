class CustomKnockoutFunctions {
    public static register(): void {
        /**
         * Creates a computed boolean that is true when obj has changed, including any child objects.
         */
        ko.dirtyFlagComputed = (obj: any): KnockoutComputed<boolean> => {
            let initialized = false;

            // one-time dirty flag that gives up its dependencies on first change
            return ko.computed(() => {
                if (!initialized) {
                    // ko.toJS creates computed dependencies on all the obj observables. we don't need the result.
                    ko.toJS(obj);
                    initialized = true;

                    // return NOT dirty on first pass
                    return false;
                }

                // on all subsequent passes, return dirty 
                return true;
            });
        };

        /**
         * Deep clones a ko obj using ko.mapping toJs/fromJs
         */
        ko.clone = (obj: any, mapping?: any): any => {
            const js = ko.mapping.toJS(obj);
            return mapping ? ko.mapping.fromJS(js, mapping) : ko.mapping.fromJS(js);
        };
    }
}
CustomKnockoutFunctions.register();
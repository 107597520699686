import { Component } from '@angular/core';
import PresignedUrlRequest from 'app/models/typescript/PresignedUrlRequest';
import Utils from '../../../shared/utils';
import { AmazonService } from '../../../services/amazon.service';
import { CustomReportService } from '../../../services/custom-report.service';

@Component({
	selector: 'app-components-managecustomer-addcustomreport',
	templateUrl: './AddCustomReport.component.html'
})
export class AddCustomReportComponent {
  public dfd: any;
  public title = 'Add Custom Report';
  // observables
  public isLoading: any = ko.observable(false);
  public customerId: any = ko.observable();
  public name: any = ko.observable().extend({
    required: { message: 'Name is a required field.' }
  });
  public month: any = ko.observable(1);
  public year: any = ko.observable();
  public file: any = ko.observable().extend({
    required: { message: 'File selection is required.' }
  });

  public filename: any = ko.computed(() => {
    let name = '';
    if (this.file()) {
      name = this.file().name;
    }
    return name;
  });
  public availableYears: any = ko.observableArray([]);
  // Public functions


  constructor(
    private amazonService: AmazonService,
    private customReportService: CustomReportService) { }

  public activate(options: any): void {
    this.customerId(options.customerId);
    const years = [];
    for (let i = moment().year(); i >= 2011; i--) {
      years.push(i);
    }
    this.availableYears(years);
    this.year(moment().year());
  }

  public close(): void {
    if (this.dfd) {
      this.dfd.reject();
    }
  }

  public save(): void {
    // we create the custom report object.            
    if (this.pageValidation.isValid()) {
      this.isLoading(true);

      const request: Boo.Objects.Amazon.PreSignedUrlRequest = new PresignedUrlRequest();
      request.BucketName = window.launchpad.config.S3Buckets.s3staticfiles;
      request.Folder = 'customreports';
      request.UseUniqueFileName = true;
      request.ContentType = 'multipart/form-data';
      request.Filename = this.filename();

      if (this.file().type) {
        request.ContentType = this.file().type;
      }
      Utils.wrapDfd(this.amazonService.generatePreSignedUrl(request))
        .then(this.uploadFile.bind(this))
        .then(this.insertCustomReportRecord.bind(this))
        .done(() => {
          this.dfd.resolve();
        }).fail((message) => {
          toastr.error(message);
        }).always(() => {
          this.isLoading(false);
        });
    } else {
      this.pageValidation.errors.showAllMessages();
      toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
    }
  }

  private uploadFile(preSignedUrl: any): any {
    preSignedUrl.filename = this.filename();
    return $.Deferred((dfd) => {
      let contentType = 'multipart/form-data';
      if (this.file().type) {
        contentType = this.file().type;
      }
      $.ajax({
        url: preSignedUrl.PreSignedUploadUrl,
        type: 'PUT',
        cache: false,
        timeout: 300000,
        contentType: contentType,
        processData: false,
        data: this.file(),
        success: () => {
          dfd.resolve(preSignedUrl);
        },
        error: () => {
          dfd.reject('Unable to upload file.');
        }
      });
    });
  }

  private insertCustomReportRecord(uploadResult: any): any {
    const report = {
      Name: this.name(),
      CustomerId: this.customerId(),
      AssignedMonth: this.month(),
      AssignedYear: this.year(),
      Filename: this.filename(),
      S3Url: uploadResult.FullUrl,
    } as Boo.Objects.CustomReport

    return Utils.wrapDfd(this.customReportService.save(report));
  }
  public pageValidation: any = ko.validatedObservable(this);
}

import { Component, Input, AfterViewInit, OnChanges, ViewChild, ElementRef, EventEmitter, Output, OnDestroy } from '@angular/core';

@Component({
  selector: 'shared-components-tree',
  templateUrl: './tree.component.html'
})
export class TreeComponent implements AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('containerElement', { static: true })
  private containerElement: ElementRef;

  @Input()
  public Settings: any;

  @Output()
  public select_node: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public ready: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public move_node: EventEmitter<any> = new EventEmitter<any>();

  private $element: JQuery;

  public ngAfterViewInit(): void {
    this.initPlugin();
  }

  public ngOnChanges(): void {
    this.initPlugin();
  }

  private refreshTree(): void {
    this.$element.jstree(true).settings.core.data = this.Settings.data();
    this.$element.jstree('refresh');
  }

  private initFinished = false;
  private subscription: any;
  private initPlugin(): void {
    this.$element = $(this.containerElement.nativeElement);
    
    if (!this.Settings.data) {
      return;
    }

    if (this.subscription) {
      this.subscription.dispose();
    }

    this.subscription = this.Settings.data.subscribe(() => {
      this.refreshTree();
    });

    if (this.initFinished) {
      this.refreshTree();
      return;
    }

    this.Settings.options = this.Settings.options || {};
    this.Settings.options.core = this.Settings.options.core || {};
    this.Settings.options.core.data = this.Settings.data();
    this.$element.jstree(this.Settings.options);
    if (this.Settings.callback) {
      this.Settings.callback($.jstree.reference(this.$element[0]));
    }
    this.$element.on('select_node.jstree', (function(...args) { this.select_node.emit([this].concat(args)); }).bind(this));
    this.$element.on('ready.jstree', (function(...args) { this.ready.emit([this].concat(args)); }).bind(this));
    this.$element.on('move_node.jstree', (function(...args) { this.move_node.emit([this].concat(args)); }).bind(this));
    this.initFinished = true;
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.dispose();
    }
  }
}
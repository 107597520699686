<div>
	<div id="app">
		<ng-container *ngIf="app">
			<div *ngIf="app.isReadonly() === true && app.priorityViews().length > 0" class="full-width-container">
				<div class="alert alert-danger" style="background-color: #f2ab3a; color: white; border: 1px solid #e89612;">
					<i class="fa fa-lock"></i>
					<strong>Read-only</strong>
					Your current user permission setting does not allow you to modify or update this page.
				</div>
			</div>
			<div *ngIf="app.isHighProfile() === true && app.priorityViews().length > 0" class="full-width-container">
				<div class="alert alert-danger" style="background-color: #f2ab3a; color: white; border: 1px solid #e89612;">
					<strong>
						<i class="fa fa-exclamation"></i>
						High Profile Customer
					</strong>
					<span>{{app.highProfileReason()}}</span>
				</div>
			</div>
			<div *ngIf="app.hasAdultContent() && app.priorityViews().length > 0" class="full-width-container">
				<div class="alert alert-danger" style="background-color: #f2ab3a; color: white; border: 1px solid #e89612;">
					<strong>
						<i class="fa fa-exclamation"></i>
						Adult Content on Website
					</strong>
					<span>ie - nudity, sexual content, provocative images</span>
				</div>
			</div>
			<div [hidden]="!app.showModalContainer()" id="modalContainer" class="full-width-container">
				<ng-container *ngFor="let $data of app.priorityViews(); trackBy: priorityViewTrackBy; let $index = index">
					<compose [disableChangeDetection]="!$data.visible()" [hidden]="!$data.visible()" [Selector]="$data.selector" [ActivateOptions]="$data.options" [Model]="$data.model" [Callback]="$data.callback?.bind(this)" [FailureCallback]="$data.failureCallback?.bind(this)" [HideErrors]="$data.hideErrors"></compose>
				</ng-container>
			</div>
			<div *ngIf="app.showPriorityViewBreadcrumb()" class="full-width-container">
				<div class="alert alert-info" style="max-width:50%; margin-left: auto; margin-right: auto;">
					<strong>
            <i class="fa fa-info-circle"></i>
            You are here:
          </strong>
          <ng-container *ngFor="let $data of app.breadcrumbs(); let last = last;">
            {{ $data }} <i *ngIf="!last" class="fas fa-long-arrow-alt-right"></i>
          </ng-container>
				</div>
			</div>
			<header *ngIf="app.showHeader()">
				<div id="header">
					<ng-container *ngIf="app.headerViewModel()">
            <div *withPermissions="let allow of ['CanViewMyTickets']" class="navbar navbar-expand-xl navbar-dark bg-dark fixed-top d-block py-0">
              <div class="full-width-container">
                <div class="float-start">
                  <button type="button" class="navbar-toggler" data-toggle="collapse" data-target=".navbar-collapse" style="color: white;">
                    <span>
                      <i class="fa fa-bars"></i>
                      Menu
                    </span>
                  </button>
                  <a class="navbar-brand" href="/">
                    <img [attr.src]="app.headerViewModel().logoUrl()" style="max-height: 18px;" class="mt-3"/>
                  </a>
                </div>
                <div class="navbar-collapse collapse">
                  <ul class="navbar-nav">
                    <li *ngIf="app.headerViewModel().isReadonly()" class="nav-item" style="background-color: #f2ab3a;">
                      <a style="cursor: default; color:white;" class="nav-link" href="javascript:void(0);">
                        <i class="fa fa-lock"></i>
                        Read-only
                      </a>
                    </li>
                    <li *ngIf="app.headerViewModel().showTestMessage()" class="nav-item" style="background-color: red;">
                      <a style="cursor: default; color:white;" href="javascript:void(0);" class="nav-link">{{app.headerViewModel().testMessage()}}</a>
                    </li>
                    <li *ngIf="app.headerViewModel().isLoadingVisible()" class="nav-item">
                      <a href="javascript:void(0);">
                        <i class="fa fa-cog fa fa-spin"></i>
                        Loading Navigation...
                      </a>
                    </li>
                    <li *ngIf="app.headerViewModel().isSalesVisible()" class="nav-item">
                      <a href="#/sales/addcustomer" class="nav-link" [class.active]="(app.headerViewModel().activeBasePath() === 'sales')">
                        <i class="fas fa-dollar-sign"></i>
                        <span>{{app.headerViewModel().salesTabText()}}</span>
                      </a>
                    </li>
                    <li *ngIf="app.headerViewModel().isSpecialistVisible()" class="nav-item">
                      <a href="#/specialist/specialistworkspace" class="nav-link" [class.active]="(app.headerViewModel().activeBasePath() === 'specialist')">
                        <i class="fa fa-tasks"></i>
                        Specialist
                      </a>
                    </li>
                    <li *ngIf="app.headerViewModel().isCustomerServiceVisible()" class="nav-item">
                      <a [attr.href]="app.headerViewModel().customerServiceUrl()" class="nav-link" [class.active]="(app.headerViewModel().activeBasePath() === 'customerservice')">
                        <i class="fa fa-phone"></i>
                        Customer Service
                      </a>
                    </li>
                    <li *ngIf="app.headerViewModel().isManagerVisible()" class="nav-item">
                      <a href="#/manager/manageusers" class="nav-link" [class.active]="(app.headerViewModel().activeBasePath() === 'manager')">
                        <i class="fa fa-sitemap"></i>
                        Manager
                      </a>
                    </li>
                    <li *ngIf="app.headerViewModel().isInternalVisible()" class="nav-item">
                      <a href="#/admin/verticalmanagement" class="nav-link" [class.active]="(app.headerViewModel().activeBasePath() === 'admin')">
                        <i class="fas fa-cogs"></i>
                        Admin
                      </a>
                    </li>
                    <li *ngIf="app.headerViewModel().workStoppageTickets().length > 0" class="nav-item">
                      <a class="cursor-pointer" data-toggle="dropdown" class="nav-link pb-0" style="padding-top: 11px;">
                        <span class="fa-stack">
                          <i class="fa fa-tag fa-stack-1x"></i>
                          <i class="fa fa-stack-1x">
                            <span [style.left]="app.headerViewModel().workStoppageTickets().length > 9 ? '12px' : '8px'" style="font-size:55%; left:13px; top:-10px; position:relative;">{{app.headerViewModel().workStoppageTickets().length}}</span>
                          </i>
                        </span>
                      </a>
                      <ul class="dropdown-menu" role="menu">
                        <li role="presentation" class="dropdown-header">
                          Work Stoppage Tickets (showing
                          <span>{{app.headerViewModel().workStoppageTicketsShown()}}</span>
                          of
                          <span>{{app.headerViewModel().workStoppageTickets().length}}</span>
                          )
                        </li>
                        <li role="presentation" class="dropdown-divider"></li>
                        <ng-container *ngFor="let $data of app.headerViewModel().workStoppageTicketsToShow()">
                          <li [attr.title]="($data.CompanyName + ' (' + $data.CustomerId + ') - ' + $data.TicketTypeName)" role="presentation">
                            <a (click)="app.headerViewModel().editTicket($data)" role="menuitem" tabindex="-1" href="javascript:void(0)" class="dropdown-item">
                              <i class="fa fa-tag"></i>
                              <span>{{(($data.CompanyName + ' (' + $data.CustomerId + ') - ' + $data.TicketTypeName).substring(0, 52) + (($data.CompanyName + ' (' + $data.CustomerId + ') - ' + $data.TicketTypeName).length > 52 ? '...' : ''))}}</span>
                              <br/>
                              <h5 class="no-margin">
                                <small [class.text-danger]="$data.DueDate < Date.now()" style="margin-left: 25px;">
                                  SLA Date:
                                  <span>{{$data.DueDate | dateText: 'M/DD/YYYY h:mm a' }}</span>
                                </small>
                              </h5>
                            </a>
                          </li>
                          <li role="presentation" class="dropdown-divider"></li>
                        </ng-container>
                        <li role="presentation">
                          <a role="menuitem" tabindex="-1" href="/#/customerservice/helpnextcustomer" style="text-align: center" class="dropdown-item">Help Next Customer</a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <div [hidden]="!app.headerViewModel().isCustomerServiceVisible() && !app.headerViewModel().isManagerVisible()" class="form-inline navbar-left">
                    <div>
                      <input id="header-search" type="text" class="form-control" placeholder="Search (Ctrl+Shift+F)"/>
                    </div>
                  </div>
                  <ul *ngIf="!app.headerViewModel().isLoadingVisible()" class="navbar-nav ms-auto">
                    <li class="nav-item dropdown">
                      <a href="javascript:void(0)" class="nav-link dropdown-toggle" data-toggle="dropdown">
                        <i class="fa fa-user" style="margin-right: 10px;"></i>
                        <span>{{app.headerViewModel().fullName()}}</span>
                        <b class="caret" style="margin-left: 5px;"></b>
                      </a>
                      <ul class="dropdown-menu">
                        <li>
                          <a href="javascript:void(0)" class="dropdown-item disabled">
                            <span>{{app.headerViewModel().partnerName()}}</span>
                          </a>
                        </li>
                        <li *ngIf="app.headerViewModel().isSwitchPartnerVisible()">
                          <a (click)="app.headerViewModel().switchPartner()" href="javascript:void(0)" class="dropdown-item">
                            <i class="fas fa-exchange-alt"></i>
                            Switch Partner
                          </a>
                        </li>
                        <li class="dropdown-divider"></li>
                        <li *ngIf="app.headerViewModel().isChangePasswordVisible()">
                          <a (click)="app.headerViewModel().changePassword()" href="javascript:void(0)" class="dropdown-item">
                            <i class="fa fa-key"></i>
                            Change Password
                          </a>
                        </li>
                        <li *ngIf="app.headerViewModel().isChangePasswordVisible()" class="dropdown-divider"></li>
                        <li>
                          <a (click)="app.headerViewModel().logOut()" href="javascript:void(0)" class="dropdown-item">
                            <i class="fas fa-sign-out-alt"></i>
                            Logout
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
					</ng-container>
				</div>
			</header>
			<div [hidden]="!app.showContent()" class="full-width-container" style="margin-top: 25px;" id="mainContainer">
				<div id="navigationContainer">
					<compose [disableChangeDetection]="!app.showContent()" [Selector]="app.route().navigationAngularSelector" [ActivateOptions]="app.route().urlFragments" [Callback]="app.route().navigationViewLoadedCallback?.bind(this)"></compose>
				</div>
				<div id="statsContainer">
					<compose [disableChangeDetection]="!app.showContent()" [Selector]="app.route().statsAngularSelector" [ActivateOptions]="app.route().urlFragments" [Callback]="app.route().statsViewLoadedCallback?.bind(this)"></compose>
				</div>
				<div id="main">
					<compose [disableChangeDetection]="!app.showContent()" [Selector]="app.route().contentAngularSelector" [ActivateOptions]="app.route().urlFragments" [Callback]="app.route().contentViewLoadedCallback?.bind(this)"></compose>
				</div>
			</div>
      <div *ngIf="app.showFooter()" class="navbar navbar-dark bg-dark fixed-bottom d-block pt-3" role="navigation">
        <div class="full-width-container">
          <span class="navbar-text">&copy; {{moment().format('YYYY')}} {{app.partnerName()}}. All rights reserved.</span>
          <i *ngIf="app.isNavigating()" class="float-end fa fa-cog fa-spin fa-lg navbar-text"></i>
        </div>
      </div>
		</ng-container>
	</div>
</div>

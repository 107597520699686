import { Component, QueryList, ViewChild, ViewChildren, computed, signal } from '@angular/core';
import System from 'framework/System';
import BaseComponent from '../BaseComponent';
import Check from 'framework/Check';
import IValidatedResult = app.interfaces.IValidatedResult;
import { SaveTypes } from 'app/models/enums/SaveTypes';
import { PublishOptionsComponent } from '../../shared/PublishOptions/PublishOptions.component';
import CustomerHelper from 'app/managecustomer/CustomerHelper';
import { CustomerKeyValuePairService } from '../../../services/customer-keyvaluepair.service';
import Utils from '../../../shared/utils';
import { PreAuditDetailService } from '../../../services/pre-audit-detail.service';
import { TabPanelDisplayMode } from 'app/components/enums/TabPanelDisplayMode';
import { finalize, forkJoin, of, switchMap } from 'rxjs';
import { CustomerAccountGroupComponent } from '../../../shared/components/customer-account-group/customer-account-group.component';

@Component({
    selector: 'app-components-ticket-onsiterecommendations',
    templateUrl: './OnsiteRecommendations.component.html'
})
export class OnsiteRecommendationsComponent extends BaseComponent {
    customer: CustomerObservable;
    isOpen = false;
    canEditContentManagementSystem: boolean;
    contentManagementSystem: Boo.Objects.ContentManagementSystem;
    @ViewChild(PublishOptionsComponent) publishOptionsComponent: PublishOptionsComponent;
    @ViewChildren(CustomerAccountGroupComponent) customerAccountGroups: QueryList<CustomerAccountGroupComponent>;
    @ViewChild('cmsGroup') cmsGroup: CustomerAccountGroupComponent;
    currentResponsibility = signal(Boo.OnsiteRecommendations.Models.Enums.Responsibilities.NotSelected);
    isBoostabilityMakingChangesToTheSite = computed(() => this.currentResponsibility() === Boo.OnsiteRecommendations.Models.Enums.Responsibilities.Boostability);
    isCmsBlacklisted: KnockoutObservable<boolean>;
    isManagedByPartner: KnockoutObservable<boolean>;
    tabPanelDisplayMode = TabPanelDisplayMode;
    hasBlog: boolean;
    cmsAccountTypes = [
        Boo.Objects.Enums.CustomerAccountTypes.CMSCustomerAccess,
        Boo.Objects.Enums.CustomerAccountTypes.FTP
    ];
    websiteAccountTypes = [
        Boo.Objects.Enums.CustomerAccountTypes.DomainRegistrar,
        Boo.Objects.Enums.CustomerAccountTypes.WebsiteHosting
    ];
    blogAccountTypes = [
        Boo.Objects.Enums.CustomerAccountTypes.BlogAdmin,
        Boo.Objects.Enums.CustomerAccountTypes.BlogEditor,
        Boo.Objects.Enums.CustomerAccountTypes.BlogHosting
    ];

    constructor(
        private customerKeyValuePairService: CustomerKeyValuePairService,
        private preAuditDetailService: PreAuditDetailService) { super(); }

    activate(params: app.ticket.components.interfaces.ITicketComponentActivateParams): JQueryPromise<void> {
        this.isLoading(true);
        return super.activate(params)
            .then(() => {
                Check.isNotEmpty(params.customer, 'A customer is required to edit Onsite Recommendation settings.');
                this.isCmsBlacklisted = ko.observable(true);
                this.canEditContentManagementSystem = this.partnerUsers.filter((x: Boo.Objects.PartnerUser) => x.PartnerId === this.customer.PartnerId() || x.PartnerId === Boo.Objects.Enums.PartnerEnum.Boostability)
                    .some((x: Boo.Objects.PartnerUser) => x.UserLevelId === Boo.Objects.Enums.UserLevelEnum.Administrator);
                this.isManagedByPartner = ko.observable(false);
                return Utils.wrapDfd(
                    forkJoin([
                        this.customerKeyValuePairService.get(this.customer.CustomerId()),
                    ])
                    .pipe(
                        switchMap(([keys]) => {
                            this.isManagedByPartner(CustomerHelper.getIsWebsiteManagedByPartner(keys));
                            return of(null);
                        }),
                        finalize(() => this.isLoading(false))
                    )
                )
            });
    }

    contentManagementSystemChanged(cms: Boo.Objects.ContentManagementSystem): void {
        this.contentManagementSystem = cms;
        this.isCmsBlacklisted(this.contentManagementSystem?.IsBlacklisted ?? true);
    }

    save(saveType: SaveTypes): JQueryPromise<app.ticket.interfaces.ISaveData | void> {
        this.isLoading(true);

        return $.when(this.publishOptionsComponent.save())
            .then(() => $.when(...this.customerAccountGroups.toArray().map((x: CustomerAccountGroupComponent) => x.save(saveType))))
            .then(() => this.SaveCustomerCMS())
            .then(() => this.SaveCustomerKeyValuePairIsManagedByPartner())
            .then(() => super.save(saveType));
    }

    SaveCustomerCMS(): JQueryPromise<any> {
        // Only save contentManagementSystem if it has changed and canEditContentManagementSystem
        if (this.contentManagementSystem && this.canEditContentManagementSystem) {
            return Utils.wrapDfd(this.preAuditDetailService.updateContentManagementSystem(this.customer.CustomerId(), this.contentManagementSystem.ContentManagementSystemId));
        }
        return System.emptyPromise();
    }    

    SaveCustomerKeyValuePairIsManagedByPartner(): JQueryPromise<void> {
        return Utils.wrapDfd(this.customerKeyValuePairService.get(this.customer.CustomerId()))
            .then(customerKeyValuePairs => {
                return Utils.wrapDfd(this.customerKeyValuePairService.save(CustomerHelper.setIsWebsiteManagedByPartner(customerKeyValuePairs, this.customer.CustomerId(), this.isManagedByPartner(), this.user.UserId)));
            });
    }

    validate(saveType: SaveTypes): JQueryPromise<IValidatedResult> {
        // validate the children components first, then return one result with all the messages
        let results: IValidatedResult[] = [];
        return $.when(...this.customerAccountGroups.toArray().flatMap((x: CustomerAccountGroupComponent) => x.validate(saveType)))
            .then((...accountResults: IValidatedResult[]) => {
                results = accountResults.concat(this.publishOptionsComponent.validate(saveType));

                if (this.cmsGroup && !this.cmsGroup.components.some(x => x.customerAccount.Url()?.length))
                {
                    results.push({
                        isValid: false,
                        errorMessages: ['Login credentials for CMS or FTP are required if Boostability is making onsite edits for the customer.']
                    });
                }

                if (this.currentResponsibility() === Boo.OnsiteRecommendations.Models.Enums.Responsibilities.Boostability) {
                    if (!this.contentManagementSystem) {
                        results.push({
                            isValid: false,
                            errorMessages: ['Customer CMS is required if Boostability is selected.']
                        })
                    } else if (this.contentManagementSystem.IsBlacklisted) {
                        results.push({
                            isValid: false,
                            errorMessages: ['SEO onsite implementation is not supported for this CMS']
                        })
                    }
                }
                
                return super.validate(saveType);
            })
            .then((ticketValidatedResult) => {
                ticketValidatedResult.isValid = ticketValidatedResult.isValid && results.every(x => x.isValid);
                ticketValidatedResult.errorMessages = ticketValidatedResult.errorMessages.concat(results.flatMap(x => x.errorMessages));

                if (!ticketValidatedResult.isValid) {
                    this.isOpen = true;
                }
                
                return ticketValidatedResult;
            });
    }
}
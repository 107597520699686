import { Component } from '@angular/core';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { PartnerPricingToolDetailService } from '../../../services/partner-pricing-tool-detail.service';
import { PartnerPricingToolHeadingGroupService } from '../../../services/partner-pricing-tool-heading-group.service';
import { PartnerPricingToolPartnerHeadingGroupService } from '../../../services/partner-pricing-tool-partner-heading-group.service';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';

@Component({
	selector: 'app-components-manager-managepartnerpricingtool',
	templateUrl: './ManagePartnerPricingTool.component.html'
})
export class ManagePartnerPricingToolComponent {
    public dfd: any;
    private constants = {
        englishUsLanguageId: 1,
        frenchLanguageId: 2,
        spanishLanguageId: 3,
        germanLanguageId: 6,
        dutchLanguageId: 7,
        frenchCanadaLanguageId: 9
    };
    public isLoading: any = ko.observable(false);
    public title = 'Manage Partner Pricing Tool';
    public partner: any = ko.observable();
    public languages: any = ko.observableArray([]);
    public partnerPricingToolHeadingGroups: any = ko.observableArray([]);
    public userSelectedPartnerPricingToolHeadingGroupIds: any = ko.observableArray([]);
    public partnerPricingToolDetailsList: any = ko.observableArray([]);

    constructor(
      private partnerPricingToolDetailService: PartnerPricingToolDetailService,
      private partnerPricingToolHeadingGroupService: PartnerPricingToolHeadingGroupService,
      private partnerPricingToolPartnerHeadingGroupService: PartnerPricingToolPartnerHeadingGroupService,
      private sessionStorageService: SessionStorageService) { }

    public savePartnerHeadingGroups(): void {
      Utils.wrapDfd(this.partnerPricingToolPartnerHeadingGroupService.replaceAllForPartnerId( this.userSelectedPartnerPricingToolHeadingGroupIds(), this.partner().PartnerId))
        .then(() => {
            toastr.success('Heading Groups were successfully updated');
        })
        .fail((displayMessage) => {
            toastr.error(displayMessage);
        })
        .always(() => {
            this.isLoading(false);
        });
    }

    public editPartnerPricingToolDetail(partnerPricingToolDetail: any): void {
        PriorityViewModel.show('app-components-manager-partnerpricingtooldetail',
            {
                'partner': this.partner(),
                partnerPricingToolDetail: partnerPricingToolDetail
            }).done(this.reloadData.bind(this));
    }

    public deletePartnerPricingToolDetail(partnerPricingToolDetail: any): void {
        bootbox.confirm('Are you sure you want to remove this language?',
            (response: any) => {
                if (response === true) {
                    this.isLoading(true);
                    Utils.wrapDfd(this.partnerPricingToolDetailService.delete(partnerPricingToolDetail.PartnerPricingToolDetailId()))
                    .then(() => {
                        toastr.success('Language has been removed.');
                        this.reloadData();
                    })
                    .fail((displayMessage) => {
                        toastr.error(displayMessage, 'Error');
                    })
                    .always(() => {
                        this.isLoading(false);
                    });
                }
            });
    }

    public addPartnerPricingToolDetail(partnerPricingToolDetail: any): void {
        if (partnerPricingToolDetail.PartnerPricingToolDetailId() <= 0) {
            Utils.wrapDfd(this.partnerPricingToolDetailService.save(ko.toJS(partnerPricingToolDetail)))
            .then((newPartnerPricingToolDetail: any) => {
                partnerPricingToolDetail.PartnerPricingToolDetailId(newPartnerPricingToolDetail.PartnerPricingToolDetailId);
                partnerPricingToolDetail.InsertedDate(newPartnerPricingToolDetail.InsertedDate);
                this.editPartnerPricingToolDetail(partnerPricingToolDetail);
            }).fail((displayMessage) => {
                toastr.error(displayMessage);
            }).always(() => {
                this.isLoading(false);
            });
        }
    }

    public close(): void {
        if (this.dfd) {
            this.dfd.reject();
        }
    }

    private findLanguage(languages: any, languageId: any): any {
        return _.find(languages,
            (language: any) => {
                return language.LanguageId === languageId;
            });
    }

    private reloadData(): void {
        this.isLoading(true);
        this.languages([]);
        this.partnerPricingToolDetailsList([]);
        $.when<any>(
          Utils.wrapDfd(this.partnerPricingToolDetailService.getByPartnerId(this.partner().PartnerId)),
          Utils.wrapDfd(this.sessionStorageService.getStaticData()))
          .then((partnerPricingToolDetails, staticData) => {
            const englishLanguage = this.findLanguage(staticData.Languages, this.constants.englishUsLanguageId);
            englishLanguage.Name = 'English';
            this.languages().push(englishLanguage);
            const frenchLanguage = this.findLanguage(staticData.Languages, this.constants.frenchLanguageId);
            this.languages().push(frenchLanguage);
            const frenchCanadaLanguage = this.findLanguage(staticData.Languages, this.constants.frenchCanadaLanguageId);
            this.languages().push(frenchCanadaLanguage);
            const germanLanguage = this.findLanguage(staticData.Languages, this.constants.germanLanguageId);
            this.languages().push(germanLanguage);
            const dutchLanguage = this.findLanguage(staticData.Languages, this.constants.dutchLanguageId);
            this.languages().push(dutchLanguage);
            const spanishLanguage = this.findLanguage(staticData.Languages, this.constants.spanishLanguageId);
            this.languages().push(spanishLanguage);
            _.each(this.languages(),
                (language: any) => {
                    let matchingPartnerPricingToolDetails = _.find(partnerPricingToolDetails,
                        (detail: any) => {
                            return detail.LanguageId === language.LanguageId;
                        });
                    if (_.isObject(matchingPartnerPricingToolDetails) === false) {
                        matchingPartnerPricingToolDetails = {
                          CustomMessage: null,
                          InsertedDate: null,
                          Language: language,
                          LanguageId: language.LanguageId,
                          LogoS3Url: null,
                          PartnerId: this.partner().PartnerId,
                          PartnerPricingToolDetailId: 0,
                          ProposalDocumentS3Url: null,
                          ProposalEmailAddress: null,
                          ShowCustomMessage: false,
                          ShowCustomProposal: false,
                          PartnerPricingToolCustomProposalTypeId: null,
                          SalesSupportContactNumber: null,
                          AllowAllPriceLevels: false,
                          ShowPrices: false,
                          ShowInfoNavigation: true,
                        } as Boo.Objects.SEOPresence.PartnerPricingToolDetail;
                    }
                    this.partnerPricingToolDetailsList().push(ko.mapping.fromJS(matchingPartnerPricingToolDetails));
                });
            this.partnerPricingToolDetailsList.valueHasMutated();

            $.when<any>(
              Utils.wrapDfd(this.partnerPricingToolHeadingGroupService.get()),
              Utils.wrapDfd(this.partnerPricingToolPartnerHeadingGroupService.getByPartnerId(this.partner().PartnerId)))
              .then((headingGroups, partnerHeadingGroups) => {
                this.partnerPricingToolHeadingGroups(headingGroups);
                this.userSelectedPartnerPricingToolHeadingGroupIds(_.pluck(partnerHeadingGroups, 'PartnerPricingToolHeadingGroupId'));
              })
              .fail((message) => {
                toastr.error(message);
              })
              .always(() => {
                  this.isLoading(false);
              });
        }).fail((message) => {
            toastr.error(message);
        }).always(() => {
            this.isLoading(false);
        });
    }

    public activate(options: any): void {
        this.partner(options.partner);
        this.reloadData();
    }
}

import { Component } from '@angular/core';
import Check from 'framework/Check';
import { CustomerQuickUrlContentAnalysisService } from '../../../../../services/customer-quick-url-content-analysis.service';
import Utils from '../../../../../shared/utils';
import IOnsiteRecommendationActivationParams = app.components.interfaces.IOnsiteRecommendationActivationParams;
import IOnsiteRecommendationPublicApi = app.components.interfaces.IOnsiteRecommendationPublicApi;
import OnsiteRecommendationConstants from '../../../../../shared/onsite-recommendation-constants';

@Component({
  selector: 'app-components-specialist-actioncomponents-components-onsiterecommendation',
  templateUrl: './OnsiteRecommendation.component.html'
})
export class OnsiteRecommendationComponent {
    public descriptionMaxCharacterCount = OnsiteRecommendationConstants.MetaDescriptionMaxLength;
    public titleMaxCharacterCount = OnsiteRecommendationConstants.TitleMaxLength;
    public h1MaxCharacterCount: number = OnsiteRecommendationConstants.H1MaxLength;
    public h2MaxCharacterCount = 500;
    public googleSearchUrl: string;
    public isReadOnly: boolean;
    public keywords: KnockoutObservableArray<Boo.Objects.KeywordSiteAnalysis> = ko.observableArray([]);
    public recommendation: OnsiteRecommendationObservable;

    private validation: KnockoutObservable<any>;

    constructor(private customerQuickUrlContentAnalysisService: CustomerQuickUrlContentAnalysisService) { }

    public activate(params: IOnsiteRecommendationActivationParams): JQueryPromise<void> {
        Check.isNotNull(params.onsiteRecommendation, 'Onsite recommendation cannot be empty');

        this.isReadOnly = params.isReadOnly;
        this.recommendation = ko.mapping.fromJS(params.onsiteRecommendation);
        this.googleSearchUrl = params.googleSearchUrl;

        if (params.isValidated) {
            this.recommendation.TitleNew.extend({
                required: true,
                maxLength: OnsiteRecommendationConstants.TitleMaxLength,
                validation: { validator: (value: string): boolean => this.keywordIsPresent(value), message: 'Keyword must be present in the New Title Text.' }
            });

            this.recommendation.DescriptionNew.extend({
                required: true,
                maxLength: OnsiteRecommendationConstants.MetaDescriptionMaxLength,
                validation: { validator: (value: string): boolean => this.keywordIsPresent(value), message: 'Keyword must be present in the New Description Text.' }
            });

            this.recommendation.H1New.extend({
                required: true,
                maxLength: OnsiteRecommendationConstants.H1MaxLength
            });

            this.recommendation.H2New.extend({
                maxLength: this.h2MaxCharacterCount
            });
        }

        this.validation = ko.validatedObservable([
            this.recommendation.TitleNew,
            this.recommendation.DescriptionNew,
            this.recommendation.H1New,
            this.recommendation.H2New
        ]);

        return Utils.wrapDfd(this.customerQuickUrlContentAnalysisService.process(params.websiteUrlId)).then((analysis: Boo.Objects.QuickUrlContentAnalysis) => {
            this.keywords(analysis.Keywords);
            this.recommendation.TitleOld(analysis.CurrentTitle);
            this.recommendation.DescriptionOld(analysis.CurrentDescription);
            if (analysis.CurrentHeadingTags) {
                this.recommendation.H1Old(
                    _.pluck(
                        _.sortBy(
                            analysis.CurrentHeadingTags,
                            (heading: Boo.Objects.Web.Elements.Header) => { return heading.HtmlTag; }),
                        'HtmlTag')
                        .join('\r\n'));
            }
            if (params.apiCallback) {
                params.apiCallback(this.getPublicApi());
            }
        });
    }

    protected getPublicApi(): IOnsiteRecommendationPublicApi {
        return {
            getOnsiteRecommendation: (): Boo.Objects.OnsiteRecommendation => this.getOnsiteRecommendation(),
            getKeywordInformation: (): any => this.getKeywordInformation(),
            getCompleteKeywordInformation: (): Boo.Objects.KeywordSiteAnalysis[] => this.getCompleteKeywordInformation(),
            isValid: (): boolean => this.isValid(),
            showAllErrorMessages: (): void => this.showAllErrorMessages(),
            hideAllErrorMessages: (): void => this.hideAllErrorMessages(),
            getErrorMessages: (): string[] => this.getErrorMessages()
        };
    }

    protected getOnsiteRecommendation(): Boo.Objects.OnsiteRecommendation {
        return ko.mapping.toJS(this.recommendation);
    }

    protected getKeywordInformation(): any {
        const trimmedValue = this.cleanAndTrimText(this.recommendation.TitleNew());
        const match = _.last(_.filter(this.keywords(), (keywordSiteAnalysis: Boo.Objects.KeywordSiteAnalysis) => {
            const word = this.cleanAndTrimText(keywordSiteAnalysis.Keyword);
            const area = this.cleanAndTrimText(keywordSiteAnalysis.Area);
            return trimmedValue.indexOf(word) >= 0 && (!area || trimmedValue.indexOf(area) >= 0);
        }));

        return { KeywordId: match ? match.KeywordId : null, AreaId: match && match.AreaId > 0 ? match.AreaId : null, IsAreaFirst: match ? match.IsAreaFirst : false };
    }

    protected getCompleteKeywordInformation(): Boo.Objects.KeywordSiteAnalysis[] {
        return this.keywords();
    }

    protected isValid(): boolean {
        return this.validation.isValid();
    }

    protected showAllErrorMessages(): void {
        this.validation.errors.showAllMessages(true);
    }

    protected hideAllErrorMessages(): void {
        this.validation.errors.showAllMessages(false);
    }

    protected getErrorMessages(): string[] {
        return ko.mapping.toJS(this.validation.errors());
    }

    private keywordIsPresent(value: string): boolean {
        const trimmedValue = this.cleanAndTrimText(value);
        return _.any(this.keywords(), (keywordSiteAnalysis: Boo.Objects.KeywordSiteAnalysis) => {
            const word = this.cleanAndTrimText(keywordSiteAnalysis.Keyword);
            const area = this.cleanAndTrimText(keywordSiteAnalysis.Area);
            return trimmedValue.indexOf(word) >= 0 && (!area || trimmedValue.indexOf(area) >= 0);
        });
    }

    private cleanAndTrimText(value: string): string {
        return $.trim(value).toLowerCase().replace(/[^\w ]|[_]+/g, '');
    }
}

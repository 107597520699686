import { Component, Input } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { DudaSsoService } from '../../../services/duda-sso.service'


@Component({
  selector: 'app-duda-site-admin-sso-button',
  templateUrl: './DudaSiteAdminSSOButton.component.html'
})
export class DudaSiteAdminSSOButtonComponent {
  @Input() showSiteInfo: boolean;

  isLoading = false;
  ssoUrlForAdmin: string = null;

  constructor(private dudaSeoService: DudaSsoService) { }

  loadDudaSsoUrlForThryv(navigate = false) {
    this.isLoading = true;
    this.dudaSeoService.getSsoUrlForThryv().pipe(finalize(() => this.isLoading = false))
      .subscribe(
        (url) => {
          this.ssoUrlForAdmin = url;
          if (navigate) {
            this.signOnWithSsoUrlForAdmin();
          }
        },
        () => toastr.error("An error occurred, unable to retreave single sign-on URL."));
  }

  signOnWithSsoUrlForAdmin() {
    if (this.ssoUrlForAdmin) {
      window.open(this.ssoUrlForAdmin);
    } else {
      this.loadDudaSsoUrlForThryv(true);
    }
  }
}
import { Component } from '@angular/core';
import System from 'framework/System';
import ITodoListObservable = app.tsmodels.interfaces.ITodoListObservable;
import ITodoObservable = app.tsmodels.interfaces.ITodoObservable;
import TodoStatus = Boo.Objects.Todo.Enums.TodoStatus;
import ITodoGroupObservable = app.tsmodels.interfaces.ITodoGroupObservable;
import ITodoGroupConfigurationObservable = app.tsmodels.interfaces.ITodoGroupConfigurationObservable;
import ITodoNoteObservable = app.tsmodels.interfaces.ITodoNoteObservable;

@Component({
	selector: 'app-components-todo-readonlytodolistlegacy',
	templateUrl: './ReadonlyTodoListLegacy.component.html'
})
export class ReadonlyTodoListLegacyComponent {
    public window = window;
    public launchpad = launchpad;
    public selectedTodo: KnockoutObservable<ITodoObservable>;
    public todoGroups: KnockoutObservableArray<ITodoGroupObservable>;
    public todoLists: KnockoutObservableArray<ITodoListObservable>;
    public todos: KnockoutObservableArray<ITodoObservable>;
    public todoModalId: string;
    public userId: number;
    public statusMap: any = {
      [TodoStatus.New]: {
        class: 'text-default',
        icon: 'far fa-fw fa-circle',
        helpText: ''
      },
      [TodoStatus.Complete]: {
        class: 'text-success',
        icon: 'fas fa-fw fa-check-circle',
        helpText: 'Completed'
      },
      [TodoStatus.Incomplete]: {
        class: 'text-warning',
        icon: 'fas fa-fw fa-minus-circle',
        helpText: 'Not Completed'
      }
    };

    public activate(params: ITodoListViewModelActivationParams): JQueryPromise<void> {

        this.todoLists = params.todoLists;
        this.userId = params.userId;
        this.todoModalId = _.uniqueId('readonlyTodoModal_');
        this.selectedTodo = ko.observable();

        this.todos = ko.observableArray(_.chain(this.todoLists())
            .map((x: ITodoListObservable) => { return x.Todos(); })
            .map((y: ITodoObservable[]) => { return y; })
            .flatten()
            .filter(x => {
              if (params.isCustomerTodo) {
                return x.Status() !== TodoStatus.Incomplete;
              } else {
                return x.Status() !== TodoStatus.New;
              }
            })
            .sortBy((z: ITodoObservable) => { return z.SortOrder(); })
            .value());

        this.todoGroups = ko.observableArray([]);

        const tempTodoGroups = _.chain(this.todos())
            .filter(todo => !!todo.TodoGroupId())
            .map(todo => ko.utils.unwrapObservable(todo.TodoGroup))
            .uniq(todoGroup => todoGroup.TodoGroupId())
            .value();

        if (tempTodoGroups.length > 0) {

            const configs = ko.mapping.toJS(_.chain(this.todoLists())
                .map((x: ITodoListObservable) => { return x.TodoGroupConfigurations; })
                .map((y: KnockoutObservableArray<ITodoGroupConfigurationObservable>) => { return y(); })
                .flatten()
                .value());

            const sortArray = _.chain(configs)
                .groupBy('TodoGroupId')
                .map((value, key) => {
                    return {
                        'todoGroupId': Number(key),
                        'sortOrder': _.chain(value)
                            .pluck('SortOrder')
                            .reduce((a: number, b: number) => { return a + b; }, 0)
                            .value()
                    };
                })
                .sortBy('sortOrder')
                .pluck('todoGroupId')
                .value();

            this.todoGroups(tempTodoGroups.sort((a,b) => sortArray.indexOf(a.TodoGroupId()) - sortArray.indexOf(b.TodoGroupId())));
        }

        return System.emptyPromise();
    }

    public todoNoteCount(todo: ITodoObservable): number {
        return _.filter(todo.TodoNotes(), (note: ITodoNoteObservable) => {
            return note.InsertedUserId() !== launchpad.config.systemAdminUserId;
        }).length;
    }

    public openTodo(todo: ITodoObservable): void {
        $('#' + this.todoModalId).modal({
            backdrop: 'static',
            keyboard: false,
            show: true
        });
        this.selectedTodo(todo);
    }

    public setTodoClass(todo: any): string {
      return todo.Delegated() ? 'text-purple' : this.statusMap[todo.Status()].class;
    }

    public setTodoIcon(todo: any): string {
      return todo.Delegated() ? 'fas fa-chevron-circle-right' : this.statusMap[todo.Status()].icon;
    }

    public setTodoHelpText(todo: any): string {
      return todo.Delegated() ? 'Delegated' : this.statusMap[todo.Status()].helpText;
    }
}

export interface ITodoListViewModelActivationParams {
    todoLists: KnockoutObservableArray<ITodoListObservable>;
    userId: number;
    isCustomerTodo: boolean;
}

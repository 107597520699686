import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class LeadSourceService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/leadsources`;

  constructor(private http: HttpClient) { }

  get(): Observable<Boo.Objects.LeadSource[]> {
    return this.http.get<Boo.Objects.LeadSource[]>(this.baseUrl);
  }

  save(leadSource: Boo.Objects.LeadSource): Observable<any> {
    return this.http.post<void>(this.baseUrl, leadSource)
  }

  delete(leadSourceId: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${leadSourceId}`);
  }

  getCategories(): Observable<Boo.ApisExternal.Store.Objects.Category[]> {
    return this.http.get<Boo.ApisExternal.Store.Objects.Category[]>(`${this.baseUrl}/GetCategories`);
  }
}

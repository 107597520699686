import { Component, AfterViewInit } from '@angular/core';
import System from 'framework/System';
import ITodoListObservable = app.tsmodels.interfaces.ITodoListObservable;
import ITodoObservable = app.tsmodels.interfaces.ITodoObservable;
import IValidatedResult = app.interfaces.IValidatedResult;
import IValidationPublicApi = app.interfaces.IValidationPublicApi;
import TodoStatus = Boo.Objects.Todo.Enums.TodoStatus;
import TodoFactory from '../../../factories/TodoFactory';

@Component({
	selector: 'app-components-todo-createtodolistlegacy',
	templateUrl: './CreateTodoListLegacy.component.html'
})
export class CreateTodoListLegacyComponent implements AfterViewInit {
    public window = window;
    public launchpad = launchpad;
    public selectedTodo: KnockoutObservable<ITodoObservable>;
    public todoList: ITodoListObservable;
    public todos: KnockoutObservableArray<ITodoObservable>;
    public todoModalId: string;
    public validationApi: IValidationPublicApi;
    public validationErrors: KnockoutValidationErrors;
    public userId: number;

    private todoFactory: TodoFactory = new TodoFactory();

    public ngAfterViewInit(): void {
        window.scrollTo(0, 0);
    }

    public activate(params: ITodoListCreateViewModelActivationParams): JQueryPromise<void> {
        this.todoList = params.todoList;
        this.todos = this.todoList.Todos;

        this.todoModalId = _.uniqueId('createTodoModal_');
        this.selectedTodo = ko.observable();
        this.validationApi = params.validationApi;

        this.userId = params.userId;

        this.todos()
            .forEach(todo => {
                this.attachValidationRules(todo);
            });


        if (_.filter(this.todos(), (todo: ITodoObservable) => { return todo.Status() === TodoStatus.New; }).length === 0) {
            this.todoList.Todos.push(this.getNewTodo());
        }

        this.validationErrors = ko.validation.group(this.todos, { deep: true });

        if (this.validationApi) {
            this.validationApi.add(
                {
                    isValid: this.isValid.bind(this),
                    validate: this.validate.bind(this)
                },
                this.todoModalId);
        }

        return System.emptyPromise();
    }

    public attachValidationRules(todo: ITodoObservable): void {
        todo.Content.extend(
            {
                maxLength: 400
            });
    }

    public isValid(): boolean {
        return this.validationErrors().length === 0;
    }

    public validate(): JQueryPromise<IValidatedResult> {
        // delete empty todos
        this.todos(_.filter(this.todos(), (todo: ITodoObservable) => {
            return todo.Content().trim() !== '';
        }));

        if (this.validationErrors().length > 0) {
            this.validationErrors.showAllMessages(true);
        }

        return System.resolvedPromise<IValidatedResult>({ isValid: this.isValid(), errorMessages: this.validationErrors().map((message: KnockoutObservable<string>) => { return message(); }) });
    }

    public onEnter(index: any, event: any): boolean {
        if (event.keyCode === 13) {
            this.todoList.Todos.splice(index + 1, 0, this.getNewTodo());
            return false;
        }
        return true;
    }

    public add(): void {
        this.todoList.Todos.push(this.getNewTodo());
    }

    public delete(index: any): void {
        this.todoList.Todos.splice(index, 1);
        this.handleEmptyList();
    }

    public openTodo(todo: ITodoObservable): void {
        this.selectedTodo(todo);
        $('#' + this.todoModalId).modal({
            backdrop: 'static',
            keyboard: false,
            show: true
        });
    }

    private handleEmptyList(): void {
        if (!_.some(this.todoList.Todos(), (todo: ITodoObservable) => { return todo.Status() !== Boo.Objects.Todo.Enums.TodoStatus.Complete; })) {
            this.todoList.Todos.push(this.getNewTodo());
        }
    }

    private getNewTodo(): ITodoObservable {
        const newTodo = this.todoFactory.createLegacy(this.todoList.TodoListId(), this.userId);
        this.attachValidationRules(newTodo);
        return newTodo;
    }
}

export interface ITodoListCreateViewModelActivationParams {
    todoList: ITodoListObservable;
    userId: number;
    validationApi?: IValidationPublicApi;
}

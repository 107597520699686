export default class customernote {
    public ContactTypeId = 0
    public ContactTypeName = null as any
    public CustomerId = 0
    public CustomerNoteId = 0
    public Duration = 0
    public FollowupDate = "0001-01-01T00:00:00" as any
    public InsertedDate = "0001-01-01T00:00:00" as any
    public PartnerUserId = 0
    public Text = null as any
    public TicketId = 0
    public TicketTypeName = null as any
    public UserFullName = null as any
    public UserId = 0
    public IsSystemGenerated = false
}
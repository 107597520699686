import { AnalyticsService } from './analytics.service';
import ReloadRequiredType = app.services.models.enums.ReloadRequiredType;
import { Injectable, OnInit } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReloadAnalyticsService {
  protected static reloadMessage = 'Reload Message';
  protected static noReloadMessage = 'No Reload Message';
  protected static applicationReloaded = 'Application Reloaded';
  protected static reloadRequired = 'Reload Required';
  private reloadMessageShown: boolean;
  private reloadNeeded: boolean;
  private reloadKey: string;

  constructor(private analyticsService: AnalyticsService) {
    this.reloadMessageShown = false;
    this.reloadNeeded = false;
    this.reloadKey = null;

    window.onbeforeunload = this.onApplicationUnload.bind(this);
  }

  public trackReload(isManualReload?: boolean): void {
    const action = this.reloadMessageShown ? ReloadAnalyticsService.reloadMessage : ReloadAnalyticsService.noReloadMessage;
    const label = isManualReload ? 'Manual Reload' : 'Auto-Reload';
    this.analyticsService.trackEvent(({ category: `${ReloadAnalyticsService.applicationReloaded} - ${this.reloadKey}`, action: action, label: label } as IAnalyticsEvent));
    this.reloadNeeded = false;
    this.reloadMessageShown = false;
  }

  public messageWasShown(): void {
    this.reloadMessageShown = true;
  }

  public trackReloadNeeded(reloadRequiredType: ReloadRequiredType, reloadKey: string): void {
    this.reloadNeeded = true;
    this.reloadKey = reloadKey;

    let action = 'Eventual';
    if (reloadRequiredType === ReloadRequiredType.forced) {
      action = 'Forced';
    }
    this.analyticsService.trackEvent(({ category: `${ReloadAnalyticsService.reloadRequired} - ${this.reloadKey}`, action: action, label: window.location.href } as IAnalyticsEvent));
  }

  private onApplicationUnload(): void {
    if (this.reloadNeeded) {
      const action = this.reloadMessageShown ? ReloadAnalyticsService.reloadMessage : ReloadAnalyticsService.noReloadMessage;
      this.analyticsService.trackEvent(({ category: `${ReloadAnalyticsService.applicationReloaded} - ${this.reloadKey}`, action: action, label: 'Browser Unload' } as IAnalyticsEvent));
      this.reloadNeeded = false;
      this.reloadMessageShown = false;
    }
  }
}
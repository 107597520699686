import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "seoHours"
})
export class SeoHoursPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    const formattedMinutes = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const formattedHours = (value / 60).toFixed(2);
    return formattedHours + ' (' + formattedMinutes + ' mins)';
  }
}
import { Component } from '@angular/core';
import System from 'framework/System';
import Check from 'framework/Check';

/**
 * This class is just  a wrapper that allows an account group to be shown in a modal
 */
@Component({
    selector: 'app-components-managecustomer-customeraccountgroupmodal',
    templateUrl: './customer-account-group-modal.component.html'
})
export class CustomerAccountGroupModalComponent implements IActivatable {
    // Note: The priority view will add a deferred. 
    public dfd: JQueryDeferred<void>;
    public title: string;
    public options: app.managecustomer.components.interfaces.CustomerAccountGroupOptions;

    public activate(params: app.managecustomer.components.interfaces.CustomerAccountGroupOptions): JQueryPromise<void> {
        Check.isNotEmpty(params.title, 'Customer Account Group must have a title');
        this.title = params.title;
        this.options = params;

        this.options.dfd = (this as any).dfd;

        return System.emptyPromise();
    }
}

<div class="card" [loadingOverlay]="isLoading">
  <div class="card-header">
    Notification Type: {{definition.Name}}
    <button (click)="close()" class="btn-close" title="Close"></button>
  </div>
  <div class="card-body">
    <app-notification-template #notificationTemplateEditor
      [(template)]="currentTemplate"
      [draftValues]="definition.DraftValues"
      [history]="selectedHistory()"
      [canEdit]="canEdit()"
      [partnerId]="partnerId"
      [recipient]="recipient"
      [definitionActive]="definition.IsActive"
      (templateSaved)="save($event)"
      (templateCreated)="create($event)"
      (sendNotification)="sendExampleNotification($event)"
      (revertToVersion)="revert($event)"
      (recipientSaved)="saveExampleRecipient($event)">
    </app-notification-template>
    <app-floating-panels>
      <app-floating-panel [displayConfig]="{
        title: 'Templates',
        icon: 'fa-envelope',
        size: 'floating-panel-md',
        badgeCount: definition.Templates.length
      }">
        <div *floatingPanelContent [loadingOverlay]="isLoading">
          <button class="btn btn-info mb-2" (click)="openModal(infoModal)">How are templates used?</button>
          <div class="row">
            <ng-container *ngFor="let template of definition.Templates">
              <div class="col-md-9 py-2"
                [class.bg-info]="template.NotificationTemplateId === currentTemplate().NotificationTemplateId">
                <ul class="list-group">
                  <li class="list-group-item">
                    Partner
                    <span class="float-end">{{template.Partner?.Name || defaultString}}</span>
                  </li>
                  <li class="list-group-item">
                    Channel {{template.ChannelId}}
                    <span class="float-end">{{!!template.Channel ? channels[template.Channel] : defaultString}}</span>
                  </li>
                  <li class="list-group-item">
                    Language
                    <span class="float-end">{{!!template.LanguageId ? template.Language?.Name : defaultString}}</span>
                  </li>
                  <li class="list-group-item">
                    Support Level
                    <span class="float-end">{{template.PartnerSupportLevelId ? template.PartnerSupportLevel?.Name : defaultString}}</span>
                  </li>
                  <li class="list-group-item">
                    Allows Files
                    <span class="float-end">{{template.AllowFiles ? 'Yes' : 'No'}}</span>
                  </li>
                </ul>
              </div>
              <div class="col-md-3 py-2 btn-group-vertical">
                <button class="btn btn-info" (click)="setTemplate(template)" [disabled]="template.NotificationTemplateId === currentTemplate().NotificationTemplateId">
                  <span><i class="fa fa-magnifying-glass"></i> View</span>
                </button>
                @if (canEdit()  && !template.PartnerId) {
                  <button class="btn btn-warning mt-2" (click)="copyTemplate(template)" [disabled]="template.NotificationTemplateId === currentTemplate().NotificationTemplateId">
                    <span><i class="fa fa-clipboard"></i> Copy To Current Template</span>
                  </button>
                }
                @if (partnerId && canEditPartner() && !template.PartnerId)
                {
                  <button class="btn btn-warning mt-2" (click)="createTemplateFrom(template)">
                    <span><i class="fa fa-print"></i> Create From</span>
                  </button>
                }
              </div>
            </ng-container>
          </div>
        </div>
      </app-floating-panel>
      <app-floating-panel [displayConfig]="{
        title: 'History',
        icon: 'fa-folder',
        size: 'floating-panel-md'
      }">
        <div *floatingPanelContent [loadingOverlay]="isLoading">
          <ng-container *ngIf="!currentTemplateHistory.length">
            <div class="text-center">
              No history available.
            </div>
          </ng-container>
          <ng-container *ngIf="currentTemplateHistory.length">
            <div class="row">
              <ng-container *ngFor="let history of currentTemplateHistory">
                <div class="col-md-9 py-2"
                [class.bg-info]="selectedHistory()?.NotificationTemplateHistoryId === history.NotificationTemplateHistoryId">
                  <ul class="list-group">
                    <li class="list-group-item">
                      Title
                      <span class="float-end">{{history.Title}}</span>
                    </li>
                    <li class="list-group-item">
                      Date
                      <span class="float-end">{{history.InsertedDate | dateText}}</span>
                    </li>
                  </ul>
                </div>
                <div class="col-md-3 py-2">
                  <button class="btn btn-info" (click)="selectedHistory.set(history)" [disabled]="selectedHistory()?.NotificationTemplateHistoryId === history.NotificationTemplateHistoryId">
                    <span><i class="fa fa-magnifying-glass"></i> View</span>
                  </button>
                </div>
              </ng-container>
            </div>
          </ng-container>
          <ng-container *ngIf="selectedHistory()">
            <button class="btn btn-danger mt-2" (click)="selectedHistory.set(null)">
              <span><i class="fa fa-undo"></i> Stop Viewing History</span>
            </button>
          </ng-container>
        </div>
      </app-floating-panel>
      <app-floating-panel [hidden]="!definition.DraftValues.length" [displayConfig]="{
        title: 'Available Tags',
        icon: 'fa-clipboard',
        size: 'floating-panel-md',
        badgeCount: definition.DraftValues.length
      }">
        <div *floatingPanelContent>
          <ng-container *ngIf="!definition.DraftValues?.length">
            <div class="text-center">
              No tags configured for this notification.
            </div>
          </ng-container>
          <ng-container *ngIf="definition.DraftValues?.length">
            <div class="mb-2">Tags are Case Insensitive</div>
            <app-table [items]="definition.DraftValues">
              <ng-template #tableHeader>
                <tr>
                  <th class="col-6" sort asc field="Name">Tag</th>
                  <th class="col-6">Type</th>
                </tr>
              </ng-template>
              <ng-template #tableBody let-item>
                <ng-container>
                  <tr>
                    <td>{{item.Name}}</td>
                    <td>{{item.Type}}</td>
                  </tr>
                </ng-container>
              </ng-template>
            </app-table>
          </ng-container>
        </div>
      </app-floating-panel>
    </app-floating-panels>
  </div>
</div>

<ng-template #infoModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Template Information</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <div>
      The template used for a notification is chosen on a best-fit basis. Only templates for the specific channel the notification is being sent from are considered. Templates are chosen based on the following criteria:
    </div>
    <ul class="mt-2">
      <li class="mb-1">
        If a template exists that matches the subscriber's partner and language, that template is used.
      </li>
      <li class="mb-1">
        If no template exists that matches the subscriber's partner and language, the template that matches the subscriber's partner with the default language will be used.
      </li>
      <li class="mb-1">
        If no template exists that matches the subscriber's partner with the default language, the template that matches the subscriber's language with the default partner will be used.
      </li>
      <li>
        If no template exists that matches the subscriber's language with the default partner, the template that matches the default partner and default language will be used.
      </li>
    </ul>
    <div>
      Additionally, templates will only be chosen if their Support Level matches the partner's or is set to Default. If no template matches any of these criteria, the notification will not be sent and the Dev Team will be alerted.
    </div>
  </div>
</ng-template>
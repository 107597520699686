import { Component } from '@angular/core';
import BaseComponent from '../BaseComponent';
import System from 'framework/System';
import timer from 'app/managecustomer/Timer';
import customernote from 'app/models/CustomerNote';
import IValidatedResult = app.interfaces.IValidatedResult;
import { ITicketNoteActivationParams } from '../NoteFloatingPanel/NoteFloatingPanel.component';
import { SaveTypes } from 'app/models/enums/SaveTypes';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';

@Component({
	selector: 'app-components-ticket-note',
	templateUrl: './Note.component.html'
})
export class NoteComponent extends BaseComponent {
    public isEnabled: boolean;
    public noteText: KnockoutObservable<string>;
    public contactTypeId: KnockoutObservable<number>;
    public contactTypes: Boo.Objects.ContactType[] = [];
    public canUpdateAndKeepOpen = true;
    public SaveTypes = SaveTypes;

    constructor(private sessionStorageService: SessionStorageService) {
      super();
    }

    public activate(params: ITicketNoteActivationParams): JQueryPromise<void> {
        return super.activate(params).then(() => {
            this.isEnabled = params.canEdit;
            if (!this.isEnabled) {
                return;
            }

            this.canUpdateAndKeepOpen = params.canUpdateAndKeepOpen;
            this.noteText = ko.observable('');
            this.contactTypeId = ko.observable(undefined); // must be undefined or else validation msg doesn't show sometimes

            // init validation
            this.shouldValidateOnUpdate = true;
            this.validation = ko.validatedObservable<any>([
                this.contactTypeId.extend({
                    required: {
                        message: 'Contact Type selection is required.',
                        onlyIf: (): boolean => { 
                            return ((this.saveType() === SaveTypes.Update && this.contactTypeId() !== Boo.Objects.Enums.ContactTypeEnum.None)
                            || this.saveType() === SaveTypes.Complete);
                        }
                    }
                }),
                this.noteText.extend({
                    required: {
                        message: 'Ticket Note is required.',
                        onlyIf: (): boolean => { 
                            return (this.saveType() === SaveTypes.Update && this.contactTypeId() !== Boo.Objects.Enums.ContactTypeEnum.None)
                            || this.saveType() === SaveTypes.Complete;
                        }
                    },
                    validation: {
                        validator: launchpad.utils.containsNoHtml,
                        message: 'Html tags are not allowed. You cannot have a < followed by a >'
                    }
                })
            ]);

            params.callback({
                validate: (x): JQueryPromise<IValidatedResult> => this.validate(x),
                isValid: (x): boolean => this.isValid(x),
                cancel: (): JQueryPromise<void> => this.cancel(),
                confirmSave: (x): JQueryPromise<boolean> => this.confirmSave(x),
                save: (x): JQueryPromise<app.ticket.interfaces.ISaveData | void> => this.save(x)
            });

            return this.loadContactTypes();
        });
    }

    public save(saveType: SaveTypes): JQueryPromise<app.ticket.interfaces.ISaveData | void> {
        if (!this.isEnabled || saveType === SaveTypes.UpdateWithoutTicketNoteValidation) {
            return System.emptyPromise();
        }

        const note: Boo.Objects.CustomerNote = new customernote()
        note.CustomerId = this.customer.CustomerId();
        note.TicketId = this.ticket.TicketId();
        note.ContactTypeId = this.contactTypeId();

        note.Text = $.trim(this.noteText());
        note.Text = note.Text ? note.Text : 'Ticket Updated';
        note.InsertedDate = moment().toDate();
        note.Duration = timer.getElapsedSecondsSinceLastTicketSaveTime();
        note.UserId = this.partnerUser.UserId;

        this.noteText(null);
        this.contactTypeId(undefined);
        this.validation.errors.showAllMessages(false);

        return System.resolvedPromise(({
            ticket: {
                NewNote: note
            }
        } as app.ticket.interfaces.ISaveData));
    }

    public validate(saveType: SaveTypes): JQueryPromise<IValidatedResult> {
        if (!this.isEnabled) {
            return System.resolvedPromise<IValidatedResult>({ isValid: true, errorMessages: [] });
        }

        return super.validate(saveType);
    }

    protected loadContactTypes(): JQueryPromise<void> {
        return Utils.wrapDfd(this.sessionStorageService.getStaticData())
          .then((staticData: Boo.Objects.LaunchPadStaticData) => {
            this.contactTypes = _.filter(staticData.ContactTypes, (x: Boo.Objects.ContactType) => {
                return x.ContactTypeId !== launchpad.config.ContactTypeEnum.NoteRevision;
            });
        });
    }
}

import { Component } from '@angular/core';
import System from 'framework/System';
import ITodoObservable = app.tsmodels.interfaces.ITodoObservable;
import TodoHistory = Boo.Objects.Todo.TodoHistory;
import TodoStatus = Boo.Objects.Todo.Enums.TodoStatus;
import ITodoNoteObservable = app.tsmodels.interfaces.ITodoNoteObservable;
import TodoNoteFactory from '../../factories/TodoNoteFactory';
import { TodoService } from '../../../app/services/todo.service';
import Utils from '../../../app/shared/utils';

@Component({
  selector: 'app-components-todolegacy',
  templateUrl: './TodoLegacy.component.html'
})
export class TodoLegacyComponent {
    public window = window;
    public launchpad = launchpad;
    public newNote: KnockoutObservable<ITodoNoteObservable>;
    public todo: ITodoObservable;
    public history: KnockoutObservableArray<TodoHistory> = ko.observableArray([]);
    public todoModalId: string;
    public userId: number;
    public validation: KnockoutObservable<any>;
    public isCustomerTodo: KnockoutObservable<boolean> = ko.observable(false);

    public statusMap: any = {
      [TodoStatus.New]: {
        class: 'text-default',
        icon: 'far fa-fw fa-circle',
        helpText: ''
      },
      [TodoStatus.Complete]: {
        class: 'text-success',
        icon: 'fas fa-fw fa-check-circle',
        helpText: 'Completed'
      },
      [TodoStatus.Incomplete]: {
        class: 'text-warning',
        icon: 'fas fa-fw fa-minus-circle',
        helpText: 'Not Completed'
      }
    };

    protected todoNoteFactory: TodoNoteFactory = new TodoNoteFactory();

    private previousState: TodoStatus = null;

    constructor(private todoService: TodoService) { }

    public activate(params: ITodoViewModelActivationParams): JQueryPromise<void> {
        this.newNote = ko.observable();
        this.userId = params.userId;
        this.todo = params.todo;
        this.isCustomerTodo(!!params.isCustomerTodo);
        this.todoModalId = _.uniqueId('todoModal_');
        this.validation = ko.validatedObservable([]);

        return System.emptyPromise();
    }

    public noteCount(): number {
        return _.filter(this.todo.TodoNotes(), (note: ITodoNoteObservable) => {
            return note.InsertedUserId() !== launchpad.config.systemAdminUserId;
        }).length;
    }

    public toggleTodo(): void {
        this.todo.Status(this.todo.Status() !== TodoStatus.New ? TodoStatus.New : TodoStatus.Complete);
        this.todo.StatusDate(moment().utc().toDate());
        this.todo.StatusUserId(this.userId);
    }

    public markTodoIncomplete(): void {
        this.previousState = this.todo.Status();
        this.todo.Status(TodoStatus.Incomplete);
        this.todo.StatusUserId(this.userId);
        this.openTodo();
    }

    public openTodo(): void {
        $('#' + this.todoModalId).bind(this).on('shown.bs.modal', () => {
            this.newNote(null);

            const editableNotes = _.filter(this.todo.TodoNotes(), (todoNote: ITodoNoteObservable) => {
                return todoNote.IsEditable();
            });

            if (editableNotes.length > 0) {
                this.newNote(editableNotes[0]);
            }
            this.checkForSkip();
        }).bind(this);

        $('#' + this.todoModalId).modal({
            backdrop: false,
            keyboard: false,
            show: true
        });
    }

    public hadEditableNote(): boolean {
        return _.some(this.todo.TodoNotes(), (todoNote: ITodoNoteObservable) => {
            return todoNote.IsEditable();
        });
    }

    public checkForSkip(): void {
        if (this.todo.Status() === TodoStatus.Incomplete) {
            this.addNote();
        }
    }

    public addNote(): void {
        if (!this.newNote()) {
            this.newNote(this.todoNoteFactory.createLegacy(this.todo.TodoId(), this.userId));
            this.attachTodoNoteValidation(this.newNote());
            this.todo.TodoNotes.push(this.newNote());
        }

        if (this.newNote().Content().trim() === '' && this.todo.Status() === TodoStatus.Incomplete) {
            this.newNote().Content(this.todo.DefaultIncompleteNote());
        }
    }

    public deleteNote(index: any): void {
        this.todo.TodoNotes.splice(index, 1);
        this.newNote(null);
    }

    public closeTodo(): void {
        if (!this.validation.isValid()) {
            this.validation.errors.showAllMessages();
            return;
        }

        this.deleteEmptyNotes();
        $('#' + this.todoModalId).modal('hide');
    }

    public cancel(): void {
      this.deleteEmptyNotes();
      // this.todo.TodoNotes(this.todo.TodoNotes().filter(x => x.TodoId() === 0))

      if (this.previousState !== null) {
        this.todo.Status(this.previousState);
      }

      this.previousState = null;
      $('#' + this.todoModalId).modal('hide');
    }

    public loadHistory(): void {
        if (this.todo.SourceTodoId() && this.history().length === 0) {
            Utils.wrapDfd(this.todoService.getHistory(this.todo.TodoId()))
                .then((history: TodoHistory[]) => {
                    this.history(history);
                });
        }
    }

    public copyTodo(): void {
      navigator.clipboard.writeText(this.todo.Content())
        .then(() => {
          toastr.success('Copied to clipboard');
        })
        .catch((err) => {
          toastr.error('Failed to copy to clipboard');
          console.error(err);
        });
    }

    public setTodoClass(): string {
      return this.todo.Delegated() ? 'text-purple' : this.statusMap[this.todo.Status()].class;
    }

    public setTodoIcon(): string {
      return this.todo.Delegated() ? 'fas fa-chevron-circle-right' : this.statusMap[this.todo.Status()].icon;
    }

    public setTodoHelpText(): string {
      return this.todo.Delegated() ? 'Delegated' : this.statusMap[this.todo.Status()].helpText;
    }

    private attachTodoNoteValidation(todoNote: ITodoNoteObservable): void {
        todoNote.Content.extend({
            required: {
                message: 'Note is required',
                onlyIf: (): boolean => this.todo.Status() === TodoStatus.Incomplete
            },
            validation: {
                validator: (value: string): boolean => {
                    return value.trim().slice(-1) !== ':';
                },
                message: 'Additional information is required after a colon (:)'
            }
        });
        this.validation = ko.validatedObservable([todoNote.Content]);
    }

    private deleteEmptyNotes(): void {
      this.todo.TodoNotes(_.filter(this.todo.TodoNotes(), (todoNote: ITodoNoteObservable) => {
        return todoNote.Content().trim() !== '';
      }));
    }
}

export interface ITodoViewModelActivationParams {
    todo: ITodoObservable;
    userId: number;
    isCustomerTodo: boolean;
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-components-specialist-actioncomponents-comment',
  templateUrl: './Comment.component.html'
})
export class CommentComponent implements OnInit {
  @Input() public comment: Boo.Objects.TaskNote;
  @Input() public displayAsHtml = false;

  userType: string;

  ngOnInit(): void {
    if (this.comment.TaskNoteContactTypeId === Boo.Objects.Enums.TaskNoteContactTypesEnum.WorkblockResolution) {
      this.userType = "CSR";
    }
  }
}

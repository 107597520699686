import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OnsiteRecommendationService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/onsiterecommendation`;

  constructor(private http: HttpClient) { }

  save(onsiteRecommendation: Boo.Objects.OnsiteRecommendation): Observable<any> {	
    return this.http.post(`${this.baseUrl}/Save`, onsiteRecommendation);
  }

  getImplementationSummary(taskId: number): Observable<Boo.Objects.OnsiteImplementation.OnsiteRecommendationImplementationSummary> {
    const params = new HttpParams().set('taskId', taskId);

    return this.http.get<Boo.Objects.OnsiteImplementation.OnsiteRecommendationImplementationSummary>(`${this.baseUrl}/ImplementationSummary`, { params: params });
  }
}

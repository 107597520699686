import { Component, OnInit, ViewChild } from '@angular/core';
import System from 'framework/System';
import Utils from '../../../shared/utils';
import BaseComponent from '../BaseComponent';
import { SaveTypes } from 'app/models/enums/SaveTypes';
import IValidatedResult = app.interfaces.IValidatedResult;
import { LocalProfilesComponent as SharedLocalProfilesComponent } from '../../shared/local-profiles/local-profiles/local-profiles.component';

@Component({
  selector: 'app-components-ticket-localprofiles',
  templateUrl: './LocalProfiles.component.html'
})
export class LocalProfilesComponent extends BaseComponent implements OnInit {
  @ViewChild(SharedLocalProfilesComponent) localProfilesComponent: SharedLocalProfilesComponent;
  customerJs: Boo.Objects.Customer;
  manageableTaskTypes: Boo.Objects.Enums.TaskTypeEnum[] = [Boo.Objects.Enums.TaskTypeEnum.LocalBusinessCitationSubscription];
  isOpen = true;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.customerJs = ko.mapping.toJS(this.customer);
  }

  public save(saveType: SaveTypes): JQueryPromise<app.ticket.interfaces.ISaveData | void> {
    return super.save(saveType)
      .then(() => Utils.wrapDfd(this.localProfilesComponent.saveSubscriptions(true)))
      .then(() => {
        return System.emptyPromise();
      })
  }

  public validate(saveType: SaveTypes): JQueryPromise<IValidatedResult> {
    return super.validate(saveType)
        .then((validationResult) => {
          if (this.localProfilesComponent.hasPendingChanges()) {
            return System.resolvedPromise<IValidatedResult>({ isValid: false, errorMessages: ['You have pending changes. Please save them before completing this ticket.'] });
          }
      
          if (!this.localProfilesComponent.isValid()) {
            this.isOpen = true;
            return System.resolvedPromise<IValidatedResult>({ isValid: false, errorMessages: ['Please fix the local profile'] });
          }
          return validationResult;
        });
  }
}

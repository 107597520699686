import { Component, Input, OnInit } from '@angular/core';
import { CustomerCampaignService } from '../../../../services/customer-campaign.service';

@Component({
  selector: 'app-components-specialist-actioncomponents-changed-keywords',
  templateUrl: './changed-keywords.component.html',
  styleUrls: ['./changed-keywords.component.scss']
})
export class ChangedKeywordsComponent implements OnInit {

  @Input() since: Date;
  @Input() websiteUrl: Boo.Objects.WebsiteUrl;
  changes: IChangeSummary[];

  constructor(private customerCampaignService: CustomerCampaignService) { }

  ngOnInit(): void {
    this.customerCampaignService.getKeywordHistory(this.websiteUrl.WebsiteUrlId, this.since)
      .subscribe(keywordHistories => {
        this.changes = this.calculateKeywordChanges(keywordHistories);
      });
  }

  private groupKeywordHistory(keywordHistories: Boo.Objects.WebsiteUrlKeywordHistory[]): Boo.Objects.WebsiteUrlKeywordHistory[][] {
    const MAX_SECONDS_APART = 1;
    const groups = [];
    let group = [keywordHistories[0]];
    for (let i = 1; i < keywordHistories.length; ++i) {
      const next = keywordHistories[i - 1];
      const current = keywordHistories[i];
      if (Math.abs(current.InsertedDate.getTime() - next.InsertedDate.getTime()) / 1000 > MAX_SECONDS_APART) {
        groups.push(group);
        group = [];
      }

      group.push(current);
    }

    groups.push(group);

    return groups;
  }

  private calculateKeywordChanges(keywordHistories: Boo.Objects.WebsiteUrlKeywordHistory[]): IChangeSummary[] {
    if (!keywordHistories.length) {
      return [];
    }

    const result: IChangeSummary[] = [];
    let current = this.websiteUrl.Keywords;
    const groupedChanges: Boo.Objects.WebsiteUrlKeywordHistory[][] = this.groupKeywordHistory(keywordHistories) as any;
    for (const changeDeltas of groupedChanges) {
      const before = this.determinePreviousKeywordsFromChanges(current, changeDeltas);

      const beforeKeywordPhrases = before.map(x => x.KeywordPhrase).join(', ');
      const afterKeywordPhrases = current.map(x => x.KeywordPhrase).join(', ');

      if (beforeKeywordPhrases !== afterKeywordPhrases) {
        const change: IChangeSummary = {
          date: _.max(_.map(changeDeltas, y => y.InsertedDate)) as Date,
          before: beforeKeywordPhrases,
          after: afterKeywordPhrases
        };

        result.push(change);
      }

      current = before;
    }

    return result;
  }

  private determinePreviousKeywordsFromChanges(after: Boo.Objects.WebsiteUrlKeyword[], changeDeltas: Boo.Objects.WebsiteUrlKeywordHistory[]): Boo.Objects.WebsiteUrlKeyword[] {
    const removed = changeDeltas.filter(x => !x.IsActive);
    let before = after.concat(removed.map(x => x.WebsiteUrlKeyword));
    const added = changeDeltas.filter(x => x.IsActive).map(x => x.WebsiteUrlKeywordId);
    before = before.filter(x => !added.includes(x.WebsiteUrlKeywordId));
    return before;
  }
}

interface IChangeSummary {
  date: Date;
  before: string;
  after: string;
}

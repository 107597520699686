import { Component } from '@angular/core';
import Check from 'framework/Check';
import IWebsiteTemplateSelectorActivateParams = app.components.websites.interfaces.IWebsiteTemplateSelectorActivateParams;
import System from 'framework/System';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { WebsiteService } from '../../../services/website.service';
import Utils from '../../../shared/utils';
import { SessionStorageService } from '../../../services/session-storage.service';

@Component({
  selector: 'app-components-websites-websitedetails',
  templateUrl: './WebsiteDetails.component.html'
})
export class WebsiteDetailsComponent implements IActivatable {
    public website: app.tsmodels.interfaces.IWebsiteObservable;
    public canSetTemplate: boolean;
    public ssoUrlForCustomer: KnockoutObservable<string>;
    public hideSiteInstructions: boolean;
    public isLoading: KnockoutObservable<boolean>;
    public validation: KnockoutObservable<KnockoutObservable<any>[]>;
    public hasOpenLandingPageTask: boolean;
    public websiteCreationHasBegun:  boolean;
    public isLandingPage: boolean;
    public allowDetailUpdates: boolean;
    public hasWebsiteBuild: boolean;
    public canViewAdminSso = false;
    private customerId: number;

    constructor(private websiteService: WebsiteService,
        private sessionStorageService: SessionStorageService) { }

    public activate(params: app.components.websites.interfaces.IWebsiteComponentActivateParams): JQueryPromise<void> {
        Check.isNotEmpty(params.customerId, 'customerId cannot be empty');
        Check.isNotEmpty(params.website, 'website cannot be empty');
        Check.isNotEmpty(params.website.WebsiteAccount, 'Website was not loaded with an associated account');
        Check.isTrue(ko.isObservable(params.website.WebsitePages) && _.isArray(params.website.WebsitePages()), 'Website was not loaded with the associated pages');

        return Utils.wrapDfd(this.sessionStorageService.getPartner())
            .then(partner => {
                this.canViewAdminSso = partner.PartnerId === Boo.Objects.Enums.PartnerEnum.Boostability
                return Utils.wrapDfd(this.websiteService.get(params.website.WebsiteId()));
            })
            .then(website => {
                this.customerId = params.customerId;
                this.hideSiteInstructions = !!params.hideSiteInstructions;
                this.website = ko.mapping.fromJS(website);
                this.hasWebsiteBuild = !!website.WebsiteBuild;
                this.isLandingPage = this.hasWebsiteBuild ? website.WebsiteBuild.IsLandingPage : false
                

                // only allow template to be set before the boost site is created
                // because some providers do not allow setting the template afterward.
                this.canSetTemplate = !this.website.SiteId() && this.website.WebsiteBuild ? true : false;

                this.isLoading = ko.observable(false);
                this.ssoUrlForCustomer = ko.observable(null);

                const isSiteIdRequired = !!this.website.SiteId();
                const isPreviewUrlRequired = !!this.website.PreviewUrl();
                const isAccountIdRequired = this.website.WebsiteAccount && this.website.WebsiteAccount.AccountId() ? true : false;

                let validatedItems: any = [
                    this.website.TemplateId.extend({
                        required: {
                            message: 'Template is required',
                            onlyIf: (): boolean => this.canSetTemplate
                        }
                    }),
                    this.website.SiteId.extend({
                        required: {
                            message: 'SiteId cannot be empty',
                            onlyIf: (): boolean => isSiteIdRequired
                        }
                    }),
                    this.website.WebsiteAccount.AccountId.extend({
                        required: {
                            message: 'AccountId cannot be empty',
                            onlyIf: (): boolean => isAccountIdRequired
                        }
                    }),
                    this.website.PreviewUrl.extend({
                        required: {
                            message: 'Preview URL cannot be empty',
                            onlyIf: (): boolean => isPreviewUrlRequired
                        }
                    })
                ];

                if (this.hasWebsiteBuild) {
                    validatedItems = validatedItems.concat([
                        this.website.WebsiteBuild.ExampleUrls.extend({
                            maxLength: 2048
                        }),
                        this.website.WebsiteBuild.GlobalDesignInformation.extend({
                            maxLength: 2048
                        }),
                        this.website.WebsiteBuild.Emails.extend({
                            maxLength: 2048
                        })
                    ]);
                }

                this.validation = ko.validatedObservable(validatedItems);
                this.hasOpenLandingPageTask = false;
                this.websiteCreationHasBegun = true;
                this.allowDetailUpdates = false;
            })
    }

    public loadSsoUrlForCustomer(): JQueryPromise<string> {
        return this.loadSsoUrl((websiteId) => Utils.wrapDfd(this.websiteService.getSsoUrlForCustomer(websiteId)), this.ssoUrlForCustomer);
    }

    public signOnWithSsoUrlForCustomer(): void {
        $.when(this.ssoUrlForCustomer() || this.loadSsoUrlForCustomer())
            .then((url) => window.open(url));
    }

    public save(): void {
        this.validate().then((isValid) => {
            if (!isValid) {
                return;
            }

            const websiteToSave: Boo.Objects.Websites.Website = ko.mapping.toJS(this.website);

            this.doAsync(() => {
                return Utils.wrapDfd(this.websiteService.save(websiteToSave))
                    .then(() => {
                        toastr.success('Website Details saved');
                    });
            });
        });
    }

    public openTemplateSelector(): void {
        const params: IWebsiteTemplateSelectorActivateParams = {
            websiteId: this.website.WebsiteId(),
            templateId: this.website.TemplateId()
        };
        PriorityViewModel.show('app-components-websites-websitetemplateselector', params)
            .then((templateId: number) => {
                if (_.isNumber(templateId) || templateId === null) {
                    this.website.TemplateId(templateId);
                }
            });
    }

    public loadSiteInfo(): void {
      this.doAsync(() => {
          return Utils.wrapDfd(this.websiteService.getSiteInfo(this.website.WebsiteId(), this.website.SiteId()))
              .then((websiteInfo: Boo.Objects.Websites.WebsiteSiteInfo) => {
                  this.website.SiteId(websiteInfo.SiteId);
                  this.website.WebsiteAccount.AccountId(websiteInfo.AccountId);
                  this.website.TemplateId(Number(websiteInfo.TemplateId));
                  this.website.PreviewUrl(websiteInfo.PreviewUrl);
                  toastr.success('Website details retrieved');
              });
      });
    }

    private validate(): JQueryPromise<boolean> {
        if (!this.validation.isValid()) {
            toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
            this.validation.errors.showAllMessages();
            return System.resolvedPromise(false);
        }

        return System.resolvedPromise(true);
    }

    private loadSsoUrl(fetchFunc: (websiteId: number) => JQueryPromise<string>, observable: KnockoutObservable<string>): JQueryPromise<string> {
        if (!this.website.WebsiteAccount.AccountId()) {
            toastr.warning(`The Website account has not yet been created with ${this.website.WebsiteAccount.HostName()}. Single sign-on link cannot be loaded.`);
            return $.Deferred<string>((dfd) => dfd.reject()).promise();
        }
        if (!this.website.SiteId()) {
            toastr.warning(`The Site Id for the website is not set. Single sign-on link cannot be loaded.`);
            return $.Deferred<string>((dfd) => dfd.reject()).promise();
        }

        return this.doAsync(() => {
            return fetchFunc(this.website.WebsiteId())
                .then((url) => {
                    observable(url);
                    return observable();
                });
        });
    }

    /**
     * Helper to do async request with standard loading indicator and error handling.
     */
    private doAsync(func: () => JQueryPromise<any>): JQueryPromise<any> {
        this.isLoading(true);
        return func().always(() => {
            this.isLoading(false);
        }).fail((err: any) => toastr.error(err));
    }
}

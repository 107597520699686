/* eslint-disable @angular-eslint/component-selector */
import { Component, Input, Output, EventEmitter, AfterViewInit, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SortDirection } from './sort-direction';
import { SortEvent } from './sort-event';

const nextSort: Record<SortDirection, SortDirection> = {
  'asc': 'desc',
  'desc': 'asc',
  '': 'asc'
};

@Component({
  selector: 'th[field]',
  templateUrl: './table-header.component.html'
})
export class TableHeaderComponent implements OnInit, AfterViewInit {
  @ViewChild('content') content: ElementRef;

  @Input() field: string;
  @Input() asc: boolean;
  @Input() desc: boolean;
  @Input() export: boolean;
  @Input() sort: boolean;
  @Output() sortEmmitter = new EventEmitter<SortEvent>();

  name: string;
  sortBy: string;
  sortDirection: SortDirection;

  ngOnInit(): void {
    this.asc = this.asc !== undefined;
    this.desc = this.desc !== undefined;
    this.sortBy = this.sort !== undefined ? this.field : '';
    this.export = this.export !== undefined;
    this.sortDirection = this.asc ? 'asc' : this.desc ? 'desc' : '';
  }

  ngAfterViewInit(): void {
    this.name = this.content.nativeElement.innerText.trim();
  }

  sortItems() {
    this.sortDirection = nextSort[this.sortDirection];
    this.sortEmmitter.emit({ column: this.sortBy, direction: this.sortDirection });
  }
}

import { Component } from '@angular/core';
import System from 'framework/System';
import IValidatedResult = app.interfaces.IValidatedResult;
import IValidationPublicApi = app.interfaces.IValidationPublicApi;

@Component({
	selector: 'app-components-managecustomer-customeruserphonenumber',
	templateUrl: './CustomerUserPhoneNumber.component.html'
})
export class CustomerUserPhoneNumberComponent implements IActivatable {
    public customerUser: CustomerUserObservable;
    public customerUserPhoneNumber: CustomerUserPhoneNumberObservable;
    public validation: KnockoutObservable<any>;
    public validationApi: IValidationPublicApi;
    public validationErrors: KnockoutValidationErrors;

    private deletePhoneNumber: (customerUserPhoneNumber: CustomerUserPhoneNumberObservable) => void;
    private uniqueId: string;

    public activate(params: ICustomerUserPhoneNumberViewModelActivateParams): JQueryPromise<void> {
        this.customerUser = params.customerUser;
        this.customerUserPhoneNumber = params.customerUserPhoneNumber;
        this.deletePhoneNumber = params.deletePhoneNumber;
        this.validationApi = params.validationApi;
        this.uniqueId = _.uniqueId('phoneNumber');

        this.customerUserPhoneNumber.PhoneNumber.Number.extend({
            phoneNumber: params.countryAbbreviation,
            required: true
        });

        this.customerUserPhoneNumber.PhoneNumber.Number.subscribe(() => this.customerUser.IsDirty(true));

        this.validation = ko.validatedObservable([this.customerUserPhoneNumber.PhoneNumber.Number]);

        if (this.validationApi) {
            this.validationApi.add(
                {
                    isValid: this.isValid.bind(this),
                    validate: this.validate.bind(this)
                },
                this.uniqueId);
        }

        return System.emptyPromise();
    }

    public delete(): void {
        if (this.validationApi) {
            this.validationApi.remove(this.uniqueId);
        }

        this.deletePhoneNumber(this.customerUserPhoneNumber);
    }

    private isValid(): boolean {
        return this.validation.isValid();
    }

    private validate(): JQueryPromise<IValidatedResult> {
        const isValid = this.validation.isValid();
        let errorMessages: string[] = [];

        if (this.validation.errors) {
            this.validation.errors.showAllMessages(true);
            errorMessages = ko.mapping.toJS(this.validation.errors()) as string[];
        }

        return System.resolvedPromise<IValidatedResult>({ isValid: isValid, errorMessages: errorMessages });
    }
}

interface ICustomerUserPhoneNumberViewModelActivateParams {
    customerUser: CustomerUserObservable;
    customerUserPhoneNumber: CustomerUserPhoneNumberObservable;
    countryAbbreviation: KnockoutComputed<string>;
    deletePhoneNumber: (customerUserPhoneNumber: CustomerUserPhoneNumberObservable) => void;
    validationApi?: IValidationPublicApi;
}

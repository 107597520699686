import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/applications`;

  constructor(private http: HttpClient) { }

  getApplicationOverview(startDate: moment.Moment, endDate: moment.Moment): Observable<Boo.Objects.ApplicationOverview> {
    const params = new HttpParams()
      .set('startDate', startDate.toISOString())
      .set('endDate', endDate.toISOString());

    return this.http.get<Boo.Objects.ApplicationOverview>(`${this.baseUrl}/overview`, { params: params });
  }
}
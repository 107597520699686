import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { SessionStorageService } from './session-storage.service';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/permissions`;

  constructor(
    private http: HttpClient,
    private sessionStorageService: SessionStorageService) { }

  public getUserAssignable(): Observable<Boo.Objects.Permission[]> {
    return this.http.get<Boo.Objects.Permission[]>(`${this.baseUrl}/GetUserAssignable`);
  }

  public hasPermission(permission: number): Observable<boolean> {
    return forkJoin([this.sessionStorageService.getPartner(), this.sessionStorageService.getUser()])
      .pipe(
        switchMap(([partner, user]) => {
          return of(launchpad.hasPermission(partner, user.PartnerUsers, permission, user));
        })
      );
  }
}

import { Component } from '@angular/core';
import permissions from 'app/models/Permissions';
import { CustomerService } from '../../../services/customer.service';
import { PartnerService } from '../../../services/partner.service';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-customerservice-csrs',
  templateUrl: './Csrs.component.html'
})
export class CsrsComponent implements IActivatable {
    public isLoading: KnockoutObservable<boolean>;
    public currentPartner: KnockoutObservable<Boo.Objects.Partner>;

    public selectedCurrentCsr: KnockoutObservable<number>;
    public selectedNewCsr: KnockoutObservable<number>;
    public customerCount: KnockoutObservable<number>;

    public selectedTeamId: KnockoutObservable<number>;
    public minSpend: KnockoutObservable<number>;
    public maxSpend: KnockoutObservable<number>;

    public csrs: KnockoutObservableArray<Boo.Objects.PartnerUser>;

    public currentPartnerHasAllTeamsPermission: KnockoutComputed<boolean>;
    public teams: KnockoutObservableArray<{ Name: string, TeamId: number }>;
    public activeCsrs: KnockoutComputed<Boo.Objects.User[]>;
    public validation: KnockoutObservable<any>;
    public allTeams: Boo.Objects.Team[];

    constructor(
      private customerService: CustomerService,
      private partnerService: PartnerService,
      private sessionStorageService: SessionStorageService) { }

    public activate(): JQueryPromise<any> {
        this.isLoading = ko.observable(true);
        this.currentPartner = ko.observable();
        this.teams = ko.observableArray([]);

        this.selectedCurrentCsr = ko.observable();
        this.selectedNewCsr = ko.observable();

        this.customerCount = ko.observable(0);
        this.selectedTeamId = ko.observable(0);
        this.minSpend = ko.observable(0);
        this.maxSpend = ko.observable(0);

        this.csrs = ko.observableArray([]);

        this.currentPartnerHasAllTeamsPermission = ko.computed(() => {
            return this.currentPartner() &&
                this.currentPartner().PartnerUserLevelPermissions &&
                _.any(this.currentPartner().PartnerUserLevelPermissions, (p) => {
                    return p.UserLevelId === launchpad.config.keys.customerServiceUserLevelId &&
                        p.PermissionId === permissions.HasAllTeams;
                }
                );
        });

        this.selectedCurrentCsr.subscribe((csr: number) => {
            this.customerCount(0);
            this.setTeams(csr);
        });

        this.selectedTeamId.subscribe(() => {
            this.customerCount(0);
        });

        this.minSpend.subscribe(() => {
            this.customerCount(0);
        });

        this.maxSpend.subscribe(() => {
            this.customerCount(0);
        });

        this.activeCsrs = ko.computed(() => {
            return _.filter(this.csrs(), (item) => {
                return item.IsActive;
            });
        });

        this.selectedCurrentCsr.extend({
            validation: {
                validator: (val: number): boolean => {
                    return val !== this.selectedNewCsr();
                },
                message: 'Cannot be the same as the new CSR.'
            }
        });

        this.selectedNewCsr.extend({
            validation: {
                validator: (val: number): boolean => {
                    return val !== this.selectedCurrentCsr();
                },
                message: 'Cannot be the same as the current CSR.'
            }
        });
        this.customerCount.extend({
            validation: {
                validator: (val: number): boolean => {
                    return val !== 0;
                },
                message: 'No customers to reassign.'
            }
        });

        this.validation = ko.validatedObservable([
            this.selectedCurrentCsr,
            this.selectedNewCsr,
            this.customerCount
        ]);

        return $.when<any>(
              Utils.wrapDfd(this.sessionStorageService.getStaticData()),
              Utils.wrapDfd(this.sessionStorageService.getPartner()),
              Utils.wrapDfd(this.partnerService.getAllCsrs())
            )
            .then((staticData: Boo.Objects.LaunchPadStaticData, partner: Boo.Objects.Partner, csrs: Boo.Objects.PartnerUser[]) => {
                this.allTeams = staticData.Teams;
                this.currentPartner(partner);

                const users = _(csrs).sortBy((user) => {
                    return user.FullName.toLowerCase();
                });

                this.csrs(users);
                this.reset();
            })
            .always(() => {
                this.isLoading(false);
            });
    }

    public canActivate(user: Boo.Objects.User, partner: Boo.Objects.Partner, params: any): boolean {
        return _.any(user.PartnerUsers, (partnerUser) => {
            return partnerUser.UserLevelId === launchpad.config.keys.adminUserLevelId
                || partnerUser.UserLevelId === launchpad.config.keys.managerUserLevelId;
        });
    }

    public reset(): void {
        this.selectedNewCsr(undefined);
        this.selectedCurrentCsr(undefined);
        this.setTeams(undefined);
        this.validation.errors.showAllMessages(false);
        this.customerCount(0);
    }

    public search(): void {
        this.selectCustomers();
    }

    public selectCustomers(): JQueryPromise<any> {
        this.isLoading(true);
        return Utils.wrapDfd(this.customerService.searchByCsrCount(this.selectedCurrentCsr() ? this.selectedCurrentCsr() : null, this.selectedTeamId(), this.minSpend(), this.maxSpend()))
            .then((count: number) => {
                this.customerCount(count || 0);
                if (this.customerCount() === 0) {
                    toastr.warning('No customers assigned to CSR.');
                }
            }).always(() => {
                this.isLoading(false);
            });
    }

    public reassignCsr(): void {
        if (!this.validation.isValid()) {
            this.validation.errors.showAllMessages(true);
            return;
        }

        this.isLoading(true);

        Utils.wrapDfd(this.customerService.reassignCsr(
            this.selectedCurrentCsr() ? this.selectedCurrentCsr() : 0,
            this.selectedNewCsr() ? this.selectedNewCsr() : 0,
            this.selectedTeamId(),
            this.minSpend(),
            this.maxSpend()))
            .then((wasSuccessful: boolean) => {
                if (wasSuccessful) {
                    toastr.success('Successfully changed the CSR.');
                    this.reset();
                } else {
                    toastr.error('Unable to change the CSR.');
                }
            })
            .always(() => {
                this.isLoading(false);
            });
    }

    private setTeams(csr: number): void {
        let teamList = [{ Name: 'All Teams', TeamId: 0 }];
        if (csr) {
            const am = _(this.csrs()).findWhere({ UserId: csr });
            if (!am) {
                toastr.error('There was a problem loading the CSR information.');
                this.teams([]);
                return;
            }

            if (this.currentPartnerHasAllTeamsPermission() || _.any(am.UserPermissions, (p) => { return p.PermissionId === permissions.HasAllTeams; })) {
                teamList = teamList.concat(this.allTeams);
            } else {
                const userTeams = _.pluck(am.Teams, 'Team');
                teamList = teamList.concat(userTeams);
            }
        } else {
            teamList = teamList.concat(this.allTeams);
        }

        this.teams(teamList);
    }
}

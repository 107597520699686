import { Component, OnInit } from '@angular/core';
import TodoStatus = Boo.Objects.Todo.Enums.TodoStatus;
import ITodoGroupObservable = app.tsmodels.interfaces.ITodoGroupObservable;
import TodoList = app.tsmodels.interfaces.TodoList;

@Component({
  selector: 'app-components-todo-readonlytodolist',
  templateUrl: './ReadonlyTodoList.component.html'
})
export class ReadonlyTodoListComponent implements IActivatable, OnInit {
  window = window;
  launchpad = launchpad;
  selectedTodo: Boo.Objects.Todo.Todo;
  todoGroups: Boo.Objects.Todo.TodoGroup[] = [];
  todoLists: TodoList[];
  todos: Boo.Objects.Todo.Todo[];
  todoModalId: string;
  userId: number;
  statusMap: any = {
    [TodoStatus.New]: {
      class: 'text-default',
      icon: 'far fa-fw fa-circle',
      helpText: ''
    },
    [TodoStatus.Complete]: {
      class: 'text-success',
      icon: 'fas fa-fw fa-check-circle',
      helpText: 'Completed'
    },
    [TodoStatus.Incomplete]: {
      class: 'text-warning',
      icon: 'fas fa-fw fa-minus-circle',
      helpText: 'Not Completed'
    }
  };

  private isCustomerTodo: boolean;
  private isDelegatedList: boolean;

  ngOnInit(): void {
    this.todoModalId = _.uniqueId('readonlyTodoModal_');

    this.todos = _.chain(this.todoLists)
      .map((x) => { return x.Todos; })
      .map((y) => { return y; })
      .flatten()
      .filter(x => {
        if (!!this.isCustomerTodo || this.isDelegatedList) {
          return x.Status !== TodoStatus.Incomplete;
        } else {
          return x.Status !== TodoStatus.New;
        }
      })
      .sortBy((z) => { return z.SortOrder; })
      .value();

    const tempTodoGroups = _.chain(this.todos)
      .filter((todo) => { return !!todo.TodoGroupId; })
      .pluck('TodoGroup')
      .uniq((todoGroup) => { return todoGroup.TodoGroupId; })
      .value();

    if (tempTodoGroups.length > 0) {

      const configs = _.chain(this.todoLists)
        .map((x) => { return x.TodoGroupConfigurations; })
        .map((y) => { return y; })
        .flatten()
        .value();

      const sortArray = _.chain(configs)
        .groupBy('TodoGroupId')
        .map((value, key) => {
          return {
            'todoGroupId': Number(key),
            'sortOrder': _.chain(value)
              .pluck('SortOrder')
              .reduce((a: number, b: number) => { return a + b; }, 0)
              .value()
          };
        })
        .sortBy('sortOrder')
        .pluck('todoGroupId')
        .value();

      this.todoGroups = tempTodoGroups.sort((a,b) => sortArray.indexOf(a.TodoGroupId) - sortArray.indexOf(b.TodoGroupId));
    }
  }

  canActivate(user: Boo.Objects.User, partner: Boo.Objects.Partner, params: ITodoListActivationParams): boolean {
    this.todoLists = params.todoLists;
    this.isCustomerTodo = params.isCustomerTodo;
    this.isDelegatedList = params.isDelegatedList;
    this.userId = params.userId;
    return true;
  }

  public todoNoteCount(todo: Boo.Objects.Todo.Todo): number {
    return _.filter(todo.TodoNotes, (note) => {
      return note.InsertedUserId !== launchpad.config.systemAdminUserId;
    }).length;
  }

  public openTodo(todo: Boo.Objects.Todo.Todo): void {
    $('#' + this.todoModalId).modal({
      backdrop: 'static',
      keyboard: false,
      show: true
    });
    this.selectedTodo = todo;
  }

  public setTodoClass(todo: Boo.Objects.Todo.Todo): string {
    return todo.Delegated ? 'text-purple' : this.statusMap[todo.Status].class;
  }

  public setTodoIcon(todo: Boo.Objects.Todo.Todo): string {
    return todo.Delegated ? 'fas fa-chevron-circle-right' : this.statusMap[todo.Status].icon;
  }

  public setTodoHelpText(todo: Boo.Objects.Todo.Todo): string {
    return todo.Delegated ? 'Delegated' : this.statusMap[todo.Status].helpText;
  }
}

export interface ITodoListActivationParams {
  todoLists: TodoList[];
  userId: number;
  isCustomerTodo: boolean;
  isDelegatedList: boolean;
}

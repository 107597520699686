import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DudaSsoService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/Duda/SSO`;

  constructor(private http: HttpClient) { }

  getSsoUrlForThryv(): Observable<string> {
    return this.http.get<string>(`${this.baseUrl}/Thryv`);
  }
}
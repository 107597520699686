import { Directive, Input, HostListener, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: 'input[enforceMinMax][type=number]'
})
export class EnforceMinMaxDirective implements OnInit {
  @Input()
  min: string;

  @Input()
  max: string;

  private minInt: number;
  private maxInt: number;

  constructor(private ngControl: NgControl) { }

  ngOnInit(): void {
    this.minInt = parseInt(this.min);
    this.maxInt = parseInt(this.max);
  }

  @HostListener('input', ['$event'])
  ngModelChange(input: any) {
    const val = parseInt(input.target.value);

    if (!Number.isNaN(this.minInt) && val <= this.minInt) {
      this.ngControl.control.setValue(this.minInt);
    }
    else if (!Number.isNaN(this.maxInt) && val >= this.maxInt) {
      this.ngControl.control.setValue(this.maxInt);
    }
  }
}

import { Component } from '@angular/core';
import System from 'framework/System';
import TaskHelper from 'app/specialist/TaskHelper';

@Component({
	selector: 'app-components-specialist-actioncomponents-custommanual',
	templateUrl: './CustomManual.component.html'
})
export class CustomManualComponent implements IActionWorkspace, IActivatable {
    public actionViewModel: KnockoutObservable<IActionWorkspace>;
    public currentAction: KnockoutObservable<Boo.Objects.Action>;
    public title = 'Manually Entered Custom Action';
    public taskDescription: string;
    public taskCategory: string;
    public taskMinutes: number;

    public activate(params: IActionWorkspaceActivationParams): JQueryPromise<any> {

        params.controlViewModel(this);
        this.actionViewModel = ko.observable(null);
        this.currentAction = params.currentAction;

        this.taskDescription = TaskHelper.getCustomDescription(this.currentAction().Task);
        this.taskMinutes = TaskHelper.getCustomBillableMinutes(this.currentAction().Task);
        this.taskCategory = TaskHelper.getCustomTaskTypeName(this.currentAction().Task);

        return System.emptyPromise();
    }

    public isStepReadyToReject(): boolean {
        return true;
    }

    public isStepReadyToSave(): boolean {
        return true;
    }

    public isStepReadyToComplete(): boolean {
        return true;
    }

    public isStepReadyToDelete(): boolean {
        return true;
    }
}

export const onsiteCopyOrderTaskTypes: Boo.Objects.Enums.TaskTypeEnum[] = [
  Boo.Objects.Enums.TaskTypeEnum.StandardOnsiteCopyCreationContentOnly,
  Boo.Objects.Enums.TaskTypeEnum.StandardOnsiteCopyCreationAndImplementation,
  Boo.Objects.Enums.TaskTypeEnum.OnsiteCopy
];

// todo: Onsite copy includes implementation when applicable. We only need one collection after deprecated tasks are completed.
// murder: After StandardOnsiteCopyCreationContentOnly and StandardOnsiteCopyCreationAndImplementation tasks/workflows are complete and there aren't future allocations.
export const onsiteCopyOrderWithImplementationTaskTypes: Boo.Objects.Enums.TaskTypeEnum[] = [
  Boo.Objects.Enums.TaskTypeEnum.StandardOnsiteCopyCreationAndImplementation,
  Boo.Objects.Enums.TaskTypeEnum.OnsiteCopy
];

export const onsiteBloggingOrderTaskTypes: Boo.Objects.Enums.TaskTypeEnum[] = [
  Boo.Objects.Enums.TaskTypeEnum.StandardOnsiteBlogPost,
  Boo.Objects.Enums.TaskTypeEnum.PremiumOnsiteBlogPost
];

export const unassignableTaskTypes: Boo.Objects.Enums.TaskTypeEnum[] = [
  Boo.Objects.Enums.TaskTypeEnum.OffsiteContentMarketing,
  Boo.Objects.Enums.TaskTypeEnum.StandardOnsiteCopyCreationContentOnly,
  Boo.Objects.Enums.TaskTypeEnum.StandardOnsiteCopyCreationAndImplementation,
  Boo.Objects.Enums.TaskTypeEnum.OnsiteCopy,
  Boo.Objects.Enums.TaskTypeEnum.StandardOnsiteBlogPost,
  Boo.Objects.Enums.TaskTypeEnum.PremiumOnsiteBlogPost,
  Boo.Objects.Enums.TaskTypeEnum.KeywordResearchStrategyUpdate,
  Boo.Objects.Enums.TaskTypeEnum.PremiumBacklink
];

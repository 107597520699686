import { Component, OnInit } from '@angular/core';
import { QaCriteriaControlComponent } from '../qa-criteria-control/qa-criteria-control.component';

@Component({
	selector: 'app-components-qa-criteria-select',
	templateUrl: './qa-criteria-select.component.html',
	styleUrls: ['./qa-criteria-select.scss']
})
export class QaCriteriaSelectComponent extends QaCriteriaControlComponent implements OnInit {
	public isGrouped: boolean;
	
	ngOnInit(): void {
		super.ngOnInit();
		this.isGrouped = this.displayConfig.groups.length > 1;
	}
}
import ITodoNoteObservable = app.tsmodels.interfaces.ITodoNoteObservable;

export default class TodoNoteFactory {
    public createLegacy(todoId: number, userId: number): ITodoNoteObservable {
        return {
            Content: ko.observable(''),
            InsertedDate: ko.observable(null),
            InsertedUserId: ko.observable(userId),
            TodoId: ko.observable(todoId),
            TodoNoteId: ko.observable(0),
            IsEditable: ko.observable(true)
        } as ITodoNoteObservable;
    }

    public create(todoId: number, userId: number, content = '', isEditable = true): Boo.Objects.Todo.TodoNote {
      return {
            Content: content,
            InsertedDate: null,
            InsertedUserId: userId,
            TodoId: todoId,
            TodoNoteId: 0,
            IsEditable: isEditable
        } as Boo.Objects.Todo.TodoNote;
    }
}

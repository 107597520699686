import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TodoService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/todo`;

  constructor(private http: HttpClient) { }

  getHistory(todoId: number): Observable<Boo.Objects.Todo.TodoHistory[]> {
    const params = new HttpParams().set('todoId', todoId);

    return this.http.get<Boo.Objects.Todo.TodoHistory[]>(`${this.baseUrl}/GetHistory`, { params: params });
  }

  getNotes(todoId: number): Observable<Boo.Objects.Todo.TodoNote[]> {
    const params = new HttpParams().set('todoId', todoId);

    return this.http.get<Boo.Objects.Todo.TodoNote[]>(`${this.baseUrl}/Notes`, { params: params });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomReportService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/customreports`;

  constructor(private http: HttpClient) { }

  getByCustomerId(customerId: number): Observable<Boo.Objects.CustomReport[]> {
    const params = new HttpParams().set('customerId', customerId);

    return this.http.get<Boo.Objects.CustomReport[]>(`${this.baseUrl}/GetByCustomerId`, { params: params });
  }

  save(report: Boo.Objects.CustomReport): Observable<void> {
    return this.http.post<void>(this.baseUrl, report);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import Timer from '../../models/timer';

@Component({
  selector: 'shared-components-timer',
  templateUrl: './timer.component.html'
})
export class TimerComponent {
  @Input() public timer: Timer;
  @Input() public showSeconds = false;

  displayTime() {
    const totalMinutes = this.timer.elapsedSeconds / 60;
    const minutes = Math.floor(totalMinutes % 60);
    const hours = Math.floor(totalMinutes / 60);
    if (this.showSeconds) {
      const sec = Math.floor(this.timer.elapsedSeconds % 60);
      const hoursString = hours < 10 ? (`0${hours}`) : hours;
      const minutesString = minutes < 10 ? (`0${minutes}`) : minutes;
      const secondsString = sec < 10 ? (`0${sec}`) : sec;
      return hoursString + ':' + minutesString + ':' + secondsString;
    } else {
      return hours + 'h ' + minutes + 'm ';
    }
  }
}

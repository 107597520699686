import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SeoWorkService } from '../../../../services/seo-work.service';

@Component({
  selector: 'app-partner-tasktype-selection',
  templateUrl: './partner-tasktype-selection.component.html'
})
export class PartnerTaskTypeSelectionComponent {

  @Input() partnerTaskTypeUsages: Boo.Objects.PartnerTaskTypeUsage[];
  @Input() partnerId: number;
  @Output() addExcludedTaskType: EventEmitter<Boo.Objects.PartnerTaskTypeUsage> = new EventEmitter<Boo.Objects.PartnerTaskTypeUsage>();

  selectedTaskType: Boo.Objects.PartnerTaskTypeUsage = undefined;
  numberOfCustomerIdsShowing = 10;

  constructor (
    private seoWorkService: SeoWorkService
  ) { }

  getIcon(taskType: Boo.Objects.PartnerTaskTypeUsage): string {
    return taskType.IsInPartnerDefaults || taskType.HasOpenWork ? 'fas fa-stop-circle text-danger' : 'fas fa-check-square text-success';
  }

  addExclusion(): void {
    this.addExcludedTaskType.emit(this.selectedTaskType);
    this.selectedTaskType = undefined;
  }

  cannotExclude(partnerTaskTypeUsage: Boo.Objects.PartnerTaskTypeUsage): boolean {
    if (partnerTaskTypeUsage == undefined) {
      return false;
    }
    return partnerTaskTypeUsage.IsInPartnerDefaults || partnerTaskTypeUsage.IsInCustomerDefaults || partnerTaskTypeUsage.HasOpenWork;
  }

  getCustomersWithTaskTypeInDefaults(take: number): void {
    this.seoWorkService.getCustomerIdsWithTaskTypeInDefaults(this.partnerId, this.selectedTaskType.TaskTypeId, take).subscribe (ids => {
      this.selectedTaskType.CustomerIdsWithTaskAsDefault = ids.join(', ');
      this.numberOfCustomerIdsShowing = ids.length;
    });
  }

  getCustomersWithTaskTypeInFutureAllocations(take: number): void {
    this.seoWorkService.getCustomerIdsWithTaskTypeInFutureAllocations(this.partnerId, this.selectedTaskType.TaskTypeId, take).subscribe (ids => {
      this.selectedTaskType.CustomerIdsWithFutureAllocatedTask = ids.join(', ');
    });
  }
}

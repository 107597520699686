import { Component } from '@angular/core';
import System from 'framework/System';
import BaseComponent from '../BaseComponent';
import { SaveTypes } from 'app/models/enums/SaveTypes';
import { TicketService } from '../../../services/ticket.service';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-ticket-lockconfirmation',
  templateUrl: './LockConfirmation.component.html'
})
export class LockConfirmationComponent extends BaseComponent {
    public lockOnAppointment: boolean;

    constructor(private ticketService: TicketService) {
      super();
    }

    public activate(params: app.ticket.components.interfaces.ILockConfirmationViewModelActivateParams): JQueryPromise<void> {
        this.lockOnAppointment = params.featureConfig.lockOnAppointment;
        return super.activate(params)
            .then(() => {
                if (!this.userCanBeAssigned()) {
                    // Returning here will open the ticket as readonly
                    return;
                }

                if (this.isActiveTicket()) {
                    if (this.isAssigned() && !this.isAssignedToLoggedInUser()) {
                        const lockTicketDfd = $.Deferred();
                        const bootBoxOptions: any = {
                            message: `This ticket is currently assigned to ${this.ticket.AssignedUser ? this.ticket.AssignedUser.FullName() : 'another user'}. Editing the ticket will unassign them from it. How would you like to continue?`,
                            onEscape: (): boolean => {
                                lockTicketDfd.resolve();
                                return true;
                            },
                            buttons: {
                                Cancel: {
                                    callback: (): void => {
                                        this.triggerTicketCancel();
                                        lockTicketDfd.resolve();
                                    }
                                },
                                View: {
                                    label: 'View-Only',
                                    callback: (): void => {
                                        lockTicketDfd.resolve();
                                    }
                                },
                                Edit: {
                                    className: 'btn-primary',
                                    callback: (): void => {
                                        this.lockTicket().then(() => lockTicketDfd.resolve());
                                    }
                                }
                            }
                        };
                        return $.when(bootbox.dialog(bootBoxOptions), lockTicketDfd);
                    } else {
                        return this.lockTicket();
                    }
                }
            });
    }

    public cancel(): JQueryPromise<void> {
        if (this.isActiveTicket() && this.isAssignedToLoggedInUser() && this.ticket.IsAppointment() === false) {
            return this.unlockTicket();
        } else {
            return System.emptyPromise();
        }
    }

    public save(saveType: SaveTypes): JQueryPromise<app.ticket.interfaces.ISaveData | void> {
        // Do not unlock tickets on complete, only on update
        if (saveType === SaveTypes.Complete ||
            !this.isActiveTicket() ||
            (this.lockOnAppointment && this.ticket.IsAppointment()) ||
            this.isAssignedToLoggedInUser() === false) {
            return System.emptyPromise();
        }

        // unlock ticket when saved by TicketViewModel
        // we do not call this.unlockTicket() because the changes would be overwritten by ticket save
        return System.resolvedPromise(({
            priority: 10, // set higher priority (arbitrary number) than BasicInfo component, which saves the same data
            onlyOnClose: true,  // only use this save data on ticket close (i.e. not on Update and KeepOpen)
            ticket: {
                AssignedUserId: null,
                IsAppointment: false,
                StatusId: Boo.Objects.Enums.TicketStatusEnum.New
            }
        } as app.ticket.interfaces.ISaveData));
    }

    private isAssigned(): boolean {
        return this.ticket.AssignedUserId() > 0;
    }

    private isAssignedToLoggedInUser(): boolean {
        return this.isAssigned() && this.user.UserId === this.ticket.AssignedUserId();
    }

    private unlockTicket(): JQueryPromise<void> {
        this.ticket.AssignedUserId(undefined);
        this.ticket.StatusId(Boo.Objects.Enums.TicketStatusEnum.New);

        return Utils.wrapDfd(this.ticketService.unlockTicket(this.ticket.TicketId()));
    }

    private userCanBeAssigned(): boolean {
        return this.userHasTeam() && (this.ticket.TicketId() === 0 || this.userHasTicketType());
    }

    private lockTicket(): JQueryPromise<any> {
        if (this.userCanBeAssigned()) {
            return Utils.wrapDfd(this.ticketService.lockTicket(this.ticket.TicketId()))
              .then(() => {
                  this.ticket.AssignedUserId(this.user.UserId);
                  this.ticket.StatusId(Boo.Objects.Enums.TicketStatusEnum.InProgress);
              });
        } else {
            toastr.error('You do not have permission to be assigned to this ticket type.');
            // Closes the entire ticket. 
            this.triggerTicketCancel();
            return System.emptyPromise();
        }
    }

    private isActiveTicket(): boolean {
        return this.ticket.StatusId() === Boo.Objects.Enums.TicketStatusEnum.InProgress ||
            this.ticket.StatusId() === Boo.Objects.Enums.TicketStatusEnum.New;
    }
}

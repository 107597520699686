import { Directive, Input, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[fileReader]'
})
export class FileReaderDirective implements AfterViewInit {
  @Input('fileReader') public Settings: { data: KnockoutObservable<string | ArrayBuffer>, filename: KnockoutObservable<string>, file: KnockoutObservable<FileReader>, accept: string, method: string, encoding: string };

  private element: HTMLInputElement;
  private $element: JQuery;
  constructor(elementRef: ElementRef) {
    this.element = elementRef.nativeElement;
    this.$element = $(this.element);
  }

  public ngAfterViewInit(): void {
    if (this.element.tagName.toLowerCase() !== 'input') {
      throw new Error('FileReader binding only applies to input elements (type=fileReader)');
    }

    this.element.setAttribute('type', 'file');
    this.element.setAttribute('multiple', 'false');

    const acceptTypes = ko.utils.unwrapObservable(this.Settings.accept);
    if (acceptTypes) {
      this.element.setAttribute('accept', acceptTypes);
    }

    const method = ko.utils.unwrapObservable(this.Settings.method) || 'readAsDataURL';
    const encoding = ko.utils.unwrapObservable(this.Settings.encoding);

    this.$element.change(() => {
      const reader = new window.FileReader();
      if (this.element.files && this.element.files.length > 0) {
        const file: any = _.first(this.element.files);

        if (ko.isObservable(this.Settings.file)) {
          this.Settings.file(file);
        }

        if (ko.isObservable(this.Settings.filename)) {
          this.Settings.filename(file.name);
        }

        reader.onload = (e) => {
          if (ko.isObservable(this.Settings.data)) {
            this.Settings.data(e.target.result);
          }
        };

        if (encoding) {
          reader[method](file, encoding);
        } else {
          reader[method](file);
        }
      }
    });
  }
}
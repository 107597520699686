/* eslint-disable @angular-eslint/component-selector */
import app from 'App';
import { Component, OnInit } from '@angular/core';
import { ViewLocatorService } from './services/view-locator.service';
import { PartnerService } from './services/partner.service';
import { HelpTextService } from './services/help-text.service';
import { LogService } from './services/log.service';
import { TicketService } from './services/ticket.service';
import { SessionStorageService } from './services/session-storage.service';
import { ReloadService } from './services/reload.service';

@Component({
  selector: 'main',
  templateUrl: './main.component.html'
})
export class MainComponent implements OnInit {
  public Date = Date;
  public ko = ko;
  public app = app;
  public moment = moment;

  public priorityViewTrackBy(index: number, priorityView: any): number {
    return priorityView.uniqueId;
  }

  constructor(
    private viewLocatorService: ViewLocatorService,
    private partnerService: PartnerService,
    private helpTextService: HelpTextService,
    private logService: LogService,
    private ticketService: TicketService,
    private sessionStorageService: SessionStorageService,
    private reloadService: ReloadService) {
  }

  ngOnInit(): void {
    this.app.activate(
      this.viewLocatorService,
      this.partnerService,
      this.helpTextService,
      this.logService,
      this.ticketService,
      this.sessionStorageService,
      this.reloadService
    );
  }
}


import { Component, OnInit, Input, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { SessionStorageService } from '../../../../services/session-storage.service';
import { LocalProfileComponent } from '../local-profile/local-profile.component';
import { MinimumLocalProfileComponent } from '../minimum-local-profile/minimum-local-profile.component';

@Component({
  selector: 'app-components-shared-local-profile-list',
  templateUrl: './local-profile-list.component.html',
  styleUrls: ['./local-profile-list.component.scss']
})
export class LocalProfileListComponent implements OnInit, AfterViewInit {
  @ViewChildren('localProfileList') localProfileList: QueryList<any>;
  @ViewChildren(LocalProfileComponent) localProfileComponents: QueryList<LocalProfileComponent>;
  @ViewChildren(MinimumLocalProfileComponent) minimumLocalProfileComponents: QueryList<MinimumLocalProfileComponent>;

  @Input() localProfileForms: UntypedFormArray;
  @Input() customer: Boo.Objects.Customer;
  @Input() readOnly = false;
  @Input() showRegistration = false;
  @Input() registrationPhoneNumber?: Boo.Objects.AutoSelectedRegistrationPhoneNumber;
  @Input() disableBusinessCitationEligibilityControls = false;
  @Input() requireFullLocalProfileOnAdd = false;
  @Input() requireFullValidation = false;

  activeLocalProfileForm: UntypedFormGroup;
  isInitialized = false;
  isReadOnly = false;
  states: Boo.Objects.State[];
  loaded: boolean;
  addingLocalProfile = false;

  get localProfileControls() {
    return this.localProfileForms.controls as UntypedFormGroup[];
  }

  constructor(private sessionStorageService: SessionStorageService) { }

  ngOnInit() {
    this.isReadOnly = this.readOnly;

    this.sessionStorageService.getStaticData()
      .subscribe(staticData => {
        this.states = staticData.States as Boo.Objects.State[];
      },
        (err) => toastr.error(err)
      );
  }

  ngAfterViewInit() {
    this.localProfileList.changes.subscribe(_ => {
      this.localProfileListRendered();
    });
  }

  reset() {
    this.isInitialized = false;
  }

  setActiveLocalProfile(localProfile: UntypedFormGroup) {
    this.activeLocalProfileForm = localProfile;
    this.addingLocalProfile = localProfile.get('localProfileId').value === 0;
  }

  setFirstLocalProfileAsActive() {
    if (this.localProfileControls.length > 0) {
      this.isInitialized = true;
      this.setActiveLocalProfile(this.localProfileForms.controls[0] as UntypedFormGroup);
    }
  }

  getStateName(stateId: number): string {
    return this.states.find(x => x.StateId === stateId)?.Name ?? '';
  }

  remove(localProfileForm: UntypedFormGroup) {
    this.localProfileForms.removeAt(this.localProfileForms.controls.indexOf(localProfileForm));
    if (this.activeLocalProfileForm == localProfileForm) {
      this.setFirstLocalProfileAsActive();
    }
  }

  getRegistrationNumber(): Boo.Objects.AutoSelectedRegistrationPhoneNumber {
    if (this.localProfileForms.length === 1) {
      return this.localProfileComponents.first.registrationPhoneNumber;
    }
    return null;
  }

  updateValueAndValidity() {
    this.localProfileComponents.forEach(x => x.updateValueAndValidity());
    this.minimumLocalProfileComponents.forEach(x => x.updateValueAndValidity());
  }

  isNewLocalProfile(localProfile: UntypedFormGroup): boolean {
    const id = localProfile.get('localProfileId')?.value;
    return !id || id === 0;
  }

  isExistingLocalProfile(localProfile: UntypedFormGroup): boolean {
    const id = localProfile.get('localProfileId')?.value;
    return !!id && id > 0;
  }

  private localProfileListRendered() {
    if (!this.isInitialized) {
      setTimeout(() => {
        this.setFirstLocalProfileAsActive();
      }, 0);
    }
  }
}

import { Component } from '@angular/core';
import permissions from 'app/models/Permissions';
import partner from 'app/models/partner';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { PartnerService } from '../../../services/partner.service';
import { PartnerUserService } from '../../../services/partner-user.service';
import { StoreService } from '../../../services/store.service';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-manager-managepartners',
  templateUrl: './ManagePartners.component.html'
})
export class ManagePartnersComponent implements IActivatable {
  isLoading: boolean;
  partnerNameFilter = '';
  onlyActivePartnerFilter = true;
  onlyLivePartnerFilter = true;
  partners: IPartnerMinimalWithHasPartnerUser[] = [];
  filteredPartners: IPartnerMinimalWithHasPartnerUser[] = [];
  partnerUsers: Boo.Objects.PartnerUser[] = [];
  user: Boo.Objects.User;
  canManageStorefronts: boolean;
  boostabilityPartnerId: Boo.Objects.Enums.PartnerEnum = Boo.Objects.Enums.PartnerEnum.Boostability;
  private partnerTemplate: Boo.Objects.Partner = new partner() as Boo.Objects.Partner;

  constructor(
    private partnerService: PartnerService,
    private partnerUserService: PartnerUserService,
    private storeService: StoreService,
    private sessionStorageService: SessionStorageService) { }

  canActivate(user: Boo.Objects.User, partner: Boo.Objects.Partner): boolean {
    this.canManageStorefronts = launchpad.hasPermission(partner, user.PartnerUsers, permissions.CanManageStorefronts, user) ||
      launchpad.hasPermission(partner, user.PartnerUsers, permissions.CanManageStorefrontsLimited, user) ||
      launchpad.hasPermission(partner, user.PartnerUsers, permissions.CanOnboardStorefronts, user);
    return true;
  }

  activate(params: any): JQueryPromise<any> {
    this.isLoading = true;
    return Utils.wrapDfd(this.sessionStorageService.getUser())
      .then((user: Boo.Objects.User) => {
        this.user = user;
        return Utils.wrapDfd(this.partnerUserService.getByUserId(user.UserId))
          .then((partnerUsers: Boo.Objects.PartnerUser[]) => {
            this.partnerUsers = partnerUsers;
          }).then(this.loadPartners.bind(this));

      }).fail((displayMessage: string) => {
        toastr.error(displayMessage);
      })
      .always(() => {
        this.isLoading = false;
      });
  }

  resetNameFilter(): void {
    this.partnerNameFilter = '';
    this.filterPartners();
  }

  setActiveFilter(value: boolean): void {
    this.onlyActivePartnerFilter = value;
    this.filterPartners();
  }

  setLiveFilter(value: boolean): void {
    this.onlyLivePartnerFilter = value;
    this.filterPartners();
  }

  filterPartners(): void {
    if (this.partnerNameFilter.length > 0) {
      this.filteredPartners = this.partners.filter((partner) => {
        return partner.PartnerName.trim().toLowerCase().includes(this.partnerNameFilter.trim().toLowerCase());
      });
    } else {
      this.filteredPartners = this.partners.filter((partner) => {
        return (partner.IsPartnerActive || !this.onlyActivePartnerFilter)
            && (partner.IsPartnerLive || !this.onlyLivePartnerFilter);
      });
    }
  }

  addPartner(): void {
    const activationOptions = {
      selectedPartner: this.partnerTemplate
    };

    PriorityViewModel.show('app-components-manager-partner', activationOptions).always(this.loadPartners.bind(this));
  }

  editPartner(partnerMin: Boo.Objects.PartnerMinimal): void {
    $.when(Utils.wrapDfd(this.partnerService.get(partnerMin.PartnerId)))
      .then((partner: Boo.Objects.Partner): void => {
        const activationOptions = {
          selectedPartner: partner
        };

        PriorityViewModel.show('app-components-manager-partner', activationOptions).always(this.loadPartners.bind(this));
      });
  }

  manageStorefront(partnerMin: Boo.Objects.PartnerMinimal): void {
    Utils.wrapDfd(this.storeService.ssoStorefronts('/storefront/all/' + partnerMin.StorefrontId))
      .then(url => window.location.href = url)
      .fail(err => toastr.error(err));
  }

  createStorefront(partnerMin: Boo.Objects.PartnerMinimal): void {
    Utils.wrapDfd(this.storeService.ssoStorefronts('/storefront/all?partnerid=' + partnerMin.PartnerId + '&name=' + partnerMin.PartnerName))
      .then(url => window.location.href = url)
      .fail(err => toastr.error(err));
  }

  openReporting(partnerMin: Boo.Objects.PartnerMinimal): void {
    PriorityViewModel.show('app-components-manager-managereporting', {
      partner: partnerMin
    }).fail((errorMessage: string) => {
      toastr.error(`There was a problem loading reporting site. ${errorMessage}`);
    });
  }

  openTaskTypeExclusions(partnerMin: Boo.Objects.PartnerMinimal): void {
    PriorityViewModel.show('app-components-manager-partner-tasktype-exclusions', {
      partner: partnerMin
    }).fail((err: string) => {
      toastr.error(`There was a problem loading partner task type exclusions. ${err}`);
    });
  }

  openPartnerSeoWorkDefaults(partnerMin: Boo.Objects.PartnerMinimal, isReadOnly: boolean): void {
    PriorityViewModel.ngShow('app-components-manager-partner-seo-work-defaults', { partner: partnerMin, isReadOnly: isReadOnly });
  }

  openPartnerEmails(partnerMin: Boo.Objects.PartnerMinimal): void {
    PriorityViewModel.ngShow('app-components-manager-partner-emails', { partnerMinimal: partnerMin });
  }

  addUserToPortal(partner: IPartnerMinimalWithHasPartnerUser): void {
    this.confirmAddUserToPortal()
      .then(() => {
        Utils.wrapDfd(this.partnerUserService.addCurrentUserToPartner(partner.PartnerId))
          .then(() => {
            toastr.success('User successfully added');
            partner.hasPartnerUserInPartner = true;
          })
          .fail((displayMessage: string) => {
            toastr.error(displayMessage);
          });
      });
  }

  managePartnerPricingTool(partnerRow: Boo.Objects.Partner): void {
    this.isLoading = true;

    Utils.wrapDfd(this.partnerService.get(partnerRow.PartnerId))
      .then((partner) => {
        PriorityViewModel.show('app-components-manager-managepartnerpricingtool', { partner: partner }).always(this.loadPartners.bind(this));
      })
      .fail((message: string) => {
        toastr.error(message);
      })
      .always(() => {
        this.isLoading = false;
      });
  }

  private loadPartners(): JQueryPromise<void> {
    return Utils.wrapDfd(this.partnerService.getPartnerMinimals())
      .then((partners) => {
        _.each(partners, (partner: IPartnerMinimalWithHasPartnerUser) => {
          partner.hasPartnerUserInPartner = _.any(this.partnerUsers, (partnerUser: Boo.Objects.PartnerUser) => {
            return partnerUser.PartnerId === partner.PartnerId;
          });
        });
        this.partners = partners as IPartnerMinimalWithHasPartnerUser[];
        this.filterPartners();
      });
  }

  private confirmAddUserToPortal(): JQueryPromise<boolean> {
    return $.Deferred<boolean>((dfd) => {
      const msg = 'Are you sure you would like to add your user to this portal?';
      bootbox.confirm(msg, (result: boolean) => {
        if (result === true) {
          dfd.resolve();
        } else {
          dfd.reject();
        }
      });
    }).promise();
  }
}

interface IPartnerMinimalWithHasPartnerUser extends Boo.Objects.PartnerMinimal {
  hasPartnerUserInPartner: boolean;
}

import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import TaskHelper from 'app/specialist/TaskHelper';
import { SessionStorageService } from '../../../../services/session-storage.service';
import Utils from '../../../../shared/utils';
import Timer from '../../../../shared/models/timer';

type ContentReviewBlogInput = Boo.Tasks.Inputs.Models.ContentReviewBlogInput;
type ContentReviewBlogOutput = Boo.Tasks.Outputs.Models.ContentReviewBlogOutput;

@Component({
    selector: 'app-components-specialist-actioncomponents-contentreview',
    templateUrl: './ContentReview.component.html'
})
export class ContentReviewComponent implements IActionWorkspace, IActivatable, AfterViewInit {
    @ViewChild('editorRichText', { static: true }) public editorRichText: ElementRef<HTMLTextAreaElement>;
    blogTitle = 'Blog Title';
    standardBacklinkContent = 'Standard Backlink Content';
    customerCountry: Boo.Objects.Country;
    customerLanguage: Boo.Objects.Language;
    goalSeconds = 900;
    reviewerToolbar: any;
    title = 'Content Review';

    actionViewModel: KnockoutObservable<IActionWorkspace>;
    input: ContentReviewBlogInput;
    output: ContentReviewBlogOutput;
    additionalKeywords: KnockoutObservableArray<string>;
    businessCategory: KnockoutObservable<string>;
    caretPosition: KnockoutObservable<number>;
    collapsedComments: KnockoutObservable<boolean>;
    completeActionValidation: KnockoutObservable<any>;
    content: KnockoutObservable<string>;
    contentAudit: KnockoutObservable<Boo.Objects.ContentAudit>;
    contentLabel: KnockoutObservable<string>;
    contentSubmitDate: KnockoutObservable<string>;
    contentTitle: KnockoutObservable<string>;
    contentUserId: KnockoutObservable<number>;
    currentAction: KnockoutObservable<Boo.Objects.Action>;
    deleteActionValidation: KnockoutObservable<any>;
    editorToolbar: any;
    findTargetKeyword: KnockoutObservable<boolean>;
    isLoading: KnockoutObservable<boolean>;
    keywordAreaRegexPattern: KnockoutComputed<string>;
    linkStrategyType: KnockoutObservable<string>;
    linkStrategyTypeId: KnockoutObservable<number>;
    revisionMessage: KnockoutObservable<string>;
    timer: Timer = new Timer();
    recommendedAnchorText: KnockoutObservable<string>;
    reviewerComments: KnockoutObservable<string>;
    reviewTaskDetails: KnockoutObservableArray<Boo.Objects.ReviewTaskDetail>;
    selectedText: KnockoutObservable<string>;
    selectionEnd: KnockoutObservable<number>;
    selectionStart: KnockoutObservable<number>;
    specialConsiderations: KnockoutObservable<string>;
    showAdditionalKeywords: KnockoutObservable<boolean>;
    showRecommendedAnchorText: KnockoutObservable<boolean>;
    showSpecialConsiderations: KnockoutObservable<boolean>;
    showTargetArea: KnockoutObservable<boolean>;
    showDuplicateWarning = false;
    showPlagiarismTable: boolean;
    tags: KnockoutObservable<string>;
    targetArea: KnockoutObservable<string>;
    targetKeyword: KnockoutObservable<string>;
    taskTypeId: KnockoutObservable<number>;
    titleLabel: KnockoutObservable<string>;
    toggleCommentsCollapsed: KnockoutObservable<boolean>;
    verticals: Boo.Objects.Vertical[];
    wordCount: KnockoutObservable<number>;
    writerUserId: number;
    isTaskImported: boolean;

    private cancelTaskCallBack: (showNotification: boolean) => void;
    private connectorWordsString = '';
    private plagiarismThreshold = 0.05; // 5%

    constructor(private sessionStorageService: SessionStorageService) { }

    activate(params: IActionWorkspaceActivationParams): JQueryPromise<void> {
        params.controlViewModel(this);
        this.input = params.input as ContentReviewBlogInput;
        this.output = params.output as ContentReviewBlogOutput;
        this.actionViewModel = ko.observable(null);
        this.currentAction = params.currentAction;
        this.cancelTaskCallBack = params.cancelTaskCallBack;
        this.additionalKeywords = ko.observableArray();
        this.businessCategory = ko.observable('');
        this.caretPosition = ko.observable(0);
        this.collapsedComments = ko.observable(true);
        this.content = ko.observable('');
        this.contentAudit = ko.observable();
        this.contentLabel = ko.observable(this.standardBacklinkContent);
        this.contentSubmitDate = ko.observable('');
        this.contentTitle = ko.observable('');
        this.contentUserId = ko.observable(0);
        this.findTargetKeyword = ko.observable(false);
        this.isLoading = ko.observable(true);
        this.linkStrategyType = ko.observable('');
        this.linkStrategyTypeId = ko.observable();
        this.revisionMessage = ko.observable(null);
        this.recommendedAnchorText = ko.observable('');
        this.reviewerComments = ko.observable('');
        this.reviewTaskDetails = ko.observableArray();
        this.selectedText = ko.observable('');
        this.selectionEnd = ko.observable(0);
        this.selectionStart = ko.observable(0);
        this.showAdditionalKeywords = ko.observable(false);
        this.showRecommendedAnchorText = ko.observable(false);
        this.showSpecialConsiderations = ko.observable(false);
        this.showTargetArea = ko.observable(false);
        this.specialConsiderations = ko.observable('');
        this.tags = ko.observable('');
        this.targetArea = ko.observable('');
        this.targetKeyword = ko.observable('');
        this.taskTypeId = ko.observable(this.input.TaskPost.Task.TaskTypeEnum);
        this.title = 'Content Review';
        this.titleLabel = ko.observable(this.blogTitle);
        this.toggleCommentsCollapsed = ko.observable(true);
        this.wordCount = ko.observable(0);

        this.editorToolbar = {
            toolbar: [
                {
                    name: 'document',
                    items: ['Source', 'Maximize']
                },
                // Defines toolbar group with name (used to create voice label)
                // and items in 3 subgroups.
                {
                    name: 'clipboard',
                    groups: ['clipboard', 'undo'],
                    items: ['Cut', 'Copy', 'Paste', 'PasteText',
                        'PasteFromWord', '-', 'Undo', 'Redo']
                },
                {
                    name: 'editing',
                    groups: ['find', 'selection', 'spellchecker'],
                    items: ['Find', 'Replace', '-', 'SelectAll']
                },
                {
                    name: 'links',
                    items: ['Link', 'Unlink', 'LinkCustomer']
                },
                '/', // new row
                {
                    name: 'basicstyles',
                    groups: ['basicstyles', 'cleanup'],
                    items: ['Bold', 'Italic', 'Underline', 'Strike',
                        'Subscript', 'Superscript', '-',
                        'RemoveFormat']
                },
                {
                    name: 'paragraph',
                    groups: ['list', 'indent', 'blocks', 'align', 'bidi'],
                    items: ['NumberedList', 'BulletedList', '-', 'Outdent',
                        'Indent', '-', 'Blockquote', '-',
                        'JustifyLeft', 'JustifyCenter', 'JustifyRight',
                        'JustifyBlock']
                }
            ]
        };

        this.reviewerToolbar = {
            toolbar: [
                {
                    name: 'document',
                    items: ['Source', 'Maximize']
                },
                // Defines toolbar group with name (used to create voice label)
                // and items in 3 subgroups.
                {
                    name: 'clipboard',
                    groups: ['clipboard', 'undo'],
                    items: ['Cut', 'Copy', 'Paste', 'PasteText',
                        'PasteFromWord', '-', 'Undo', 'Redo']
                },
                {
                    name: 'editing',
                    groups: ['find', 'selection', 'spellchecker'],
                    items: ['Find', 'Replace', '-', 'SelectAll']
                },
                '/', // new row
                {
                    name: 'basicstyles',
                    groups: ['basicstyles', 'cleanup'],
                    items: ['Bold', 'Italic', 'Underline', 'Strike',
                        'Subscript', 'Superscript', '-',
                        'RemoveFormat']
                },
                {
                    name: 'paragraph',
                    groups: ['list', 'indent', 'blocks', 'align', 'bidi'],
                    items: ['NumberedList', 'BulletedList', '-', 'Outdent',
                        'Indent', '-', 'Blockquote', '-',
                        'JustifyLeft', 'JustifyCenter', 'JustifyRight',
                        'JustifyBlock']
                }
            ]
        };

        if (this.currentAction().Task.TaskStatusEnum === Boo.Objects.Enums.TaskStatusEnum.Complete) {
          this.reviewerComments(TaskHelper.getComment(this.currentAction().Task));
        }

        const contentTask = this.currentAction
                && this.input
                && this.input.TaskPost
                && this.input.TaskPost.Task ? this.input.TaskPost.Task : null;
        this.writerUserId = contentTask ? contentTask.AssignedUserId : 0;

        this.isTaskImported = this.writerUserId === launchpad.config.systemAdminUserId;

        this.keywordAreaRegexPattern = ko.computed(() => {
            const keyword = launchpad.utils.escapeRegExp(this.targetKeyword());
            const area = launchpad.utils.escapeRegExp(this.targetArea());

            let result = keyword;
            if (area && area.trim() !== '') {
                result = `(${keyword}|${area})\\s*(${this.connectorWordsString})?\\s*(${keyword}|${area})`;
            }
            return result;
        });

        this.timer.start();

        this.content.extend({
            required: { message: 'Content is a required field.' },
            validation: [
                { validator: this.contentContainsOnlyOneLinkToCustomerSite.bind(this), message: 'Content must contain ONE (but only one) link to the customer\'s site.' },
                { validator: this.contentContainsMinimumKeywords.bind(this), message: 'Minimum Content Length is ' + this.minimumWords() + ' words.' },
                { validator: this.contentContainsAnchorText.bind(this), message: 'Content must include specified anchor text in order to complete task.' }
            ]
        });

        this.contentTitle.extend({
            required: { message: 'Title is a required field.' }
        });

        this.reviewerComments.extend({
            maxLength: 4000
        });

        this.tags.extend({
            required: { message: 'Blog Tags is a required field.' }
        });

        this.completeActionValidation = ko.validatedObservable([
            this.contentTitle, this.content, this.tags, this.reviewerComments
        ]);

        this.deleteActionValidation = ko.validatedObservable([
            this.reviewerComments
        ]);

        return Utils.wrapDfd(this.sessionStorageService.getStaticData())
            .then((data: any) => {
                this.verticals = data.Verticals;
                this.connectorWordsString = data.ConnectorWordsString;

                this.contentAudit(this.input.TaskPost.ContentAudit);

                if (this.contentAudit()) {
                    this.showDuplicateWarning = this.contentAudit().TotalPercentage > this.plagiarismThreshold;
                    this.showPlagiarismTable = (this.contentAudit().Url1 !== null 
                        || this.contentAudit().Url2 !== null 
                        || this.contentAudit().Url3 !== null);
                }

                this.customerCountry = this.currentAction().Customer.Country;
                this.customerLanguage = this.currentAction().Customer.Language;

                this.loadDisplayOnlyFields();
                this.loadEditableFields();
                this.loadContentAuditFields();
                this.loadKeywordsAndAreas();
                this.loadLinkStrategyFields();
                this.clearAllErrorMessages();
            }).always(() => {
                this.isLoading(false);
            });
    }

    ngAfterViewInit(): void {
        $(this.editorRichText.nativeElement).data('linkCustomer_taskAction', this.currentAction);
    }

    deactivate(): void {
        this.timer.pause();
    }

    isStepReadyToReject(): boolean {
        this.clearAllErrorMessages();

        if (!this.deleteActionValidation.isValid()) {
            this.deleteActionValidation.errors.showAllMessages();
            return false;
        }

        this.completeActionValidation.errors.showAllMessages(false);
        this.deleteActionValidation.errors.showAllMessages();

        this.output.TaskPost = this.input.TaskPost || {} as Boo.Objects.TaskPost;

        this.output.TaskPost.ReviewerComment = this.reviewerComments();
        this.output.RecommendedAnchorText = this.recommendedAnchorText();
        TaskHelper.setComment(this.currentAction().Task, this.reviewerComments(), this.currentAction().Task.AssignedUserId);

        return true;
    }

    isStepReadyToSave(): boolean {
        return false;
    }

    // Called by Step Control Right before completing an item (When Complete button is clicked, before actually completing)
    // Set Data on the Action or do validation here.
    isStepReadyToComplete(): boolean {
        this.clearAllErrorMessages();

        if (!this.completeActionValidation.isValid()) {
            this.completeActionValidation.errors.showAllMessages();
            return false;
        }

        // SaveTaskFields
        this.output.TaskPost = this.input.TaskPost || {} as Boo.Objects.TaskPost;
        this.output.TaskPost.ContentTitle = this.contentTitle();
        this.output.TaskPost.Content = this.content();
        this.output.TaskPost.Tags = this.tags();
        this.output.TaskPost.ReviewerComment = this.reviewerComments();
        this.output.RecommendedAnchorText = this.recommendedAnchorText();
        TaskHelper.setComment(this.currentAction().Task, this.reviewerComments(), this.currentAction().Task.AssignedUserId);

        return true;
    }

    isStepReadyToDelete(): boolean {
        return false;
    }

    passedGoalSeconds(): boolean {
        return this.timer.elapsedSeconds > this.goalSeconds;
    }

    private minimumWords(): number {
        if (this.currentAction().Task.LanguageId === Boo.Objects.Enums.LanguageEnum.Finnish
            && this.taskTypeId() === Boo.Objects.Enums.TaskTypeEnum.StandardBacklink) {
            return 300;
        }

        if (this.taskTypeId() === Boo.Objects.Enums.TaskTypeEnum.StandardBacklink) {
            return 400;
        }

        return 600;
    }

    private loadDisplayOnlyFields(): void {
        // Show UserId
        this.contentUserId(this.input.TaskPost.Task.AssignedUserId);

        const vertical = _.find(this.verticals, (vert) => {
            return vert.VerticalId === this.currentAction().Customer.VerticalId;
        });

        if (vertical) {
            this.businessCategory(vertical.Name);
        }

        // Show Submit Date
        this.contentSubmitDate(moment(this.currentAction().Task.InsertedDate).format('MM/DD/YYYY h:mm:ss A'));
    }

    private loadContentAuditFields(): void {
        const revisionCount = this.input.TaskPost.Task.ExternalEditCount;

        const revisionMessageMap = new Map<number,string>([
            [1, 'This is the first revision'],
            [2, 'This is the second revision'],
            [3, 'This is the third revision'],
            [4, 'This is the fourth revision'],
            [5, 'This is the fifth revision']
        ]); 
        if (revisionMessageMap.has(revisionCount)){
            this.revisionMessage(revisionMessageMap.get(revisionCount));
        } else if (revisionCount > 5) {
            this.revisionMessage(`This has been revised ${revisionCount} times`);
        }

        // Populate and show special instructions
        if (this.currentAction().Customer.Considerations) {
            this.specialConsiderations(this.currentAction().Customer.Considerations);
            this.showSpecialConsiderations(true);
        }
    }

    private loadKeywordsAndAreas(): void {
        this.targetKeyword = ko.observable(this.currentAction().Task.Keyword?.Name || 'N/A');
        if (this.currentAction().Task.Area) {
            this.targetArea(this.currentAction().Task.Area.Name);
            this.showTargetArea(true);
        }

        // Get additional Keywords to display
        const keywords = _.filter(this.input.WebsiteUrl.Keywords, (word) => {
            return word.IsActive && (word.KeywordId !== this.currentAction().Task.KeywordId || word.AreaId !== this.currentAction().Task.AreaId);
        });

        const doAdditionalKeywordsExist = keywords && keywords.length > 0;
        if (doAdditionalKeywordsExist) {
            const array: string[] = [];
            _.each(keywords, (word) => {
                array.push(launchpad.utils.getKeywordPhrase(word));
            });
            this.additionalKeywords(array);
        }
        this.showAdditionalKeywords(doAdditionalKeywordsExist);
    }

    private getAnchorText(): string {
        const linkStrategyTypeId = this.input.TaskLinkStrategyDetail.LinkStrategyId;
        switch (linkStrategyTypeId) {
            case Boo.Objects.Enums.LinkStrategyEnum.CompanyName:
                return this.currentAction().Customer.Name || '';
            case Boo.Objects.Enums.LinkStrategyEnum.RootDomain:
                return this.currentAction().Customer.Url || '';
            case Boo.Objects.Enums.LinkStrategyEnum.ActionPhrase:
                return this.input.TaskLinkStrategyDetail.LinkActionPhrase?.Name || '';
            default:
                return '';
        }
    }

    private loadLinkStrategyFields(): void {
        if (this.input.TaskLinkStrategyDetail &&
            this.input.TaskLinkStrategyDetail.LinkStrategyId) {

            // Set Link Strategy Type
            this.linkStrategyTypeId(this.input.TaskLinkStrategyDetail.LinkStrategyId);

            if (this.linkStrategyTypeId() === Boo.Objects.Enums.LinkStrategyEnum.ExactMatch) {
                this.showRecommendedAnchorText(false);
            } else {
                this.showRecommendedAnchorText(true);
            }

            // Set Anchor Text
            this.recommendedAnchorText(this.getAnchorText());
            // Show Link Strategy Type
            this.linkStrategyType(this.getLinkStrategyTypeDescription(this.linkStrategyTypeId()));
            this.linkStrategyTypeId(this.linkStrategyTypeId());
        }
    }

    private getLinkStrategyTypeDescription(linkStrategyTypeId: number): string {
        let result;
        switch (linkStrategyTypeId) {
            case Boo.Objects.Enums.LinkStrategyEnum.CompanyName:
                result = 'Company Name';
                break;
            case Boo.Objects.Enums.LinkStrategyEnum.RootDomain:
                result = 'Root Domain';
                break;
            case Boo.Objects.Enums.LinkStrategyEnum.ActionPhrase:
                result = 'Action Phrase';
                break;
            case Boo.Objects.Enums.LinkStrategyEnum.ExactMatch:
                result = 'Active Keywords';
                break;
            default:
                result = 'Unknown';
                break;
        }
        return result;
    }

    private loadEditableFields(): void {
        // Show Content
        let content;
        if (this.input.TaskPost.Content.indexOf('<p>') === -1) {
            content = ('<p>' + this.input.TaskPost.Content.replace(/\r?\n|\r/g, '</p><p>') + '</p>').split('<p></p>').join('');
        } else {
            content = this.input.TaskPost.Content;
        }

        this.content(content);

        // Show Content Title
        this.contentTitle(this.input.TaskPost.ContentTitle);

        // Show tags
        this.tags(this.input.TaskPost.Tags);

        // Get list of review task details
        if (this.input.ReviewTaskDetails.length > 0) {
            this.reviewTaskDetails(this.input.ReviewTaskDetails);
        }
    }


    private contentContainsOnlyOneLinkToCustomerSite(): boolean {
        // trailing slash is optional
        const url = this.input.WebsiteUrl.Url;
        const pattern = new RegExp(`href="${launchpad.utils.escapeRegExp(url)}/?"`);
        const matches = this.content().match(pattern);

        return matches && matches.length === 1;
    }

    private contentContainsMinimumKeywords(): boolean {
        return this.wordCount() >= this.minimumWords();
    }

    private clearAllErrorMessages(): void {
        this.deleteActionValidation.errors.showAllMessages(false);
        this.completeActionValidation.errors.showAllMessages(false);
    }

    // for link strategies other than exact match ensure required words are in content
    private contentContainsAnchorText(): boolean {
        let result = false;
        if (this.linkStrategyTypeId() === Boo.Objects.Enums.LinkStrategyEnum.ExactMatch) {
            result = true; // Exact match does not have additional anchor text
        } else {
            const filteredContent = $('<div />').html(this.content().replace('[[', '').replace(']]', '').replace(/&nbsp;/g, ' ')).text().toLowerCase();
            const anchorText = this.getAnchorText();
            result = filteredContent.indexOf(anchorText.trim().toLowerCase()) >= 0;
        }
        return result;
    }
}

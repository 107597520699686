import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { CustomerService } from '../../../../services/customer.service';
import { PartnerGroupService } from '../../../../services/partner-group.service';
import PartnerGroupEnum = Boo.Objects.Enums.PartnerGroupEnum;
import { PreAuditDetailService } from '../../../../services/pre-audit-detail.service';
import { forkJoin } from 'rxjs';
import { SessionStorageService } from '../../../../services/session-storage.service';
import Utils from '../../../../shared/utils';

/*
 * Adds external access note to the save and validation for the customer account group.
 */
@Component({
  selector: 'app-components-managecustomer-cms',
  templateUrl: './CmsSelection.Component.html'
})
export class CmsSelectionComponent implements OnInit {
  @Input() customerId: number;
  @Input() partnerId: number;
  @Input() canEditContentManagementSystem: boolean;
  @Input() isManagedByPartner: boolean;
  @Input() isExternalAccessShared: boolean;
  @Input() showBoostabilityAccessOption: boolean;

  @Output() cmsChange: EventEmitter<Boo.Objects.ContentManagementSystem> = new EventEmitter<Boo.Objects.ContentManagementSystem>();
  @Output() isManagedByPartnerChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isExternalAccessSharedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  isLoading = false;
  showManagedByPartnerSelect = false;
  contentManagementSystems: Boo.Objects.ContentManagementSystem[];
  contentManagementSystem: Boo.Objects.ContentManagementSystem;
  isThryvDudaSsoPartnerGroup: boolean;
  showDudaAdminSSO: boolean;
  // These partner groups all use the Thryv Duda account
  private thryvDudaSsoVisiblePartnerGroups: PartnerGroupEnum[] = [PartnerGroupEnum.Thryv, PartnerGroupEnum.Vivial, PartnerGroupEnum.Yellow];

  constructor(
    private customerService: CustomerService,
    private partnerGroupService: PartnerGroupService,
    private preAuditDetailService: PreAuditDetailService,
    private sessionStorageService: SessionStorageService) { }

  ngOnInit(): void {
    forkJoin([
      this.preAuditDetailService.getByCustomerId(this.customerId),
      this.sessionStorageService.getStaticData(),
      this.partnerGroupService.get(this.partnerId)
    ])
    .subscribe(([preAudit, staticData, partnerGroups]) => {
      this.contentManagementSystems = staticData.ContentManagementSystems;
      this.contentManagementSystem = preAudit ? staticData.ContentManagementSystems.find((i: { ContentManagementSystemId: any; }) => i.ContentManagementSystemId === preAudit.ContentManagementSystemId) : null;
      this.isThryvDudaSsoPartnerGroup = partnerGroups ? partnerGroups.some(x => this.thryvDudaSsoVisiblePartnerGroups.includes(x.GroupId)) : false;
      this.contentManagementSystemChanged();
      this.managedByPartnerChanged();
      this.toggleDudaAdminSSOURLVisability();
    });
  }

  contentManagementSystemChanged(): void {
    this.cmsChange.emit(this.contentManagementSystem);
    this.showManagedByPartnerSelect = this.contentManagementSystem &&
      this.contentManagementSystem.ContentManagementSystemId === Boo.Objects.Enums.ContentManagementSystems.Duda &&
      this.isThryvDudaSsoPartnerGroup;
    this.toggleDudaAdminSSOURLVisability();

    if (this.contentManagementSystem && this.contentManagementSystem.ContentManagementSystemId !== Boo.Objects.Enums.ContentManagementSystems.Duda) {
      this.isManagedByPartner = false;
      this.managedByPartnerChanged();
    }
  }

  managedByPartnerChanged(): void {
    this.isManagedByPartnerChange.emit(this.isManagedByPartner);
    this.toggleDudaAdminSSOURLVisability();
  }

  externalAccessSharedChanged(): void {
    this.isExternalAccessSharedChange.emit(this.isExternalAccessShared);
  }

  toggleDudaAdminSSOURLVisability(): void {
    if (this.isThryvDudaSsoPartnerGroup) {
      if (this.contentManagementSystem?.ContentManagementSystemId === Boo.Objects.Enums.ContentManagementSystems.Duda && this.isManagedByPartner === true) {
        this.showDudaAdminSSO = true; // Can Show Duda Admin SSO Link Generator
      } else {
        this.showDudaAdminSSO = false; // Shouldn't Show Duda Admin SSO Link Generator
      }
    }
  }

  recheckCustomerCMS(): void {
    this.isLoading = true;
    this.customerService.getCustomerCmsId(this.customerId).subscribe(
      x => {
        this.contentManagementSystem = this.contentManagementSystems.find(i => i.ContentManagementSystemId === x);
        x === 0 ? toastr.warning(`No CMS found for customer ${this.customerId}`) : toastr.success('Successfully Updated CMS');
      },
      err => {
        toastr.error(err);
        this.isLoading = false;
      },
      () => {
        this.contentManagementSystemChanged();
        this.isLoading = false;
      });
  }
}

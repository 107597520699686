import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClipboardModule } from '@angular/cdk/clipboard'
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormcontrolValidationMsgDirective } from './formcontrol-validation-msg.directive';
import { FormSubmitValidationMsgDirective } from './formsubmit-validation-msg.directive';
import { DatePickerComponent } from './components/datePicker/datePicker.component';
import { TableComponent } from './components/table/table.component';
import { TableHeaderComponent } from './components/table/table-header/table-header.component';
import { SortPipe } from './pipes/sort.pipe';
import { PagePipe } from './pipes/page.pipe';
import { OrderByPipe } from './pipes/orderBy.pipe';
import { DisableChangeDetectionDirective } from './directives/disableChangeDetection.directive';
import { WithPermissionsDirective } from './directives/withPermissions.directive';
import { TextWithLinksComponent } from './components/textWithLinks/textWithLinks.component';
import { ClickToCallComponent } from './components/clickToCall/clickToCall.component';
import { ChartComponent } from './components/chart/chart.component';
import { TimerComponent } from './components/timer/timer.component';
import { TreeComponent } from './components/tree/tree.component';
import { TogglePanelComponent } from './components/TogglePanel/TogglePanel.component';
import { ExternalLinkDirective } from './directives/externalLink.directive';
import { PasswordToggleDirective } from './directives/passwordToggle.directive';
import { SearchTextDirective } from './directives/searchText.directive';
import { SlideVisibleDirective } from './directives/slideVisible.directive';
import { RichTextDirective } from './directives/richText.directive';
import { ReturnKeyDirective } from './directives/returnKey.directive';
import { FocusOnCreateDirective } from './directives/focusOnCreate.directive';
import { NumericValueDirective } from './directives/numericValue.directive';
import { EnforceMinMaxDirective } from './directives/enforceMinMax.directive';
import { PercentageDirective } from './directives/percentage.directive';
import { Select2Directive } from './directives/select2.directive';
import { AutoExpandDirective } from './directives/autoExpand.directive';
import { FileDirective } from './directives/file.directive';
import { FileReaderDirective } from './directives/fileReader.directive';
import { DateTimePickerDirective } from './directives/dateTimePicker.directive';
import { TimePickerDirective } from './directives/timePicker.directive';
import { TimePickerStringDirective } from './directives/timePickerString.directive';
import { DataValidationDirective } from './directives/dataValidation.directive';
import { FormValidationDirective } from './directives/form-validation.directive'
import { NumericTextPipe } from './pipes/numericText.pipe';
import { NumberWithCommasPipe } from './pipes/numberWithCommas.pipe';
import { YesNoPipe } from './pipes/yesNo.pipe';
import { DateTextPipe } from './pipes/dateText.pipe';
import { DayNamePipe } from './pipes/dayName.pipe';
import { RemoveCommaPipe } from './pipes/removeComma.pipe';
import { PrettyJsonPipe } from './pipes/prettyJson.pipe';
import { SeoMinutesPipe } from './pipes/seoMinutes.pipe';
import { SeoHoursPipe } from './pipes/seoHours.pipe';
import { SafeUrlPipe } from './pipes/safeUrl.pipe';
import { SafeHtmlPipe } from './pipes/safeHtml.pipe';
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { EmailRecipientTypePipe } from './pipes/emailRecipientType.pipe';
import { PurifyHtmlPipe } from './pipes/purify-html.pipe';
import { SearchVolumeDisplay } from './pipes/search-volume-display.pipe';
import { CustomCheckboxLegacyComponent } from './components/customCheckboxLegacy/customCheckboxLegacy.component';
import { CustomChoiceComponent } from './components/customChoice/customChoice.component';
import { CustomToggleComponent } from './components/customToggle/customToggle.component';
import { CustomChoiceLegacyComponent } from './components/customChoiceLegacy/customChoiceLegacy.component';
import { LoadingOverlayDirective } from './directives/loading-overlay.directive';
import { ComposeComponent } from './components/compose/compose.component';
import { FrequencyPipe } from './pipes/frequency.pipe';
import { QuillModule } from 'ngx-quill';
import { QuillConfig } from './quill/quill-config';
import { FloatingPanelsComponent } from './components/floating-panels/floating-panels.component';
import { FloatingPanelComponent } from './components/floating-panel/floating-panel.component'
import { FloatingPanelContentDirective } from './directives/floating-panel-content.directive';
import { FloatingTabPanelComponent } from './components/floating-tab-panel/floating-tab-panel.component';
import { TabPanelComponent } from './components/TabPanel/tab-panel/tab-panel.component';
import { TabComponent } from './components/TabPanel/tab/tab.component';
import { LazyTabComponent } from './components/TabPanel/lazy-tab/lazy-tab.component';
import { DynamicTabComponent } from './components/TabPanel/dynamic-tab/dynamic-tab.component';
import { TabContentDirective } from './directives/tab-content.directive';
import { NavigationHandlerDirective } from './directives/navigationHandler.directive';
import { CustomerAccountGroupComponent } from './components/customer-account-group/customer-account-group.component';
import { MustacheValueDisplayComponent } from './mustache/components/mustache-value-display/mustache-value-display.component';
import { OnsiteInfoButtonCssPipe } from './pipes/onsite-info-button-color.pipe';
import { DateTimePickerComponent } from './components/date-time-picker/date-time-picker.component';
import { TimeZonePickerComponent } from './components/time-zone-picker/time-zone-picker.component';

const declarations = [
  FormcontrolValidationMsgDirective,
  FormSubmitValidationMsgDirective,
  DatePickerComponent,
  TableComponent,
  TableHeaderComponent,
  SortPipe,
  PagePipe,
  OrderByPipe,
  TextWithLinksComponent,
  ClickToCallComponent,
  ChartComponent,
  TimerComponent,
  TreeComponent,
  DisableChangeDetectionDirective,
  ExternalLinkDirective,
  PasswordToggleDirective,
  SearchTextDirective,
  SlideVisibleDirective,
  RichTextDirective,
  ReturnKeyDirective,
  FocusOnCreateDirective,
  NumericValueDirective,
  EnforceMinMaxDirective,
  PercentageDirective,
  Select2Directive,
  AutoExpandDirective,
  FileDirective,
  FileReaderDirective,
  DateTimePickerDirective,
  TimePickerDirective,
  TimePickerStringDirective,
  WithPermissionsDirective,
  DataValidationDirective,
  FormValidationDirective,
  LoadingOverlayDirective,
  ComposeComponent,
  NumericTextPipe,
  NumberWithCommasPipe,
  PrettyJsonPipe,
  SeoMinutesPipe,
  SeoHoursPipe,
  SafeHtmlPipe,
  SafeUrlPipe,
  YesNoPipe,
  DateTextPipe,
  DayNamePipe,
  RemoveCommaPipe,
  PurifyHtmlPipe,
  CustomCheckboxLegacyComponent,
  CustomChoiceComponent,
  CustomChoiceLegacyComponent,
  CustomToggleComponent,
  FrequencyPipe,
  SearchFilterPipe,
  EmailRecipientTypePipe,
  SearchVolumeDisplay,
  TogglePanelComponent,
  TabPanelComponent,
  TabComponent,
  LazyTabComponent,
  FloatingPanelsComponent,
  FloatingPanelComponent,
  FloatingPanelContentDirective,
  FloatingTabPanelComponent,
  DynamicTabComponent,
  TabContentDirective,
  NavigationHandlerDirective,
  CustomerAccountGroupComponent,
  MustacheValueDisplayComponent,
  OnsiteInfoButtonCssPipe,
  DateTimePickerComponent,
  TimeZonePickerComponent
];

@NgModule({
  declarations: declarations,
  imports: [
    CommonModule,
    FormsModule.withConfig({ callSetDisabledState: "whenDisabledForLegacyCode" }),
    ReactiveFormsModule,
    NgSelectModule,
    NgbModule,
    QuillModule.forRoot(QuillConfig.GetDefaults()),
    ClipboardModule,
    DragDropModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbModule,
    QuillModule,
    ...declarations
  ],
  providers: [
    DayNamePipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/users`;

  constructor(private http: HttpClient) { }

  getCurrentUser(): Observable<Boo.Objects.User> {
    return this.http.get<Boo.Objects.User>(`${this.baseUrl}/GetCurrentUser`);
  }

  login(username: string, password: string): Observable<IdentityModel.Client.JwtToken> {
    return this.http.post<IdentityModel.Client.JwtToken>(`${this.baseUrl}/Login`, {
      username: username,
      password: password
    });
  }

  sso(token: string): Observable<IdentityModel.Client.JwtToken> {
    return this.http.post<IdentityModel.Client.JwtToken>(`${this.baseUrl}/SingleSignOn`, { token: token });
  }

  sendPasswordResetEmail(email: string, requestedByCustomer = true): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/SendPasswordResetEmail`, { email: email, requestedByCustomer: requestedByCustomer });
  }

  resetPassword(email: string, resetToken: string, password: string, confirmPassword: string): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/ResetPassword`, {
      email,
      resetToken,
      password,
      confirmPassword
    });
  }

  impersonate(impersonateId: number): Observable<string> {
    const params = new HttpParams().set('impersonateId', impersonateId);
    return this.http.get<string>(`${this.baseUrl}/Impersonate`, { params: params });
  }

  save(user: Boo.Objects.User, customerId: number, isCustomerUserActive: boolean, canContact: boolean, canReceiveTextMessages: boolean, isCustomerUserPartner: boolean, phoneNumbers: Boo.Objects.CustomerUserPhoneNumber[], emailRecipientType: Boo.Objects.Enums.EmailRecipientTypes): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/Save`, {
      user: user,
      customerId: customerId,
      isCustomerUserActive: isCustomerUserActive,
      canContact: canContact,
      canReceiveTextMessages: canReceiveTextMessages,
      isCustomerUserPartner: isCustomerUserPartner,
      phoneNumbers: phoneNumbers,
      emailRecipientType: emailRecipientType
    });
  }

  csrReportingAccess(customerId: number): Observable<string> {
    const params = new HttpParams().set('customerId', customerId);
    return this.http.get<string>(`${this.baseUrl}/CsrReportingAccess`, { params: params });
  }

  getTaskNoteAcceptedFeedback(userId: number): Observable<Boo.Objects.TaskNoteFeedback[]> {
    const params = new HttpParams().set('userId', userId);
    return this.http.get<Boo.Objects.TaskNoteFeedback[]>(`${this.baseUrl}/GetTaskNoteAcceptedFeedback`, { params: params });
  }

  canWorkTaskType(taskTypeId: number): Observable<boolean> {
    const params = new HttpParams().set('taskTypeId', taskTypeId);
    return this.http.get<boolean>(`${this.baseUrl}/CanWorkTaskType`, { params: params });
  }

  getNameAndUsername(userId: number): Observable<string> {
    const params = new HttpParams().set('userId', userId);
    return this.http.get<string>(`${this.baseUrl}/GetNameAndUsername`, { params: params });
  }

  getSelectableByPartner(partnerId: number): Observable<Boo.Objects.SelectableUser[]> {
    const params = new HttpParams().set('partnerId', partnerId);
    return this.http.get<Boo.Objects.SelectableUser[]>(`${this.baseUrl}/GetSelectableByPartner`, { params: params });
  }

  getByUsername(username: string): Observable<Boo.Objects.User> {
    return this.http.post<Boo.Objects.User>(`${this.baseUrl}/GetByUsername`, { username: username });
  }
}

import { Component, Input } from '@angular/core';
import { HaloKeywordSuggestion, KeywordSuggestionSources, PageSuggestion } from '../../../../shared/models/keywords/keywords';

@Component({
  selector: 'app-components-shared-keyword-research-halo-keyword',
  templateUrl: './halo-keyword.component.html',
  styleUrls: ['../keyword-research/keyword-research.component.scss']
})
export class HaloKeywordComponent {
  @Input() customer: Boo.Objects.Customer;
  @Input() pageSuggestions: PageSuggestion[];
  @Input() haloKeywordSuggestion: HaloKeywordSuggestion;
  @Input() haloKeywordSuggestions: HaloKeywordSuggestion[];

  removeKeyword() {
    if (this.haloKeywordSuggestion.SourceId === KeywordSuggestionSources.User && this.haloKeywordSuggestion.HaloKeywordSuggestionId === 0) {
      this.haloKeywordSuggestions.splice(this.haloKeywordSuggestions.indexOf(this.haloKeywordSuggestion), 1);
    } else {
      this.haloKeywordSuggestion.IsSelected = false;
      this.haloKeywordSuggestion.IsModified = true;
    }
  }

  get validationMessage(): string{
    const keywordPhrases = this.pageSuggestions.filter(x => x.IsSelected && x.IsCampaignNational === this.customer.IsCampaignNational)
      .flatMap(c => c.KeywordSuggestions)
      .filter(x => x.IsSelected)
      .map(x => x.KeywordPhrase.toLowerCase().trim())
      .concat(this.haloKeywordSuggestions.filter(x => x.IsSelected && x.IsCampaignNational === this.customer.IsCampaignNational && x != this.haloKeywordSuggestion).map(x => x.KeywordPhrase.toLowerCase().trim()));

    if (keywordPhrases.includes(this.haloKeywordSuggestion.KeywordPhrase.toLowerCase().trim())) {
      return 'Duplicate';
    }
    return '';
  }
}

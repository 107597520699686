import { Component } from '@angular/core';
import System from 'framework/System';
import PresignedUrlRequest from 'app/models/typescript/PresignedUrlRequest';
import FileUploader from 'framework/FileUploader';
import FileSectionType from 'app/models/typescript/FileSectionType';

@Component({
	selector: 'app-components-managecustomer-uploadcustomerfile',
	templateUrl: './UploadCustomerFile.component.html'
})
export class UploadCustomerFileComponent implements IActivatable {
    protected priorityViewApi: app.interfaces.IPriorityViewPublicApi;
    public isLoading: KnockoutObservable<boolean> = ko.observable(false);
    public title = 'Upload File';
    public folder: KnockoutObservable<string> = ko.observable();
    public file: KnockoutObservable<File> = ko.observable();
    public user: Boo.Objects.User;
    public customerId: number;
    public filename: KnockoutComputed<string> = ko.computed(() => {
        let name = '';
        if (this.file()) {
            name = this.file().name;
        }
        return name;
    });
    public comment: KnockoutObservable<string> = ko.observable();
    public preSignedUrlRequest: PresignedUrlRequest = new PresignedUrlRequest();
    public useUniqueFileNames = true;
    public fileSectionTypeId: KnockoutObservable<number>;
    public fileSectionTypes: FileSectionType[];
    public pageValidation: KnockoutObservable<any> = ko.validatedObservable([this.comment, this.file]);

    constructor(private fileUploader: FileUploader) { }

    public canActivate(user: Boo.Objects.User, partner: Boo.Objects.Partner, params: app.managecustomer.interfaces.ICustomWebsiteViewModelActiveParams): boolean {
        this.user = user;
        return true;
    }

    public activate(params: IUploadcustomerFileViewModelParams): JQueryPromise<void> {
        this.priorityViewApi = params.priorityViewApi || null;
        this.customerId = params.customerFile.CustomerId;
        this.preSignedUrlRequest.BucketName = launchpad.config.S3Buckets.s3staticfiles;
        this.preSignedUrlRequest.Folder = 'customerfiles/' + params.customerFile.CustomerId + '/' + params.customerFile.FileSectionTypeId;
        this.preSignedUrlRequest.UseUniqueFileName = true;
        this.fileSectionTypeId = ko.observable(params.customerFile.FileSectionTypeId);
        this.fileSectionTypes = params.fileSectionTypes;
        this.file.extend({ required: true, message: 'A file is required.' });
        this.comment.extend({ maxLength: 4000 });

        return System.emptyPromise();
    }

    public close(): void {
        if (this.priorityViewApi) {
            this.priorityViewApi.resolve();
        }
    }

    public save(): void {
        if (!this.pageValidation.isValid()) {
            this.pageValidation.errors.showAllMessages(true);
            return;
        }

        this.fileUploader.upload(this.file(), this.preSignedUrlRequest.Folder, this.filename())
            .then((preSignedUrl: Boo.Objects.Amazon.PreSignedUrl) => {
                if (this.priorityViewApi) {
                    const file: Boo.Objects.CustomerFile = {
                        CustomerId: this.customerId,
                        FileSectionTypeId: this.fileSectionTypeId(),
                        UploadedByUserId: this.user.UserId,
                        Name: preSignedUrl.FileName,
                        S3Url: preSignedUrl.FullUrl,
                        Comment: this.comment(),
                    } as Boo.Objects.CustomerFile;
                    this.priorityViewApi.resolve(file);
                }
            })
            .fail(() => {
                if (this.priorityViewApi) {
                    this.priorityViewApi.reject('Unable to upload file.');
                } else {
                    toastr.error('Unable to upload file.');
                }
            })
            .always(() => {
                this.isLoading(false);
            });
    }
}

interface IUploadcustomerFileViewModelParams {
    customerFile: Boo.Objects.CustomerFile;
    priorityViewApi: app.interfaces.IPriorityViewPublicApi;
    fileSectionTypes: FileSectionType[];
}

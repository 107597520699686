<div [loadingOverlay]="isLoading()" *withPermissions="let allow of ['CanManagePartnerTicketTypes']" style="min-height:500px;">
	<div class="card">
		<ng-container *ngIf="partner()">
			<div class="card-header">
				<div >
					<span>{{(partner().PartnerId() ? 'Manage Partner: ' + partner().Name() + ' (' + partner().PartnerId() + ')' : 'Add Partner' + (partner().Name() ? ': ' + partner().Name() : ''))}}</span>
					<span>&nbsp;</span>
					<button (click)="cancel()" class="btn-close" title="Close"></button>
				</div>
			</div>
			<div class="card-body animated fadeIn">
				<div id="accordion" style="margin-bottom: 15px;">
					<div class="card mb-0" [class.card-danger]="partnerBasicInfoValidation().isAnyMessageShown() || businessEntityValidation().isAnyMessageShown()" [class.card-default]="!partnerBasicInfoValidation().isAnyMessageShown()" style="overflow: inherit;">
						<div class="card-header">
							<h4>
								<a class="btn btn-link" data-toggle="collapse" href="#basicInfo">Basic Information</a>
							</h4>
						</div>
						<div id="basicInfo" class="collapse show" data-parent="#accordion" >
							<div class="card-body">
									<div class="form-group row">
										<label class="col-md-4 col-form-label" for="partnerChannel">
											<app-components-helptext [key]="'Partner_PartnerChannel'"></app-components-helptext>
											Partner Channel
										</label>
										<div class="col-md-6">
											<select [dataValidation]="{ placement: 'top', value: partner().PartnerChannelId }" [ngModel]="partner().PartnerChannelId()" (ngModelChange)="partner().PartnerChannelId($event)" [ngModelOptions]="{ standalone: true }" class="form-select" name="partnerChannel">
												<option [ngValue]="0">Choose partner channel</option>
												<option *ngFor="let option of partnerChannels()" [ngValue]="option.PartnerChannelId">{{ option.Name }}</option>
											</select>
										</div>
									</div>
									<div class="form-group row">
										<label class="col-md-4 col-form-label" for="supportLevel">Support Level</label>
										<div class="col-md-6">
											<select [dataValidation]="{ placement: 'top', value: partner().SupportLevelId }" [ngModel]="partner().SupportLevelId()" (ngModelChange)="partner().SupportLevelId($event)" [ngModelOptions]="{ standalone: true }" class="form-select" name="supportLevel">
												<option [ngValue]="0">Choose service level</option>
												<option *ngFor="let option of partnerSupportLevels()" [ngValue]="option.PartnerSupportLevelId">{{ option.Name }}</option>
											</select>
											<p class="help-block">
												<strong>NOTE:</strong>
												By default, customer users setup in Tier 1 partners will be contacted by our customer service staff. Also, by default, customer users setup in Tier 2 partners will NOT be contacted by our customer service staff.
											</p>
										</div>
									</div>
									<ng-container *ngIf="partner().IsLive() && partner().IsActive()">
										<div class="form-group row">
											<label class="col-md-4 col-form-label">Partner Team</label>
											<div class="col-md-6">
												<select [ngModel]="teamId()" (ngModelChange)="teamId($event)" [ngModelOptions]="{ standalone: true }" class="form-select" name="partnerTeam">
													<option [ngValue]="undefined">Choose...</option>
													<option *ngFor="let option of teams()" [ngValue]="option.TeamId">{{ option.Name }}</option>
												</select>
											</div>
										</div>
									</ng-container>
									<div class="form-group row">
										<label class="col-md-4 col-form-label">Full White Label Support?</label>
										<div class="col-md-6">
											<div>
												<shared-components-customchoicelegacy [Settings]="{ value: partner().IsWhiteLabel }"></shared-components-customchoicelegacy>
											</div>
										</div>
									</div>
                  <div class="form-group row" *ngIf="partnerCanEnableTextMessaging()">
                    <label class="col-md-4 col-form-label">Allow Text Messaging</label>
                    <div class="col-md-6">
                      <div>
                        <shared-components-customchoicelegacy
                          [Settings]="{value: this.partner().AllowsTextMessaging }">
                        </shared-components-customchoicelegacy>
                      </div>
                    </div>
                  </div>
									<div class="form-group row">
										<label class="col-md-4 col-form-label" for="name">Name</label>
										<div class="col-md-6">
											<input [dataValidation]="{ placement: 'top', value: partner().Name }" [ngModel]="partner().Name()" (ngModelChange)="partner().Name($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" type="text" name="name"/>
										</div>
									</div>
									<div class="form-group row">
										<label class="col-md-4 col-form-label" for="businessDevelopmentRep">Business Development Representative</label>
										<div class="col-md-6">
											<select select2 [ngModel]="partner().BusinessDevelopmentRepUserId()" (ngModelChange)="partner().BusinessDevelopmentRepUserId($event)" [ngModelOptions]="{ standalone: true }" class="form-control" name="businessDevelopmentRep">
												<option [ngValue]="null">Choose business development representative</option>
												<option *ngFor="let option of boostabilityUsers()" [ngValue]="option.UserId">{{ option.FullName }}</option>
											</select>
										</div>
									</div>
									<div class="form-group row">
										<label class="col-md-4 col-form-label">Account Executive</label>
										<div class="col-md-6">
											<select select2 [ngModel]="partner().AccountExecutiveUserId()" (ngModelChange)="partner().AccountExecutiveUserId($event)" [ngModelOptions]="{ standalone: true }" class="form-control">
												<option [ngValue]="null">Choose account executive representative</option>
												<option *ngFor="let option of boostabilityUsers()" [ngValue]="option.UserId">{{ option.FullName }}</option>
											</select>
										</div>
									</div>
									<div class="form-group row">
										<label class="col-md-4 col-form-label" for="partnerManager">Partner Manager</label>
										<div class="col-md-6">
											<select [dataValidation]="{ placement: 'top', value: partner().PartnerManagerUserId }" [ngModel]="partner().PartnerManagerUserId()" (ngModelChange)="partner().PartnerManagerUserId($event)" [ngModelOptions]="{ standalone: true }" class="form-select" name="partnerManager">
												<option [ngValue]="0">Choose partner manager</option>
												<option *ngFor="let option of partnerManagers()" [ngValue]="option.PartnerUserId">{{ option.FullName }}</option>
											</select>
										</div>
									</div>
                  <div class="form-group row">
                    <label class="col-form-label col-md-4">Partner Group</label>
                    <div class="col-md-6">
                      <ng-select [multiple]="true" [(ngModel)]="selectedPartnerGroupIds" [items]="partnerGroups" bindLabel="Name" bindValue="GroupId">
                      </ng-select>
                      <p class="help-block">
                        <strong>OPTIONAL:</strong>
                        A partner can be assigned to a partner group to better identify when multiple partner portals belong to a single
                        partnership/agreement.
                      </p>
                    </div>
                  </div>
									<div class="form-group row">
										<label class="col-md-4 col-form-label">Finance Approved</label>
										<div class="col-md-6">
											<div>
												<shared-components-customchoicelegacy [Settings]="{ value: partner().HasFinanceApproved }"></shared-components-customchoicelegacy>
											</div>
										</div>
									</div>
									<div [hidden]="!(partner().PartnerId() !== 0)">
										<ng-container *ngIf="adminPartnerUser()">
											<div class="form-group row">
												<label class="col-md-4 col-form-label">Partner Username</label>
												<div class="col-md-6">
													<input [ngModel]="adminPartnerUser().Username()" (ngModelChange)="adminPartnerUser().Username($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [disabled]="true" [readonly]="true" type="text" class="form-control"/>
												</div>
											</div>
											<div class="form-group row">
												<label class="col-md-4 col-form-label">Partner Access Token</label>
												<div class="col-md-6">
													<input [ngModel]="adminPartnerUser().AccessToken()" (ngModelChange)="adminPartnerUser().AccessToken($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [disabled]="true" [readonly]="true" type="text" class="form-control"/>
												</div>
											</div>
										</ng-container>
									</div>
									<div class="form-group row">
										<label class="col-form-label col-md-4">Countries</label>
										<div class="col-md-6">
											<div [dataValidation]="{ placement: 'top', value: countriesIsValid }">
												<select [ngModel]="partnerCountryIds()" (ngModelChange)="partnerCountryIds($event)" [ngModelOptions]="{ standalone: true }" select2 multiple="multiple" class="form-control" name="userLanguageSelection">
													<option *ngFor="let option of countries()" [ngValue]="option.CountryId">{{ option.Name }}</option>
												</select>
											</div>
											<p class="help-block">
												<strong>NOTE:</strong>
												This determines the countries that the Partner's customers can be assigned to.
											</p>
										</div>
									</div>
									<div class="form-group row">
										<label class="col-form-label col-md-4">Business Entity</label>
										<div class="col-md-6">
											<select [dataValidation]="{ placement: 'top', value: partner().BusinessEntityId }" [ngModel]="partner().BusinessEntityId()" (ngModelChange)="partner().BusinessEntityId($event)" [ngModelOptions]="{ standalone: true }" [disabled]="isBusinessEntityLocked()" (change)="setDefaultCurrency()" class="form-select">
												<option [ngValue]="null">Choose The business entity this partner will be invoiced from</option>
												<option *ngFor="let option of businessEntities()" [ngValue]="option.BusinessEntityId">{{ option.Name }}</option>
											</select>
											<p class="help-block">
												<strong>NOTE:</strong>
												Once this field is set, it cannot be undone.
											</p>
										</div>
									</div>
									<div class="form-group row">
										<label class="col-form-label col-md-4">Partner Currency</label>
										<div class="col-md-6">
											<select [dataValidation]="{ placement: 'top', value: selectedCurrency }" [ngModel]="selectedCurrency()" (ngModelChange)="selectedCurrency($event)" [ngModelOptions]="{ standalone: true }" class="form-select">
												<option [ngValue]="undefined">Choose currency</option>
												<option *ngFor="let option of currencies()" [ngValue]="option.CurrencyId">{{ option.Name }}</option>
											</select>
											<p class="help-block">
												<strong>NOTE:</strong>
												All billing is done is USD, this field is for finance / reporting purposes only.
											</p>
										</div>
									</div>
									<ng-container *ngIf="!isNewPartner()">
										<div class="form-group row">
											<label class="col-form-label col-md-4">Partner Is Live</label>
											<div class="col-md-6">
												<div class="mt-2">{{partner().IsLive() ? 'Yes' : 'No'}}</div>
												<p class="help-block">
													<strong>NOTE:</strong>
													If a partner is live, then active customers will be inserted in this partner, work will be fulfilled on, and the partner will be invoiced for that work. If the partner is not live, then the partner exists for development purposes.
												</p>
											</div>
										</div>
										<div class="form-group row">
											<label class="col-form-label col-md-4">Partner Is Active</label>
											<div class="col-md-6">
												<div class="mt-2">{{partner().IsActive() ? 'Yes' : 'No'}}</div>
											</div>
										</div>
									</ng-container>
									<ng-container *ngIf="salesUser()">
										<div class="form-group row">
											<label class="col-form-label col-md-4">Inserted By</label>
											<div class="col-md-6">
												<div class="form-control-static">{{salesUser()}}</div>
											</div>
										</div>
									</ng-container>
							</div>
							<div class="card-footer">
								<div class="row">
									<div class="col-md-12">
										<button (click)="buttonNext(partner(), $event)" [attr.data-next]="navigationMap().basicInfo.next" class="btn btn-primary float-end" data-current="basicInfo">
											Next
											<i class="fa fa-angle-right"></i>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="card mb-0" [class.card-danger]="partnerBusinessContactsValidation().isAnyMessageShown()" [class.card-default]="!partnerBusinessContactsValidation().isAnyMessageShown()" style="overflow: auto;">
						<div class="card-header">
							<h4 >
								<a class="btn btn-link" data-toggle="collapse" href="#businesscontacts">Business Contacts</a>
							</h4>
						</div>
						<div id="businesscontacts" class="collapse" data-parent="#accordion">
							<div class="card-body">
								<div class="form-horizontal">
									<div class="form-group row">
										<label class="col-md-4 col-form-label">Technical Contact Name</label>
										<div class="col-md-6">
											<input [dataValidation]="{ placement: 'top', value: partner().TechnicalContactName }" [ngModel]="partner().TechnicalContactName()" (ngModelChange)="partner().TechnicalContactName($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" type="text" name="name"/>
										</div>
									</div>
									<div class="form-group row">
										<label class="col-md-4 col-form-label">Technical Contact Email Address</label>
										<div class="col-md-6">
											<input [dataValidation]="{ placement: 'top', value: partner().TechnicalContactEmail }" [ngModel]="partner().TechnicalContactEmail()" (ngModelChange)="partner().TechnicalContactEmail($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" type="text" name="name"/>
										</div>
									</div>
									<div class="form-group row">
										<label class="col-md-4 col-form-label">Technical Contact Phone</label>
										<div class="col-md-6">
											<input [dataValidation]="{ placement: 'top', value: partner().TechnicalContactPhone }" [ngModel]="partner().TechnicalContactPhone()" (ngModelChange)="partner().TechnicalContactPhone($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" type="text" name="name"/>
										</div>
									</div>
									<div [hidden]="!partner().IsWhiteLabel()" class="form-group row">
										<label class="col-md-4 col-form-label">Sales Phone Number</label>
										<div class="col-md-6">
											<input [dataValidation]="{ placement: 'top', value: partner().SalesPhoneNumber }" [ngModel]="partner().SalesPhoneNumber()" (ngModelChange)="partner().SalesPhoneNumber($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" type="text" name="name"/>
										</div>
									</div>
									<div [hidden]="!partner().IsWhiteLabel()" class="form-group row">
										<label class="col-md-4 col-form-label">Support Phone Number</label>
										<div class="col-md-6">
											<input [dataValidation]="{ placement: 'top', value: partner().SupportPhoneNumber }" [ngModel]="partner().SupportPhoneNumber()" (ngModelChange)="partner().SupportPhoneNumber($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" type="text" name="name"/>
										</div>
									</div>
									<div [hidden]="!partner().IsWhiteLabel()" class="form-group row">
										<label class="col-md-4 col-form-label">Support Email Address</label>
										<div class="col-md-6">
											<input [dataValidation]="{ placement: 'top', value: partner().SupportEmailAddress }" [ngModel]="partner().SupportEmailAddress()" (ngModelChange)="partner().SupportEmailAddress($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" type="text" name="name"/>
										</div>
									</div>
									<div [hidden]="!partner().IsWhiteLabel()" class="form-group row">
										<label class="col-md-4 col-form-label">System Notification Email Address</label>
										<div class="col-md-6">
											<input [dataValidation]="{ placement: 'top', value: partner().SystemNotificationEmailAddress }" [ngModel]="partner().SystemNotificationEmailAddress()" (ngModelChange)="partner().SystemNotificationEmailAddress($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" type="text" name="systemNotificationEmail"/>
										</div>
									</div>
                                  <div *ngIf="partner().AllowsTextMessaging() && partner().TextMessagingPhoneNumber()" class="form-group row">
                                    <label class="col-md-4 col-form-label">Text Messaging Number</label>
                                    <div class="col-md-6">
                                      <input class="form-control" [ngModel]="partner().TextMessagingPhoneNumber()" disabled="true" type="text" name="name" />
                                    </div>
                                  </div>
								</div>
							</div>
							<div class="card-footer">
								<div class="row">
									<div class="col-md-12">
										<button (click)="buttonPrevious(partner(), $event)" [attr.data-previous]="navigationMap().businesscontacts.previous" class="btn btn-primary float-start" data-current="businesscontacts">
											<i class="fa fa-angle-left"></i>
											Previous
										</button>
										<button (click)="buttonNext(partner(), $event)" [attr.data-next]="navigationMap().businesscontacts.next" class="btn btn-primary float-end" data-current="businesscontacts">
											Next
											<i class="fa fa-angle-right"></i>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div [hidden]="!navigationMap().emailsettings.isVisible" class="card mb-0" [class.card-danger]="partnerEmailSettingsValidation().isAnyMessageShown()" [class.card-default]="!partnerEmailSettingsValidation().isAnyMessageShown()">
						<div class="card-header">
							<h4 >
								<a class="btn btn-link" data-toggle="collapse" href="#emailsettings">Email Settings</a>
							</h4>
						</div>
						<div id="emailsettings" class="collapse" data-parent="#accordion" >
							<div class="card-body">
								<div class="form-horizontal">
									<div class="form-group row">
										<label class="col-md-4 col-form-label">
											System emails AND Reports may be sent from Boostability?
											<br/>
											<small>(clientsupport&#64;boostability.com)</small>
										</label>
										<div class="col-md-6">
											<div>
												<shared-components-customchoicelegacy [Settings]="{ value: reportsFromBoostability }"></shared-components-customchoicelegacy>
											</div>
										</div>
									</div>
									<div class="form-group row">
										<div [hidden]="!(false && reportsFromBoostability())">
											<label class="col-md-4 col-form-label">Email From Address for reports</label>
											<div class="col-md-6">
												<input [dataValidation]="{ placement: 'top', value: partner().ReportsEmailAddress }" [ngModel]="partner().ReportsEmailAddress()" (ngModelChange)="partner().ReportsEmailAddress($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [disabled]="reportsFromBoostability()" [readonly]="reportsFromBoostability()" class="form-control" type="text" name="name"/>
											</div>
										</div>
									</div>
									<div class="form-group row">
										<label class="col-md-4 col-form-label">Email From Address</label>
										<div class="col-md-6">
											<input [dataValidation]="{ placement: 'top', value: partner().FromEmailAddress }" [ngModel]="partner().FromEmailAddress()" (ngModelChange)="partner().FromEmailAddress($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [disabled]="reportsFromBoostability()" [readonly]="reportsFromBoostability()" class="form-control" type="text" name="fromemailaddress"/>
										</div>
									</div>
									<div class="form-group row">
										<label class="col-md-4 col-form-label">Email Display Name</label>
										<div class="col-md-6">
											<input [dataValidation]="{ placement: 'top', value: partner().FromEmailAddressDisplayName }" [ngModel]="partner().FromEmailAddressDisplayName()" (ngModelChange)="partner().FromEmailAddressDisplayName($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [disabled]="reportsFromBoostability()" [readonly]="reportsFromBoostability()" class="form-control" type="text" name="fromemailaddressdisplayname"/>
										</div>
									</div>
									<div class="form-group row">
										<label class="col-md-4 col-form-label">Email Bcc Address</label>
										<div class="col-md-6">
											<input [dataValidation]="{ placement: 'top', value: partner().BccEmailAddress }" [ngModel]="partner().BccEmailAddress()" (ngModelChange)="partner().BccEmailAddress($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [disabled]="reportsFromBoostability()" [readonly]="reportsFromBoostability()" class="form-control" type="text" name="bccemailaddress"/>
										</div>
									</div>
									<div class="form-group row">
										<label class="col-md-4 col-form-label">Email Bcc Display Name</label>
										<div class="col-md-6">
											<input [dataValidation]="{ placement: 'top', value: partner().BccEmailAddressDisplayName }" [ngModel]="partner().BccEmailAddressDisplayName()" (ngModelChange)="partner().BccEmailAddressDisplayName($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [disabled]="reportsFromBoostability()" [readonly]="reportsFromBoostability()" class="form-control" type="text" name="bccemailaddressdisplayname"/>
										</div>
									</div>
									<div class="form-group row">
										<label class="col-md-4 col-form-label">SMTP Host</label>
										<div class="col-md-6">
											<input [dataValidation]="{ placement: 'top', value: partner().SmtpHost }" [ngModel]="partner().SmtpHost()" (ngModelChange)="partner().SmtpHost($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [disabled]="reportsFromBoostability()" [readonly]="reportsFromBoostability()" class="form-control" type="text" name="smtphost"/>
										</div>
									</div>
									<div class="form-group row">
										<label class="col-md-4 col-form-label">SMTP Username</label>
										<div class="col-md-6">
											<input [dataValidation]="{ placement: 'top', value: partner().SmtpUsername }" [ngModel]="partner().SmtpUsername()" (ngModelChange)="partner().SmtpUsername($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [disabled]="reportsFromBoostability()" [readonly]="reportsFromBoostability()" class="form-control" type="text" name="smtpusername"/>
										</div>
									</div>
									<div class="form-group row">
										<label class="col-md-4 col-form-label">SMTP Password</label>
										<div (click)="getPassword()" class="col-md-6">
											<input [dataValidation]="{ placement: 'top', value: partner().SmtpPassword }" [ngModel]="partner().SmtpPassword()" (ngModelChange)="partner().SmtpPassword($event)" [ngModelOptions]="{ standalone: true }" [disabled]="reportsFromBoostability()" passwordToggle class="form-control" type="password" autocomplete="off" name="smtppassword"/>
										</div>
									</div>
									<div class="form-group row">
										<label class="col-md-4 col-form-label">SMTP Port</label>
										<div class="col-md-6">
											<input [dataValidation]="{ placement: 'top', value: partner().SmtpPort }" [ngModel]="partner().SmtpPort()" (ngModelChange)="partner().SmtpPort($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [disabled]="reportsFromBoostability()" [readonly]="reportsFromBoostability()" class="form-control" type="text" name="smtpport"/>
										</div>
									</div>
									<div class="form-group row">
										<label class="col-md-4 col-form-label" for="testEmailAddress">Test Email Settings</label>
										<div class="col-md-6">
											<div class="input-group">
												<input [dataValidation]="{ placement: 'top', value: testEmailAddress }" [ngModel]="testEmailAddress()" (ngModelChange)="testEmailAddress($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" placeholder="Email address to send test email" name="testEmailAddress"/>
												<button (click)="sendTestEmail()" class="btn btn-info" type="button">Send Test Email</button>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="card-footer">
								<div class="row">
									<div class="col-md-12">
										<button (click)="buttonPrevious(partner(), $event)" [attr.data-previous]="navigationMap().emailsettings.previous" class="btn btn-primary float-start" data-current="emailsettings">
											<i class="fa fa-angle-left"></i>
											Previous
										</button>
										<button [hidden]="!isNewPartner()" [disabled]="isLoading()" (click)="buttonNext(partner(), $event)" [attr.data-next]="navigationMap().emailsettings.next" class="btn btn-success float-end" data-current="emailsettings">
											Create Partner and Continue
											<i class="fa fa-angle-right"></i>
										</button>
										<button [hidden]="!isExistingPartner()" (click)="buttonNext(partner(), $event)" [attr.data-next]="navigationMap().emailsettings.next" class="btn btn-primary float-end" data-current="emailsettings">
											Next
											<i class="fa fa-angle-right"></i>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div style="overflow: auto;" class="card mb-0">
						<div class="card-header">
							<h4 >
								<a class="btn btn-link" data-toggle="collapse" href="#externalaccess">External Access</a>
							</h4>
						</div>
						<div id="externalaccess" class="collapse" data-parent="#accordion">
							<div class="card-body">
								<div class="customer-account-default-group">
									<ng-container *ngFor="let $data of customerAccountDefaultGroups">
										<label class="col-form-label">{{$data.title}}</label>
										<compose Selector="app-components-manager-customeraccountdefaultgroup" [ActivateOptions]="{
                                        partnerId: partner().PartnerId(),
                                        customerAccountTypes: $data.customerAccountTypes,
                                        showSaveButton: false,
                                        publicApiCallback: customerAccountDefaultGroupApiCallback.bind(this)
                                    }"></compose>
									</ng-container>
								</div>
							</div>
							<div class="card-footer">
								<div class="row">
									<div class="col-md-12">
										<button (click)="buttonPrevious(partner(), $event)" [attr.data-previous]="navigationMap().externalaccess.previous" class="btn btn-primary float-start" data-current="externalaccess">
											<i class="fa fa-angle-left"></i>
											Previous
										</button>
										<button (click)="buttonNext(partner(), $event)" [attr.data-next]="navigationMap().externalaccess.next" class="btn btn-primary float-end" data-current="externalaccess">
											Next
											<i class="fa fa-angle-right"></i>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<ng-container *ngIf="allow().CanManagePartnerTicketTypes()">
						<div [hidden]="!navigationMap().tickettypes.isVisible" class="card mb-0">
							<div class="card-header">
								<h4 >
									<a class="btn btn-link" data-toggle="collapse" href="#tickettypes">Ticket Types</a>
								</h4>
							</div>
							<div id="tickettypes" class="collapse" data-parent="#accordion">
								<div class="card-body">
									<form class="form-group row">
										<label class="col-md-2 col-form-label" for="editPartnerTicketType">Ticket Types</label>
										<div class="col-md-6">
											<select [ngModel]="partnerTicketTypeIds()" (ngModelChange)="partnerTicketTypeIds($event)" [ngModelOptions]="{ standalone: true }" [select2]="{ closeOnSelect: false }" class="form-control" multiple="multiple" name="editPartnerTicketType">
												<option *ngFor="let option of ticketTypes()" [ngValue]="option.TicketTypeId">{{ option.Name }}</option>
											</select>
										</div>
									</form>
								</div>
								<div class="card-footer">
									<div class="row">
										<div class="col-md-12">
											<button (click)="buttonPrevious(partner(), $event)" [attr.data-previous]="navigationMap().tickettypes.previous" class="btn btn-primary float-start" data-current="tickettypes">
												<i class="fa fa-angle-left"></i>
												Previous
											</button>
											<button [hidden]="!isNewPartner()" [disabled]="isLoading()" (click)="buttonNext(partner(), $event)" [attr.data-next]="navigationMap().tickettypes.next" class="btn btn-success float-end" data-current="tickettypes">
												Create Partner and Continue
												<i class="fa fa-angle-right"></i>
											</button>
											<button [hidden]="!isExistingPartner()" (click)="buttonNext(partner(), $event)" [attr.data-next]="navigationMap().tickettypes.next" class="btn btn-primary float-end" data-current="tickettypes">
												Next
												<i class="fa fa-angle-right"></i>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</ng-container>
					<div class="card mb-0" [class.card-danger]="hostValidation().isAnyMessageShown()" [class.card-default]="!hostValidation().isAnyMessageShown()">
						<div class="card-header">
							<h4 >
								<a class="btn btn-link" data-toggle="collapse" href="#websitehosts">Website Hosts</a>
							</h4>
						</div>
						<div id="websitehosts" class="collapse" data-parent="#accordion">
							<div class="card-body">
								<div class="form-horizontal">
									<ng-container *ngFor="let $data of hostConfigs()">
										<fieldset style="margin: 10px auto;">
											<legend style="font-size: 110%;">
												<span>{{$data.HostName()}}</span>
												Host Configuration
											</legend>
											<div>
												<div class="form-group row">
													<label class="col-md-4 col-form-label">API User</label>
													<div class="col-md-6">
														<input [dataValidation]="{ placement: 'top', value: $data.ApiAuthKey }" [ngModel]="$data.ApiAuthKey()" (ngModelChange)="$data.ApiAuthKey($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" type="text"/>
													</div>
												</div>
												<div class="form-group row">
													<label class="col-md-4 col-form-label">
														Password
														<small class="text-danger">(Keep this secret!)</small>
													</label>
													<div class="col-md-6">
														<input [dataValidation]="{ placement: 'top', value: $data.SetApiAuthSecret }" [ngModel]="$data.SetApiAuthSecret()" (ngModelChange)="$data.SetApiAuthSecret($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" [attr.placeholder]="($data.HostConfigurationId() ? '(keep existing)' : '')" class="form-control" type="password"/>
													</div>
												</div>
												<ng-container *ngIf="$data.HostId() === Hosts.Duda">
													<div class="form-group row">
														<label class="col-md-4 col-form-label">Email</label>
														<div class="col-md-6">
															<input [dataValidation]="{ placement: 'top', value: $data.AdminAccountId }" [ngModel]="$data.AdminAccountId()" (ngModelChange)="$data.AdminAccountId($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" type="text"/>
														</div>
													</div>
												</ng-container>
											</div>
										</fieldset>
									</ng-container>
								</div>
							</div>
							<div class="card-footer">
								<div class="row">
									<div class="col-md-12">
										<button (click)="buttonPrevious(partner(), $event)" [attr.data-previous]="navigationMap().websitehosts.previous" class="btn btn-primary float-start" data-current="websitehosts">
											<i class="fa fa-angle-left"></i>
											Previous
										</button>
										<button (click)="buttonNext(partner(), $event)" [attr.data-next]="navigationMap().websitehosts.next" class="btn btn-primary float-end" data-current="websitehosts">
											Next
											<i class="fa fa-angle-right"></i>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div [hidden]="!navigationMap().whitelabel.isVisible" class="card mb-0" [class.card-danger]="partnerWhiteLabelValidation().isAnyMessageShown()" [class.card-default]="!partnerWhiteLabelValidation().isAnyMessageShown()">
						<div class="card-header">
							<h4 >
								<a class="btn btn-link" data-toggle="collapse" href="#whitelabel">White Label Details</a>
							</h4>
						</div>
						<div id="whitelabel" class="collapse" data-parent="#accordion">
							<div class="card-body">
								<div class="form-horizontal">
									<div class="form-group row">
										<label class="col-md-6 col-form-label">
											Partner's Domain
											<br/>
											(Example www.yourdomain.com)
										</label>
										<div class="col-md-6">
											<input [dataValidation]="{ placement: 'top', value: partner().WwwUrl }" [ngModel]="partner().WwwUrl()" (ngModelChange)="partner().WwwUrl($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" type="text" name="wwwurl"/>
										</div>
									</div>
									<div class="form-group row">
										<label class="col-md-6 col-form-label">
											White Label Reporting URL
											<br/>
											(Example: reporting.yourdomain.com)
										</label>
										<div class="col-md-6">
											<input [dataValidation]="{ placement: 'top', value: partner().ReportingUrl }" [ngModel]="partner().ReportingUrl()" (ngModelChange)="partner().ReportingUrl($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" type="text" name="reportingurl"/>
										</div>
									</div>
									<div class="form-group row">
										<label class="col-md-6 col-form-label">
											Partner Requires Single Sign-on URL Support
											<br/>
											(Partner may only access launchpad through single sign-on link)
										</label>
										<div class="col-md-4">
											<div>
												<shared-components-customchoicelegacy [Settings]="{ value: partner().IsSingleSignonRequired }"></shared-components-customchoicelegacy>
											</div>
										</div>
									</div>
									<div class="form-group row">
										<label class="col-md-6 col-form-label" for="languages">Default Language</label>
										<div class="col-md-4">
											<select [ngModel]="partner().DefaultLanguageId()" (ngModelChange)="partner().DefaultLanguageId($event)" [ngModelOptions]="{ standalone: true }" name="languages" id="languages" class="form-select">
												<option [ngValue]="undefined">Select a Language</option>
												<option *ngFor="let option of languages()" [ngValue]="option.LanguageId">{{ option.Name }}</option>
											</select>
										</div>
									</div>
								</div>
							</div>
							<div class="card-footer">
								<div class="row">
									<div class="col-md-12">
										<button (click)="buttonPrevious(partner(), $event)" [attr.data-previous]="navigationMap().whitelabel.previous" class="btn btn-primary float-start" data-current="whitelabel">
											<i class="fa fa-angle-left"></i>
											Previous
										</button>
										<button (click)="buttonNext(partner(), $event)" [attr.data-next]="navigationMap().whitelabel.next" class="btn btn-primary float-end" data-current="whitelabel">
											Next
											<i class="fa fa-angle-right"></i>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div [hidden]="!navigationMap().logos.isVisible" class="card mb-0" [class.card-danger]="partnerLogosValidation().isAnyMessageShown()" [class.card-default]="!partnerLogosValidation().isAnyMessageShown()">
						<div class="card-header">
							<h4 >
								<a class="btn btn-link" data-toggle="collapse" href="#logos">Logos</a>
							</h4>
						</div>
						<div id="logos" class="collapse" data-parent="#accordion">
							<div class="card-body">
								<div class="form-horizontal">
									<div class="form-group row">
										<label class="col-md-4 col-form-label">Big Logo in .png format (300px by 75px max dimensions)</label>
										<div class="col-md-6">
											<button (click)="uploadFile(partner(), $event)" class="btn btn-primary btn-sm" data-logotype="large">
												<i class="fa fa-upload"></i>
												Upload File
											</button>
											<img [hidden]="!partner().LogoLargeS3Url()" [attr.src]="partner().LogoLargeS3Url()" class="img-rounded" alt="Large Logo" style="width: auto; height: auto; max-width: 300px; max-height: 75px;"/>
										</div>
									</div>
									<div class="form-group row">
										<label class="col-md-4 col-form-label">Small Logo in .png format (250px by 30px max dimensions)</label>
										<div class="col-md-6">
											<button (click)="uploadFile(partner(), $event)" class="btn btn-primary btn-sm" data-logotype="small">
												<i class="fa fa-upload"></i>
												Upload File
											</button>
											<img [hidden]="!partner().LogoSmallS3Url()" [attr.src]="partner().LogoSmallS3Url()" class="img-rounded" alt="Small Logo" style="width: auto; height: auto; max-width: 250px; max-height: 30px;"/>
										</div>
									</div>
								</div>
							</div>
							<div class="card-footer">
								<div class="row">
									<div class="col-md-12">
										<button (click)="buttonPrevious(partner(), $event)" [attr.data-previous]="navigationMap().logos.previous" class="btn btn-primary float-start" data-current="logos">
											<i class="fa fa-angle-left"></i>
											Previous
										</button>
										<button (click)="buttonNext(partner(), $event)" [attr.data-next]="navigationMap().logos.next" class="btn btn-primary float-end" data-current="logos">
											Next
											<i class="fa fa-angle-right"></i>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<ng-container *ngIf="isExistingPartner()">
						<div [hidden]="!navigationMap().files.isVisible" class="card mb-0" [class.card-default]="true">
							<div class="card-header">
								<h4 >
									<a class="btn btn-link" data-toggle="collapse" href="#files">Files</a>
								</h4>
							</div>
							<div id="files" class="collapse" data-parent="#accordion">
								<div class="card-body">
									<compose Selector="app-components-manager-partnerfiles" [ActivateOptions]="{
                                        partnerId: partner().PartnerId()
                                    }"></compose>
								</div>
								<div class="card-footer">
									<div class="row">
										<div class="col-md-12">
											<button (click)="buttonPrevious(partner(), $event)" [attr.data-previous]="navigationMap().files.previous" class="btn btn-primary float-start" data-current="files">
												<i class="fa fa-angle-left"></i>
												Previous
											</button>
											<button (click)="buttonNext(partner(), $event)" [attr.data-next]="navigationMap().files.next" class="btn btn-primary float-end" data-current="files">
												Next
												<i class="fa fa-angle-right"></i>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</ng-container>
					<div [hidden]="!navigationMap().billing.isVisible" class="card mb-0" [class.card-danger]="partnerBillingValidation().isAnyMessageShown()" [class.card-default]="!partnerBillingValidation().isAnyMessageShown()">
						<div class="card-header">
							<h4 >
								<a class="btn btn-link" data-toggle="collapse" href="#billing">Billing Information</a>
							</h4>
						</div>
						<div id="billing" class="collapse" data-parent="#accordion">
							<div class="card-body">
								<div class="row">
									<div class="col-md-6">
										<div class="form-horizontal">
											<div class="form-group row">
												<label class="col-md-4 col-form-label">Billing Contact Name</label>
												<div class="col-md-8">
													<input [dataValidation]="{ placement: 'top', value: partner().BillingContactName }" [ngModel]="partner().BillingContactName()" (ngModelChange)="partner().BillingContactName($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" type="text" name="billingcontactname"/>
												</div>
											</div>
											<div class="form-group row">
												<label class="col-md-4 col-form-label">Billing Contact Email Address</label>
												<div class="col-md-8">
													<input [dataValidation]="{ placement: 'top', value: partner().BillingContactEmailAddress }" [ngModel]="partner().BillingContactEmailAddress()" (ngModelChange)="partner().BillingContactEmailAddress($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" type="text" name="billingcontactemailaddress"/>
												</div>
											</div>
											<div class="form-group row">
												<label class="col-md-4 col-form-label">Billing Contact Phone</label>
												<div class="col-md-8">
													<input [dataValidation]="{ placement: 'top', value: partner().BillingContactPhone }" [ngModel]="partner().BillingContactPhone()" (ngModelChange)="partner().BillingContactPhone($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" type="text" name="billingcontactphone"/>
												</div>
											</div>
											<div class="form-group row">
												<label class="col-md-4 col-form-label">
													Address
													<small>(Line 1)</small>
												</label>
												<div class="col-md-8">
													<input [dataValidation]="{ placement: 'top', value: partner().Address1 }" [ngModel]="partner().Address1()" (ngModelChange)="partner().Address1($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" class="form-control"/>
												</div>
											</div>
											<div class="form-group row">
												<label class="col-md-4 col-form-label">
													Address
													<small>(Line 2)</small>
												</label>
												<div class="col-md-8">
													<input [dataValidation]="{ placement: 'top', value: partner().Address2 }" [ngModel]="partner().Address2()" (ngModelChange)="partner().Address2($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" class="form-control"/>
												</div>
											</div>
											<div class="form-group row">
												<label class="col-md-4 col-form-label">City</label>
												<div class="col-md-8">
													<input [dataValidation]="{ placement: 'top', value: partner().City }" [ngModel]="partner().City()" (ngModelChange)="partner().City($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" class="form-control"/>
												</div>
											</div>
											<div class="form-group row">
												<label class="col-md-4 col-form-label">Postal Code</label>
												<div class="col-md-8">
													<input [dataValidation]="{ placement: 'top', value: partner().PostalCode }" [ngModel]="partner().PostalCode()" (ngModelChange)="partner().PostalCode($event)" [ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" class="form-control"/>
												</div>
											</div>
											<div [slideVisible]="filteredStates().length > 1" class="form-group row">
												<label class="col-md-4 col-form-label">State</label>
												<div class="col-md-8">
													<select [dataValidation]="{ placement: 'top', value: partner().StateId }" [ngModel]="partner().StateId()" (ngModelChange)="partner().StateId($event)" [ngModelOptions]="{ standalone: true }" class="form-select">
														<option *ngFor="let option of filteredStates()" [ngValue]="option.StateId">{{ option.Name }}</option>
													</select>
												</div>
											</div>
											<div class="form-group row">
												<label class="col-md-4 col-form-label">Country</label>
												<div class="col-md-8">
													<select [dataValidation]="{ placement: 'top', value: partner().CountryId }" [ngModel]="partner().CountryId()" (ngModelChange)="partner().CountryId($event)" [ngModelOptions]="{ standalone: true }" class="form-select">
														<option *ngFor="let option of countries()" [ngValue]="option.CountryId">{{ option.Name }}</option>
													</select>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="card-footer">
								<div class="row">
									<div class="col-md-12">
										<button (click)="buttonPrevious(partner(), $event)" [attr.data-previous]="navigationMap().billing.previous" class="btn btn-primary float-start" data-current="billing">
											<i class="fa fa-angle-left"></i>
											Previous
										</button>
										<button (click)="buttonNext(partner(), $event)" [attr.data-next]="navigationMap().billing.next" class="btn btn-primary float-end" data-current="billing">
											Next
											<i class="fa fa-angle-right"></i>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div [dataValidation]="{ placement: 'top', value: partner().StorefrontId }" [hidden]="partner().IsActive()" class="card mb-0">
						<div class="card-header">
							<h4 >
								<a class="btn btn-link" data-toggle="collapse" href="#activate">Activate Partner</a>
							</h4>
						</div>
						<div id="activate" data-parent="#accordion" class="collapse">
							<div class="card-body">
								<div class="form-horizontal">
									<div [hidden]="!partner().IsWhiteLabel()" class="form-group row">
										<label class="col-md-6 col-form-label">
											Are whitelabel elements setup and ready for the partner to be created?
											<br/>
											(white label reporting URL ready, SSL cert approved and in place, Google Analytics access granted, phone and email support)
										</label>
										<div class="col-md-4">
											<div>
												<shared-components-customchoicelegacy [Settings]="{ value: isWhiteLabelSetupComplete }"></shared-components-customchoicelegacy>
											</div>
										</div>
									</div>
									<div class="form-group row">
										<label class="col-md-6 col-form-label">Create Dev Partner Account</label>
										<div class="col-md-4">
											<div>
												<shared-components-customchoicelegacy [Settings]="{ value: createDevPartnerRequested }"></shared-components-customchoicelegacy>
											</div>
										</div>
									</div>
									<div class="form-group row">
										<label class="col-md-6 col-form-label">Create QA Partner Account</label>
										<div class="col-md-4">
											<div>
												<shared-components-customchoicelegacy [Settings]="{ value: createQAPartnerRequested }"></shared-components-customchoicelegacy>
											</div>
										</div>
									</div>
									<div class="text-center">
										<button [disabled]="!isActivateButtonEnabled()" (click)="activatePartner()" class="btn btn-primary">
											<i class="fa fa-bolt"></i>
											Activate partner
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="card card-body bg-light d-block text-end">
					<button (click)="cancel()" class="btn btn-link">Cancel</button>
					<button [disabled]="!isSaveButtonEnabled()" (click)="save()" class="btn btn-success">
						<i class="fa fa-save"></i>
						Save
					</button>
				</div>
			</div>
		</ng-container>
	</div>
</div>

<ng-template #provisionTextMessageNumberModal let-modal>
<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Provision Phone Number</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
</div>
<div class="modal-body">
  <span>This will provision a new toll-free number for partner: {{ partner().Name() }}</span>
</div>
<div class="modal-footer">
  <button (click)="modal.dismiss()" class="btn btn-outline">Cancel</button>
  <button (click)="modal.close(true)" class="btn btn-primary">Confirm</button>
</div>
</ng-template>

import { Component, Input, OnInit } from '@angular/core';
import { CustomerTargetUrlService } from '../../../../services/customer-target-url.service';
import ProactiveUnderPerformingKeywordValidationState from 'app/specialist/actioncomponents/ProactiveUnderPerformingKeywordValidationState';
import { CustomerService } from '../../../../services/customer.service';
import { UserService } from '../../../../services/user.service';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'app-components-specialist-actioncomponents-customertargeturls',
    templateUrl: './customer-target-urls.component.html'
})
export class CustomerTargetUrlsComponent implements OnInit {
    @Input() options: CustomerTargetUrlsViewModelOptions;
    urls: Boo.Objects.CustomerTargetUrl[] = [];
    isLoaded: boolean;
    private customer: Boo.Objects.Customer;
    private validationState: KnockoutObservable<ProactiveUnderPerformingKeywordValidationState>;

    constructor(
        private customerService: CustomerService,
        private customerTargetUrlService: CustomerTargetUrlService,
        private userService: UserService) { }

    ngOnInit() {
        this.isLoaded = false;
        this.validationState = this.options.validationState;

        let targetUrlsObservable;
        if (this.options.isMoneyBack === false) {
            targetUrlsObservable = this.customerTargetUrlService.withUnderPerformingKeywords(this.options.customer.CustomerId, this.options.taskId);
        } else {
            targetUrlsObservable = this.customerTargetUrlService.withUnderPerformingMoneyBackKeywords(this.options.customer.CustomerId, this.options.taskId);
        }

        forkJoin({
            customer: this.customerService.get(this.options.customer.CustomerId),
            targetUrls: targetUrlsObservable
        }).subscribe(({customer, targetUrls}) => {
            this.customer = customer;
            this.urls = targetUrls as Boo.Objects.CustomerTargetUrl[];
            this.isLoaded = true;

            if (this.validationState) {
                const currentState = this.validationState();
              if (this.urls.length > 0) {
                    currentState.hasUnderPerformingKeywords = true;
                }
                currentState.hasGottenKeywordsResponse = true;
                this.validationState(currentState);
            }
        });
    }

    viewReportingSite() {
        this.userService.csrReportingAccess(this.customer.CustomerId).subscribe((token: string) => {
            window.open(`${this.customer.Partner.ReportingUrl}/?t=${token}`, '_blank');
        }, (error: string) => {
            toastr.error(error, 'Impersonated User');
        });
    }
}

export interface CustomerTargetUrlsViewModelOptions {
    customer: Boo.Objects.Customer;
    validationState: KnockoutObservable<ProactiveUnderPerformingKeywordValidationState>;
    taskId: number;
    isMoneyBack: boolean;
}

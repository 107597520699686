import { Component } from '@angular/core';
import System from 'framework/System';

@Component({
	selector: 'app-components-managecustomer-connecteduserssidepanel',
	templateUrl: './ConnectedUsersSidePanel.component.html'
})
export class ConnectedUsersSidePanelComponent {
    public numberOfConnectedUsers: KnockoutObservable<number> = ko.observable(0);

    protected floatPanel: IJQueryFloatPanel;
    public customerId: number;

    public activate(params: any): JQueryPromise<void> {
        this.customerId = params.customerId();

        // init jquery.floatpanel element, which is our container
        this.floatPanel = ($('#connected-users-float-panel-mc') as any).floatPanel({ collapsed: true });

        return System.emptyPromise();
    }

    public onChanged(users: string[]): void {
        this.numberOfConnectedUsers(users.length);
    }
}

import { Component, ViewChild } from '@angular/core';
import System from 'framework/System';
import Utils from '../../../../shared/utils';
import { RegistrationPhoneNumberService } from '../../../../services/registration-phone-number.service';
import { LocalProfilesComponent } from '../../../shared/local-profiles/local-profiles/local-profiles.component';

@Component({
  selector: 'app-components-specialist-actioncomponents-localbusinesscitations',
  templateUrl: './LocalBusinessCitations.component.html'
})
export class LocalBusinessCitationsComponent implements IActionWorkspace, IActivatable {
    @ViewChild(LocalProfilesComponent) LocalProfilesComponent: LocalProfilesComponent;
    actionViewModel: KnockoutObservable<IActionWorkspace>;
    citationUrl: KnockoutObservable<string>;
    contactInfo: KnockoutObservable<any>;
    currentAction: KnockoutObservable<Boo.Objects.Action>;
    customer: KnockoutObservable<Boo.Objects.Customer>;
    externalEditCount: KnockoutObservable<number>;
    files: Boo.Objects.File[] = [];
    isGoogleLinkLocation: boolean;
    isLoading: KnockoutObservable<boolean>;
    localProfile: Boo.Objects.LocalProfiles.LocalProfile;
    previousAccountsUsed: KnockoutObservable<string>;
    previousAccountsUsedList: KnockoutObservableArray<any>;
    registrationPhoneNumber: KnockoutObservable<Boo.Objects.AutoSelectedRegistrationPhoneNumber>;
    showAccountsUsed: KnockoutObservable<boolean>;
    showCustomLocationSection: KnockoutObservable<boolean>;
    targetKeywords: KnockoutObservableArray<string>;
    taskActionUrl: KnockoutObservable<string>;
    title: string;
    urlSubmittedTo: KnockoutObservable<string>;
    validation: KnockoutObservable<any>;
    showGoogleBusinessProfile: boolean;
    googleBusinessProfileCustomerAccountType: number = Boo.Objects.Enums.CustomerAccountTypes.GoogleBusinessProfile;
    googleBusinessProfileIsEnabled: KnockoutObservable<boolean> = ko.observable(false);

    constructor(private registrationPhoneNumberService: RegistrationPhoneNumberService) { }

    activate(params: IActionWorkspaceActivationParams): JQueryPromise<any> {
        params.controlViewModel(this);
        this.actionViewModel = ko.observable(null);
        this.currentAction = params.currentAction;
        this.title = 'Local Business Citations';
        this.isGoogleLinkLocation = false;
        this.isLoading = ko.observable(false);
        this.targetKeywords = ko.observableArray([]);
        this.previousAccountsUsedList = ko.observableArray([]);
        this.previousAccountsUsed = ko.observable();
        this.contactInfo = ko.observable();
        this.showAccountsUsed = ko.observable(false);
        this.showCustomLocationSection = ko.observable(false);
        this.urlSubmittedTo = ko.observable();
        this.registrationPhoneNumber = ko.observable();
        this.taskActionUrl = ko.observable();
        this.citationUrl = ko.observable('');


        this.externalEditCount = ko.observable(this.currentAction().Task.ExternalEditCount);
        this.localProfile = this.currentAction().LocalProfile;
        this.customer = ko.observable(this.currentAction().Customer);

        this.targetKeywords(this.currentAction().TaskDetails.WebsiteUrl.Keywords.map(x => { return x.Keyword.Name; }));
        this.files = this.currentAction().TaskDetails.Files;

        const googleLinkLocations = [
            launchpad.config.linkLocations.googleSpanish,
            launchpad.config.linkLocations.googleCanada,
            launchpad.config.linkLocations.googleFirstAustralia,
            launchpad.config.linkLocations.googleSecondAustralia,
            launchpad.config.linkLocations.googleFrance,
            launchpad.config.linkLocations.googleUs
        ];

        this.isGoogleLinkLocation = _.any(googleLinkLocations, (linkLocationId: number) => {
            return linkLocationId === this.currentAction().Task.LinkLocationId;
        });

        // Set previous used accounts if no link location specified
        if (this.currentAction().Task.LinkLocationId === 0) {
            this.showCustomLocationSection(true);
            if (this.currentAction().TaskDetails.PreviousAccountsUsed && this.currentAction().TaskDetails.PreviousAccountsUsed.length > 0) {
                this.showAccountsUsed(true);
                const usedAccounts = _.sortBy(this.currentAction().TaskDetails.PreviousAccountsUsed, (item: string) => { return item.toLowerCase(); });
                this.previousAccountsUsedList(usedAccounts);

                if (this.currentAction().Task.TaskActionUrl) {
                    this.urlSubmittedTo(this.currentAction().Task.TaskActionUrl);
                }
            }
        } else if (this.currentAction().TaskDetails.LinkLocation) {
            this.citationUrl(this.currentAction().TaskDetails.LinkLocation.Url);
        }

        if (this.showAccountsUsed && this.currentAction().Task.TaskActionUrl.trim() !== '') {
            this.taskActionUrl(this.currentAction().Task.TaskActionUrl);
        } else if (this.currentAction().TaskDetails.LinkLocation) {
            this.taskActionUrl(this.currentAction().TaskDetails.LinkLocation.Url);
        }

        if (this.currentAction().TaskDetails.RegistrationPhoneNumber) {
            this.registrationPhoneNumber(this.currentAction().TaskDetails.RegistrationPhoneNumber);
        }

        if (this.previousAccountsUsedList()) {
            this.previousAccountsUsed(this.previousAccountsUsedList().join('\n'));
        }

        const cu = _.find(this.customer().Users, (user: Boo.Objects.CustomerUser) => {
            return user.UserLevelId === Boo.Objects.Enums.UserLevelEnum.Analytics;
        });

        // Set Contact Info
        if (cu) {
            this.contactInfo(`Contact Information: ${cu.FullName} @ ${this.customer().Phone}`);
        } else {
            this.contactInfo(`Contact Email and/or Phone: ${this.customer().Email} or ${this.customer().Phone}`);
        }

        this.showGoogleBusinessProfile = this.currentAction().Task.TaskActionUrl.indexOf('google.com') > -1;

        this.taskActionUrl.extend({
            validation: { validator: this.taskActionUrlIsValid.bind(this), message: 'In order to complete this Google action, you must update the Task Action URL' }
        });

        this.urlSubmittedTo.extend({
            maxLength: 512,
            pattern: launchpad.utils.validation.validUrlValidationPattern(),
            validation: { validator: this.urlSubmittedToIsValid.bind(this), message: 'The Url has already been submitted to.' }
        });

        this.validation = ko.validatedObservable([this.taskActionUrl, this.urlSubmittedTo]);

        return System.emptyPromise();
    }

    isStepReadyToReject(): boolean {
        return false;
    }

    isStepReadyToSave(): boolean {
        return false;
    }

    isStepReadyToComplete(): boolean {
        if (!this.validation.isValid()) {
            this.validation.errors.showAllMessages();
            return false;
        }

        if (this.showCustomLocationSection()) {
            const accountNameHost = launchpad.utils.formatUrl(this.urlSubmittedTo(), true, true, true);
            this.currentAction().Task.TaskActionUrl = accountNameHost;
        } else { // if user entered a verified profile link then save it but only if they are not showing a custom location section
            this.currentAction().Task.TaskActionUrl = this.isGoogleLinkLocation ? this.getGoogleTaskActionUrl() : this.taskActionUrl();
        }

        // We need to store the registration phone number on the local profile (if it exists).
        const registrationPhoneNumber = this.LocalProfilesComponent.getRegistrationNumber();
        if (registrationPhoneNumber?.PhoneNumber ?? false) {
          this.localProfile.GoogleRegistrationPhoneNumber = registrationPhoneNumber.PhoneNumber;
        }
        
        // save local profile to action
        this.currentAction().LocalProfile = this.localProfile;

        return true;
    }

    isStepReadyToDelete(): boolean {
        return true;
    }

    onSkip(): void {
        if (this.registrationPhoneNumber()) {
            // make sure the phone number data is reverted on a skip
            Utils.wrapDfd(this.registrationPhoneNumberService.revert(this.registrationPhoneNumber()));
        }
    }

    actionCanBeDoneLater(): boolean {
        return true;
    }

    private urlSubmittedToIsValid(url: string): boolean {
        let result = true;
        if (this.showCustomLocationSection() && this.showAccountsUsed()) {

            let wasPreviouslyUsed = false;
            const valHostname = launchpad.utils.formatUrl(url, false, false, false);

            // loops through urls already submitted to and checks if any url contains the new submitted url
            _.each(this.previousAccountsUsedList(), (previousAccountUsed: string) => {
                if (!wasPreviouslyUsed && launchpad.utils.formatUrl(previousAccountUsed, false, false, false).indexOf(valHostname) >= 0) {
                    wasPreviouslyUsed = true;
                }
            });

            result = !wasPreviouslyUsed && url.trim() !== '';
        }

        return result;
    }

    private taskActionUrlIsValid(url: string): boolean {
        if (!url || !this.isGoogleLinkLocation) {
            return true;
        }

        return url.toLowerCase().indexOf('google.com/local/add') < 0 && url.toLowerCase().indexOf('google') >= 0;
    }

    // For google linklocations users copy in completed url similar to https://business.google.com/b/101316174892870898393/add/verify/l/05767655044161439032?hl
    // We want to construct the taskActionUrl from the page id in the URL
    private getGoogleTaskActionUrl(): string {

        if (this.taskActionUrl().trim().toLowerCase().indexOf('https://business.google.com/b') !== 0) {
            return this.taskActionUrl();
        }

        let result = this.taskActionUrl();
        const splitUrl = this.taskActionUrl().split('/');
        if (splitUrl.length >= 5 && _.isNumber(parseInt(splitUrl[4], 10))) {
            result = 'https://plus.google.com/' + splitUrl[4];
        }

        return result;
    }
}

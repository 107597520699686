import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import WorkRequestHelper from 'app/managecustomer/WorkRequestHelper';
import { CustomerCampaignService } from '../../../services/customer-campaign.service';

@Component({
  selector: 'app-components-managecustomer-keyword-research-strategy-update-custom-action',
  templateUrl: './keyword-research-strategy-update-custom-action.component.html'
})
export class KeywordResearchStrategyUpdateCustomActionComponent implements OnInit, IActivatable {

  websiteUrlStatuses_Archived = Boo.Objects.Enums.WebsiteUrlStatuses.Archived;
  isNew = true;
  isLoading = false;
  customerId: number;
  websiteUrls: UrlController[];
  user: Boo.Objects.User;
  previouslySelectedWebsiteUrlIds: number[] = [];

  constructor(private customerCampaignService: CustomerCampaignService) { }

  ngOnInit(): void {
    this.isLoading = true;

    this.customerCampaignService.getWebsiteUrls(this.customerId, true) // excludeInactiveKeywords: true
      .pipe(finalize(() => { this.isLoading = false }))
      .subscribe(
        x => 
          { 
            this.websiteUrls = x.map((y) => { 
              return { websiteUrl: y, isOrdered: !this.isNew && this.previouslySelectedWebsiteUrlIds.includes(y.WebsiteUrlId) }; } )
                .filter(y => (y.websiteUrl.StatusId === Boo.Objects.Enums.WebsiteUrlStatuses.Active || y.websiteUrl.StatusId === Boo.Objects.Enums.WebsiteUrlStatuses.Limited || y.websiteUrl.StatusId === Boo.Objects.Enums.WebsiteUrlStatuses.New) || y.isOrdered); 
          },
        err => { toastr.error(err) }
      )
  }

  canActivate(user: Boo.Objects.User, _partner: Boo.Objects.Partner, params: any): boolean {
    if (params.controlViewModel) {
      params.controlViewModel(this);
    }

    this.isNew = params.isNew;
    this.customerId = params.workRequestDetail.WorkRequest.CustomerId;
    this.user = user;

    if (!this.isNew) {
      this.previouslySelectedWebsiteUrlIds = WorkRequestHelper.getKeywordResearchWebsiteUrlIds(params.workRequestDetail.WorkRequest);
    }

    return true;
  }

  canSave(): boolean {
    const someUrlOrdered = this.websiteUrls.reduce((acc, curr) => acc || curr.isOrdered, false);

    if (!someUrlOrdered) {
      toastr.error('Please select at least one URL.');
    }

    return someUrlOrdered;
  }

  save(workRequest: Boo.Objects.Work.WorkRequest): void {
    WorkRequestHelper.setKeywordResearchWebsiteUrlIds(workRequest, this.websiteUrls.filter(x => x.isOrdered).map(x => x.websiteUrl.WebsiteUrlId), this.user.UserId);
  }
}

class UrlController {
  websiteUrl: Boo.Objects.WebsiteUrl;
  isOrdered: boolean;
}
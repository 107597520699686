import { Component } from '@angular/core';
import app from 'App';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { ViewLocatorService } from '../../../services/view-locator.service';
import { ActionService } from '../../../services/action.service';
import { UserSkipService } from '../../../services/user-skip.service';
import { LaunchpadThemeService, themes } from '../../../services/launchpad-theme.service';
import { UserService } from '../../../services/user.service';
import permissions from 'app/models/Permissions';
import { SessionStorageService } from '../../../services/session-storage.service';
import { ReloadService } from '../../../services/reload.service';
import Utils from '../../../shared/utils';


@Component({
  selector: 'app-components-specialist-specialistworkspace',
  templateUrl: './SpecialistWorkspace.component.html',
  styleUrls: ['./SpecialistWorkspace.component.scss', '../../../shared/action-button.scss']
})
export class SpecialistWorkspaceComponent implements IActivatable {
  public theme: themes;
  public themes = themes;
  public customerButtonCss: string;
  public icon: string;
  public hasWebsiteBookmarkingPermissions: boolean;
  public taskTypes: Boo.Objects.TaskType[];
  public priorityTaskTypeId: number;
  public selectedLanguages: number[] = [];
  public canSetOwnPriority: boolean;
  public canFilterLanguages: boolean;
  public languageOptions: LanguageSelection[];
  public userLanguages: Boo.Objects.UserLanguage[];

  constructor(
    private viewLocatorService: ViewLocatorService,
    private actionService: ActionService,
    private userSkipService: UserSkipService,
    private themeService: LaunchpadThemeService,
    private userService: UserService,
    private sessionStorageService: SessionStorageService,
    private reloadService: ReloadService) { }

  private static autoGetNextRouteParam = 'next';

  public isLoading: KnockoutObservable<boolean>;

  public canActivate(user: Boo.Objects.User, partner: Boo.Objects.Partner) {
    this.taskTypes = user.PartnerUsers.find((pu) => pu.PartnerId == partner.PartnerId && pu.UserLevelId == Boo.Objects.Enums.UserLevelEnum.Specialist).TaskTypes;
    this.canSetOwnPriority = window.launchpad.hasPermission(partner, user.PartnerUsers, permissions.CanPrioritizeTaskTypeFromQueue, user);
    this.canFilterLanguages = user.Languages.length > 1;
    this.userLanguages = user.Languages;
    return true;
  }

  public activate(params: any): JQueryPromise<void> {
    this.isLoading = ko.observable(false);
    this.theme = this.themeService.getTheme();
    this.customerButtonCss = this.themeService.getNextButtonStyles[this.theme];
    this.icon = this.themeService.getSpecialistIcon();
    this.priorityTaskTypeId = this.actionService.defaultPriority;
    Utils.wrapDfd(this.sessionStorageService.getStaticData())
      .then((staticData: Boo.Objects.LaunchPadStaticData) => {
        this.languageOptions = staticData.Languages.filter(x => this.userLanguages.map(y => y.LanguageId).includes(x.LanguageId)).map((x) => {
          return {
            label: x.Abbreviation.toUpperCase(),
            fullName: x.Name,
            languageId: x.LanguageId
          } as LanguageSelection
        }).sort((a, b) => a.languageId - b.languageId);
          this.selectedLanguages = this.languageOptions.map(x => x.languageId);
      })
      .fail((err: any) => toastr.error(err));

    if (_.first(params) === SpecialistWorkspaceComponent.autoGetNextRouteParam) {
      this.viewLocatorService.removeFromDisplayedRoute(SpecialistWorkspaceComponent.autoGetNextRouteParam);
      return this.getNextTaskInQueue();
    }

    return Utils.wrapDfd(this.userService.canWorkTaskType(Boo.Objects.Enums.TaskTypeEnum.WebsiteBookmarking))
      .then(hasWebsiteBookmarkingPermissions => {
        this.hasWebsiteBookmarkingPermissions = hasWebsiteBookmarkingPermissions;
      });
  }

  public getNextTaskInQueue(): JQueryPromise<any> {
    this.isLoading(true);

    if (app.hasUserBeenNotifiedSessionExpired()) {
      app.notifySessionExpired();
      return;
    }

    // if reload is required, add /next to route and reload so getNextTaskInQueue gets called after reload
    if (this.reloadService.isReloadRequired()) {
      this.viewLocatorService.addToDisplayedRoute(SpecialistWorkspaceComponent.autoGetNextRouteParam, true);
      this.reloadService.reload('Auto reload from specialistworkspace getNextTaskInQueue');
      return;
    }

    return Utils.wrapDfd(this.actionService.getNextActionInQueue(this.priorityTaskTypeId, this.selectedLanguages))
      .then((data) => {
        if (!data || !data.Task) {
          toastr.warning('No more actions are available.');
        } else {
          PriorityViewModel.show('app-components-specialist-actionworkspace', { currentAction: data })
            .done((result: any) => {
              if (result) {
                // HACK: This is super hacky but we need to fix priority view to wait until the chain is done.
                setTimeout(this.getNextTaskInQueue.bind(this), 1000);
              }
            });
        }
      })
      .fail((err: any) => toastr.error(err))
      .always(() => {
        this.isLoading(false);
      });
  }

  public deleteWebsiteBookmarkingSkips(): void {
    Utils.wrapDfd(this.userSkipService.deleteCustomerSkips(Boo.Objects.Enums.TaskTypeEnum.WebsiteBookmarking))
      .then(() => {
          bootbox.alert('Your Website Bookmarking user skips have been deleted');
      })
      .fail((msg: string) => {
          toastr.error(msg);
      });
  }

  public toggleEasterEgg(): void {
    this.themeService.toggleEasterEgg();
    this.icon = this.themeService.getSpecialistIcon();
  }

  getIconClass(languageId: number): string {
    return this.selectedLanguages.includes(languageId) ? 'fas fa-check-square text-success' : 'fas fa-stop-circle text-danger';
  }
}

interface LanguageSelection {
  label: string;
  fullName: string
  languageId: number;
}

import { AfterViewInit, Component, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { TaskService } from '../../../../services/task.service';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import TaskTypeEnum = Boo.Objects.Enums.TaskTypeEnum;
import TaskHelper from 'app/specialist/TaskHelper';
import { CustomerFileService } from '../../../../services/customer-file.service';
import Utils from '../../../../shared/utils';
import { UntypedFormControl, Validators } from '@angular/forms';
import { QuillConfig } from '../../../../shared/quill/quill-config';
import CustomValidators from '../../../../shared/custom-validators';
import Quill from 'quill';

type OnsiteBloggingReviewOutput = Boo.Tasks.Outputs.Models.OnsiteBloggingReviewOutput;

@Component({
  selector: 'app-components-specialist-actioncomponents-onsitebloggingreview',
  templateUrl: './OnsiteBloggingReview.component.html'
})
export class OnsiteBloggingReviewComponent implements IActionWorkspace, IActivatable, AfterViewInit {
    @ViewChildren('editorRichText') public editorRichTexts: QueryList<ElementRef>;
    public window = window;
    public launchpad = launchpad;
    public actionViewModel: KnockoutObservable<IActionWorkspace>;
    public currentAction: KnockoutObservable<Boo.Objects.Action>;
    public title: string;
    public content: UntypedFormControl;
    public contentAudit: Boo.Objects.ContentAudit;
    public onsiteRecommendationsPageTitle: string;
    public onsiteRecommendationsMetaDescription: string;
    public contentTitle: string;
    public contentTitle2: string;
    public imageUrl: string;
    public customer: Boo.Objects.Customer;
    public editorFeedback: UntypedFormControl;
    public fileCount: number;
    public files: Boo.Objects.CustomerFile[] = [];
    public hasComments: boolean;
    public isLoading: boolean;
    public isTryingToComplete: boolean;
    public noteCount: number;
    public notes: Boo.Objects.TaskNote[];
    public otherContentPosts: Boo.Objects.OnsiteBloggingTaskHistory[] = [];
    public pastImageUrls: string[];
    public showPlagiarismTable: boolean;
    public noteToQa: string;
    public editorToolbar: any = {
      toolbar: [
          {
              name: 'document',
              items: ['Source', 'Maximize']
          },
          // Defines toolbar group with name (used to create voice label)
          // and items in 3 subgroups.
          {
              name: 'clipboard',
              groups: ['clipboard', 'undo'],
              items: ['Cut', 'Copy', 'Paste', 'PasteText',
                  'PasteFromWord', '-', 'Undo', 'Redo']
          },
          {
              name: 'editing',
              groups: ['find', 'selection', 'spellchecker'],
              items: ['Find', 'Replace', '-', 'SelectAll']
          },
          {
              name: 'links',
              items: ['Link', 'Unlink', 'LinkCustomer']
          },
          '/', // new row
          {
              name: 'basicstyles',
              groups: ['basicstyles', 'cleanup'],
              items: ['Bold', 'Italic', 'Underline', 'Strike',
                  'Subscript', 'Superscript', '-',
                  'RemoveFormat']
          },
          {
              name: 'paragraph',
              groups: ['list', 'indent', 'blocks', 'align', 'bidi'],
              items: ['NumberedList', 'BulletedList', '-', 'Outdent',
                  'Indent', '-', 'Blockquote', '-',
                  'JustifyLeft', 'JustifyCenter', 'JustifyRight',
                  'JustifyBlock']
          }
      ]
    };

    public quillModules: any = QuillConfig.GetDefaults().modules;

    private output: OnsiteBloggingReviewOutput;

    constructor(
      private taskService: TaskService,
      private customerFileService: CustomerFileService) { }

    public activate(params: IActionWorkspaceActivationParams): JQueryPromise<void> {
        params.controlViewModel(this);
        this.actionViewModel = ko.observable(null);
        this.currentAction = params.currentAction;
        this.output = params.currentAction().Task.Output as OnsiteBloggingReviewOutput;

        if (!this.currentAction().TaskDetails.TaskPost) {
            toastr.error('No content exists for this review.');
            return;
        }

        this.customer = this.currentAction().Customer;
        this.editorFeedback = new UntypedFormControl(this.output.EditorFeedback ?? '', [Validators.required]);
        this.noteToQa = this.output.NoteToQa ?? '';
        this.isLoading = true;
        this.isTryingToComplete = false;

        if (this.currentAction().Task.TaskStatusEnum === Boo.Objects.Enums.TaskStatusEnum.Complete) {
          this.editorFeedback.setValue(TaskHelper.getComment(this.currentAction().Task));
        }

        this.contentAudit = this.currentAction().TaskDetails.TaskPost.ContentAudit;

        this.showPlagiarismTable = this.contentAudit && (this.contentAudit.Url1 !== null
            || this.contentAudit.Url2 !== null
            || this.contentAudit.Url3 !== null);

        this.title = 'Onsite Blogging Article Review ' + (this.currentAction().Task.TaskTypeEnum !== TaskTypeEnum.OnsiteBloggingReviewPremium ? ' Standard' : ' Premium');

        this.content = new UntypedFormControl(this.currentAction().TaskDetails.TaskPost.Content, [Validators.required]);
        this.contentTitle = this.currentAction().TaskDetails.TaskPost.ContentTitle;
        this.contentTitle2 = this.currentAction().TaskDetails.OnsiteRecommendation.H2New;

        this.imageUrl = this.currentAction().TaskDetails.TaskPost.ImageUrl;
        this.quillModules.linkCustomerUrl = {
            customerUrl: this.currentAction().TaskDetails.WebsiteUrl.Url
        }
        this.onsiteRecommendationsPageTitle = this.currentAction().TaskDetails.OnsiteRecommendation.TitleNew;

        this.onsiteRecommendationsMetaDescription = this.currentAction().TaskDetails.OnsiteRecommendation.DescriptionNew;

        this.notes = this.currentAction().TaskDetails.TaskNotes.filter(x => x.TaskTypeEnum === this.currentAction().Task.TaskTypeEnum) || [];
        this.hasComments = this.notes.length > 0;
        this.noteCount = this.notes.length;

        return $.when<any>(
            Utils.wrapDfd(this.customerFileService.getMany(this.customer.CustomerId, launchpad.config.FileSectionTypeEnum.Marketing)),
            Utils.wrapDfd(this.taskService.getOnsiteTaskDefinitionHistory(this.customer.CustomerId)))
            .then((files: Boo.Objects.CustomerFile[], history: Boo.Objects.OnsiteBloggingTaskHistory[]) => {
                this.files = files ? files : [];
                this.fileCount = this.files.length;
                this.otherContentPosts = history;
                const eightMonthsAgo = new Date();
                eightMonthsAgo.setMonth(eightMonthsAgo.getMonth() - 8);
                this.pastImageUrls = history.filter((item) => {
                    return eightMonthsAgo < item.TaskDate;
                   })
                   .map(item => item.ImageUrl).filter(item => item);
            }).always(() => {
                this.isLoading = false;
            });
    }

    ngAfterViewInit(): void {
      $(this.editorRichTexts.first.nativeElement).data('linkCustomer_taskAction', this.currentAction);
    }

    public isStepReadyToReject(): boolean {

        if (!this.editorFeedback.valid) {
            Utils.showAllMessages(this.editorFeedback);
            return false;
        }

        TaskHelper.setComment(this.currentAction().Task, this.editorFeedback.value, this.currentAction().Task.AssignedUserId);

        this.output.NoteToQa = this.noteToQa;
        this.output.EditorFeedback = this.editorFeedback.value;

        return true;
    }

    public isStepReadyToSave(): boolean {
        this.isTryingToComplete = false;
        this.currentAction().TaskDetails.TaskPost.Content = this.content.value;
        this.output.NoteToQa = this.noteToQa;
        this.output.EditorFeedback = this.editorFeedback.value;
        return true;
    }

    public isStepReadyToComplete(): boolean {
        this.isTryingToComplete = true;

        if (!this.content.valid) {
            Utils.showAllMessages(this.content);
            return false;
        }

        this.currentAction().TaskDetails.TaskPost.Content = this.content.value;
        this.output.NoteToQa = this.noteToQa;
        this.output.EditorFeedback = this.editorFeedback.value;
        TaskHelper.setComment(this.currentAction().Task, this.editorFeedback.value, this.currentAction().Task.AssignedUserId);

        return true;
    }

    public isStepReadyToDelete(): boolean {
        return false;
    }

    public contentEditorCreated(editor: Quill) {
        if (this.content) {
            this.content.addValidators(CustomValidators.quillWordCountValidator(editor, this.requiredWordCount()));
        }
    }

    private requiredWordCount(): number {
        if (this.currentAction().Task.LanguageId === Boo.Objects.Enums.LanguageEnum.Finnish
            && this.currentAction().Task.TaskTypeEnum === TaskTypeEnum.OnsiteBloggingReviewStandard) {
            return 400;
        }

        if (this.currentAction().Task.LanguageId === Boo.Objects.Enums.LanguageEnum.Finnish
            && this.currentAction().Task.TaskTypeEnum === TaskTypeEnum.OnsiteBloggingReviewPremium) {
            return 550;
        }

        if (this.currentAction().Task.TaskTypeEnum === TaskTypeEnum.OnsiteBloggingReviewPremium) {
            return 800;
        }

        return 600;
    }
}

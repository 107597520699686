import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VerticalService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/verticals`;

  constructor(private http: HttpClient) { }

  getManagementSummary(): Observable<Boo.Objects.VerticalSummary[]> {
    return this.http.get<Boo.Objects.VerticalSummary[]>(`${this.baseUrl}/getManagementSummary`);
  }

  getTopLevelOnly(): Observable<Boo.Objects.Vertical[]> {
    return this.http.get<Boo.Objects.Vertical[]>(`${this.baseUrl}/getTopLevelOnly`);
  }

  get(verticalId: number): Observable<Boo.Objects.Vertical> {
    const params = new HttpParams().set('verticalId', verticalId);

    return this.http.get<Boo.Objects.Vertical>(`${this.baseUrl}/get`, { params: params });
  }

  save(vertical: Boo.Objects.Vertical): Observable<number> {
    return this.http.post<number>(`${this.baseUrl}/save`, vertical);
  }
}

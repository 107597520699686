import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PartnerPricingToolService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/partnerpricingtool`;

  constructor(private http: HttpClient) { }

  getPricingToolPartners(): Observable<Boo.Objects.PartnerMinimal[]> {
    return this.http.get<Boo.Objects.PartnerMinimal[]>(`${this.baseUrl}/GetPricingToolPartners`);
  }

  getPricingToolInformation(partnerId: number): Observable<Boo.Objects.SEOPresence.PartnerPricingToolInformation> {
    const params = new HttpParams().set('partnerId', partnerId);
    return this.http.get<Boo.Objects.SEOPresence.PartnerPricingToolInformation>(`${this.baseUrl}/GetPricingToolInformation`, { params: params });
  }
}

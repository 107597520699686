import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import PartnerEnum = Boo.Objects.Enums.PartnerEnum;
import SsoTypes = Boo.Partners.Sso.SsoTypes;

@Injectable({
  providedIn: 'root'
})
export class PartnerSsoService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/partner/sso`;

  constructor(private http: HttpClient) { }

  localProfilesRequireSso(partnerId: number): boolean {
    return [PartnerEnum.Manta, PartnerEnum.MantaDev].includes(partnerId);
  }

  getLocalProfileSsoLabel(partnerId: number): string {
    const labels = {
      [PartnerEnum.Manta]: 'Edit Local Profile on Manta.com',
      [PartnerEnum.MantaDev]: 'Edit Local Profile on Manta.com'
    };
    return labels[partnerId] ?? '';
  }

  getLocalProfileSso(partnerId: number, identifier: string): Observable<string> {
    return this.getUrl(partnerId, SsoTypes.LocalProfile, identifier);
  }

  getCustomerSso(partnerId: number, customerId: number): Observable<string> {
    return this.getUrl(partnerId, SsoTypes.Customer, customerId.toString());
  }

  private getUrl(partnerId: number, ssoType: SsoTypes, id: string): Observable<string> {
    const params = new HttpParams()
      .set('partnerId', partnerId)
      .set('ssoType', ssoType)
      .set('id', id);

    return this.http.get<string>(`${this.baseUrl}`, { params: params });
  }
}
export default class Routes {
  public routes: any = [];
  // / <summary>Adds a new route based on the view resolution naming convention.</summary>
  // / <param name="pageName">The name of the page.</param>
  // / <param name="baseName">The name of the section.</param>
  // / <param name="showStats">(bool) Should the app try to load the stats page based on the convention.</param>
  // / <param name="showNavigation">(bool)Should the app try to load the navigation paged based on the convention.</param>
  // / <param name="title">The title of the page</param>

  private addRouteOnConvention: any = (pageName: any, baseName: any, showStats: any, showNavigation: any, title: any) => {
    const prefix = 'app-components';

    baseName = (baseName || '').toLowerCase();
    pageName = pageName.toLowerCase();

    this.routes.push({
      hash: baseName ? `${baseName}/${pageName}` : pageName,
      contentAngularSelector: baseName ? `${prefix}-${baseName}-${pageName}` : `${prefix}-${pageName}`,
      statsAngularSelector: showStats ? `${prefix}-stats-${baseName}` : undefined,
      navigationAngularSelector: showNavigation ? `${prefix}-navigation-${baseName}` : undefined,
      title: title
    });
  };

  private addRouteRedirect: any = (hash: any, redirectHash: any) => {
    this.routes.push({
      hash: hash,
      redirectHash: redirectHash
    });
  };

  constructor() {
    // Generic
    this.addRouteOnConvention('Login', '', false, false, 'Login');
    this.addRouteOnConvention('Logout', '', false, false, 'Logging out...');
    this.addRouteOnConvention('SwitchPartner', '', false, false, 'Switch Partner');
    this.addRouteOnConvention('ChangePassword', '', false, false, 'Change Password');
    this.addRouteOnConvention('ResetPassword', '', false, false, 'Reset Password');
    // Customer Service
    this.addRouteOnConvention('ManagerTools', 'CustomerService', false, true, 'Manager Tools');
    this.addRouteOnConvention('HelpNextCustomer', 'CustomerService', false, true, 'Help Next Customer');
    this.addRouteOnConvention('CustomerSearch', 'CustomerService', false, true, 'Customer Search');
    this.addRouteOnConvention('ManageCustomer', 'CustomerService', false, false, 'Manage Customer');
    this.addRouteOnConvention('ManageTickets', 'CustomerService', false, true, 'Manage Tickets');
    this.addRouteOnConvention('EditTicket', 'CustomerService', false, true, 'Edit Ticket');
    this.addRouteOnConvention('AssignTicketTypes', 'CustomerService', false, true, 'Assign Ticket Types');
    this.addRouteOnConvention('Csrs', 'CustomerService', false, true, 'Assign CSRs');
    this.addRouteOnConvention('CreateTicketBatch', 'CustomerService', false, true, 'Create Ticket Batch');
    this.addRouteOnConvention('CustomerPriorityAssignments', 'CustomerService', false, true, 'Help Next Customer Assignments');
    // Manager
    this.addRouteOnConvention('LinkTypeActivation', 'Manager', false, true, 'Link Type Activation');
    this.addRouteOnConvention('ManageUsers', 'Manager', false, true, 'Manage Users');
    this.addRouteOnConvention('TaskStewardships', 'Manager', false, true, 'Task Stewardships');
    this.addRouteOnConvention('PartnerUser', 'Manager', false, false, 'Partner User');
    this.addRouteOnConvention('EditUser', 'Manager', false, false, 'Edit User');
    this.addRouteOnConvention('LinkLocation', 'Manager', false, false, 'Link Location');
    this.addRouteOnConvention('ManagePartners', 'Manager', false, true, 'Manage Partners');
    this.addRouteOnConvention('TransferAccounts', 'Manager', false, true, 'Transfer Accounts');
    this.addRouteOnConvention('LeadSources', 'Manager', false, true, 'Manage Lead Sources');
    this.addRouteOnConvention('RegistrationPhoneNumbers', 'Manager', false, true, 'Registration Phone Numbers');
    this.addRouteOnConvention('RepublishPost', 'Manager', false, true, 'Republish post');
    this.addRouteOnConvention('PremiumBacklink', 'Manager', false, true, 'Premium Backlink');
    this.addRouteOnConvention('MerchantFields', 'Manager', false, true, 'Merchant Fields');
    this.addRouteOnConvention('TaskImportExport', 'Manager', false, true, 'Task Import/Export');
    this.addRouteOnConvention('BlacklistDomains', 'Manager', false, true, 'Blacklist Domains');
    this.addRouteOnConvention('OrganizationalTeams', 'Manager', false, true, 'Manage Organizational Teams');
    this.addRouteOnConvention('ConfigurePartnerPricingTool', 'Manager', false, true, 'Configure Partner Pricing Tool');
    this.addRouteOnConvention('ConfigureQaTasks', 'Manager', false, true, 'Configure QA Tasks');
    this.addRouteOnConvention('Notifications', 'Manager', false, true, 'Notifications');
    // Sales
    this.addRouteOnConvention('AddCustomer', 'Sales', true, true, 'Add Customer');
    this.addRouteOnConvention('SearchCustomers', 'Sales', true, true, 'Search Customers');
    // Specialist
    this.addRouteOnConvention('SpecialistWorkspace', 'Specialist', true, true, 'Specialist Workspace');
    this.addRouteOnConvention('BlogSites', 'Specialist', true, true, 'Blogs');
    this.addRouteOnConvention('EditBlog', 'Specialist', false, false, 'Edit Blog');
    this.addRouteOnConvention('ContentFeedback', 'Specialist', false, true, 'Content Feedback');
    this.addRouteOnConvention('internal-tasks', 'Specialist', false, true, 'My Internal Tasks');
    this.addRouteOnConvention('LockedInternalTasks', 'Specialist', true, true, 'Locked Internal Tasks');
    this.addRouteOnConvention('AssignedTasks', 'Specialist', true, true, 'Assigned Tasks');
    this.addRouteOnConvention('ViewTaskWorkspace', 'Specialist', false, true, 'View Task Workspace');
    this.addRouteOnConvention('ViewTaskFeedback', 'Specialist', false, true, 'View Task Feedback');
    this.addRouteOnConvention('LockedTasks', 'Specialist', false, true, 'Locked Tasks');
    this.addRouteOnConvention('ChangeSpecialistScore', 'Specialist', false, true, 'Change Specialist Score');
    // Admin
    this.addRouteOnConvention('Workflows', 'Admin', false, true, 'Workflows');
    this.addRouteOnConvention('InternalTaskHistory', 'Admin', false, true, 'Workflow Internal Tasks');
    this.addRouteOnConvention('VerticalManagement', 'Admin', false, true, 'Vertical Management');
    this.addRouteOnConvention('ApplicationManagement', 'Admin', true, true, 'Application Management');
    this.addRouteOnConvention('Translations', 'Admin', false, true, 'Translations');
    this.addRouteOnConvention('PartnerUsers', 'Admin', false, true, 'Partner Users');
    this.addRouteOnConvention('HelpTextEditor', 'Admin', false, true, 'HelpText Editor');
    this.addRouteOnConvention('countryandlanguagemanagement', 'Admin', false, true, 'Country And Language');
    // Redirects
    this.addRouteRedirect('sales/myleads', 'sales/addcustomer');
    this.addRouteRedirect('sales/searchleads', 'sales/addcustomer');
  }
}

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import CustomValidators from '../../../../shared/custom-validators';
import { CustomerCampaignService } from '../../../../services/customer-campaign.service';
import { KeywordSuggestionFilter, KeywordSuggestion, KeywordSuggestionSources, PageSuggestion } from '../../../../shared/models/keywords/keywords';
import { KeywordResearchService } from '../../../../services/keyword-research.service';

@Component({
  selector: 'app-components-shared-keyword-research-add-keyword',
  templateUrl: './add-keyword.component.html',
  styleUrls: ['../keyword-research/keyword-research.component.scss']
})
export class AddKeywordComponent implements OnInit{
  @Input() customer: Boo.Objects.Customer;
  @Input() pageSuggestion: PageSuggestion;
  @Input() pageSuggestions: PageSuggestion[];
  @Input() kwrSearchTerm: string;

  @ViewChild('keywordInput') keywordInput: ElementRef;
  @ViewChild('areaInput') areaInput: ElementRef;

  keywordSuggestionFilter: KeywordSuggestionFilter;
  keywordControl: FormControl<string>;
  areaControl: FormControl<string>;
  formGroup: FormGroup;
  addingKeyword = false;
  isAreaFirst = false;
  canAddKeywords = false;
  rankThreshold: number;

  constructor(
    private customerCampaignService: CustomerCampaignService,
    private keywordResearchService: KeywordResearchService) { }

  ngOnInit(): void {
    this.rankThreshold = this.keywordResearchService.rankThreshold;
    this.setupForm();

    // Keywords can only be added once research has been performed for at least one page suggestion
    this.canAddKeywords = this.pageSuggestions.filter(x => x.IsCampaignNational === this.customer.IsCampaignNational)
      .flatMap(x => x.KeywordSuggestions)
      .some(x => x.SourceId === KeywordSuggestionSources.KeywordTool);
  }

  toggleAddingKeyword(): void {
    this.addingKeyword = !this.addingKeyword;

    if (this.addingKeyword) {
      this.isAreaFirst = false;
      this.keywordControl.reset('');
      this.areaControl.reset('');
      this.focus();
    }
  }

  setupForm(): void {
    this.keywordControl = new FormControl('', [
      Validators.required,
      Validators.maxLength(128),
      CustomValidators.mustNotBeWhitespace()
    ]);
    this.areaControl = new FormControl('', [
      CustomValidators.requiredIfValidator(() => !this.customer.IsCampaignNational),
      Validators.maxLength(256),
      CustomValidators.mustNotBeWhitespace()
    ]);
    this.formGroup = new FormGroup({ keyword: this.keywordControl, area: this.areaControl }, {
      validators: [CustomValidators.keywordsCannotBeDuplicated(this.customerCampaignService, () => this.isAreaFirst, this.getUnavailableKeywords.bind(this), 'This keyword already exists and cannot be duplicated.')]
    });
  }

  getUnavailableKeywords(): string[] {
    return this.pageSuggestion
      ? this.pageSuggestion.KeywordSuggestions
          .filter(x => x.IsSelected && x.IsCampaignNational === this.customer.IsCampaignNational)
          .map(x => x.KeywordPhrase.toLowerCase().trim())
      : [];
  }

  keywordSort = (a: KeywordSuggestion, b: KeywordSuggestion): number => this.keywordResearchService.keywordSort(a, b);

  get availableKeywordSuggestions(): KeywordSuggestion[] {
    return this.pageSuggestion.KeywordSuggestions.filter(x => !x.IsSelected && x.IsCampaignNational === this.customer.IsCampaignNational);
  }

  get filteredKeywordSuggestions(): KeywordSuggestion[] {
    return this.keywordResearchService.filterKeywordSuggestions(this.availableKeywordSuggestions, this.keywordSuggestionFilter);
  }

  filterChanged($event: KeywordSuggestionFilter): void {
    this.keywordSuggestionFilter = $event;
  }

  addKeyword(): void {
    this.keywordControl.markAsDirty();
    this.keywordControl.updateValueAndValidity();

    if (!this.customer.IsCampaignNational) {
      this.areaControl.markAsDirty();
      this.areaControl.updateValueAndValidity();
    } else {
      this.areaControl.reset('');
      this.isAreaFirst = false;
    }

    if (!this.formGroup.valid) {
      toastr.error("Please correct errors before adding a keyword.");
      return;
    }

    const phrase = this.customerCampaignService.getKeywordPhrase(this.keywordControl.value, this.areaControl.value, this.isAreaFirst);

    const existingKeywordSuggestion = this.pageSuggestion.KeywordSuggestions
      .find(x => x.KeywordPhrase.toLowerCase().trim() === phrase.toLowerCase() && x.IsCampaignNational === this.customer.IsCampaignNational);

    if (existingKeywordSuggestion) {
      existingKeywordSuggestion.IsSelected = true;
      this.pageSuggestion.IsModified = true;
    } else {
      const existingKeywordSuggestionOnAnotherPage = this.pageSuggestions.filter(x => x.IsCampaignNational === this.customer.IsCampaignNational)
        .flatMap(x => x.KeywordSuggestions)
        .find(x => x.KeywordPhrase.toLowerCase().trim() === phrase.toLowerCase().trim() && x.IsCampaignNational === this.customer.IsCampaignNational && x.SourceId === KeywordSuggestionSources.KeywordTool);

      const keywordSuggestion = {
        KeywordSuggestionId: 0,
        PageSuggestionId: this.pageSuggestion.PageSuggestionId,
        SourceId: existingKeywordSuggestionOnAnotherPage ? KeywordSuggestionSources.KeywordTool : KeywordSuggestionSources.User,
        IsCampaignNational: this.customer.IsCampaignNational,
        KeywordPhrase: phrase,
        Keyword: this.customerCampaignService.normalizeKeyword(this.keywordControl.value),
        Area: this.areaControl.value ? this.customerCampaignService.normalizeArea(this.areaControl.value) : null,
        IsAreaFirst: this.isAreaFirst,
        IsSelected: true,
        Volume: existingKeywordSuggestionOnAnotherPage ? existingKeywordSuggestionOnAnotherPage.Volume : null,
        Competition: existingKeywordSuggestionOnAnotherPage ? existingKeywordSuggestionOnAnotherPage.Competition : null,
        CompetitionDescription: existingKeywordSuggestionOnAnotherPage ? existingKeywordSuggestionOnAnotherPage.CompetitionDescription : null,
        Rank: existingKeywordSuggestionOnAnotherPage ? existingKeywordSuggestionOnAnotherPage.Rank : null,
        InsertedDate: null
      } as KeywordSuggestion;

      this.pageSuggestion.KeywordSuggestions.push(keywordSuggestion);
      this.pageSuggestion.IsModified = true;
    }

    this.toggleAddingKeyword();
  }

  addKeywordSuggestion(keywordSuggestion: KeywordSuggestion){
    keywordSuggestion.IsSelected = true;
    this.pageSuggestion.IsModified = true;
  }

  private focus() {
    setTimeout(() => {
      this.keywordInput.nativeElement.focus();
    }, 0);
  }
}
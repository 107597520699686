import { Directive, Input, ElementRef, OnChanges, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[externalLink]'
})
export class ExternalLinkDirective implements AfterViewInit, OnChanges {
  @Input('externalLink') public Settings: { url: string, text: string };
  
  private get Href(): string {
    const url = ko.utils.unwrapObservable(this.Settings.url) || '';
    if (url.toLowerCase().indexOf('http') !== 0) {
      return `http://${url}`;
    }

    return url;
  }

  private $element: JQuery;
  constructor(elementRef: ElementRef) {
    this.$element = $(elementRef.nativeElement);
    this.$element.append(`<i class='fas fa-external-link-square-alt'></i><span class='externalLink'></span>`);
    this.$element.css('cursor', 'pointer');
    this.$element.css('white-space', 'nowrap');
    this.$element.prop('rel', 'noreferrer');
    this.$element.prop('target', '_blank');
  }

  public ngAfterViewInit(): void {
    this.updateControl();
  }

  public ngOnChanges(): void {
    this.updateControl();
  }

  private updateControl(): void {
    this.$element.children('.externalLink').text(ko.utils.unwrapObservable(this.Settings.text) || '');
    this.$element.attr('href', this.Href);
  }
}
import { Component } from '@angular/core';
import permissions from 'app/models/Permissions';
import { TaskPostService } from '../../../services/taskpost.service';
import { SessionStorageService } from '../../../services/session-storage.service';
import { finalize } from 'rxjs/operators';

@Component({
	selector: 'app-components-manager-republishpost',
	templateUrl: './RepublishPost.component.html'
})
export class RepublishPostComponent {
  public constant: any = {
    boostabilityPartnerId: 1
  };
  // Observables
  public isLoading: any = ko.observable(false);
  public partnerId: any = ko.observable();

  public taskActionURL: any = ko.observable().extend({
    required: true
  });
  // Functions

  public canActivate(user: any, partner: any): any {
    return window.launchpad.hasPermission(partner, user.PartnerUsers, permissions.CanRepublishPost, user);
  }

  constructor(
    private taskPostService: TaskPostService,
    private sessionStorageService: SessionStorageService) { }

  public activate(): void {
    this.sessionStorageService.getPartner()
    .subscribe(partner => {
      this.partnerId(partner.PartnerId);
    });
  }

  public republish(): any {
    if (!this.taskActionURLValidation.isValid()) {
      this.taskActionURLValidation.errors.showAllMessages(true);
      return;
    }
    this.isLoading(true);
    this.taskPostService.deleteAndRepublishPost(this.taskActionURL())
      .pipe(finalize(() => this.isLoading(false)))
      .subscribe(_ => {
        this.taskActionURL('');
        this.taskActionURLValidation.errors.showAllMessages(false);
        toastr.success('Post successfully republished');
      }, err => toastr.error(err));
  }
  // Validation

  public taskActionURLValidation: any = ko.validatedObservable({
    taskActionURL: this.taskActionURL
  });
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ExampleNotificationRequest, NotificationDefinition, NotificationTemplate, NotificationTemplateHistory } from '../shared/models/notifications/notifications';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/notification`;

  constructor(private http: HttpClient) { }

  getDefinitions(): Observable<NotificationDefinition[]> {
    return this.http.get<NotificationDefinition[]>(`${this.baseUrl}/definitions`);
  }

  getPartnerDefinitions(partnerId: number): Observable<NotificationDefinition[]> {
    return this.http.get<NotificationDefinition[]>(`${this.baseUrl}/definitions/partner/${partnerId}`);
  }

  getTemplates(definitionId: string): Observable<NotificationTemplate[]> {
    return this.http.get<NotificationTemplate[]>(`${this.baseUrl}/templates/${definitionId}`);
  }

  getTemplatesForPartner(partnerId: number, definitionId: string): Observable<NotificationTemplate[]> {
    return this.http.get<NotificationTemplate[]>(`${this.baseUrl}/templates/partner/${partnerId}/${definitionId}`);
  }

  getTemplateHistory(templateId: string): Observable<NotificationTemplateHistory[]> {
    return this.http.get<NotificationTemplateHistory[]>(`${this.baseUrl}/templates/history/${templateId}`);
  }


  updateTemplate(template: NotificationTemplate): Observable<any> {
    return this.http.post(`${this.baseUrl}/templates/update`, template);
  }

  createTemplate(template: NotificationTemplate): Observable<any> {
    return this.http.post(`${this.baseUrl}/templates/create`, template);
  }

  updatePartnerExclusions(partner: number, notificationDefinitions: NotificationDefinition[]): Observable<any> {
    // Don't send down the templates to reduce payload size
    const body = notificationDefinitions.map(definition => { 
      return { 
        NotificationDefinitionId: definition.NotificationDefinitionId,
        Name: definition.Name,
        IsActive: definition.IsActive,
        EnabledForSubscriber: definition.EnabledForSubscriber,
        Disableable: definition.Disableable
      }; 
    });
    return this.http.post(`${this.baseUrl}/definitions/partner/${partner}/update-exclusions`, body);
  }

  revertToVersion(history: NotificationTemplateHistory): Observable<any> {
    return this.http.post(`${this.baseUrl}/templates/revert`, history);
  }

  sendExampleNotification(request: ExampleNotificationRequest): Observable<any> {
    return this.http.post(`${this.baseUrl}/send-example`, request);
  }

  getExampleRecipient(partnerId: number): Observable<string> {
    const params = new HttpParams().set('partnerId', partnerId);
    return this.http.get<string>(`${this.baseUrl}/get-example-recipient`, { params: params });
  }

  saveFrontExampleRecipient(recipient: string, partnerId: number): Observable<any> {
    return this.http.post(`${this.baseUrl}/save-example-recipient`,
      {
        Recipient: recipient,
        PartnerId: partnerId 
      });
  }
}

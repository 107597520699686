import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/partners`;

  constructor(private http: HttpClient) { }

  getAllCsrs(): Observable<Boo.Objects.PartnerUser[]> {
    return this.http.get<Boo.Objects.PartnerUser[]>(`${this.baseUrl}/GetAllCsrs`);
  }

  get(partnerId: number): Observable<Boo.Objects.Partner> {
    const params = new HttpParams().set('partnerId', partnerId);

    return this.http.get<Boo.Objects.Partner>(`${this.baseUrl}/Get`, { params: params });
  }

  getAll(): Observable<Boo.Objects.Partner[]> {
    return this.http.get<Boo.Objects.Partner[]>(`${this.baseUrl}/GetAll`);
  }

  getPartnerMinimals(): Observable<Boo.Objects.PartnerMinimal[]> {
    return this.http.get<Boo.Objects.PartnerMinimal[]>(`${this.baseUrl}/GetPartnerMinimals`);
  }

  update(partner: Boo.Objects.Partner, activatePartner: boolean, partnerTeam: Boo.Objects.PartnerTeam): Observable<any> {
    return this.http.post(`${this.baseUrl}/Update`, {
        partner: partner,
        activatePartner: activatePartner,
        partnerTeam: partnerTeam
    });
  }

  getTaskWorkerSummary(): Observable<Boo.Objects.PartnerTaskWorkerTotals> {
    return this.http.get<Boo.Objects.PartnerTaskWorkerTotals>(`${this.baseUrl}/GetTaskWorkerSummary`);
  }

  create(partner: Boo.Objects.Partner): Observable<Boo.Objects.Partner> {
    return this.http.post<Boo.Objects.Partner>(`${this.baseUrl}/Create`, partner);
  }

  sendTestEmail(partner: Boo.Objects.Partner, toEmailAddress: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/SendTestEmail`, {
        partner: partner,
        toEmailAddress: toEmailAddress
    });
  }

  getBoostabilityLogos(): Observable<Boo.Objects.PartnerLogos> {
    return this.http.get<Boo.Objects.PartnerLogos>(`${this.baseUrl}/GetBoostabilityLogos`);
  }

  getSmtpPassword(partnerId: number): Observable<string> {
    const params = new HttpParams().set('partnerId', partnerId);

    return this.http.get<string>(`${this.baseUrl}/GetSmtpPassword`, { params: params });
  }

  isPartnerNameUnique(partnerName: string, partnerId: number): Observable<boolean> {
    const params = new HttpParams()
      .set('partnerName', partnerName)
      .set('partnerId', partnerId);

    return this.http.get<boolean>(`${this.baseUrl}/IsPartnerNameUnique`, { params: params });
  }

  provisionTextMessagingPhoneNumber(partnerId: number): Observable<string> {
    return this.http.post<string>(`${this.baseUrl}/ProvisionTextMessagingPhoneNumber`, partnerId);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RetentionService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/retention`;

  constructor(private http: HttpClient) { }

  getRetentionAttempt(customerId: number, ticketId: number): Observable<Boo.Objects.Retention.RetentionAttempt> {
    const params = new HttpParams()
      .set('customerId', customerId)
      .set('ticketId', ticketId);

    return this.http.get<Boo.Objects.Retention.RetentionAttempt>(`${this.baseUrl}/getRetentionAttempt`, {params: params});
  }

  getRetentionReasons(): Observable<Boo.Objects.Retention.RetentionReason[]> {
    return this.http.get<Boo.Objects.Retention.RetentionReason[]>(`${this.baseUrl}/getRetentionReasons`);
  }

  getRetentionResolutionTypes(): Observable<Boo.Objects.Retention.RetentionResolutionType[]> {
    return this.http.get<Boo.Objects.Retention.RetentionResolutionType[]>(`${this.baseUrl}/getRetentionResolutionTypes`);
  }

  saveRetentionAttempt(retentionAttempt: Boo.Objects.Retention.RetentionAttempt): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/saveRetentionAttempt`, retentionAttempt);
  }
}

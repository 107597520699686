import { Component, Input, OnInit } from '@angular/core';
import { SeoWorkService } from '../../../../services/seo-work.service';
@Component({
  selector: 'app-components-specialist-actioncomponents-customertasktypes',
  templateUrl: './customer-task-types.component.html'
})
export class CustomerTaskTypesComponent implements OnInit {
  @Input() options: CustomerTaskTypesViewModelOptions;

  seoWorkDefaults: Boo.Objects.Work.SeoMinute.SeoWorkDefault[];

  taskMinutes = 0;

  boostMinutes = 0;

  constructor(private seoWorkService: SeoWorkService) { }

  ngOnInit() {
    this.taskMinutes = this.options.customer.SeoMinutesTotal;
    this.boostMinutes = this.options.customer.SeoMinutesFree;

    return this.seoWorkService.getSeoWorkDefaults(this.options.customer.CustomerId)
      .subscribe((summaries) => {
        this.seoWorkDefaults = summaries;
      });
  }
}

export interface CustomerTaskTypesViewModelOptions {
  customer: Boo.Objects.Customer;
}

<div class="form-group row" [formGroup]="emailSettingsForm">
  <label class="col-md-4 col-form-label">Disable System Emails?
    <br/>
    <small>Login Emails, Onsite Blog Preview Emails, Onsite Blog Content Emails</small>
  </label>
  <div class="col-md-6">
    <div>
      <shared-components-customchoice [value]="partner.IsUserAndContactRestricted" (valueChange)="setIsUserAndContactRestricted($event)"></shared-components-customchoice>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4 col-form-label">
      Onboarding Scheduling Link
    </div>
    <div class="col-md-8">
      <input class="form-control" type="text" formControlName="onboardingSchedulingLink" lpValidationMsg>
    </div>
  </div>
  <div class="form-group row">
    <div class="col-md-4 col-form-label">
      Scheduling Link
    </div>
    <div class="col-md-8">
      <input class="form-control" type="text" formControlName="schedulingLink" lpValidationMsg>
    </div>
  </div>
</div>

import { Component } from '@angular/core';
import permissions from 'app/models/Permissions';
import System from 'framework/System';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import aws = Boo.Objects.Amazon.Enums;
import { LinkLocationService } from '../../../services/link-location.service';
import { LinkLocationBlogDetailService } from '../../../services/link-location-blog-detail.service';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';

@Component({
	selector: 'app-components-specialist-blogsitedetails',
	templateUrl: './BlogSiteDetails.component.html'
})
export class BlogSiteDetailsComponent implements IActivatable {
	public moment = moment;
    public ko = ko;
    public awsDeployStatus: string;
    public blogCanBeRepublished: boolean;
    public blogCanBeRetired: boolean;
    public blogDetails: KnockoutObservable<LinkLocationBlogDetailsObservable>;
    public blogDetailsValidation: KnockoutObservable<any>;
    public country: Boo.Objects.Country;
    public firstBlogPostDate: string;
    public hasAdminUser: boolean;
    public hasPosts: boolean;
    public isLoading: KnockoutObservable<boolean>;
    public lastBlogPostDate: string;
    public language: Boo.Objects.Language;
    public linkLocation: KnockoutObservable<LinkLocationObservable>;
    public linkLocationValidation: KnockoutObservable<any>;
    public numberOfPosts: number;
    public title = 'Blog Site Details';
    public user: Boo.Objects.User;
    public verticals: Boo.Objects.Vertical[];
    public languages: Boo.Objects.Language[];

    private countries: Boo.Objects.Country[];

    private allowableRetireStatus: aws.AmazonDeploymentStatuses[] = [
        aws.AmazonDeploymentStatuses.Deployed
    ];
    private allowableRepublishStatuses: aws.AmazonDeploymentStatuses[] = [
        aws.AmazonDeploymentStatuses.New,
        aws.AmazonDeploymentStatuses.Deployed
    ];

    constructor(
      private linkLocationService: LinkLocationService,
      private linkLocationBlogDetailService: LinkLocationBlogDetailService,
      private sessionStorageService: SessionStorageService) { }

    public activate(params: any): JQueryPromise<void> {
        this.isLoading = ko.observable(true);

        return Utils.wrapDfd(this.sessionStorageService.getStaticData())
            .then((data: any) => {
                this.countries = data.Countries;
                this.languages = data.Languages;
                this.verticals = data.Verticals;

                const linkLocationId: number = params.LinkLocationId;
                return $.when<any>(
                  Utils.wrapDfd(this.linkLocationService.get(linkLocationId)),
                  Utils.wrapDfd(this.linkLocationBlogDetailService.getByLinkLocationId(linkLocationId)),
                  Utils.wrapDfd(this.linkLocationService.getPostSummary(linkLocationId)));
            }).then((ll: Boo.Objects.LinkLocation, blog: Boo.Objects.LinkLocationBlogDetail, summary: Boo.Objects.LinkLocationBlogPostSummary) => {
                this.hasPosts = summary.NumberOfPosts > 0;
                this.firstBlogPostDate = summary.FirstBlogPostDate ? moment(summary.FirstBlogPostDate).format('L') : 'N/A';
                this.lastBlogPostDate = summary.LastBlogPostDate ? moment(summary.LastBlogPostDate).format('L') : 'N/A';
                this.numberOfPosts = summary.NumberOfPosts;

                this.linkLocation = ko.observable(ko.mapping.fromJS(ll));
                this.blogDetails = ko.observable(ko.mapping.fromJS(blog));
                this.blogCanBeRetired = _.any(this.allowableRetireStatus, (status) => { return status === blog.AmazonDeploymentStatusId; });
                this.blogCanBeRepublished = _.any(this.allowableRepublishStatuses, (status) => { return status === blog.AmazonDeploymentStatusId; });
                const awsDeployStatuses = _.map(launchpad.config.AmazonDeploymentStatusEnum.names, (name: string) => { return { 'Name': name, 'Id': launchpad.config.AmazonDeploymentStatusEnum.ids[name.split(' ').join('')] as number }; });
                this.awsDeployStatus = _.findWhere(awsDeployStatuses, { Id: blog.AmazonDeploymentStatusId }).Name;

                this.country = _.findWhere(this.countries, { CountryId: this.linkLocation().CountryId() });
                this.language = _.findWhere(this.languages, { LanguageId: this.linkLocation().LanguageId() });

                this.initValidation();
                return System.emptyPromise();
            })
            .always(() => {
                this.isLoading(false);
            });
    }

    public canActivate(user: Boo.Objects.User, partner: Boo.Objects.Partner, params: any): boolean {
        this.user = user;
        const hasCanManageBlogSitesPermission = (launchpad as Launchpad).hasPermission(partner, user.PartnerUsers, permissions.CanManageBlogSites, user);

        this.hasAdminUser = _.any(user.PartnerUsers, (pu) => { return pu.UserLevelId === Boo.Objects.Enums.UserLevelEnum.Administrator; });

        return this.hasAdminUser || hasCanManageBlogSitesPermission;
    }

    public close(): void {
        (this as any).dfd.resolve();
    }

    public deployStaticSite(): void {
        this.isLoading(true);
        Utils.wrapDfd(this.linkLocationBlogDetailService.deploy(ko.mapping.toJS(this.blogDetails)))
            .then(() => {
                toastr.success('The blog will be deployed. It could take up to 20 minutes.');
            }).fail((displayMessage) => {
                toastr.error(displayMessage);
            })
            .always(() => {
                this.isLoading(false);
            });
    }

    public retireBlogAndRepostPosts(): void {
        bootbox.confirm('Are you sure you want to retire this blog? Posts will be republished to different blogs.', (result: boolean) => {
            if (!result) {
                return;
            }
            this.isLoading(true);

            Utils.wrapDfd(this.linkLocationService.retireBlog(this.linkLocation().LinkLocationId(), false, true))
                .then(() => {
                    toastr.success('The blog has been retired. Posts will be republished on different blogs.');
                })
                .fail((msg: string) => {
                    toastr.error(msg);
                })
                .always(() => {
                    this.isLoading(false);
                });
        });
    }

    public retireBlogWithoutRepublishing(): void {
      bootbox.confirm('Are you sure you would like to retire this blog? Posts will not be republished.', (result: boolean) => {
          if (!result) {
              return;
          }
          this.isLoading(true);

          Utils.wrapDfd(this.linkLocationService.retireBlog(this.linkLocation().LinkLocationId(), false, false))
              .then(() => {
                  toastr.success('The blog has been retired. Posts will not be republished.');
              })
              .fail((msg: string) => {
                  toastr.error(msg);
              })
              .always(() => {
                  this.isLoading(false);
              });
      });
    }

    public republishPosts(): void {
        bootbox.confirm('Are you sure you would like to republish the posts for this blog?', (result: boolean) => {
            if (!result) {
                return;
            }

            this.isLoading(true);

            Utils.wrapDfd(this.linkLocationService.repostPosts(this.linkLocation().LinkLocationId(), true))
                .then(() => {
                    toastr.success('Posts have been republished.');
                })
                .fail((msg: string) => {
                    toastr.error(msg);
                })
                .always(() => {
                    this.isLoading(false);
                });
        });
    }

    public resetDNS(): void {
        bootbox.confirm('Are you sure you would like to reset the cloudflare DNS for this blog?', (result: boolean) => {
            if (!result) {
                return;
            }

            this.isLoading(true);

            Utils.wrapDfd(this.linkLocationService.resetDNS(this.linkLocation().LinkLocationId()))
                .then(() => {
                    toastr.success('DNS has been reset.');
                })
                .fail((msg: string) => {
                    toastr.error(msg);
                })
                .always(() => {
                    this.isLoading(false);
                });
        });
    }

    public save(): void {
        if (!this.linkLocationValidation.isValid() || !this.blogDetailsValidation.isValid()) {
            this.linkLocationValidation.errors.showAllMessages();
            this.blogDetailsValidation.errors.showAllMessages();

            toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
            return;
        }

        this.isLoading(true);
        $.when<any>(
            Utils.wrapDfd(this.linkLocationService.saveBlogOrArticle(ko.mapping.toJS(this.linkLocation()) as Boo.Objects.LinkLocation)),
            Utils.wrapDfd(this.linkLocationBlogDetailService.save(ko.mapping.toJS(this.blogDetails()) as Boo.Objects.LinkLocationBlogDetail)))
          .then(() => {
              System.noop;
          })
          .fail((msg: string) => {
              toastr.error(msg);
          })
          .always(() => {
              this.isLoading(false);
          });
    }

    public viewStatusHistory(): void {
        PriorityViewModel.show('app-components-specialist-amazondeploymentstatushistory', { linkLocation: this.linkLocation() });
    }

    private initValidation(): void {
        const hasValue = (val: any) => { return parseInt(val, 10) > 0; };

        this.linkLocation().Name.extend({ required: true });
        this.linkLocation().Url.extend({
            required: true,
            pattern: new launchpad.utils.validation.validUrlValidationPattern()
        });

        this.linkLocation().LanguageId.extend({ validation: { validator: hasValue, message: 'Please pick one' } });
        this.linkLocation().CountryId.extend({ validation: { validator: hasValue, message: 'Please pick one' } });
        this.linkLocation().VerticalId.extend({ validation: { validator: hasValue, message: 'Please pick one' } });
        this.linkLocationValidation = ko.validatedObservable(this.linkLocation());
        this.blogDetails().PenName.extend({ required: true });
        this.blogDetailsValidation = ko.validatedObservable(this.blogDetails());
    }
}

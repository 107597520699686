import System from 'framework/System';
import { Component, ViewChild } from '@angular/core';
import { QaGradeService } from '../../../services/qa-grade.service';
import { QaCriteriaGroupService } from '../../../services/qa-criteria-group.service';
import { QaGradingAreaComponent } from '../../qa/qa-grading-area/qa-grading-area.component';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-specialist-changespecialistscore',
  templateUrl: './change-specialist-score.component.html'
})
export class ChangeSpecialistScoreComponent {
  @ViewChild('gradingArea')
  public gradingArea: QaGradingAreaComponent;
  public isLoading = false;
  public taskId: number;
  public showTool: boolean;
  public qaGrade: Boo.Qa.QaGrade;
  public user: Boo.Objects.User;
  public name: string;
  public qaTaskType: Boo.Objects.Enums.TaskTypeEnum;
  public qaCriteriaGroups: Boo.Qa.QaCriteriaGroup[];
  public dateString: string;

  constructor(private qaGradeService: QaGradeService, private qaCriteriaGroup: QaCriteriaGroupService) { }

  public loadGrade() {
    if (!this.taskId) {
      toastr.error('Please enter a Task ID');
      return;
    }

    this.isLoading = true;
    this.showTool = false;

    Utils.wrapDfd(this.qaGradeService.getWithUser(this.taskId))
      .then(qaGrade => {
        if (!qaGrade) {
          return System.autoRejectedPromise(`No grade found for task ${this.taskId}.`);
        }
        this.qaGrade = qaGrade;
        this.user = this.qaGrade.Task.AssignedUser;
        this.qaTaskType = this.qaGrade.InternalTask.TaskTypeId;
        this.dateString = moment(this.qaGrade.Task.StatusDate).format('MM/DD/YYYY h:mm:ss A');
        return Utils.wrapDfd(this.qaCriteriaGroup.getForTask(this.qaGrade.Task));
      })
      .then(qaCriteriaGroups => {
        this.qaCriteriaGroups = qaCriteriaGroups;
        this.showTool = true;
      })
      .fail((msg: string) => {
        this.clearGrade();
        toastr.error(msg);
      }).always(() => {
        this.isLoading = false;
      });
  }

  public changeGrade() {
    if (!this.gradingArea.validate()) {
      toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
      return;
    }

    this.isLoading = true;

    const details = this.gradingArea.getDetails();
    this.qaGrade.Comment = this.gradingArea.gradeComment;

    Utils.wrapDfd(this.qaGradeService.reviseGrade(this.qaGrade, details))
      .done(() => {
        toastr.success('Grade was updated successfully.');
        this.clearGrade();
      })
      .fail((displayMessage) => {
        toastr.error(displayMessage);
      }).always(() => {
        this.isLoading = false;
      });
  }

  public clearGrade() {
    this.showTool = false;
    this.qaGrade = null;
    this.user = null;
    this.qaTaskType = null;
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { SessionStorageService } from './session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class LocalProfileService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/localprofiles`;

  constructor(private http: HttpClient) { }

  byCustomerId(customerId: number): Observable<Boo.Objects.LocalProfiles.LocalProfile[]> {
    const params = new HttpParams().set('customerId', customerId);
    return this.http.get<Boo.Objects.LocalProfiles.LocalProfile[]>(`${this.baseUrl}/ByCustomerId`, { params: params });
  }

  activeByCustomerId(customerId: number): Observable<Boo.Objects.LocalProfiles.LocalProfile[]> {
    const params = new HttpParams().set('customerId', customerId);
    return this.http.get<Boo.Objects.LocalProfiles.LocalProfile[]>(`${this.baseUrl}/ActiveByCustomerId`, { params: params });
  }

  by(localProfileId: number): Observable<Boo.Objects.LocalProfiles.LocalProfile> {
    const params = new HttpParams().set('localProfileId', localProfileId);
    return this.http.get<Boo.Objects.LocalProfiles.LocalProfile>(this.baseUrl, { params: params });
  }

  save(request: Boo.Objects.LocalProfiles.SaveLocalProfilesRequest): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/Save`, request);
  }

  byTicketId(ticketId: number): Observable<Boo.Objects.LocalProfiles.LocalProfile> {
    const params = new HttpParams().set('ticketId', ticketId);
    return this.http.get<Boo.Objects.LocalProfiles.LocalProfile>(`${this.baseUrl}/ByTicketId`, { params: params });
  }

  fragments(customerId: number): Observable<Boo.Objects.LocalProfiles.LocalProfileFragment[]> {
    const params = new HttpParams().set('customerId', customerId);
    return this.http.get<Boo.Objects.LocalProfiles.LocalProfileFragment[]>(`${this.baseUrl}/Fragments`, { params: params });
  }

  findServiceAreas(input: string): Observable<Boo.Objects.LocalProfiles.LocalProfileServiceArea[]> {
    const params = new HttpParams().set('input', input);
    return this.http.get<Boo.Objects.LocalProfiles.LocalProfileServiceArea[]>(`${this.baseUrl}/serviceareas`, { params: params });
  }

  sort(a: Boo.Objects.LocalProfiles.LocalProfile, b: Boo.Objects.LocalProfiles.LocalProfile): number {
    // Favor existing over new
    if (a.LocalProfileId === 0 || b.LocalProfileId === 0) {
      if (a.LocalProfileId < b.LocalProfileId) {
        return 1;
      }

      if (a.LocalProfileId > b.LocalProfileId) {
        return -1;
      }

      return 0;
    }

    // Sort alphabetically. 
    if (a.IsActive === b.IsActive) {
      if (a.CompanyName < b.CompanyName) {
        return -1;
      }

      if (a.CompanyName > b.CompanyName) {
        return 1;
      }

      return 0;
    }

    // Favor active
    return (b.IsActive ? 1 : 0) - (a.IsActive ? 1 : 0);
  }

  sortFragments(a: Boo.Objects.LocalProfiles.LocalProfileFragment, b: Boo.Objects.LocalProfiles.LocalProfileFragment): number {
        if (!a.BusinessCitationSubscription || !b.BusinessCitationSubscription) {
          if (a.BusinessCitationSubscription) {
            return -1;
          }
          if (b.BusinessCitationSubscription) {
            return 1;
          }
        }
    
        if (!a.BusinessCitationSubscriptionTaskId || !b.BusinessCitationSubscriptionTaskId) {
          if (a.BusinessCitationSubscriptionTaskId) {
            return -1;
          }
          if (b.BusinessCitationSubscriptionTaskId) {
            return 1;
          }
        }
    
        if ((a.BusinessCitationSubscription && b.BusinessCitationSubscription) || (!a.BusinessCitationSubscription && !b.BusinessCitationSubscription)) {
          if (a.CompanyName < b.CompanyName) {
            return -1;
          }
    
          if (a.CompanyName > b.CompanyName) {
            return 1;
          }
    
          return 0;
        }
    
        return (b.CanSubscribe ? 1 : 0) - (a.CanSubscribe ? 1 : 0);
      }
}

<app-components-specialist-internal-tasks-domain-purchase-action-information
  [internalAction]="internalAction"></app-components-specialist-internal-tasks-domain-purchase-action-information>
<div class="row">
  <div class="col-md-12 text-center">
    <ng-container *ngIf="purchaseStatus === purchaseStatusPurchasing">
      <div class="card card-body bg-light d-block">
        <h3>Registering the Domain</h3>
        <p>
          <img style="height: 20px; background-color: black; margin-top: -5px; border-radius: 10px; opacity: 0.7"
            src="/Images/ajax-loader.gif" alt="" />
          Checking the status of the domain in
          <span>{{countDown}}</span>
          seconds.
        </p>
        <small>
          <strong>Why?</strong>
          We use
          <a href="https://www.namecheap.com/" target="_blank">namecheap</a>
          to register domains and sometimes they take a while to register a domain. Please wait.
        </small>
      </div>
    </ng-container>
  </div>
  <div class="col-md-12">
    <ng-container *ngIf="purchaseStatus === purchaseStatusNone || purchaseStatus === purchaseStatusPurchaseFailed">
      <div class="card card-body bg-light d-block">
        <h3>Please specify the domain name to purchase</h3>
        <ul>
          <li>
            Domain should match the vertical provided.
          </li>
          <li>
            The domain must be ready for purchase.
          </li>
          <li>
            Filters should be applied to select a '.com' domain, in the English language, that contains no adult names
          </li>
          <li>
            The older the domain, the better.
          </li>
        </ul>
        <br />
        <div class="form-group">
          <label class="control-label">Blog Domain Name</label>
          <div class="row">
            <div class="col-md-6">
              <input class="form-control" [formControl]="domainName" lpValidationMsg="Invalid domain name." />
            </div>
            <div class="col-md-3">
              <button class="btn btn-primary" [disabled]="domainName.invalid" (click)="purchase()">Purchase
                Domain</button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="col-md-12">
    <ng-container *ngIf="purchaseStatus === purchaseStatusPurchased">
      <h3>Domain purchased successfully!</h3>
    </ng-container>
  </div>
</div>
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FrontService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/frontapp`;

  constructor(private http: HttpClient) { }

  getPartnerChannel(partnerId: number): Observable<app.tsmodels.interfaces.FrontChannel> {
    return this.http.get<app.tsmodels.interfaces.FrontChannel>(`${this.baseUrl}/partnerChannel/${partnerId}`);
  }

  savePartnerChannel(partnerId: number, channel: app.tsmodels.interfaces.FrontChannel): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/partnerChannel/${partnerId}`, {
        partnerId: partnerId,
        channel: channel
    });
  }

  getPartnerChannelOrDefault(partnerId: number): Observable<app.tsmodels.interfaces.FrontChannel> {
    return this.http.get<app.tsmodels.interfaces.FrontChannel>(`${this.baseUrl}/partnerChannelOrDefault/${partnerId}`);
  }

  getChannels(): Observable<app.tsmodels.interfaces.FrontChannel[]> {
    return this.http.get<app.tsmodels.interfaces.FrontChannel[]>(`${this.baseUrl}/channels`);
  }

  getTeammates(): Observable<app.tsmodels.interfaces.FrontTeammate[]> {
    return this.http.get<app.tsmodels.interfaces.FrontTeammate[]>(`${this.baseUrl}/teammates`);
  }

  getUserTeammateId(userId: number): Observable<string> {
    return this.http.get<string>(`${this.baseUrl}/teammate/${userId}`);
  }

  createDraft(channelId: string, customerId: number): Observable<string> {
    return this.http.post<string>(`${this.baseUrl}/createdraft`, {
        channelId: channelId,
        customerId: customerId
    });
  }

  sendDiscoveryEmail(customerId: number, note: string, appointmentTime: Date): Observable<any> {
    return this.http.post(`${this.baseUrl}/messages/discovery`, {
        customerId: customerId,
        note: note,
        appointmentTime: appointmentTime
    });
  }
}
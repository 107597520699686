import { Component, Input, OnInit } from '@angular/core';
import { WebsiteService } from '../../../services/website.service';
import { WordpressWebsiteService } from '../../../services/wordpresswebsite.service';
import { forkJoin, of } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Notification } from '../../../shared/models/notifications/notifications';

@Component({
  selector: 'app-components-websites-vendornotifications',
  templateUrl: './VendorNotifications.component.html'
})
export class VendorNotificationsComponent implements OnInit {
  @Input() public hasHadCustomWeb: boolean;
  @Input() public hasHadBoostSite: boolean;
  @Input() public customerId: number;
  isLoading = false;
  notifications: Notification[] = [];
  shownBodyNotification: Notification;

  constructor(private websiteService: WebsiteService,
    private wordpressWebsiteService: WordpressWebsiteService) { }

  ngOnInit(): void {
    this.isLoading = true;
    forkJoin([
      (this.hasHadCustomWeb ? this.wordpressWebsiteService.getVendorNotifications(this.customerId) : of([])),
      (this.hasHadBoostSite ? this.websiteService.getVendorNotifications(this.customerId) : of([]))
    ])
    .pipe(
      finalize(() => this.isLoading = false)  
    ).subscribe(([wordpressNotifications, websiteNotifications]) => {
      this.notifications.push(...wordpressNotifications, ...websiteNotifications);
    });
  }

  showBody(notification: Notification) {
    this.shownBodyNotification = notification;
  }
  
  hideBody(_: Notification) {
    this.shownBodyNotification = null;
  }
}

import { Component } from '@angular/core';
import { CustomerQuickUrlContentAnalysisService } from '../../../../services/customer-quick-url-content-analysis.service';
import { SeoContentSurveyService } from '../../../../services/seo-content-survey.service';
import { OnsiteRecommendationBase } from '../OnsiteRecommendationBase/OnsiteRecommendationBase';

type OnsiteRecommendationInput = Boo.Tasks.Inputs.Models.OnsiteRecommendationInput;
type OnsiteRecommendationCreateContentOutput = Boo.Tasks.Outputs.Models.OnsiteRecommendationCreateContentOutput;

@Component({
    selector: 'app-components-specialist-actioncomponents-onsiterecommendation',
    templateUrl: './OnsiteRecommendation.component.html'
})
export class OnsiteRecommendationComponent extends OnsiteRecommendationBase implements IActionWorkspace, IActivatable {
    title = 'Onsite Recommendation';
    actionViewModel: KnockoutObservable<IActionWorkspace>;
    output: OnsiteRecommendationCreateContentOutput;

    constructor(
        protected seoContentSurveyService: SeoContentSurveyService,
        protected customerQuickUrlContentAnalysisService: CustomerQuickUrlContentAnalysisService) {
        super(seoContentSurveyService, customerQuickUrlContentAnalysisService);
    }

    activate(params: IActionWorkspaceActivationParams): JQueryPromise<void> {
        params.controlViewModel(this);
        this.actionViewModel = ko.observable(null);
        this.output = params.output as OnsiteRecommendationCreateContentOutput;

        return super.initialize(params.currentAction, params.input as OnsiteRecommendationInput);
    }

    isStepReadyToReject(): boolean {
        return false;
    }

    isStepReadyToSave(): boolean {
        return false;
    }

    isStepReadyToComplete(): boolean {
        if (!this.validation.isValid()) {
            this.validation.errors.showAllMessages();
            return false;
        }

        this.currentAction().Task.KeywordId = this.keywordId();
        this.currentAction().Task.AreaId = this.areaId();
        this.currentAction().Task.IsAreaFirst = this.isAreaFirst();

        this.output.OnsiteRecommendation = this.input.OnsiteRecommendation;

        if (!this.output.OnsiteRecommendation) {
            this.output.OnsiteRecommendation = {} as Boo.Objects.OnsiteRecommendation;
        }

        this.output.OnsiteRecommendation.TitleNew = $.trim(this.newTitle());
        this.output.OnsiteRecommendation.TitleOld = $.trim(this.currentTitle());
        this.output.OnsiteRecommendation.DescriptionNew = $.trim(this.newDescription());
        this.output.OnsiteRecommendation.DescriptionOld = $.trim(this.currentDescription());
        this.output.OnsiteRecommendation.WebsiteUrlId = this.currentAction().Task.WebsiteUrlId;
        this.output.OnsiteRecommendation.TaskId = this.currentAction().Task.TaskId;
        this.output.OnsiteRecommendation.H1Old = this.currentHeadingTags();
        this.output.OnsiteRecommendation.H1New = this.newH1Tag();
        this.output.OnsiteRecommendation.H2New = this.newH2Tag();
        this.output.OnsiteRecommendation.AltImageNew = this.newAltImageTag();

        // Pull out any image tag that have the same source as the new tag.

        if (this.newAltImageTag()) {
            const pattern = new RegExp('<img.+?src=["\'](.+?)["\'].+?>', 'i');
            const altImageNewSrc = pattern.exec(this.newAltImageTag());

            if (altImageNewSrc) {
                const altImageNewSrcString = $.trim(altImageNewSrc ? altImageNewSrc.toString() : '').toLowerCase();
                const imgTags = this.currentAltImageTags().split('\r\n');
                let oldAltImg = '';
                if (imgTags.length > 0) {
                    _.each(imgTags, (tag) => {
                        const tagPattern = new RegExp('<img.+?src=["\'](.+?)["\'].+?>', 'i');
                        const tmpStr = tagPattern.exec(tag);
                        if (tmpStr && $.trim(tmpStr ? tmpStr.toString() : '').toLowerCase() === altImageNewSrcString) {
                            oldAltImg += (tag + '\r\n');
                        }
                    });
                    this.output.OnsiteRecommendation.AltImageOld = oldAltImg;
                }
            }
        }

        const fileExtension = (/[.]/.exec(this.filename())) ? /[^.]+$/.exec(this.filename()) : '';
        if (fileExtension.length > 0) {
            this.output.OnsiteRecommendation.FileExtension = '.' + fileExtension;
        }

        const dataSplits = this.base64Data().split('base64,');
        if (dataSplits.length > 1) {
            this.output.ChangesFileBytes = dataSplits[1];
        }

        return true;
    }

    isStepReadyToDelete(): boolean {
        return false;
    }
}

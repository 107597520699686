import { Component } from '@angular/core';
import System from 'framework/System';
import { CustomerTodoListComponent } from '../../todo/CustomerTodoList/CustomerTodoList.component';
import { wobbleIf } from '../../../shared/animations';

@Component({
	selector: 'app-components-managecustomer-floatingcustomertodolist',
  templateUrl: './FloatingCustomerTodoList.component.html',
  animations: [wobbleIf]
})
export class FloatingCustomerTodoListComponent implements IActivatable {
  public customer: Boo.Objects.Customer;
  public todoListPanel: IJQueryFloatPanel;
  public unresolved: KnockoutObservable<number> = ko.observable(0);
  public unsavedChanges: KnockoutObservable<boolean> = ko.observable(false);
  public isUserPartner: KnockoutObservable<boolean> = ko.observable(false);
  public ctlViewModel: CustomerTodoListComponent;

  public activate(params: any): JQueryPromise<any> {
    this.customer = ko.toJS(params.customer);
    this.todoListPanel = ($('#customer-todo-list-float-panel') as any).floatPanel({ collapsed: true });

    return System.emptyPromise();
  }

  public save(): void {
    this.ctlViewModel.save();
  }

  public customerTodoListComposeBinding(model: CustomerTodoListComponent): void {
    this.ctlViewModel = model;

    this.isUserPartner(this.ctlViewModel.isUserPartner);

    // number of unresolved todos
    this.unresolved(this.ctlViewModel.unresolvedTodos());
    this.ctlViewModel.unresolvedTodos.subscribe(x => this.unresolved(x));

    // change detection
    this.ctlViewModel.unsavedChanges.subscribe(x => this.unsavedChanges(x));
  }
}

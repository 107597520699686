import { Component, Input, OnInit } from '@angular/core';
import { QaCriteriaControlComponent } from '../qa-criteria-control/qa-criteria-control.component';

@Component({
	selector: 'app-components-qa-criteria-button-group',
	templateUrl: './qa-criteria-button-group.component.html',
	styleUrls: ['./qa-criteria-button-group.scss']
})
export class QaCriteriaButtonGroupComponent extends QaCriteriaControlComponent implements OnInit {
	@Input() blockTitle = false;

	ngOnInit(): void {
		super.ngOnInit();
	}
}
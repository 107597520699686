import { Directive, Input, ElementRef, OnChanges, AfterViewInit, OnDestroy } from '@angular/core';

@Directive({
  selector: '[richText]'
})
export class RichTextDirective implements AfterViewInit, OnChanges, OnDestroy {
  @Input('richText') public Settings: { content: KnockoutObservable<string>, options: IEditorOptions, wordCount: KnockoutObservable<number>, autoGrow: boolean };

  private static DefaultOptions: IEditorOptions = {
    toolbar: [
      {
        name: 'document',
        items: ['Source', 'Maximize']
      },
      // Defines toolbar group with name (used to create voice label)
      // and items in 3 subgroups.
      {
        name: 'clipboard',
        groups: ['clipboard', 'undo'],
        items: [
          'Cut', 'Copy', 'Paste', 'PasteText',
          'PasteFromWord', '-', 'Undo', 'Redo'
        ]
      },
      {
        name: 'editing',
        groups: ['find', 'selection', 'spellchecker'],
        items: ['Find', 'Replace', '-', 'SelectAll']
      },
      {
        name: 'links',
        items: ['Link', 'Unlink']
      },
      '/', // new row
      {
        name: 'basicstyles',
        groups: ['basicstyles', 'cleanup'],
        items: [
          'Bold', 'Italic', 'Underline', 'Strike',
          'Subscript', 'Superscript', '-',
          'RemoveFormat'
        ]
      },
      {
        name: 'paragraph',
        groups: ['list', 'indent', 'blocks', 'align', 'bidi'],
        items: [
          'NumberedList', 'BulletedList', '-', 'Outdent',
          'Indent', '-', 'Blockquote', '-',
          'JustifyLeft', 'JustifyCenter', 'JustifyRight',
          'JustifyBlock'
        ]
      }
    ],
    readOnly: false
  };

  private element: HTMLTextAreaElement;
  private $element: JQuery;
  constructor(elementRef: ElementRef) {
    this.element = elementRef.nativeElement;
    this.$element = $(this.element);
  }

  public ngAfterViewInit(): void {
    if (!this.$element.is('textarea')) {
      throw 'You can only use the richtext binding on textarea elements.';
    }

    this.$element.html(ko.utils.unwrapObservable(this.Settings.content));

    // The options are the CKEditor options that are used to configure the toolbar. We have the full installation of the editor but we start it with a base set of functionality.
    // http://ckeditor.com/latest/samples/plugins/toolbar/toolbar.html
    const options = this.Settings.options || RichTextDirective.DefaultOptions;

    if (this.Settings.autoGrow) {
      options.autoGrow_onStartup = true;
      options.extraPlugins = window.launchpad.config.ckeditorExtraPlugins + ',autogrow';
    }

    this.$element.ckeditor(() => {
      const editor = this.$element.ckeditorGet();
      const wordCount = this.Settings.wordCount;

      editor.on('blur', (e: any) => {
        if (ko.isWriteableObservable(this.Settings.content)) {
          this.Settings.content($(e.listenerData).val());
        }
      }, this, this.element);

      editor.on('change', (e: any) => {
        if (ko.isWriteableObservable(this.Settings.content)) {
          this.Settings.content($(e.listenerData).val());
        }
        if (ko.isWriteableObservable(wordCount)) {
          wordCount(editor.plugins.wordcount.wordCount);
        }
      }, this, this.element);

      editor.on('afterPaste', (e: any) => {
        if (ko.isWriteableObservable(this.Settings.content)) {
          this.Settings.content($(e.listenerData).val());
        }
        if (ko.isWriteableObservable(wordCount)) {
          wordCount(editor.plugins.wordcount.wordCount);
        }
      }, this, this.element);

      editor.on('instanceReady', () => {
        if (ko.isWriteableObservable(wordCount)) {
          wordCount(editor.plugins.wordcount.wordCount);
        }
      }, this, this.element);

      if (ko.isWriteableObservable(wordCount)) {
        wordCount(editor.plugins.wordcount.wordCount);
      }
    }, options);
  }

  public ngOnChanges(): void {
    if (!this.$element.data("ckeditorInstance")) {
      setTimeout(() => {
        this.ngOnChanges();
      }, 1000);
      return;
    }

    let value = ko.utils.unwrapObservable(this.Settings.content);
    if (!value)
    {
      // setting falsey values to an empty paragraph is required because if a ckeditor is inited to a value it won't set to '' or null
      value = '<p></p>'
    }

    const editor = this.$element.ckeditorGet();
    if (editor && value !== editor.getData()) {
      editor.setData(value);
    }
  }

  public ngOnDestroy(): void {
    const existingEditor = this.$element.ckeditorGet();
    if (existingEditor) {
      existingEditor.destroy(true);
    }
  }
}

type ToolbarEntry = { name: string, groups?: string[], items: string[] } | string;
export interface IEditorOptions {
  autoGrow_onStartup?: boolean;
  extraPlugins?: string;
  toolbar: ToolbarEntry[];
  readOnly: boolean;
  allowedContent?: string | boolean;
}
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import MerchantFieldLabelMap from 'app/models/typescript/MerchantFieldLabelMap';
import PartnerGroupEnum = Boo.Objects.Enums.PartnerGroupEnum;
import { SessionStorageService } from './session-storage.service';
import Utils from '../../app/shared/utils';

@Injectable({
  providedIn: 'root'
})

export class MerchantFieldsService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/merchantfields`;
  private partnerGroups: Boo.Objects.Group[];

  constructor(
    private http: HttpClient,
    private sessionStorageService: SessionStorageService) { }

  export(statuses: Boo.Objects.Enums.StatusEnum[]): Observable<any> {
    return this.http.post(`${this.baseUrl}/Export`, { statuses: statuses });
  }

  import(merchantFieldsList: Boo.Objects.CustomerMerchantFields[]): Observable<any> {
    return this.http.post(`${this.baseUrl}/Import`, { merchantFieldsList: merchantFieldsList })
  }

  get(customerId: number): Observable<Boo.Objects.CustomerMerchantFields> {
    const params = new HttpParams().set('customerId', customerId);
    return this.http.get<Boo.Objects.CustomerMerchantFields>(this.baseUrl, { params: params });
  }

  save(merchantFields: Boo.Objects.CustomerMerchantFields): Observable<any> {
    return this.http.post<void>(this.baseUrl, merchantFields)
  }

  // Changes to merchant labels should also be added to customerinformation.js and editmerchantfields.js
  public generateLabelMapping(partnerId: number): JQueryPromise<MerchantFieldLabelMap> {
    // defaults
    const map: MerchantFieldLabelMap = new MerchantFieldLabelMap();

    return Utils.wrapDfd(this.sessionStorageService.getStaticData())
      .then((staticData: any) => {
        this.partnerGroups = staticData.PartnerGroups;

        // partner specific
        if (this.isPartnerInPartnerGroup(partnerId, PartnerGroupEnum.Thryv)) {
          map.IdLabel.Value = 'Thryv Customer Id';
          map.ProductCodeLabel.Value = 'Legacy Name';
          map.CategoryIdLabel.Value = 'Company Id';
          map.ItemIdLabel.Value = 'Item Id';
          map.CategoryLabel.Value = 'Listing Id';
          map.SubCategoryLabel.Value = 'Category';
          map.SubCategoryIdLabel.Value = 'Enterprise Account Id';
          map.ConsultantLabel.Value = 'Business Advisor';
          map.ConsultantContactLabel.Value = 'Business Advisor\'s Email';
          map.SitePublishDateLabel.Value = 'Site Publish Date';
          map.UdacLabel.Value = 'UDAC';
          map.SourceLabel.Value = 'Source (Local/National)';
          map.ProposalIdLabel.Value = 'Merchant ID';
          map.MiscIdLabel.Value = 'SEO Proposal ID';
          map.RegionLabel.Value = 'Sales Region';
          map.CustomerValueLabel.Value = 'Total Contract Value';
          map.ContractEndDateLabel.Value = 'Contract End Date';
          map.ServiceLevelLabel.Value = 'Client Service Level';
          map.CampaignManagerLabel.Value = 'Service Specialist';
          map.CampaignManagerContactLabel.Value = 'Service Specialist Email';
        } else if (this.isPartnerInPartnerGroup(partnerId, PartnerGroupEnum.Yellow)) {
          map.IdLabel.Value = 'Advertiser ID';
          map.SourceLabel.Value = 'BC Product ID';
          map.ProposalIdLabel.Value = 'MSA Name';
          map.CategoryLabel.Value = 'MISC';
          map.ConsultantContactLabel.Value = 'MSA/MC Email';
          map.CategoryIdLabel.Value = 'MSA Phone';
          map.SubCategoryLabel.Value = 'Heading';
          map.SubCategoryIdLabel.Value = 'SiteSmart(Y/N)';
          map.ConsultantLabel.Value = 'SiteSmart Customisation (Y/N)';
          map.UdacLabel.Value = 'Need Click Manager PPC Campaign (Y/N)';
          map.ProductCodeLabel.Value = 'Product Code';
          map.ItemIdLabel.Value = 'Web Specialist';
          map.MiscIdLabel.Value = 'Web Specialist Email Address';
        } else if (partnerId === launchpad.config.partners.foundDigital) {
          map.IdLabel.Value = 'Advertiser ID';
          map.SourceLabel.Value = 'BC Product ID';
          map.ProposalIdLabel.Value = 'MSA Name';
          map.CategoryLabel.Value = 'MSA Email';
          map.CategoryIdLabel.Value = 'MSA Phone';
          map.SubCategoryLabel.Value = 'Heading';
          map.SubCategoryIdLabel.Value = 'SiteSmart (Y/N)';
          map.ConsultantLabel.Value = 'SiteSmart Customisation (Y/N)';
          map.UdacLabel.Value = 'Need Click Manager PPC Campaign (Y/N)';
          map.ProductCodeLabel.Value = 'Product Code';
          map.ItemIdLabel.Value = 'Web Specialist';
          map.MiscIdLabel.Value = 'Web Specialist Email Address';
        } else if (this.isPartnerInPartnerGroup(partnerId, PartnerGroupEnum.GoDaddy)) {
          map.CustomerValueLabel.Value = 'GoDaddy ID';
          map.SourceLabel.Value = 'Plan Name';
          map.ProductCodeLabel.Value = 'Term Length';
          map.ContractEndDateLabel.Value = 'Pre-Pay Plan Expiration';
          map.CategoryLabel.Value = 'NPS Score';
          map.ConsultantLabel.Value = 'NPS Score Updated';
          map.ProposalIdLabel.Value = 'Auto-Renew Status';
        } else if (partnerId === window.launchpad.config.partners.herold) {
          map.ConsultantContactLabel.Value = 'Customer Email for Reports';
        } else if (partnerId === window.launchpad.config.partners.heiseRegioConcept) {
          map.ConsultantContactLabel.Value = 'Customer Email for Reports';
        } else if (partnerId === window.launchpad.config.partners.heiseRegioConceptÖsterreich) {
          map.ConsultantContactLabel.Value = 'Customer Email for Reports';
        } else if (partnerId === window.launchpad.config.partners.heiseRegioConceptSchweiz) {
          map.ConsultantContactLabel.Value = 'Customer Email for Reports';
        }

        return map;
      });
  }

  private isPartnerInPartnerGroup(partnerId: number, partnerGroup: Boo.Objects.Enums.PartnerGroupEnum): boolean {
    return launchpad.utils.partnerInPartnerGroup(partnerId, partnerGroup, this.partnerGroups);
  }
}

export default class CsvConversionService {
    // Converts a list of objects to comma separated strings with a header
    public convert(columnMapping: app.interfaces.ICsvColumn[], dataSource: any[]): string {
        const headerData: string[] = [];
        _.each(columnMapping, (column: app.interfaces.ICsvColumn) => {
            headerData.push(column.Name);
        });

        const rowData: string[][] = [];
        const items = ko.utils.unwrapObservable(dataSource);
        for (const item of items) {
            const row: string[] = [];
            for (const column of columnMapping) {
                const dataSourceName = column.Value;
                // Allow nesting one level
                let value: any = null;
                if (dataSourceName.indexOf('.') >= 0) {
                    const splitDataSource = dataSourceName.split('.');
                    value = ko.utils.unwrapObservable(item[splitDataSource[0]][splitDataSource[1]]);
                } else {
                    value = ko.utils.unwrapObservable(item[dataSourceName]);
                }
                if (_.isString(value)) {
                    // CSV quotes are escaped by using 2 quotes
                    value = value.split('"').join('""');
                }
                row.push(value);
            }
            rowData.push(row);
        }
        let data = `"${headerData.join('","')}"\r\n`;
        for (const row of rowData) {
            data += `"${row.join('","')}"\r\n`;
        }
        return data;
    }
}

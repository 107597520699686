import { Component } from '@angular/core';
import { MerchantFieldsService } from '../../../services/merchant-fields.service';
import permissions from 'app/models/Permissions';
import Check from 'framework/Check';
import MerchantFieldLabelMap from 'app/models/typescript/MerchantFieldLabelMap';
import { finalize } from 'rxjs/operators';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';

@Component({
	selector: 'app-components-manager-merchantfields',
	templateUrl: './MerchantFields.component.html'
})
export class MerchantFieldsComponent implements IActivatable {
    public exportData: KnockoutObservable<string>;
    public exportFilename: KnockoutObservable<string>;
    public exportValidation: KnockoutObservable<any>;
    public importContent: KnockoutObservable<string>;
    public importFile: KnockoutObservable<any>;
    public importFilename: KnockoutObservable<string>;
    public importLimit: KnockoutObservable<number>;
    public importValidation: KnockoutObservable<any>;
    public isLoading: KnockoutObservable<boolean>;
    public statuses: KnockoutObservableArray<app.interfaces.ICheckboxSelectionObservable>;
    public merchantFieldLabels: KnockoutObservable<MerchantFieldLabelMap>;

    private partner: Boo.Objects.Partner;

    constructor(
      private merchantFieldsService: MerchantFieldsService,
      private sessionStorageService: SessionStorageService) {}

    public canActivate(user: Boo.Objects.User, partner: Boo.Objects.Partner, params: any): boolean {
        return launchpad.hasPermission(partner, user.PartnerUsers, permissions.CanManageMerchantFields, user);
    }

    public activate(params: any): JQueryPromise<any> {
        this.isLoading = ko.observable(true);
        this.exportData = ko.observable('');
        this.exportFilename = ko.observable('');
        this.importFile = ko.observable(null);
        this.importFilename = ko.observable(null).extend({ required: true });
        this.importContent = ko.observable('');
        this.importLimit = ko.observable(500);
        this.merchantFieldLabels = ko.observable(null);

        this.statuses = ko.observableArray([
            {
                Name: ko.observable('Active'),
                Value: ko.observable(2),
                IsChecked: ko.observable(true)
            },
            {
                Name: ko.observable('Canceled'),
                Value: ko.observable(5),
                IsChecked: ko.observable(false)
            }]).extend({
                validation: [
                    {
                        validator: (values: any): boolean  => {
                            const statusList = _.filter(values, (value: any) => {
                                return value.IsChecked();
                            });
                            return statusList.length > 0;
                        },
                        message: 'At least one customer status must be selected'
                    }
                ]
            });

        this.importValidation = ko.validatedObservable([this.importFilename]);
        this.exportValidation = ko.validatedObservable([this.statuses]);

        return Utils.wrapDfd(this.sessionStorageService.getPartner())
            .then((partner: Boo.Objects.Partner) => {
                this.partner = partner;
                // todo: need the correct map
                return this.merchantFieldsService.generateLabelMapping(this.partner.PartnerId);
            })
            .then((map: MerchantFieldLabelMap) => {
                this.merchantFieldLabels(map);
            })
            .fail((displayMessage: string) => toastr.error(displayMessage))
            .always(() => this.isLoading(false));
    }

    public exportMerchantFields(): any {
        if (!this.exportValidation.isValid()) {
            this.exportValidation.errors.showAllMessages(true);
            return;
        } else {
            this.isLoading(true);
            const statusList = _.filter(this.statuses(), (status: any) => {
                return status.IsChecked();
            });

            const statuses = ko.toJS(_.pluck(statusList, 'Value'));
            this.merchantFieldsService.export(statuses)
                .pipe(
                    finalize(() => this.isLoading(false))
                )
                .subscribe({
                    next: x => {
                        const result: any = Papa.unparse(x.map(this.formatForExport));
                        const a = window.document.createElement('a');
                        a.setAttribute('download', `Merchant Fields ${this.partner.Name} (${ko.toJS(_.pluck(statusList, 'Name')).join(',')}) ${moment().format('L')}.csv`);
                        a.href = window.URL.createObjectURL(new Blob(['\uFEFF' + result], { type: 'text/csv;charset=utf-8' }));

                        document.body.appendChild(a);
                        a.click();

                        document.body.removeChild(a);
                    },
                    error: err => toastr.error(err)
                });
        }
    }

    public importMerchantFields(): any {
        if (!this.importValidation.isValid()) {
            this.importValidation.errors.showAllMessages(true);
            return;
        } else {
            try {
                this.isLoading(true);

                // Parse csv
                const result: PapaParse.ParseResult = Papa.parse(this.importContent(), { skipEmptyLines: true, header: true });
                Check.isNotNull(result, 'Cannot read CSV file');
                Check.isTrue(result.data.length <= this.importLimit() + 1, `File too big. Please import ${this.importLimit()} records at a time.`);

                // Check csv format
                const csvFormatArray: string[] = ['CustomerMerchantFieldId', 'CustomerId', 'MerchantCampaignManager', 'MerchantCampaignManagerContact', 'MerchantCategory', 'MerchantCategoryId', 'MerchantConsultant', 'MerchantConsultantContact', 'MerchantContractEndDate', 'MerchantCustomerValue', 'MerchantId', 'MerchantItemId', 'MerchantMiscId', 'MerchantProductCode', 'MerchantProposalId', 'MerchantRegion', 'MerchantServiceLevel', 'MerchantSitePublishDate', 'MerchantSource', 'MerchantSubCategory', 'MerchantSubCategoryId', 'MerchantUdac', 'InsertedDate'];

                Check.csvFormatByKeys(csvFormatArray, result);

                // Import csv
                const merchantFieldsList: Boo.Objects.CustomerMerchantFields[] = result.data as Boo.Objects.CustomerMerchantFields[];
                this.merchantFieldsService.import(merchantFieldsList)
                    .pipe(
                        finalize(() => this.isLoading(false))
                    )
                    .subscribe({
                        next: () => {
                            this.importContent('');
                            this.importFile(null);
                            this.importFilename(null);
                            this.importValidation.errors.showAllMessages(false);
                            toastr.success('Import successful.');
                        },
                        error: err => toastr.error(err)
                    })
            } catch (e) {
                toastr.error(e);
                this.isLoading(false);
            }
        }
    }

    public formatForExport(data: any): any {
        for (const property in data) {
            if (!Object.prototype.hasOwnProperty.apply(data, property)) {
                continue;
            }

            if (data[property] === null) {
                continue;
            }

            // dates should be in a short format
            if (data[property].getDate) {
                data[property] = moment(data[property]).utc().toISOString();
            }
        }
        return data;
    }
}

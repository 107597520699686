import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { switchMap, tap } from 'rxjs/operators';

const sessionStorageKeys = {
  staticData: 'sd',
  reloadData: 'rd'
};

const localStorageKeys = {
  user: 'u',
  partner: 'p',
  bearerToken: 'bt',
  bearerTokenExpiration: 'bte',
  businessCitiationProviderCategories: 'pc',
  businessCitiationProviderProfileData: 'ppd'
};

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {
  private baseUrl = `${environment.urlConfig.serviceUrl}`;
  private sessionUrl = `${environment.urlConfig.serviceUrl}/session`;

  private user: Boo.Objects.User;
  private partner: Boo.Objects.Partner;
  private partners: Boo.Objects.Partner[];
  private partnerUser: Boo.Objects.PartnerUser;
  private partnerUsers: Boo.Objects.PartnerUser[];
  private bearerToken = '';
  private accessToken = '';
  private readonly cacheTimeout = 300000; // 5 minutes
  private readonly expirationTimeout = 60000; // 1 minute
  private readonly reloadExpiration = 60000; // 1 minute
  private readonly oneHour = 3600000;
  private readonly tenHours = 36000000;
  private nextItemExpiration = 0;
  private oneHourExpirationNotice = false;

  constructor(private http: HttpClient) {
    setInterval(this.clearCache.bind(this), this.cacheTimeout);
    setInterval(this.clearExpiredItems.bind(this), this.expirationTimeout);
  }

  getCurrentUser(): Observable<Boo.Objects.User> {
    return this.http.get<Boo.Objects.User>(`${this.sessionUrl}/GetCurrentUser`);
  }

  setReloadData(data: any): void {
    sessionStorage.setItem(sessionStorageKeys.reloadData, this.createExpirableJsonObject(data, this.reloadExpiration));
  }

  getReloadData(): any {
    return JSON.parse(sessionStorage.getItem(sessionStorageKeys.reloadData));
  }

  clearReloadData(): void {
    localStorage.removeItem(localStorageKeys.businessCitiationProviderCategories);
    sessionStorage.removeItem(sessionStorageKeys.reloadData);
  }

  getBusinessCitationProviderCategories(): Observable<Boo.BusinessCitations.Models.BusinessCitationProviderCategory[]> {
    return of(JSON.parse(localStorage.getItem(localStorageKeys.businessCitiationProviderCategories)) as Boo.BusinessCitations.Models.BusinessCitationProviderCategory[]);
  }

  setBusinessCitationProviderCategories(categories: Boo.BusinessCitations.Models.BusinessCitationProviderCategory[]): void {
    localStorage.setItem(localStorageKeys.businessCitiationProviderCategories, JSON.stringify(categories));
  }

  getBusinessCitationProviderProfileData(): Observable<Boo.BusinessCitations.Models.BusinessCitationProviderProfileData> {
    return of(JSON.parse(localStorage.getItem(localStorageKeys.businessCitiationProviderProfileData)) as Boo.BusinessCitations.Models.BusinessCitationProviderProfileData);
  }

  setBusinessCitationProviderProfileData(staticData: Boo.BusinessCitations.Models.BusinessCitationProviderProfileData): void {
    localStorage.setItem(localStorageKeys.businessCitiationProviderProfileData, JSON.stringify(staticData));
  }

  handleBearerTokenExpiration(): void {
    const expirationDate = new Date(this.getBearerTokenExpiration());
    const now = new Date();
    const oneHourBeforeExpiration = new Date(expirationDate.getTime() - this.oneHour);

    if (now >= expirationDate) {
      const message = 'Login again to continue.';
      toastr.info(message, 'Session has expired', {
        timeOut: 4000,
        extendedTimeOut: 0,
        tapToDismiss: true
      });

      this.oneHourExpirationNotice = false;
      this.clear();
      this.logout();
      window.location.reload();
      return;
    }

    if (now >= oneHourBeforeExpiration && !this.oneHourExpirationNotice) {

      const message = 'Save your work and log in again.';
      toastr.info(message, 'Session expiring in one hour', {
        timeOut: 0,
        extendedTimeOut: 0,
        tapToDismiss: false
      });

      this.oneHourExpirationNotice = true;
      return;
    }
  }

  getBearerTokenExpiration(): Date {
    const expiration = localStorage.getItem(localStorageKeys.bearerTokenExpiration);
    return expiration ? new Date(expiration) : new Date();
  }

  getBearerTokenValue(): string {
    if (this.bearerToken) {
      return this.bearerToken;
    }

    const bearerToken = localStorage.getItem(localStorageKeys.bearerToken);
    this.bearerToken = bearerToken ? bearerToken : this.bearerToken;
    return this.bearerToken;
  }

  setBearerToken(jwtToken: IdentityModel.Client.JwtToken) {
    this.bearerToken = jwtToken.AccessToken;
    localStorage.setItem(localStorageKeys.bearerToken, this.bearerToken);

    const expiration = new Date(Date.now() + ((jwtToken.ExpiresIn * 1000)));
    localStorage.setItem(localStorageKeys.bearerTokenExpiration, expiration.toISOString());
  }

  setAutoLoginBearerToken(accessToken: string) {
    this.bearerToken = accessToken;
    localStorage.setItem(localStorageKeys.bearerToken, this.bearerToken);

    const expiration = new Date(Date.now() + this.tenHours);
    localStorage.setItem(localStorageKeys.bearerTokenExpiration, expiration.toISOString());
  }

  getAccessToken(): Observable<string> {
    return of(this.accessToken);
  }

  getAccessTokenValue(): string {
    return this.accessToken;
  }

  setAccessToken(value: string) {
    this.accessToken = value;
    this.updateSignalRHeaders();
  }

  getStaticData(): Observable<Boo.Objects.LaunchPadStaticData> {
    const storedStaticData = JSON.parse(sessionStorage.getItem(sessionStorageKeys.staticData));
    return storedStaticData ? of(storedStaticData) : this.refreshStaticData();
  }

  refreshStaticData(): Observable<any> {
    return this.http.get<Boo.Objects.LaunchPadStaticData>(`${this.baseUrl}/staticdata`)
      .pipe(tap(staticData => sessionStorage.setItem(sessionStorageKeys.staticData, JSON.stringify(staticData))));
  }

  getUser(): Observable<Boo.Objects.User> {
    if (this.user) {
      return of(this.user);
    }

    const user = (JSON.parse(localStorage.getItem(localStorageKeys.user))?.data || null) as Boo.Objects.User;

    if (!user) {
      localStorage.clear();
      this.setAccessToken('');
    }
    this.user = user;
    return of(this.user);
  }

  setUser(user: Boo.Objects.User) {
    localStorage.setItem(localStorageKeys.user, this.createExpirableJsonObject(user, this.tenHours));

    if (user) {
      if (user.Partners && user.Partners.length > 0) {
        this.setPartner(user.Partners[0]);
        if (user.PartnerUsers && user.PartnerUsers.length > 0) {
          const firstPartnerUser: any = _.findWhere(user.PartnerUsers, { PartnerId: user.Partners[0].PartnerId });
          this.setAccessToken(firstPartnerUser.AccessToken);
        }
      }
    }
  };

  getPartner(): Observable<Boo.Objects.Partner> {
    if (this.partner) {
      return of(this.partner);
    }

    this.partner = (JSON.parse(localStorage.getItem(localStorageKeys.partner))?.data || null) as Boo.Objects.Partner;
    return of(this.partner);
  }

  setPartner(partner: Boo.Objects.Partner) {
    const user = (JSON.parse(localStorage.getItem(localStorageKeys.user))?.data || null) as Boo.Objects.User;
    if (partner && user && user.PartnerUsers) {
      const firstPartnerUser: any = _.findWhere(user.PartnerUsers, { PartnerId: partner.PartnerId });
      this.setAccessToken(firstPartnerUser.AccessToken);
    }
    localStorage.setItem(localStorageKeys.partner, this.createExpirableJsonObject(partner, this.tenHours));
  };

  getPartners(): Observable<Boo.Objects.Partner[]> {
    if (this.partners && this.partners.length > 0) {
      return of(this.partners);
    }

    const user = (JSON.parse(localStorage.getItem(localStorageKeys.user))?.data || null) as Boo.Objects.User;
    this.partners = user ? _.uniq(user.Partners, x => x.PartnerId) : [];
    return of(this.partners);
  }

  getPartnerUser(userLevelId = 0): Observable<Boo.Objects.PartnerUser> {
    if ((this.partnerUser?.UserLevelId || null) === userLevelId) {
      return of(this.partnerUser);
    }
    return forkJoin([
      this.getPartnerUsersFromUser(),
      this.getPartner()
    ])
      .pipe(
        switchMap(([partnerUsers, partner]) => {
          if (partnerUsers && partner) {
            let bestUser: any;
            if (userLevelId) {
              bestUser = _.findWhere(partnerUsers, { UserLevelId: userLevelId, PartnerId: partner.PartnerId });
            } else {
              const adminUser = _.findWhere(partnerUsers, { UserLevelId: 1, PartnerId: partner.PartnerId });
              const managerUser = _.findWhere(partnerUsers, { UserLevelId: 5, PartnerId: partner.PartnerId });
              const customerServiceUser = _.findWhere(partnerUsers, { UserLevelId: 6, PartnerId: partner.PartnerId });
              const specialistUser = _.findWhere(partnerUsers, { UserLevelId: 2, PartnerId: partner.PartnerId });
              const salesUser = _.findWhere(partnerUsers, { UserLevelId: 4, PartnerId: partner.PartnerId });

              // coalesce madness
              bestUser = adminUser || (managerUser || (customerServiceUser || (specialistUser || (salesUser))));
            }

            if (bestUser) {
              this.accessToken = bestUser.AccessToken;
            }

            this.partnerUser = bestUser;
            return of(bestUser);
          } else {
            this.partnerUser = null;
            return of(null);
          }
        })
      );
  }

  getPartnerUsers(): Observable<Boo.Objects.PartnerUser[]> {
    if (this.partnerUsers && this.partnerUsers.length > 0) {
      return of(this.partnerUsers);
    }

    return forkJoin([this.getPartnerUsersFromUser(), this.getPartner()])
      .pipe(
        switchMap(([partnerUsers, partner]) => {
          this.partnerUsers = (partnerUsers && partner) ? _.where(partnerUsers, { PartnerId: partner.PartnerId }) : [] as Boo.Objects.PartnerUser[];
          return of(this.partnerUsers);
        }));
  }

  clearCache(): void {
    this.user = null;
    this.partner = null;
    this.partners = null;
    this.partnerUser = null;
    this.partnerUsers = null;
  };

  clear(): void {
    this.clearCache();
    localStorage.clear();
  }

  logout(): void {
    localStorage.clear();
    this.setAccessToken('');
    this.bearerToken = '';
  }

  clearForReload(): void {
    sessionStorage.removeItem(sessionStorageKeys.staticData);
    sessionStorage.removeItem(sessionStorageKeys.reloadData);
  }

  private getPartnerUsersFromUser(): Observable<Boo.Objects.PartnerUser[]> {
    return this.getUser().pipe(switchMap(user => (user && user.PartnerUsers) ? of(user.PartnerUsers) : of([] as Boo.Objects.PartnerUser[])));
  }

  private updateSignalRHeaders(): void {
    if ($.signalR as any) {
      ($.signalR as any).ajaxDefaults.headers = { 'PartnerUserAccessToken': this.accessToken };
    }
  }

  private createExpirableJsonObject(object: any, expiresInMilliseconds: number): string {
    const expiration = new Date(Date.now() + expiresInMilliseconds).getTime();
    this.updateNextItemExpiration(expiration);
    return JSON.stringify({
      data: object,
      expires: expiration
    } as IExpirableObject);
  }

  private updateNextItemExpiration(itemExpiration: number): void {
    if (!this.nextItemExpiration || itemExpiration < this.nextItemExpiration) {
      this.nextItemExpiration = itemExpiration;
    }
  }

  private isExpired(expirableObject: IExpirableObject): boolean {
    if (expirableObject) {
      this.updateNextItemExpiration(expirableObject.expires);
      return expirableObject.expires < Date.now();
    }
    return false;
  }

  private clearExpiredItems(): void {
    if (this.nextItemExpiration > Date.now()) {
      return;
    }

    this.nextItemExpiration = 0;

    // Expires in 60 seconds
    if (this.isExpired(JSON.parse(sessionStorage.getItem(sessionStorageKeys.reloadData)) as IExpirableObject)) {
      sessionStorage.removeItem(sessionStorageKeys.reloadData);
    }
  }
}

export interface IExpirableObject {
  data: object;
  expires: number;
}

<div [loadingOverlay]="isLoading()" class="card">
  <div class="card-header">Locked Internal Tasks
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label class="control-label">Task Type</label>
          <select [ngModel]="selectedTaskTypeId()" (ngModelChange)="selectedTaskTypeId($event)"
            [ngModelOptions]="{ standalone: true }" [disabled]="lockedInternalTasks().length === 0" name="showFilter"
            class="form-select">
            <option *ngFor="let taskType of taskTypes()" [ngValue]="taskType.taskTypeId">{{ taskType.taskTypeName }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="control-label">Assigned User</label>
          <select [ngModel]="selectedUserId()" (ngModelChange)="selectedUserId($event)"
            [ngModelOptions]="{ standalone: true }" [disabled]="lockedInternalTasks().length === 0" name="showFilter"
            class="form-select">
            <option *ngFor="let user of users()" [ngValue]="user.Id">{{ user.Name }}</option>
          </select>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="control-label d-block">&nbsp;</label>
          <button (click)="loadLockedInternalTasks()" class="btn btn-primary">
            <i class="fa fa-search"></i>
            Search
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <app-table [items]="filteredTasks()" [exportFilename]="'locked-internal-tasks'">
          <ng-template #tableHeader>
            <tr>
              <th export sort field="InternalTaskId">Task ID</th>
              <th export sort field="InsertedDate">Created Date</th>
              <th export sort field="StatusDate">Locked Date</th>
              <th export sort field="TaskTypeName">Task Type</th>
              <th export sort field="FullName">Assigned User</th>
              <th export sort field="LinkLocationUrl">Website URL</th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template #tableBody let-task>
            <tr>
              <td>{{task.InternalTaskId}}</td>
              <td>{{task.InsertedDate | dateText }}</td>
              <td>{{task.StatusDate | dateText }}</td>
              <td>{{task.TaskTypeName}}</td>
              <td>{{task.FullName}}</td>
              <td>{{task.LinkLocationUrl}}</td>
              <td>
                <button (click)="unlock(task)" class="btn btn-info">
                  <i class="fa fa-unlock"></i>
                  Unlock &amp; Unassign
                </button>
              </td>
            </tr>
          </ng-template>
        </app-table>
      </div>
    </div>
  </div>
</div>
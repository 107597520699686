import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import QaStockComment = Boo.Qa.QaStockComment;

@Component({
	selector: 'app-components-qa-stock-comment-select',
	templateUrl: './qa-stock-comment-select.component.html',
	styleUrls: ['./qa-stock-comment-select.scss']
})
export class QaStockCommentSelectComponent implements OnChanges {
	@Input() stockComments: QaStockComment[] = [];
	@Output() insertStockComment = new EventEmitter<string>();

	public selectedComment: QaStockComment;

	insert() {
		this.insertStockComment.emit(this.selectedComment.Comment);
	}

	ngOnChanges(changes: SimpleChanges): void {
		const stockCommentsKey = 'stockComments';
		if (Object.prototype.hasOwnProperty.apply(changes, stockCommentsKey)) {
			this.selectedComment = changes[stockCommentsKey].currentValue[0];
		}
	}
}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BlogDiagnosticService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/blog/diagnostics`;

  constructor(private http: HttpClient) { }

  isUp(linkLocationBlogDetailId: number): Observable<boolean> {
    return this.http.post<boolean>(`${this.baseUrl}/IsUp`, linkLocationBlogDetailId);
  }

  cloudFlareDns(linkLocationBlogDetailId: number): Observable<{ Key: string, Value: string }[]> {
    return this.http.post<{ Key: string, Value: string }[]>(`${this.baseUrl}/CloudflareDns`, linkLocationBlogDetailId);
  }

  namecheapNameservers(linkLocationBlogDetailId: number): Observable<string[]> {
    return this.http.post<string[]>(`${this.baseUrl}/NamecheapNameservers`, linkLocationBlogDetailId);
  }
}

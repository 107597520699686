import { Component, Input, OnInit } from '@angular/core';
import System from 'framework/System';
import { CustomerAccountGroupComponent } from '../../../shared/components/customer-account-group/customer-account-group.component';
import CustomerAccountTypes = Boo.Objects.Enums.CustomerAccountTypes;
import interfaces = app.managecustomer.components.interfaces;
import { SaveTypes } from 'app/models/enums/SaveTypes';
import { CustomerKeyValuePairService } from '../../../services/customer-keyvaluepair.service';
import { CustomerAccountRouteFactory } from 'app/customeraccount/CustomerAccountRouteFactory';
import { CustomerAccountService } from '../../../services/customer-account.service';
import { WebsiteService } from '../../../services/website.service';
import CustomerHelper from '../../../../Scripts/app/managecustomer/CustomerHelper';
import { CustomerService } from '../../../services/customer.service';
import { PreAuditDetailService } from '../../../services/pre-audit-detail.service';
import { OnsiteRecommendationDetailService } from '../../../services/onsite-recommendation-detail.service';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';
import { finalize, forkJoin } from 'rxjs';

export interface WebsiteAccessOptions extends app.managecustomer.components.interfaces.CustomerAccountGroupOptions {
    getCurrentImplementationResponsibility?: () => Boo.OnsiteRecommendations.Models.Enums.Responsibilities;
    customer: CustomerObservable;
    showContentManagementSystem: boolean;
    showBoostabilityAccessOption: boolean;
}

/*
 * Adds external access note to the save and validation for the customer account group.
 */
@Component({
    selector: 'app-components-managecustomer-websiteaccess',
    templateUrl: './website-access.component.html'
})
export class WebsiteAccessComponent extends CustomerAccountGroupComponent implements OnInit {
    @Input() options: WebsiteAccessOptions;
    customer: CustomerObservable;
    validation: KnockoutObservable<any> = ko.observable(false);
    groupValidation: KnockoutObservable<any>;
    isExternalAccessShared: boolean;
    canEditContentManagementSystem: boolean;
    isManagedByPartner: boolean;
    contentManagementSystem: Boo.Objects.ContentManagementSystem;
    showContentManagementSystem: boolean;
    showBoostabilityAccessOption: boolean;
    getCurrentImplementationResponsibility: () => Boo.OnsiteRecommendations.Models.Enums.Responsibilities;

    currentUser: Boo.Objects.User;

    constructor(
        private customerKeyValuePairService: CustomerKeyValuePairService,
        customerAccountRouteFactory: CustomerAccountRouteFactory,
        customerAccountService: CustomerAccountService,
        websiteService: WebsiteService,
        private customerService: CustomerService,
        private preAuditDetailService: PreAuditDetailService,
        private onsiteRecommendationDetailService: OnsiteRecommendationDetailService,
        sessionStorageService: SessionStorageService
    ) { super(customerAccountRouteFactory, customerAccountService, websiteService, sessionStorageService); }

    ngOnInit() {
        this.customer = this.options.customer;
        this.showSaveButton = this.options.showSaveButton;
        this.isExternalAccessShared = ko.utils.unwrapObservable(this.options.customer.IsExternalAccessShared);
        this.showBoostabilityAccessOption = this.options.showBoostabilityAccessOption;
        this.showContentManagementSystem = this.options.showContentManagementSystem;

        if (this.options.publicApiCallback) {
            this.options.publicApiCallback(this.getPublicApi());
        }

        this.isLoading = true;
        forkJoin([
            this.onsiteRecommendationDetailService.getByCustomerId(this.customer.CustomerId()),
            this.sessionStorageService.getPartnerUsers(),
            this.sessionStorageService.getUser(),
            this.customerKeyValuePairService.get(this.customer.CustomerId())
        ])
        .pipe(finalize(() => this.isLoading = false))
        .subscribe(([onsiteRecommendationDetail, partnerUsers, currentUser, keys]) => {
            this.isManagedByPartner = CustomerHelper.getIsWebsiteManagedByPartner(keys);
            this.getCurrentImplementationResponsibility = this.options.getCurrentImplementationResponsibility ?? (() => onsiteRecommendationDetail?.Responsibility);

            this.validation.extend({
                validation: {
                    validator: (val: number): boolean => {
                        let isValid: boolean = val >= 0;
                        if (this.getCurrentImplementationResponsibility && this.getCurrentImplementationResponsibility() === Boo.OnsiteRecommendations.Models.Enums.Responsibilities.Boostability && !this.hasAccountAndSiteId) {
                            isValid = false;
                            const filteredComponents: interfaces.CustomerAccountViewModel[] = _.filter(this.components, (component: interfaces.CustomerAccountViewModel) => {
                                return component.customerAccount.CustomerAccountTypeId() === CustomerAccountTypes.CMSCustomerAccess ||
                                    component.customerAccount.CustomerAccountTypeId() === CustomerAccountTypes.FTP;
                            });
                            _.each(filteredComponents, (component: interfaces.CustomerAccountViewModel) => {
                                const account: Boo.Objects.CustomerAccount = ko.mapping.toJS(component.customerAccount);
                                const passwordIsSet: boolean = !!account.Password || !!account.SetPassword;
                                if (!!account.Username && !!account.Url && passwordIsSet) {
                                    isValid = true;
                                }
                            });
                            if (this.isManagedByPartner) {
                                isValid = true;
                            }
                        }
                        return isValid;
                    },
                    message: 'Login credentials for CMS or FTP are required if Boostability is making onsite edits for the customer.'
                }
            });
            this.groupValidation = ko.validatedObservable([this.validation]);

            ko.validation.rules['CMSIsBlacklisted'] = {
                validator: function (val: Boo.Objects.ContentManagementSystem) {
                    return !val?.IsBlacklisted;
                },
                message: 'SEO onsite implementation is not supported for this CMS.'
            };
            ko.validation.registerExtenders();
            this.currentUser = currentUser;

            const userLevels = partnerUsers.filter((x: Boo.Objects.PartnerUser) => x.PartnerId === this.customer.PartnerId() || x.PartnerId === launchpad.config.partners.boostability)
                .map((x: Boo.Objects.PartnerUser) => x.UserLevelId);
            this.canEditContentManagementSystem = _.intersection(userLevels, [Boo.Objects.Enums.UserLevelEnum.Administrator]).length > 0;
            return super.ngOnInit();
        });
    }

    getPublicApi(): app.interfaces.ISave {
        return {
            save: (saveType: SaveTypes): JQueryPromise<void> => { return this.save(saveType); },
            confirmSave: (saveType: SaveTypes): JQueryPromise<boolean> => { return this.confirmSave(saveType); }
        };
    }

    ContentManagementSystemChanged(cms: Boo.Objects.ContentManagementSystem): void {
        this.contentManagementSystem = cms;
    }

    confirmSave(saveType: SaveTypes): JQueryPromise<boolean> {
        return System.resolvedPromise(true);
    }

    save(saveType?: SaveTypes): JQueryPromise<void> {
        // this has to be here for the validation to update in the UI
        this.validation.notifySubscribers();

        const promises: JQueryPromise<void>[] = [];
        promises.push(this.SaveLogins(saveType));
        promises.push(this.SaveCustomerCMS());
        promises.push(this.SaveCustomerKeyValuePairIsManagedByPartner());
        promises.push(this.saveCustomerExternalAccess());

        return $.when<any>(...promises)
            .fail((displayMessage: string) => {
                toastr.error(displayMessage);
            });
    }

    SaveLogins(saveType?: SaveTypes): JQueryPromise<void> {
        if (!this.groupValidation.isValid()) {
            this.groupValidation.errors.showAllMessages();
            return System.autoRejectedPromise('rejected');
        } else {
            return super.save(saveType);
        }
    }

    SaveCustomerCMS(): JQueryPromise<any> {
        // Only save contentManagementSystem if it has changed and canEditContentManagementSystem
        if (this.canEditContentManagementSystem) {
            return Utils.wrapDfd(this.preAuditDetailService.updateContentManagementSystem(
                this.customer.CustomerId(),
                this.contentManagementSystem ? this.contentManagementSystem.ContentManagementSystemId : null));
        }
        return System.emptyPromise();
    }

    SaveCustomerKeyValuePairIsManagedByPartner(): JQueryPromise<void> {
        return Utils.wrapDfd(this.customerKeyValuePairService.get(this.customer.CustomerId()))
            .then(customerKeyValuePairs => {
                return Utils.wrapDfd(this.customerKeyValuePairService.save(CustomerHelper.setIsWebsiteManagedByPartner(customerKeyValuePairs, this.customer.CustomerId(), this.isManagedByPartner, this.currentUser.UserId)));
            });
    }

    saveCustomerExternalAccess(): JQueryPromise<void> {
        if (this.isExternalAccessShared !== this.customer.IsExternalAccessShared()) {
            this.customer.IsExternalAccessShared(this.isExternalAccessShared);
            return Utils.wrapDfd(this.customerService.save(ko.mapping.toJS(this.customer)));
        }
        return System.emptyPromise();
    }
}

import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SessionStorageService } from '../../../../services/session-storage.service';

@Component({
  selector: 'app-components-shared-local-profile-address',
  templateUrl: './address.component.html'
})
export class AddressComponent implements OnInit {
  @Input() localProfileFormGroup?: UntypedFormGroup;
  @Input() localProfile?: Boo.Objects.LocalProfiles.LocalProfile;

  state = '';
  states: Boo.Objects.State[] = [];

  constructor(private sessionStorageService: SessionStorageService) { }

  ngOnInit(): void {

    this.sessionStorageService.getStaticData()
      .subscribe(staticData => {
        this.states = staticData.States as Boo.Objects.State[];
      },
        (err) => toastr.error(err)
      );

    if (this.localProfileFormGroup) {
      const stateFormGroup = this.localProfileFormGroup.get('stateId');
      if (stateFormGroup && stateFormGroup.value) {
        this.state = this.getStateAbbreviation(stateFormGroup.value);
      } else {
        return;
      }

     stateFormGroup.valueChanges.subscribe((stateId: number) => {
        this.state = this.getStateAbbreviation(stateId);
      });
    }

    if (this.localProfile && this.localProfile.StateId) {
      this.state = this.getStateAbbreviation(this.localProfile.StateId);
    }
  }

  private getStateAbbreviation(stateId: number): string {
    return this.states.find(x => x.StateId === stateId)?.Abbreviation ?? '';
  }
}
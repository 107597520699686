import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { QaGradingAreaComponent } from '../../../../qa/qa-grading-area/qa-grading-area.component';
import { CompletableWorkspace } from 'app/interfaces/completable-workspace';
import { UnlockableWorkspace } from 'app/interfaces/unlockable-workspace';
import { DeletableWorkspace } from 'app/interfaces/deletable-workspace';
import { InternalActionToken } from '../../internal-action.token';
import { Subject } from 'rxjs';
import { ClosableWorkspace } from 'app/interfaces/closable-workspace';
import QaCriteriaGroup = Boo.Qa.QaCriteriaGroup;

@Component({
  selector: 'app-components-specialist-internal-tasks-local-business-citation-qa',
  templateUrl: './local-business-citation-qa.component.html'
})
export class LocalBusinessCitationQaComponent implements OnInit, CompletableWorkspace, UnlockableWorkspace, DeletableWorkspace, ClosableWorkspace {
  showCompleteButton = false;
  showUnlockButton = true;
  showDeleteButton = false;
  showCloseButton = true;
  complete = new Subject<void>();
  delete = new Subject<void>();
  
  @ViewChild('gradingArea')
  gradingArea: QaGradingAreaComponent;
  taskType: Boo.Objects.Enums.TaskTypeEnum = Boo.Objects.Enums.TaskTypeEnum.InternalTaskQaLocalBusinessCitations;
  qaCriteriaGroups: QaCriteriaGroup[] = [];
  isLoading: boolean;
  associatedTask: Boo.Objects.Task;
  taskActionUrl: string;
  customer: Boo.Objects.Customer;
  localProfile: Boo.Objects.LocalProfiles.LocalProfile;
  targetKeywords: string[];
  files: Boo.Objects.File[] = [];
  showGoogleBusinessProfile: boolean;
  googleBusinessProfileCustomerAccountType: number = Boo.Objects.Enums.CustomerAccountTypes.GoogleBusinessProfile;
  googleBusinessProfileIsEnabled = false;

  constructor(@Inject(InternalActionToken) public internalAction: Boo.Objects.InternalAction) { }

  ngOnInit() {
    this.isLoading = true;
    //Create the criteria float panel
    ($('#internal-task-qa-criteria-float-panel') as any).floatPanel({ collapsed: false });

    this.qaCriteriaGroups = this.internalAction.InternalTaskDetails.QaCriteriaGroups;
    this.associatedTask = this.internalAction.InternalTaskDetails.AssociatedTask;
    this.taskActionUrl = this.internalAction.InternalTaskDetails.AssociatedTask.TaskActionUrl;
    this.customer = this.internalAction.InternalTaskDetails.AssociatedTaskCustomer;
    this.localProfile = this.internalAction.InternalTaskDetails.LocalProfile;

    this.showGoogleBusinessProfile = this.associatedTask.TaskActionUrl.indexOf('google.com') > -1;

    this.targetKeywords = _.map(this.internalAction.InternalTaskDetails.WebsiteUrl.Keywords, (websiteUrlKeyword: Boo.Objects.WebsiteUrlKeyword) => {
      return websiteUrlKeyword.Keyword.Name;
    });

    this.isLoading = false;
  }

  isReadyToComplete(): boolean {
    if (!this.gradingArea.validate()) {
      toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
      return false
    }

    this.internalAction.InternalTaskDetails.QaCriteriaDetails = this.gradingArea.getDetails();
    this.internalAction.InternalTaskDetails.QaGrade.Comment = this.gradingArea.gradeComment;
    return true;
  }
  
  isReadyToDelete(): boolean {
    return true;
  }

  openTaskActionUrl(): void {
    window.open(this.taskActionUrl, '_blank');
  }
}

import { Component, OnInit } from '@angular/core';
import { CountryLanguageService } from "../../../services/countryLanguage.service";
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';

@Component({
    selector: 'app-components-admin-countryandlanguage',
    templateUrl: './CountryAndLanguage.component.html'
})
export class CountryAndLanguageComponent implements OnInit {
    public dfd: any;
    public isLoading: boolean;
    public selectedCountry: Boo.Objects.Country;
    public filteredCountries: Boo.Objects.Country[];
    public selectedLanguage: Boo.Objects.Language;
    public filteredLanguages: Boo.Objects.Language[];
    public countryLanguages: Boo.Objects.CountryLanguage[];

    constructor(
      private countryLanguageService: CountryLanguageService,
      private sessionStorageService: SessionStorageService) { }

    ngOnInit(): void {
        this.isLoading = true;
        this.getStaticData();
        this.isLoading = false;
    }


    getStaticData(): void {
        this.isLoading = true;
        Utils.wrapDfd(this.sessionStorageService.getStaticData())
          .then((staticData: Boo.Objects.LaunchPadStaticData) => {
              this.filteredCountries = staticData.Countries.filter((country: any) => country.SupportStatusId === 3);
              this.filteredLanguages = staticData.Languages.filter((language: any) => language.SupportStatusId === 3);
              this.countryLanguages = staticData.CountryLanguages;
          })
          .fail((err: any) => toastr.error(err))
          .always(() => this.isLoading = false);
    }

    close(): void {
        if (this.dfd) {
            this.dfd.resolve();
        }
    }

    cancel(): void {
        if (this.dfd) {
            this.dfd.reject();
        }
    }

    save(): void {
        this.isLoading = true;
        if(!this.selectedCountry || !this.selectedLanguage) {
            this.isLoading = false;
            toastr.error("Select Country and Language");
            return;
        }

        if (this.countryLanguages.filter(x => x.CountryId === this.selectedCountry.CountryId && x.LanguageId === this.selectedLanguage.LanguageId)?.length) {
            toastr.error("Selected pair already exists");
        }
        else {
            const countryLanguage: Boo.Objects.CountryLanguage = {
                CountryLanguageId: null,
                CountryId: this.selectedCountry.CountryId,
                Country: this.selectedCountry,
                LanguageId: this.selectedLanguage.LanguageId,
                Language: this.selectedLanguage,
            };
            this.countryLanguageService.save(countryLanguage)
                .subscribe(
                    () => this.close(),
                    (err) => toastr.error(err),
                    () => this.isLoading = false
                );
        }
        this.isLoading = false;
    };
}
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { OnsiteRecommendationService } from '../../../../../services/onsite-recommendation.service';
import TaskHelper from 'app/specialist/TaskHelper';
import System from 'framework/System';
import { finalize } from 'rxjs/operators';
import { TaskRejectionService } from '../../../../../services/task-rejection.service';
import ITaskFilesPublicApi = app.components.interfaces.ITaskFilesPublicApi;
import ITaskFilesPublicApiCallback = app.components.interfaces.ITaskFilesPublicApiCallback;
import TaskTypes = Boo.Objects.Enums.TaskTypeEnum;
import { forkJoin, of } from 'rxjs';
import { error } from 'jquery';

@Component({
  selector: 'app-components-specialist-actioncomponents-onsiterecommendationimplementation',
  templateUrl: './OnsiteRecommendationImplementation.component.html'
})
export class OnsiteRecommendationImplementationComponent implements OnInit, IActionWorkspace, IActivatable {
  private validatedControls: UntypedFormControl[];

  actionViewModel: KnockoutObservable<IActionWorkspace>;
  currentAction: KnockoutObservable<Boo.Objects.Action>;
  taskFilesApi: ITaskFilesPublicApi;
  taskFilesApiCallback: ITaskFilesPublicApiCallback;
  onsiteRecommendationImplementationSummary: Boo.Objects.OnsiteImplementation.OnsiteRecommendationImplementationSummary;
  onsiteChangeFileUrl: string;
  newTitle: string;
  newDescription: string
  newH1: string;
  newH2: string;
  newAltImage: string;
  noteToReviewerCtrl: UntypedFormControl;
  rejection: Boo.Objects.TaskRejection = null;
  isLoading = false;

  constructor(private onsiteRecommendationService: OnsiteRecommendationService,
    private taskRejectionService: TaskRejectionService) {}

  ngOnInit(): void {

    this.onsiteChangeFileUrl = this.currentAction().TaskDetails.OnsiteRecommendation.S3Url;
    this.newTitle = this.currentAction().TaskDetails.OnsiteRecommendation.TitleNew;
    this.newDescription = this.currentAction().TaskDetails.OnsiteRecommendation.DescriptionNew;
    this.newH1 = this.currentAction().TaskDetails.OnsiteRecommendation.H1New;
    this.newH2 = this.currentAction().TaskDetails.OnsiteRecommendation.H2New;
    this.newAltImage = this.currentAction().TaskDetails.OnsiteRecommendation.AltImageNew;

    this.taskFilesApiCallback = (api: ITaskFilesPublicApi) => { this.taskFilesApi = api; }

    this.noteToReviewerCtrl = new UntypedFormControl(TaskHelper.getOnsiteImplementationNoteToReviewer(this.currentAction().Task), [Validators.maxLength(2000)]);
    this.validatedControls = [this.noteToReviewerCtrl];

    const previousTaskId = TaskHelper.getPreviouslyCompletedImplementationTaskId(this.currentAction().Task);
    this.isLoading = true;

    forkJoin([
      this.onsiteRecommendationService.getImplementationSummary(this.currentAction().Task.TaskId),
      previousTaskId ? this.taskRejectionService.getLatest(previousTaskId) : of(null)
    ])
      .pipe(finalize(() =>
      { 
        this.isLoading = false;
        if (previousTaskId) {
          toastr.warning('This is an implementation revision');
        }
      }))
      .subscribe(
        ([summary, rejection]) => {
          this.onsiteRecommendationImplementationSummary = summary;
          this.rejection = rejection;
        },
        (error: any) => toastr.error(error)
      );

  }

  activate(params: IActionWorkspaceActivationParams): JQueryPromise<any> {
    params.controlViewModel(this);
    this.currentAction = params.currentAction;
    this.actionViewModel = ko.observable(null);
    return System.emptyPromise();
  }

  isStepReadyToReject(): boolean {
    return false;
  }

  isStepReadyToSave(): boolean {
    if (!this.currentAction().Task.TaskTypeSummary.IsPersistable ||
      !this.validatedControls.every(x => x.valid))
    {
      // show errors
      this.validatedControls.forEach(x => x.markAsTouched());
      return false;
    }

    TaskHelper.maybeSetOnsiteImplementationNoteToReviewer(this.currentAction().Task, this.noteToReviewerCtrl.value, this.currentAction().Task.AssignedUserId);
    return true;
  }

  isStepReadyToDelete(): boolean {
    return false;
  }

  isStepReadyToComplete(): boolean {
    let isValid = true;
    if (!this.validatedControls.every(x => x.valid))
    {
      this.validatedControls.forEach(x => x.markAsTouched());
      isValid = false;
    }

    TaskHelper.maybeSetOnsiteImplementationNoteToReviewer(this.currentAction().Task, this.noteToReviewerCtrl.value, this.currentAction().Task.AssignedUserId);
    return isValid;
  }
}
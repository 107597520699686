import 'startup/Utils';
import 'startup/Config';
import 'startup/CustomKnockoutFunctions';
import 'startup/CustomKnockoutExtenders';
import 'startup/CustomKnockoutValidationRules';
import 'startup/GoogleAnalytics';
import permissions from 'app/models/Permissions';
import { IResolvedPriorityView, PriorityViewModel } from 'app/models/PriorityViewModel';
import BoundFunctionEqualityMonkeyPatch from 'startup/BoundFunctionEqualityMonkeyPatch';
import { HeaderViewModel } from 'app/models/HeaderViewModel';
import { HelpTextService } from '../app/services/help-text.service';
import { PartnerService } from '../app/services/partner.service';
import { LogService } from '../app/services/log.service';
import Utils from '../app/shared/utils';
import { ReloadService } from '../app/services/reload.service';
import { TicketService } from '../app/services/ticket.service';
import { SessionStorageService } from '../app/services/session-storage.service';
import { ViewLocatorService } from '../app/services/view-locator.service';
import { switchMap, tap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { environment } from '../environments/environment';

class app {
  public viewLocatorService: ViewLocatorService;
  public partnerService: PartnerService;
  public helpTextService: HelpTextService;
  public logService: LogService;
  public ticketService: TicketService;
  public sessionStorageService: SessionStorageService;
  public reloadService: ReloadService;
  public canSendNotifications: any = ko.observable(true);
  public priorityViews: KnockoutObservableArray<IResolvedPriorityView> = ko.observableArray([]);
  public isNavigating: any = ko.observable(false);
  // Global UI visiblity observables
  public showModalContainer: any = ko.observable(false);
  public showHeader: any = ko.observable(false);
  public showFooter: any = ko.observable(true);
  public showContent: any = ko.observable(true);
  public showPriorityViewBreadcrumb: any = ko.observable(false);
  // Views and ViewModels
  public headerViewModel: any = ko.observable();

  public route: any = ko.observable({});

  public modalViewName: any = ko.observable('');
  public modalViewModel: any = ko.observable();

  // App-wide observables
  public hasUserBeenNotifiedSessionExpired: any = ko.observable(false);
  public isReadonly: any = ko.observable(true);
  public partnerId: any = ko.observable();
  public partnerName: any = ko.observable();
  public isSingleSignOnRequired: any = ko.observable();
  public smallLogoUrl: any = ko.observable();
  public largeLogoUrl: any = ko.observable();
  public isHighProfile: any = ko.observable(false);
  public highProfileReason: any = ko.observable();
  public hasAdultContent: any = ko.observable(false);
  public staticData: any = ko.observable();
  public accessTokenRegex: any = new RegExp('id_token=(.*)$', 'i');
  public errorMessageDetails: any = ko.observable('');
  public isErrorStoppingWork: any = ko.observable(false);

  public currentPageName: any = ko.computed(() => {
    const priorityView: any = _.last(this.priorityViews());
    return (priorityView && priorityView.title ? ko.utils.unwrapObservable(priorityView.title) : undefined) || this.route().title || '';
  });

  breadcrumbs(): string[] {
    return this.priorityViews().map(x => x.title || this.route().title || '');
  }
  /**
   * Reloads all data that depends on the server version.
   * This is throttled to run every 30 seconds at most, just in case multiple version checks run
   * and the version flag is not set correctly for whatever reason. (During dev, this happened and this call went crazy.)
   */

  public notifySessionExpired: any = () => {
    if (!this.hasUserBeenNotifiedSessionExpired()) {
      this.hasUserBeenNotifiedSessionExpired(true);
      this.headerViewModel().sessionHasExpired();
      document.cookie = 'ASP.NET_SessionId=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      const message = 'Your session has expired. Please refresh (F5) ASAP.';
      toastr.info(message, 'Session Expired', {
        timeOut: 0,
        extendedTimeOut: 0,
        tapToDismiss: false
      });
      this.isNavigating(false);
    }
  }

  public setReadonly: any = () => {
    this.sessionStorageService.getPartnerUsers()
      .subscribe(partnerUsers => {
        this.isReadonly(_.where(partnerUsers, { IsReadonly: true }).length > 0);
        this.headerViewModel().isReadonly(this.isReadonly());
      });
  }

  public handleLogin: any = () => {
    this.sessionStorageService.getPartners()
      .subscribe(partners => {
        this.headerViewModel().refresh();
        if (partners.length === 1) {
          this.switchPartner(partners[0].PartnerId);
          this.setReadonly();
        } else if (partners.length > 1) {
          this.setLocation('switchpartner');
        }
      });
  }

  public switchPartner(partnerId: number): JQueryPromise<any> {
    this.sessionStorageService.clearCache();
    return Utils.wrapDfd(this.sessionStorageService.getPartners())
      .then(partners => {
        if (partners) {
          const partner = _.findWhere(partners, { PartnerId: partnerId });
          this.sessionStorageService.setPartner(partner);
          return partner;
        }
        return Utils.wrapDfd(this.sessionStorageService.refreshStaticData());
      })
      .then(() => {
        this.headerViewModel().refresh();
      })
      .fail(_ => {
        toastr.error('There was a problem retrieving necessary data. Please refresh and try again.', 'Error Retrieving Data');
      })
      .always(() => {
        this.setReadonly();
        this.goToDefaultRoute();
      });
  }

  public setLocation: any = (newLocation: any) => {
    newLocation = newLocation.split('/').map((s: any) => { return encodeURIComponent(s); }).join('/');
    this.viewLocatorService.setRoute(newLocation);
  }

  public getLocation: any = () => {
    return window.location.hash.replace('#/', '');
  }

  public onUrlChanged: any = (newUrl: any) => {
    this.updatePageTitle();
    this.headerViewModel().urlChanged(newUrl);
  }

  public updatePageTitle: any = () => {
    document.title = this.partnerName() ? this.currentPageName() + ' - ' + this.partnerName() : this.currentPageName();
  }

  public emphasizeRefreshNotification: any = () => {
    const $toastContainer = $('#toast-container');
    $toastContainer.children('.toast.toast-error').animateCSS('wobble');
  }

  public onSessionExpired: any = () => {
    const loc = this.getLocation();
    if (loc !== 'login' && loc !== 'logout') {
      this.notifySessionExpired();
      this.sessionStorageService.clear();
    }
  }

  public errorOccurred: any = (exception: any) => {
    if (exception === 'ResizeObserver loop limit exceeded') {
      // bug in Grammarly chrome extension
      return;
    }
    if (exception === 'ResizeObserver loop completed with undelivered notifications.') {
      // bug in LastPass chrome extension TODO: check after 3 months(11/18/2023) to see if bug is resolved and we can remove this.
      return;
    }
    if (this.hasUserBeenNotifiedSessionExpired()) {
      return;
    }
    if (this.accessTokenRegex.exec(exception)) {
      return;
    }
    let exceptionParts;
    if (typeof (exception) === 'object') {
      exceptionParts = [];
      for (const prop in exception) {
        if (typeof (exception[prop]) === 'string') {
          exceptionParts.push(exception[prop]);
        }
      }
    } else {
      exceptionParts = exception.split('\n');
    }
    let hasUserBeenNotified = false;
    if (exceptionParts.length > 1) {
      for (const exceptionPart of exceptionParts) {
        if (exceptionPart.indexOf('Message') === 0) {
          toastr.error(exceptionPart, 'Error');
          hasUserBeenNotified = true;
          break;
        }
      }
    }
    if (!hasUserBeenNotified) {
      toastr.error(exception, 'Error');
    }
    const stackTrace = (window as any).printStackTrace({ e: exception, guess: true });
    // Simple function to make it easier to log properties. If we don't pass an array or we add everything.

    const copyObjectProperties: any = (toObject: any, fromObject: any, properties: any) => {
      for (const i in fromObject) {
        if (!properties || properties.length === 0 || _.contains(properties, i)) {
          toObject[i] = fromObject[i];
        }
      }
    };
    const loggingObject: any = {};
    loggingObject.message = exception;
    let locationString = this.getLocation();
    if (this.priorityViews() && this.priorityViews().length > 0) {
      locationString += ', priority views open (bottom of stack to top): ';
      locationString += _(this.priorityViews()).map((value) => {
        return ko.utils.unwrapObservable(value.title) + ' (' + ko.utils.unwrapObservable(value.selector) + ')';
      }).join(', ');
    }
    loggingObject.location = locationString;
    loggingObject.stackTrace = (stackTrace.length > 1) ? stackTrace.join('\n') : stackTrace;
    loggingObject.partnerId = this.partnerId();
    loggingObject.partnerName = this.partnerName();
    copyObjectProperties(loggingObject, window.navigator, ['appVersion', 'language', 'platform', 'userAgent', 'vendor']);
    launchpad.utils.log(loggingObject);
    Utils.wrapDfd(this.logService.error(exception, JSON.stringify(loggingObject)));
  }

  constructor() {
    BoundFunctionEqualityMonkeyPatch.register();
  }

  /**
   * Bootstraps the application. Things that need to be registered on startup can be put here.
   */
  public activate(
    viewLocatorService: any,
    partnerService: PartnerService,
    helpTextService: HelpTextService,
    logService: LogService,
    ticketService: TicketService,
    sessionStorageService: SessionStorageService,
    reloadService: ReloadService): any {
    this.viewLocatorService = viewLocatorService;
    this.partnerService = partnerService;
    this.helpTextService = helpTextService;
    this.logService = logService;
    this.ticketService = ticketService;
    this.sessionStorageService = sessionStorageService;
    this.reloadService = reloadService;

    PriorityViewModel.register(this, this.viewLocatorService, this.reloadService);

    // start app reload polling
    this.reloadService.start();

    this.configureApplication();

    // set default config
    this.partnerId(environment.defaultPartnerConfig.id);
    this.partnerName(environment.defaultPartnerConfig.name);
    this.largeLogoUrl(environment.defaultPartnerConfig.logoLargeS3Url);
    this.smallLogoUrl(environment.defaultPartnerConfig.logoSmallS3Url);

    const token = this.getAccessTokenFromHash();
    if (token) {
      this.sessionStorageService.setAutoLoginBearerToken(token);
    }

    // if there is a token in the hash, user is already authenticated and we can fetch the user
    // otherwise we start with a clean slate
    const appInit = token ? this.sessionStorageService.getCurrentUser() : of(null);

    appInit.pipe(
      tap((user: Boo.Objects.User) => this.handleAutoLogin(user)),
      switchMap(() => forkJoin({
        user: this.sessionStorageService.getUser(),
        partnerUser: this.sessionStorageService.getPartnerUser(),
        partners: this.sessionStorageService.getPartners()
      })))
      .subscribe(({ user, partnerUser, partners }) => {
        const location: string = this.getLocation();

        if (this.shouldRedirectToLogin(location, user, partnerUser, partners)) {
          return this.setLocation('login');
        }

        if (location.includes('resetpassword')) {
          return;
        }

        if (!partnerUser && partners && partners.length > 1) {
          return this.setLocation('switchpartner');
        }

        const accessTokenInHash = this.accessTokenRegex.test(window.location.hash);
        if (location === '' || location === 'login' || accessTokenInHash) {
          this.goToDefaultRoute();
        }

        if (this.headerViewModel().activate) {
          this.headerViewModel().activate();
        }

        this.setReadonly();
      }, (err) => {
        window.location.href = '/Error/FiveHundred.html';
      }, () => {
        this.helpTextService.initialize();
        this.viewLocatorService.activateRouting();
      });
  }

  public configureApplication() {
    // Wire up global events
    window.onerror = this.errorOccurred;
    amplify.subscribe(launchpad.config.events.errorOccurred, this.errorOccurred.bind(this));
    amplify.subscribe(launchpad.config.events.urlChanged, this.onUrlChanged.bind(this));
    amplify.subscribe(launchpad.config.events.sessionExpired, this.onSessionExpired.bind(this));
    // Override the default browser alert
    window.alert = bootbox.alert;
    // Override the toastr error to prevent invalid token errors
    const oldToastrError: any = toastr.error;
    const newToastrError = (message: any, title: any, optionsOverride: any) => {
      if (!message) {
        oldToastrError('An unhandled error occurred.', 'Error');
      } else if (message.indexOf && message.indexOf('Invalid token') > -1) {
        this.notifySessionExpired();
      } else {
        oldToastrError(message, title, optionsOverride);
      }
    };
    toastr.error = newToastrError as any;
    // Override toastr options
    toastr.options = {
      'positionClass': 'toast-bottom-left',
      'showMethod': 'slideDown'
    };
    $(window).on('keydown', (event) => {
      if (event.ctrlKey === true && event.shiftKey === true && event.which === 70) {
        $('#header-search').focus();
      }
    });
    $('.nav-link').on('click', () => {
      $('.nav a').on('click', () => { if ($('.navbar-toggle').css('display') !== 'none') { $('.navbar-toggle').trigger('click'); } });
    });
    // Don't let AJAX requests run longer than 60 seconds
    $.ajaxSetup({
      timeout: 60000,
      cache: false
    });
    bootbox.setDefaults({
      showCloseButton: false
    });
    // Fix for bootstrap tooltips, sometimes they are left in the DOM when they shouldn't be.
    $('body').on('hidden.bs.tooltip', () => {
      const tooltips = $('.tooltip').not('.in');
      if (tooltips) {
        tooltips.remove();
      }
    });
    // Load some other app-level observables
    this.headerViewModel(new HeaderViewModel(this, this.ticketService, this.sessionStorageService, this.reloadService));
  }

  public goToDefaultRoute: any = () => {
    $.when<any>(
      Utils.wrapDfd(this.sessionStorageService.getPartnerUsers()),
      Utils.wrapDfd(this.sessionStorageService.getPartner()),
      Utils.wrapDfd(this.sessionStorageService.getUser()))
      .done((partnerUsers, partner, user) => {
        let partnerUser;

        // Sales
        partnerUser = _.findWhere(partnerUsers, { UserLevelId: 4 });
        if (partnerUser) {
          this.setLocation('sales/addcustomer');
          return;
        }

        // Specialist
        partnerUser = _.findWhere(partnerUsers, { UserLevelId: 2 });
        if (partnerUser) {
          this.setLocation('specialist/specialistworkspace');
          return;
        }

        // Customer Service
        partnerUser = _.findWhere(partnerUsers, { UserLevelId: 6 });
        if (partnerUser) {
          const canViewMyTickets = window.launchpad.hasPermission(partner, partnerUsers, permissions.CanViewMyTickets, user);
          this.setLocation(canViewMyTickets ? 'customerservice/helpnextcustomer' : 'customerservice/customersearch');
          return;
        }

        // Manager
        partnerUser = _.findWhere(partnerUsers, { UserLevelId: 5 }) || _.findWhere(partnerUsers, { UserLevelId: 1 });
        if (partnerUser) {
          this.setLocation('manager/manageusers');
          return;
        }

        toastr.error('Something went wrong. Invalid Partner User.', 'Error');
      });
  }

  private shouldRedirectToLogin(location: string, user: any, partnerUser: any, partners: any): boolean {
    return !location.includes('resetpassword') && !user || (user && !partnerUser && !partners);
  }

  private getAccessTokenFromHash: any = () => {
    const matches = this.accessTokenRegex.exec(window.location.hash);
    return matches && matches.length >= 2 ? matches[1] : '';
  }

  private handleAutoLogin(user: Boo.Objects.User): void {
    if (user) {
      this.sessionStorageService.setUser(user)
      this.logService.error(`Token login used by: ${user.Username} (${user.UserId})`, '');
    }
  }
}
export default new app();

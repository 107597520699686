import { Component, OnInit } from '@angular/core';
import { PartnerPricingToolHeadingService } from '../../../services/partner-pricing-tool-heading.service';
import { PartnerPricingToolLocationService } from '../../../services/partner-pricing-tool-location.service';
import { Observable, Subject, forkJoin, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, finalize, switchMap, tap } from 'rxjs/operators';
import { PartnerPricingToolService } from '../../../services/partner-pricing-tool-service.service';
import { PartnerPricingToolMarketService } from '../../../services/partner-pricing-tool-market.service';
import { PartnerPricingToolHeadingGroupService } from '../../../services/partner-pricing-tool-heading-group.service';

@Component({
  selector: 'app-components-manager-configurepartnerpricingtool',
  templateUrl: './ConfigurePartnerPricingTool.component.html',
  styleUrls: ['./ConfigurePartnerPricingTool.component.scss']
})
export class ConfigurePartnerPricingToolComponent implements OnInit {
  isLoading = false;
  partners: Boo.Objects.PartnerMinimal[];
  headingGroups: Boo.Objects.SEOPresence.PartnerPricingToolHeadingGroup[];
  selectedHeadingGroups: Boo.Objects.SEOPresence.PartnerPricingToolHeadingGroup[] = [];
  markets: Boo.Objects.SEOPresence.PartnerPricingToolMarket[];
  selectedMarkets: Boo.Objects.SEOPresence.PartnerPricingToolMarket[] = [];
  minSearchTermLength = 3;
  headingInput$ = new Subject<string>();
  headingLoading = false;
  headings$: Observable<Boo.Objects.SEOPresence.PartnerPricingToolHeading[]> = of([]);
  selectedHeadings: Boo.Objects.SEOPresence.PartnerPricingToolHeading[] = [];
  locationInput$ = new Subject<string>();
  locationLoading = false;
  locations$: Observable<Boo.Objects.SEOPresence.PartnerPricingToolLocation[]> = of([]);
  selectedLocations: Boo.Objects.SEOPresence.PartnerPricingToolLocation[] = [];
  fourTierMarkets = [29, 30, 31]; //YP.com markets have 4 tiers instead of 3

  constructor(
    private partnerPricingToolService: PartnerPricingToolService,
    private partnerPricingToolMarketService: PartnerPricingToolMarketService,
    private partnerPricingToolHeadingGroupService: PartnerPricingToolHeadingGroupService,
    private headingService: PartnerPricingToolHeadingService,
    private locationService: PartnerPricingToolLocationService) { }

  ngOnInit(): void {
    this.isLoading = true;
    forkJoin([
      this.partnerPricingToolService.getPricingToolPartners(),
      this.partnerPricingToolMarketService.get(),
      this.partnerPricingToolHeadingGroupService.get()
    ])
    .pipe(finalize(() => this.isLoading = false))
    .subscribe(([partners, markets, headingGroups]) => {
      this.partners = partners;
      this.markets = markets;
      this.headingGroups = headingGroups;
    });

    this.locations$ = this.locationInput$.pipe(
      filter(() => this.markets.length > 0),
      distinctUntilChanged(),
      debounceTime(800),
      tap(() => this.locationLoading = true),
      switchMap(term => this.locationService.searchMarkets(term, this.selectedMarkets.map(x => x.PartnerPricingToolMarketId))
        .pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.locationLoading = false)
        ))
    );

    this.headings$ = this.headingInput$.pipe(
      filter(() => this.selectedHeadingGroups.length > 0),
      distinctUntilChanged(),
      debounceTime(800),
      tap(() => this.headingLoading = true),
      switchMap(term => this.headingService.searchGroups(term, this.selectedHeadingGroups.map(x => x.PartnerPricingToolHeadingGroupId))
        .pipe(
          catchError(() => of([])), // empty list on error
          tap((headings: Boo.Objects.SEOPresence.PartnerPricingToolHeading[]) => {
            headings.forEach((heading) => {
              heading.TierHours.sort((x, y) => x.TierId - y.TierId);
            });
            this.headingLoading = false;
          })
        ))
    );
  }

  setSearchValues(partner: Boo.Objects.Partner): void {
    this.clearHeadings();
    this.clearLocations();

    if (!partner) {
      this.selectedMarkets = [];
      this.selectedHeadingGroups = [];
      return;
    }
    
    this.partnerPricingToolService.getPricingToolInformation(partner.PartnerId)
    .subscribe(result => {
      this.selectedMarkets = this.markets.filter(x => result.PartnerMarkets.map(y => y.PartnerPricingToolMarketId).includes(x.PartnerPricingToolMarketId));
      this.selectedHeadingGroups = this.headingGroups.filter(x => result.PartnerHeadingGroups.map(y => y.PartnerPricingToolHeadingGroupId).includes(x.PartnerPricingToolHeadingGroupId));
    });
  }

  validateTierHours(tierHour: Boo.Objects.SEOPresence.PartnerPricingToolHeadingTierHour): void {
    if (tierHour.Hours < 1) {
      tierHour.Hours = 1;
    }
  }

  saveHeading(heading: Boo.Objects.SEOPresence.PartnerPricingToolHeading): void {
    this.headingService.save(heading)
      .subscribe(() => {
        toastr.success('Heading saved successfully.');
      }, err => {
        toastr.error(err);
      });
  }

  deselectHeading(heading: Boo.Objects.SEOPresence.PartnerPricingToolHeading): void {
    this.selectedHeadings = this.selectedHeadings.filter(x => x.PartnerPricingToolHeadingId !== heading.PartnerPricingToolHeadingId);
  }

  clearHeadings(): void {
    this.selectedHeadings = [];
  }

  validateLocationTier(location: Boo.Objects.SEOPresence.PartnerPricingToolLocation): void {
    if (location.TierId < 1) {
      location.TierId = 1;
    } else if (location.TierId > this.getMaxTier()) {
      location.TierId = this.getMaxTier();
    }
  }

  saveLocation(location: Boo.Objects.SEOPresence.PartnerPricingToolLocation): void {
    this.locationService.save(location)
      .subscribe(() => {
        toastr.success('Location saved successfully.');
      }, err => {
        toastr.error(err);
      });
  }

  deselectLocation(location: Boo.Objects.SEOPresence.PartnerPricingToolLocation): void {
    this.selectedLocations = this.selectedLocations.filter(x => x.PartnerPricingToolLocationId !== location.PartnerPricingToolLocationId);
  }

  clearLocations(): void {
    this.selectedLocations = [];
  }

  getRecommendedHours(heading: Boo.Objects.SEOPresence.PartnerPricingToolHeading, location: Boo.Objects.SEOPresence.PartnerPricingToolLocation): string {
    return heading.TierHours.find(x => x.TierId === location.TierId)?.Hours.toString() ?? 'N/A';
  }

  marketDisabled(market: Boo.Objects.SEOPresence.PartnerPricingToolMarket): boolean {
    if (this.fourTierMarkets.includes(market.PartnerPricingToolMarketId)) {
      return this.selectedMarkets.some(x => !this.fourTierMarkets.includes(x.PartnerPricingToolMarketId));
    } else {
      return this.selectedMarkets.some(x => this.fourTierMarkets.includes(x.PartnerPricingToolMarketId));
    }
  }

  getMaxTier(): number {
    return this.selectedMarkets.every(x => this.fourTierMarkets.includes(x.PartnerPricingToolMarketId)) ? 4 : 3;
  }
}
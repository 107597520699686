import System from "framework/System";

export default class activityLog {
  private messages: any = [];

  public record: any = (message: any) => {
    try {
      this.messages.push(message);
    } catch (innerException) {
      System.noop;
    }
  };

  public recordApiRequest: any = (request: any) => {
    this.record(`API Request: ${request.url}`);
  };

  public getRecords: any = (limit: any) => {
    if (!limit) {
      limit = 10;
    }
    if (limit > this.messages.length) {
      limit = this.messages.length;
    }
    return this.messages.slice(-limit);
  };
}

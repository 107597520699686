import { Component } from '@angular/core';
import Check from 'framework/Check';
import timer from 'app/managecustomer/Timer';
import { CustomerNoteService } from '../../../services/customer-note.service';
import IManageCustomerPublicApi = app.components.interfaces.IManageCustomerPublicApi;
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';

@Component({
	selector: 'app-components-managecustomer-floatingcustomernote',
	templateUrl: './FloatingCustomerNote.component.html'
})
export class FloatingCustomerNoteComponent implements IActivatable {
    public contactTypeId: KnockoutObservable<number>;
    public contactTypes: Boo.Objects.ContactType[];
    public customerId: number;
    public customerNotePanel: IJQueryFloatPanel;
    public manageCustomerPublicApi: IManageCustomerPublicApi;
    public noteText: KnockoutObservable<string>;

    private validation: KnockoutObservable<any>;

    constructor(
      private customerNoteService: CustomerNoteService,
      private sessionStorageService: SessionStorageService) { }

    public activate(params: any): JQueryPromise<void> {
        Check.notNegative(ko.utils.unwrapObservable(params.customerId), 'Customer Id is required.');
        this.customerId = ko.utils.unwrapObservable(params.customerId);
        this.contactTypeId = ko.observable(undefined); // must be undefined or else validation msg doesn't show sometimes
        this.manageCustomerPublicApi = params.manageCustomerPublicApi;
        this.noteText = ko.observable(undefined);
        // init jquery.floatpanel element, which is our container
        this.customerNotePanel = ($('#customer-note-float-panel') as any).floatPanel({ collapsed: true });

        this.contactTypeId.extend({
            required: true
        });

        this.noteText.extend({
            required: true,
            maxLength: 4000,
            validation: { validator: launchpad.utils.containsNoHtml, message: 'Html tags are not allowed.  You cannot have a < followed by a >' }
        });

        this.validation = ko.validatedObservable({
            contactTypeId: this.contactTypeId,
            noteText: this.noteText
        });

        return Utils.wrapDfd(this.sessionStorageService.getStaticData())
          .then((staticData: Boo.Objects.LaunchPadStaticData) => {
            this.contactTypes = _.filter(staticData.ContactTypes, (x: Boo.Objects.ContactType) => {
                return x.ContactTypeId !== launchpad.config.ContactTypeEnum.NoteRevision;
            });
        });
    }

    public save(): void {
        if (!this.validation.isValid()) {
            this.validation.errors.showAllMessages();
            return;
        }
        const note: Boo.Objects.CustomerNote = {
            CustomerId: this.customerId,
            Text: this.noteText(),
            FollowupDate: moment(moment().format('L')).toDate(),
            Duration: (timer.getElapsedSecondsSinceLastCustomerTouch()),
            ContactTypeId: this.contactTypeId()
        } as Boo.Objects.CustomerNote;
        Utils.wrapDfd(this.customerNoteService.save(note)).then(() => {
            this.contactTypeId(undefined);
            this.noteText(undefined);
            this.customerNotePanel.floatPanel('collapse');
            this.manageCustomerPublicApi.floatingNoteSaved();
        });
    }
}

import { Component, Input, OnInit } from '@angular/core';
import { CustomerAccountTypeService } from '../../../services/customer-account-type.service';
import { CustomerAccountService } from '../../../services/customer-account.service';

@Component({
  selector: 'app-components-managecustomer-readonlycustomeraccount',
  templateUrl: './ReadOnlyCustomerAccount.component.html'
})
export class ReadOnlyCustomerAccountComponent {
  @Input() public customerAccount: Boo.Objects.CustomerAccount;

  window = window;

  constructor(
    private customerAccountService: CustomerAccountService,
    private customerAccountTypeService: CustomerAccountTypeService
  ) { }

  removePassword(): void {
    this.customerAccount.Password = '';
  }

  getPassword(): void {
    if (this.customerAccount.Password) {
      return;
    }

    this.customerAccountService.selectPasswordByAccountType(this.customerAccount.CustomerId, this.customerAccount.CustomerAccountTypeId)
      .subscribe(
        (password: string) => {
          this.customerAccount.Password = password;
        });
  }

  get customerAccountTypeName(): string {
    return this.customerAccountTypeService.getName(this.customerAccount.CustomerAccountTypeId);
  }

  get customerAccountContainsData(): boolean {
    return (this.customerAccount.Url && this.customerAccount.Url.trim().length > 0) ||
      (this.customerAccount.Username && this.customerAccount.Username.trim().length > 0) ||
      (this.customerAccount.Note && this.customerAccount.Note.trim().length > 0);
  }

  get loginHref(): string {
    const needsProtocol = this.customerAccount.Url.toLowerCase().indexOf('http') !== 0 && this.customerAccount.Url.toLowerCase().indexOf('ftp') !== 0;
    return needsProtocol ? '//' + this.customerAccount.Url : this.customerAccount.Url;
  }
}

import Check from 'framework/Check';

/**
 * The grid is just a data structure. It chunks an [] into an [][]
 */
export default class Grid<T> {
    public rows: T[][];
    public bootstrapColumnClass: string;

    constructor(items: T[], numberOfColumns?: number) {
        Check.isNotEmpty(items, 'Items cannot be empty');
        const columns: number = numberOfColumns || 1;
        this.chunk(items, columns);
        this.bootstrapColumnClass = `col-lg-${Math.round(12 / columns)}`;
    }

    /**
     * Gets the T[] back from the T[][]
     */
    public collect(): T[] {
        const { flatten } = _;
        return flatten(this.rows, false);
    }

    /**
     * Turns a flat array into a collection a T[][] where number of columns is the maximum size of the sub-array (or columns). 
     * @param {T[]} items
     * @param {number} numberOfColumns
     */
    private chunk(items: T[], numberOfColumns: number): void {
        const result = [];
        while (items.length > 0) {
            result.push(items.splice(0, numberOfColumns));
        }

        this.rows = result;
    }
}

import { Component } from '@angular/core';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import TaskHelper from 'app/specialist/TaskHelper';
import { TaskService } from '../../../../services/task.service';
import { CustomerFileService } from '../../../../services/customer-file.service';
import Utils from '../../../../shared/utils';
import { AmazonService } from '../../../../services/amazon.service';
import OnsiteRecommendationConstants from '../../../../shared/onsite-recommendation-constants';

@Component({
  selector: 'app-components-specialist-actioncomponents-onsitebloggingrevision',
  templateUrl: './OnsiteBloggingRevision.component.html'
})
export class OnsiteBloggingRevisionComponent implements IActionWorkspace, IActivatable {
    public window = window;
    public launchpad = launchpad;
    public actionViewModel: KnockoutObservable<IActionWorkspace>;
    public currentAction: KnockoutObservable<Boo.Objects.Action>;
    public isLoading: KnockoutObservable<boolean>;
    public title = 'Onsite Blogging Revision';
    public instructions: string;
    public contentTitle: KnockoutObservable<string>;
    public contentTitle2: KnockoutObservable<string>;
    public content: KnockoutObservable<string>;
    public onsiteRecommendationsPageTitle: KnockoutObservable<string>;
    public onsiteRecommendationsMetaDescription: KnockoutObservable<string>;
    public completeValidation: KnockoutObservable<any>;
    public imageUrl: KnockoutObservable<string>;
    public pastImageUrls: string[];
    public wordCount: KnockoutObservable<number>;
    public notes: KnockoutObservableArray<Boo.Objects.TaskNote>;
    public files: Boo.Objects.CustomerFile[] = [];
    public researchSources: string;
    public osrMetaDescriptionCount: KnockoutComputed<string>;
    public osrTitleCount: KnockoutComputed<string>;
    public osrTitle2Count: KnockoutComputed<string>;
    public customer: Boo.Objects.Customer;
    private maxUploadSizeMB = 15;

    constructor(
      private taskService: TaskService,
      private customerFileService: CustomerFileService,
      private amazonService: AmazonService) { }

    public activate(params: IActionWorkspaceActivationParams): JQueryPromise<any> {

        params.controlViewModel(this);
        this.actionViewModel = ko.observable(null);
        this.currentAction = params.currentAction;

        if (!this.currentAction().TaskDetails.TaskPost) {
            toastr.error('No content exists for this revision.');
            return;
        }

        this.wordCount = ko.observable(0);
        this.isLoading = ko.observable(true);
        this.researchSources = this.currentAction().TaskDetails.TaskPost.Synopsis;
        this.title = this.title + (this.currentAction().Task.TaskTypeEnum === Boo.Objects.Enums.TaskTypeEnum.OnsiteBloggingRevisionStandard ? ' Standard' : ' Premium');
        this.instructions = TaskHelper.getComment(this.currentAction().Task);
        const customerRejectionNotes = this.currentAction().TaskDetails.TaskNotes
            .filter(x => x.Note.includes('Customer\'s Rejection Reason: ') && x.TaskNoteContactTypeId === Boo.Objects.Enums.TaskNoteContactTypesEnum.Reject)
            .sort((x, y) => y.InsertedDate.getTime() - x.InsertedDate.getTime());
        if (customerRejectionNotes.length > 0) {
            this.instructions += '\n' + customerRejectionNotes[0].Note;
        }
        this.contentTitle = ko.observable(this.currentAction().TaskDetails.TaskPost.ContentTitle).extend(
            {
                required: true,
                maxLength: OnsiteRecommendationConstants.H1MaxLength
            });
        this.contentTitle2 = ko.observable(this.currentAction().TaskDetails.OnsiteRecommendation.H2New || '');
        this.content = ko.observable(this.currentAction().TaskDetails.TaskPost.Content).extend(
            {
                required: true,
                validation: {
                    validator: (value: string): boolean => {
                        return this.wordCount() >= this.requiredWordCount();
                    }, message: 'Content must contain at least ' + this.requiredWordCount() + ' words.'
                }
            });

        this.imageUrl = ko.observable(this.currentAction().TaskDetails.TaskPost.ImageUrl).extend({
            required: { message: 'Image is required' },
            pattern: new launchpad.utils.validation.validUrlValidationPattern()
        });
        this.onsiteRecommendationsPageTitle = ko.observable(this.currentAction().TaskDetails.OnsiteRecommendation.TitleNew).extend(
            {
                required: true,
                maxLength: OnsiteRecommendationConstants.TitleMaxLength
            });

        this.onsiteRecommendationsMetaDescription = ko.observable(this.currentAction().TaskDetails.OnsiteRecommendation.DescriptionNew).extend(
            {
                required: true,
                maxLength: OnsiteRecommendationConstants.MetaDescriptionMaxLength
            });

        this.notes = ko.observableArray(this.currentAction().TaskDetails.TaskNotes as Boo.Objects.TaskNote[]);
        this.customer = this.currentAction().Customer;

        this.completeValidation = ko.validatedObservable([this.content, this.contentTitle, this.imageUrl, this.onsiteRecommendationsPageTitle, this.onsiteRecommendationsMetaDescription]);
        this.completeValidation.errors.showAllMessages(false);

        this.osrMetaDescriptionCount = ko.computed(() => {
            return this.onsiteRecommendationsMetaDescription().length + ' Characters';
        });

        this.osrTitleCount = ko.computed(() => {
            return this.contentTitle().length + ' Characters';
        });

        this.osrTitle2Count = ko.computed(() => {
            return this.contentTitle2().length + ' Characters';
        });

        return this.loadFiles();
    }

    public isStepReadyToDelete(): boolean {
        return false;
    }

    public isStepReadyToReject(): boolean {
        return false;
    }

    public isStepReadyToSave(): boolean {
        this.currentAction().TaskDetails.TaskPost.Content = this.content();
        this.currentAction().TaskDetails.TaskPost.ContentTitle = this.contentTitle();
        this.currentAction().TaskDetails.TaskPost.ImageUrl = this.imageUrl();
        this.currentAction().TaskDetails.OnsiteRecommendation.DescriptionNew = this.onsiteRecommendationsMetaDescription();
        this.currentAction().TaskDetails.OnsiteRecommendation.TitleNew = this.onsiteRecommendationsPageTitle();
        this.currentAction().TaskDetails.OnsiteRecommendation.H1New = this.contentTitle();
        this.currentAction().TaskDetails.OnsiteRecommendation.H2New = this.contentTitle2();

        return true;
    }

    public isStepReadyToComplete(): boolean {
        if (!this.completeValidation.isValid()) {
            this.completeValidation.errors.showAllMessages();
            return false;
        }

        this.currentAction().TaskDetails.TaskPost.Content = this.content();
        this.currentAction().TaskDetails.TaskPost.ContentTitle = this.contentTitle();
        this.currentAction().TaskDetails.TaskPost.ImageUrl = this.imageUrl();
        this.currentAction().TaskDetails.OnsiteRecommendation.DescriptionNew = this.onsiteRecommendationsMetaDescription();
        this.currentAction().TaskDetails.OnsiteRecommendation.TitleNew = this.onsiteRecommendationsPageTitle();
        this.currentAction().TaskDetails.OnsiteRecommendation.H1New = this.contentTitle();
        this.currentAction().TaskDetails.OnsiteRecommendation.H2New = this.contentTitle2();
        return true;
    }

    public loadFiles(): JQueryPromise<void> {
        return $.when<any>(Utils.wrapDfd(this.customerFileService.getMany(this.customer.CustomerId, Boo.Objects.Enums.FileSectionTypesEnum.Marketing)),
                           Utils.wrapDfd(this.taskService.getOnsiteTaskDefinitionHistory(this.customer.CustomerId)))
            .then((files: Boo.Objects.CustomerFile[], history: Boo.Objects.OnsiteBloggingTaskHistory[]) => {
                this.files = files;
                const eightMonthsAgo = new Date();
                eightMonthsAgo.setMonth(eightMonthsAgo.getMonth() - 8);
                this.pastImageUrls = history.filter((item) => {
                    return eightMonthsAgo < item.TaskDate;
                })
                    .map(item => item.ImageUrl).filter(item => item);
            })
            .fail((displayMessage: string) => {
                toastr.error(displayMessage);
            })
            .always(() => {
                this.isLoading(false);
            });
    }

    public removeImage(): void {
        this.imageUrl('');
    }

    public uploadImage(): void {
        const request = {
            BucketName: launchpad.config.S3Buckets.queuedForResize,
            Folder: 'tickets/customers/' + this.customer.CustomerId,
            UseUniqueFileName: true,
            ContentSize: 0,
            ContentType: '',
            Filename: ''
        };
        PriorityViewModel.show('app-components-shared-uploadfile', { preSignedURLRequest: request, maxUploadInMB: this.maxUploadSizeMB })
            .then((uploadResult: any) => {
                // we are using the queuedForResize bucket to upload the image, but we want to show the image in the s3staticfiles bucket
                const imgUrl = uploadResult.FullUrl.replace(launchpad.config.S3Buckets.queuedForResize, launchpad.config.S3Buckets.s3staticfiles);
                uploadResult.FullUrl = imgUrl;
                this.isLoading(true);
                return Utils.wrapDfd(this.amazonService.checkIfFileExists(uploadResult.FullUrl));
            })
            .then((data) => {
                if (data) {
                    this.imageUrl(data);
                } else {
                    toastr.error('Image upload is taking longer than expected');
                }
            })
            .fail((error) => toastr.error('Could not upload image'))
            .always(() => this.isLoading(false));
    }

    private requiredWordCount(): number {
        if (this.currentAction().Task.LanguageId === Boo.Objects.Enums.LanguageEnum.Finnish
            && this.currentAction().Task.TaskTypeEnum === Boo.Objects.Enums.TaskTypeEnum.OnsiteBloggingRevisionStandard) {
            return 400;
        }

        if (this.currentAction().Task.LanguageId === Boo.Objects.Enums.LanguageEnum.Finnish
            && this.currentAction().Task.TaskTypeEnum === Boo.Objects.Enums.TaskTypeEnum.OnsiteBloggingRevisionPremium) {
            return 550;
        }

        if (this.currentAction().Task.TaskTypeEnum === Boo.Objects.Enums.TaskTypeEnum.OnsiteBloggingRevisionPremium) {
            return 800;
        }
        return 600;
    }
}

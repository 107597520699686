import { Component } from '@angular/core';
import IPriorityViewPublicApi = app.interfaces.IPriorityViewPublicApi;

type OnsiteRecommendationInput = Boo.Tasks.Inputs.Models.OnsiteRecommendationInput;
type OnsiteRecommendationReviewOutput = Boo.Tasks.Outputs.Models.OnsiteRecommendationReviewOutput;

@Component({
  selector: 'app-components-onsite-recommendations-feedback',
  templateUrl: './onsite-recommendations-feedback.component.html'
})
export class OnsiteRecommendationsFeedbackComponent implements IActivatable {
  feedback: string;
  input: OnsiteRecommendationInput;
  output: OnsiteRecommendationReviewOutput;
  
  editorToolbar: any = {
    toolbar: [
      {
        name: 'document',
        items: ['Source', 'Maximize']
      },
      {
        name: 'editing',
        items: ['SelectAll']
      },
      {
        name: 'clipboard',
        items: ['Copy']
      }
    ],
    readOnly: true,
    allowedContent: true
  };
  
  private priorityViewApi: IPriorityViewPublicApi;
  
  canActivate(user: Boo.Objects.User, partner: Boo.Objects.Partner, params: any): boolean {
    this.priorityViewApi = params.priorityViewApi;
    this.input = params.history.Input as OnsiteRecommendationInput;
    this.output = params.history.Output as OnsiteRecommendationReviewOutput;
    this.feedback = params.feedback;
    return true;
  }

  close() {
    this.priorityViewApi.resolve();
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import ODataQuery from 'framework/data/ODataQuery';

@Injectable({
  providedIn: 'root'
})
export class CustomerNoteService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/customernotes`;

  constructor(private http: HttpClient) { }

  get(customerId: number): ODataQuery<framework.data.IPageResult<Boo.Objects.CustomerNote>> {
    const request = {
      url: this.baseUrl,
      parameters: [{ name: 'customerId', value: customerId.toString() }]
    };

    return new ODataQuery<framework.data.IPageResult<Boo.Objects.CustomerNote>>(this.http, request);
  }

  getTicketNotes(ticketId: number): Observable<Boo.Objects.CustomerNote[]> {
    const params = new HttpParams().set('ticketId', ticketId);

    return this.http.get<Boo.Objects.CustomerNote[]>(`${this.baseUrl}/TicketNotes`, { params: params });
  }

  getSalesNotes(customerId: number): Observable<Boo.Objects.CustomerNote[]> {
    const params = new HttpParams().set('customerId', customerId);

    return this.http.get<Boo.Objects.CustomerNote[]>(`${this.baseUrl}/SalesNotes`, { params: params });
  }

  getLatestKeywordResearchNote(customerId: number): Observable<Boo.Objects.CustomerNote[]> {
    const params = new HttpParams().set('customerId', customerId);

    return this.http.get<Boo.Objects.CustomerNote[]>(`${this.baseUrl}/LatestKeywordResearchNote`, { params: params });
  }

  save(customerNote: Boo.Objects.CustomerNote): Observable<void> {
    return this.http.post<void>(this.baseUrl, customerNote);
  }

  revise(customerNote: Boo.Objects.CustomerNote): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/Revise`, customerNote);
  }
}

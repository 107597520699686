import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class LinkLocationExclusionService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/linklocationexclusions`;

  constructor(private http: HttpClient) { }

  getAllByLinkTypeId(linkTypeId: number): Observable<Boo.Objects.LinkLocationExclusion> {
    const params = new HttpParams().set('linkTypeId', linkTypeId);
    return this.http.get<Boo.Objects.LinkLocationExclusion>(`${this.baseUrl}/GetAllByLinkTypeId`, { params: params });
  }
}

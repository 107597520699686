import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PartnerPricingToolHeadingGroupService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/partnernpricingtoolheadinggroups`;

  constructor(private http: HttpClient) { }

  get(): Observable<Boo.Objects.SEOPresence.PartnerPricingToolHeadingGroup[]> {
    return this.http.get<Boo.Objects.SEOPresence.PartnerPricingToolHeadingGroup[]>(`${this.baseUrl}/`);
  }
}
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class StoreService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/store`;
  constructor(private http: HttpClient) { }

  getOrdersSnapshot(customerId: number): Observable<Boo.ApisExternal.Store.Objects.Order[]> {
    return this.http.get<Boo.ApisExternal.Store.Objects.Order[]>(`${this.baseUrl}/orders/snapshot/${customerId}`);
  }

  getSubscriptionsSnapshot(customerId: number): Observable<Boo.ApisExternal.Store.Objects.Subscription> {
    return this.http.get<Boo.ApisExternal.Store.Objects.Subscription>(`${this.baseUrl}/subscriptions/snapshot/${customerId}`);
  }

  sso(customerId: number, redirect: string = null, returnPath?: string): Observable<string> {
    const params = new HttpParams()
      .set('customerId', customerId)
      .set('redirect', redirect)
      .set('returnPath', returnPath);
    
    return this.http.get<string>(`${this.baseUrl}/sso/sales`, { params: params });
  }

  ssoStorefronts(redirect: string = null): Observable<string> {
    const params = new HttpParams().set('redirect', redirect);
    
    return this.http.get<string>(`${this.baseUrl}/sso/storefronts`, { params: params });
  }

  getSeoMinutePriceLevels(partnerId: number): Observable<Boo.ApisExternal.Store.Objects.SubscriptionPriceLevel[]> {
    const params = new HttpParams().set('partnerId', partnerId);
    
    return this.http.get<Boo.ApisExternal.Store.Objects.SubscriptionPriceLevel[]>(`${this.baseUrl}/products/GetSeoMinutePriceLevels`, { params: params });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class InternalTaskService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/internaltasks`;

  constructor(private http: HttpClient) { }


  getForUser(): Observable<Boo.Objects.InternalTaskDetails[]> {
    return this.http.get<Boo.Objects.InternalTaskDetails[]>(`${this.baseUrl}/GetForUser`);
  }

  getAllLocked(): Observable<Boo.Objects.InternalTaskDetails[]> {
    return this.http.get<Boo.Objects.InternalTaskDetails[]>(`${this.baseUrl}/GetAllLocked`);
  }

  getStats(): Observable<Boo.Objects.InternalTaskStats> {
    return this.http.get<Boo.Objects.InternalTaskStats>(`${this.baseUrl}/Stats`);
  }

  getWorkflowHistoryByLinkLocationId(linkLocationId: number): Observable<Boo.Objects.InternalTaskHistoryDetail[]> {
    const params = new HttpParams().set('linkLocationId', linkLocationId);

    return this.http.get<Boo.Objects.InternalTaskHistoryDetail[]>(`${this.baseUrl}/GetWorkflowHistoryByLinkLocationId`, { params: params });
  }

  getWorkflowHistoryByInternalTaskId(internalTaskId: number): Observable<Boo.Objects.InternalTaskHistoryDetail[]> {
    const params = new HttpParams().set('internalTaskId', internalTaskId);

    return this.http.get<Boo.Objects.InternalTaskHistoryDetail[]>(`${this.baseUrl}/GetWorkflowHistoryByInternalTaskId`, { params: params });
  }
}

import { Component, Input, OnInit } from '@angular/core';
import System from 'framework/System';
import Check from 'framework/Check';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import Utils from '../../../shared/utils';
import { ActionService } from '../../../services/action.service';

@Component({
  selector: 'app-components-managecustomer-completedtasks',
  templateUrl: './CompletedTasks.component.html'
})
export class CompletedTasksComponent implements OnInit {
  @Input() customer: Boo.Objects.Customer;

  completedTasks: Boo.Objects.ActionSummary[] = [];
  endDate: KnockoutObservable<string>;
  startDate: KnockoutObservable<string>;
  isLoading = false;
  showAllTasks = false;

  constructor(private actionService: ActionService) { }

  ngOnInit() {
    Check.isNotEmpty(this.customer, 'Customer is missing');

    this.startDate = ko.observable(moment().subtract(1, 'months').format('L'));
    this.endDate = ko.observable(moment(this.startDate()).add(1, 'months').format('L'));
  }

  toggleReportingVisible(task: Boo.Objects.ActionSummary): void {
    this.isLoading = true;
    const message = `Task will be ${task.ReportingVisible ? 'hidden' : 'visible'} in the reporting dashboard`;

    task.ReportingVisible = !task.ReportingVisible;
    Utils.wrapDfd(this.actionService.toggleReportingVisibility(task.TaskId, task.ReportingVisible))
      .then(() => {
        toastr.success(message);
        this.loadCompletedTasks();
      });
  }

  showTaskDetails(task: Boo.Objects.ActionSummary): void {
    PriorityViewModel.show(
      'app-components-managecustomer-taskdetails',
      {
        taskId: task.TaskId
      }
    );
  }

  loadCompletedTasks(): JQueryPromise<void> {
    if (this.startDate() > this.endDate()) {
      toastr.error('Start date cannot be after the end date.');
    } else {
      return Utils.wrapDfd(this.actionService.getCompletedByCustomerAndDate(this.customer.CustomerId, moment(this.startDate()).format('L'), moment(this.endDate()).format('L'), this.showAllTasks))
        .then((actionSummaries: Boo.Objects.ActionSummary[]) => {
          if (actionSummaries) {
            _.each(actionSummaries, (item: Boo.Objects.ActionSummary) => {
              delete item.CompletedBy;
            });
          }
          this.completedTasks = actionSummaries;
          this.isLoading = false;
        }).fail((displayMessage: string) => {
          toastr.error(displayMessage);
        });
    }
  }
}

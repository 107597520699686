import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import CustomValidators from '../../../../shared/custom-validators';
import { CustomerCampaignService } from '../../../../services/customer-campaign.service';

@Component({
  selector: 'app-components-shared-add-page',
  templateUrl: './add-page.component.html'
})
export class AddPageComponent implements OnInit {
  @Input() customer: Boo.Objects.Customer;
  @Input() websiteUrls: Boo.Objects.WebsiteUrl[];
  @Input() archivedWebsiteUrls: Boo.Objects.ArchivedWebsiteUrl[];
  @Input() restrictions: Boo.Objects.CustomerCampaign.Enums.RestrictionTypes[];

  @ViewChild('pageInput') pageInput: ElementRef;

  pageControl: UntypedFormControl;
  addingPage = false;
  addingLivePage = false;

  customerCampaignRestrictionTypes_ReadOnly = Boo.Objects.CustomerCampaign.Enums.RestrictionTypes.ReadOnly;

  constructor(private customerCampaignService: CustomerCampaignService) { }

  ngOnInit(): void {
    this.setupForm();
  }

  toggleAddingPage(isLive: boolean): void {
    this.addingPage = !this.addingPage;
    this.addingLivePage = isLive;

    if (this.addingPage) {
      this.focus();
    } else {
      this.pageControl.reset('');
    }
  }

  setupForm(): void {
    this.pageControl = new UntypedFormControl('', [
      CustomValidators.validUrl(this.customer.Url),
      CustomValidators.stringsNotAllowedMethod(this.getUnavailableUrls.bind(this), true, 'This URL is already in use.'),
      CustomValidators.domainMustMatchPrefix(this.customer.Url, this.customer.Url, `Domain must match the customer's domain.`),
      CustomValidators.maxLengthPrefix(this.customer.Url, 512)
    ]);
  }

  getUnavailableUrls(): string[] {
    return this.websiteUrls ? this.websiteUrls.filter(websiteUrl => this.isActiveStatus(websiteUrl)).map(websiteUrl => websiteUrl.Url.replace(this.customer.Url, '')) : [];
  }

  addPage(): void {
    this.pageControl.markAsDirty();
    this.pageControl.updateValueAndValidity();

    if (!this.pageControl.valid) {
      toastr.error("Please correct errors before adding a page.");
      return;
    }

    const url = this.customer.Url.trim() + this.pageControl.value.trim();
    const existingWebsiteUrl = this.websiteUrls.find(x => x.Url.toLowerCase().trim() === url.toLowerCase());
    if (existingWebsiteUrl) {
      existingWebsiteUrl.StatusId = Boo.Objects.Enums.WebsiteUrlStatuses.Limited;
      existingWebsiteUrl.IsModified = true;
    } else {
      this.websiteUrls.push({
        CustomerId: this.customer.CustomerId,
        Description: '',
        IsHomePage: false,
        KeywordPhrases: '',
        Keywords: [],
        WebsiteUrlTask: null,
        StatusId: this.addingLivePage ? Boo.Objects.Enums.WebsiteUrlStatuses.Limited : Boo.Objects.Enums.WebsiteUrlStatuses.New,
        Url: url,
        WebsiteUrlId: 0,
        IsModified: true
      } as Boo.Objects.WebsiteUrl);
    }

    const archivedWebsiteUrl = this.archivedWebsiteUrls.find(x => x.Url.toLowerCase().trim() === url.toLowerCase());
    if (archivedWebsiteUrl) {
      this.archivedWebsiteUrls.splice(this.archivedWebsiteUrls.indexOf(archivedWebsiteUrl), 1);
    }

    this.toggleAddingPage(true);
  }

  private focus()
  {
    setTimeout(() => {
      if (this.pageInput) {
        this.pageInput.nativeElement.focus();
      }
    }, 0);
  }

  private isActiveStatus(websiteUrl: Boo.Objects.WebsiteUrl): boolean {
    return this.customerCampaignService.isActiveStatus(websiteUrl);
  }
}
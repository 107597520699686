import { Component } from '@angular/core';
import CsvConversionService from 'app/services/CsvConversionService';
import TranslatedTableConfig, { ITranslatedTable, ITranslatedTableConfig } from 'app/admin/config/TranslatedTableConfig';
import Check from 'framework/Check';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-admin-translations',
  templateUrl: './Translations.component.html'
})
export class TranslationsComponent implements IActivatable {
    public isLoading: KnockoutObservable<boolean> = ko.observable(false);
    public languages: Boo.Objects.Language[] = null;
    public translatedTables: ITranslatedTable[];
    public file: KnockoutObservable<File>;
    public filename: KnockoutObservable<string> = ko.observable(null);
    public content: KnockoutObservable<string> = ko.observable('');
    public csvRows: KnockoutObservable<number> = ko.observable(0);
    public validation: KnockoutObservable<any> = ko.validatedObservable<any>();
    public importData: KnockoutObservable<any> = ko.observable(null);
    public importDataHeader: KnockoutObservable<any[]> = ko.observable(null);
    public exportSelectedTranslatedTableId: KnockoutObservable<number> = ko.observable(null);
    public importSelectedTranslatedTable: KnockoutObservable<ITranslatedTable> = ko.observable(null);
    public exportSelectedLanguageId: KnockoutObservable<number> = ko.observable(launchpad.config.LanguageIdEnum.English).extend({
        required: { message: 'Selection is required' }
    });
    public importSelectedLanguage: KnockoutObservable<Boo.Objects.Language> = ko.observable(null);
    public exportValidation: KnockoutObservable<any> = ko.validatedObservable([this.exportSelectedLanguageId]);
    public displayData: KnockoutComputed<any> = ko.computed(() => {
        const data = ko.utils.unwrapObservable(this.importData);
        if (data && _.isArray(data) && data.length >= 1) {
            if (data.length > 100) {
                return _.take(data, 50).concat(_.last(data, 50)); // At most we want to preview 100 rows
            } else {
                return data;
            }
        } else {
            return null;
        } 
    });

    constructor(
      private translatedTableConfig: TranslatedTableConfig,
      private sessionStorageService: SessionStorageService) { }

    public activate(): JQueryPromise<any> {
        this.file = ko.observable(null);
        this.content.subscribe((content: string) => {
            if ($.trim(content) !== '') {
                this.handleFileSelected(content);
            }
        });
        this.exportValidation.errors.showAllMessages(false);
        return Utils.wrapDfd(this.sessionStorageService.getStaticData())
            .then((staticData: any) => {
                Check.isNotEmpty(staticData, 'StaticData is empty.');
                Check.hasSequence(staticData.Languages, 'Languages are null or empty.');
                this.translatedTables = this.translatedTableConfig.getExportableTables();
                this.languages = staticData.Languages;
            }).fail((displayMessage: string) => {
                toastr.error(displayMessage);
            });
    }

    public canActivate(user: Boo.Objects.User, partner: Boo.Objects.Partner, params: any): boolean {
        return _.any(user.PartnerUsers, (partnerUser: Boo.Objects.PartnerUser) => {
            return partnerUser.UserLevelId === launchpad.config.keys.adminUserLevelId;
        });
    }

    public exportTranslations(): void {
        if (!this.exportValidation.isValid()) {
            this.exportValidation.errors.showAllMessages(true);
            toastr.error('Please correct the errors on the page');
            return;
        }

        const translatedTableConfig = this.translatedTableConfig.getConfig(this.exportSelectedTranslatedTableId());
        if (translatedTableConfig) {
            this.isLoading(true);
            Utils.wrapDfd(translatedTableConfig.service.getMany(this.exportSelectedLanguageId(), true))
              .then((data: any[]) => {
                const csvData = new CsvConversionService().convert(translatedTableConfig.columnData, data);
                const language = _.find(this.languages, (language: Boo.Objects.Language) => {
                    return language.LanguageId === this.exportSelectedLanguageId();
                });
                const a = window.document.createElement('a');
                // For Microsoft Excel to open the csv correctly with utf-8 encoding we need to add a BOM (Byte Order Mark) to the blob.  
                // This solution was found at this link https://github.com/eligrey/FileSaver.js/issues/28
                a.href = window.URL.createObjectURL(new Blob(['\uFEFF' + csvData], { type: 'text/csv;charset=utf-8' }));
                a.setAttribute('download', `${translatedTableConfig.Name.replace(/ /g, '_')}_${language.Name.replace(/ /g, '_')}_${moment().format('L')}.csv`);

                // Append anchor to body.
                document.body.appendChild(a);
                a.click();

                // Remove anchor from body
                document.body.removeChild(a);

            }).fail((displayMessage: string) => {
                toastr.error(displayMessage);
            }).always(() => {
                this.isLoading(false);
            });
        } else {
            toastr.info('Nothing to export');
        }
    }

    public importTranslations(): void {
        const translatedTableConfig = this.translatedTableConfig.getConfig(this.importSelectedTranslatedTable().translatedTableType);
        Check.isNotNull(translatedTableConfig, 'Could not find table config');

        this.isLoading(true);

        const data = this.listToObjects(this.importData(), translatedTableConfig, this.importSelectedLanguage().LanguageId);
        Utils.wrapDfd(translatedTableConfig.service.saveMany(data))
            .then(() => {
                toastr.success('Successfully saved translations');
                this.importData([]);
                this.content('');
                this.filename('');
                this.importSelectedLanguage(null);
                this.importSelectedTranslatedTable(null);
            }).fail((displayMessage: string) => {
                toastr.error(displayMessage);
            }).always(() => {
                this.isLoading(false);
            });
    }

    private handleFileSelected(content: string): void {
        Check.isNotEmpty(this.filename(), 'Filename cannot be empty');
        const detectedLanguage = _.find(this.languages, (language: Boo.Objects.Language) => {
            return this.filename().indexOf(language.Name.replace(/ /g, '_')) >= 0;
        });
        Check.isNotEmpty(detectedLanguage, 'Language could not be derived from filename');
        this.importSelectedLanguage(detectedLanguage);
        const translatedTable = _.find(this.translatedTables, (translatedTableConfig: ITranslatedTable) => {
            return this.filename().indexOf(translatedTableConfig.name.replace(/ /g, '_')) >= 0;
        });
        this.importSelectedTranslatedTable(translatedTable);
        Check.isNotEmpty(translatedTable, 'Table name could not be derived from filename');

        const jscharDetResult = jschardet.detect(content);
        if (jscharDetResult.confidence < .90) {
            toastr.error('Could not determine character encoding.');
            return;
        }

        // Most times the encoding will be windows-1252 because the files are edited/saved in Microsoft Excel.  
        // JscharDet will also determine if the encoding is utf- 8 which will also work correctly
        if (jscharDetResult.encoding === 'utf-8' || jscharDetResult.encoding === 'windows-1252') {
           this.parseCsv(content, jscharDetResult.encoding);
        } else {
           toastr.error(`Illegal character encoding. The file you tried to upload uses ${jscharDetResult.encoding} encoding.  utf-8 or windows-1252 encoding is required`);
           return;
        }
    }

    private listToObjects(list: any[], translatedTableConfig: ITranslatedTableConfig, languageId: number): any[] {
        const result: any[] = [];
        _.each(list, (row: any[]) => {
            const translation: any = { 'LanguageId': languageId };
            for (let x = 0; x < row.length; x++) {
                translation[translatedTableConfig.columnData[x].Value] = row[x];
            }
            result.push(translation);
        });
        return result;
    }

    private parseCsv(content: string, encoding: string): void {
        const parsedCsv: PapaParse.ParseResult = Papa.parse(content, { skipEmptyLines: true, encoding: encoding });
        this.csvRows(parsedCsv.data.length);

        try {
            Check.isNotNull(parsedCsv, 'Cannot read CSV file');
            const translatedTableConfig = this.translatedTableConfig.getConfig(this.importSelectedTranslatedTable().translatedTableType);

            Check.isNotNull(translatedTableConfig, 'Could not find table config');
            const csvColumns = _.map(translatedTableConfig.columnData, (column: app.interfaces.ICsvColumn) => {
                return column.Name;
            });
            Check.isCsvFormatValid(csvColumns, parsedCsv);

            this.importDataHeader(_.first(parsedCsv.data));
            this.importData(_.without(parsedCsv.data, _.first(parsedCsv.data)));
        } catch (e) {
            toastr.error(e.message);
        }
    }
}


import { Directive, Input, ElementRef, OnChanges, AfterViewInit, OnDestroy } from '@angular/core';
import System from 'framework/System';

@Directive({
  selector: '[dateTimePicker]'
})
export class DateTimePickerDirective implements AfterViewInit, OnChanges, OnDestroy {
  @Input('dateTimePicker') public Settings: { date: KnockoutObservable<moment.Moment>, displayAsUtc?: boolean };
  @Input() public readonly: boolean;

  private $element: JQuery;
  constructor(elementRef: ElementRef) {
    this.$element = $(elementRef.nativeElement);
  }

  private initialized = false;
  public ngAfterViewInit(): void {
    if (!this.$element.is('input:text')) {
      throw 'The dateTimePicker binding can only be applied to text inputs.';
    }

    const $inputGroupWrapper = $('<div />');
    $inputGroupWrapper.addClass('input-group date');
    this.$element.addClass('form-control');
    this.$element.wrap($inputGroupWrapper);

    $('<span />').addClass('input-group-text').append($('<i/>').addClass('fas fa-calendar-alt')).insertAfter(this.$element);

    this.$element.parent().datetimepicker({
      ignoreReadonly: true,
      format: 'MM/DD/YYYY',
      extraFormats: [],
      widgetPositioning: {
        horizontal: 'auto',
        vertical: 'bottom'
      },
      icons: {
        time: 'far fa-clock',
        date: 'fas fa-calendar-alt',
        up: 'fas fa-arrow-up',
        down: 'fas fa-arrow-down',
        previous: 'fas fa-arrow-left',
        next: 'fas fa-arrow-right'
      }
    });

    this.$element.parent().on('dp.change', (e: any) => {
      this.Settings.date(moment(e.date));
    });

    let date = moment(ko.utils.unwrapObservable(this.Settings.date));
    if (this.Settings.displayAsUtc) {
      date = moment(date.utc().format('L'));
    }

    this.$element.parent().data('DateTimePicker').date(date);
    this.initialized = true;
  }

  private dateSubscription: any;
  public ngOnChanges(): void {
    if (this.readonly === false) { // undefined defaults to readonly
      this.$element.removeAttr('readonly');
    }
    else {
      this.$element.attr('readonly', 'readonly');
    }

    if (this.Settings.date && typeof this.Settings.date() === 'string') {
      this.Settings.date(moment(this.Settings.date()));
    }

    if (this.dateSubscription) {
      this.dateSubscription.dispose();
    }
    if (this.Settings.date) {
      this.dateSubscription = this.Settings.date.subscribe(() => {
        if (this.Settings.date && typeof this.Settings.date() === 'string') {
          this.Settings.date(moment(this.Settings.date()));
        }

        if (this.initialized) {
          this.$element.parent().data('DateTimePicker').date(this.Settings.date());
        }
      });
    }

    if (!this.initialized) {
      return;
    }
    this.$element.parent().data('DateTimePicker').date(this.Settings.date());
  }

  public ngOnDestroy(): void {
    if (this.dateSubscription) {
      this.dateSubscription.dispose();
    }

    try {
      this.$element.datetimepicker('destroy');
    }
    catch { System.noop; }
  }

  public clear(): void {
    this.$element.parent().data('DateTimePicker').clear();
    this.Settings.date(null);
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LaunchpadThemeService {

  currentYear: number = new Date().getFullYear();

  // javascript month indexes are 0-11
  themeSchedules: ThemeSchedule[] = [
    {theme: themes.halloween, startDate: new Date(this.currentYear, 9, 1), endDate: new Date(this.currentYear, 10, 1)},
    {theme: themes.valentines, startDate: new Date(this.currentYear, 1, 1), endDate: new Date(this.currentYear, 1, 15)},
    {theme: themes.stpatricks, startDate: new Date(this.currentYear, 2, 1), endDate: new Date(this.currentYear, 2, 18)},
    {theme: themes.fall, startDate: new Date(this.currentYear, 8, 22), endDate: new Date(this.currentYear, 11, 1)},
    {theme: themes.winter, startDate: new Date(this.currentYear, 11, 1), endDate: new Date(this.currentYear + 1, 0, 1)}
  ];

  getNextButtonStyles: Record<number, string> = {
    [themes.regular]: 'action-button',
    [themes.halloween]: 'action-button-halloween',
    [themes.fall]: 'action-button-fall',
    [themes.winter]: 'action-button-winter progress-bar-striped',
    [themes.valentines]: 'action-button-valentines',
    [themes.stpatricks]: 'action-button-stpatricks'
  };

  helpNextButtonStyles: Record<number, string> = {
    [themes.regular]: 'customer-button',
    [themes.halloween]: 'customer-button-halloween',
    [themes.fall]: 'customer-button-fall',
    [themes.winter]: 'customer-button-winter progress-bar-striped',
    [themes.valentines]: 'customer-button-valentines',
    [themes.stpatricks]: 'customer-button-stpatricks'
  };

  specialistIcons: Record<number, string> = {
    [themes.halloween]: 'fas fa-ghost',
    [themes.fall]: 'fas fa-leaf',
    [themes.winter]: 'fas fa-gift',
    [themes.valentines]: 'fas fa-heart',
    [themes.stpatricks]: 'fas fa-rainbow'
  };

  helpNextIcons1: Record<number, string> = {
    [themes.halloween]: 'fas fa-cat',
    [themes.fall]: 'fas fa-tree',
    [themes.winter]: 'fas fa-snowman',
    [themes.valentines]: 'fas fa-heart',
    [themes.stpatricks]: 'fas fa-rainbow'
  };

  helpNextIcons2: Record<number, string> = {
    [themes.halloween]: 'fas fa-cloud-moon',
    [themes.fall]: 'fas fa-leaf',
    [themes.winter]: 'fas fa-snowflake',
    [themes.valentines]: 'fas fa-heart',
    [themes.stpatricks]: 'fas fa-rainbow'
  };

  websiteBuildsIcons: Record<number, string> = {
    [themes.halloween]: 'fas fa-ghost',
    [themes.fall]: 'fas fa-leaf',
    [themes.winter]: 'fas fa-candy-cane',
    [themes.valentines]: 'fas fa-heart',
    [themes.stpatricks]: 'fas fa-rainbow'
  };

  easterEggIcons: Record<number, string> = {
    [themes.valentines]: 'fa fa-heart-broken'
  };

  easterEggEnabled = false;

  // todo: create service service
  // private baseUrl: string = `${environment.urlConfig.serviceUrl}/theme`;

  constructor() {
    this.easterEggEnabled = this.isEasterEggEnabled();
  }

  getTheme(): themes {
    const currentDate = new Date();
    const schedule: ThemeSchedule = this.themeSchedules.find(x => x.startDate <= currentDate && x.endDate > currentDate);
    return schedule ? schedule.theme : themes.regular;
  }

  getHelpNextIcon1(): string {
    return this.easterEggEnabled && this.easterEggIcons[this.getTheme()] ? this.easterEggIcons[this.getTheme()] : this.helpNextIcons1[this.getTheme()];
  }

  getHelpNextIcon2(): string {
    return this.easterEggEnabled && this.easterEggIcons[this.getTheme()] ? this.easterEggIcons[this.getTheme()] : this.helpNextIcons2[this.getTheme()];
  }

  getSpecialistIcon(): string {
    return this.easterEggEnabled && this.easterEggIcons[this.getTheme()] ? this.easterEggIcons[this.getTheme()] : this.specialistIcons[this.getTheme()];
  }

  getWebsiteBuildsIcon(): string {
    return this.easterEggEnabled && this.easterEggIcons[this.getTheme()] ? this.easterEggIcons[this.getTheme()] : this.websiteBuildsIcons[this.getTheme()];
  }

  toggleEasterEgg(): void {
    this.easterEggEnabled = !this.easterEggEnabled;
    localStorage.setItem('easterEgg', this.easterEggEnabled.toString());
  }

  isEasterEggEnabled(): boolean {
    this.easterEggEnabled = localStorage.getItem('easterEgg') === 'true';
    return this.easterEggEnabled;
  }
}

export enum themes {
  regular = 1,
  halloween = 2,
  fall = 3,
  winter = 4,
  valentines = 5,
  stpatricks = 6
}

interface ThemeSchedule {
  theme: themes;
  startDate: Date;
  endDate: Date;
}

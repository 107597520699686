import { Component } from '@angular/core';
import SeoContentSurvey from 'app/models/typescript/SeoContentSurvey';
import { SeoContentSurveyService } from '../../../services/seo-content-survey.service';
import Utils from '../../../shared/utils';
import permissions from 'app/models/Permissions';
import { TabPanelDisplayMode } from 'app/components/enums/TabPanelDisplayMode';
import { PreAuditDetailService } from '../../../services/pre-audit-detail.service';
import { SessionStorageService } from '../../../services/session-storage.service';

@Component({
  selector: 'app-components-managecustomer-seocontentsurvey',
  templateUrl: './SeoContentSurvey.component.html'
})
export class SeoContentSurveyComponent implements IActivatable {
    canClose: KnockoutObservable<boolean> = ko.observable(false);
    canEditBlogCredentials: KnockoutObservable<boolean> = ko.observable(false);
    canEditSeoContentSurvey: KnockoutObservable<boolean> = ko.observable(false);
    customer: KnockoutObservable<CustomerObservable> = ko.observable(null);
    seoContentSurvey: KnockoutObservable<SeoContentSurveyObservable>;
    contentManagementSystem: Boo.Objects.ContentManagementSystem;
    showBloggingInformation = true;
    priorityViewApi: app.interfaces.IPriorityViewPublicApi;
    partner: Boo.Objects.Partner;
    tabPanelDisplayMode = TabPanelDisplayMode;

    constructor(
      private seoContentSurveyService: SeoContentSurveyService,
      private preAuditDetailService: PreAuditDetailService,
      private sessionStorageService: SessionStorageService) { }

    activate(params: any): JQueryPromise<void> {
        if (params && params.showBloggingInformation === false) {
            this.showBloggingInformation = false;
        }

        this.customer(params.customer);
        this.priorityViewApi = params.priorityViewApi;

        this.canClose(params.priorityViewApi);
        return $.when<any>(
            Utils.wrapDfd(this.sessionStorageService.getPartner()),
            Utils.wrapDfd(this.sessionStorageService.getPartnerUsers()),
            Utils.wrapDfd(this.sessionStorageService.getUser()),
            Utils.wrapDfd(this.sessionStorageService.getStaticData()),
            Utils.wrapDfd(this.seoContentSurveyService.getByCustomerId(this.customer().CustomerId())),
            Utils.wrapDfd(this.preAuditDetailService.getByCustomerId(this.customer().CustomerId())))
          .then((
              partner: Boo.Objects.Partner,
              partnerUsers: Boo.Objects.PartnerUser[],
              user: Boo.Objects.User,
              staticData: Boo.Objects.LaunchPadStaticData,
              seoContentSurvey: Boo.Objects.SeoContentSurvey,
              preAuditDetail: Boo.Objects.PreAuditDetail
          ) => {
              this.canEditSeoContentSurvey(_.any(user.PartnerUsers, (item: Boo.Objects.PartnerUser) => {
                  return item.UserLevelId === launchpad.config.keys.adminUserLevelId || // Admin
                      item.UserLevelId === launchpad.config.keys.managerUserLevelId || // Manager
                      item.UserLevelId === launchpad.config.keys.customerServiceUserLevelId; // Customer Service
              }));

              this.canEditBlogCredentials(launchpad.hasPermission(partner, partnerUsers, permissions.CanEditOnsiteBloggingCredentials));

              this.seoContentSurvey = ko.observable(ko.mapping.fromJS(seoContentSurvey || new SeoContentSurvey()));
              this.contentManagementSystem = preAuditDetail ? staticData.ContentManagementSystems.find(x => x.ContentManagementSystemId == preAuditDetail.ContentManagementSystemId) : null;
              this.partner = partner;
          });
    }
}

import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationModalComponent } from '../../shared/notification-modal/notification-modal.component';
import { CustomerService } from '../../../services/customer.service';
import { finalize } from 'rxjs/operators';
import { Notification } from '../../../shared/models/notifications/notifications';

@Component({
  selector: 'app-components-managecustomer-customer-notifications',
  templateUrl: './customer-notifications.component.html'
})
export class CustomerNotificationsComponent implements OnInit {
  @Input() customerId: number;
  notifications: Notification[] = [];
  isLoading = false;

  constructor(private modalService: NgbModal,
    private customerService: CustomerService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.customerService.getCustomerNotifications(this.customerId)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(
        (notifications: Notification[]) => this.notifications = notifications,
        err => toastr.error(err)
      );
  }

  displayNotification(notification: Notification) {
    const modalRef = this.modalService.open(NotificationModalComponent, { size: 'lg' });
    modalRef.componentInstance.notification = notification;
  }
}

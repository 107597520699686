export default class System {    
    /**
     * No operation
     */
    public static noop: void;

    /**
     * Short cut to create a promise from an object
     * @param {T} item
     * @returns
     */
    public static resolvedPromise<T>(item: T): JQueryPromise<T> {
        return $.when(item);
    }

    public static resolvePromiseAfter<T>(milliseconds: number, item: T): JQueryPromise<T> {
        return $.Deferred((dfd: JQueryDeferred<T>) => {
            setTimeout(
                () => {
                    dfd.resolve(item);
                },
                milliseconds);
        }).promise();
    }

    /**
     * Returns a void promise
     * @returns
     */
    public static emptyPromise(): JQueryPromise<void> {
        return System.resolvedPromise(System.noop);
    }

    /**
     * Rejects a promise
     * @returns
     */
    public static autoRejectedPromise(): JQueryPromise<any>
    public static autoRejectedPromise(message: string): JQueryPromise<any>
    public static autoRejectedPromise(message?: string): JQueryPromise<any> {
        return $.Deferred((dfd: JQueryDeferred<any>) => {
            dfd.reject(message || 'Cannot activate');
        }).promise();
    }
}

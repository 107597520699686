class Timer {
  // / <summary>This is a static view model that stores the last save time and time on the record.
  // / The start time can be reset by calling start().
  // / </summary>        
  private startTime = new Date();
  private lastCustomerTouchTime = new Date();
  private lastTicketSaveTime = new Date();
  private lastUiActivityTime = new Date();
  private customerInactiveMilliseconds = 0;
  private ticketInactiveMilliseconds = 0;
  private totalInactiveMilliseconds = 0;

  public start: any = () => {
    this.startTime = new Date();
    this.lastCustomerTouchTime = new Date();
    this.lastTicketSaveTime = new Date();
    this.lastUiActivityTime = new Date();
    this.customerInactiveMilliseconds = 0;
    this.ticketInactiveMilliseconds = 0;
    this.totalInactiveMilliseconds = 0;
  };

  public getElapsedActiveSecondsSinceStart: any = () => {
    const startTimeWithOffset = moment(this.startTime).add(this.totalInactiveMilliseconds, 'milliseconds');
    const result = moment().diff(startTimeWithOffset, 'seconds');
    return result;
  };

  public getElapsedSecondsSinceLastCustomerTouch: any = () => {
    const lastCustomerTouchTimeWithOffset = moment(this.lastCustomerTouchTime).add(this.customerInactiveMilliseconds, 'milliseconds');
    const result = moment().diff(lastCustomerTouchTimeWithOffset, 'seconds');
    return result;
  };

  public getElapsedHoursSinceLastTicketSaveTime: any = () => {
    return moment().diff(moment(this.lastTicketSaveTime).add(this.ticketInactiveMilliseconds, 'milliseconds'), 'hours');
  };

  public getElapsedSecondsSinceLastTicketSaveTime: any = () => {
    const lastTicketSaveTimeWithOffset = moment(this.lastTicketSaveTime).add(this.ticketInactiveMilliseconds, 'milliseconds');
    const result = moment().diff(lastTicketSaveTimeWithOffset, 'seconds');
    return result;
  };

  public getFormattedTimeSinceLastTicketSaveTime: any = () => {
    return this.getFormattedTime(this.getElapsedSecondsSinceLastTicketSaveTime());
  };

  public getFormattedTimeSinceStart: any = () => {
    return this.getFormattedTime(this.getElapsedActiveSecondsSinceStart());
  };

  public getElapsedSecondsSinceLastUIActivity: any = () => {
    return moment().diff(this.lastUiActivityTime as any, 'seconds');
  };

  public getElapsedMinutesSinceLastUIActivity: any = () => {
    return moment().diff(this.lastUiActivityTime as any, 'minutes');
  };

  private getFormattedTime: any = (secs: any) => {
    const hours = Math.floor(secs / (60 * 60));
    const divisor_for_minutes = secs % (60 * 60);
    const minutes = Math.floor(divisor_for_minutes / 60);
    const divisor_for_seconds = divisor_for_minutes % 60;
    const seconds = Math.ceil(divisor_for_seconds);
    return ((hours < 10) ? '0' : '') + hours + ':' + ((minutes < 10) ? '0' : '') + minutes + ':' + ((seconds < 10) ? '0' : '') + seconds;
  };

  public resetCustomerTouchTime: any = () => {
    this.resetUIActivityTime();
    this.lastCustomerTouchTime = new Date();
    this.customerInactiveMilliseconds = 0;
  };

  public resetTicketSaveTime: any = () => {
    this.resetUIActivityTime();
    this.lastTicketSaveTime = new Date();
    this.ticketInactiveMilliseconds = 0;
  };

  public resetUIActivityTime: any = () => {
    this.lastUiActivityTime = new Date();
  };

  public addInactiveMilliseconds: any = (milliseconds: any) => {
    this.ticketInactiveMilliseconds += milliseconds;
    this.customerInactiveMilliseconds += milliseconds;
    this.totalInactiveMilliseconds += milliseconds;
  };

  public browserNotification: any = () => {
    if (Notification.permission !== 'granted') {
      Notification.requestPermission();
    } else {
      const title = 'Launchpad Notification';
      const options = {
        icon: 'favicon.ico',
        body: 'You have been inactive for over 60 minutes!'
      };
      const notification = new Notification(title, options);
      notification.onclick = function () {
        parent.focus();
        window.focus();
        this.close();
      };
    }
  };
}

export default new Timer();
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TaskPostService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/taskposts`;

  constructor(private http: HttpClient) { }

  get(taskPostId: number): Observable<Boo.Objects.TaskPost> {
    const params = new HttpParams().set('taskPostId', taskPostId);

    return this.http.get<Boo.Objects.TaskPost>(`${this.baseUrl}/Get`, { params: params });
  }

  getLastOnsiteBloggingPublishDate(customerId: number): Observable<Date> {
    const params = new HttpParams().set('customerId', customerId);

    return this.http.get<Date>(`${this.baseUrl}/GetLastOnsiteBloggingPublishDate`, { params: params });
  }

  deleteAndRepublishPost(taskActionUrl: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/DeleteAndRepublishPost`, { taskActionUrl: taskActionUrl });
  }

  getForCustomer(customerId: number, taskTypes: Boo.Objects.Enums.TaskTypeEnum[]): Observable<Boo.Objects.TaskPost[]> {  
    return this.http.post<Boo.Objects.TaskPost[]>(`${this.baseUrl}/GetForCustomer`, {
        customerId: customerId,
        taskTypes: taskTypes
    });
  }

  updateTaskPostContent(taskPostId: number, content: string): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/UpdateTaskPostContent`, {
        taskPostId: taskPostId,
        content: content
    });
  }

  getCustomerLink(taskPostId: number): Observable<string> {
    const params = new HttpParams().set('taskId', taskPostId);

    return this.http.get<string>(`${this.baseUrl}/CustomerLink`, { params: params });
  }

  save(taskPost: Boo.Objects.TaskPost, savingTaskId: number): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/Save`, {
        taskPost: taskPost,
        savingTaskId: savingTaskId
    });
  }
}

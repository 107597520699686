import { Component, AfterViewInit, OnInit } from '@angular/core';
import System from 'framework/System';
import TodoList = app.tsmodels.interfaces.TodoList;
import Todo = Boo.Objects.Todo.Todo;
import TodoValidation = app.tsmodels.interfaces.TodoValidation;
import IValidatedResult = app.interfaces.IValidatedResult;
import IValidationPublicApi = app.interfaces.IValidationPublicApi;
import TodoStatus = Boo.Objects.Todo.Enums.TodoStatus;
import TodoFactory from '../../../factories/TodoFactory';
import { UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-components-todo-createtodolist',
  templateUrl: './CreateTodoList.component.html'
})
export class CreateTodoListComponent implements AfterViewInit, OnInit, IActivatable {
  window = window;
  launchpad = launchpad;
  selectedTodo: Todo;
  todoList: TodoList;
  todos: TodoValidation[];
  todos2: TodoValidation[];
  todoModalId: string;
  validationApi: IValidationPublicApi;
  userId: number;

  private todoFactory: TodoFactory = new TodoFactory();
  private errorMessages: string[] = [];

  ngOnInit(): void {
    this.todos = this.todoList.Todos as TodoValidation[];

    this.todoModalId = _.uniqueId('createTodoModal_');
    this.selectedTodo = {} as Todo;


    this.todos.forEach(todo => {
      this.attachValidationRules(todo);
    });

    if (!this.todos.filter(todo => todo.Status === TodoStatus.New).length) {
      this.todoList.Todos.push(this.getNewTodo());
    }

    if (this.validationApi) {
      this.validationApi.add(
        {
          isValid: this.isValid.bind(this),
          validate: this.validate.bind(this)
        },
        this.todoModalId);
    }
  }

  canActivate(user: Boo.Objects.User, partner: Boo.Objects.Partner, params: ITodoListCreateActivationParams): boolean {
    this.todoList = params.todoList;
    this.validationApi = params.validationApi;
    this.userId = params.userId;

    return true;
  }

  ngAfterViewInit(): void {
    window.scrollTo(0, 0);
  }

  attachValidationRules(todo: TodoValidation): void {
    todo.ContentCtrl = new UntypedFormControl(todo.Content, [Validators.maxLength(400)]);
    todo.ContentCtrl.markAsTouched();
    todo.ContentCtrl.valueChanges.subscribe(value => {
      todo.Content = value;
    });
  }

  isValid(): boolean {
    let isValid = true;
    this.todos.forEach(todo => {
      if (!todo.ContentCtrl.valid) {
        isValid = false;
        this.errorMessages.push(todo.ContentCtrl.errors.toString());
      }
    });

    return isValid;
  }

  validate(): JQueryPromise<IValidatedResult> {
    this.todos = this.todos.filter(todo => todo.Content.trim() !== '');
    return System.resolvedPromise<IValidatedResult>({ isValid: this.isValid(), errorMessages: this.errorMessages });
  }

  onEnter(index: any, event: any, context: TodoValidation): void {
    if (context.ContentCtrl.valid) {
      this.todoList.Todos.splice(index + 1, 0, this.getNewTodo());
    }
  }

  add(): void {
    this.todoList.Todos.push(this.getNewTodo());
  }

  delete(index: any): void {
    this.todoList.Todos.splice(index, 1);
    this.handleEmptyList();
  }

  openTodo(todo: Todo): void {
    this.selectedTodo = todo;
    $('#' + this.todoModalId).modal({
      backdrop: 'static',
      keyboard: false,
      show: true
    });
  }

  private handleEmptyList(): void {
    if (!this.todoList.Todos.some(todo => todo.Status !== Boo.Objects.Todo.Enums.TodoStatus.Complete)) {
      this.todoList.Todos.push(this.getNewTodo());
    }
  }

  private getNewTodo(): TodoValidation {
    const newTodo = this.todoFactory.create(this.todoList.TodoListId, this.userId) as TodoValidation;
    this.attachValidationRules(newTodo);
    return newTodo;
  }
}

export interface ITodoListCreateActivationParams {
  todoList: TodoList;
  userId: number;
  validationApi?: IValidationPublicApi;
}

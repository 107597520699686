import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { LocalProfileBusinessHourFactory } from './local-profile-business-hour.factory';
import { LocalProfileSpecialHourFactory } from './local-profile-special-hour.factory';
import { skip } from 'rxjs/operators';
import Languages = Boo.Objects.Enums.LanguageEnum;

@Injectable({
  providedIn: 'root'
})
export class LocalProfileFactory {

  constructor(
    private formBuilder: UntypedFormBuilder,
    private localProfileBusinessHourFactory: LocalProfileBusinessHourFactory,
    private localProfileSpecialHourFactory: LocalProfileSpecialHourFactory) { }

  create(
    customer: Boo.Objects.Customer,
    businessCitationProviderId: Boo.BusinessCitations.Models.Enums.Providers,
    defaultCanSubscribeToTrue: boolean): Boo.Objects.LocalProfiles.LocalProfile {
    return {
      AcceptsAmericanExpress: false,
      AcceptsCash: false,
      AcceptsCheck: false,
      AcceptsDinersClub: false,
      AcceptsDiscover: false,
      AcceptsFinancing: false,
      AcceptsGoogleCheckout: false,
      AcceptsInvoice: false,
      AcceptsMasterCard: false,
      AcceptsPaypal: false,
      AcceptsTravelersCheck: false,
      AcceptsVisa: false,
      AdditionalHoursInformation: '',
      AlternatePhone: '',
      Associations: '',
      BingListingClaimedByStatusId: 0,
      Brands: [],
      BusinessCitationProviderId: businessCitationProviderId,
      BusinessHours: [],
      CanSubscribe: defaultCanSubscribeToTrue ? true : null,
      Categories: [],
      City: '',
      CompanyName: '',
      ContactName: '',
      CountryId: customer.CountryId,
      CustomerId: customer.CustomerId,
      Description: '',
      DisplayName: '',
      Email: '',
      FacebookHandle: '',
      Fax: '',
      FeaturedMessage: this.getFeaturedMessage(customer.LanguageId),
      FoursquareListingClaimedByStatusId: 0,
      GoogleCoverPhoto: '',
      GoogleListingClaimedByStatusId: 1,
      GooglePreferredPhoto: '',
      GoogleProfilePhoto: '',
      GoogleProfileUrl: '',
      GoogleRegistrationPhoneNumber: '',
      Identifier: null,
      ImageUrls: '',
      InsertedDate: null,
      InstagramHandle: '',
      IsActive: true,
      IsBingOptimized: false,
      IsBingPinVerified: false,
      IsByAppointmentOnly: false,
      IsClosed: false,
      IsEdited: false,
      IsFoursquareOptimized: false,
      IsFoursquarePinVerified: false,
      IsGoogleOptimized: false,
      IsGooglePinVerified: false,
      IsListingOptimized: false,
      IsOpen247: false,
      IsStreetAddressHidden: false,
      IsYahooOptimized: false,
      IsYahooPinVerified: false,
      Latitude: 0,
      LinkedInHandle: '',
      LocalProfileId: 0,
      LogoUrl: '',
      Longitude: 0,
      Notes: '',
      OpeningDate: null,
      Phone: '',
      PinterestHandle: '',
      PreviousCategory1: '',
      PreviousCategory2: '',
      PreviousCategory3: '',
      PreviousCategory4: '',
      PreviousCategory5: '',
      Products: '',
      RegistrationEmail: '',
      RegistrationPassword: '',
      ServiceAreas: [],
      Services: '',
      ShowOperatingHours: false,
      SpecialHours: [],
      Specialties: '',
      State: '',
      StateId: null,
      StreetAddress: '',
      StreetAddress2: '',
      TwitterHandle: '',
      VerifyAddressMatchesWithGoogle: false,
      VideoUrls: '',
      Website: '',
      YahooListingClaimedByStatusId: 0,
      YouTubeHandle: '',
      Zip: '',
      IsComplete: false,
      SocialProfiles: [],
      PaymentOptions: [],
      Languages: []
    };
  }

  createNewFormGroup(
    customer: Boo.Objects.Customer,
    businessCitationProviderId: Boo.BusinessCitations.Models.Enums.Providers,
    profileData: Boo.BusinessCitations.Models.BusinessCitationProviderProfileData,
    fullLocalProfile: boolean,
    defaultCanSubscribeToTrue: boolean): UntypedFormGroup {
    return fullLocalProfile
      ? this.createFullLocalProfileFormGroup(this.create(customer, businessCitationProviderId, defaultCanSubscribeToTrue), profileData)
      : this.createMinimumLocalProfileFormGroup(this.create(customer, businessCitationProviderId, defaultCanSubscribeToTrue));
  }

  createFullLocalProfileFormGroup(
    localProfile: Boo.Objects.LocalProfiles.LocalProfile,
    profileData: Boo.BusinessCitations.Models.BusinessCitationProviderProfileData): UntypedFormGroup {
    const formGroup = this.formBuilder.group({
      additionalHoursInformation: [localProfile.AdditionalHoursInformation, null],
      alternatePhone: [localProfile.AlternatePhone, null],
      associations: [localProfile.Associations, null],
      businessCitationProviderId: [localProfile.BusinessCitationProviderId, null],
      brands: [localProfile.Brands, null],
      canSubscribe: [localProfile.CanSubscribe, null],
      categories: [localProfile.Categories.flatMap(x => x.BusinessCitationProviderCategoryId), null],
      city: [localProfile.City, null],
      companyName: [localProfile.CompanyName, null],
      contactName: [localProfile.ContactName, null],
      countryId: [localProfile.CountryId, null],
      description: [localProfile.Description, null],
      email: [localProfile.Email, null],
      fax: [localProfile.Fax, null],
      featuredMessage: [localProfile.FeaturedMessage, null],
      googleCoverPhoto: [localProfile.GoogleCoverPhoto, null],
      googleListingClaimedByStatusId: [localProfile.GoogleListingClaimedByStatusId, null],
      googlePreferredPhoto: [localProfile.GooglePreferredPhoto, null],
      googleProfilePhoto: [localProfile.GoogleProfilePhoto, null],
      googleProfileUrl: [localProfile.GoogleProfileUrl, null],
      googleRegistrationPhoneNumber: [localProfile.GoogleRegistrationPhoneNumber, null],
      identifier: [localProfile.Identifier, null],
      imageUrls: [localProfile.ImageUrls, null],
      isActive: [localProfile.IsActive, null],
      isComplete: [localProfile.IsComplete, null],
      isByAppointmentOnly: [localProfile.IsByAppointmentOnly, null],
      isClosed: [localProfile.IsClosed, null],
      isOpen247: [localProfile.IsOpen247, null],
      isStreetAddressHidden: [localProfile.IsStreetAddressHidden, null],
      languages: [localProfile.Languages, null],
      localProfileId: [localProfile.LocalProfileId, null],
      logoUrl: [localProfile.LogoUrl, null],
      notes: [localProfile.Notes, null],
      openingDate: [localProfile.OpeningDate, null],
      paymentOptions: [localProfile.PaymentOptions, null],
      phone: [localProfile.Phone, null],
      previousCategory1: [localProfile.PreviousCategory1, null],
      previousCategory2: [localProfile.PreviousCategory2, null],
      previousCategory3: [localProfile.PreviousCategory3, null],
      previousCategory4: [localProfile.PreviousCategory4, null],
      previousCategory5: [localProfile.PreviousCategory5, null],
      products: [localProfile.Products, null],
      registrationEmail: [localProfile.RegistrationEmail, null],
      registrationPassword: [localProfile.RegistrationPassword, null],
      serviceAreas: [localProfile.ServiceAreas.sort((a, b) => (a.Description > b.Description) ? 1 : (a.Description < b.Description) ? -1 : 0), null],
      services: [localProfile.Services, null],
      showOperatingHours: [localProfile.ShowOperatingHours, null],
      specialties: [localProfile.Specialties, null],
      stateId: [localProfile.StateId, null],
      streetAddress: [localProfile.StreetAddress, null],
      streetAddress2: [localProfile.StreetAddress2, null],
      verifyAddressMatchesWithGoogle: [localProfile.VerifyAddressMatchesWithGoogle, null],
      videoUrls: [localProfile.VideoUrls, null],
      website: [localProfile.Website, null],
      zip: [localProfile.Zip, null],
    });

    formGroup.addControl('businessHours', this.localProfileBusinessHourFactory.createFormArray(this.localProfileBusinessHourFactory.createMissingAndSort(localProfile)));
    formGroup.addControl('specialHours', this.localProfileSpecialHourFactory.createFormArray(localProfile.SpecialHours || []));
    formGroup.addControl('socialProfiles', this.createSocialProfileFormArray(localProfile, profileData.SocialProfileTypes));

    return formGroup;
  }

  createSaveableLocalProfiles(
    customer: Boo.Objects.Customer,
    businessCitationProviderId: number,
    categories: Boo.BusinessCitations.Models.BusinessCitationProviderCategory[],
    existingLocalProfiles: Boo.Objects.LocalProfiles.LocalProfile[],
    localProfileForms: UntypedFormArray,
    requireFullLocalProfileOnAdd: boolean): Boo.Objects.LocalProfiles.LocalProfile[] {
    const localprofiles: Boo.Objects.LocalProfiles.LocalProfile[] = [];

    this.formBuilder.array(localProfileForms.controls.filter(x => !x.pristine))
      .getRawValue()
      .forEach(x => {
        let localProfile = existingLocalProfiles.find(y => y.LocalProfileId === x.localProfileId);
        if (!localProfile) {
          localProfile = this.create(customer, businessCitationProviderId, false);
        }

        // Mimimum Local Profile Fields
        localProfile.CanSubscribe = x.canSubscribe;
        localProfile.CountryId = x.countryId ? x.countryId : customer.CountryId;
        localProfile.IsActive = x.isActive;
        localProfile.IsClosed = x.isClosed;
        localProfile.ServiceAreas = x.serviceAreas;
        localProfile.StreetAddress = x.streetAddress;
        localProfile.StreetAddress2 = x.streetAddress2;
        localProfile.Zip = x.zip;

        // If we are only showing the minimum local profile, we don't want to override the existing local profile with controls that don't exist in the form
        if (!requireFullLocalProfileOnAdd && (!localProfile.LocalProfileId || localProfile.LocalProfileId === 0)) {
          localprofiles.push(localProfile);
          return;
        }

        localProfile.PaymentOptions = x.paymentOptions;
        localProfile.Languages = x.languages;
        localProfile.SocialProfiles = x.socialProfiles.filter(x => x.url !== '').map(s => { return { Name: s.name, Url: s.url }; });

        localProfile.AdditionalHoursInformation = x.additionalHoursInformation;
        localProfile.AlternatePhone = x.alternatePhone;
        localProfile.Associations = x.associations;
        localProfile.Brands = x.brands;
        localProfile.BusinessCitationProviderId = x.businessCitationProviderId;
        localProfile.BusinessHours = x.businessHours ? x.businessHours : localProfile.BusinessHours;;

        if (x.categories) {
          localProfile.Categories = categories.filter(c => x.categories.indexOf(c.BusinessCitationProviderCategoryId) !== -1);
        }

        localProfile.City = x.city;
        localProfile.CompanyName = x.companyName;
        localProfile.ContactName = x.contactName;
        localProfile.Description = x.description;
        localProfile.Email = x.email;
        localProfile.Fax = x.fax;
        localProfile.FeaturedMessage = x.featuredMessage;
        localProfile.GoogleCoverPhoto = x.googleCoverPhoto;
        localProfile.GoogleListingClaimedByStatusId = x.googleListingClaimedByStatusId;
        localProfile.GooglePreferredPhoto = x.googlePreferredPhoto;
        localProfile.GoogleProfilePhoto = x.googleProfilePhoto;
        localProfile.GoogleProfileUrl = x.googleProfileUrl;
        localProfile.GoogleRegistrationPhoneNumber = x.googleRegistrationPhoneNumber;
        localProfile.ImageUrls = x.imageUrls;
        localProfile.IsByAppointmentOnly = x.isByAppointmentOnly;
        localProfile.IsOpen247 = x.isOpen247;
        localProfile.IsStreetAddressHidden = x.isStreetAddressHidden;
        localProfile.LogoUrl = x.logoUrl;
        localProfile.Notes = x.notes;
        localProfile.OpeningDate = x.openingDate;
        localProfile.Phone = x.phone;
        localProfile.Products = x.products;
        localProfile.RegistrationEmail = x.RegistrationEmail;
        localProfile.RegistrationPassword = x.RegistrationPassword;
        localProfile.Services = x.services;
        localProfile.ShowOperatingHours = x.showOperatingHours;
        localProfile.SpecialHours = x.specialHours ? x.specialHours : localProfile.SpecialHours;
        localProfile.Specialties = x.specialties;
        localProfile.StateId = x.stateId;
        localProfile.VerifyAddressMatchesWithGoogle = x.verifyAddressMatchesWithGoogle;
        localProfile.VideoUrls = x.videoUrls;
        localProfile.Website = x.website;
        localprofiles.push(localProfile);
      });

    return localprofiles;
  }

  private createMinimumLocalProfileFormGroup(localProfile: Boo.Objects.LocalProfiles.LocalProfile): UntypedFormGroup {
    const formGroup = this.formBuilder.group({
      businessCitationProviderId: [localProfile.BusinessCitationProviderId, null],
      canSubscribe: [localProfile.CanSubscribe, null],
      isActive: [localProfile.IsActive, null],
      isClosed: [localProfile.IsClosed, null],
      isOpen247: [localProfile.IsOpen247, null],
      isComplete: [localProfile.IsComplete, null],
      isStreetAddressHidden: [localProfile.IsStreetAddressHidden, null],
      localProfileId: [localProfile.LocalProfileId, null],
      streetAddress: [localProfile.StreetAddress, null],
      streetAddress2: [localProfile.StreetAddress2, null],
      serviceAreas: [localProfile.ServiceAreas.sort((a, b) => (a.Description > b.Description) ? 1 : (a.Description < b.Description) ? -1 : 0), null],
      zip: [localProfile.Zip, null]
    });

    return formGroup;
  }

  private createSocialProfileFormArray(
    localProfile: Boo.Objects.LocalProfiles.LocalProfile,
    socialProfileTypes: Boo.BusinessCitations.Models.BusinessCitationProviderSocialProfileType[]): UntypedFormArray {
    return this.formBuilder.array(
      socialProfileTypes.map(s => {
        return this.formBuilder.group(
          {
            name: s.Name,
            baseUrl: s.BaseUrl,
            prependBaseUrl: s.PrependBaseUrl,
            accountPrefix: s.AccountPrefix,
            url: localProfile.SocialProfiles.find(x => x.Name === s.Name)?.Url ?? '',
            example: this.getExampleSocialProfileUrl(s)
          }
        );
      }));
  }

  private getExampleSocialProfileUrl(type: Boo.BusinessCitations.Models.BusinessCitationProviderSocialProfileType): string {
    if (type.AccountPrefix) {
      return `example: ${type.AccountPrefix}CompanyProfile`;
    }
    else if (type.PrependBaseUrl) {
      return `example: ${type.BaseUrl}CompanyProfile`;
    }
    else {
      return `example: https://${type.BaseUrl}CompanyProfile`;
    }
  }

  private getFeaturedMessage(language: Languages): string {
    switch (language) {
      case Languages.Spanish:
        return '¡Contáctanos hoy!';
      case Languages.German:
        return 'Kontaktieren Sie uns noch heute!';
      case Languages.French:
        return 'Contactez-nous dès aujourd\'hui!';
      case Languages.FrenchCA:
        return 'Contactez-nous dès aujourd\'hui !';
      case Languages.Dutch:
        return 'Neem vandaag nog contact met ons op!';
      case Languages.Finnish:
        return 'Ota meihin yhteyttä jo tänään!';
      default:
        return 'Contact Us Today!';
    }
  }
}

import { Component, Input, ViewChildren, QueryList, OnInit, AfterViewInit } from '@angular/core';
import QaDisplayConfigFactory from 'app/specialist/qa/factories/qa-display-config-factory';
import TaskTypeEnum = Boo.Objects.Enums.TaskTypeEnum;
import QaCriteria = Boo.Qa.QaCriteria;
import QaCriteriaGroup = Boo.Qa.QaCriteriaGroup;
import QaCriteriaDetail = Boo.Qa.QaCriteriaDetail;
import QaDisplayConfig = app.specialist.qa.interfaces.QaDisplayConfig;
import { QaCriteriaControlComponent } from '../qa-criteria-control/qa-criteria-control.component';

@Component({ template: '' })
export class QaGradingAreaComponent implements OnInit, AfterViewInit {
	@Input() taskType: TaskTypeEnum;
	@Input() qaCriteriaGroups: QaCriteriaGroup[];
	@Input() initialCriteria: QaCriteria[];
	@Input() initialGradeComment: string;
	@ViewChildren('criteriaControl')
	public criteriaControls: QueryList<QaCriteriaControlComponent>;
	public displayConfigs: QaDisplayConfig[];
	public gradeComment = '';

	ngOnInit(): void {
		//Remove configs with no groups
		this.displayConfigs = QaDisplayConfigFactory.create(this.taskType, this.qaCriteriaGroups);
		if (this.initialGradeComment) {
			this.gradeComment = this.initialGradeComment;
		}
	}

	ngAfterViewInit(): void {
		if (this.initialCriteria) {
			this.setCriteria();
		}
	}

	validate(): boolean {
		return this.criteriaControls.toArray().map(x => x.validate()).every(x => x);
	}

	getDetails(): QaCriteriaDetail[] {
		return this.criteriaControls.toArray().map(x => x.createDetail()).filter(x => Boolean(x));
	}

	setCriteria(): void {
		this.initialCriteria.forEach(c => {
			this.criteriaControls.find(x => x.groups.includes(c.QaCriteriaDefinition.QaCriteriaGroupEnum)).loadCriteria(c);
		});
	}
}
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class PartnerTaskTypeExclusionService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/partnertasktypeeclusions`;

  constructor(private http: HttpClient) { }

  public get(partnerId: number): Observable<Boo.Objects.PartnerTaskTypeExclusion[]> {
    return this.http.get<Boo.Objects.PartnerTaskTypeExclusion[]>(`${this.baseUrl}/${partnerId}`);
  }

  public save(partnerId: number, taskTypeIds: number[]): Observable<any> {
    return this.http.post(`${this.baseUrl}/save`, {
        partnerId,
        taskTypeIds
    });
  }
}

import { Component, Input, OnInit } from '@angular/core';
import Utils from '../../../shared/utils';
import Constants from '../../../shared/constants';
import { GoogleAnalyticsService } from '../../../services/google-analytics.service';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'app-components-managecustomer-seocustomeraccounts',
    templateUrl: './seo-customer-accounts.component.html'
})
export class SeoCustomerAccountComponent implements IActivatable, OnInit { 
    public customer: CustomerObservable;
    public hasGoogleAnalyticsAccess: boolean;
    public isLoading: boolean;
    public customerId: number;
    public customerAccountTypes: number[];
    public showSaveButton: boolean;
    public publicApiCallback: any;
    //Required for communication between components, unfortunately
    public isEnabled: KnockoutObservable<boolean>;
    
    constructor (
      private googleAnalyticsService: GoogleAnalyticsService,
      private userService: UserService) {}

    ngOnInit(): void {
        Utils.wrapDfd(this.googleAnalyticsService.checkAuthorization(this.customerId))
            .then((hasAccess) => {
                this.hasGoogleAnalyticsAccess = hasAccess;
                this.isLoading = false;
            });
    }
    
    canActivate(_user: Boo.Objects.User, _partner: Boo.Objects.Partner, params: ISeoCustomerAccountParams): boolean {
        this.isLoading = true;
        this.customer = params.customer;
        this.customerId = params.customerId;
        this.customerAccountTypes = params.customerAccountTypes;
        this.showSaveButton = params.showSaveButton;
        this.isEnabled = params.isEnabled;
        this.publicApiCallback = params.publicApiCallback;
        return true;
    }

    /**
     * Triggers the oauth grant process for google analytics.
     */
    grantGoogleAnalyticsAccess(): JQueryPromise<void> {
        const user = _.first(this.customer.Users());
        return Utils.wrapDfd(this.userService.impersonate(user.CustomerUserId()))
            .then((token: string) => {
                window.open(`${this.customer.Partner.ReportingUrl()}/?t=${token}&path=${Constants.MyMarketInfoProfilePath}`, '_blank');

                const msg = 'Hey! Did you complete Google Authorization? Hit OK to refresh';
                bootbox.confirm(msg, (result: boolean) => {
                    if (result) {
                        Utils.wrapDfd(this.googleAnalyticsService.checkAuthorization(this.customer.CustomerId()))
                            .then((hasAccess: boolean) => {
                                this.hasGoogleAnalyticsAccess = hasAccess;
                            });
                    }
                });
            })
            .fail((error: string) => {
                toastr.error(error, 'User Impersonation Failed');
            });
    }

    /**
     * Revokes analytics access for the customer.
     */
    revokeAnalyticsAccess(): void {
        bootbox.confirm('Are you sure you want to revoke Google Analytics Access?', (result: boolean) => {
            if (result) {
                Utils.wrapDfd(this.googleAnalyticsService.revokeAccess(this.customer.CustomerId()))
                    .then(() => {
                        return Utils.wrapDfd(this.googleAnalyticsService.checkAuthorization(this.customer.CustomerId()))
                    })
                    .then((hasAccess: boolean) => {
                        this.hasGoogleAnalyticsAccess = hasAccess;
                    });
            }
        });
    }
}

interface ISeoCustomerAccountParams {
  customer: CustomerObservable;
  customerId: number;
  customerAccountTypes: number[];
  showSaveButton: boolean;
  publicApiCallback: any;
  isEnabled: KnockoutObservable<boolean>;
}

import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import System from 'framework/System';
import Check from 'framework/Check';
import IPagedData = framework.data.IPageResult;
import DataTableLoader = app.components.interfaces.DataTableLoader;
import DataTableOptions = app.components.interfaces.DataTableOptions;
import DataTableColumn = app.components.interfaces.DataTableColumn;
import DataTablePublicApi = app.components.interfaces.DataTablePublicApi;

@Component({
    selector: 'shared-components-odatagrid',
    templateUrl: './o-data-grid.component.html'
})
export class ODataGridComponent implements OnInit {
    @Input() options: DataTableOptions;
    rowTemplate: TemplateRef<any>;
    pagerOptions: app.components.interfaces.IPagerActivateParams;

    protected loader: DataTableLoader<any>;
    protected columns: DataTableColumn[];
    protected columnsById: Record<string, DataTableColumn>;
    protected rows: KnockoutObservableArray<any>;
    protected sortColumnId: string;
    protected isSortedAscFlag: boolean;
    protected isLoadingFlag = false;
    protected isPagingVisibleFlag = false;
    protected pagerApi: app.components.interfaces.IPagerPublicApi;

    ngOnInit() {
        Check.isTrue(_.isFunction(this.options.loader), 'params.loader must be a function');
        Check.isNotEmpty(this.options.columns, 'params.columns cannot be empty');

        this.loader = this.options.loader;
        this.rowTemplate = this.options.rowTemplate || null;

        this.rows = ko.observableArray([]);
        this.isSortedAscFlag = true;

        // configure columns
        this.columns = [];
        this.columnsById = {};
        _.each(this.options.columns, (c: DataTableColumn) => {
            Check.isNotEmpty(c.id, 'Column id cannot be empty');

            if (c.isDefaultSort) {
                this.sortColumnId = c.id;
                this.isSortedAscFlag = c.isDefaultSortAsc !== false;
            }
            this.columns.push(c);
            this.columnsById[c.id] = c;
        });

        this.pagerOptions = {
            apiCallback: (api): void => {
                this.pagerApi = api;

                // finish init and start load after pager api is set, which means we're fully ready to go
                if (this.options.apiCallback) {
                    this.options.apiCallback(this.getPublicApi());
                }

                this.isPagingVisibleFlag = this.options.isPagingVisible !== false;
                this.load();
            },
            stateChangeCallback: (page, pageSize): JQueryPromise<void> => this.load(page, pageSize),
            pageSize: this.options.pageSize,
            pageSizeOptions: this.options.pageSizeOptions,
            maxPageLinks: this.options.maxPageLinks
        };

    }

    getColumns(): DataTableColumn[] {
        return this.columns;
    }

    getRows(): any[] {
        return this.rows();
    }

    getHeaderValue(columnId: string): string {
        const column = this.getColumnById(columnId);
        return column.name !== undefined ? column.name : column.id;
    }

    getValue(row: any, columnId: string): any {
        const column = this.getColumnById(columnId);

        // check for value and parse dot syntax like object1.object2.object3
        if (column.value) {
            let result = row;
            const properties = column.value.split('.');
            _.every(properties, (p: string) => {
                if (result[p] === undefined) {
                    result = undefined;
                    return false;
                }
                result = result[p];
                return true;
            });
            return result;
        }

        // default case, return data by id
        return row[column.id];
    }

    getSortOrder(): string {
        const sortColumn = this.sortColumnId ? this.getColumnById(this.sortColumnId) : null;

        // todo: we translate dot syntax to slash syntax for odata, but that should be encapulated in the loader
        // we may need to use a SortOrder data structure and allow the loader to translate it correctly.
        let sortSpec: string = null;
        if (sortColumn) {
            sortSpec = sortColumn.value ? sortColumn.value.replace('.', '/') : sortColumn.id;
        }

        return sortSpec ?
            sortSpec + ' ' + (this.isSortedAscFlag ? 'asc' : 'desc')
            : null;
    }

    sortByColumn(columnId: string, asc?: boolean): JQueryPromise<void> {
        const column = columnId ? this.getColumnById(columnId) : null;
        if (!column || column.canSort === false) {
            return System.emptyPromise();
        }

        const originalSortColumnId = this.sortColumnId;
        const originalIsSortedAscFlag = this.isSortedAscFlag;

        if (column.id === this.sortColumnId) {
            this.isSortedAscFlag = _.isBoolean(asc) ? asc : !this.isSortedAscFlag;
        } else {
            this.sortColumnId = column.id;
            this.isSortedAscFlag = _.isBoolean(asc) ? asc : true;
        }

        const hasChanged = this.sortColumnId !== originalSortColumnId || this.isSortedAscFlag !== originalIsSortedAscFlag;
        return hasChanged ? this.load() : System.emptyPromise();
    }

    isColumnSortable(columnId: string): boolean {
        const column = columnId ? this.getColumnById(columnId) : null;
        return (column && column.canSort !== false && (column.value || column.id)) ? true : false;
    }

    isSortedByColumn(columnId: string): boolean {
        return columnId && this.sortColumnId === columnId;
    }

    isSortedAsc(): boolean {
        return this.isSortedAscFlag;
    }

    isLoading(): boolean {
        return this.isLoadingFlag;
    }

    isPagingVisible(): boolean {
        return this.isPagingVisibleFlag;
    }

    protected getColumnById(id: string): DataTableColumn {
        Check.isNotEmpty(this.columnsById[id], 'Invalid column id: ' + id);
        return this.columnsById[id];
    }

    protected load(page?: number, pageSize?: number): JQueryPromise<void> {
        if (!this.pagerApi) {
            throw new Error('You cannot call load() before setting the pagerApi.');
        }

        page = Math.max(1, page || this.pagerApi.getPage());
        pageSize = Math.max(1, pageSize || this.pagerApi.getPageSize());

        setTimeout(() => {
            this.isLoadingFlag = true;
        }, 0);
        return this.loader(page, pageSize, this.getSortOrder())
            .then((data: IPagedData<any>) => {
                this.rows(data && data.Items ? data.Items : []);
                this.pagerApi.setState(data && data.Count ? data.Count : 0, page, pageSize);
            })
            .always(() => {
                this.isLoadingFlag = false;
            })
            .fail((error: string) => {
                toastr.error(error, 'Error');
            });
    }

    protected getPublicApi(): DataTablePublicApi {
        return {
            reload: (page?: number): JQueryPromise<void> => this.load(page),
            replace: (oldItem: any, newItem: any): void => this.rows.replace(oldItem, newItem)
        };
    }
}

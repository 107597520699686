import { Component } from '@angular/core';
import timer from 'app/managecustomer/Timer';
import customernote from 'app/models/CustomerNote';
import { CustomerNoteService } from '../../../services/customer-note.service';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';
import { forkJoin } from 'rxjs';

@Component({
	selector: 'app-components-managecustomer-addnote',
	templateUrl: './AddNote.component.html'
})
export class AddNoteComponent {
    public dfd: any;
    public title = 'Add a new Note';
    private partnerUser: any;
    // observables
    public parent: any = ko.observable();
    public isLoading: any = ko.observable(false);
    public newNote: any = ko.observable('').extend({
        required: { message: 'Note Description is a required field.' },
        validation: { validator: window.launchpad.utils.containsNoHtml, message: 'Html tags are not allowed.  You cannot have a < followed by a >' },
        maxLength: 4000
    });
    public selectedContactType: any = ko.observable().extend({
        required: { message: 'Contact Type is a required field.' }
    });
    public toggleCustomerInfoCollapsed: any = ko.observable(false);
    public customer: any = ko.observable();
    // observable arrays
    public contactTypes: any = ko.observableArray();
    // Computed

    constructor(
      private customerNoteService: CustomerNoteService,
      private sessionStorageService: SessionStorageService) { }

    public partnerName: any = ko.computed(() => {
        if (this.customer() && this.customer().Partner) {
            return ko.utils.unwrapObservable(this.customer().Partner.Name);
        } else {
            return '';
        }
    });

    public activate(options: any): void {
      forkJoin([
        this.sessionStorageService.getPartnerUser(),
        this.sessionStorageService.getStaticData()
      ])
      .subscribe(([pUser, staticData]) => {
        this.partnerUser = pUser;
        if (_.isObject(options) && _.isObject(options.customer) && _.isObject(options.parent)) {
            this.customer(options.customer);
            this.parent(options.parent);
        } else {
            toastr.error('Customer is undefined.');
            return;
        }
        if (staticData && staticData.ContactTypes) {
            this.contactTypes(_.filter(staticData.ContactTypes, (x: any) => { return x.ContactTypeId !== window.launchpad.config.ContactTypeEnum.NoteRevision; }));
        }
      });
    }

    public close(): void {
        if (this.dfd) {
            this.dfd.reject();
        }
    }

    public addNewNote(): any {
        if (this.pageValidation.isValid()) {
            this.newNote($.trim(this.newNote()));
            const note = new customernote();
            if (_.isObject(this.customer()) && _.isNumber(this.customer().CustomerId())) {
                note.CustomerId = this.customer().CustomerId();
            } else {
                toastr.error('Error: customerId is undefined.');
                return;
            }
            note.FollowupDate = moment(moment().format('L')).toDate();
            note.ContactTypeId = this.selectedContactType();
            note.Duration = (timer.getElapsedSecondsSinceLastCustomerTouch());
            note.Text = this.newNote();
            note.UserFullName = this.partnerUser.UserFullName;
            note.UserId = this.partnerUser.UserId;
            $.ajaxSettings.traditional = true;

            Utils.wrapDfd(this.customerNoteService.save(note))
              .then(() => {
                toastr.success('Sucessfully added a new note.');
                this.dfd.resolve();
              })
              .fail((displayMessage) => {
                  toastr.error(displayMessage);
              });
        } else {
            toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
            this.pageValidation.errors.showAllMessages();
        }
    }
    public pageValidation: any = ko.validatedObservable(this);
}

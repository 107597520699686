import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NotificationService } from '../../../services/notification.service';
import { finalize } from 'rxjs/operators';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { NotificationDefinition } from '../../../shared/models/notifications/notifications';
import { PartnerService } from '../../../services/partner.service';
import { forkJoin } from 'rxjs';
import { PartnerFrontChannelComponent } from '../partner-front-channel/partner-front-channel.component';
import { PartnerEmailSettingsComponent } from '../partner-email-settings/partner-email-settings.component';

@Component({
  selector: 'app-components-manager-partner-emails',
  templateUrl: './partner-emails.component.html'
})
export class PartnerEmailsComponent implements OnInit {
  @Input() partnerMinimal: Boo.Objects.PartnerMinimal;
  notificationDefinitions: NotificationDefinition[] = [];
  dfd: any;
  isLoading = false;
  partner: Boo.Objects.Partner;
  @ViewChild(PartnerFrontChannelComponent) frontChannelComponentRef: PartnerFrontChannelComponent;
  @ViewChild(PartnerEmailSettingsComponent) emailSettingsComponentRef: PartnerEmailSettingsComponent;

  constructor(
    private notificationService: NotificationService,
    private partnerService: PartnerService) { }

  ngOnInit(): void {
    this.isLoading = true;
    forkJoin([
      this.notificationService.getPartnerDefinitions(this.partnerMinimal.PartnerId),
      this.partnerService.get(this.partnerMinimal.PartnerId)
    ])
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(
        ([notificationDefinitions, partner]) => {
          this.notificationDefinitions = notificationDefinitions;
          this.partner = partner;
        },
        err => toastr.error(err)
      );
  }

  close(): void {
    if (this.dfd) {
      this.dfd.resolve();
    }
  }

  save(): void {
    if (!this.emailSettingsComponentRef.emailSettingsForm.valid) {
      toastr.error("Fix the errors on the page before saving");
      return;
    }
    
    this.isLoading = true;
    this.frontChannelComponentRef.save(this.partnerMinimal.PartnerId);
    forkJoin([
      this.partnerService.update(this.partner, false, null),
      this.notificationService.updatePartnerExclusions(this.partner.PartnerId, this.notificationDefinitions)
    ])
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(
        () => {
          toastr.success('Settings updated successfully');
          this.close();
        },
        err => toastr.error(err)
      );
  }

  openTemplates(definition: NotificationDefinition): void {
    PriorityViewModel.ngShow('app-notification-definition', { definition: definition, partnerId: this.partner.PartnerId });
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import CustomValidators from '../../../../../shared/custom-validators';
import { BlogDomainService } from '../../../../../services/blog-domain.service';
import { InternalActionToken } from '../../internal-action.token';
import { CompletableWorkspace } from 'app/interfaces/completable-workspace';
import { Subject } from 'rxjs';
import { SavableWorkspace } from 'app/interfaces/savable-workspace';

@Component({
    selector: 'app-components-specialist-internal-tasks-blog-domain-purchase',
    templateUrl: './blog-domain-purchase.component.html'
})
export class BlogDomainPurchaseComponent implements OnInit, CompletableWorkspace, SavableWorkspace {
    showCompleteButton = false;
    complete = new Subject<void>();

    purchaseStatus: Boo.Blogs.DomainPurchaseStatus;
    countDown: number;
    elapsedSeconds: number;
    isLoading: boolean;
    domainName: UntypedFormControl;
    purchaseStatusNone = Boo.Blogs.DomainPurchaseStatus.None;
    purchaseStatusPurchasing = Boo.Blogs.DomainPurchaseStatus.Purchasing;
    purchaseStatusPurchased = Boo.Blogs.DomainPurchaseStatus.Purchased;
    purchaseStatusPurchaseFailed = Boo.Blogs.DomainPurchaseStatus.PurchaseFailed;

    private retrySeconds = 30;

    constructor(
        @Inject(InternalActionToken) public internalAction: Boo.Objects.InternalAction,
        private blogDomainService: BlogDomainService) { }

    ngOnInit() {
        this.domainName = new UntypedFormControl('', [
            Validators.required,
            CustomValidators.validUrl()
        ]);

        this.purchaseStatus = Boo.Blogs.DomainPurchaseStatus.None;
        this.elapsedSeconds = 0;
        this.isLoading = false;
    }

    updateCountDown() {
        const current = this.retrySeconds - this.elapsedSeconds;

        this.countDown = current < 0
            ? 0
            : current;
    }

    isReadyToComplete(): boolean {
        return this.purchaseStatus !== Boo.Blogs.DomainPurchaseStatus.Purchasing;
    }

    isReadyToSave(): boolean {
        if (this.purchaseStatus == Boo.Blogs.DomainPurchaseStatus.Purchasing) {
            toastr.error('Cannot close until purchase is complete');
            return false;
        }

        return true;
    }

    purchase(): void {
        this.purchaseStatus = Boo.Blogs.DomainPurchaseStatus.Purchasing;
        this.elapsedSeconds = 0;
        this.updateCountDown();
        const workflowId = this.internalAction.InternalTaskDetails.WorkflowId;
        const request = {
            Domain: this.domainName.value,
            WorkflowId: workflowId
        } as Boo.Blogs.Models.DomainPurchaseRequest;

        this.blogDomainService.purchase(request).subscribe(() => this.checkStatusOnInterval(workflowId))
    }

    private checkStatusOnInterval(workflowId: number): void {
        // We just use this to display a countdown timer.
        const elapsedInterval = setInterval(
            () => {
                this.elapsedSeconds++;
                this.updateCountDown();
            },
            1000);

        // We need to check the blog domain candidate status on an interval because namecheap timesout.
        const statusInterval = setInterval(
            () => {
                this.elapsedSeconds = 0;
                this.updateCountDown();
                this.blogDomainService.status(workflowId)
                    .subscribe((x) => {
                        if (x != Boo.Blogs.DomainPurchaseStatus.Purchasing) {
                            // if we have the results then clear the interval and set the status
                            this.purchaseStatus = x;
                            clearInterval(statusInterval);
                            clearInterval(elapsedInterval);
                        }

                        // if domain is purchased successfully
                        if (x === Boo.Blogs.DomainPurchaseStatus.Purchased) {
                            toastr.success('Domain purchased successfully.');
                            this.complete.next();
                        }

                        // if unable to purchase domain for some reason.
                        if (x === Boo.Blogs.DomainPurchaseStatus.PurchaseFailed) {
                            toastr.error('Domain could not be purchased. It may no longer be available, or there could be a system issue. Please try again.');
                        }
                    });
            },
            this.retrySeconds * 1000);
    }
}

import { Component } from '@angular/core';
import linklocation from 'app/models/linklocation';
import Utils from '../../../shared/utils';
import { LinkLocationService } from '../../../services/link-location.service';

@Component({
	selector: 'app-components-specialist-amazondeploymentstatushistory',
	templateUrl: './AmazonDeploymentStatusHistory.component.html'
})
export class AmazonDeploymentStatusHistoryComponent {
    public dfd: any;
    public title = 'Amazon Deployment Status History';
    public isLoading: any = ko.observable(false);
    public canPreview: any = ko.observable(false);
    public canSave: any = ko.observable(false);
    public linkLocation: any = ko.observable(ko.mapping.fromJS(new linklocation()));
    public amazonDeploymentStatusHistoryItems: any = ko.observableArray([]);

    constructor(private linkLocationService: LinkLocationService) { }

    public activate(options: any): void {
        if (options.linkLocation) {
            this.linkLocation(options.linkLocation);
            this.loadAmazonDeploymentStatusHistoryItems();
        }
    }

    public loadAmazonDeploymentStatusHistoryItems(): void {
        this.isLoading(true);
        Utils.wrapDfd(this.linkLocationService.getAmazonDeploymentHistory(this.linkLocation().LinkLocationId()))
          .then((data) => {
            this.amazonDeploymentStatusHistoryItems(data);
          })
          .fail((displayMessage) => {
              toastr.error(displayMessage);
          })
          .always(() => {
              this.isLoading(false);
          });
    }

    public close(): void {
        if (this.dfd) {
            this.dfd.reject();
        }
    }
}

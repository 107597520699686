import { Component, OnInit } from '@angular/core';
import System from 'framework/System';
import Todo = Boo.Objects.Todo;
import TodoHistory = Boo.Objects.Todo.TodoHistory;
import TodoStatus = Boo.Objects.Todo.Enums.TodoStatus;
import TodoValidation = app.tsmodels.interfaces.TodoValidation;
import TodoNoteValidation = app.tsmodels.interfaces.TodoNoteValidation;
import TodoNoteFactory from '../../factories/TodoNoteFactory';
import { UntypedFormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import CustomValidators from '../app/../../shared/custom-validators';
import { TodoService } from '../../../app/services/todo.service';
import Utils from '../../../app/shared/utils';

@Component({
  selector: 'app-components-todo',
  templateUrl: './Todo.component.html'
})
export class TodoComponent implements OnInit, IActivatable {
  window = window;
  launchpad = launchpad;
  newNote: TodoNoteValidation;
  todo: TodoValidation;
  history: TodoHistory[] = [];
  todoModalId: string;
  userId: number;
  isCustomerTodo = false;
  allowDelegation = false;
  statusMap = {
    [TodoStatus.New]: {
      class: 'text-default',
      icon: 'far fa-fw fa-circle',
      helpText: ''
    },
    [TodoStatus.Complete]: {
      class: 'text-success',
      icon: 'fas fa-fw fa-check-circle',
      helpText: 'Completed'
    },
    [TodoStatus.Incomplete]: {
      class: 'text-warning',
      icon: 'fas fa-fw fa-minus-circle',
      helpText: 'Not Completed'
    }
  };

  protected todoNoteFactory: TodoNoteFactory = new TodoNoteFactory();

  private previousState: TodoStatus = null;
  private delegatedNote = 'Item delegated to development';

  get isValid(): boolean {
    if (!this.newNote || !this.newNote.ContentCtrl) {
      return true;
    }

    return this.newNote.ContentCtrl.valid;
  }

  constructor(private todoService: TodoService) { }

  ngOnInit(): void {
    this.todoModalId = _.uniqueId('todoModal_');
    this.todo.NotesWithValidation = [];

    if (this.todo.TodoNotes.length) {
      this.todo.TodoNotes.map(x => {
        const note = x as TodoNoteValidation;
        this.addTodoNoteValidation(note);
        this.todo.NotesWithValidation.push(note);
      });
    }
  }
  canActivate(user: Boo.Objects.User, partner: Boo.Objects.Partner, params: ITodoActivationParams): boolean {
    this.userId = params.userId;
    this.todo = params.todo;
    this.isCustomerTodo = !!params.isCustomerTodo;
    this.allowDelegation = params.allowDelegation || false;
    return true;
  }

  noteCount(): number {
    return this.todo.TodoNotes
      .filter(note => note.InsertedUserId !== launchpad.config.systemAdminUserId)
      .length;
  }

  toggleTodo(): void {
    this.setStatus(this.toggleStatus());
  }

  markTodoIncomplete(): void {
    this.previousState = this.todo.Status;
    this.setStatus(TodoStatus.Incomplete);
    this.openTodo();
  }

  toggleDelegation(): void {
    const newStatus = this.toggleStatus();
    this.setStatus(newStatus);
    this.todo.Delegated = !this.todo.Delegated;

    switch (newStatus) {
      case TodoStatus.Complete:
        this.createNote(this.delegatedNote, false);
        break;
      case TodoStatus.New:
        this.deleteDelegatedNotes();
        break;
      default:
        break;
    }
    this.newNote = null;
  }

  openTodo(): void {
    $('#' + this.todoModalId).bind(this).on('shown.bs.modal', () => {
      this.newNote = null;

      const editableNotes = this.todo.TodoNotes.filter(note => note.IsEditable);

      if (editableNotes.length > 0) {
        this.newNote = editableNotes[0] as TodoNoteValidation;
      }
      this.checkForSkip();
    }).bind(this);

    $('#' + this.todoModalId).modal({
      backdrop: false,
      keyboard: false,
      show: true
    });
  }

  hadEditableNote(): boolean {
    return this.todo.TodoNotes.some(note => note.IsEditable);
  }

  checkForSkip(): void {
    if (this.todo.Status === TodoStatus.Incomplete) {
      this.addNote();
    }
  }

  addNote(): void {
    if (!this.newNote) {
      this.newNote = this.createNote();
    }

    if (this.newNote.Content.trim() === '' && this.todo.Status === TodoStatus.Incomplete) {
      this.newNote.Content = this.todo.DefaultIncompleteNote;
    }
  }

  deleteNote(index: any): void {
    this.todo.NotesWithValidation.splice(index, 1);
    this.todo.TodoNotes.splice(index, 1);
    this.newNote = null;
  }

  closeTodo(): void {
    this.todo.NotesWithValidation.forEach(note => {
      note.ContentCtrl.markAsTouched();
    });

    if (!this.isValid) {
      return;
    }

    this.deleteEmptyNotes();
    $('#' + this.todoModalId).modal('hide');
  }

  cancel(): void {
    this.deleteEmptyNotes();

    if (this.previousState !== null) {
      this.todo.Status = this.previousState;
    }

    this.previousState = null;
    $('#' + this.todoModalId).modal('hide');
  }

  loadHistory(): void {
    if (this.todo.SourceTodoId && this.history.length === 0) {
      Utils.wrapDfd(this.todoService.getHistory(this.todo.TodoId))
        .then((history: TodoHistory[]) => {
          this.history = history;
        });
    }
  }

  copyTodo(): void {
    navigator.clipboard.writeText(this.todo.Content)
      .then(() => {
        toastr.success('Copied to clipboard');
      })
      .catch((err) => {
        toastr.error('Failed to copy to clipboard');
        console.error(err);
      });
  }

  setTodoClass(): string {
    return this.todo.Delegated ? 'text-purple' : this.statusMap[this.todo.Status].class;
  }

  setTodoIcon(): string {
    return this.todo.Delegated ? 'fas fa-chevron-circle-right' : this.statusMap[this.todo.Status].icon;
  }

  setTodoHelpText(): string {
    return this.todo.Delegated ? 'Delegated' : this.statusMap[this.todo.Status].helpText;
  }

  private createNote(content = '', isEditable = true): TodoNoteValidation {
    const newNote = this.todoNoteFactory.create(this.todo.TodoId, this.userId, content, isEditable) as TodoNoteValidation;
    this.addTodoNoteValidation(newNote);
    this.todo.TodoNotes.push(newNote);
    this.todo.NotesWithValidation.push(newNote);

    return newNote;
  }

  private addTodoNoteValidation(newTodoNote: TodoNoteValidation): void {
    const validators: ValidatorFn[] = [];
    if (this.todo.Status === TodoStatus.Incomplete) {
      validators.push(Validators.required);
    }
    validators.push(CustomValidators.todoNoteValidator(this.todo));

    newTodoNote.ContentCtrl = new UntypedFormControl(newTodoNote.Content, validators);
    newTodoNote.ContentCtrl.valueChanges.subscribe(value => this.newNote.Content = value);
  }

  private deleteEmptyNotes(): void {
    this.todo.NotesWithValidation = this.todo.NotesWithValidation.filter(note => note.Content.trim() !== '');
    this.todo.TodoNotes = this.todo.TodoNotes.filter(note => note.Content.trim() !== '');
  }

  private deleteDelegatedNotes(): void {
    this.todo.TodoNotes = this.todo.TodoNotes
      .filter(x => !x.Content.includes(this.delegatedNote) && !x.TodoNoteId);

    this.todo.NotesWithValidation = this.todo.NotesWithValidation
      .filter(x => !x.Content.includes(this.delegatedNote) && !x.TodoNoteId);
  }

  private setStatus(status: TodoStatus): void {
    this.todo.StatusCtrl.setValue(status);
    this.todo.StatusCtrl.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    this.todo.StatusDate = moment().utc().toDate();
    this.todo.StatusUserId = this.userId;
  }

  private toggleStatus(): TodoStatus {
    return this.todo.Status !== TodoStatus.New ? TodoStatus.New : TodoStatus.Complete;
  }
}

export interface ITodoActivationParams {
  todo: TodoValidation;
  userId: number;
  isCustomerTodo: boolean;
  allowDelegation: boolean;
}

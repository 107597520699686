import { Injectable } from '@angular/core';
import PresignedUrlRequest from  'app/models/typescript/PresignedUrlRequest';
import Check from 'framework/Check';
import { AmazonService } from '../../app/services/amazon.service';

@Injectable({
  providedIn: 'root'
})
export default class FileUploader {

    constructor(private amazonService: AmazonService) { }

    /**
     * Uploads a file to amazon's S3 storage.
     * @param {File} file
     * @param {string} folder
     * @param {string} filename?
     * @returns
     */
    public upload(file: File, folder: string, filename?: string, bucket: string = launchpad.config.S3Buckets.s3staticfiles): JQueryPromise<Boo.Objects.Amazon.PreSignedUrl> {
        Check.isNotEmpty(file, 'File cannot be empty');

        const contentType: string = file.type ? file.type : 'multipart/form-data';
        filename = filename ? filename : file.name;

        Check.isNotEmpty(filename, 'Filename cannot be empty');

        const request: Boo.Objects.Amazon.PreSignedUrlRequest = new PresignedUrlRequest();
        request.BucketName = bucket;
        request.Filename = filename;
        request.Folder = folder;
        request.UseUniqueFileName = true;
        request.ContentType = contentType;

        return this.amazonService.upload(request, file);
    }
}

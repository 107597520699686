import { Component, TemplateRef, ViewChild } from '@angular/core';
import permissions from 'app/models/Permissions';
import CustomerAccountTypeGroup from 'app/customeraccount/CustomerAccountTypeGroup';
import partneruser from 'app/models/partneruser';
import Currencies from 'app/models/enums/currencies';
import BusinessEntities from 'app/models/enums/businessentities';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { PartnerGroupService } from '../../../services/partner-group.service';
import { HostConfigurationService } from '../../../services/host-configuration.service';
import { Observable, forkJoin } from 'rxjs';
import { Hosts } from 'app/models/enums/Hosts';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Countries } from 'app/models/enums/Countries';
import PresignedUrlRequest from 'app/models/typescript/PresignedUrlRequest';
import { PartnerUserService } from '../../../services/partner-user.service';
import { PartnerService } from '../../../services/partner.service';
import { CurrencyService } from '../../../services/currency.service';
import { PartnerChannelService } from '../../../services/partner-channel.service';
import { PartnerCountryService } from '../../../services/partner-country.service';
import { PartnerTicketTypeService } from '../../../services/partner-ticket-type.service';
import { PartnerTeamService } from '../../../services/partner-team.service';
import { UserService } from '../../../services/user.service';
import { PartnerSupportLevelService } from '../../../services/partner-support-level.service';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';
import { finalize } from 'rxjs/operators';

@Component({
	selector: 'app-components-manager-partner',
	templateUrl: './Partner.component.html'
})
export class PartnerComponent {
    @ViewChild('provisionTextMessageNumberModal') provisionTextMessagePhoneNumberModal: TemplateRef<any>;
    window = window;
    launchpad = launchpad;
    dfd: any;
    private mockPassword = '';
    title = 'Manage Partner';
    private normalNavigationMap = {
        "basicInfo": { next: 'businesscontacts', previous: null as any, isVisible: true },
        "businesscontacts": { next: 'emailsettings', previous: 'basicInfo', isVisible: true },
        "emailsettings": { next: 'externalaccess', previous: 'businesscontacts', isVisible: true },
        "externalaccess": { next: 'tickettypes', previous: 'emailsettings', isVisible: true },
        "tickettypes": { next: 'websitehosts', previous: 'externalaccess', isVisible: true },
        "websitehosts": { next: 'whitelabel', previous: 'tickettypes', isVisible: true },
        "whitelabel": { next: 'logos', previous: 'websitehosts', isVisible: false },
        "logos": { next: 'files', previous: 'whitelabel', isVisible: true },
        "files": { next: 'billing', previous: 'logos', isVisible: true },
        "billing": { next: 'activate', previous: 'files', isVisible: true },
        "activate": { next: null as any, previous: 'billing', isVisible: true }
    };
    private whiteLabelNavigationMap = {
        "basicInfo": { next: 'businesscontacts', previous: null as any, isVisible: true },
        "businesscontacts": { next: 'emailsettings', previous: 'basicInfo', isVisible: true },
        "emailsettings": { next: 'externalaccess', previous: 'businesscontacts', isVisible: true },
        "externalaccess": { next: 'tickettypes', previous: 'emailsettings', isVisible: true },
        "tickettypes": { next: 'websitehosts', previous: 'externalaccess', isVisible: true },
        "websitehosts": { next: 'whitelabel', previous: 'tickettypes', isVisible: true },
        "whitelabel": { next: 'logos', previous: 'websitehosts', isVisible: true },
        "logos": { next: 'files', previous: 'whitelabel', isVisible: true },
        "files": { next: 'billing', previous: 'logos', isVisible: true },
        "billing": { next: 'activate', previous: 'files', isVisible: true },
        "activate": { next: null as any, previous: 'billing', isVisible: true }
    };
    private partnerDefaults: Boo.Objects.PartnerDefaults;
    customerAccountDefaultGroupPublicApi: any = [];

    customerAccountDefaultGroupApiCallback(publicApi: any): void {
        this.customerAccountDefaultGroupPublicApi.push(publicApi);
    }
    customerAccountDefaultGroups: any = [
        {
            title: 'SEO',
            customerAccountTypes: CustomerAccountTypeGroup.seo,
        },
        {
            title: 'Website',
            customerAccountTypes: CustomerAccountTypeGroup.website,
        }
    ];

    constructor(
      private partnerGroupService: PartnerGroupService,
      private hostConfigurationService: HostConfigurationService,
      private modalService: NgbModal,
      private partnerUserService: PartnerUserService,
      private partnerService: PartnerService,
      private currencyService: CurrencyService,
      private partnerChannelService: PartnerChannelService,
      private partnerCountryService: PartnerCountryService,
      private partnerTicketTypeService: PartnerTicketTypeService,
      private partnerTeamService: PartnerTeamService,
      private userService: UserService,
      private partnerSupportLevelService: PartnerSupportLevelService,
      private sessionStorageService: SessionStorageService) { }

    testEmailAddress: any = ko.observable().extend({
        validation: {
            validator: (val: any) => {
                return !val || launchpad.utils.validation.emailAddressIsValid(val);
            },
            message: 'A valid email address is required'
        }
    });
    isLoading: any = ko.observable(false);
    partnerBillingOptions: any = ko.observable();
    partner: any = ko.observable();
    products: any = ko.observableArray([]);
    partnerChannels: any = ko.observableArray([]);
    partnerSupportLevels: any = ko.observableArray([]);
    partnerManagers: any = ko.observableArray([]);
    partnerGroups: Boo.Objects.Group[] = [];
    boostabilityUsers: any = ko.observableArray([]);
    salesUser: any = ko.observable('');
    seoProduct: any = ko.observable(null);
    seoRetailPerHour: any = ko.observable(null);
    seoWholesalePerHour: any = ko.observable(null);
    isWhiteLabelSetupComplete: any = ko.observable(false);
    reportsFromBoostability: any = ko.observable(true);
    adminPartnerUser: any = ko.observable();
    currentPartnerUser: any = ko.observable();
    createDevPartnerRequested: any = ko.observable(false);
    createQAPartnerRequested: any = ko.observable(false);
    selectedCurrency: any = ko.observable();
    selectedPartnerGroupIds: number[];
    currencies: any = ko.observableArray([]);
    businessEntities: any = ko.observableArray([]);
    isBusinessEntityLocked: any = ko.observable(false);
    partnerCountryIds: any = ko.observableArray([]);
    hostConfigs: any = ko.observableArray([]);
    teams: any = ko.observableArray([]);
    teamId: any = ko.observable();
    Hosts = Hosts;
    countryAbbreviation: KnockoutComputed<string>;

    private hasExistingPartnerGroup: boolean;
    // Validation observables

    partnerValidation: any = ko.validatedObservable({});

    partnerActivationValidation: any = ko.validatedObservable({});

    partnerCreationValidation: any = ko.validatedObservable({});

    partnerBasicInfoValidation: any = ko.validatedObservable({});

    businessEntityValidation: any = ko.validatedObservable({});

    partnerBusinessContactsValidation: any = ko.validatedObservable({});

    partnerEmailSettingsValidation: any = ko.validatedObservable({});

    hostValidation: any = ko.validatedObservable({});

    partnerWhiteLabelValidation: any = ko.validatedObservable({});

    partnerLogosValidation: any = ko.validatedObservable({});

    partnerBillingValidation: any = ko.validatedObservable({});
    isValidating: any = false;

    base64DataSmall: any = ko.observable();
    base64DataLarge: any = ko.observable();
    filenameLargeLogo: any = ko.observable();
    filenameSmallLogo: any = ko.observable();
    languages: any = ko.observableArray([]);
    countries: KnockoutComputed<Boo.Objects.Country[]>;
    states: any = ko.observableArray([]);
    ticketTypes: any = ko.observableArray([]);
    partnerTicketTypeIds: any = ko.observableArray([]);
    canManagePartnerTicketTypes: any = ko.observable(false);

    countriesIsValid: any = ko.computed(() => {
        return this.partnerCountryIds().length > 0;
    });

    filteredStates: any = ko.computed(() => {
        let states: any = [];
        if (this.partner() && this.partner().CountryId()) {
            states = _.filter(this.states(),
                (state: any) => {
                    return state.CountryId === this.partner().CountryId();
                });
        }
        // Adding a default "state" to force users to make a choice
        states.unshift({ Name: 'Choose...', StateId: 0 });
        return states;
    });

    canSavePartner: any = ko.computed(() => {
        return this.partner() && this.partner().PartnerId() > 0;
    });

    canActivatePartner: any = ko.computed(() => {
        return this.partner() && (!this.partner().IsWhiteLabel() || (this.partner().IsWhiteLabel() && this.isWhiteLabelSetupComplete()));
    });

    isSaveButtonEnabled: any = ko.computed(() => {
        return this.canSavePartner() && !this.isLoading();
    });

    isActivateButtonEnabled: any = ko.computed(() => {
        return this.canActivatePartner() && !this.isLoading();
    });

    isExistingPartner: any = ko.computed(() => {
        return this.partner() && this.partner().PartnerId() > 0;
    });
    // This is slightly preferable for binding over the isExistingPartner.

    isNewPartner: any = ko.computed(() => {
        return !this.isExistingPartner();
    });

    navigationMap: any = ko.computed(() => {
        return (_.isObject(this.partner()) && this.partner().IsWhiteLabel()) ? this.whiteLabelNavigationMap : this.normalNavigationMap;
    });

    private isHostConfigEmpty(config: any): any {
        const observables = [
            config.ApiAuthKey,
            config.SetApiAuthSecret,
            config.AdminAccountId,
            config.TrialProductId,
            config.TrialBillingFrequencyInMonths,
            config.LiveProductId,
            config.LiveBillingFrequencyInMonths
        ];
        return _.every(observables,
            (obs) => {
                return !$.trim(obs());
            });
    }

    private saveHostConfigs(): void {
        // only save existing and non-empty configs
        const configs = _.filter(this.hostConfigs(),
            (c: any) => {
                return c.HostConfigurationId() || !this.isHostConfigEmpty(c);
            });
        if (configs.length > 0) {
          const hostConfigurations: Boo.Objects.Websites.HostConfiguration[] = ko.mapping.toJS(configs);
          this.trimHostConfigurations(hostConfigurations);
          Utils.wrapDfd(this.hostConfigurationService.save(hostConfigurations))
            .fail(toastr.error);
        }
    }

    private trimHostConfigurations(hostConfigurations: Boo.Objects.Websites.HostConfiguration[])
    {
      const trimValue = (val: string) => val === null ? null : $.trim(val);

      _.each(hostConfigurations, (c) => {
        c.ApiAuthKey = trimValue(c.ApiAuthKey);
        c.SetApiAuthSecret = trimValue(c.SetApiAuthSecret);
        c.AdminAccountId = trimValue(c.AdminAccountId);
        c.TrialProductId = trimValue(c.TrialProductId);
        c.LiveProductId = trimValue(c.LiveProductId);
      });
    }

    private filterPartnerChannels(partnerId: number, initialPartnerChannels: Boo.Objects.PartnerChannel[]): Boo.Objects.PartnerChannel[] {
        const shouldRemoveDirectPartnerChannel = partnerId !== this.launchpad.config.partners.boostability; // Direct partner channel is only available for Boostability
        const shouldRemoveLegacyPartnerChannel = partnerId === 0; // Legacy is only allowed for existing partners

        return initialPartnerChannels.filter(
            (x: Boo.Objects.PartnerChannel) =>
                (!shouldRemoveDirectPartnerChannel || x.PartnerChannelId !== Boo.Objects.Enums.PartnerChannels.Direct) &&
                (!shouldRemoveLegacyPartnerChannel || x.PartnerChannelId !== Boo.Objects.Enums.PartnerChannels.Legacy)
            );
    }

    activate(options: any): void {
        // Set Smtp Password so that mapping generates the observable
        options.selectedPartner.SmtpPassword = '';
        // Load the partner channels and partner Managers first
        this.isLoading(true);
        forkJoin({
          partnerChannels: this.partnerChannelService.getAll(),
          partnerManagers: this.partnerUserService.getByUserLevel(Boo.Objects.Enums.UserLevelEnum.Manager),
          boostabilityUsers: this.userService.getSelectableByPartner(Boo.Objects.Enums.PartnerEnum.Boostability),
          supportLevels: this.partnerSupportLevelService.getActive(),
          partnerUser: this.sessionStorageService.getPartnerUser(),
          staticData: this.sessionStorageService.getStaticData(),
          currencies: this.currencyService.getSupported(),
          partnerGroups: this.partnerGroupService.getPartnerGroups(),
          partnerCountries: this.partnerCountryService.get(options.selectedPartner.PartnerId)
        })
        .pipe(
          finalize(() => {
          this.isLoading(false);
          this.partnerBillingOptions({ partner: this.partner, onSales: false });
          })
        )
        .subscribe(result => {
          const activeCountries = result.staticData.Countries.filter((x: any) => x.SupportStatusId === window.launchpad.config.supportStatus.supported);
          this.states(result.staticData.States);
          this.languages(result.staticData.Languages);
          this.ticketTypes(result.staticData.TicketTypes);
          this.businessEntities(result.staticData.BusinessEntities);
          this.partnerGroups = result.partnerGroups;
          this.countries = ko.computed(() => {
              const businessEntity = result.staticData.BusinessEntities.find(be => be.BusinessEntityId == this.partner()?.BusinessEntityId());
              if (!businessEntity) {
                  return activeCountries;
              }

              return businessEntity.Countries.filter(x => x.SupportStatusId === window.launchpad.config.supportStatus.supported);
          });
          this.teams(result.staticData.Teams);
          this.mockPassword = result.staticData.MockPassword;
          this.partnerDefaults = result.staticData.PartnerDefaults;
          if (result.currencies) {
              const returnedCurrencies = _.filter(result.currencies,
                  (currency: any) => {
                      return currency.Name.toLowerCase() !== 'unknown';
                  });
              this.currencies(_.map(returnedCurrencies,
                  (currency) => {
                      currency.Name = currency.Name + (currency.Code !== '' ? ` (${currency.Code})` : '');
                      return currency;
                  }));
          }
          this.partnerCountryIds(_.map(result.partnerCountries,
              (partnerCountry: any) => {
                  return partnerCountry.CountryId;
              }));
          this.businessEntities(result.staticData.BusinessEntities);

          this.partnerChannels(_.sortBy(this.filterPartnerChannels(options.selectedPartner.PartnerId, result.partnerChannels), (partnerChannel: Boo.Objects.PartnerChannel) => { return partnerChannel.Name;}));

          this.partnerManagers(_.sortBy(result.partnerManagers,
              (manager: any) => {
                  return manager.FullName.toLowerCase();
              }));
          this.boostabilityUsers(_.sortBy(result.boostabilityUsers,
              (user: any) => {
                  return user.FullName.toLowerCase();
              }));
          this.partnerSupportLevels(_.sortBy(result.supportLevels),
              (level: any) => {
                  return level.Name;
              });
          this.currentPartnerUser(result.partnerUser);
          // Now, we are ready to bind everything.
          if (options && options.selectedPartner && options.selectedPartner.PartnerId !== 0) {
              this.selectedCurrency(options.selectedPartner.CurrencyId);
              this.partner(ko.mapping.fromJS(options.selectedPartner));
              this.partner().AllowsTextMessaging.subscribe((value: any) => {
                this.updateTextMessagingConfig(value);
              });
              this.getPartnerTicketTypes(this.partner().PartnerId());
              this.setupSmtpPassword();
              this.setPartnerGroup();

              this.partner().SmtpPassword.subscribe((value: any) => {
                  this.partner().SetSmtpPassword(value);
              });
              this.isBusinessEntityLocked(this.partner().BusinessEntityId() && this.partner().BusinessEntityId() > 0);

              $.when<any>(
                Utils.wrapDfd(this.partnerTeamService.get(this.partner().PartnerId())),
                Utils.wrapDfd(this.partnerUserService.getByPartnerAndUserLevel(this.partner().PartnerId(), Boo.Objects.Enums.UserLevelEnum.Administrator)),
                Utils.wrapDfd(this.userService.getNameAndUsername(this.partner().SalesUserId())),
                Utils.wrapDfd(this.hostConfigurationService.getConfigurableHosts()),
                Utils.wrapDfd(this.hostConfigurationService.getByPartnerId(this.partner().PartnerId()))
              )
                .then((partnerTeam: any, adminUserData, salesUser, configurableHosts, hostConfigs) => {
                    if (partnerTeam !== null) {
                        const teamId = partnerTeam.TeamId;
                        this.teamId(teamId);
                    } else {
                        this.teamId();
                    }
                    // We need to default this to the correct value. It only controls validation and isn't stored in the database.
                    this.reportsFromBoostability(this.partner().FromEmailAddress() === this.partnerDefaults.FromEmailAddress);
                    this.reportsFromBoostability.subscribe((value: boolean) => {
                        if (value) {
                            this.partner().FromEmailAddress(this.partnerDefaults.FromEmailAddress);
                            this.partner().BccEmailAddress(this.partnerDefaults.BccEmailAddress);
                        } else {
                            this.partner().FromEmailAddress('');
                        }
                    })
                    let adminPartnerUser = _.find(adminUserData,
                        (partnerUser: any) => {
                            return partnerUser.IsPrimary;
                        });
                    adminPartnerUser = adminPartnerUser || new partneruser();
                    this.adminPartnerUser(ko.mapping.fromJS(adminPartnerUser));
                    this.setupUrlSubscriptions();
                    this.setupWhiteLabelSubscriptions();
                    // Do not show Inserted By if SalesUserId == 0
                    if (salesUser !== undefined && this.partner().SalesUserId() !== 0) {
                        this.salesUser(salesUser);
                    }
                    // set up website hosts and configs
                    const existingHostConfigs = hostConfigs || [];
                    this.hostConfigs(_.map(configurableHosts,
                        (configurableHost: Boo.Objects.Websites.Enums.Hosts) => {
                            const existing = _.find(existingHostConfigs,
                                (e: any) => {
                                    return e.HostId === configurableHost;
                                });
                            return ko.mapping.fromJS(
                              existing ? existing :
                              {
                                HostConfigurationId: 0,
                                PartnerId: this.partner().PartnerId(),
                                HostId: configurableHost,
                                HostName: Hosts[configurableHost],
                                ApiAuthKey: null,
                                SetApiAuthSecret: null,
                                AdminAccountId: null,
                                TrialProductId: null,
                                TrialBillingFrequencyInMonths: null,
                                LiveProductId: null,
                                LiveBillingFrequencyInMonths: null,
                            } as Boo.Objects.Websites.HostConfiguration);
                        }));
                    this.setupPartnerValidation();
                })
                .fail((partnerError, notesError, adminUserError) => {
                    if (partnerError) {
                        toastr.error(partnerError);
                    }
                    if (notesError) {
                        toastr.error(notesError);
                    }
                    if (adminUserError) {
                        toastr.error(adminUserError);
                    }
                });
          } else {
              this.partner(ko.mapping.fromJS(options.selectedPartner));
              this.setupSmtpPassword();
              this.getPartnerTicketTypesForNewPartner();

              this.partner().SmtpPassword.subscribe((value: any) => {
                  this.partner().SetSmtpPassword(value);
              });
          }
          // We need to make sure we default the partners country to the US if one isn't selected yet.
          if (options.selectedPartner.CountryId === 0) {
              this.partner().CountryId(1);
          }
          this.setupPartnerValidation();
          this.setupUrlSubscriptions();
          this.setupWhiteLabelSubscriptions();
          this.clearValidationMessages();
      }, err => toastr.error(err));
    }

    getPartnerTicketTypes(partnerId: any): void {
        Utils.wrapDfd(this.partnerTicketTypeService.get(partnerId))
            .then((ticketTypes) => {
                this.partnerTicketTypeIds(_.flatten(_.pluck(ticketTypes, 'TicketTypeId')));
            }).fail((displayMessage) => {
                toastr.error(displayMessage);
            });
    }

    getPartnerTicketTypesForNewPartner(): void {
        Utils.wrapDfd(this.partnerTicketTypeService.getForNewPartner())
            .then((ticketTypes) => {
                this.partnerTicketTypeIds(_.flatten(_.pluck(ticketTypes, 'TicketTypeId')));
            }).fail((displayMessage) => {
                toastr.error(displayMessage);
            });
    }

    partnerCanEnableTextMessaging(): boolean {
      const enabledCountries = [ Countries.US, Countries.CA ];
      return enabledCountries.some(x => x = this.partner().CountryId())
    }

    provisionTextMessagePhoneNumber(): void {
      this.modalService.open(this.provisionTextMessagePhoneNumberModal).result
        .then(() => {
          this.partnerService.provisionTextMessagingPhoneNumber(this.partner().PartnerId()).subscribe(x => {
            this.partner().AllowsTextMessaging(!!x);
            this.partner().TextMessagingPhoneNumber(x);
          });
        }, () => {
            this.partner().AllowsTextMessaging(false)
        });
    }

    updateTextMessagingConfig(allowsTextMessaging: boolean): void {
      if (allowsTextMessaging) {
        this.provisionTextMessagePhoneNumber();
      } else {
        this.partner().AllowsTextMessaging(false);
        this.partner().TextMessagingPhoneNumber(null);
      }
    }

    setupSmtpPassword(): void {
        if (this.partner().IsSmtpPasswordSet()) {
            this.partner().SmtpPassword(this.mockPassword);
        } else {
            this.partner().SmtpPassword('');
        }
    }

    getPassword(): any {
        if (!this.partner().IsSmtpPasswordSet() || this.partner().SmtpPassword() !== this.mockPassword) {
            return;
        }
        // Prevent a little blip.
        this.partner().SmtpPassword('');
        $.when(Utils.wrapDfd(this.partnerService.getSmtpPassword(ko.utils.unwrapObservable(this.partner().PartnerId))))
            .then((password) => {
                this.partner().SmtpPassword(password);
            }).fail((message) => {
                toastr.error(message);
            });
    }

    canActivate(user: any, partner: any): any {
        this.canManagePartnerTicketTypes(window.launchpad.hasPermission(partner, user.PartnerUsers, permissions.CanManagePartnerTicketTypes, user));
        return true;
    }

    private validateCustomerAccountDefaultGroups(): any {
        // Collect all the validation methods.
        const customerAccountDefaultGroupPromises = _.map(this.customerAccountDefaultGroupPublicApi,
            (x: any) => {
                return x.validate();
            });
        // Validate components.
        return $.when.apply(this, customerAccountDefaultGroupPromises)
            .then(function (...args) {
                const results = [];
                for (let _i = 0; _i < args.length; _i++) {
                    results[_i] = args[_i];
                }
                const isValid = _.every(results, (r) => { return r === true; });
                if (!isValid) {
                    toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
                }
                return isValid;
            });
    }

    save(): any {
        if (!this.customerAccountDefaultGroupPublicApi) {
            toastr.error('Could not save external access. Missing public api');
            return;
        }
        // First verify that the external access is valid
        this.validateCustomerAccountDefaultGroups()
            .then((result: any) => {
                if (!result) {
                    return $.Deferred().reject('External Access is not valid');
                }
                if (!this.partnerValidation.isValid()) {
                    this.partnerValidation.errors.showAllMessages();
                    toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
                    return $.Deferred().reject('Please correct the errors on the screen');
                }
                this.savePartner();
                this.savePartnerCountries();
                if (this.canManagePartnerTicketTypes()) {
                    this.savePartnerTicketTypes(this.partner().PartnerId());
                }
                this.savePartnerGroup();
                this.saveHostConfigs();
            }).then(this.saveCustomerAccountDefaults.bind(this))
            .fail((message: any) => {
                toastr.error(message);
            }).always(() => {
                this.isLoading(false);
            });
    }

    private saveCustomerAccountDefaults(): any {
        // Loop through each CustomerAccountDefaultGroup and call save
        const customerAccountDefaultGroupPromises = _.map(this.customerAccountDefaultGroupPublicApi,
            (x: any) => {
                return x.save(false, this.partner().PartnerId());
            });
        return $.when.apply(this, customerAccountDefaultGroupPromises);
    }

    sendTestEmail(): any {
        if (!this.testEmailAddress()) {
            toastr.error('A test email address is required');
            return;
        }
        if (!launchpad.utils.validation.emailAddressIsValid(this.testEmailAddress())) {
            toastr.error('A valid Email address is required');
            return;
        }
        Utils.wrapDfd(this.partnerService.sendTestEmail(ko.mapping.toJS(this.partner()), this.testEmailAddress()))
          .then(() => {
              toastr.success('Email successfully sent');
          }).fail((displayMessage) => {
              toastr.error(displayMessage);
          }).always(() => {
              this.isLoading(false);
          });
    }

    savePartnerCountries(): void {
        Utils.wrapDfd(this.partnerCountryService.saveMany(this.partner().PartnerId(), this.partnerCountryIds()))
            .fail((displayMessage) => {
                toastr.error(displayMessage);
            });
    }

    savePartnerGroup(): void {
      let $save: Observable<any>;

      if ((!this.selectedPartnerGroupIds || this.selectedPartnerGroupIds.length === 0) && !this.hasExistingPartnerGroup) {
        return;
      } else if ((!this.selectedPartnerGroupIds || this.selectedPartnerGroupIds.length === 0) && this.hasExistingPartnerGroup) {
        $save = this.partnerGroupService.delete(this.partner().PartnerId());
      } else {
        $save = this.partnerGroupService.save(this.partner().PartnerId(), this.selectedPartnerGroupIds);
      }

      $save.subscribe({ error: err => toastr.error(err) });
    }

    savePartnerTicketTypes(partnerId: any): void {
        Utils.wrapDfd(this.partnerTicketTypeService.replaceAll(partnerId, this.partnerTicketTypeIds()))
            .fail((displayMessage) => {
                toastr.error(displayMessage);
            });
    }

    createSecondaryPartner(secondPartner: any): void {
        this.isLoading(true);
        Utils.wrapDfd(this.partnerService.create(secondPartner))
          .then((data: Boo.Objects.Partner) => {
              this.savePartnerTicketTypes(data.PartnerId);
              toastr.success(data.Name + ' Partner was created successfully.');
          }).fail((displayMessage) => {
              toastr.error(displayMessage);
          }).always(() => {
              this.isLoading(false);
          });
    }

  activatePartner(): any {
        if (!this.partnerActivationValidation.isValid() || !this.partnerValidation.isValid() || !this.businessEntityValidation().isValid() || !this.partnerBillingValidation.isValid()) {
            this.partnerActivationValidation.errors.showAllMessages();
            this.partnerValidation.errors.showAllMessages();
            this.businessEntityValidation.errors.showAllMessages();
            this.partnerBillingValidation.errors.showAllMessages();
            toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
            this.isLoading(false);
            return;
        }
        this.savePartner(true);
        if (this.createDevPartnerRequested() === true) {
            const devPartner = _.clone(ko.mapping.toJS(this.partner()));
            devPartner.Name += ' (DEV)';
            devPartner.PartnerId = 0;
            this.createSecondaryPartner(devPartner);
        }
        const delay = 500; // Avoid double click checking
        setTimeout(() => {
            if (this.createQAPartnerRequested() === true) {
                const qaPartner = _.clone(ko.mapping.toJS(this.partner()));
                qaPartner.Name += ' (QA)';
                qaPartner.PartnerId = 0;
                this.createSecondaryPartner(qaPartner);
            }
        },
            delay);
    }

    savePartner(activatePartner = false, shouldStayOpen = false): void {
        if (!this.partnerValidation.isValid() || this.isValidating) {
            this.partnerValidation.errors.showAllMessages();
            toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
            return;
        }
        if (this.isExistingPartner()) {
            this.isLoading(true);
            if (!this.selectedCurrency.isValid()) {
                const currencyErrors = ko.validation.group([this.selectedCurrency]);
                currencyErrors.showAllMessages();
                toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
                this.isLoading(false);
                return;
            }
            this.partner().CurrencyId(this.selectedCurrency());
            const partner = ko.mapping.toJS(this.partner());
            let partnerTeam: any;
            if (this.teamId()) {
                partnerTeam = { PartnerId: partner.PartnerId, TeamId: this.teamId() };
            }
            Utils.wrapDfd(this.partnerService.update(partner, activatePartner, partnerTeam || null))
              .then(() => {
                  toastr.info('Partner record has been saved');
                  if (activatePartner) {
                      toastr.info('Partner is now active.');
                  }
                  if (!shouldStayOpen) {
                      this.dfd.resolve();
                  }
              }).fail((displayMessage) => {
                  toastr.error(displayMessage);
              }).always(() => {
                  this.isLoading(false);
              });
        }
    }

    create(): void {
        this.isLoading(true);
        this.partner().SalesUserId(this.currentPartnerUser().UserId);
        Utils.wrapDfd(this.partnerService.create(ko.mapping.toJS(this.partner())))
          .then((data: Boo.Objects.Partner) => {
            data.SmtpPassword = '';
            this.customerAccountDefaultGroupPublicApi = [];
            this.partner(ko.mapping.fromJS(data)); // Update partner with fields modified by create call
            this.setupSmtpPassword();
            this.savePartnerCountries();
            this.savePartnerTicketTypes(this.partner().PartnerId());
            if (this.selectedPartnerGroupIds) {
              this.savePartnerGroup();
            }
            this.setupPartnerValidation();
            this.setupUrlSubscriptions();
            this.setupWhiteLabelSubscriptions();
            this.partner.valueHasMutated(true);
            toastr.success('Partner was created successfully.');
            $('#basicInfo').collapse('hide');
        }).fail((displayMessage) => {
            toastr.error(displayMessage);
            $('#products').collapse('hide');
            $('#basicInfo').collapse('show');
        }).always(() => {
            this.isLoading(false);
        });
    }

    cancel(): void {
        if (this.dfd) {
            this.dfd.reject();
        }
    }
    private currentUploadType: any = null;

    uploadFile(data: any, event: any): void {
        const elem = $(event.target);
        this.currentUploadType = elem.data('logotype');
        const request: Boo.Objects.Amazon.PreSignedUrlRequest = new PresignedUrlRequest();
        request.BucketName = window.launchpad.config.S3Buckets.s3staticfiles;
        request.Folder = `logos/${this.partner().PartnerId()}`;
        request.UseUniqueFileName = true;

      PriorityViewModel.show('app-components-shared-uploadfile', { preSignedURLRequest: request }).done((fileData: any) => {
            if (this.currentUploadType === 'large') {
                this.partner().LogoLargeS3Url(fileData.FullUrl);
            } else if (this.currentUploadType === 'small') {
                this.partner().LogoSmallS3Url(fileData.FullUrl);
            } else if (this.currentUploadType === 'jpg') {
                this.partner().LogoJpgS3Url(fileData.FullUrl);
            } else {
                toastr.error('File was uploaded, but something else happened to prevent the partner from being saved. Please contact your manager about this error');
            }
            this.savePartner(false, true);
        });
    }
    // Panel Navigation

    buttonNext(data: any, event: any): any {
        const elem = $(event.target);
        const current = $(`#${elem.data('current')}`);
        const currentPage = current.attr('id');
        // Force the navigation map to refresh.
        // this.partner.valueHasMutated();
        const next = $(`#${elem.data('next')}`);
        let isValid = true;
        switch (currentPage) {
            case 'basicInfo':
                if (!this.partnerBasicInfoValidation.isValid()) {
                    this.partnerBasicInfoValidation.errors.showAllMessages();
                    isValid = false;
                }
                break;
            case 'businesscontacts':
                if (!this.partnerBusinessContactsValidation.isValid()) {
                    this.partnerBusinessContactsValidation.errors.showAllMessages();
                    isValid = false;
                }
                break;
            case 'emailsettings':
                if (!this.partnerEmailSettingsValidation.isValid()) {
                    this.partnerEmailSettingsValidation.errors.showAllMessages();
                    isValid = false;
                } else {
                    if (this.partner().PartnerId() === 0) {
                        this.create();
                        return;
                    }
                    isValid = true;
                }
                break;
            case 'websitehosts':
                if (!this.hostValidation.isValid()) {
                    this.hostValidation.errors.showAllMessages();
                    isValid = false;
                }
                break;
            case 'whitelabel':
                this.partner().WwwUrl.valueHasMutated();
                this.partner().ReportingUrl.valueHasMutated();
                if (!this.partnerWhiteLabelValidation.isValid()) {
                    this.partnerWhiteLabelValidation.errors.showAllMessages();
                    isValid = false;
                }
                break;
            case 'logos':
                if (!this.partnerLogosValidation.isValid()) {
                    this.partnerLogosValidation.errors.showAllMessages();
                    isValid = false;
                }
                break;
            case 'billing':
                this.partner().BillingContactEmailAddress.valueHasMutated();
                this.partner().BillingContactName.valueHasMutated();
                this.partner().BillingContactPhone.valueHasMutated();
                if (!this.partnerBillingValidation.isValid()) {
                    this.partnerBillingValidation.errors.showAllMessages();
                    isValid = false;
                }
                break;
            default:
                break;
        }
        if (!isValid) {
            toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
        } else {
            next.collapse('toggle');
        }
    }

    buttonPrevious(data: any, event: any): void {
        const elem = $(event.target);
        const previous = $(`#${elem.data('previous')}`);
        previous.collapse('toggle');
    }

    private setupUrlSubscriptions(): void {

        this.partner().WwwUrl.subscribe((val: any) => {
            if (val && !_.isEmpty(val)) {
                const newUrl = window.launchpad.utils.formatUrl(val, true, true, true, false, true);
                if (newUrl !== this.partner().WwwUrl()) {
                    this.partner().WwwUrl(newUrl);
                }
            }
        });

        this.partner().ReportingUrl.subscribe((val: any) => {
            if (val && !_.isEmpty(val)) {
                const newUrl = window.launchpad.utils.formatUrl(val, true, true, true, true, true);
                if (newUrl !== this.partner().ReportingUrl()) {
                    this.partner().ReportingUrl(newUrl);
                }
            }
        });
    }

    private setupWhiteLabelSubscriptions(): void {
        this.partner().IsWhiteLabel.subscribe((val: boolean) => {
            if (val) {
                this.partner().SupportEmailAddress(this.partner().SupportEmailAddress() === this.partnerDefaults.SupportEmailAddress ? '' : this.partner().SupportEmailAddress());
                this.partner().SupportPhoneNumber(this.partner().SupportPhoneNumber() === this.partnerDefaults.SupportPhoneNumber ? '' : this.partner().SupportPhoneNumber());
            } else {
                this.partner().SupportEmailAddress(this.partner().SupportEmailAddress() === '' ? this.partnerDefaults.SupportEmailAddress : this.partner().SupportEmailAddress());
                this.partner().SupportPhoneNumber(this.partner().SupportPhoneNumber() === '' ? this.partnerDefaults.SupportPhoneNumber : this.partner().SupportPhoneNumber());
            }
        });
    }

    // Validation

    private clearValidationMessages(): void {
        this.partnerActivationValidation.errors.showAllMessages(false);
        this.partnerCreationValidation.errors.showAllMessages(false);
        this.partnerBasicInfoValidation.errors.showAllMessages(false);
        this.partnerBusinessContactsValidation.errors.showAllMessages(false);
        this.partnerEmailSettingsValidation.errors.showAllMessages(false);
        this.partnerWhiteLabelValidation.errors.showAllMessages(false);
        this.partnerLogosValidation.errors.showAllMessages(false);
        this.partnerBillingValidation.errors.showAllMessages(false);
        this.hostValidation.errors.showAllMessages(false);
    }

    private partnerNameValidator(val: any, b: any, callback: any): void {
        Utils.wrapDfd(this.partnerService.isPartnerNameUnique(val, ko.utils.unwrapObservable(this.partner().PartnerId)))
          .then((x) => {
              callback(x);
          }).fail(() => {
              callback(false);
          });
    }

    setupPartnerValidation(): void {
        this.countryAbbreviation = ko.computed(() => {
            const country = this.countries().find((x) => this.partner().CountryId() === x.CountryId);
            return country.Abbreviation ?? "US";
        })
        // Basic Information
        this.partner().PartnerChannelId.extend({ required: { message: 'A Partner Channel is required.' } });
        this.partner().SupportLevelId.extend({ required: { message: 'A Support Level is required.' } });
        this.partner().Name.extend({
            required: { message: 'Partner name is a required field.' },
            maxLength: 128,
            validation: {
                async: true,
                validator: this.partnerNameValidator.bind(this),
                message: 'Partner name must be unique.'
            }
        });

        this.partner().Name.isValidating.subscribe((isValidating: any) => {
            this.isValidating = isValidating;
        });
        this.partner().PartnerManagerUserId.extend({
            required: { message: 'Partner Manager is required.' },
            validation: {
              message: 'Partner Manager is required.',
              validator: (value: any) => {
                return parseInt(value) > 0;
              }
            }
        });
        this.partner().HasFinanceApproved.extend({
            required: { params: true, message: 'Finance Approval status required.' }
        });
        this.partner().StorefrontId.extend({
            required: { params: true, message: 'A storefront must be created before activating a partner.' }
        });
        this.selectedCurrency.extend({
            validation: {
                message: 'Partner Currency is required.',
                validator: (val: any) => {
                    let result = false;
                    const isLiveAndActive = this.partner().IsLive() && this.partner().IsActive();
                    const isNew = this.partner().PartnerId() === 0;
                    const isSet = parseInt(val) > 0;
                    if (isSet || (!isNew && isLiveAndActive)) {
                        result = true;
                    }
                    return result;
                }
            }
        });
        // Only required when activating a partner
        this.partner().BusinessEntityId.extend({
            required: { message: 'Selection is required.' }
        });
        // Business Contacts
        this.partner().TechnicalContactName.extend({
            required: { message: 'Technical Contact Name is required.' },
            maxLength: 128
        });
        this.partner().TechnicalContactEmail.extend({
            required: { message: 'Technical Contact Email Address is required.' },
            validation: {
                validator: (val: any, validate: any) => {
                    return ko.validation.rules.email.validator($.trim(val), validate);
                },
                maxLength: 256,
                message: 'Not a valid email address.'
            }
        });
        this.partner().TechnicalContactPhone.extend({
            required: { message: 'Technical Contact Phone Number is required.' },
            phoneNumber: this.countryAbbreviation
        });
        this.partner().SalesPhoneNumber.extend({
            required: {
                onlyIf: () => {
                    return this.partner().IsWhiteLabel() === true;
                }
            },
            phoneNumber: this.countryAbbreviation
        });
        this.partner().SupportPhoneNumber.extend({
            required: {
                onlyIf: () => {
                    return this.partner().IsWhiteLabel() === true;
                }
            },
            phoneNumber: this.countryAbbreviation
        });
        this.partner().SupportEmailAddress.extend({
            required: {
                onlyIf: () => {
                    return this.partner().IsWhiteLabel() === true;
                }
            },
            validation: {
                validator: (val: any, validate: any) => {
                    return ko.validation.rules.email.validator($.trim(val), validate);
                },
                message: 'Not a valid email address.'
            },
            maxLength: 256
        });
        this.partner().SystemNotificationEmailAddress.extend({
            validation: {
                validator: (val: any, validate: any) => {
                    return ko.validation.rules.email.validator($.trim(val), validate);
                },
                message: 'Not a valid email address.'
            },
            maxLength: 256
        });
        // Email Settings
        this.partner().FromEmailAddress.extend({
            required: {
                onlyIf: () => {
                    return this.partner().IsWhiteLabel() === true
                      && !this.reportsFromBoostability();
                },
                message: 'From Email Address is required.'
            },
            validation: {
                validator: (val: any, validate: any) => {
                    return ko.validation.rules.email.validator($.trim(val), validate);
                },
                message: 'Not a valid email address.'
            },
            maxLength: 256
        });
        this.partner().FromEmailAddressDisplayName.extend({
            required: {
                onlyIf: () => {
                    return this.partner().IsWhiteLabel() === true
                      && !this.reportsFromBoostability();
                },
                message: 'From Email Address Display Name is required.'
            },
            maxLength: 256
        });
        this.partner().BccEmailAddress.extend({
            validation: {
                validator: (val: any, validate: any) => {
                    return ko.validation.rules.email.validator($.trim(val), validate);
                },
                message: 'Not a valid email address.'
            },
            maxLength: 32
        });
        this.partner().BccEmailAddressDisplayName.extend({});
        this.partner().SmtpHost.extend({
            required: {
                onlyIf: () => {
                    return this.partner().IsWhiteLabel() === true
                      && !this.reportsFromBoostability();
                },
                message: 'SMTP Host Name is required.'
            },
            validation: {
              validator: (val: any) => {
                return this.reportsFromBoostability() ||
                    window.launchpad.utils.isValidUrl(val);
                },
                message: 'Not a valid URL.'
            },
            maxLength: 256
        });
        this.partner().SmtpUsername.extend({
            required: {
                onlyIf: () => {
                    return this.partner().IsWhiteLabel() === true
                      && !this.reportsFromBoostability();
                },
                message: 'SMTP Username is required.'
            },
            maxLength: 64
        });
        this.partner().SmtpPassword.extend({
            required: {
                onlyIf: () => {
                    return this.partner().IsWhiteLabel() === true
                      && !this.reportsFromBoostability();
                },
                message: 'SMTP Password is required.'
            },
            maxLength: 256
        });
        this.partner().SmtpPort.extend({
            required: {
                onlyIf: () => {
                    return this.partner().IsWhiteLabel() === true
                      && !this.reportsFromBoostability();
                },
                message: 'SMTP Port is a required field.'
            },
            pattern: new window.launchpad.utils.validation.nonNegativeIntValidationPattern()
        });
        // White Label Details
        this.partner().WwwUrl.extend({
            required: {
                onlyIf: () => {
                    return this.partner().IsWhiteLabel() === true;
                },
                message: 'Reporting URL is required.'
            },
            validation: [
                {
                    validator: (val: any) => {
                        return (this.partner().IsWhiteLabel() !== true) ||
                          window.launchpad.utils.isValidUrl(val);
                    },
                    message: 'Not a valid URL.'
                }
            ],
            maxLength: 256
        });
        this.partner().ReportingUrl.extend({
            required: {
                onlyIf: () => {
                    return this.partner().IsWhiteLabel() === true;
                },
                message: 'Reporting URL is required.'
            },
            validation: [
                {
                    validator: (val: any) => {
                        return (this.partner().IsWhiteLabel() !== true) ||
                            window.launchpad.utils.isValidUrl(val);
                    },
                    message: 'Not a valid URL.'
                },
                {
                    validator: (val: any) => {
                        const url = $.trim(val).replace('https:// ', '').toLowerCase();
                        return !_.find((window.urlConfig as any).restrictedReportingUrls,
                            (restrictedUrl: any) => {
                                return url.indexOf(restrictedUrl) === 0;
                            });
                    },
                    message: 'Reporting URL cannot be mymarketinfo.com'
                }
            ],
            maxLength: 256
        });
        // Billing Information
        this.partner().BillingContactName.extend({
            required: { message: 'Billing Contact Name is required.' },
            maxLength: 256
        });
        this.partner().BillingContactEmailAddress.extend({
            required: { message: 'Billing Contact Email Address is required.' },
            validation: {
                validator: (val: any, validate: any) => {
                    return ko.validation.rules.email.validator($.trim(val), validate);
                },
                message: 'Not a valid email address.'
            },
            maxLength: 256
        });
        this.partner().BillingContactPhone.extend({
            required: { message: 'Billing Contact Phone Number is required.' },
            phoneNumber: this.countryAbbreviation
        });
        this.partner().Address1.extend({
            required: { message: 'Address is required.' },
            maxLength: 512
        });
        this.partner().City.extend({
            required: { message: 'City is required.' },
            maxLength: 512
        });
        this.partner().PostalCode.extend({
            required: { message: 'Postal Code is required.' },
            maxLength: 10
        });
        this.partner().CountryId.extend({ required: true });
        this.partner().StateId.extend({
            validation: {
                validator: (val: any) => {
                    if (parseInt(val) <= 0 && this.filteredStates().length > 1) {
                        return false;
                    }
                    return true;
                },
                message: 'Please pick a State'
            }
        });
        //Panel Validations
        this.partnerBasicInfoValidation = ko.validatedObservable([
            this.partner().PartnerChannelId,
            this.partner().Name,
            this.partner().PartnerManagerUserId,
            this.partner().SupportLevelId,
            this.partner().HasFinanceApproved,
            this.selectedCurrency,
            this.countriesIsValid.extend({
                equal: { message: 'At least one country must be selected' }
            })
        ]);
        this.businessEntityValidation = ko.validatedObservable([
            this.partner().BusinessEntityId
        ]);

        this.partnerBusinessContactsValidation = ko.validatedObservable({
            TechnicalContactName: this.partner().TechnicalContactName,
            TechnicalContactEmail: this.partner().TechnicalContactEmail,
            TechnicalContactPhone: this.partner().TechnicalContactPhone,
            SalesPhoneNumber: this.partner().SalesPhoneNumber,
            SupportPhoneNumber: this.partner().SupportPhoneNumber,
            SupportEmailAddress: this.partner().SupportEmailAddress,
            SystemNotificationEmailAddress: this.partner().SystemNotificationEmailAddress
        });

        this.partnerEmailSettingsValidation = ko.validatedObservable({
            SmtpHost: this.partner().SmtpHost,
            SmtpUsername: this.partner().SmtpUsername,
            SmtpPassword: this.partner().SmtpPassword,
            SmtpPort: this.partner().SmtpPort,
            FromEmailAddress: this.partner().FromEmailAddress,
            FromEmailAddressDisplayName: this.partner().FromEmailAddressDisplayName,
            BccEmailAddress: this.partner().BccEmailAddress,
            BccEmailAddressDisplayName: this.partner().BccEmailAddressDisplayName
        });

        this.partnerWhiteLabelValidation = ko.validatedObservable({
            WwwUrl: this.partner().WwwUrl,
            ReportingUrl: this.partner().ReportingUrl
        });

        this.partnerLogosValidation = ko.validatedObservable([
            this.partner().LogoLargeS3Url,
            this.partner().LogoJpgS3Url
        ]);
        this.partnerBillingValidation = ko.validatedObservable([
            this.partner().BillingContactName,
            this.partner().BillingContactEmailAddress,
            this.partner().BillingContactPhone,
            this.partner().Address1,
            this.partner().City,
            this.partner().PostalCode,
            this.partner().StateId,
            this.partner().CountryId
        ]);
        this.partnerActivationValidation = ko.validatedObservable([
            this.partner().StorefrontId
        ]);

        // Validation required to initially create partner
        this.partnerCreationValidation = ko.validatedObservable({
            PartnerChannelId: this.partner().PartnerChannelId,
            Name: this.partner().Name,
            PartnerManagerUserId: this.partner().PartnerManagerUserId,
            HasFinanceApproved: this.partner().HasFinanceApproved,
            TechnicalContactName: this.partner().TechnicalContactName,
            TechnicalContactEmail: this.partner().TechnicalContactEmail,
            TechnicalContactPhone: this.partner().TechnicalContactPhone,
            SalesPhoneNumber: this.partner().SalesPhoneNumber,
            SupportPhoneNumber: this.partner().SupportPhoneNumber,
            SupportEmailAddress: this.partner().SupportEmailAddress,
            SystemNotificationEmailAddress: this.partner().SystemNotificationEmailAddress,
            SmtpHost: this.partner().SmtpHost,
            SmtpUsername: this.partner().SmtpUsername,
            SmtpPassword: this.partner().SmtpPassword,
            SmtpPort: this.partner().SmtpPort,
            FromEmailAddress: this.partner().FromEmailAddress,
            FromEmailAddressDisplayName: this.partner().FromEmailAddressDisplayName,
            BccEmailAddress: this.partner().BccEmailAddress,
            BccEmailAddressDisplayName: this.partner().BccEmailAddressDisplayName
        });

        // website host validation
        let hostValidationItems: any = [];
        _.each(this.hostConfigs(),
            (config: any) => {
                const requiredMsg = 'This field is required when any fields for the host are defined or when the configuration already exists.';

                const isRequiredFunc = () => { return config.HostConfigurationId() || !this.isHostConfigEmpty(config); };
                const requiredDef = { message: requiredMsg, onlyIf: isRequiredFunc };
                hostValidationItems = hostValidationItems.concat([
                    config.ApiAuthKey.extend({
                        required: requiredDef
                    }),
                    config.SetApiAuthSecret.extend({
                        required: {
                            message: requiredMsg,
                            onlyIf: () => {
                                return !config.HostConfigurationId() && !this.isHostConfigEmpty(config);
                            }
                        }
                    })
                ]);
                // host specific validation:
                switch (config.HostId()) {
                    case Boo.Objects.Websites.Enums.Hosts.Mono:
                        hostValidationItems = hostValidationItems.concat([
                            config.TrialProductId.extend({ required: requiredDef }),
                            config.TrialBillingFrequencyInMonths.extend({ required: requiredDef }),
                            config.LiveProductId.extend({ required: requiredDef }),
                            config.LiveBillingFrequencyInMonths.extend({ required: requiredDef })
                        ]);
                        break;
                    case Boo.Objects.Websites.Enums.Hosts.Duda:
                        hostValidationItems.push(config.AdminAccountId.extend({ required: requiredDef }));
                        break;
                }
            });
        this.hostValidation = ko.validatedObservable(hostValidationItems);
        // Comprehensive Validation
        const partnerValidationItems = [
            this.partner().Name,
            this.partner().HasFinanceApproved,
            this.partner().PartnerChannelId,
            this.partner().TechnicalContactName,
            this.partner().TechnicalContactEmail,
            this.partner().TechnicalContactPhone,
            this.partner().SmtpHost,
            this.partner().SmtpUsername,
            this.partner().SmtpPassword,
            this.partner().SmtpPort,
            this.partner().FromEmailAddress,
            this.partner().FromEmailAddressDisplayName,
            this.partner().BccEmailAddress,
            this.partner().BccEmailAddressDisplayName,
            this.selectedCurrency
        ].concat(hostValidationItems);
        this.partnerValidation = ko.validatedObservable(partnerValidationItems);
        this.partnerValidation.errors.showAllMessages(false);
        this.partnerActivationValidation.errors.showAllMessages(false);
    }

    defaultCurrencyPerBusinessEntity: any = {
        [BusinessEntities.USA]: Currencies.USD,
        [BusinessEntities.Manta]: Currencies.USD,
        [BusinessEntities.Netherlands]: Currencies.EUR,
        [BusinessEntities.Germany]: Currencies.EUR
    };

    setDefaultCurrency(): any {
        if (this.selectedCurrency()) {
            return;
        }
        const businessEntityId = this.partner().BusinessEntityId();
        this.selectedCurrency(this.defaultCurrencyPerBusinessEntity[businessEntityId]);
    }

    private setPartnerGroup(): void {
      if (!this.partner().PartnerId() && !this.selectedPartnerGroupIds) {
        return;
      }

      this.partnerGroupService.get(this.partner().PartnerId())
        .subscribe(partnerGroups => {
          if (partnerGroups.length > 0) {
            this.hasExistingPartnerGroup = true;
            this.selectedPartnerGroupIds = partnerGroups.map((x) => x.GroupId);
          }
        });
    }
}

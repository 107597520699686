import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WorkflowKeyValuePairService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/workflowkeyvaluepairs`;

  constructor(private http: HttpClient) { }

  save(workflowKeyValuePairs: Boo.Objects.WorkflowKeyValuePair[]): Observable<any> {
    return this.http.post(`${this.baseUrl}/save`, workflowKeyValuePairs);
  }

  getByWorkflowId(workflowId: number): Observable<Boo.Objects.WorkflowKeyValuePair[]> {
    return this.http.get<Boo.Objects.WorkflowKeyValuePair[]>(`${this.baseUrl}/getByWorkflowID/${workflowId}`, {
    });
  }

  getByTaskId(taskId: number): Observable<Boo.Objects.WorkflowKeyValuePair[]> {
    return this.http.get<Boo.Objects.WorkflowKeyValuePair[]>(`${this.baseUrl}/getByTaskId/${taskId}`);
  }

  getByTicketId(ticketId: number): Observable<Boo.Objects.WorkflowKeyValuePair[]> {
    return this.http.get<Boo.Objects.WorkflowKeyValuePair[]>(`${this.baseUrl}/getByTicketId/${ticketId}`);
  }
}

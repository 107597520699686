import { Component } from '@angular/core';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import System from 'framework/System';
import { finalize } from 'rxjs/operators';
import { CustomerService } from '../../../services/customer.service';
import { PartnerService } from '../../../services/partner.service';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';
import { forkJoin, of } from 'rxjs';

@Component({
  selector: 'app-components-customerservice-customersearch',
  templateUrl: './CustomerSearch.component.html'
})
export class CustomerSearchComponent implements IActivatable {
  public isCsr: any = ko.observable(false);
  public anyLanguageValue: any = 0;
  public anyStatusValue = 0;
  public page: any = ko.observable(1);
  public pageSize: any = ko.observable(10000);
  public isLoading: any = ko.observable(false);
  public selectedStatus: any = ko.observable(window.launchpad.config.StatusEnum.Active);
  public selectedLanguage: any = ko.observable(this.anyLanguageValue);
  public selectedFilter: any = ko.observable('All');
  public searchContextText: any = ko.observable();
  public languages: any = ko.observableArray([
    { Name: 'All Languages', LanguageId: this.anyLanguageValue }
  ]);
  public customerStatuses: any = ko.observableArray([
    { name: 'All Statuses', value: this.anyStatusValue },
    { name: 'Active', value: window.launchpad.config.StatusEnum.Active },
    { name: 'Canceled', value: window.launchpad.config.StatusEnum.Canceled }
  ]);
  public customers: any = ko.observableArray();
  public canSelectPartner = false;
  public selectedPartnerId = 0;
  public partners: Boo.Objects.PartnerMinimal[] = [];
  private liveAccountsOnly = true;

  constructor(
    private customerService: CustomerService,
    private partnerService: PartnerService,
    private sessionStorageService: SessionStorageService) { }

  public resultCount: any = ko.computed(() => {
    return this.customers ? this.customers().length : 0;
  });

  public hasResults: any = ko.computed(() => {
    return this.customers() && this.customers().length > 0;
  });

  public isMyCustomersSearch: any = ko.computed(() => this.selectedFilter() === 'My Customers');

  public isAllCustomerSearch: any = ko.computed(() => this.selectedFilter() === 'All');

  public openCustomer(customer: any): void {
    PriorityViewModel.showManageCustomer(customer.CustomerId);
  }

  public search(): any {
    if (this.pageValidation.isValid()) {

      this.isLoading(true);
      this.customers([]);
      this.searchContextText(this.searchText().trim());

      this.customerService.search(
        this.searchText().trim(),
        this.selectedPartnerId,
        this.page(),
        this.pageSize(),
        this.selectedLanguage(),
        this.liveAccountsOnly,
        this.isMyCustomersSearch(),
        this.selectedStatus() === this.anyStatusValue,
        this.selectedStatus() === window.launchpad.config.StatusEnum.Active)
        .pipe(
          finalize(() => this.isLoading(false))
        )
        .subscribe((data: any) => {
          if (data && data.length !== 0) {
            // use a proper line break (the SQL function that builds the string inserts \r\n between products)
            _.each(data,
              (customer: any) => {
                customer.Products = customer.Products.replace('\\r\\n', '<br />');
              });
            this.customers(data);
          } else {
            toastr.warning('No results.');
          }
        }, err => toastr.error(err));
    } else {
      this.pageValidation.errors.showAllMessages();
      toastr.error('Minimum search length is 3 characters');
    }
  }

  public canActivate(user: Boo.Objects.User, partner: Boo.Objects.Partner): any {
    this.canSelectPartner = partner.PartnerId === Boo.Objects.Enums.PartnerEnum.Boostability;
    return _.any(user.PartnerUsers,
      (item: any) => {
        return item.UserLevelId === window.launchpad.config.keys.adminUserLevelId || // Admin
          item.UserLevelId === window.launchpad.config.keys.managerUserLevelId || // Manager
          item.UserLevelId === window.launchpad.config.keys.customerServiceUserLevelId; // Customer Service
      });
  }

  public activate(options: any): JQueryPromise<void> {
    this.loadInitialData();
    this.pageValidation.errors.showAllMessages(false);
    if (options && options.length > 0 && options[0] !== '') {
      this.searchText(options[0]);
      this.search();
    }

    return System.emptyPromise();
  }

  public loadInitialData(): void {
    forkJoin([
      this.sessionStorageService.getStaticData(),
      this.canSelectPartner ? this.partnerService.getPartnerMinimals() : of([])])
        .subscribe(([staticData, partners]) => {
          _.each(staticData.Languages,
            (item) => {
              this.languages.push(item);
            });
          this.partners = this.liveAccountsOnly ? partners.filter((partner: Boo.Objects.PartnerMinimal) => partner.IsPartnerActive && partner.IsPartnerLive) : partners;
        },
        err => toastr.error('Cannot load static data.'));
  }

  private searchTextIsValid(val: any): any {
    return _.isString(val) && $.trim(val).length > 2;
  }

  public searchText: any = ko.observable().extend({
    validation: { validator: this.searchTextIsValid.bind(this), message: 'Minimum search length is 3 characters.' }
  });

  public pageValidation: any = ko.validatedObservable(this);
}

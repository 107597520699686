import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "onsiteInfoButtonCss",
  pure: false
})
export class OnsiteInfoButtonCssPipe implements PipeTransform {
  
  transform(websiteUrlTask: Boo.Objects.WebsiteUrlTask): string {
    let result = 'btn dropdown-toggle';

    if (!websiteUrlTask.DisplayStatusId) {
      return result += ' btn-primary disabled';
    }

    if (websiteUrlTask.DisplayStatusId === Boo.Objects.Enums.WebsiteUrlTaskDisplayStatuses.NotVerified) {
      result += ' btn-warning';
    } else if (websiteUrlTask.DisplayStatusId === Boo.Objects.Enums.WebsiteUrlTaskDisplayStatuses.Verified) {
      result += ' btn-success';
    } else if (websiteUrlTask.WorkflowStateId === Boo.Objects.Enums.WorkflowStates.ReadyForCsrApproval) {
      result += ' btn-danger'
    } else {
      result += ' btn-primary';
    }

    return result;
  }
}
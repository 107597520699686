<div class="form-group" [loadingOverlay]="isLoading">
  <div class="d-flex gap-2">
    <div class="w-25 align-self-end">
      <label class="control-label">Customer CMS</label>
      <ng-select [multiple]="false" [closeOnSelect]="true" [clearable]="false"
        [disabled]="!canEditContentManagementSystem" placeholder="Not Set" [(ngModel)]="contentManagementSystem"
        (change)="contentManagementSystemChanged()">
        <ng-option *ngFor="let option of contentManagementSystems" [value]="option" class="text-center">
          {{option.Name}}
        </ng-option>
      </ng-select>
    </div>
    <div class="align-self-end">
      <div>
        <button (click)="recheckCustomerCMS()" class="btn btn-warning">
          Check CMS
        </button>
      </div>
    </div>
    <label *ngIf="contentManagementSystem?.IsBlacklisted" class="text-warning align-self-end">SEO onsite implementation is not supported for this CMS.</label>
    <label *ngIf="!contentManagementSystem" class="text-warning align-self-end">Set CMS for this customer to access SEO onsite implementation.</label>
    <div *ngIf="showManagedByPartnerSelect" class="text-center ms-auto">
        <label class="control-label">Is the website managed by the partner?</label><br />
        <shared-components-customchoice [(value)]="isManagedByPartner" (valueChange)="managedByPartnerChanged()">
        </shared-components-customchoice>
    </div>
    <div *ngIf="showBoostabilityAccessOption" class="text-center ms-auto">
      <label class="control-label">Will the customer allow Boostability access?</label><br />
      <shared-components-customchoice [(value)]="isExternalAccessShared" (valueChange)="externalAccessSharedChanged()">
      </shared-components-customchoice>
    </div>
  </div>
  <div *ngIf="showDudaAdminSSO" class="mt-1">
    <app-duda-site-admin-sso-button [showSiteInfo]="true">
    </app-duda-site-admin-sso-button>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import System from 'framework/System';
import TodoList = app.tsmodels.interfaces.TodoList;
import TodoListValidation = app.tsmodels.interfaces.TodoListValidation;
import TodoValidation = app.tsmodels.interfaces.TodoValidation;
import Todo = Boo.Objects.Todo;
import TodoStatus = Boo.Objects.Todo.Enums.TodoStatus;
import IValidatedResult = app.interfaces.IValidatedResult;
import IValidationPublicApi = app.interfaces.IValidationPublicApi;
import TodoListActions = Boo.Objects.Todo.Enums.TodoListActions;
import CustomValidators from '../../app/../../shared/custom-validators';
import { UntypedFormControl, Validators, ValidatorFn } from '@angular/forms';
import { ValidationMsgService } from '../../../shared/validation-msg-service';

@Component({
  selector: 'app-components-todo-todolist',
  templateUrl: './TodoList.component.html'
})
export class TodoListComponent implements OnInit, IActivatable {
  selectedTodo: Boo.Objects.Todo.Todo;
  todoGroups: Todo.TodoGroup[];
  todoLists: TodoListValidation[];
  todos: TodoValidation[];
  todoModalId: string;
  validationApi: IValidationPublicApi;
  userId: number;
  isCustomerTodo = false;
  allowDelegation: boolean;

  private skipValidation: boolean;
  private validationErrors: string[] = [];

  constructor(private validationMsgService: ValidationMsgService) { }

  ngOnInit(): void {
    this.todoModalId = _.uniqueId('todoModal_');

    this.todos = _.chain(this.todoLists)
      .map((x: TodoList) => { return x.Todos; })
      .map((y: Boo.Objects.Todo.Todo[]) => { return y; })
      .flatten()
      .sortBy((z: Boo.Objects.Todo.Todo) => { return z.SortOrder; })
      .value() as TodoValidation[];

    this.todoGroups = [];

    const tempTodoGroups = _.chain(this.todos)
      .filter((todo: Todo.Todo) => !!todo.TodoGroupId)
      .pluck('TodoGroup')
      .uniq((todoGroup: Todo.TodoGroup) => todoGroup.TodoGroupId)
      .value();

    if (tempTodoGroups.length > 0) {
      const configs = ko.mapping.toJS(_.chain(this.todoLists)
        .map((x: TodoList) => x.TodoGroupConfigurations)
        .flatten()
        .value());

      const sortArray = _.chain(configs)
        .groupBy('TodoGroupId')
        .map((value, key) => {
          return {
            'todoGroupId': Number(key),
            'sortOrder': _.chain(value)
              .pluck('SortOrder')
              .reduce((a: number, b: number) => { return a + b; }, 0)
              .value()
          };
        })
        .sortBy('sortOrder')
        .pluck('todoGroupId')
        .value();

      this.todoGroups = _.sortBy(tempTodoGroups, (todoGroup: Todo.TodoGroup) => { return sortArray.indexOf(todoGroup.TodoGroupId); });
    }

    this.todoLists.forEach(list => {
      list.Todos.forEach(todo => {
        const validators: ValidatorFn[] = [];
        if (!this.skipValidation && list.TodoListAction === TodoListActions.Do) {
          validators.push(Validators.required, CustomValidators.todoStatusValidator(todo))
        }

        todo.StatusCtrl = new UntypedFormControl(todo.Status, validators);
        todo.StatusCtrl.valueChanges.subscribe(value => todo.Status = value);
      })
    });

    if (this.validationApi) {
      this.validationApi.add(
        {
          isValid: this.isValid.bind(this),
          validate: this.validate.bind(this)
        },
        this.todoModalId);
    }
  }

  canActivate(user: Boo.Objects.User, partner: Boo.Objects.Partner, params: ITodoListActivationParams): boolean {
    this.todoLists = params.todoLists as TodoListValidation[];
    this.userId = params.userId;
    this.isCustomerTodo = !!params.isCustomerTodo;
    this.validationApi = params.validationApi;
    this.skipValidation = params.skipValidation;
    this.allowDelegation = params.allowDelegation || false;
    return true;
  }

  isValid(): boolean {
    let isValid = true;
    this.validationErrors = [];
    this.todos.forEach(todo => {
      // validate todo statuses
      if (!todo.StatusCtrl.valid) {
        isValid = false;
        const err = Object.keys(todo.StatusCtrl.errors)[0];
        this.validationErrors.push(this.validationMsgService.getValidationMsg(err, null, null));
      }

      // validate todo notes
      todo.NotesWithValidation.forEach(note => {
        if (!note.ContentCtrl.valid) {
          isValid = false;
          this.validationErrors.push(note.ContentCtrl.errors.toString());
        }
      })
    });

    if (this.validationErrors.length) {
      toastr.error(this.validationErrors[0]);
    }

    return isValid;
  }

  validate(): JQueryPromise<IValidatedResult> {
    return System.resolvedPromise<IValidatedResult>({ isValid: this.isValid(), errorMessages: this.validationErrors });
  }

  todoNoteCount(todo: Todo.Todo): number {
    return _.filter(todo.TodoNotes, (note: Todo.TodoNote) => {
      return note.InsertedUserId !== launchpad.config.systemAdminUserId;
    }).length;
  }

  toggleTodo(todo: Todo.Todo): void {
    todo.Status = todo.Status === TodoStatus.Incomplete || todo.Status === TodoStatus.Complete ? TodoStatus.New : TodoStatus.Complete;
    todo.StatusDate = moment().utc().toDate();
    todo.StatusUserId = this.userId;
  }

  markTodoIncomplete(todo: Todo.Todo): void {
    todo.Status = TodoStatus.Incomplete;
    todo.StatusUserId = this.userId;
    $('#' + this.todoModalId).modal({
      backdrop: 'static',
      keyboard: false,
      show: true
    });
    this.selectedTodo = todo;
  }

  openTodo(todo: Todo.Todo): void {
    $('#' + this.todoModalId).modal({
      backdrop: 'static',
      keyboard: false,
      show: true
    });
    this.selectedTodo = todo;
  }

  hasNewNote(todoNotes: Todo.TodoNote[]): boolean {
    return _.some(todoNotes, (todoNote: Todo.TodoNote) => {
      return todoNote.IsEditable;
    });
  }
}

export interface ITodoListActivationParams {
  todoLists: TodoList[];
  userId: number;
  validationApi?: IValidationPublicApi;
  skipValidation?: boolean;
  isCustomerTodo: boolean;
  allowDelegation?: boolean;
}

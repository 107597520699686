import { Component } from '@angular/core';
import { WebsiteService } from '../../../services/website.service';
import Check from 'framework/Check';
import System from 'framework/System';
import { CustomerService } from '../../../services/customer.service';
import { TicketService } from '../../../services/ticket.service';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-websites-websitedomain',
  templateUrl: './WebsiteDomain.component.html'
})
export class WebsiteDomainComponent implements IActivatable {
    public isLoading: KnockoutObservable<boolean>;
    public isRequestedDomainAvailable: KnockoutObservable<boolean>;
    public requestedDomain: KnockoutObservable<string>;
    public isRegistrationSupported: boolean;
    
    private customerId: number;
    public website: app.tsmodels.interfaces.IWebsiteObservable;
    private requestedDomainValidation: KnockoutObservable<KnockoutObservable<any>[]>;

    constructor(
      private customerService: CustomerService,
      private ticketService: TicketService,
      private websiteService: WebsiteService) { }

    public activate(params: app.components.websites.interfaces.IWebsiteComponentActivateParams): JQueryPromise<void> {
        Check.isNotEmpty(params.customerId, 'customerId cannot be empty');
        Check.isNotEmpty(params.website, 'website cannot be empty');
        
        this.customerId = params.customerId;
        this.website = params.website;

        this.isLoading = ko.observable(false);
        this.isRequestedDomainAvailable = ko.observable(false);
        this.requestedDomain = ko.observable(this.website.Domain() || null).extend({
            required: {
                message: 'Domain is required'
            },
            validation: {
                validator: launchpad.utils.isValidDomain,
                message: 'Domain is invalid'
            }
        });
        this.requestedDomainValidation = ko.validatedObservable([this.requestedDomain]);
        this.isRegistrationSupported = false; // update below by calling service

        return this.doAsync(() => {
            // if this component is disabled, we don't need to check if registration is supported, so bail early.
            if (!this.isEnabled()) {
                return System.emptyPromise();
            }

            return Utils.wrapDfd(this.websiteService.isDomainRegistrationSupported(this.website.WebsiteId()))
                .then((isRegistrationSupported) => {
                    this.isRegistrationSupported = isRegistrationSupported === true;
                });
        });
    }

    public isEnabled(): boolean {
        return !this.website.IsDomainRegistered();
    }

    public clearRequestedDomain (): void {
        this.requestedDomain(null);
        this.isRequestedDomainAvailable(false);
    }

    public checkDomainAvailability(): void {
        if (!this.validateRequestedDomain()) {
            return;
        }

        this.doAsync(() => {
            return  Utils.wrapDfd(this.websiteService.checkDomainAvailability(this.website.WebsiteId(), this.requestedDomain().trim()))
                .then((isAvailable) => {
                    this.isRequestedDomainAvailable(isAvailable);
                    if (isAvailable) {
                        toastr.success('Domain is available.');
                    } else {
                        toastr.error('Domain is not available.');
                    }
                });   
        });
    }

    public registerDomain(): void {
        if (!this.validateRequestedDomain()) {
            return;
        }

        this.doAsync(() => {
            const domain = this.requestedDomain().trim();

            return Utils.wrapDfd(this.websiteService.registerDomain(this.website.WebsiteId(), domain))
                .then(() => {
                    // update observable for other things bound to it.
                    this.website.Domain(domain);
                    this.website.IsDomainRegistered(true);
                    this.website.IsDomainPreOwned(false);
                        
                    this.clearRequestedDomain();
                    toastr.success('Domain was registered.');
                    return Utils.wrapDfd(this.customerService.get(this.customerId));
                })
                .fail(() => {
                    toastr.error('Domain could not be registered.');
                });
        });
    }

    public saveDomainIsRegistered(): void {
        if (!this.validateRequestedDomain()) {
            return;
        }

        bootbox.confirm('You have to manually configure the pre-existing domain.', (result: boolean) => {
            if (result) {

                this.doAsync(() => {
                    const domain = this.requestedDomain().trim();
                    const websiteToSave: Boo.Objects.Websites.Website = ko.mapping.toJS(this.website);
                    websiteToSave.Domain = domain;
                    websiteToSave.IsDomainRegistered = true;
                    websiteToSave.IsDomainPreOwned = true;

                    return  Utils.wrapDfd(this.websiteService.save(websiteToSave))
                      .then(() => {
                        // update observable for other things bound to it.
                        this.website.Domain(domain);
                        this.website.IsDomainRegistered(true);
                        this.website.IsDomainPreOwned(true);
                        
                        this.clearRequestedDomain();
                        toastr.success('Pre-owned domain was set.');
                        return Utils.wrapDfd(this.customerService.get(this.customerId));
                      });
                });
            }
        });
    }

    private validateRequestedDomain(): boolean {
        if (!this.requestedDomainValidation.isValid()) {
            this.requestedDomainValidation.errors.showAllMessages();
            return false;
        }

        this.requestedDomainValidation.errors.showAllMessages(false);
        return true;
    }

    /**
     * Helper to do async request with standard loading indicator and error handling.
     */
    private doAsync(func: () => JQueryPromise<any>): JQueryPromise<any> {
        this.isLoading(true);
        return func().always(() => {
            this.isLoading(false);
        }).fail((msg) => toastr.error(msg));
    }
}

import { Component, OnInit } from '@angular/core';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { SessionStorageService } from '../../../services/session-storage.service';
import { PermissionService } from '../../../services/permission.service';
import { PartnerUserService } from '../../../services/partner-user.service';
import { OrganizationalTeamService } from '../../../services/organizational-team.service';
import { forkJoin } from 'rxjs';
import { environment } from '../../../../environments/environment';
import UserLevelEnum = Boo.Objects.Enums.UserLevelEnum;
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-components-manager-manageusers',
  templateUrl: './ManageUsers.component.html'
})
export class ManageUsersComponent implements OnInit {
  boostabilityPartnerId = 1;
  isLoading = false;
  searchString = '';
  searchPerformed = false;
  partnerId: number;
  selectedOrganizationalTeam: Boo.OrganizationalTeams.Models.OrganizationalTeam;
  permissions: Boo.Objects.Permission[] = [];
  activeTeams: Boo.OrganizationalTeams.Models.OrganizationalTeam[] = [];
  accessLevels: Boo.Objects.UserLevel[] = [
    { UserLevelId: UserLevelEnum.CustomerService, Name: 'Customer Service' },
    { UserLevelId: UserLevelEnum.Manager, Name: 'Manager' },
    { UserLevelId: UserLevelEnum.Sales, Name: 'Sales' },
    { UserLevelId: UserLevelEnum.Specialist, Name: 'SEO Specialist' }
  ];

  users: Boo.Objects.PartnerUserSearchResult[] = [];
  usersFiltered: Boo.Objects.PartnerUserSearchResult[] = [];
  organizationalTeamUsers: Boo.Objects.PartnerUserSearchResult[] = [];
  organizationalTeamUsersFiltered: Boo.Objects.PartnerUserSearchResult[] = [];
  selectedPermission: Boo.Objects.Permission = null;
  selectedStatus: boolean = null;
  selectedAccessLevel: Boo.Objects.UserLevel = null;

  constructor(
    private sessionStorageService: SessionStorageService,
    private permissionService: PermissionService,
    private partnerUserService: PartnerUserService,
    private organizationalTeamService: OrganizationalTeamService) { }

  ngOnInit(): void {
    forkJoin([
      this.sessionStorageService.getPartner(),
      this.permissionService.getUserAssignable(),
      this.organizationalTeamService.getActiveTeams(),
      this.sessionStorageService.getUser()
    ])
      .subscribe(([partner, permissions, activeTeams, user]) => {
        this.partnerId = partner.PartnerId;
        this.permissions = permissions.filter((permission) => user.UserPermissions.some((item) => item.PermissionId === permission.PermissionId));
        this.activeTeams = activeTeams;
      });
  }

  public activate(options: any): void {
    if (options && options.length > 0 && options[0] !== '') {
      this.searchString = options[0];
      this.searchUsers();
    }
  }

  public canActivate(user: Boo.Objects.User): boolean {
    return user.PartnerUsers.some((item) => item.UserLevelId === UserLevelEnum.Administrator || item.UserLevelId === UserLevelEnum.Manager);
  }

  public filterUsers(): void {
    this.usersFiltered = this.getFilteredUsers();
  }

  public searchUsers(): void {
    this.isLoading = true;
    forkJoin([
      this.partnerUserService.search(this.searchString.trim()),
      this.sessionStorageService.getUser()
    ])
    .pipe(finalize(() => this.isLoading = false))
      .subscribe(([partnerUsers, user]) => {
        if (!environment.testConfig.usernamesChangedForTesting && this.partnerId !== this.boostabilityPartnerId && !user.Username.includes('boostability.com')) {
          this.users = partnerUsers.filter((u) => !u.Username.toLowerCase().includes('boostability.com'));
        }
        else {
          this.users = partnerUsers;
        }

        this.usersFiltered = this.getFilteredUsers();
        this.searchPerformed = true;
      });
  }

  public searchForOrganizationalTeam(): void {
    this.isLoading = true;
    forkJoin([
      this.partnerUserService.searchByOrganizationalTeam(this.selectedOrganizationalTeam.OrganizationalTeamId), 
      this.sessionStorageService.getUser()
    ])
    .pipe(finalize(() => this.isLoading = false))
      .subscribe(([partnerUsers, user]) => {
        if (!environment.testConfig.usernamesChangedForTesting && this.partnerId !== this.boostabilityPartnerId && !user.Username.includes('boostability.com')) {
          this.organizationalTeamUsersFiltered = partnerUsers.filter((u) => !u.Username.toLowerCase().includes('boostability.com'));
        }
        else {
          this.organizationalTeamUsersFiltered = partnerUsers;
        }
      });
  }

  public addUser(): void {
    const activationOptions = {
      selectedUser: undefined as any
    };
    PriorityViewModel.show('app-components-manager-partneruser', activationOptions).done(() => {
      this.searchUsers();
    });
  }

  public editUser(user: Boo.Objects.User): void {
    const activationOptions = {
      selectedUser: user
    };
    PriorityViewModel.show('app-components-manager-partneruser', activationOptions).done(() => {
      this.searchUsers();
    });
  }

  private getFilteredUsers(): Boo.Objects.PartnerUserSearchResult[] {
    return this.users.filter((user) => {
      return (this.selectedAccessLevel === null || user.AccessLevels.includes(this.selectedAccessLevel.Name)) && (this.selectedStatus === null || user.IsActive === this.selectedStatus) && (this.selectedPermission === null || (user.Permissions && user.Permissions.includes(this.selectedPermission.Name)));
    });
  }
}

import { Component } from '@angular/core';
import System from 'framework/System';
import Check from 'framework/Check';
import job from 'app/tsmodels/job';
import { JobService } from '../../../services/job.service';
import { JobTypeService } from '../../../services/job-type.service';
import Utils from '../../../shared/utils';

class JobPriority {
  public Name: string;
  public JobPriorityId: number;
  constructor(name: string, jobPriorityId: number) {
    this.Name = name;
    this.JobPriorityId = jobPriorityId;
  }
}

@Component({
  selector: 'app-components-admin-addjob',
  templateUrl: './AddJob.component.html'
})
export class AddJobComponent implements IActivatable {
  protected priorityViewApi: app.interfaces.IPriorityViewPublicApi;
  title = 'Add Job';
  isLoading: KnockoutObservable<boolean> = ko.observable(false);
  selectedJobTypeId: KnockoutObservable<number> = ko.observable(undefined).extend({ required: true, message: 'Selection is required' });
  jobTypes: KnockoutObservableArray<Boo.Objects.Jobs.JobType> = ko.observableArray([]);
  selectedJobPriorityId: KnockoutObservable<number> = ko.observable(undefined).extend({ required: true, message: 'Selection is required' });
  jobPriorities: JobPriority[] = [
    new JobPriority('High', Boo.Objects.Jobs.Enums.JobPriorities.High),
    new JobPriority('Normal', Boo.Objects.Jobs.Enums.JobPriorities.Normal),
    new JobPriority('Low', Boo.Objects.Jobs.Enums.JobPriorities.Low),
    new JobPriority('Monitor', Boo.Objects.Jobs.Enums.JobPriorities.Monitor),
    new JobPriority('LongRun', Boo.Objects.Jobs.Enums.JobPriorities.LongRun),
    new JobPriority('SingleThreaded', Boo.Objects.Jobs.Enums.JobPriorities.SingleThreaded),
    new JobPriority('StaticSites', Boo.Objects.Jobs.Enums.JobPriorities.StaticSites),
    new JobPriority('Store', Boo.Objects.Jobs.Enums.JobPriorities.Store),
    new JobPriority('LocalListings', Boo.Objects.Jobs.Enums.JobPriorities.LocalListings)
  ];
  version: KnockoutObservable<number> = ko.observable(1).extend({ required: true, message: 'Version is required' });
  payload: KnockoutObservable<string> = ko.observable(undefined).extend({ required: true, message: 'Payload is required' });
  pageValidation: KnockoutObservable<any> = ko.validatedObservable([this.payload, this.version, this.selectedJobTypeId, this.selectedJobPriorityId]);

  constructor(
    private jobService: JobService,
    private jobTypeService: JobTypeService) { }

  activate(params: any): JQueryPromise<any> {
    Check.isNotEmpty(params, 'Jobs cannot be viewed without any options');
    Check.isNotEmpty(params.priorityViewApi, 'PriorityViewApi cannot be null');
    this.priorityViewApi = params.priorityViewApi;

    return Utils.wrapDfd(this.jobTypeService.getAll())
      .then(this.handleGetJobTypes.bind(this))
      .fail((displayMessage: string): void => {
        toastr.error(displayMessage);
      });
  }

  handleGetJobTypes(jobTypes: Boo.Objects.Jobs.JobType[]): JQueryPromise<any> {
    this.jobTypes(_.filter(jobTypes, (jt: Boo.Objects.Jobs.JobType) => {
      return jt.AllowManualEnqueue === true && jt.IsActive === true;
    }));
    this.pageValidation.errors.showAllMessages(false);
    return System.emptyPromise();
  }

  handleAddedJob(): void {
    this.payload = undefined;
    this.version = undefined;
    this.selectedJobTypeId = undefined;
    this.selectedJobPriorityId = undefined;
    toastr.success('Successfully added job');
    this.closeView();
  }

  addJob(): void {
    if (!this.pageValidation.isValid()) {
      this.pageValidation.errors.showAllMessages(true);
      return;
    }

    const newJob: Boo.Objects.Jobs.Job = new job();

    newJob.JobTypeId = this.selectedJobTypeId();
    newJob.Version = this.version();
    newJob.Payload = this.payload();
    newJob.JobPriorityId = this.selectedJobPriorityId();

    Utils.wrapDfd(this.jobService.enqueue(newJob))
      .then(this.handleAddedJob.bind(this))
      .fail((displayMessage: string): void => {
        toastr.error(displayMessage);
      });
  }

  closeView(): void {
    this.priorityViewApi.reject();
  }
}

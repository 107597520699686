import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import System from 'framework/System';
import { CustomerService } from '../../../services/customer.service';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-customerservice-managertools',
  templateUrl: './ManagerTools.component.html'
})
export class ManagerToolsComponent implements IActivatable {
    public title = 'Manager Tools';
    public isLoading: KnockoutObservable<boolean> = ko.observable(false);
    public verified: KnockoutObservable<boolean> = ko.observable(false);
    public customerId: KnockoutObservable<string> = ko.observable('').extend(launchpad.utils.validation.customerIdIsValid());
    public currentWebsite: KnockoutObservable<string> = ko.observable('');
    public newWebsite: KnockoutObservable<string> = ko.observable('').extend({
        required: { message: 'URL cannot be blank and must be a valid URL.' },
        validation: { validator: launchpad.utils.isValidUrl, message: 'Invalid URL. Please verify and try again.' }
    });
    private customerValidation: KnockoutObservable<any> = ko.validatedObservable([this.customerId]);
    private resetValidation: KnockoutObservable<any> = ko.validatedObservable([this.newWebsite]);
    private priorityViewApi: app.interfaces.IPriorityViewPublicApi;

    constructor(private customerService: CustomerService) { }

    public cancel(): JQueryPromise<void> {
        this.verified(false);
        this.currentWebsite('');
        this.newWebsite('');

        // close priority view if applicable (for ticket Review component)
        if (this.priorityViewApi) {
            this.priorityViewApi.reject();
        }

        return System.autoRejectedPromise();
    }

    public canActivate(user: Boo.Objects.User, partner: Boo.Objects.Partner, params: app.ticket.interfaces.ITicketActivateParams): boolean {
        return _.any(user.PartnerUsers, (item): boolean => {
            return item.UserLevelId === 1 || // Admin
                item.UserLevelId === 5 || // Manager
                item.UserLevelId === 6; // Customer Service
        });
    }

    public activate(params: any): JQueryPromise<void> {
        // store priorityViewApi to close later if applicable (for ticket Review component)
        if (params.priorityViewApi) {
            this.priorityViewApi = params.priorityViewApi;
        }

        // start with customerId already input (for ticket Review component)
        if (params.customerId) {
            this.customerId(params.customerId);
            return this.verify();
        }
        
        return System.emptyPromise();
    }

    public resetWebsite(): void {
        if (!this.resetValidation.isValid()) {
            this.resetValidation.errors.showAllMessages();
            return;
        }
        this.isLoading(true);

        const customerId: number = parseInt(this.customerId(), 10);
        Utils.wrapDfd(this.customerService.resetUrl(customerId, this.newWebsite()))
          .then(() => {
              const newWebsite: string = this.newWebsite();

              toastr.success('The website was successfully reset.');

              // reset controls to initial state
              this.customerId('');
              this.customerId.isModified(false);
              this.verified(false);
              this.currentWebsite('');
              this.newWebsite('');

              // resolve priorityViewApi if applicable (for ticket Review component)
              if (this.priorityViewApi) {
                  this.priorityViewApi.resolve({ url: newWebsite });
              }
          }).fail((err: string) => {
              toastr.error(err);
          }).always(() => {
              this.isLoading(false);
          });
    }

    public verify(): JQueryPromise<any> {
        if (!this.customerValidation.isValid()) {
            this.customerValidation.errors.showAllMessages();
            return;
        }

        this.isLoading(true);
        const customerId: number = parseInt(this.customerId(), 10);

        return Utils.wrapDfd(this.customerService.get(customerId))
            .then((data: any) => {
                if (data === null || data === undefined) {
                    toastr.warning('No results for the customer ID.');
                } else {
                    this.currentWebsite(data.Url);
                    this.verified(true);
                }
            }).fail((err: string) => {
                toastr.error(err);
            }).always(() => {
                this.isLoading(false);
            });
    }
}

export default class WorkRequestHelper {
  public static getCustomName(workRequest: Boo.Objects.Work.WorkRequest): string {
    return WorkRequestHelper.getValue(workRequest.KeyValuePairs, Boo.Objects.Enums.KeyEnum.CustomTaskName);
  }

  public static setCustomName(workRequest: Boo.Objects.Work.WorkRequest, value: string, userId: number): void {
    WorkRequestHelper.setValue(workRequest, Boo.Objects.Enums.KeyEnum.CustomTaskName, value, workRequest.WorkRequestId, userId);
  }

  public static getCustomDescription(workRequest: Boo.Objects.Work.WorkRequest): string {
    return WorkRequestHelper.getValue(workRequest.KeyValuePairs, Boo.Objects.Enums.KeyEnum.CustomTaskDescription);
  }

  public static setCustomDescription(workRequest: Boo.Objects.Work.WorkRequest, value: string, userId: number): void {
    WorkRequestHelper.setValue(workRequest, Boo.Objects.Enums.KeyEnum.CustomTaskDescription, value, workRequest.WorkRequestId, userId);
  }

  public static getCustomBillableMinutes(workRequest: Boo.Objects.Work.WorkRequest): number {
  return parseInt(WorkRequestHelper.getValue(workRequest.KeyValuePairs, Boo.Objects.Enums.KeyEnum.CustomTaskBillableMinutes), 10);
  }

  public static setCustomBillableMinutes(workRequest: Boo.Objects.Work.WorkRequest, value: number, userId: number): void {
    WorkRequestHelper.setValue(workRequest, Boo.Objects.Enums.KeyEnum.CustomTaskBillableMinutes, value as unknown as string, workRequest.WorkRequestId, userId);
  }

  public static getCustomTaskTypeName(workRequest: Boo.Objects.Work.WorkRequest): string {
  return WorkRequestHelper.getValue(workRequest.KeyValuePairs, Boo.Objects.Enums.KeyEnum.CustomTaskTaskTypeName);
  }

  public static setCustomTaskTypeName(workRequest: Boo.Objects.Work.WorkRequest, value: string, userId: number): void {
    WorkRequestHelper.setValue(workRequest, Boo.Objects.Enums.KeyEnum.CustomTaskTaskTypeName, value, workRequest.WorkRequestId, userId);
  }

  public static getCustomCreatedByUserId(workRequest: Boo.Objects.Work.WorkRequest): number {
  return parseInt(WorkRequestHelper.getValue(workRequest.KeyValuePairs, Boo.Objects.Enums.KeyEnum.CustomTaskCreatedByUserId), 10);
  }

  public static setCustomCreatedByUserId(workRequest: Boo.Objects.Work.WorkRequest, value: number, userId: number): void {
    WorkRequestHelper.setValue(workRequest, Boo.Objects.Enums.KeyEnum.CustomTaskCreatedByUserId, value ? value.toString() : '0', workRequest.WorkRequestId, userId);
  }

  public static getComment(workRequest: Boo.Objects.Work.WorkRequest): string {
  return WorkRequestHelper.getValue(workRequest.KeyValuePairs, Boo.Objects.Enums.KeyEnum.Comment);
  }

  public static setComment(workRequest: Boo.Objects.Work.WorkRequest, value: string, userId: number): void {
    WorkRequestHelper.setValue(workRequest, Boo.Objects.Enums.KeyEnum.Comment, value, workRequest.WorkRequestId, userId);
  }

  public static getExternalStatus(workRequest: Boo.Objects.Work.WorkRequest): string {
  return WorkRequestHelper.getValue(workRequest.KeyValuePairs, Boo.Objects.Enums.KeyEnum.ExternalStatus);
  }

  public static setExternalStatus(workRequest: Boo.Objects.Work.WorkRequest, value: string, userId: number): void {
    WorkRequestHelper.setValue(workRequest, Boo.Objects.Enums.KeyEnum.ExternalStatus, value, workRequest.WorkRequestId, userId);
  }

  public static setExternalId(workRequest: Boo.Objects.Work.WorkRequest, value: string, userId: number): void {
    WorkRequestHelper.setValue(workRequest, Boo.Objects.Enums.KeyEnum.ExternalId, value, workRequest.WorkRequestId, userId);
  }

  public static getWebsiteUrlId(workRequest: Boo.Objects.Work.WorkRequest): number {
    return parseInt(WorkRequestHelper.getValue(workRequest.KeyValuePairs, Boo.Objects.Enums.KeyEnum.WebsiteUrlId), 10);
  }

  public static setWebsiteUrlId(workRequest: Boo.Objects.Work.WorkRequest, value: number, userId: number): void {
    WorkRequestHelper.setValue(workRequest, Boo.Objects.Enums.KeyEnum.WebsiteUrlId, value ? value.toString() : '0', workRequest.WorkRequestId, userId);
  }

  public static getTaskNotifyOnComplete(workRequest: Boo.Objects.Work.WorkRequest): boolean {
    return (WorkRequestHelper.getValue(workRequest.KeyValuePairs, Boo.Objects.Enums.KeyEnum.TaskNotifyOnComplete).toLowerCase() === 'true');
  }

  public static setTaskNotifyOnComplete(workRequest: Boo.Objects.Work.WorkRequest, value: boolean, userId: number): void {
    WorkRequestHelper.setValue(workRequest, Boo.Objects.Enums.KeyEnum.TaskNotifyOnComplete, value ? 'true' : 'false', workRequest.WorkRequestId, userId);
  }

  public static getTaskActionUrl(workRequest: Boo.Objects.Work.WorkRequest): string {
    return WorkRequestHelper.getValue(workRequest.KeyValuePairs, Boo.Objects.Enums.KeyEnum.TaskActionUrl);
  }

  public static setTaskActionUrl(workRequest: Boo.Objects.Work.WorkRequest, value: string, userId: number): void {
    WorkRequestHelper.setValue(workRequest, Boo.Objects.Enums.KeyEnum.TaskActionUrl, value, workRequest.WorkRequestId, userId);
  }

  public static getOnsiteRecImplementationInstructions(workRequest: Boo.Objects.Work.WorkRequest): string {
    return WorkRequestHelper.getValue(workRequest.KeyValuePairs, Boo.Objects.Enums.KeyEnum.OnsiteRecImplementationInstructions);
  }

  public static setOnsiteRecImplementationInstructions(workRequest: Boo.Objects.Work.WorkRequest, value: string, userId: number): void {
    WorkRequestHelper.setValue(workRequest, Boo.Objects.Enums.KeyEnum.OnsiteRecImplementationInstructions, value, workRequest.WorkRequestId, userId);
  }

  public static getOnsiteCopyOrderReason(workRequest: Boo.Objects.Work.WorkRequest): string {
    return WorkRequestHelper.getValue(workRequest.KeyValuePairs, Boo.Objects.Enums.KeyEnum.OnsiteCopyOrderReason);
   }
  public static setOnsiteCopyOrderReason(workRequest: Boo.Objects.Work.WorkRequest, value: string, userId: number): void {
    WorkRequestHelper.setValue(workRequest, Boo.Objects.Enums.KeyEnum.OnsiteCopyOrderReason, value, workRequest.WorkRequestId, userId);
  }

  public static getOnsiteCopyOrderReasonOther(workRequest: Boo.Objects.Work.WorkRequest): string {
    return WorkRequestHelper.getValue(workRequest.KeyValuePairs, Boo.Objects.Enums.KeyEnum.OnsiteCopyOrderReasonOther);
  }
  public static setOnsiteCopyOrderReasonOther(workRequest: Boo.Objects.Work.WorkRequest, value: string, userId: number): void {
    WorkRequestHelper.setValue(workRequest, Boo.Objects.Enums.KeyEnum.OnsiteCopyOrderReasonOther, value, workRequest.WorkRequestId, userId);
  }

  public static getOnsiteCopyPageType(workRequest: Boo.Objects.Work.WorkRequest): string {
    return WorkRequestHelper.getValue(workRequest.KeyValuePairs, Boo.Objects.Enums.KeyEnum.OnsiteCopyPageType);
  }
  public static setOnsiteCopyPageType(workRequest: Boo.Objects.Work.WorkRequest, value: string, userId: number): void {
    WorkRequestHelper.setValue(workRequest, Boo.Objects.Enums.KeyEnum.OnsiteCopyPageType, value, workRequest.WorkRequestId, userId);
  }

  public static getOnsiteCopyImplementationInstructions(workRequest: Boo.Objects.Work.WorkRequest): string {
    return WorkRequestHelper.getValue(workRequest.KeyValuePairs, Boo.Objects.Enums.KeyEnum.OnsiteCopyImplementationInstructions);
  }
  public static setOnsiteCopyImplementationInstructions(workRequest: Boo.Objects.Work.WorkRequest, value: string, userId: number): void {
    WorkRequestHelper.setValue(workRequest, Boo.Objects.Enums.KeyEnum.OnsiteCopyImplementationInstructions, value, workRequest.WorkRequestId, userId);
  }

  public static getNumberOfContentPagesCreated(workRequest: Boo.Objects.Work.WorkRequest): number {
    return parseInt(WorkRequestHelper.getValue(workRequest.KeyValuePairs, Boo.Objects.Enums.KeyEnum.NumberOfContentPagesCreated), 10);
  }
  public static setNumberOfContentPagesCreated(workRequest: Boo.Objects.Work.WorkRequest, value: number, userId: number): void {
    WorkRequestHelper.setValue(workRequest, Boo.Objects.Enums.KeyEnum.NumberOfContentPagesCreated, value ? value.toString() : '0', workRequest.WorkRequestId, userId);
  }

  public static getWasContactUsPageCreated(workRequest: Boo.Objects.Work.WorkRequest): string {
    return WorkRequestHelper.getValue(workRequest.KeyValuePairs, Boo.Objects.Enums.KeyEnum.WasContactUsPageCreated);
  }
  public static setWasContactUsPageCreated(workRequest: Boo.Objects.Work.WorkRequest, value: string, userId: number): void {
    WorkRequestHelper.setValue(workRequest, Boo.Objects.Enums.KeyEnum.WasContactUsPageCreated, value, workRequest.WorkRequestId, userId);
  }

  public static getOnsiteCopyLinkBuildAfterPublish(workRequest: Boo.Objects.Work.WorkRequest): boolean {
    return (WorkRequestHelper.getValue(workRequest.KeyValuePairs, Boo.Objects.Enums.KeyEnum.OnsiteCopyLinkBuildAfterPublish).toLowerCase() === 'true');
  }
  public static setOnsiteCopyLinkBuildAfterPublish(workRequest: Boo.Objects.Work.WorkRequest, value: boolean, userId: number): void {
    WorkRequestHelper.setValue(workRequest, Boo.Objects.Enums.KeyEnum.OnsiteCopyLinkBuildAfterPublish, value ? 'True' : 'False', workRequest.WorkRequestId, userId);
  }

  public static getImplementationResponsibility(workRequest: Boo.Objects.Work.WorkRequest): Boo.OnsiteRecommendations.Models.Enums.Responsibilities {
    const responsibility = WorkRequestHelper.getValue(workRequest.KeyValuePairs, Boo.Objects.Enums.KeyEnum.ImplementationResponsibility);
    return responsibility ? parseInt(responsibility) : Boo.OnsiteRecommendations.Models.Enums.Responsibilities.NotSelected;
  }

  public static setImplementationResponsibility(workRequest: Boo.Objects.Work.WorkRequest, value: Boo.OnsiteRecommendations.Models.Enums.Responsibilities, userId: number): void {
    WorkRequestHelper.setValue(workRequest, Boo.Objects.Enums.KeyEnum.ImplementationResponsibility, value.toString(), workRequest.WorkRequestId, userId);
  }

  public static getIsBoostImplementing(workRequest: Boo.Objects.Work.WorkRequest): boolean {
    const boostIsImplementing = WorkRequestHelper.getValue(workRequest.KeyValuePairs, Boo.Objects.Enums.KeyEnum.IsBoostImplementing);
    return boostIsImplementing ? parseBoolean(boostIsImplementing) : null;
  }

  public static setIsBoostImplementing(workRequest: Boo.Objects.Work.WorkRequest, value: boolean, userId: number): void {
    WorkRequestHelper.setValue(workRequest, Boo.Objects.Enums.KeyEnum.IsBoostImplementing, value.toString(), workRequest.WorkRequestId, userId);
  }

  public static getGoogleAnalyticsTag(workRequest: Boo.Objects.Work.WorkRequest): string {
    return WorkRequestHelper.getValue(workRequest.KeyValuePairs, Boo.Objects.Enums.KeyEnum.GoogleAnalyticsTag);
  }

  public static setGoogleAnalyticsTag(workRequest: Boo.Objects.Work.WorkRequest, value: string, userId: number): void {
    WorkRequestHelper.setValue(workRequest, Boo.Objects.Enums.KeyEnum.GoogleAnalyticsTag, value, workRequest.WorkRequestId, userId);
  }

  public static getCreateGoogleAnalytics(workRequest: Boo.Objects.Work.WorkRequest): boolean {
    return (WorkRequestHelper.getValue(workRequest.KeyValuePairs, Boo.Objects.Enums.KeyEnum.CreateGoogleAnalytics).toLowerCase() === 'true');
  }

  public static setCreateGoogleAnalytics(workRequest: Boo.Objects.Work.WorkRequest, value: boolean, userId: number): void {
    WorkRequestHelper.setValue(workRequest, Boo.Objects.Enums.KeyEnum.CreateGoogleAnalytics, value.toString(), workRequest.WorkRequestId, userId);
  }

  public static getKeywordResearchWebsiteUrlIds(workRequest: Boo.Objects.Work.WorkRequest): number[] {
    return WorkRequestHelper.getValue(workRequest.KeyValuePairs, Boo.Objects.Enums.KeyEnum.KeywordResearchWebsiteUrlIds).split(',').map(x => parseInt(x, 10));
  }

  public static setKeywordResearchWebsiteUrlIds(workRequest: Boo.Objects.Work.WorkRequest, value: number[], userId: number): void {
    WorkRequestHelper.setValue(workRequest, Boo.Objects.Enums.KeyEnum.KeywordResearchWebsiteUrlIds, value.toString(), workRequest.WorkRequestId, userId);
  }

  private static getValue(workRequestKeyValuePairs: Boo.Objects.Work.WorkRequestKeyValuePair[], key: Boo.Objects.Enums.KeyEnum): string {
    const kvp = _.find(_.sortBy(workRequestKeyValuePairs, k => k.InsertedDate).reverse(), x => x.KeyValuePair.KeyId === key);
    return kvp ? kvp.KeyValuePair.Value : '';
  }

  private static setValue(workRequest: Boo.Objects.Work.WorkRequest, key: Boo.Objects.Enums.KeyEnum, value: string, workRequestId: number, userId: number): void {
    if (!workRequest.KeyValuePairs) {
      workRequest.KeyValuePairs = [];
    }

    const kvp = _.find(_.sortBy(workRequest.KeyValuePairs, k => k.InsertedDate).reverse(), x => x.KeyValuePair.KeyId === key);
    if (kvp && kvp.KeyValuePair) {
      kvp.KeyValuePair.Value = value;
    } else {
      const tkvp: Boo.Objects.Work.WorkRequestKeyValuePair = {
        WorkRequestId: workRequestId,
        KeyValuePair: {
          Key: {
            KeyId: key
          } as Boo.Objects.Key,
          KeyId: key,
          KeyValuePairId: 0,
          Value: value
        },
        InsertedDate: new Date(),
        InsertedUserId: userId,
        KeyValuePairId: 0,
        WorkRequestKeyValuePairId: 0
      };

      workRequest.KeyValuePairs.push(tkvp);
    }
  }
}


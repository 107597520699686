import { Component } from '@angular/core';
import CustomerAccountBase from '../customer-account-base';
import { SessionStorageService } from '../../../../services/session-storage.service';

/**
 * Extends the CustomerAccountBaseViewModel
 */
@Component({
	selector: 'app-components-customeraccount-sitecredentials',
	templateUrl: './site-credentials.component.html'
})
export class SiteCredentialsComponent extends CustomerAccountBase {
    public showPort: boolean;
    public canViewUrl: KnockoutComputed<boolean>;

    constructor(sessionStorageService: SessionStorageService) {
        super(sessionStorageService);
    }

    public activate(params: app.managecustomer.components.interfaces.CustomerAccountActivationParams): JQueryPromise<void> {
        // add some extra validation to customer accounts through a callback
        params.extraValidationCallbacks = [
            ...(params.extraValidationCallbacks || []), // keep original callbacks if any
            (customerAccount: CustomerAccountBaseObservable) => {
                return [
                    customerAccount.Port.extend({
                        validation: {
                            validator: (value: string): boolean => {
                                return this.validatePort(value);
                            },
                            message: 'Value must be a number between 1 and 65535'
                        }
                    }),
                    customerAccount.Url.extend({
                        required: {
                            message: 'A url is required to complete the login information.',
                            onlyIf: (): boolean => { return !!this.customerAccount.Password() || !!this.customerAccount.Username(); }
                        },
                        validation: {
                            validator: (value: string): boolean => {
                                return this.validateUrl(value);
                            },
                            message: 'Value must be a valid Url'
                        }
                    })
                ];
            }
        ];

        return super.activate(params)
            .then(() => {
                this.showPort = params.showPort || false;

                this.canViewUrl = ko.computed(
                    () => {
                        return this.customerAccount.Url() && this.customerAccount.Url.isValid();
                    },
                    this);
            });
    }

    /**
     * Validates that a port is in an acceptable range and that it is a number.
     * @param {string} val
     */
    private validatePort(val: string): boolean {
        if (!this.showPort) {
            return true;
        }

        let result = true;

        if ($.trim(val)) {
            const port = parseInt(val, 10);
            result = port > 0 && port <= 65535;
        }

        return result;
    }

    /**
     * Validates the URL format
     * @param {string} val
     */
    private validateUrl(val: string): boolean {
        let result = true;

        if ($.trim(val)) {
            result = launchpad.utils.isValidUrl(val);
        }

        return result;
    }
}

import { CustomerQuickUrlContentAnalysisService } from '../../../../services/customer-quick-url-content-analysis.service';
import TaskHelper from 'app/specialist/TaskHelper';
import { SeoContentSurveyService } from '../../../../services/seo-content-survey.service';
import Utils from '../../../../shared/utils';
import { DisplayModes } from 'app/models/enums/DisplayModes';
import Timer from '../../../../shared/models/timer';
import OnsiteRecommendationConstants from '../../../../shared/onsite-recommendation-constants';

type OnsiteRecommendationInput = Boo.Tasks.Inputs.Models.OnsiteRecommendationInput;

// This class holds all shared functionality for the Onsite Recommendation and Onsite Recommendation Review components.
export class OnsiteRecommendationBase {
    DisplayModes = DisplayModes;
    twentyFiveMegabytes = 26214400;
    isLoading: KnockoutObservable<boolean>;
    partnerName: string;
    companyName: string;
    fullUrl: string;
    currentTitle: KnockoutObservable<string>;
    currentDescription: KnockoutObservable<string>;
    newTitle: KnockoutObservable<string>;
    newDescription: KnockoutObservable<string>;
    currentHeadingTags: KnockoutObservable<string>;
    newH1Tag: KnockoutObservable<string>;
    newH2Tag: KnockoutObservable<string>;
    currentAltImageTags: KnockoutObservable<string>;
    newAltImageTag: KnockoutObservable<string>;
    timer: Timer = new Timer();
    base64Data: KnockoutObservable<string>;
    filename: KnockoutObservable<string>;
    file: KnockoutObservable<any>;
    files: Boo.Objects.File[] = [];
    keywordId: KnockoutObservable<number>;
    areaId: KnockoutObservable<number>;
    isAreaFirst: KnockoutObservable<boolean>;
    revisionInformation: KnockoutObservable<Boo.Objects.TaskRevisionInformation>;
    hasRevisions: KnockoutComputed<boolean>;
    textAnalysis: KnockoutObservableArray<Boo.Objects.KeywordSiteAnalysis>;
    notes: KnockoutObservableArray<Boo.Objects.TaskNote>;
    seoContentSurvey: KnockoutObservable<Boo.Objects.SeoContentSurvey>;
    noteCount: KnockoutComputed<number>;
    fileCount: KnockoutComputed<number>;
    hasComments: KnockoutComputed<boolean>;
    latestNote: KnockoutComputed<Boo.Objects.TaskNote>;
    showComment: boolean;
    comment: string;
    newTitleCharacterCount: KnockoutComputed<string>;
    newDescriptionCharacterCount: KnockoutComputed<string>;
    uploadMessage: KnockoutComputed<string>;
    csrNotes: string;
    showCsrNotes: boolean;
    keywordDensity: KnockoutComputed<any[]>;
    validation: KnockoutObservable<any>;
    currentAction: KnockoutObservable<Boo.Objects.Action>;
    input: OnsiteRecommendationInput;

    constructor(
        protected seoContentSurveyService: SeoContentSurveyService,
        protected customerQuickUrlContentAnalysisService: CustomerQuickUrlContentAnalysisService) { }

    protected initialize(currentAction: KnockoutObservable<Boo.Objects.Action>, input: OnsiteRecommendationInput): JQueryPromise<void> {
        this.currentAction = currentAction;
        this.input = input;
        this.isLoading = ko.observable(false);
        this.currentTitle = ko.observable('');
        this.currentDescription = ko.observable('');
        this.newTitle = ko.observable('');
        this.newDescription = ko.observable('');
        this.currentHeadingTags = ko.observable('');
        this.newH1Tag = ko.observable('');
        this.newH2Tag = ko.observable('');
        this.currentAltImageTags = ko.observable('');
        this.newAltImageTag = ko.observable();
        this.timer.start();
        this.base64Data = ko.observable('');
        this.filename = ko.observable();
        this.file = ko.observable();
        this.keywordId = ko.observable(0);
        this.areaId = ko.observable(0);
        this.isAreaFirst = ko.observable(false);

        this.revisionInformation = ko.observable(
            this.input.OnsiteRecommendation && this.input.OnsiteRecommendation.RevisionInformation ?
                this.input.OnsiteRecommendation.RevisionInformation :
                { NumberOfRevisions: 0, LastRevisionNote: '' });

        this.hasRevisions = ko.computed(() => {
            return this.revisionInformation && this.revisionInformation() && this.revisionInformation().NumberOfRevisions > 0;
        });

        this.textAnalysis = ko.observableArray([]);

        this.hasComments = ko.computed(() => {
            return this.input.TaskNotes.length > 0;
        });

        this.notes = ko.observableArray(this.hasComments() ? this.input.TaskNotes : []);

        this.noteCount = ko.computed(() => {
            return this.notes().length;
        });

        this.latestNote = ko.computed(() => {
            return this.hasComments() ? _.first(this.notes()) : null;
        });

        this.showComment = $.trim(TaskHelper.getComment(this.currentAction().Task)) !== '';
        this.comment = this.showComment ? TaskHelper.getComment(this.currentAction().Task).replace(/\n/g, '<br />') : '';

        this.newTitleCharacterCount = ko.computed(() => {
            return $.trim(this.newTitle()) !== '' ? `${this.newTitle().length} Characters` : 'Maximum Length is 256 Characters';
        });

        this.newDescriptionCharacterCount = ko.computed(() => {
            return $.trim(this.newDescription()) !== '' ? `${this.newDescription().length} Characters` : 'Maximum Length is 256 Characters';
        });

        this.uploadMessage = ko.computed(() => {
            // when it's a revision, make sure the user is aware that the file will be overwritten
            return this.input.OnsiteRecommendation && this.filename() && this.file() && this.file().size > 0 && this.hasRevisions()
                ? 'NOTE: This is a revision, and the current onsite changes file will be overwritten when the new one is uploaded and saved.'
                : '';
        });

        this.csrNotes = this.input.OnsiteRecommendationSettings ? this.input.OnsiteRecommendationSettings.Note : '';

        this.showCsrNotes = !!this.csrNotes;

        this.keywordDensity = ko.computed(() => {
            const densityList: any[] = [];

            const activeKeywords = _.filter(this.input.WebsiteUrl.Keywords, x => {
                return x.IsActive;
            });

            _.each(activeKeywords, x => {
                const kwd = {
                    keyword: launchpad.utils.getKeywordPhrase(x),
                    prominentTitle: false,
                    titleCount: 0,
                    prominentDescription: false,
                    descriptionCount: 0
                };

                const area = x.Area ? x.Area.Name : '';

                const newTitle = this.newTitle();
                if (newTitle) {
                    kwd.titleCount = this.keywordOccurrences(newTitle, x.Keyword.Name, area);

                    // it's "prominent" if the keyword is in the first chars of the title
                    const prominentCharCount = 50;
                    kwd.prominentTitle = this.keywordOccurrences(newTitle.substring(0, prominentCharCount), x.Keyword.Name, area) > 0;
                }

                const newDescription = this.newDescription();
                if (newDescription) {
                    kwd.descriptionCount = this.keywordOccurrences(newDescription, x.Keyword.Name, area);

                    // it's "prominent" if the keyword is in the first chars of the description
                    const prominentCharCount = 50;
                    kwd.prominentDescription = this.keywordOccurrences(newDescription.substring(0, prominentCharCount), x.Keyword.Name, area) > 0;
                }

                densityList.push(kwd);
            });

            return densityList;
        });

        this.files = this.input.OnsiteRecommendation ? this.input.Files : [];

        this.fileCount = ko.computed(() => {
            return this.files.length;
        });

        this.partnerName = this.input.Customer.Partner.Name;
        this.companyName = this.input.Customer.Name;
        this.fullUrl = this.input.WebsiteUrl.Url;

        this.newTitle.extend({
            required: { message: 'New Title is a required field.' },
            maxLength: OnsiteRecommendationConstants.TitleMaxLength,
            validation: { validator: this.keywordIsPresent.bind(this), message: 'Keyword must be present in the title.' }
        });

        this.newDescription.extend({
            required: { message: 'New Description is a required field.' },
            maxLength: OnsiteRecommendationConstants.MetaDescriptionMaxLength,
            validation: { validator: this.keywordIsPresent.bind(this), message: 'Keyword must be present in the description.' }
        });

        this.newH1Tag.extend({
            validation: { validator: this.isNewH1TagValid.bind(this), message: 'New H1 Tag is a required field.' },
            maxLength: OnsiteRecommendationConstants.H1MaxLength
        });

        this.newAltImageTag.extend({
            maxLength: OnsiteRecommendationConstants.AltImageTagMaxLength
        });

        this.filename.extend({
            validation: [
                { validator: this.onsiteChangesValidFilenameRequired.bind(this), message: 'A valid onsite changes file with a filename is required.' },
                { validator: this.onsiteChangesValidFileDataRequired.bind(this), message: 'A valid onsite changes file is required.' },
                { validator: this.onsiteChangesFileMustBe25MegabytesOrLess.bind(this), message: 'File size is too large. Files must be less than 25 MB.' }
            ]
        });

        this.validation = ko.validatedObservable([this.newTitle, this.newDescription, this.newH1Tag, this.newAltImageTag, this.filename]);

        this.isLoading(true);

        return $.when<any>(
            Utils.wrapDfd(this.customerQuickUrlContentAnalysisService.process(this.currentAction().Task.WebsiteUrlId)),
            Utils.wrapDfd(this.seoContentSurveyService.getByCustomerId(this.input.Customer.CustomerId)))
            .then((analysis: Boo.Objects.QuickUrlContentAnalysis, seoContentSurvey: Boo.Objects.SeoContentSurvey) => {
                this.loadAnalysis(analysis);
                this.input.Customer.SeoContentSurvey = seoContentSurvey;
                this.validation.errors.showAllMessages(false);
            })
            .fail((err) => toastr.error(err))
            .always(() => this.isLoading(false));
    }

    private isNewH1TagValid(tag: string): boolean {
        return $.trim(tag) !== '';
    }

    private keywordOccurrences(inText: string, keyword: string, area: string): number {
        // cleanup and special text handling
        keyword = this.sanitize($.trim(keyword || '').toLowerCase());
        area = this.sanitize($.trim(area || '').toLowerCase());
        inText = $.trim(inText || '').toLowerCase();

        const enteredText = this.sanitize(inText);

        const numberOfKeywords = this.occurrences(enteredText, keyword);
        // we allow area phrases to have commas
        const numberOfAreas = area ? this.occurrences(this.useSpaceForComma(enteredText), area) : 0;

        // if there's an area, the total number of existing keyword phrases
        // is the lowest number of combinations.
        // for example, if there are two keywords and three areas
        // present, that's effectively *two* complete keyword phrases.
        const howMany = area ? Math.min(numberOfAreas, numberOfKeywords) : numberOfKeywords;

        return howMany;
    }

    private sanitize(input: string): string {
        let text = this.useSpaceForHyphenAndDash(input);
        text = this.useSpaceForComma(text);
        text = this.consolidateSpaces(text);
        return text;
    }

    private useSpaceForComma(input: string): string {
        const text = input.replaceAll(',', ' ');
        return this.consolidateSpaces(text);
    }

    private useSpaceForHyphenAndDash(input: string): string {
        // we replace hyphens, dashes, and commas with spaces for comparison

        const enDash = '\u2013';
        const emDash = '\u2014';

        // replace does not replace all occurrences
        const text = input
            .replaceAll('-', ' ')
            .replaceAll(enDash, ' ')
            .replaceAll(emDash, ' ');

        return this.consolidateSpaces(text);
    }

    private consolidateSpaces(input: string): string {
        return input.replace(/\s\s+/g, ' ');
    }

    private occurrences(text: string, searchString: string): number {
        if (searchString.length === 0) {
            return text.length + 1;
        }

        let n = 0;
        let pos = 0;
        const step = searchString.length;

        while (true) {
            pos = text.indexOf(searchString, pos);

            if (pos >= 0) {
                n++;
                pos += step;
            } else {
                break;
            }
        }

        return n;
    }

    private keywordIsPresent(val: string): boolean {
        let shouldCheck = true;
        let isPresent = false;

        const analyses = this.textAnalysis();

        // underscore "each" logic doesn't allow breaking so we use a boolean instead
        // https://stackoverflow.com/questions/8779799/how-to-break-the-each-function-in-underscore-js
        _.each(analyses, (x: Boo.Objects.KeywordSiteAnalysis) => {
            if (shouldCheck) {
                isPresent = this.keywordOccurrences(val, x.Keyword, x.Area) > 0;

                if (isPresent) {
                    this.keywordId(x.KeywordId);
                    this.areaId(x.AreaId);
                    this.isAreaFirst(x.IsAreaFirst);

                    shouldCheck = false;
                }
            }
        });

        return isPresent;
    }

    private loadTitleTags(analysis: Boo.Objects.QuickUrlContentAnalysis): void {
        // Fill current title with task details or from analysis
        if (this.input.OnsiteRecommendation) {
            this.currentTitle(this.input.OnsiteRecommendation.TitleOld);
            this.newTitle(this.input.OnsiteRecommendation.TitleNew);
        } else if (analysis && analysis.CurrentTitle) {
            this.currentTitle(analysis.CurrentTitle);
        }
    }

    private loadDescriptionTags(analysis: Boo.Objects.QuickUrlContentAnalysis): void {
        if (this.input.OnsiteRecommendation) {
            this.currentDescription(this.input.OnsiteRecommendation.DescriptionOld);
            this.newDescription(this.input.OnsiteRecommendation.DescriptionNew);
        } else if (analysis && analysis.CurrentTitle) {
            this.currentDescription(analysis.CurrentDescription);
        }
    }

    private loadHeaderTags(analysis: Boo.Objects.QuickUrlContentAnalysis): void {
        let headers = '';
        if (analysis.CurrentHeadingTags) {
            _.sortBy(analysis.CurrentHeadingTags, (heading) => {
                return heading.HtmlTag;
            }).map((x) => {
                headers += x.HtmlTag + '\r\n';
            });
            this.currentHeadingTags(headers);
        }

        // Fill new headers if they exist
        if (this.input.OnsiteRecommendation) {
            this.newH1Tag(this.input.OnsiteRecommendation.H1New);
            this.newH2Tag(this.input.OnsiteRecommendation.H2New);
        }
    }

    private loadAltImageTags(analysis: Boo.Objects.QuickUrlContentAnalysis): void {
        // Fill in the Alt image Tags
        let images = '';

        _.sortBy(analysis.AltImageTags, (altImageTag) => {
            return altImageTag.Order;
        }).map((x) => {
            const imageParts = x.Source.split('/');
            if (imageParts.length > 0 && imageParts.indexOf('data:image') < 0) {
                images += x.HtmlTag + '\r\n';
            }
        });

        this.currentAltImageTags(images);

        if (this.input.OnsiteRecommendation) {
            this.newAltImageTag(this.input.OnsiteRecommendation.AltImageNew);
        }
    }

    private loadAnalysis(analysis: Boo.Objects.QuickUrlContentAnalysis): void {
        this.textAnalysis(analysis.Keywords);

        this.loadTitleTags(analysis);
        this.loadDescriptionTags(analysis);
        this.loadHeaderTags(analysis);
        this.loadAltImageTags(analysis);

        // If analysis could not be run because url was not accessible then show a message
        if (!analysis.WasUrlAccessible) {
            toastr.error('The Url was not accessible to analyze');
        }
    }

    private onsiteChangesValidFilenameRequired(filename: string): boolean {
        return ($.trim(filename) !== '' && filename.indexOf('.') >= 0);
    }

    private onsiteChangesValidFileDataRequired(): boolean {
        return $.trim(this.base64Data()) !== '';
    }

    private onsiteChangesFileMustBe25MegabytesOrLess(): boolean {
        return !(this.base64Data() && this.file && this.file().size > this.twentyFiveMegabytes);
    }
}

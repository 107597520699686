import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { AddCustomReportComponent } from './AddCustomReport/AddCustomReport.component';
import { WorkRequestEditComponent } from './WorkRequestEdit/WorkRequestEdit.component';
import { AddNoteComponent } from './AddNote/AddNote.component';
import { BasicInformationComponent } from './BasicInformation/BasicInformation.component';
import { BlogPostsComponent } from './BlogPosts/BlogPosts.component';
import { CloseWorkflowComponent } from './CloseWorkflow/CloseWorkflow.component';
import { CompletedTasksComponent } from './CompletedTasks/CompletedTasks.component';
import { ConnectedUsersComponent } from './ConnectedUsers/ConnectedUsers.component';
import { ConnectedUsersSidePanelComponent } from './ConnectedUsersSidePanel/ConnectedUsersSidePanel.component';
import { CustomerAccountGroupModalComponent } from './customer-account-group-modal/customer-account-group-modal.component';
import { WebsiteAccessModalComponent } from './WebsiteAccess/modal/website-access-modal.component';
import { CustomerContactPreferencesComponent } from './CustomerContactPreferences/CustomerContactPreferences.component';
import { CustomerWorkHistoryComponent } from './CustomerWorkHistory/CustomerWorkHistory.component';
import { CustomerInformationComponent } from './CustomerInformation/CustomerInformation.component';
import { CustomerInformationLimitedComponent } from './CustomerInformationLimited/CustomerInformationLimited.component';
import { CustomerSynopsisComponent } from './CustomerSynopsis/CustomerSynopsis.component';
import { CustomerTodoHistoryComponent } from './CustomerTodoHistory/CustomerTodoHistory.component';
import { CustomerUserComponent } from './CustomerUser/CustomerUser.component';
import { CustomerUserPhoneNumberComponent } from './CustomerUserPhoneNumber/CustomerUserPhoneNumber.component';
import { CustomerUsersComponent } from './CustomerUsers/CustomerUsers.component';
import { EditBlogPostComponent } from './EditBlogPost/EditBlogPost.component';
import { EditMerchantFieldsComponent } from './EditMerchantFields/EditMerchantFields.component';
import { LocalProfilesComponent } from './local-profiles/local-profiles.component';
import { LoginsComponent } from './logins/logins.component';
import { FilesComponent } from './Files/Files.component';
import { FloatingCustomerNoteComponent } from './FloatingCustomerNote/FloatingCustomerNote.component';
import { FloatingCustomerTodoListComponent } from './FloatingCustomerTodoList/FloatingCustomerTodoList.component';
import { ManageCustomerComponent } from './ManageCustomer.component';
import { BloggingDetailsComponent } from './SeoContentSurvey/BloggingDetails/BloggingDetails.component';
import { SurveyComponent } from './SeoContentSurvey/Survey/Survey.component';
import { SeoContentSurveyComponent } from './SeoContentSurvey/SeoContentSurvey.component';
import { MerchantFieldsComponent } from './MerchantFields/MerchantFields.component';
import { MerchantFieldsEditComponent } from './MerchantFieldsEdit/MerchantFieldsEdit.component';
import { NewTicketComponent } from './NewTicket/NewTicket.component';
import { NotesComponent } from './notes/notes.component';
import { NotesAndTicketsComponent } from './notes-and-tickets/notes-and-tickets.component';
import { OnsiteCopyCustomActionComponent } from './OnsiteCopyCustomAction/OnsiteCopyCustomAction.component';
import { OpenTicketsComponent } from './OpenTickets/OpenTickets.component';
import { PendingWorkComponent } from './PendingWork/PendingWork.component';
import { ReviseNoteComponent } from './ReviseNote/ReviseNote.component';
import { TicketGroupComponent } from './TicketGroup/TicketGroup.component';
import { UploadCustomerFileComponent } from './UploadCustomerFile/UploadCustomerFile.component';
import { WebsiteAccessComponent } from './WebsiteAccess/website-access.component';
import { WordpressWebsiteReportsComponent } from './WordpressWebsiteReports/WordpressWebsiteReports.component';
import { WorkflowTaskHistoryComponent } from './WorkflowTaskHistory/WorkflowTaskHistory.component';
import { EditImplementationInstructionsComponent } from './EditImplementationInstructions/EditImplementationInstructions.component';
import { EditRejectionInstructionsComponent } from './EditRejectionInstructions/EditRejectionInstructions.component';
import { AllocateWorkComponent } from './AllocateWork/AllocateWork.component';
import { SharedComponentsModule } from '../shared/shared-components.module';
import { ReadOnlyExternalAccessComponent } from './ReadOnlyExternalAccess/ReadOnlyExternalAccess.component';
import { ReadOnlyCustomerAccountGroupComponent } from './ReadOnlyCustomerAccountGroup/ReadOnlyCustomerAccountGroup.component';
import { ReadOnlyCustomerAccountComponent } from './ReadOnlyCustomerAccount/ReadOnlyCustomerAccount.component';
import { ReadOnlyBoostSiteAccountsComponent } from './ReadOnlyBoostSiteAccounts/ReadOnlyBoostSiteAccounts.component';
import { ReadOnlyBoostSiteAccountComponent } from './ReadOnlyBoostSiteAccount/ReadOnlyBoostSiteAccount.component';
import { WorkDefaultsComponent } from './AllocateWork/WorkDefaults/WorkDefaults.component';
import { WorkPeriodsComponent } from './AllocateWork/WorkPeriods/WorkPeriods.component';
import { PastPeriodsComponent } from './AllocateWork/PastPeriods/PastPeriods.component';
import { NonworkableTaskTypeFlagComponent } from './AllocateWork/Components/NonworkableTaskTypeFlag/NonworkableTaskTypeFlag.component';
import { TaskTypeFlagComponent } from './AllocateWork/Components/TaskTypeFlag/TaskTypeFlag.component';
import { TaskTypeSelectionComponent } from './AllocateWork/Components/TaskTypeSelection/TaskTypeSelection.component';
import { TaskDetailsComponent } from './TaskDetails/TaskDetails.component';
import { WebsitesModule } from '../websites/websites.module';
import { SeoCustomerAccountComponent } from './seo-customer-accounts/seo-customer-accounts.component';
import { GoogleAnalyticsCustomActionComponent } from './GoogleAnalyticsCustomAction/GoogleAnalyticsCustomAction.component';
import { KeywordResearchStrategyUpdateCustomActionComponent } from './keyword-research-strategy-update-custom-action/keyword-research-strategy-update-custom-action.component';
import { CustomerNotificationsComponent } from './customer-notifications/customer-notifications.component';
import { CustomerCampaignComponent } from './customer-campaign/customer-campaign.component';

@NgModule({
  declarations: [
    AddCustomReportComponent,
    WorkRequestEditComponent,
    AddNoteComponent,
    BasicInformationComponent,
    BlogPostsComponent,
    CompletedTasksComponent,
    ConnectedUsersComponent,
    ConnectedUsersSidePanelComponent,
    CustomerAccountGroupModalComponent,
    WebsiteAccessModalComponent,
    CustomerContactPreferencesComponent,
    CustomerWorkHistoryComponent,
    CustomerInformationComponent,
    CustomerInformationLimitedComponent,
    CustomerSynopsisComponent,
    CustomerTodoHistoryComponent,
    CustomerUserComponent,
    CustomerUserPhoneNumberComponent,
    CustomerUsersComponent,
    EditBlogPostComponent,
    EditMerchantFieldsComponent,
    LoginsComponent,
    LocalProfilesComponent,
    FilesComponent,
    FloatingCustomerNoteComponent,
    FloatingCustomerTodoListComponent,
    ManageCustomerComponent,
    BloggingDetailsComponent,
    SurveyComponent,
    SeoContentSurveyComponent,
    MerchantFieldsComponent,
    MerchantFieldsEditComponent,
    NewTicketComponent,
    NotesComponent,
    NotesAndTicketsComponent,
    OnsiteCopyCustomActionComponent,
    WorkflowTaskHistoryComponent,
    EditImplementationInstructionsComponent,
    EditRejectionInstructionsComponent,
    OpenTicketsComponent,
    ReviseNoteComponent,
    TicketGroupComponent,
    UploadCustomerFileComponent,
    WebsiteAccessComponent,
    WordpressWebsiteReportsComponent,
    ReadOnlyExternalAccessComponent,
    AllocateWorkComponent,
    ReadOnlyCustomerAccountGroupComponent,
    ReadOnlyCustomerAccountComponent,
    ReadOnlyBoostSiteAccountsComponent,
    ReadOnlyBoostSiteAccountComponent,
    WorkDefaultsComponent,
    WorkPeriodsComponent,
    PastPeriodsComponent,
    NonworkableTaskTypeFlagComponent,
    TaskTypeFlagComponent,
    TaskTypeSelectionComponent,
    TaskDetailsComponent,
    SeoCustomerAccountComponent,
    CloseWorkflowComponent,
    PendingWorkComponent,
    GoogleAnalyticsCustomActionComponent,
    KeywordResearchStrategyUpdateCustomActionComponent,
    CustomerNotificationsComponent,
    CustomerCampaignComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SharedComponentsModule,
    WebsitesModule
  ],
  exports: [
    NotesComponent,
    WebsiteAccessComponent
  ]
})
export class ManageCustomerModule { }

<div [loadingOverlay]="isLoading" class="row">
	<div [class]="jobTableWidthClass()">
		<div class="row">
			<div class="col-md-2">
				<div class="form-group">
					<label class="control-label">Job Type</label>
					<select [ngModel]="selectedJobTypeId()" (ngModelChange)="selectedJobTypeId($event)"
						[ngModelOptions]="{ standalone: true }" name="teams" class="form-select">
						<option [ngValue]="undefined">All Job Types</option>
						<option *ngFor="let option of jobTypes()" [ngValue]="option.JobTypeId">{{ option.Name }}</option>
					</select>
				</div>
			</div>
			<div class="col-md-2">
				<div class="form-group">
					<label class="control-label">Status</label>
					<select [ngModel]="selectedJobStatusId()" (ngModelChange)="selectedJobStatusId($event)"
						[ngModelOptions]="{ standalone: true }" name="teams" class="form-select">
						<option [ngValue]="undefined">All Statuses</option>
						<option *ngFor="let option of jobStatuses" [ngValue]="option.JobStatusId">{{ option.Name }}</option>
					</select>
				</div>
			</div>
			<div class="col-md-2">
				<div class="form-group">
					<label class="control-label">Filter by</label>
					<input [ngModel]="filterText()" (ngModelChange)="filterText($event)" [ngModelOptions]="{ standalone: true }"
						type="text" class="form-control" placeholder="ID or Hangfire ID" />
				</div>
			</div>
			<div class="col-md-3">
				<div class="form-group">
					<label class="control-label">Start Date</label>
					<input [dateTimePicker]="{ date: startDate }" class="form-control" />
				</div>
			</div>
			<div class="col-md-3">
				<div class="form-group">
					<label class="control-label">End Date</label>
					<input [dateTimePicker]="{ date: endDate }" class="form-control" />
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<ng-container *ngIf="isActivated()">
					<shared-components-odatagrid *ngIf="dataTableOptions"
						[options]="dataTableOptions"></shared-components-odatagrid>
					<ng-template #jobs_row_template let-ViewModel="ViewModel">
						<tr>
							<td>
								<button (click)="openJob(ViewModel)" class="btn btn-primary">
									<span>{{ViewModel.JobId + ' - ' + ViewModel.JobType.Name}}</span>
								</button>
							</td>
							<td>{{ViewModel.InsertedDate | dateText: dateFormat }}</td>
							<td>{{ViewModel.StatusDate | dateText: dateFormat }}</td>
							<td>{{getJobStatusName(ViewModel.JobStatusId)}}</td>
							<td>{{getPriorityName(ViewModel.JobPriorityId)}}</td>
							<td>{{ViewModel.HangfireJobId}}</td>
							<td style="max-width: 100px; white-space:nowrap;  overflow: hidden; text-overflow:ellipsis;">
								<span>{{ViewModel.Payload}}</span>
							</td>
						</tr>
					</ng-template>
				</ng-container>
			</div>
		</div>
	</div>
	<ng-container *ngIf="selectedJob()">
		<div class="col-md-4">
			<div class="card">
				<div class="card-header">
					<ng-container *ngIf="selectedJob()">
						<strong>Job #</strong>
						<span>{{selectedJob().JobId + ' - ' + selectedJob().JobType.Name}}</span>
						<button (click)="closeJob()" type="button" class="btn-close float-end"></button>
					</ng-container>
				</div>
				<div class="card-body">
					<ng-container *ngIf="selectedJob()">
						<div class="row">
							<div class="col-md-12">
								<div class="form-group">
									<label class="control-label">ID</label>
									<input [(ngModel)]="selectedJob().JobId" [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
										[hidden]="!(!canRequeueSelectedJob() && !canDeleteSelectedJob())" type="text" readonly="readonly"
										class="form-control" />
									<div [hidden]="!canDeleteSelectedJob()" class="input-group">
										<input [(ngModel)]="selectedJob().JobId" [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
											type="text" readonly="readonly" class="form-control" />
										<button (click)="deleteJob(selectedJob())" class="btn btn-danger">
											<i class="fas fa-external-link-square-alt"></i>
											<span>Delete Job</span>
										</button>
									</div>
									<div [hidden]="!canRequeueSelectedJob()" class="input-group">
										<input [(ngModel)]="selectedJob().JobId" [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
											type="text" readonly="readonly" class="form-control" />
										<button (click)="requeueJob(selectedJob())" class="btn btn-success">
											<i class="fas fa-external-link-square-alt"></i>
											<span>Re-enqueue Job</span>
										</button>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-1">
								<label class="control-label">Inserted</label>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<strong>
									<i class="far fa-calendar"></i>
								</strong>
								<span class="form-control-static">{{moment(selectedJob().InsertedDate).format(dateFormat)}}</span>
							</div>
						</div>
						<div class="row top10">
							<div class="col-md-1">
								<label class="control-label">Modified</label>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<strong>
									<i class="far fa-calendar"></i>
								</strong>
								<span class="form-control-static">{{moment(selectedJob().StatusDate).format(dateFormat)}}</span>
							</div>
							<div class="col-md-6 text-start">
								<ng-container *ngIf="selectedJob().JobStatusId === 1">
									<span class="badge bg-primary">
										<i class="fa fa-tag"></i>
										Pending
									</span>
								</ng-container>
								<ng-container *ngIf="selectedJob().JobStatusId === 2">
									<span class="badge bg-primary">
										<i class="fa fa-tag"></i>
										Processing
									</span>
								</ng-container>
								<ng-container *ngIf="selectedJob().JobStatusId === 3">
									<span class="badge bg-success">
										<i class="fa fa-tag"></i>
										Completed
									</span>
								</ng-container>
								<ng-container *ngIf="selectedJob().JobStatusId === 4">
									<span class="badge bg-warning">
										<i class="fa fa-tag"></i>
										Failed
									</span>
								</ng-container>
								<ng-container *ngIf="selectedJob().JobStatusId === 5">
									<span class="badge bg-danger">
										<i class="fa fa-tag"></i>
										Deleted
									</span>
								</ng-container>
								<ng-container *ngIf="selectedJob().JobStatusId === 6">
									<span class="badge bg-info">
										<i class="fa fa-tag"></i>
										Scheduled
									</span>
								</ng-container>
							</div>
						</div>
						<div class="row top10">
							<div class="col-md-6">
								<strong>
									<i class="far fa-clone"></i>
								</strong>
								<span>{{selectedJob().Version}}</span>
							</div>
							<div class="col-md-6 text-start">
								<span class="badge bg-primary">
									<i class="fa fa-flag"></i>
									<span>{{getPriorityName(selectedJob().JobPriorityId)}}</span>
								</span>
							</div>
						</div>
						<div class="row top10">
							<div class="col-md-12">
								<div class="form-group">
									<label class="control-label">Hangfire Job ID</label>
									<input [(ngModel)]="selectedJob().HangfireJobId"
										[ngModelOptions]="{ updateOn: 'blur', standalone: true }" type="text" readonly="readonly"
										class="form-control" />
								</div>
								<div class="form-group">
									<label class="control-label">Payload</label>
									<pre rows="4">{{selectedJob().Payload| prettyJson}}</pre>
								</div>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</ng-container>
</div>
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BacklinkParametersCorrectionService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/backlinkparametercorrections`;

  constructor(private http: HttpClient) { }

  get(ticketId: number): Observable<Boo.Objects.BacklinkParameterCorrection[]> {
    const params = new HttpParams().set('ticketId', ticketId);

    return this.http.get<Boo.Objects.BacklinkParameterCorrection[]>(`${this.baseUrl}/`, { params: params });
  }

  save(backlinkParameterCorrection: Boo.Objects.BacklinkParameterCorrection[]) {
    return this.http.post(`${this.baseUrl}/Save`, backlinkParameterCorrection);
  }
}

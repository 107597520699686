import { Component, ViewChild, TemplateRef, AfterViewInit, Input, OnInit } from '@angular/core';
import permissions from 'app/models/Permissions';
import timer from 'app/managecustomer/Timer';
import { CustomerNoteService } from '../../../services/customer-note.service';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import NotesPublicApi = app.managecustomer.components.interfaces.NotesPublicApi;
import { SessionStorageService } from '../../../services/session-storage.service';
import Constants from '../../../shared/constants';
import { forkJoin } from 'rxjs';
import System from 'framework/System';
type NotesOptions = app.managecustomer.components.interfaces.NotesOptions;

@Component({
    selector: 'app-components-managecustomer-notes',
    templateUrl: './notes.component.html'
})
export class NotesComponent implements OnInit, AfterViewInit {
    @Input() options: NotesOptions;
    @ViewChild('row_template') rowTemplate: TemplateRef<any>;
    canReviseNotes: boolean;
    customer: Boo.Objects.Customer;
    dataTable: app.components.interfaces.DataTablePublicApi;
    dataTableOptions: app.components.interfaces.DataTableOptions;
    isLoading = false;
    textFilter: string;
    contactTypeFilter?: number;
    partner: Boo.Objects.Partner;
    canActionComponent: boolean;
    contactTypes: Boo.Objects.ContactType[] = [];

    private editableContactType: Boo.Objects.Enums.ContactTypeEnum[] = [
        Boo.Objects.Enums.ContactTypeEnum.CampaignSummary,
        Boo.Objects.Enums.ContactTypeEnum.Email,
        Boo.Objects.Enums.ContactTypeEnum.InboundContact,
        Boo.Objects.Enums.ContactTypeEnum.OutboundCallContactMade,
        Boo.Objects.Enums.ContactTypeEnum.OutboundCallNoContact
    ];

    private isReadOnly: boolean;
    private customerId: number;
    //Notes no longer uses Knockout, but some of its child components still expect a CustomerObservable
    private customerObservable: CustomerObservable;

    constructor(
        private customerNoteService: CustomerNoteService,
        private sessionStorageService: SessionStorageService) { }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.configureODataLoader();
        }, 0);
    }

    ngOnInit(): void {
        this.textFilter = '';
        this.contactTypeFilter = null

        this.customer = this.options.customer;
        this.customerObservable = ko.mapping.fromJS(this.customer, Constants.CustomerMapping);
        this.partner = this.customer.Partner;
        this.isReadOnly = this.options.isReadOnly || false;
        this.canActionComponent = !this.isReadOnly;

        if (this.isReadOnly) {
            this.customerId = this.options.customerId;
        } else {
            this.customerId = this.customer.CustomerId;
        }

        if (this.options.apiCallback) {
            this.options.apiCallback(this.getPublicApi());
        }

        this.sessionStorageService.getStaticData()
            .subscribe((staticData: any) => {
                if (staticData && staticData.ContactTypes) {
                    this.contactTypes = staticData.ContactTypes;
                }
            });
        
        forkJoin([this.sessionStorageService.getUser(), this.sessionStorageService.getPartner()])
            .subscribe(([user, partner]) => {
                this.canReviseNotes = (launchpad as Launchpad).hasPermission(partner, user.PartnerUsers, permissions.CanReviseNotes)
            });
    }

    addNote(): void {
        PriorityViewModel.show(
            'app-components-managecustomer-addnote',
            { customer: this.customerObservable, parent: this.createParentContext(), partner: this.partner })
            .done(() => {
                this.dataTable.reload();
            });
    }

    search(): void {
        this.dataTable.reload();
    }

    clear(): void {
        this.textFilter = '';
        this.contactTypeFilter = null;
        this.dataTable.reload();
    }

    reviseNote(note: Boo.Objects.CustomerNote): void {
        PriorityViewModel.show('app-components-managecustomer-revisenote', { note: note, customer: this.customerObservable, parent: this.createParentContext() })
            .done(() => {
                this.dataTable.reload();
            });
    }

    isNoteEditable(note: Boo.Objects.CustomerNote): boolean {
        return this.editableContactType.indexOf(note.ContactTypeId) !== -1;
    }

    openTicket(note: Boo.Objects.CustomerNote): void {
        timer.resetUIActivityTime();

        const ticketOptions = {
            customer: this.customerObservable,
            ticketId: note.TicketId
        };

        timer.resetUIActivityTime();

        PriorityViewModel.show('app-components-ticket', ticketOptions);
    }

    private configureODataLoader(): void {
        this.dataTableOptions = {
            loader: (page, pageSize, sortOrder): JQueryPromise<framework.data.IPageResult<Boo.Objects.CustomerNote>> => {
                this.isLoading = true;
                if (!sortOrder) {
                    sortOrder = 'InsertedDate desc';
                }

                const query = this.customerNoteService.get(this.customerId)
                    .orderBy(sortOrder)
                    .page(page, pageSize);

                const term = this.textFilter.trim();
                const filters = [];
                if (term) {
                    filters.push(`contains(Text, '${term}')`);
                }

                const contactTypeId = this.contactTypeFilter;
                if (contactTypeId) {
                    filters.push(`(ContactTypeId eq ${contactTypeId})`);
                }

                if (filters.length > 0) {
                    query.filter(filters.join(' and '));
                }

                return query.load().always(() => this.isLoading = false);
            },
            columns: [
                { id: 'inserted_date', value: 'InsertedDate', name: 'Date' },
                { id: 'ticketid', value: 'TicketId', name: 'Ticket ID' },
                { id: 'Text', value: 'Text', name: 'Note' },
                { id: 'userfullname', value: 'UserFullName', name: 'User' },
                { id: 'contacttype.name', value: 'ContactTypeName', name: 'Contact Type' },
                { id: 'revise', name: 'Revise', canSort: false }
            ],
            pageSize: 5,
            rowTemplate: this.rowTemplate,
            apiCallback: (api): void => {
                this.dataTable = api;
            }
        };
    }

    private getPublicApi(): NotesPublicApi {
        return {
            reloadNotes: (): JQueryPromise<void> => this.dataTable.reload()
        };
    }

    //The add/revise note components are normally passed their parent, which is expected to have these members.
    //Rather then implementing these here we'll just pass this object.
    private createParentContext(): any {
        return {
            customer: this.customerObservable,
            partner: this.customerObservable.Partner,
            refreshCustomer: () => { System.noop; }
        }
    }
}

import { Component } from '@angular/core';
import System from 'framework/System';

@Component({
	selector: 'app-components-specialist-actioncomponents-businessdirectories',
	templateUrl: './BusinessDirectories.component.html'
})
export class BusinessDirectoriesComponent implements IActionWorkspace, IActivatable {
    public actionViewModel: KnockoutObservable<IActionWorkspace>;
    public currentAction: KnockoutObservable<Boo.Objects.Action>;
    public directoryUrl: string;
    public directoryDomain: string;
    public title = 'Business Directories';
    public verticalName: string;
    public siteInformation: string;

    public activate(params: IActionWorkspaceActivationParams): JQueryPromise<any> {

        params.controlViewModel(this);
        this.actionViewModel = ko.observable(null);
        this.currentAction = params.currentAction;

        this.verticalName = this.currentAction().TaskDetails.Vertical.Name;
        this.siteInformation = this.getSiteInformation();
        this.directoryDomain = new URI(this.currentAction().TaskDetails.LinkLocation.Url).normalizeHostname().hostname();
        this.directoryUrl = this.currentAction().TaskDetails.LinkLocation.Url;

        if (!this.currentAction().TaskDetails.LinkLocation) {
            toastr.error('There is no location to submit to... please mark this task complete.');
            return System.emptyPromise();
        }

        return System.emptyPromise();
    }

    public isStepReadyToReject(): boolean {
        return true;
    }

    public isStepReadyToSave(): boolean {
        return true;
    }

    public isStepReadyToComplete(): boolean {
        return true;
    }

    public isStepReadyToDelete(): boolean {
        return true;
    }

    private getSiteInformation(): string {
        const keywords = _.filter(this.currentAction().TaskDetails.WebsiteUrl.Keywords, (keyword) => {
            return keyword.IsActive;
        }).map((keyword) => { return launchpad.utils.getKeywordPhrase(keyword); }).join();
        const url = this.currentAction().TaskDetails.WebsiteUrl.Url;
        const title = this.currentAction().Customer.Heading ? _.unescape(this.currentAction().Customer.Heading) : (this.currentAction().TaskDetails.WebsiteUrl.Title ? _.unescape(this.currentAction().TaskDetails.WebsiteUrl.Title) : 'No page TITLE defined.');
        const description = this.currentAction().Customer.Description ? _.unescape(this.currentAction().Customer.Description) : (this.currentAction().TaskDetails.WebsiteUrl.Description ? _.unescape(this.currentAction().TaskDetails.WebsiteUrl.Description) : 'No META DESCRIPTION defined.');

        return `Url:\r\n${url}\r\n\r\nTitle:\r\n${title}\r\n\r\nDescription:\r\n${description}\r\n\r\nKeywords:\r\n${keywords}`;
    }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WorkRequestService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/workrequests`;

  constructor (private http: HttpClient) { }

  getByCustomerId(customerId: number): Observable<Boo.Objects.Work.WorkRequest[]> {
    return this.http.get<Boo.Objects.Work.WorkRequest[]>(`${this.baseUrl}/byCustomerId/${customerId}`);
  }

  get(workRequestId: number): Observable<Boo.Objects.Work.WorkRequest> {
    const params = new HttpParams()
      .set('workRequestId', workRequestId);
    return this.http.get<Boo.Objects.Work.WorkRequest>(`${this.baseUrl}/`, {params: params});
  }
}
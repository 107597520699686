import { Component } from '@angular/core';
import { TaskService } from '../../../services/task.service';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-specialist-lockedtasks',
  templateUrl: './LockedTasks.component.html'
})
export class LockedTasksComponent {

  isLoading: KnockoutObservable<boolean> = ko.observable(false);
  customerId: KnockoutObservable<number> = ko.observable(null);

  assignedUsersOptions: KnockoutObservableArray<string> = ko.observableArray([]);
  assignedUserFilter: KnockoutObservable<string> = ko.observable();
  assignedUserFilterCaption = 'Select a User...';

  actionTitleOptions: KnockoutObservableArray<string> = ko.observableArray([]);
  actionTitleFilter: KnockoutObservable<string> = ko.observable(undefined);
  actionTitleFilterCaption = 'Select a Task Type...';

  lockedActions: Boo.Objects.LockedTask[] = [];

  searchTextExists: KnockoutComputed<boolean> = ko.computed(() => {
    let searched = false;

    if (this.assignedUserFilter() || this.actionTitleFilter() || (this.customerId() !== undefined)) {
      searched = true;
    }

    return searched;
  });

  constructor(private taskService: TaskService) { }

  UnlockCustomer(data: Boo.Objects.LockedTask): void {
    this.isLoading(true);
    const taskId: number = data.TaskId;

    Utils.wrapDfd(this.taskService.unlockAndUnassign(taskId))
      .then(() => {
        toastr.success('The Action was successfully unlocked.');
        this.reloadLockedActions();
      })
      .fail((displayMessage: string) => {
        toastr.error(displayMessage);
      })
      .always(() => {
        this.isLoading(false);
      });
  }

  openCustomer(lockedTask: Boo.Objects.LockedTask): void {
    PriorityViewModel.showManageCustomer(lockedTask.CustomerId);
  }

  clearSearch(): void {
    this.customerId(null);

    this.assignedUserFilter(undefined);
    this.actionTitleFilter(undefined);

    this.lockedActions = [];
  }

  reloadLockedActions(): void {
    this.isLoading(true);
    Utils.wrapDfd(this.taskService.getLocked(this.customerId() ?? 0, this.assignedUserFilter() ?? '', this.actionTitleFilter() ?? ''))
      .then((result: Boo.Objects.LockedTask[]) => {
        this.lockedActions = result;
      })
      .fail((displayMessage: string) => {
        toastr.error(displayMessage);
      })
      .always(() => {
        this.isLoading(false);
      });
  }

  canActivate(user: Boo.Objects.User): boolean {
    return _.any(user.PartnerUsers, (partnerUser: Boo.Objects.PartnerUser) => {
      return partnerUser.UserLevelId === launchpad.config.keys.adminUserLevelId
        || partnerUser.UserLevelId === launchpad.config.keys.managerUserLevelId;
    });
  }

  activate(): JQueryPromise<any> {
    this.isLoading(true);
    return $.when(
      Utils.wrapDfd(this.taskService.getLockedAssignedUsers()),
      Utils.wrapDfd(this.taskService.getLockedTaskTypeTitle()))
      .then((assignedUserResults: any[], lockedTaskTypeResults: any[]) => {
        this.assignedUsersOptions((assignedUserResults as string[]));
        this.actionTitleOptions((lockedTaskTypeResults as string[]));
      }).fail((displayMessage: string) => {
        toastr.error(displayMessage);
      }).always(() => {
        this.isLoading(false);
      });
  }
}

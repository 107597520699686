import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TicketGroupService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/ticketgroups`;

  constructor(private http: HttpClient) { }

  getActive(customerId: number): Observable<Boo.Objects.TicketGroup[]> {
    const params = new HttpParams().set('customerId', customerId);

    return this.http.get<Boo.Objects.TicketGroup[]>(`${this.baseUrl}/getActive`, { params: params });
  }
}

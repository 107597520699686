import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import actionTimer from 'app/components/actiontimer';
import { ActionService } from '../../../services/action.service';
import { taskTypeActionFeatures } from './TaskTypeActionFeatures';
import Utils from '../../../shared/utils';
import { CustomerTouchService } from '../../../services/customer-touch.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TaskService } from '../../../services/task.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SessionStorageService } from '../../../services/session-storage.service';
import RejectionFactory from '../../../factories/RejectionFactory';

type OutputWithRejectionReason = Boo.Tasks.Outputs.Models.OutputWithRejectionReason;

@Component({
  selector: 'app-components-specialist-actionworkspace',
  templateUrl: './ActionWorkspace.component.html'
})
export class ActionWorkspaceComponent implements OnInit {
  window: any = window;
  launchpad: any = launchpad;
  dfd: any;
  skipReason: any;
  actionTimer: any;
  viewModelUpdateTimer: any;
  // Observables
  isReadOnly: any = ko.observable(false);
  title: string;
  currentAction: any = ko.observable();
  isLoading: any = ko.observable(false);
  CustomerUrl: any = ko.observable();
  readyToComplete: any = ko.observable(false);
  customerId: any = ko.observable();
  activeChild: any = ko.observable();
  taskTime: any = ko.observable(0);
  canDelete: any = ko.observable(true);
  canWorkblock = false;
  showCustomerCountry = false;
  showPartnerName = false;
  showCustomerName = false;
  showTaskId = false;
  workblockReason: UntypedFormControl = new UntypedFormControl(null);
  workblockNote: UntypedFormControl = new UntypedFormControl('');
  currentUser: Boo.Objects.User;

  rejectionCtrl: UntypedFormGroup = new UntypedFormGroup({
    rejectionReason: new UntypedFormControl('', [Validators.required, Validators.maxLength(4000)]),
    rejectionTypes: new UntypedFormControl([])
  });

  actionIsLoaded: any = ko.computed(() => {
    return (_.isObject(this.activeChild()) &&
      ko.isObservable(this.activeChild().currentAction) &&
      _.isObject(this.activeChild().currentAction()) &&
      _.isObject(this.activeChild().currentAction().Task) &&
      _.isObject(this.activeChild().currentAction().Task.TaskTypeSummary) &&
      _.isObject(this.activeChild().currentAction().TaskDetails) &&
      _.isObject(this.activeChild().currentAction().TaskDetails.WebsiteUrl) &&
      $.trim(this.activeChild().currentAction().Task.TaskTypeSummary.ControlName) !== '');
  });

  canTaskTypeSkip: any = ko.computed(() => {
    let result = true;
    if (this.actionIsLoaded() &&
      this.activeChild().currentAction() &&
      this.activeChild().currentAction().Task &&
      this.activeChild().currentAction().Task.TaskTypeSummary) {
      result = this.activeChild().currentAction().Task.TaskTypeSummary.IsSkippable;
    }
    return result;
  });

  canReject: any = ko.computed(() => {
    let result = false;
    if (this.actionIsLoaded() && this.activeChild().currentAction()) {
      const taskTypeId = this.actionIsLoaded() && this.activeChild().currentAction().Task.TaskTypeEnum;
      result = _.contains(taskTypeActionFeatures.rejectable, taskTypeId);
    }
    return result;
  });

  canRejectWithoutProvidingReason: any = ko.computed(() => {
    let result = false;
    if (this.actionIsLoaded() && this.activeChild().currentAction()) {
      const taskTypeId = this.actionIsLoaded() && this.activeChild().currentAction().Task.TaskTypeEnum;
      result = _.contains(taskTypeActionFeatures.rejectableWithoutReason, taskTypeId);
    }
    return result;
  });

  rejectableByRejectionType = false;

  showTimer: any = ko.computed(() => {
    let show = false;
    if (this.actionIsLoaded()) {
      show = _.contains(taskTypeActionFeatures.hasTimer, this.activeChild().currentAction().Task.TaskTypeEnum);
    }
    return show;
  });

  canUnlockAndUnassign: any = ko.computed(() => {
    let result = false;
    if (this.actionIsLoaded() && this.activeChild().currentAction()) {
      // Persistable tasks can never be unlocked and unassigned and are handled separately from this array
      const taskTypeIdsThatCanNotUnlockAndUnassign = _.union(taskTypeActionFeatures.canNotUnlockAndUnassign, window.launchpad.config.implementationCustomTaskTypes);
      const taskTypeId = this.actionIsLoaded() && this.activeChild().currentAction().Task.TaskTypeEnum;
      result = _.contains(taskTypeIdsThatCanNotUnlockAndUnassign, taskTypeId) === false;
    }
    return result;
  });

  isPersistable: any = ko.computed(() => {
    return this.actionIsLoaded() && this.activeChild().currentAction().Task.TaskTypeSummary.IsPersistable;
  });

  actionName: any = ko.computed(() => {
    return this.actionIsLoaded() && this.activeChild().currentAction().Task.TaskTypeSummary.Name;
  });

  actionTaskDate: any = ko.computed(() => {
    let result = '';
    if (this.actionIsLoaded()) {
      result = moment(this.activeChild().currentAction().Task.TaskDate).format('MM/DD/YYYY');
    }
    return result;
  });

  estimatedTime: any = ko.computed(() => {
    let result = '';
    if (this.actionIsLoaded() === true) {
      result = this.activeChild().currentAction().Task.TaskTypeSummary.EstimatedMinutes + ' Minutes';
    }
    return result;
  });

  customerUrl: any = ko.computed(() => {
    let customerUrl = '';
    if (this.actionIsLoaded()) {
      customerUrl = this.activeChild().currentAction().TaskDetails.WebsiteUrl.Url;
    }
    return customerUrl;
  });

  customerName: any = ko.computed(() => {
    let customerName = '';
    if (this.actionIsLoaded() && this.activeChild().currentAction().Customer) {
      customerName = this.activeChild().currentAction().Customer.Name;
    } else if (this.activeChild()?.currentAction()?.Task?.Input?.Customer) {
      customerName = this.activeChild().currentAction().Task.Input.Customer.Name;
    }
    return customerName;
  });

  customerLanguage: any = ko.computed(() => {
    let customerLanguage = 'N/A';
    if (this.actionIsLoaded() && this.activeChild().currentAction().Customer && this.activeChild().currentAction().Customer.Language) {
      customerLanguage = this.activeChild().currentAction().Customer.Language.Name;
    } else if (this.activeChild()?.currentAction()?.Task?.Input?.Customer) {
      customerLanguage = this.activeChild().currentAction().Task.Input.Customer.Language.Name;
    }
    return customerLanguage;
  });

  customerCountry: any = ko.computed(() => {
    let customerCountry = 'N/A';
    if (this.actionIsLoaded() && this.activeChild().currentAction().Customer && this.activeChild().currentAction().Customer.Country) {
      customerCountry = this.activeChild().currentAction().Customer.Country.Name;
    } else if (this.activeChild()?.currentAction()?.Task?.Input?.Customer) {
      customerCountry = this.activeChild().currentAction().Task.Input.Customer.Country.Name;
    }
    return customerCountry;
  });

  customerVertical: any = ko.computed(() => {
    let customerVertical = 'N/A';
    if (this.actionIsLoaded() && this.activeChild().currentAction().Customer && this.activeChild().currentAction().Customer.Vertical) {
      customerVertical = this.activeChild().currentAction().Customer.Vertical.Name;
    } else if (this.activeChild()?.currentAction()?.Task?.Input?.Customer) {
      customerVertical = this.activeChild().currentAction().Task.Input.Customer.Vertical.Name;
    }
    return customerVertical;
  });

  constructor(
    private modalService: NgbModal,
    private actionService: ActionService,
    private taskService: TaskService,
    private customerTouchService: CustomerTouchService,
    private sessionStorageService: SessionStorageService) { }

  cancelTask(showNotification: any): any {
    this.isLoading(true);
    if (this.quitView()) {
      return;
    }
    if (this.actionIsLoaded()) {
      this.ensureCanTakeAction(false) // Don't show message when user cannot take action
        .subscribe(canTakeAction => {
          if (canTakeAction) {
            Utils.wrapDfd(this.taskService.unassignAndUnlockNonPersistableAssignedTask(this.activeChild().currentAction().Task.TaskId, this.activeChild().currentAction().Task.AssignedUserId))
              .done(() => {
                if (showNotification !== false) {
                  toastr.success('Tasks have been unlocked and unassigned.');
                }
                this.activeChild(undefined);
              }).fail((displayMessage) => {
                toastr.error(displayMessage);
              }).always(() => {
                this.isLoading(false);
                if (this.dfd) {
                  this.dfd.resolve();
                }
              });
          }
        },
        err => {
          toastr.error(err);
          this.isLoading(false);
        });
    }
  }

  verifyTaskIsValid(data: any): any {
    const currentAction = data;
    return true;
  }

  deactivate(): void {
    this.actionTimer.stop();
    clearInterval(this.viewModelUpdateTimer);
  }

  loadWorkspace(currentAction: Boo.Objects.Action): void {
    this.activeChild(
      {
        selector: `app-components-specialist-actioncomponents-${currentAction.Task.TaskTypeSummary.ControlName}`,
        actionViewModel: ko.observable(),
        currentAction: ko.observable(currentAction),
        output: currentAction.Task.Output,
        input: currentAction.Task.Input ? structuredClone(currentAction.Task.Input) : null
      });
    this.actionTimer.start();
    // Update timer view every second
    this.viewModelUpdateTimer = setInterval(() => this.updateViewModel(), 1000);
    // CustomerId must be set for the skipCustomerModal
    this.customerId(this.actionIsLoaded() ? this.activeChild().currentAction().Task.CustomerId : '');
    $('#content').animateCSS('fadeInDown');
  }

  updateViewModel(): void {
    this.taskTime(this.actionTimer.getFormattedSeconds());
  }

  quitView(): any {
    if (!this.isReadOnly()) {
      return false;
    }
    this.isLoading(false);
    if (this.dfd) {
      this.dfd.resolve();
    }
    return true;
  }

  rejectTask(modal: NgbModalRef = null): any {
    this.isLoading(true);
    if (this.quitView()) {
      return;
    }

    this.ensureCanTakeAction()
      .subscribe(
        canTakeAction => {
          if (canTakeAction) {
            const taskStatus = this.getRejectionTaskStatus();

            if (!this.activeChild().actionViewModel().isStepReadyToReject()) {
              toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
              this.isLoading(false);
            } else if (this.canRejectWithoutProvidingReason()) {
              bootbox.confirm(
                'This will reject the current task. Are you sure you want to do this?',
                (result: any) => {
                  if (result) {
                    this.takeAction(taskStatus, false);
                  } else {
                    this.isLoading(false);
                  }
                });
            } else if (this.rejectableByRejectionType) {
              this.activeChild().currentAction().TaskDetails.Rejection = RejectionFactory.createRejectionFromTypes(this.currentUser.UserId, this.rejectionCtrl.get('rejectionTypes').value, this.rejectionCtrl.get('rejectionReason').value);
              this.takeAction(taskStatus, false);
            } else if (this.isOutputWithRejectionReason(this.activeChild().currentAction().Task.Output)) {
                this.activeChild().currentAction().Task.Output.RejectionReason = this.rejectionCtrl.get('rejectionReason').value;
                this.takeAction(taskStatus, false);
            } else {
              this.activeChild().currentAction().TaskDetails.Reason = this.rejectionCtrl.get('rejectionReason').value;
              this.takeAction(taskStatus, false);
            }

            if (modal) {
              modal.dismiss();
            }
          }
        },
        err => {
          toastr.error(err);
          this.isLoading(false);
        });
  }

  saveTask(): any {
    this.isLoading(true);
    if (this.quitView()) {
      return;
    }
    // We don't care if it passes validation yet.
    if (_.isObject(this.activeChild()) === false) {
      toastr.error('Could not complete task. ActiveChild is undefined. Please notify your manager of this error.');
      return;
    }
    if (ko.isObservable(this.activeChild().actionViewModel) && _.isObject(this.activeChild().actionViewModel()) === false) {
      toastr.error('Could not complete task. ActionViewModel is undefined. Please notify your manager of this error.');
      return;
    }
    this.ensureCanTakeAction()
      .subscribe(
        canTakeAction => {
          if (canTakeAction) {
            const isStepReadyToSave = this.activeChild().actionViewModel().isStepReadyToSave();
            if (typeof isStepReadyToSave.then === 'function') {
              isStepReadyToSave.then((result: any) => {
                if (result) {
                  this.takeAction(window.launchpad.config.TaskStatusEnum.saveProgress, true);
                } else {
                  toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
                  this.isLoading(false);
                }
              }).fail(() => { this.isLoading(false); });
            } else if (isStepReadyToSave) {
              this.takeAction(window.launchpad.config.TaskStatusEnum.saveProgress, true);
            } else {
              toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
              this.isLoading(false);
            }
          }
        },
        err => {
          toastr.error(err);
          this.isLoading(false);
        });
  }

  completeTask(): any {
    this.isLoading(true);
    if (this.quitView()) {
      return;
    }
    if (_.isObject(this.activeChild()) === false) {
      toastr.error('Could not complete task. ActiveChild is undefined. Please notify your manager of this error.');
      return;
    }
    if (ko.isObservable(this.activeChild().actionViewModel) && _.isObject(this.activeChild().actionViewModel()) === false) {
      toastr.error('Could not complete task. ActionViewModel is undefined. Please notify your manager of this error.');
      return;
    }
    this.ensureCanTakeAction()
      .subscribe(
        canTakeAction => {
          if (canTakeAction) {
            const isStepReadyToComplete = this.activeChild().actionViewModel().isStepReadyToComplete();
            if (typeof isStepReadyToComplete.then === 'function') {
              isStepReadyToComplete.then((result: any) => {
                if (result) {
                  this.takeAction(window.launchpad.config.TaskStatusEnum.complete, false);
                } else {
                  toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
                  this.isLoading(false);
                }
              }).fail(() => { this.isLoading(false); });
            } else if (isStepReadyToComplete) {
              this.takeAction(window.launchpad.config.TaskStatusEnum.complete, false);
            } else {
              toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
              this.isLoading(false);
            }
          }
        },
        err => {
          toastr.error(err);
          this.isLoading(false);
        });
  }

  saveTaskDuration(): any {
    if (this.quitView()) {
      return;
    }
    // Logic to prevent overwriting of the minutes on a previously completed action
    if (this.actionIsLoaded() && this.activeChild().currentAction().Task.TaskStatusEnum !== window.launchpad.config.TaskStatusEnum.complete) {
      let seconds;
      // Save actual time for Onsite Content Change and Onsite Recommendation tasks
      if (this.activeChild().currentAction().Task.TaskTypeSummary.TaskTypeId === Boo.Objects.Enums.TaskTypeEnum.OnsiteRecommendationCreateContent ||
        this.activeChild().currentAction().Task.TaskTypeSummary.TaskTypeId === Boo.Objects.Enums.TaskTypeEnum.ContentReviewBlog) {
        seconds = this.activeChild().actionViewModel().timer.elapsedSeconds;
      } else {
        seconds = this.actionTimer.getElapsedSeconds();
      }

      // Launchpad completed tasks will always save with at least 1 second.
      seconds = seconds === 0 ? 1 : seconds;

      this.activeChild().currentAction().Task.ActualSeconds += seconds;
    }
  }

  takeAction(newActionStatus: any, preventReload: boolean): void {
    // CalculateActualTimeSpent
    if (newActionStatus === window.launchpad.config.TaskStatusEnum.complete || newActionStatus === window.launchpad.config.TaskStatusEnum.deleted || window.launchpad.config.TaskStatusEnum.saveProgress) {
      this.saveTaskDuration();
    }
    // Fill Up Data
    this.activeChild().currentAction().Task.TaskStatusEnum = newActionStatus;
    const currentAction = this.activeChild().currentAction();

    this.persistAction(currentAction, preventReload);
  }

  persistAction(currentAction: Boo.Objects.Action, preventReload: boolean): any {
    if (this.quitView()) {
      return;
    }
    this.isLoading(true);
    Utils.wrapDfd(this.actionService.updateStatus(currentAction))
      .then(() => {
        $('#content').slideUp(
          500,
          () => {
            this.isLoading(false);
            toastr.success('Successfully updated.');
            // Usually, when we save a persistable action, we don't want to reload the same action.
            this.close(!preventReload);
          });
      })
      .fail((err: any) => {
        toastr.error(err);
        this.isLoading(false);
      });
  }

  deleteTask(): any {
    this.isLoading(true);
    if (this.quitView()) {
      return;
    }
    this.ensureCanTakeAction()
      .subscribe(
        canTakeAction => {
          if (canTakeAction) {
            if (_.isObject(this.activeChild().actionViewModel()) &&
              _.isFunction(this.activeChild().actionViewModel().isStepReadyToDelete) &&
              this.activeChild().actionViewModel().isStepReadyToDelete()) {
              this.takeAction(window.launchpad.config.TaskStatusEnum.deleted, false);
            } else {
              toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
              this.isLoading(false);
            }
          }
        },
        err => {
          toastr.error(err);
          this.isLoading(false);
        });
  }

  close(shouldReload: any): void {
    if (this.dfd) {
      this.dfd.resolve(shouldReload);
    }
  }

  closeTask(): void {
    this.close(false);
  }

  ngOnInit(): void {
    this.sessionStorageService.getUser()
      .subscribe((user) => this.currentUser = user);
  }

  activate(options: ActionWorkspacePriorityViewParams): void {
    this.actionTimer = new actionTimer(() => this.close(false));
    this.taskTime(this.actionTimer.getFormattedSeconds());
    if (options.isReadOnly) {
      this.isReadOnly(options.isReadOnly);
    }
    if (options.currentAction && options.currentAction.Task) {
      if (options.currentAction.Task && options.currentAction.Task.TaskTypeSummary) {
        let titlePrefix = (options.currentAction.Customer && options.currentAction.Customer.Partner) ? options.currentAction.Customer.Partner.Name + ' - Task Type - ' : '';
        if (options.currentAction.Customer && options.currentAction.Customer.Partner) {
          titlePrefix += options.currentAction.Customer.Partner.Name + ' - Task Type - ';
        } else if ((options.currentAction.Task.Input as any)?.Customer?.Partner) {
          titlePrefix += (options.currentAction.Task.Input as any).Customer.Partner.Name + ' - Task Type - ';
        }

        this.title = titlePrefix + options.currentAction.Task.TaskTypeSummary.Name;
      }

      //Inputs are used for history and should never be altered by a workspace
      if (options.currentAction.Task.Input)
      {
        Utils.deepFreeze(options.currentAction.Task.Input);
      }

      this.currentAction(options.currentAction);
      this.loadWorkspace(options.currentAction);
      this.canDelete(_.contains(taskTypeActionFeatures.deletable, options.currentAction.Task.TaskTypeEnum));
      this.canWorkblock = _.contains(taskTypeActionFeatures.workblockable, options.currentAction.Task.TaskTypeEnum);
      this.showTaskId = _.contains(taskTypeActionFeatures.showTaskId, this.activeChild().currentAction().Task.TaskTypeEnum);
      this.showCustomerCountry = _.contains(taskTypeActionFeatures.showCustomerCountry, this.activeChild().currentAction().Task.TaskTypeEnum);
      this.showPartnerName = _.contains(taskTypeActionFeatures.showPartnerName, this.activeChild().currentAction().Task.TaskTypeEnum);
      this.showCustomerName = _.contains(taskTypeActionFeatures.showCustomerName, this.activeChild().currentAction().Task.TaskTypeEnum);
      this.rejectableByRejectionType = _.contains(taskTypeActionFeatures.rejectableByRejectionType, this.activeChild().currentAction().Task.TaskTypeEnum);

      if (this.rejectableByRejectionType) {
        this.rejectionCtrl.get('rejectionTypes').addValidators(Validators.required);
      }
    } else {
      toastr.error('Failed to load next action. Please refresh your browser and try again. If this issue persists please contact your manager');
      this.close(false);
    }
  }

  openModal(template: any, action: string): void {
    this.ensureCanTakeAction()
      .subscribe(
        canTakeAction => {
          if (canTakeAction) {
            this.modalService.open(template);
          }
        },
        err => {
          toastr.error(err);
          this.isLoading(false);
        });
  }

  customerSkipSaved(modal: NgbModalRef): void {
    if (this.activeChild().actionViewModel() && this.activeChild().actionViewModel().onSkip) {
        this.activeChild().actionViewModel().onSkip();
    }

    modal.dismiss();
    this.close(false);
  }

  workblockSaved(modal: NgbModalRef): void {
    Utils.wrapDfd(this.customerTouchService.insert(this.customerId(), this.actionTimer.getElapsedSeconds(), 'Create Action Workblock Ticket'))
      .then(() => {
        modal.dismiss();
        $('#content').slideUp(
          500,
          () => {
            this.isLoading(false);
            toastr.success('Successfully updated.');
            this.close(true);
          });
      });
  }

  ensureCanTakeAction(showMessage = true): Observable<boolean> {
    const taskId = this.activeChild().currentAction().Task.TaskId;
    return this.taskService.isActionableByUser(taskId, this.currentUser.UserId)
      .pipe(
        tap(canTakeAction => {
          if (!canTakeAction && showMessage) {
            bootbox.dialog({
              message: `This task (${taskId}) has been unassigned from your user or is no longer available. Please get your next task.`,
              closeButton: false,
              buttons: {
                  ok: {
                      label: 'OK',
                      className: 'btn-primary',
                      callback: (): any => {
                        this.close(false);
                      }
                  }
              }
            });
          } else if (!canTakeAction && !showMessage) {
            this.close(false);
          }
        })
      );
  }

  // Sometimes when we reject a task, we want to delete it instead of setting it to NeedsData
  private getRejectionTaskStatus(): Boo.Objects.Enums.TaskStatusEnum {
    if (taskTypeActionFeatures.rejectUsingDelete.includes(this.activeChild().currentAction().Task.TaskTypeSummary.TaskTypeId)) {
      return Boo.Objects.Enums.TaskStatusEnum.Deleted;
    }
    return Boo.Objects.Enums.TaskStatusEnum.NeedsData;
  }

  private isOutputWithRejectionReason(obj: any): obj is OutputWithRejectionReason {
    return 'RejectionReason' in obj;
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { finalize, switchMap } from 'rxjs/operators';
import { TabPanelDisplayMode } from 'app/components/enums/TabPanelDisplayMode';
import { CustomerCampaignService } from '../../../../services/customer-campaign.service';
import { forkJoin, Observable, of } from 'rxjs';
import { StrategyUpdateRequest } from '../../../../shared/models/strategy-update/strategy-update';
import { SessionStorageService } from '../../../../services/session-storage.service';

@Component({
  selector: 'app-components-shared-customer-campaign',
  templateUrl: './customer-campaign.component.html',
  styleUrls: ['./customer-campaign.component.scss']
})
export class CustomerCampaignComponent implements OnInit {

  @Input() customer: Boo.Objects.Customer;
  @Input() restrictions: Boo.Objects.CustomerCampaign.Enums.RestrictionTypes[] = [];
  @Input() reviewSource?: Boo.OnsiteRecommendations.Models.Enums.ReviewSources = Boo.OnsiteRecommendations.Models.Enums.ReviewSources.CustomerWorkspace;
  @Output() refreshCustomer = new EventEmitter<void>();
  @Output() openTicket = new EventEmitter<number>();
  @Output() campaignSaved = new EventEmitter<void>();

  tabPanelDisplayMode = TabPanelDisplayMode;
  loading = false;
  websiteUrls: Boo.Objects.WebsiteUrl[];
  websiteUrlKeywordRanks: Boo.Objects.WebsiteUrlKeywordRank[];
  archivedWebsiteUrls: Boo.Objects.ArchivedWebsiteUrl[];
  archivedKeywords: Boo.Objects.ArchivedKeyword[];
  haloKeywords: Boo.Objects.WebsiteKeywordTracking[];
  websiteKeywordTrackingRanks: Boo.Objects.WebsiteKeywordTrackingRank[];
  websiteUrlTasks: Boo.Objects.WebsiteUrlTask[];
  firstPageKeywordPhrases: string[];
  existingCampaignTypeIsNational: boolean;
  readOnlyReasons: string;
  strategyUpdateDisabledReasons: string;
  strategyUpdateRequested = false;
  user: Boo.Objects.User;

  customerCampaignRestrictionTypes_ReadOnly = Boo.Objects.CustomerCampaign.Enums.RestrictionTypes.ReadOnly;
  customerCampaignRestrictionTypes_HideHaloTab = Boo.Objects.CustomerCampaign.Enums.RestrictionTypes.HideHaloTab;
  customerCampaignRestrictionTypes_HideArchivedTab = Boo.Objects.CustomerCampaign.Enums.RestrictionTypes.HideArchivedTab;
  customerCampaignRestrictionTypes_HideStrategyUpdateRequestButton = Boo.Objects.CustomerCampaign.Enums.RestrictionTypes.HideStrategyUpdateRequestButton;

  constructor(
    private customerCampaignService: CustomerCampaignService,
    private sessionStorageService: SessionStorageService) { }

  ngOnInit(): void {
    this.loading = true;
    this.load()
      .pipe(finalize(() => this.loading = false))
      .subscribe({error: (e) => toastr.error(e)});
  }

  load(): Observable<void> {
    this.existingCampaignTypeIsNational = this.customer.IsCampaignNational;

    return forkJoin([
      this.customerCampaignService.getSummary(this.customer.CustomerId),
      this.sessionStorageService.getUser()
      ])
      .pipe(
        switchMap(([summary, user]) => {
          this.user = user;
          this.websiteUrls = summary.WebsiteUrls.map(websiteUrl => {
            websiteUrl.Keywords = websiteUrl.Keywords.sort((a, b) => a.KeywordPhrase.localeCompare(b.KeywordPhrase));
            return websiteUrl;
          }).sort((a, b) => a.Url.localeCompare(b.Url));
          this.websiteUrlKeywordRanks = summary.WebsiteUrlKeywordRanks;
          this.archivedWebsiteUrls = summary.ArchivedWebsiteUrls;
          this.archivedKeywords = summary.ArchivedKeywords;
          this.haloKeywords = summary.TrackingKeywords.sort((a, b) => Number(a.WasFirstPage) - Number(b.WasFirstPage) || a.KeywordPhrase.localeCompare(b.KeywordPhrase));
          this.websiteKeywordTrackingRanks = summary.WebsiteKeywordTrackingRanks;
          this.websiteUrlTasks = summary.WebsiteUrlTasks;
          this.readOnlyReasons = this.getReadOnlyReasons(summary.Restrictions);
          this.strategyUpdateDisabledReasons = this.getStrategyUpdateDisabledReasons(summary.Restrictions);
          this.restrictions = this.distinct(this.restrictions.concat(summary.Restrictions.map(x => x.Type)));
          this.firstPageKeywordPhrases = summary.FirstPageKeywordPhrases;
    
          this.populateRanks();
          this.populateTasks();
          this.configureReadOnlyRestrictions();
          return of(null);
        })
      );
  }

  populateRanks(): void {
    this.websiteUrls.flatMap(x => x.Keywords).forEach(keyword => {
      keyword.Rank = this.websiteUrlKeywordRanks.find(x => x.WebsiteUrlKeywordId === keyword.WebsiteUrlKeywordId)?.CurrentRank ?? 0;
    });

    this.haloKeywords.forEach(keyword => {
      keyword.Rank = this.websiteKeywordTrackingRanks.find(x => x.WebsiteKeywordTrackingId === keyword.WebsiteKeywordTrackingId)?.CurrentRank ?? 0;
    });
  }

  populateTasks(): void {
    this.websiteUrls.forEach(url => {
      url.WebsiteUrlTask = this.websiteUrlTasks.sort((a, b) => b.TaskId - a.TaskId).find(x => x.WebsiteUrlId === url.WebsiteUrlId);
    });
  }

  configureReadOnlyRestrictions(): void {
    if (this.readOnlyReasons) {
      this.restrictions.push(Boo.Objects.CustomerCampaign.Enums.RestrictionTypes.ReadOnly);
    }

    this.websiteUrls.filter(websiteUrl => websiteUrl.WebsiteUrlTask).forEach(websiteUrl => this.customerCampaignService.calculateReadOnlyState(websiteUrl));
  }

  requestStrategyUpdate(): void {
    bootbox.confirm(`<p>Do you want to create a Strategy Update ticket?</p><p class='text-danger'>Warning: Pages currently locked cannot be updated in the Strategy Update. While the Strategy Update is in progress, all pages in the Customer Campaign screen will be locked.</p>`, (result: boolean) => {
      if (!result) {
          return;
      }

      this.restrictions.push(Boo.Objects.CustomerCampaign.Enums.RestrictionTypes.ReadOnly);
      this.readOnlyReasons = 'Strategy update in progress';

      this.strategyUpdateRequested = true;
      this.customerCampaignService.requestStrategyUpdate(this.generateStrategyUpdateRequest())
        .subscribe((ticketId) => {
          this.openTicket.emit(ticketId);
          setTimeout(() => { this.strategyUpdateRequested = false; }, 60000); // Disable button for 1 minute
        });
    });
  }

  save(): void {
    this.loading = true;

    if (this.validationMessage) {
      this.loading = false;
      toastr.error(this.validationMessage);
      return;
    }

    if (this.modifiedCount === 0) {
      toastr.success('Customer campaign saved.');
      this.load()
        .pipe(finalize(() => this.loading = false))
        .subscribe({error: (e) => toastr.error(e)});
      return;
    }

    this.customerCampaignService.save(
      this.customer.CustomerId,
      this.websiteUrls.filter(websiteUrl => this.isWebsiteUrlModified(websiteUrl)).map(websiteUrl => {
        websiteUrl.Keywords = websiteUrl.Keywords.filter(keyword => keyword.IsModified);
        return websiteUrl;
      }),
      this.haloKeywords.filter(x => x.IsModified),
      this.isCampaignTypeModified() ? this.customer.IsCampaignNational : null
    ).pipe(
      switchMap(_ => {
        if (this.isCampaignTypeModified()) {
          this.refreshCustomer.emit();
        }

        toastr.success('Customer campaign saved.');
        return this.load();
      }),
      finalize(() => {
        this.loading = false;
        this.campaignSaved.emit();
      }))
    .subscribe({error: (e) => toastr.error(e)});
  }

  updateWebsiteUrlStatusToActive(url: string) {
    const websiteUrl = this.websiteUrls.find(x => x.Url.toLowerCase().trim() === url.toLowerCase().trim());
    if (websiteUrl && websiteUrl.StatusId !== Boo.Objects.Enums.WebsiteUrlStatuses.Active) {
      this.customerCampaignService.updateWebsiteUrlStatus(this.websiteUrls, this.haloKeywords, this.archivedWebsiteUrls, this.archivedKeywords, this.firstPageKeywordPhrases, websiteUrl, Boo.Objects.Enums.WebsiteUrlStatuses.Active);
    }
  }

  get modifiedCount(): number {
    const customerModificationCount = this.isCampaignTypeModified() ? 1 : 0;
    const pageAndKeywordModificationCount = (this.websiteUrls && this.haloKeywords) ? this.websiteUrls.filter(x => this.isWebsiteUrlModified(x)).length + this.haloKeywords.filter(x => x.IsModified).length : 0
    return customerModificationCount + pageAndKeywordModificationCount;
  }

  get strategyUpdateRequestEnabled() : boolean {
    return this.strategyUpdateValidationMessage.length === 0 
      && this.modifiedCount === 0
      && !this.restrictions.includes(Boo.Objects.CustomerCampaign.Enums.RestrictionTypes.ReadOnly);
  }

  get validationMessage(): string {
    if (this.websiteUrls.some(page => this.customerCampaignService.isActiveStatus(page) && !this.customerCampaignService.domainMatches(this.customer.Url, page.Url))) {
      return 'All page domains must match the customer domain.';
    }

    if (!this.websiteUrls.some(page => page.StatusId === Boo.Objects.Enums.WebsiteUrlStatuses.Active)) {
      return 'At least one page must be active.';
    }

    const urls = this.websiteUrls.filter(x => this.customerCampaignService.isActiveStatus(x)).map(x => x.Url.toLowerCase().trim());
    if (new Set(urls).size !== urls.length) {
      return 'Pages can not be duplicated.';
    }

    if (this.websiteUrls.some(page => page.StatusId === Boo.Objects.Enums.WebsiteUrlStatuses.Active && page.Keywords.filter(keyword => keyword.IsActive).length === 0)) {
      return 'Active pages must have at least one keyword.';
    }

    const keywordPhrases = this.websiteUrls.filter(x => x.StatusId === Boo.Objects.Enums.WebsiteUrlStatuses.Active)
      .flatMap(page => page.Keywords)
      .filter(x => x.IsActive)
      .map(x => x.KeywordPhrase.toLowerCase().trim())
      .concat(this.haloKeywords.filter(x => x.IsActive).map(x => x.KeywordPhrase.toLowerCase().trim()));

    if (new Set(keywordPhrases).size !== keywordPhrases.length) {
      return 'Keywords can not be duplicated.';
    }

    return '';
  }

  get strategyUpdateValidationMessage(): string {
    if (this.strategyUpdateDisabledReasons) {
      return this.strategyUpdateDisabledReasons;
    }

    if (this.validationMessage) {
      return this.validationMessage;
    }

    const keywordPhrases = this.websiteUrls.filter(x => x.StatusId !== Boo.Objects.Enums.WebsiteUrlStatuses.Archived)
      .flatMap(page => page.Keywords)
      .filter(x => x.IsActive)
      .map(x => x.KeywordPhrase.toLowerCase().trim())
      .concat(this.haloKeywords.filter(x => x.IsActive).map(x => x.KeywordPhrase.toLowerCase().trim()));

    if (new Set(keywordPhrases).size !== keywordPhrases.length) {
      return 'Not allowed with duplicate keywords';
    }

    if (this.modifiedCount > 0) {
      return 'Not allowed with unsaved changes';
    }

    return '';
  }

  private isCampaignTypeModified(): boolean {
    return this.customer.IsCampaignNational !== this.existingCampaignTypeIsNational;
  }

  private isWebsiteUrlModified(websiteUrl: Boo.Objects.WebsiteUrl): boolean {
    return websiteUrl.IsModified || websiteUrl.Keywords.some(x => x.IsModified);
  }

  private generateStrategyUpdateRequest(): StrategyUpdateRequest {
    const unknownRank = 101;
    return {
      UserId: this.user.UserId,
      CustomerId: this.customer.CustomerId,
      WebsiteUrls: this.websiteUrls.filter(x => x.StatusId !== Boo.Objects.Enums.WebsiteUrlStatuses.Archived)
        .map(x => ({
          WebsiteUrlId: x.WebsiteUrlId,
          WebsiteUrlKeywords: x.Keywords.filter(y => y.IsActive)
            .map(y => ({
              WebsiteUrlKeywordId: y.WebsiteUrlKeywordId,
              Rank: y.Rank === 0 || y.Rank === null ? unknownRank : y.Rank
            })),
          ReadOnlyReason: x.IsReadOnly ? x.ReadOnlyReason
            : x.IsRestrictedFromStrategyUpdate ? x.RestrictionReason 
            : null
        })),
      WebsiteKeywordTrackings: this.haloKeywords.filter(x => x.IsActive)
        .map(x => ({
          websiteKeywordTrackingId: x.WebsiteKeywordTrackingId,
          rank: x.Rank === 0 || x.Rank === null ? unknownRank : x.Rank
      }))
    } as StrategyUpdateRequest;
  }

  private distinct(items: any[]): any[] {
    return items.filter((value, index, self) => self.indexOf(value) === index);
  }

  private getReadOnlyReasons(restrictions: Boo.Objects.CustomerCampaign.Restriction[]): string {
    return this.distinct(
      restrictions.filter(x => x.Type === Boo.Objects.CustomerCampaign.Enums.RestrictionTypes.ReadOnly)
        .map(x => x.Reason)
      ).join(', ');
  }

  private getStrategyUpdateDisabledReasons(restrictions: Boo.Objects.CustomerCampaign.Restriction[]): string {
    return this.distinct(
      restrictions.filter(x => x.Type === Boo.Objects.CustomerCampaign.Enums.RestrictionTypes.ReadOnly)
        .concat(restrictions.filter(x => x.Type === Boo.Objects.CustomerCampaign.Enums.RestrictionTypes.DisableStrategyUpdateRequestButton))
        .map(x => x.Reason)
      ).join(', ');
  }
}

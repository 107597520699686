import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({   
    providedIn: 'root'
})

@Injectable({
  providedIn: 'root'
})
export class CustomerUserService {

  private baseUrl = `${environment.urlConfig.serviceUrl}/customerusers`;

  constructor(private http: HttpClient) { }

  public create(
    customer: Boo.Objects.Customer,
    email: string,
    firstName: string,
    lastName: string,
    isCustomerUserActive: boolean,
    canContact: boolean,
    canReceiveTextMessages: boolean,
    isCustomerUserPartner: boolean,
    phoneNumbers: Boo.Objects.CustomerUserPhoneNumber[],
    emailRecipientType: Boo.Objects.Enums.EmailRecipientTypes): Observable<Boo.Objects.CustomerUser> {
    const requestBody = {
        customer: customer,
        email: email,
        firstName: firstName,
        lastName: lastName,
        isCustomerUserActive: isCustomerUserActive,
        canContact: canContact,
        canReceiveTextMessages: canReceiveTextMessages,
        isCustomerUserPartner: isCustomerUserPartner,
        phoneNumbers: phoneNumbers,
        emailRecipientType: emailRecipientType
    };
      return this.http.post<Boo.Objects.CustomerUser>(`${this.baseUrl}/Create`, requestBody);
  }

  public delete(customerUserId: number): Observable<boolean> {
    return this.http.post<boolean>(`${this.baseUrl}/Delete`, customerUserId);
  }

  public getByIds(userIds: number[]): Observable<Boo.Objects.CustomerUser[]> {
    return this.http.post<Boo.Objects.CustomerUser[]>(`${this.baseUrl}/All`, userIds);
  }

  public getForCustomer(customerId: number): Observable<Boo.Objects.CustomerUser[]> {
    return this.http.get<Boo.Objects.CustomerUser[]>(`${this.baseUrl}/customer/${customerId}`);
  }
}

import { Component, OnInit } from '@angular/core';
import { OrganizationalTeamService } from '../../../services/organizational-team.service';
import { PriorityViewModel } from 'app/models/PriorityViewModel';

@Component({
  selector: 'app-components-manager-organizationalteams',
  templateUrl: './OrganizationalTeams.component.html'
})
export class OrganizationalTeamsComponent implements OnInit {
  public isLoading = false;
  public filterString = '';
  public organizationalTeams: Boo.OrganizationalTeams.Models.OrganizationalTeam[] = [];
  public filteredOrganizationalTeams: Boo.OrganizationalTeams.Models.OrganizationalTeam[] = [];

  constructor(private organizationalTeamService: OrganizationalTeamService) {
  }

  public ngOnInit(): void {
    this.loadTeams();
  }

  public loadTeams(): void {
    this.organizationalTeamService.getAllTeams()
      .subscribe(x => {
        this.organizationalTeams = x;
        this.filter();
    });
  }

  public editTeam(team: Boo.OrganizationalTeams.Models.OrganizationalTeam): void {
    this.openEditTeam({organizationalTeamId: team.OrganizationalTeamId});
  }

  public addTeam(): void {
    this.openEditTeam({});
  }

  public filter(): void {
    this.filteredOrganizationalTeams = this.organizationalTeams.filter((team) => team.Name.trim().toLowerCase().indexOf(this.filterString.trim().toLowerCase()) >= 0);
  }

  private openEditTeam(model: any): void {
    PriorityViewModel.ngShow('app-components-manager-organizationalteam', model).always(() => this.loadTeams());
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-components-manager-task-type-selection',
  templateUrl: './task-type-selection.component.html',
})
export class TaskTypeSelectionComponent {
  @Input() taskTypes: Boo.Objects.Work.SeoMinute.SeoWorkTaskType[];
  @Input() isReadOnly: boolean;
  @Output() addTaskType: EventEmitter<Boo.Objects.Work.SeoMinute.SeoWorkTaskType> = new EventEmitter<Boo.Objects.Work.SeoMinute.SeoWorkTaskType>();
  @Output() refresh: EventEmitter<any> = new EventEmitter<any>();
  selectedTaskType: Boo.Objects.Work.SeoMinute.SeoWorkTaskType;

  add(): void {
    this.addTaskType.emit(this.selectedTaskType);
    this.selectedTaskType = undefined;
  }

  searchTaskTypes(term: string, item: Boo.Objects.Work.SeoMinute.SeoWorkTaskType): boolean {
    term = term.toLowerCase();
    return item.Name.toLowerCase().indexOf(term) > -1 || item.Minutes.toString().indexOf(term) > -1;
  }
}

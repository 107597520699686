import { AfterViewInit, Component, ContentChild, ContentChildren, Input, OnChanges, OnDestroy, OnInit, QueryList, SimpleChanges, TemplateRef } from '@angular/core';
import { SortDirection } from './table-header/sort-direction';
import { ExportColumn } from './table-header/export-column';
import { TableHeaderComponent } from './table-header/table-header.component';
import { SortEvent } from './table-header/sort-event';
import { Subject, Subscription } from 'rxjs';
import { SortPipe } from '../../pipes/sort.pipe';
import { saveAs } from 'file-saver';
import { LogService } from '../../../services/log.service';
import Utils from '../../../shared/utils';
import { startWith } from 'rxjs/operators';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html'
})
export class TableComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  @ContentChild('tableHeader') public thead: TemplateRef<any>;
  @ContentChild('tableBody') public tbody: TemplateRef<any>;
  @ContentChildren(TableHeaderComponent, { descendants: true }) tableHeaders: QueryList<TableHeaderComponent>;

  @Input() items: any[];
  @Input() pageSize: number;
  @Input() disablePaging: boolean;
  @Input() exportFilename: string;

  exportEnabled = false;
  page = 1;
  pageSizeOptions: number[] = [];
  sortField: string;
  sortDirection: SortDirection;

  private pageSizeDefaults = [10, 25, 50, 100];
  private sortSubscriptions: Subscription[] = [];
  private onChanges = new Subject<SimpleChanges>();

  constructor(private logService: LogService) { }

  ngOnInit(): void {
    this.pageSize = this.pageSize || 10;
    this.disablePaging = this.disablePaging || false;
    this.sortField = this.sortField || '';
    this.sortDirection = this.sortDirection || '';
    this.exportFilename = this.exportFilename ? (`${this.exportFilename}-export`) : 'data-export';

    if (!this.pageSizeDefaults.includes(this.pageSize)) {
      this.pageSizeDefaults.push(this.pageSize);
      this.pageSizeDefaults = this.pageSizeDefaults.sort((a, b) => a - b);
    }

    this.setPageSizeOptions();
    this.onChanges.subscribe(() => this.setPageSizeOptions());
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.tableHeaders.changes.pipe(startWith([undefined])).subscribe(() => this.configureTableHeaders());
    }, 0);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.onChanges.next(changes);
  }

  ngOnDestroy() {
    this.sortSubscriptions.forEach(x => x.unsubscribe());
    this.onChanges.complete();
  }

  setPageSize(pageSize: number) {
    this.pageSize = pageSize;
  }

  export() {
    const data = new SortPipe().transform(this.items, this.items.length, this.sortField, this.sortDirection);
    const replacer = (key: string, value: any) => value === null ? '' : value;
    const exportColumns = this.tableHeaders.filter(x => !!x.export).map(x => ({ name: x.name, value: x.field }) as ExportColumn);
    const exportFields = exportColumns.map(x => x.value);

    const csv = data.map((item: Record<string, any>) => exportFields.map(fieldName => JSON.stringify(this.getProperty(item, fieldName), replacer)).join(','));
    csv.unshift(`"${exportColumns.map(x => x.name).join('","')}"`);
    const csvArray = csv.join('\r\n');

    const blob = new Blob([csvArray], { type: 'text/csv' });
    saveAs(blob, `${this.exportFilename}.csv`);

    this.logService.info(`Exporting data to ${this.exportFilename}.csv`);
  }

  private sort(header: TableHeaderComponent, sortBy: string, sortDirection: SortDirection) {
    this.tableHeaders.forEach(x => x.sortDirection = '');
    this.sortField = sortBy;
    this.sortDirection = sortDirection;
    header.sortDirection = sortDirection;
  }

  private getProperty(item: any, field: string): any {
    const segments = field.split('.');
    let value = item;
    for (const segment of segments) {
      // This line accounts for knockout observables and should be removed once no more observables are used in tables
      value = segment.indexOf('()') > -1 ? value[segment.replace('()', '')]() : value[segment];
      if (value === null) {
        return '';
      }
    }

    return Utils.isDate(value)
      ? (Utils.isDefaultDate(value) ? '-' : moment(value).format('MM/DD/YYYY h:mm:ss a'))
      : value;
  }

  private setPageSizeOptions() {
    this.pageSizeOptions = this.pageSizeDefaults.filter(x => x <= this.items?.length || 0);

    if (this.pageSizeOptions.length < this.pageSizeDefaults.length) {
      this.pageSizeOptions = this.pageSizeOptions.concat(this.pageSizeDefaults[this.pageSizeOptions.length]);
    }

    const itemCount = this.items?.length || 0;
    while (itemCount < this.pageSize * this.page && this.page > 1) {
      this.page--;
    }

    if (itemCount > 0 && this.pageSizeOptions.indexOf(this.pageSize) === -1) {
      this.pageSize = this.pageSizeOptions[this.pageSizeOptions.length - 1];
    }
  }

  private configureTableHeaders() {
    this.sortSubscriptions = this.tableHeaders.filter(x => !!x.sortBy)
      .map(sortableColumn => sortableColumn.sortEmmitter.subscribe(({ column, direction }: SortEvent) => {
        this.sort(sortableColumn, column, direction);
      }));

    const initialSortColumn = this.tableHeaders.find(x => !!x.sortBy && !!x.sortDirection);
    if (initialSortColumn) {
      this.sort(initialSortColumn, initialSortColumn.sortBy, initialSortColumn.sortDirection);
    }

    this.exportEnabled = !!this.tableHeaders.filter(x => !!x.export).length;
  }
}

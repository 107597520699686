import { Component } from '@angular/core';
import System from 'framework/System';
import TaskHelper from 'app/specialist/TaskHelper';
import IOnsiteRecommendationPublicApi = app.components.interfaces.IOnsiteRecommendationPublicApi;
import { WorkflowKeyValuePairService } from '../../../../services/workflow-keyvaluepair.service';
import WorkflowHelper from '../../../../shared/WorkflowHelper';
import OnsiteCopyPageTypeFactory from '../../../../factories/onsite-copy-page-type.factory';

type OnsiteCopyCreateContentOutput = Boo.Tasks.Outputs.Models.OnsiteCopyCreateContentOutput;

@Component({
	selector: 'app-components-specialist-actioncomponents-onsitecopycreatecontent',
	templateUrl: './OnsiteCopyCreateContent.component.html'
})
export class OnsiteCopyCreateContentComponent implements IActionWorkspace, IActivatable {
    public actionViewModel: KnockoutObservable<IActionWorkspace>;
    public currentAction: KnockoutObservable<Boo.Objects.Action>;
    public content: KnockoutObservable<string>;
    public contentWriterInstructions: KnockoutObservable<string>;
    public customer: KnockoutObservable<Boo.Objects.Customer>;
    public seoContentSurvey: Boo.Objects.SeoContentSurvey;
    public hasComments: boolean;
    public isLoading: KnockoutObservable<boolean> = ko.observable(true);
    public lastNote: KnockoutObservable<Boo.Objects.TaskNote>;
    public noteCount: KnockoutComputed<number>;
    public notes: KnockoutObservableArray<Boo.Objects.TaskNote>;
    public noteToEditor: KnockoutObservable<string>;
    public orderReason: KnockoutObservable<string>;
    public pageStrategy: KnockoutObservable<string>;
    public pageType: KnockoutObservable<string>;
    public boostToPublish: KnockoutObservable<boolean>;
    public pageValidation: KnockoutObservable<any>;
    public recommendedWordCount: KnockoutComputed<number>;
    public requiredWordCount = 100;
    public onsiteRecommendationApi: IOnsiteRecommendationPublicApi;
    public csrImplementationInstructions: string;
    public url: KnockoutObservable<string>;
    public wordCount: KnockoutObservable<number>;
    public taskInstructions: string;
    public isStandard: boolean;
    
    private output: OnsiteCopyCreateContentOutput;

    constructor (private workflowKeyValuePairService : WorkflowKeyValuePairService,
        private onsiteCopyPageTypeFactory: OnsiteCopyPageTypeFactory) {}

    public onsiteRecommendationApiCallback(api: IOnsiteRecommendationPublicApi): void {
        this.onsiteRecommendationApi = api;
        this.isLoading(false);
    }

    public activate(params: IActionWorkspaceActivationParams): JQueryPromise<void> {
        params.controlViewModel(this);
        this.actionViewModel = ko.observable(null);
        this.currentAction = params.currentAction;
        this.output = this.currentAction().Task.Output as OnsiteCopyCreateContentOutput;
        this.csrImplementationInstructions = TaskHelper.getOnsiteCopyImplementationInstructions(this.currentAction().TaskDetails.OnsiteCopyDetails.BillingTask);
        this.customer = ko.observable(this.currentAction().Customer);
        this.wordCount = ko.observable(0);
        this.notes = ko.observableArray(this.currentAction().TaskDetails && this.currentAction().TaskDetails.TaskNotes ? this.currentAction().TaskDetails.TaskNotes : []);
        this.noteToEditor = ko.observable(this.output.NoteToEditor ?? '');
        this.pageType = ko.observable('');
        this.orderReason = ko.observable('');
        this.boostToPublish = ko.observable(false);
        this.taskInstructions = this.currentAction().Task.TaskTypeSummary.Tips;
        this.isStandard = this.currentAction().Task.TaskTypeEnum === Boo.Objects.Enums.TaskTypeEnum.OnsiteCopyCreateContentStandard;

        this.hasComments = this.currentAction()
            && this.currentAction().TaskDetails
            && this.currentAction().TaskDetails.TaskNotes
            && this.currentAction().TaskDetails.TaskNotes.length > 0;

        this.noteCount = ko.computed(() => {
            return this.notes().length;
        });

        this.lastNote = ko.observable();
        if (this.noteCount()) {
            this.lastNote(this.notes()[0]);
        }

        this.recommendedWordCount = ko.computed(() => {
            return this.onsiteCopyPageTypeFactory.getWordCount(this.pageType());
        });

        this.content = ko.observable(this.currentAction().TaskDetails.TaskPost.Content);

        this.pageStrategy = ko.observable(this.currentAction().TaskDetails.TaskPost.Synopsis || '');

        this.url = ko.observable(this.currentAction().TaskDetails && this.currentAction().TaskDetails.WebsiteUrl ? this.currentAction().TaskDetails.WebsiteUrl.Url : '');

        this.contentWriterInstructions = ko.observable(
            this.currentAction().TaskDetails &&
            this.currentAction().TaskDetails.OnsiteCopyDetails &&
                this.currentAction().TaskDetails.OnsiteCopyDetails.BillingTask ?
                TaskHelper.getCustomDescription(this.currentAction().TaskDetails.OnsiteCopyDetails.BillingTask) : '');

        if (this.currentAction().TaskDetails &&
            this.currentAction().TaskDetails.OnsiteCopyDetails &&
            this.currentAction().TaskDetails.OnsiteCopyDetails.BillingTask &&
            this.currentAction().TaskDetails.OnsiteCopyDetails.BillingTask.KeyValuePairs) {
            const task = this.currentAction().TaskDetails.OnsiteCopyDetails.BillingTask;

            this.workflowKeyValuePairService.getByTaskId(task.TaskId)
                .subscribe((keyValuePairs: Boo.Objects.WorkflowKeyValuePair[]) => {
                    this.boostToPublish(WorkflowHelper.getIsBoostImplementingByResponsibility(keyValuePairs));
                });
            this.pageType(TaskHelper.getOnsiteCopyPageType(task));
            this.orderReason(TaskHelper.getOnsiteCopyOrderReason(task));
            if (this.orderReason() === 'Other') {
              this.orderReason('Other: ' + TaskHelper.getOnsiteCopyOrderReasonOther(task));
            }
        }

        this.seoContentSurvey = this.customer().SeoContentSurvey;

        this.noteToEditor.extend({
            maxLength: 4000
        });

        this.content.extend(
            {
                required: true,
                validation: {
                    validator: (value: string): boolean => {
                        return this.wordCount() >= this.requiredWordCount;
                    }, message: `Content must contain at least ${this.requiredWordCount} words.`
                }
            });

        this.pageStrategy.extend({
            required: { message: 'Page Strategy is a required field.' },
            maxLength: 4000
        });

        this.pageValidation = ko.validatedObservable([
            this.content,
            this.pageStrategy,
            this.noteToEditor
        ]);

        return System.emptyPromise();
    }

    public isStepReadyToReject(): boolean {
        this.currentAction().TaskDetails.TaskPost.Content = this.content();
        return true;
    }

    public isStepReadyToSave(): boolean {
        this.currentAction().TaskDetails.TaskPost.Content = this.content();
        this.currentAction().TaskDetails.OnsiteRecommendation = this.onsiteRecommendationApi.getOnsiteRecommendation();
        this.currentAction().TaskDetails.TaskPost.Synopsis = this.pageStrategy();
        this.output.NoteToEditor = this.noteToEditor();
        return true;
    }

    public isStepReadyToComplete(): boolean {
        if (!this.onsiteRecommendationApi.isValid() || !this.pageValidation.isValid()) {
            this.onsiteRecommendationApi.showAllErrorMessages();
            this.pageValidation.errors.showAllMessages();
            return false;
        }

        this.currentAction().TaskDetails.TaskPost.Content = this.content();
        this.output.NoteToEditor = this.noteToEditor();
        this.currentAction().TaskDetails.TaskPost.Synopsis = this.pageStrategy();
        this.currentAction().TaskDetails.OnsiteRecommendation = this.onsiteRecommendationApi.getOnsiteRecommendation();

        const keywordInformation = this.onsiteRecommendationApi.getKeywordInformation();
        this.currentAction().Task.KeywordId = keywordInformation.KeywordId;
        this.currentAction().Task.AreaId = keywordInformation.AreaId;
        this.currentAction().Task.IsAreaFirst = keywordInformation.IsAreaFirst;
        return true;
    }

    public isStepReadyToDelete(): boolean {
        return false;
    }
}

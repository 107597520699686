import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OnsiteCopyService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/onsitecopy`;

  constructor(private http: HttpClient) {}

  getImplementationSummary(taskId: number): Observable<Boo.Objects.OnsiteImplementation.OnsiteCopyImplementationSummary> {
    const params = new HttpParams().set('taskId', taskId);

    return this.http.get<Boo.Objects.OnsiteImplementation.OnsiteCopyImplementationSummary>(`${this.baseUrl}/ImplementationSummary`, { params: params });
  }
}

import { Component } from '@angular/core';
import { RegistrationPhoneNumberService } from '../../../services/registration-phone-number.service';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-manager-registrationphonenumbers',
  templateUrl: './RegistrationPhoneNumbers.component.html'
})
export class RegistrationPhoneNumbersComponent implements IActivatable {
  contents: KnockoutObservable<string>;
  file: KnockoutObservable<any>;
  filename: KnockoutObservable<string>;
  isLoading: KnockoutObservable<boolean>;
  isUploadButtonEnabled: KnockoutComputed<boolean>;
  phoneNumbers: KnockoutObservableArray<string>;
  registrationPhoneNumbers: Boo.Objects.RegistrationPhoneNumber[] = [];

  constructor(private registrationPhoneNumberService: RegistrationPhoneNumberService) { }

  activate(params: any): JQueryPromise<any> {
    this.isLoading = ko.observable(false);
    this.phoneNumbers = ko.observableArray([]);

    this.file = ko.observable();
    this.filename = ko.observable('');
    this.contents = ko.observable('');

    this.isUploadButtonEnabled = ko.computed((): boolean => {
      return this.phoneNumbers().length > 0;
    });

    this.contents.subscribe((value: string) => {
      this.simpleCSVNumberParser(value);
    });

    return this.load();
  }

  load(): JQueryPromise<void> {
    this.isLoading(true);
    this.registrationPhoneNumbers = [];
    this.phoneNumbers([]);

    return Utils.wrapDfd(this.registrationPhoneNumberService.get())
      .then((registrationPhoneNumbers: Boo.Objects.RegistrationPhoneNumber[]) => {
        this.registrationPhoneNumbers = registrationPhoneNumbers;
      }).fail((displayMessage: string) => {
        toastr.error(displayMessage);
      }).always(() => {
        this.isLoading(false);
      });
  }

  upload(): JQueryPromise<void> {
    this.isLoading(true);

    if (this.phoneNumbers().length > 0) {
      return Utils.wrapDfd(this.registrationPhoneNumberService.import(this.phoneNumbers()))
        .then(() => {
          return this.load();
        }).fail((displayMessage: string) => {
          toastr.error(displayMessage);
        }).always(() => {
          this.phoneNumbers([]);
          this.file({});
          this.filename('');
          this.isLoading(false);
        });
    }
  }

  private simpleCSVNumberParser(values: string): void {
    const result: string[] = values.split('\n');
    const numbers: string[] = [];

    _.each(result, (number) => {
      const phoneNumber: string = number.replace(/\D/g, '');
      if (phoneNumber && phoneNumber.length === 10) {
        numbers.push(phoneNumber);
      }
    });

    this.phoneNumbers(numbers);

    if (this.phoneNumbers().length === 0) {
      toastr.error('No valid phone numbers found.');
    }
  }
}

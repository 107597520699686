import { Component, QueryList, AfterViewInit, ContentChildren } from '@angular/core';
import IFloatingPanelConfig = app.components.interfaces.IFloatingPanelConfig
import { wobbleIf } from '../../animations';
import { FloatingPanelComponent } from '../floating-panel/floating-panel.component';

@Component({
  selector: 'app-floating-panels',
  templateUrl: './floating-panels.component.html',
  animations: [wobbleIf]
})
export class FloatingPanelsComponent implements AfterViewInit {

  @ContentChildren(FloatingPanelComponent) public panelComponents: QueryList<FloatingPanelComponent>;

  panels: IFloatingPanelConfig [] = [];
  childrenIds = '';
  topZ = 100;
  isLoading = false;

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.initializePanels(); 
    }, 0);
  }

  initializePanels() {
    this.panelComponents.forEach((panel, index) =>
    {
      const newPanel: IFloatingPanelConfig = {
        expanded: false,
        panelId: index,
        templateRef: panel.floatingPanelContent,
        dragPosition: {x:0, y:0},
        zIndex: this.topZ,
        title: panel.displayConfig.title,
        icon: panel.displayConfig.icon,
        wobble: panel.displayConfig.wobble,
        titleBadgeClass: panel.displayConfig.titleBadgeClass,
        badgeCount: panel.displayConfig.badgeCount,
        size: panel.displayConfig.size,
      };
      this.panels.push(newPanel);
    })
  }

  expandPanel(panelId: number): void {
    const panel = this.panels[panelId];
    panel.expanded = true;
    this.moveToTop(panel);
  }

  expandPanelByTitle(title: string): void {
    const panel = this.panels.find(x => x.title === title);
    panel.expanded = true;
    this.moveToTop(panel);
  }

  collapsePanel(panelId: number): void {
    const panel = this.panels[panelId];
    panel.expanded = false;
    panel.dragPosition = {x:0, y:0}
  }

  getPanelSize = (panel: IFloatingPanelConfig) => {
    return panel.size;
  }

  moveToTop(panel: IFloatingPanelConfig): void {
    panel.zIndex = ++this.topZ;
  }

  focusPanel(panelId: number) {
    const panel = this.panels[panelId];
    this.moveToTop(panel);
  }

  setLoading(loading: boolean) {
    if(loading) {
      this.isLoading = true;
      this.panels.forEach((panel) => this.collapsePanel(panel.panelId));
    } else {
      this.isLoading = false;
    }
  }
}


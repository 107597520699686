import { Component, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import BaseComponent from '../BaseComponent';
import { CustomerNoteService } from '../../../services/customer-note.service';

@Component({
    selector: 'app-components-ticket-customernotes',
    templateUrl: './customer-notes.component.html'
})
export class CustomerNotesComponent extends BaseComponent implements AfterViewInit {
    @ViewChild('noteGridTemplate') public noteGridTemplate: TemplateRef<any>;
    public customer: CustomerObservable = null;
    public salesNote: KnockoutObservable<string>;
    public dataTable: app.components.interfaces.DataTablePublicApi;
    public dataTableOptions: app.components.interfaces.DataTableOptions;

    constructor(private customerNoteService: CustomerNoteService) {
        super();
    }

    public activate(params: app.ticket.components.interfaces.ITicketComponentActivateParams): JQueryPromise<void> {
        return super.activate(params)
            .then(() => {
                this.customer = params.customer;
                this.salesNote = ko.observable('');
            });
    }

    public ngAfterViewInit(): void {
        setTimeout(() => {
            this.configureODataLoader();
        }, 0);
    }

    private configureODataLoader(): void {
        this.dataTableOptions = {
            loader: (page, pageSize, sortOrder): JQueryPromise<framework.data.IPageResult<Boo.Objects.CustomerNote>> => {
                this.isLoading(true);
                if (!sortOrder) {
                    sortOrder = 'InsertedDate desc';
                }

                const salesQuery = this.customerNoteService.get(this.customer.CustomerId())
                    .orderBy(sortOrder)
                    .filter(`ContactTypeId eq ${Boo.Objects.Enums.ContactTypeEnum.Sales}`);

                return salesQuery.load()
                    .then(x => {
                        const salesNotes = _.chain(x.Items)
                            .filter((note: Boo.Objects.CustomerNote) => {
                                return note.Text !== '';
                            })
                            .pluck('Text')
                            .value()
                            .reverse()
                            .join('\n--------\n');

                        this.salesNote(salesNotes);

                        return this.customerNoteService.get(this.customer.CustomerId())
                            .orderBy(sortOrder)
                            .page(page, pageSize)
                            .load();
                    })
                    .always(() => this.isLoading(false));
            },
            columns: [
                { id: 'inserted_date', value: 'InsertedDate', name: 'Date' },
                { id: 'note', value: 'Text', name: 'Note' },
                { id: 'userfullname', value: 'UserFullName', name: 'User', canSort: false },
            ],
            pageSize: 5,
            rowTemplate: this.noteGridTemplate,
            apiCallback: (api): void => {
                this.dataTable = api;
            }
        };
    }
}

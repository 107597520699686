import { Component, Input, OnInit } from '@angular/core';
import { BusinessCitationService } from '../../../../services/business-citation.service';
import TaskTypeEnum = Boo.Objects.Enums.TaskTypeEnum;
import { finalize, map, switchMap } from 'rxjs/operators';
import { LocalProfileService } from '../../../../services/local-profile.service';
import SubscriptionModificationTypes = Boo.BusinessCitations.Models.Enums.SubscriptionModificationTypes;
import { Observable, of, throwError } from 'rxjs';

@Component({
  selector: 'app-components-shared-business-citation-subscriptions',
  templateUrl: './business-citation-subscriptions.component.html',
  styleUrls: ['./business-citation-subscriptions.component.scss']
})
export class BusinessCitationSubscriptionsComponent implements OnInit {
  @Input() customerId: number;
  @Input() manageableTaskTypes: TaskTypeEnum[] = [];
  @Input() allowAutoSubscribe = false;
  @Input() allowValidationAndAugmentationOrdering = false;
  @Input() showSaveButton = false;

  isLoading = false;
  localProfiles: Boo.Objects.LocalProfiles.LocalProfileFragment[];
  tasks: Boo.Objects.TaskFragment[];
  businessCitationSubscriptions: Boo.BusinessCitations.Models.BusinessCitationSubscriptionFragment[];

  boostabilityProviderId: Boo.BusinessCitations.Models.Enums.Providers = Boo.BusinessCitations.Models.Enums.Providers.Boostability;
  uberallProviderId: Boo.BusinessCitations.Models.Enums.Providers = Boo.BusinessCitations.Models.Enums.Providers.Uberall;
  activeSubscriptionStatusId: Boo.BusinessCitations.Models.Enums.SubscriptionStatuses = Boo.BusinessCitations.Models.Enums.SubscriptionStatuses.Active;

  subscriptionModifications: Boo.BusinessCitations.Models.SubscriptionModification[] = [];
  canManageSubscriptions: boolean;

  private origins: Partial<Record<TaskTypeEnum, string>> = {
    [TaskTypeEnum.LocalBusinessCitationSubscription]: 'SEO',
    [TaskTypeEnum.BusinessProfileManagement]: 'BPM'
  };

  constructor(private businessCitationService: BusinessCitationService,
    private localProfileService: LocalProfileService) { }

  ngOnInit(): void {
    this.canManageSubscriptions = this.manageableTaskTypes.length > 0;

    this.isLoading = true;
    this.load()
      .pipe(finalize(() => this.isLoading = false))
      .subscribe({ error: (err) => toastr.error(err) });
  }

  load(): Observable<void> {
    this.subscriptionModifications = [];

    return this.businessCitationService.getBusinessCitationSubscriptionSummary(this.customerId, this.manageableTaskTypes, this.allowValidationAndAugmentationOrdering)
      .pipe(
        map(summary => {
          this.localProfiles = summary.LocalProfiles.sort(this.localProfileService.sortFragments.bind(this));
          this.tasks = summary.Tasks;
          this.businessCitationSubscriptions = summary.LocalProfiles.filter(x => x.BusinessCitationSubscription).map(x => x.BusinessCitationSubscription);

          if (this.allowAutoSubscribe) {
            const subscribableLocalProfiles = this.localProfiles.filter(x => x.BusinessCitationSubscriptionTaskId === null && x.IsActive && x.CanSubscribe);
            const availableTasks = this.tasks.filter(x => x.LocalProfileId === 0);

            if (subscribableLocalProfiles.length === availableTasks.length) {
              subscribableLocalProfiles.forEach(x => x.BusinessCitationSubscription ? this.confirmSubscription(x) : this.subscribe(x));
            }
          }
        })
      );
  }

  canManageSubscription(businessCitationSubscriptionFragment: Boo.BusinessCitations.Models.BusinessCitationSubscriptionFragment): boolean {
    return businessCitationSubscriptionFragment && this.manageableTaskTypes.indexOf(businessCitationSubscriptionFragment.FulfillmentTaskTypeEnum) > -1;
  }

  getOrigin(localProfile: Boo.Objects.LocalProfiles.LocalProfileFragment): string {
    const modification = this.subscriptionModifications.find(x => x.LocalProfileId === localProfile.LocalProfileId);
    return modification ? this.origins[modification.TaskTypeEnum] : '';
  }

  subscribe(localProfile: Boo.Objects.LocalProfiles.LocalProfileFragment): void {
    if (localProfile.BusinessCitationSubscriptionTaskId === null) {
      const task = _.find(this.tasks, t => { return t.LocalProfileId === 0; });
      if (task) {
        task.LocalProfileId = localProfile.LocalProfileId;
        localProfile.BusinessCitationSubscriptionTaskId = task.TaskId;
        localProfile.HasSubscriptionModification = true;
        this.subscriptionModifications.push({ Type: SubscriptionModificationTypes.Subscribe, LocalProfileId: localProfile.LocalProfileId, TaskId: task.TaskId, TaskTypeEnum: task.TaskTypeId, CustomerId: this.customerId });
      } else {
        toastr.error('Cannot subscribe. There are no unassociated Business Citation Subscription tasks');
      }
    } else {
      toastr.error('This Local Profile has already been associated with a Business Citation Subscription task');
    }
  }

  confirmSubscription(localProfile: Boo.Objects.LocalProfiles.LocalProfileFragment): void {
    if (localProfile.BusinessCitationSubscriptionTaskId === null) {
      const task = _.find(this.tasks, t => { return t.LocalProfileId === 0; });
      if (task) {
        task.LocalProfileId = localProfile.LocalProfileId;
        localProfile.BusinessCitationSubscriptionTaskId = task.TaskId;
        localProfile.HasSubscriptionModification = true;
        this.subscriptionModifications.push({ Type: SubscriptionModificationTypes.ConfirmSubscription, LocalProfileId: localProfile.LocalProfileId, TaskId: task.TaskId, TaskTypeEnum: task.TaskTypeId, CustomerId: this.customerId });
      } else {
        toastr.error('Cannot confirm subscription. There are no unassociated Business Citation Subscription tasks');
      }
    } else {
      toastr.error('This Local Profile has already been associated with a Business Citation Subscription task');
    }
  }

  cancel(localProfile: Boo.Objects.LocalProfiles.LocalProfileFragment): void {
    localProfile.HasSubscriptionModification = true;
    this.subscriptionModifications.push({ Type: SubscriptionModificationTypes.Cancel, LocalProfileId: localProfile.LocalProfileId, TaskId: null, TaskTypeEnum: null, CustomerId: this.customerId });
    localProfile.BusinessCitationSubscription = null;
  }

  saveSubcriptions(): void {
    this.save().subscribe(_ => toastr.success('Subscriptions saved'), (err) => toastr.error(err));
  }

  save(preventReload = false): Observable<void> {
    if (!this.isValid()) {
      return throwError(this.requiredAction);
    }

    if (this.subscriptionModifications.length > 0) {
      return this.businessCitationService.modifySubscriptions(this.subscriptionModifications)
        .pipe(switchMap(() => preventReload ? of(null) : this.load()));
    }
    else {
      this.isLoading = false;
      toastr.info('No changes to save');
      return of(null);
    }
  }

  undo(): void {
    const previousAction = this.subscriptionModifications.pop();
    if (previousAction) {
      if (previousAction.Type === SubscriptionModificationTypes.Subscribe || previousAction.Type === SubscriptionModificationTypes.ConfirmSubscription) {
        const task = _.find(this.tasks, t => { return t.LocalProfileId === previousAction.LocalProfileId; });
        if (task) {
          task.LocalProfileId = 0;;
        }

        const localProfile = _.find(this.localProfiles, lp => { return lp.LocalProfileId === previousAction.LocalProfileId; });
        if (localProfile) {
          localProfile.BusinessCitationSubscriptionTaskId = null;
          localProfile.HasSubscriptionModification = false;
        }
      }

      if (previousAction.Type === SubscriptionModificationTypes.Cancel) {
        const localProfile = _.find(this.localProfiles, lp => { return lp.LocalProfileId === previousAction.LocalProfileId; });
        if (localProfile) {
          const businessCitationSubscriptionFragment = ko.mapping.fromJS(_.find(this.businessCitationSubscriptions, ls => { return ls.LocalProfileId === previousAction.LocalProfileId; }));
          localProfile.BusinessCitationSubscription = businessCitationSubscriptionFragment;
          localProfile.HasSubscriptionModification = false;
        }
      }
    }
  }

  openProviderSSO(localProfileId: number): void {
    this.businessCitationService.generateBusinessCitationProviderSSOUrl(localProfileId)
      .subscribe(
        ssoUrl => {
          window.open(ssoUrl, '_blank');
        },
        (err) => toastr.error(err));
  }

  orderValidationAndAugmentation(localProfile: Boo.Objects.LocalProfiles.LocalProfileFragment) {
    bootbox.dialog({
      message: 'Would you like to order a Business Profile Validation and Augmentation task for this local profile?',
      closeButton: false,
      buttons: {
        yes: {
          label: 'Yes',
          className: 'btn-success',
          callback: (): void => {
            localProfile.ValidationAndAugmentationStatus.CanOrder = false;
            localProfile.ValidationAndAugmentationStatus.Reason = 'In progress';

            this.businessCitationService.orderValidationAndAugmentation(this.customerId, localProfile.LocalProfileId)
              .subscribe(_ => toastr.info('Validation and Augmentation order'), (err) => toastr.error(err));
          }
        },
        no: {
          label: 'No',
          className: 'btn-danger'
        }
      }
    });
  }

  isValid(): boolean {
    if (this.requiredAction) {
      toastr.error(this.requiredAction);
    }
    return !this.requiredAction;
  }

  get unassignedTaskCount(): number {
    return _.filter(this.tasks, t => t.LocalProfileId === 0).length;
  }

  get requiredAction() {
    if (this.unassignedTaskCount > 0) {
      return `${this.unassignedTaskCount} of ${this.tasks.length} ${this.unassignedTaskCount > 1 ? 'locations' : 'location'} needs to be subscribed`;
    }

    const unassignedLocalProfilesWithSubscriptions = _.filter(this.localProfiles, lp => { return lp.BusinessCitationSubscriptionTaskId === null && lp.BusinessCitationSubscription !== null && this.canManageSubscription(lp.BusinessCitationSubscription); });
    if (this.unassignedTaskCount === 0 && unassignedLocalProfilesWithSubscriptions.length > 0) {
      return `Cancel  ${unassignedLocalProfilesWithSubscriptions.length} ${unassignedLocalProfilesWithSubscriptions.length > 1 ? 'subscriptions' : 'subscription'}`;
    }

    return null;
  }
}
import { Directive, ElementRef, OnInit, OnDestroy, HostListener, Input } from '@angular/core';
import { NgControl, ValidationErrors } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ValidationMsgService } from './validation-msg-service';

@Directive({
  selector: '[lpValidationMsg]'
})
export class FormcontrolValidationMsgDirective implements OnInit, OnDestroy {
  constructor(
    private elRef: ElementRef,
    private control: NgControl,
    private validationMsgService: ValidationMsgService
  ) { }

  @Input()
  public lpValidationMsg: string;
  errorSpanId = '';
  statusChangeSubscription: Subscription;

  ngOnInit(): void {
    const ctrlName = `${this.control.name ? this.control.name.toString() : 'ctrl'}-`;
    this.errorSpanId = `${ctrlName}error-msg-${new Date().toISOString()}`;
    this.statusChangeSubscription = this.control.statusChanges.subscribe(status => {
      if (status === Status.valid || (this.control.pristine && !this.control.touched) || !this.control.touched) {
        this.removeError();
      } else if (status === Status.invalid) {
        this.showError();
      }
    });
  }

  ngOnDestroy(): void {
    this.statusChangeSubscription.unsubscribe();
  }

  @HostListener('blur', ['$event'])
  handleBlurEvent(event: any) {
    if (this.control.errors) {
      this.showError();
    } else {
      this.removeError();
    }
  }

  private showError() {
    this.removeError();
    const valErrors: ValidationErrors = this.control.errors;
    const errKey = Object.keys(valErrors)[0];
    let condition;
    let alternateMessage;
    switch (errKey) {
      case 'maxlength':
      case 'minlength':
        condition = valErrors[errKey].requiredLength;
        break;
      case 'min':
      case 'max':
        condition = valErrors[errKey][errKey]
        break;
      case 'invalidPhoneNumber':
      case 'stringsNotAllowed':
      case 'mismatchedDomain':
        if (valErrors[errKey].alternateMessage) {
          alternateMessage = valErrors[errKey].alternateMessage;
        }
        break;
      case 'urlInvalid':
        if (this.lpValidationMsg) {
          alternateMessage = this.lpValidationMsg;
        }
        break;
      default:
        condition = valErrors[errKey];
    }
    const conditionStr: string = condition !== undefined ? condition.toString() : null;
    const alternateMessageStr: string = alternateMessage !== undefined ? alternateMessage.toString() : null;
    const errorMsg = this.validationMsgService.getValidationMsg(errKey, conditionStr, alternateMessageStr);
    const errSpan = '<span style="color:#DA4355;" id="' + this.errorSpanId + '">' + errorMsg + '</span>';
    this.elRef.nativeElement.parentElement.insertAdjacentHTML('beforeend', errSpan);
  }

  private removeError(): void {
    const errorElement = document.getElementById(this.errorSpanId);
    if (errorElement) {
      errorElement.remove();
    }
  }

}

enum Status {
  valid = 'VALID',
  invalid = 'INVALID'
}

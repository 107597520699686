import { TabPanelCssConfig } from "../../Scripts/app/models/TabPanelCssConfig";
import { TabPanelDisplayMode } from "app/components/enums/TabPanelDisplayMode";

export default class TabPanelCssFactory {
  private static displayModeCss: Map<TabPanelDisplayMode, TabPanelCssConfig> = new Map<TabPanelDisplayMode, TabPanelCssConfig>([
      [TabPanelDisplayMode.none, {
        wrapper: '',
        tabsWrapper: '',
        tabs: '',
        nav: '',
        contentWrapper: '',
        content: ''
      }],
      [TabPanelDisplayMode.smallVertical, {
        wrapper: 'row',
        tabsWrapper: 'col-md-2',
        tabs: '',
        nav: 'nav nav-pills flex-column',
        contentWrapper: 'col-md-10',
        content: ''
      }],
      [TabPanelDisplayMode.largeVertical, {
        wrapper: 'row',
        tabsWrapper: 'col-md-4',
        tabs: '',
        nav: 'nav nav-pills flex-column',
        contentWrapper: 'col-md-8',
        content: ''
      }],
      [TabPanelDisplayMode.horizontal, {
        wrapper: '',
        tabsWrapper: '',
        tabs: '',
        nav: 'nav nav-tabs',
        contentWrapper: '',
        content: ''
      }],
      [TabPanelDisplayMode.mediumVertical, {
        wrapper: 'row',
        tabsWrapper: 'col-md-3',
        tabs: '',
        nav: 'nav nav-pills flex-column',
        contentWrapper: 'col-md-9',
        content: ''
      }],
      [TabPanelDisplayMode.horizontalCard, {
        wrapper: 'card',
        tabsWrapper: '',
        tabs: '',
        nav: 'nav nav-tabs card-header mb-0 pb-0',
        contentWrapper: 'card-body',
        content: ''
      }]
    ]);


  static getCss(displayMode: TabPanelDisplayMode, style?: TabPanelCssConfig): TabPanelCssConfig {
    let css = this.displayModeCss.get(displayMode);
    css = _.extend(css, style || {})
    return css;
  }
}
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import TaskHelper from 'app/specialist/TaskHelper';
import System from 'framework/System';
import CustomValidators from '../../../../../shared/custom-validators';
import { finalize } from 'rxjs/operators';
import { TaskRejectionService } from '../../../../../services/task-rejection.service';
import { TaskPostService } from '../../../../../services/taskpost.service';
import { forkJoin, of } from 'rxjs';
import ITaskFilesPublicApi = app.components.interfaces.ITaskFilesPublicApi;
import ITaskFilesPublicApiCallback = app.components.interfaces.ITaskFilesPublicApiCallback;

@Component({
  selector: 'app-components-specialist-actioncomponents-onsitebloggingimplementation',
  templateUrl: './OnsiteBloggingImplementation.component.html',
  styleUrls: ['./OnsiteBloggingImplementation.component.scss']
})
export class OnsiteBloggingImplementationComponent implements OnInit, IActionWorkspace, IActivatable {
  actionViewModel: KnockoutObservable<IActionWorkspace>;
  currentAction: KnockoutObservable<Boo.Objects.Action>;
  taskFilesApi: ITaskFilesPublicApi;
  taskFilesApiCallback: ITaskFilesPublicApiCallback;
  taskDescription: string;
  taskInstructions: string;
  blogUrl: string;
  noteToReviewerCtrl: UntypedFormControl;
  publishUser: string;
  imageUrl: string;
  pageTitle: string;
  newDescription: string;
  contentTitle: string;
  newH2: string;
  content: string;
  saveAsDraft: boolean;
  publishUrlCtrl: UntypedFormControl;
  publishDate: string;
  rejection: Boo.Objects.TaskRejection = null;
  isLoading = false;

  private validatedControls: UntypedFormControl[];

  constructor(private taskPostService: TaskPostService,
    private taskRejectionService: TaskRejectionService) { }

  ngOnInit(): void {
    this.taskDescription = this.currentAction().Task.TaskTypeSummary.Tips;
    
    this.saveAsDraft = this.currentAction().TaskDetails.OnsiteBloggingPreviewTaskExternalStatus !== 'Approved';
    const instructions = this.currentAction().TaskDetails.OnsiteBlogPostTaskComment ? this.currentAction().TaskDetails.OnsiteBlogPostTaskComment : '';
    this.taskInstructions = this.saveAsDraft ? 'Save as Draft. ' + instructions : (instructions ? instructions : 'Publish: This content is ready to be published on the customer\'s blog');
    this.blogUrl = this.currentAction().Customer.SeoContentSurvey.BlogUrl;
    this.imageUrl = this.currentAction().TaskDetails.TaskPost.ImageUrl;
    this.publishUser = this.currentAction().Customer.SeoContentSurvey.PublishUser;
    this.contentTitle = this.currentAction().TaskDetails.TaskPost.ContentTitle;
    this.newH2 = this.currentAction().TaskDetails.OnsiteRecommendation ? this.currentAction().TaskDetails.OnsiteRecommendation.H2New : '';
    this.pageTitle = this.currentAction().TaskDetails.OnsiteRecommendation ? this.currentAction().TaskDetails.OnsiteRecommendation.TitleNew : '';
    this.newDescription = this.currentAction().TaskDetails.OnsiteRecommendation ? this.currentAction().TaskDetails.OnsiteRecommendation.DescriptionNew : '';
    this.content = this.currentAction().TaskDetails.TaskPost.Content;

    this.taskFilesApiCallback = (api: ITaskFilesPublicApi) => { this.taskFilesApi = api; };

    this.publishUrlCtrl = new UntypedFormControl(
      this.currentAction().Task.TaskActionUrl, 
      {
        validators: [
          Validators.maxLength(2000),
          Validators.required,
          CustomValidators.validUrl()
        ],
        updateOn: 'blur'
      });
    this.noteToReviewerCtrl = new UntypedFormControl(TaskHelper.getOnsiteImplementationNoteToReviewer(this.currentAction().Task), [Validators.maxLength(2000)]);
    this.validatedControls = [this.publishUrlCtrl, this.noteToReviewerCtrl];

    const previousTaskId = TaskHelper.getPreviouslyCompletedImplementationTaskId(this.currentAction().Task);
    this.isLoading = true;
    forkJoin([
      this.taskPostService.getLastOnsiteBloggingPublishDate(this.currentAction().TaskDetails.TaskPost.CustomerId),
      previousTaskId ? this.taskRejectionService.getLatest(previousTaskId) : of(null)
    ])
      .pipe(finalize(() => {
        this.isLoading = false;
        if (previousTaskId) {
          toastr.warning('This is an implementation revision');
        }
      }))
      .subscribe(
        ([publishDate, rejection]) => {
          this.rejection = rejection;
          this.publishDate = moment().format('l');
          const lastPublishDate = moment(moment(publishDate).format('l'));
          if (publishDate
              && !launchpad.utils.isDefaultDate(publishDate)
              && lastPublishDate.add(5, 'd').isAfter(moment().format('l'))) {
              this.publishDate = lastPublishDate.add(5, 'd').format('l');
          }
        },
        (error: any) => toastr.error(error)
      );
  }

  activate(params: IActionWorkspaceActivationParams): JQueryPromise<any> {
    params.controlViewModel(this);
    this.currentAction = params.currentAction;
    this.actionViewModel = ko.observable(null);
    return System.emptyPromise();
  }

  isStepReadyToReject(): boolean {
    return false;
  }

  isStepReadyToSave(): boolean {
    if (!this.currentAction().Task.TaskTypeSummary.IsPersistable) {
      return false;
    }

    this.currentAction().Task.TaskActionUrl = this.publishUrlCtrl.value;
    TaskHelper.maybeSetOnsiteImplementationNoteToReviewer(this.currentAction().Task, this.noteToReviewerCtrl.value, this.currentAction().Task.AssignedUserId);
    return true;
  }

  isStepReadyToDelete(): boolean {
    return false;
  }

  isStepReadyToComplete(): boolean {
    let isValid = true;
    if (!this.validatedControls.every(x => x.valid)) {
      this.validatedControls.forEach(x => x.markAsTouched());
      isValid = false;
    }

    this.currentAction().TaskDetails.TaskPost.PostedDate = launchpad.utils.getCurrentMomentWithoutTime().toDate();
    TaskHelper.maybeSetOnsiteImplementationNoteToReviewer(this.currentAction().Task, this.noteToReviewerCtrl.value, this.currentAction().Task.AssignedUserId);
    this.currentAction().Task.TaskActionUrl = this.publishUrlCtrl.value;
    return isValid;
  }
}

import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PreAuditReport } from '../components/ticket/pre-audit-report/pre-audit-report-config';

@Injectable({
  providedIn: 'root'
})
export class PreAuditReportService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/preauditreport`;

  constructor(private http: HttpClient) { }

  getLatest(customerId: number): Observable<PreAuditReport> {
    const params = new HttpParams().set('customerId', customerId);
    return this.http.get<PreAuditReport>(`${this.baseUrl}/getLatest`, { params: params });
  }
}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PartnerSupportLevelService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/partnersupportlevels`;
  private activePartnerSupportLevels: Boo.Objects.PartnerSupportLevel[] = [];

  constructor(private http: HttpClient) { }

  getActive(): Observable<Boo.Objects.PartnerSupportLevel[]> {
    return this.activePartnerSupportLevels.length > 0
      ? of(this.activePartnerSupportLevels)
      : this.http.get<Boo.Objects.PartnerSupportLevel[]>(`${this.baseUrl}/GetActive`)
        .pipe(
          tap(x => this.activePartnerSupportLevels = x)
        );
  }
}

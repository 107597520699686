import { Component } from '@angular/core';
import permissions from 'app/models/Permissions';
import FileUploader from 'framework/FileUploader';
import Check from 'framework/Check';
import { PremiumBacklinkService } from '../../../services/premium-backlink.service';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';

@Component({
    selector: 'app-components-manager-premiumbacklink',
    templateUrl: './PremiumBacklink.component.html'
})
export class PremiumBacklinkComponent {
    public isLoading = false;
    public downloads: KnockoutObservableArray<Boo.Objects.ExportedTasksDownload> = ko.observableArray([]);

    public previewDownloadData: KnockoutObservableArray<Boo.Objects.PremiumBacklink.Preview> = ko.observableArray([]);
    public downloadUrl: KnockoutObservable<string> = ko.observable('');

    public outstandingOrders: KnockoutObservableArray<Boo.Objects.PremiumBacklink.OutstandingOrder> = ko.observableArray([]);
    public overdueOrders: KnockoutObservableArray<Boo.Objects.PremiumBacklink.OverdueOrder> = ko.observableArray([]);

    public showOverdueOrders: KnockoutObservable<boolean> = ko.observable(false);
    public overdueOrderPartnerName: KnockoutObservable<string> = ko.observable('');
    public filteredOverdueOrders: KnockoutObservableArray<Boo.Objects.PremiumBacklink.OverdueOrder> = ko.observableArray([]);
   
    private user: Boo.Objects.User;
    // File information 
    public content: KnockoutObservable<string> = ko.observable('');
    public filename: KnockoutObservable<string> = ko.observable(null);
    public file: KnockoutObservable<any> = ko.observable(null);

    private csvFormatArray: string[] = ['Boostability Identifier', 'Target Url', 'Blog Url', 'Blog Title', 'Blog Content', 'Team', 'Anchor Text', 'Site DA', 'Publish Date', 'Note'];
    private csvObjectFormat: string[] = ['TaskId', 'TargetUrl', 'TaskActionUrl', 'Title', 'Content', 'UserEmail', 'AnchorText', 'SiteDomainAuthority', 'PublishDate', 'Note'];

    public canActivate(user: Boo.Objects.User, partner: Boo.Objects.Partner): boolean {
        const hasPermission = (launchpad as Launchpad).hasPermission(partner, user.PartnerUsers, permissions.CanManagePremiumBacklink, user);
        return hasPermission;
    }

    constructor(
      private fileUploader: FileUploader,
      private premiumBacklinkService: PremiumBacklinkService,
      private sessionStorageService: SessionStorageService) { }

    public activate(params: any): JQueryPromise<any> {
        return Utils.wrapDfd(this.premiumBacklinkService.getReport())
            .then((report) => {
                this.outstandingOrders(report.OutstandingOrders);
                this.overdueOrders(report.OverdueOrders);
                return $.when(
                    this.loadDownloadHistory(),
                    Utils.wrapDfd(this.sessionStorageService.getUser()).then((user: Boo.Objects.User) => { this.user = user; })
                );
            })
            .fail((errorMessage: string) => {
                toastr.error(errorMessage);
            });
    }

    public preview(): void {
        Utils.wrapDfd(this.premiumBacklinkService.getPreviews())
            .then((previews) => {
                if (previews !== null && previews.length > 0) {
                    this.previewDownloadData(previews);
                } else {
                    toastr.info('There are no tasks available to preview');
                }
            })
            .fail((errorMessage: string) => {
                toastr.error(errorMessage);
            });
    }

    public download(): void {
        Utils.wrapDfd(this.premiumBacklinkService.download(this.user.UserId))
            .then((downloadUrl: string) => {
                this.downloadUrl(downloadUrl);
                this.previewDownloadData([]);
                this.loadDownloadHistory();
            })
            .fail((errorMessage: string) => {
                toastr.error(errorMessage);
            });
    }

    public parseCsv(): void {
        this.isLoading = true;
        const csv: string = this.content();
        const result: PapaParse.ParseResult = Papa.parse(csv, { skipEmptyLines: true });

        try {
            Check.isNotNull(result, 'Cannot read CSV file');
            Check.isCsvFormatValid(this.csvFormatArray, result);

            _.each(result.data.slice(1), (i: any[]) => {
                const item: StringUpload = _.object(this.csvObjectFormat, i) as StringUpload;

                Check.isUrl(item.TaskActionUrl, 'The URL "' + item.TaskActionUrl + '" is not valid');
                Check.isEmail(item.UserEmail, 'The Email "' + item.UserEmail + '" is not valid');
                this.validateDate(item.PublishDate);
            });

            this.fileUploader.upload(this.file(), 'premiumbacklink').then((x) =>
                Utils.wrapDfd(this.premiumBacklinkService.upload(x.FullUrl))
            ).then(() => {
                toastr.info('Upload is complete');

                // Clear file info
                this.file('');
                this.filename('');
                this.content('');
            }).always(() => {
                this.isLoading = false;
            });
        } catch (e) {
            toastr.error(e.message);
            this.isLoading = false;
        }
    }

    public viewOverdueOrders(partnerId: number, partnerName: string): void {
        const overdueOrders = _.filter(this.overdueOrders(), (overdueOrder: Boo.Objects.PremiumBacklink.OverdueOrder): boolean => {
            return overdueOrder.PartnerId === partnerId;
        });

        if (overdueOrders && overdueOrders.length > 0) {
            this.filteredOverdueOrders(overdueOrders);
            this.overdueOrderPartnerName(partnerName);
            this.showOverdueOrders(true);
        } else {
            this.showOverdueOrders(false);
        }
    }

    private loadDownloadHistory(): JQueryPromise<any> {
        return Utils.wrapDfd(this.premiumBacklinkService.getDownloads())
            .then((downloads) => {
                this.downloads(downloads);
            });
    }

    private validateDate(dateString: string): void {
        if (!moment(dateString, 'M/D/YYYY', true).isValid()) {
            throw new Error('The Date "' + dateString + '" is not valid');
        }
    }
}

interface StringUpload {
    AnchorText: string;
    Content: string;
    Note: string;
    PublishDate: string;
    SiteDomainAuthority: string;
    TargetUrl: string;
    TaskActionUrl: string;
    TaskId: string;
    Title: string;
    UserEmail: string;
}
import { Component, OnInit, ViewChild } from '@angular/core';
import System from 'framework/System';
import { LocalProfilesComponent } from '../../../shared/local-profiles/local-profiles/local-profiles.component';
import Utils from '../../../../shared/utils';
import TaskHelper from 'app/specialist/TaskHelper';

@Component({
  selector: 'app-components-specialist-actioncomponents-businessprofilevalidationandaugmentation',
  templateUrl: './BusinessProfileValidationAndAugmentation.component.html'
})
export class BusinessProfileValidationAndAugmentationComponent implements IActionWorkspace, IActivatable, OnInit {
  @ViewChild(LocalProfilesComponent) localProfilesComponent: LocalProfilesComponent;

  actionViewModel: KnockoutObservable<IActionWorkspace>;
  currentAction: KnockoutObservable<Boo.Objects.Action>;
  taskDetails: Boo.Objects.ActionDetail;
  customer: Boo.Objects.Customer;
  manageableTaskTypes: Boo.Objects.Enums.TaskTypeEnum[] = [Boo.Objects.Enums.TaskTypeEnum.BusinessProfileManagement];
  customerUrl: string;
  gbpUrl: string;
  appleMapsUrl: string;
  bingUrl: string;
  yelpUrl: string;
  facebookUrl: string;
  locations: string;
  isLoading = false;
  showSubscriptions = false;
  canAddLocalProfile = false;
  showingBusinessProfileSubscriptionManagement = false;

  canActivate?(_user: Boo.Objects.User, _partner: Boo.Objects.Partner, params: IActionWorkspaceActivationParams): boolean {
    params.controlViewModel(this);
    this.currentAction = params.currentAction;
    this.actionViewModel = ko.observable(null);
    this.taskDetails = this.currentAction().TaskDetails;
    this.customer = params.currentAction().Customer;
    this.customerUrl = params.currentAction().Customer.Url;
    this.showingBusinessProfileSubscriptionManagement = TaskHelper.getRequiresBusinessCitationSubscriptionManagement(this.currentAction().Task);
    
    if (this.showingBusinessProfileSubscriptionManagement) {
      this.showSubscriptions = true;
      this.canAddLocalProfile = true;
    } else {
      this.showSubscriptions = false;
      this.canAddLocalProfile = false;
      this.manageableTaskTypes = [];
    }
    
    return true;
  }

  ngOnInit(): void {
    const encodedCompanyName = encodeURIComponent(this.customer.Name);
    this.gbpUrl = this.customer.Country.GoogleSearchUrl + 'search?pws=0&q=' + encodedCompanyName;
    this.appleMapsUrl = 'https://duckduckgo.com/?q=' + encodedCompanyName + '&t=h_&ia=web&iaxm=maps';
    this.bingUrl = 'https://www.bing.com/search?q=' + encodedCompanyName;
    this.yelpUrl = 'https://www.yelp.com/search?find_desc=' + encodedCompanyName;
    this.facebookUrl = 'https://www.facebook.com/search/pages/?q=' + encodedCompanyName;
    this.locations = TaskHelper.getBusinessProfileValidationAndAugmentationLocations(this.currentAction().Task);
  }

  isStepReadyToReject(): boolean {
    return false;
  }

  isStepReadyToSave(): boolean {
    return false;
  }

  isStepReadyToComplete(): JQueryPromise<boolean> {
    if (this.localProfilesComponent.hasPendingChanges()) {
      toastr.error('You have pending changes. Please save them before completing this task.');
      return System.resolvedPromise(false);
    }

    if (!this.localProfilesComponent.isValid()) {
      return System.resolvedPromise(false);
    }

    this.isLoading = true;
    const preventReload = true;
    if (this.showingBusinessProfileSubscriptionManagement) {
      return Utils.wrapDfd(this.localProfilesComponent.saveSubscriptions(preventReload))
        .then(() => true)
        .fail((displayMessage: string): boolean => {
          toastr.error(displayMessage);
          return false;
        })
        .always(() => this.isLoading = false);
    } else {
      this.isLoading = false;
      return System.resolvedPromise(true);
    }
  }

  isStepReadyToDelete(): boolean {
    return false;
  }
}

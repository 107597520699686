import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class LocalProfileBusinessHourFactory {

  constructor(private formBuilder: UntypedFormBuilder) { }

  create(localProfileId: number, dayOfWeek: number): Boo.Objects.LocalProfiles.LocalProfileBusinessHour {
    return {
      DayOfWeek: dayOfWeek,
      From1: '',
      From2: '',
      IsClosed: true,
      IsOpen24Hours: false,
      LocalProfileBusinessHourId: 0,
      LocalProfileId: localProfileId,
      To1: '',
      To2: ''
    };
  }

  createMissingAndSort(localProfile: Boo.Objects.LocalProfiles.LocalProfile): Boo.Objects.LocalProfiles.LocalProfileBusinessHour[] {
    const businessHours = localProfile.BusinessHours || [];

    for (let dayOfWeek = 0; dayOfWeek <= 6; dayOfWeek++) {
      const hasValueForDay = _.some(localProfile.BusinessHours, (profileBusinessHours) => {
        return profileBusinessHours.DayOfWeek === dayOfWeek;
      });

      if (hasValueForDay) {
        continue;
      }

      businessHours.push(this.create(localProfile.LocalProfileId, dayOfWeek));
    }

  
    businessHours.sort((a: Boo.Objects.LocalProfiles.LocalProfileBusinessHour, b: Boo.Objects.LocalProfiles.LocalProfileBusinessHour) => {
      return a.DayOfWeek - b.DayOfWeek;
    });

    return businessHours;
  }

  createFormArray(businessHours: Boo.Objects.LocalProfiles.LocalProfileBusinessHour[]): UntypedFormArray {
    return this.formBuilder.array(businessHours.flatMap(x => this.createFormGroup(x)));
  }

  createFormGroup(businessHour: Boo.Objects.LocalProfiles.LocalProfileBusinessHour): UntypedFormGroup {
    return this.formBuilder.group({
      dayOfWeek: [businessHour.DayOfWeek, null],
      from1: [businessHour.From1, null],
      from2: [businessHour.From2, null],
      isClosed: [businessHour.IsClosed, null],
      isOpen24Hours: [businessHour.IsOpen24Hours, null],
      localProfileBusinessHourId: [businessHour.LocalProfileBusinessHourId, null],
      localProfileId: [businessHour.LocalProfileId, null],
      to1: [businessHour.To1, null],
      to2: [businessHour.To2, null]
    });
  }
}

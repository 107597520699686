import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GoogleAnalyticsService } from '../../../../services/google-analytics.service';
import { UserService } from '../../../../services/user.service';

@Component({
  selector: 'app-components-specialist-actioncomponents-googleanalyticsaccess',
  templateUrl: './GoogleAnalyticsAccess.component.html'
})
export class GoogleAnalyticsAccessComponent implements OnInit {
  @Input() public customer: Boo.Objects.Customer;
  @Output() public hasGoogleAnalyticsAccess: EventEmitter<boolean> = new EventEmitter<boolean>();

  hasAccess: boolean;

  constructor(
    private googleAnalyticsService: GoogleAnalyticsService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.checkAccess();
  }

  public grantGoogleAnalyticsAccess(): void {
    const user = _.first(this.customer.Users);
    this.userService.impersonate(user.CustomerUserId)
      .subscribe(
        (token: string) => {
          window.open(`${this.customer.Partner.ReportingUrl}/?t=${token}&path=${launchpad.config.mmi.profile}`, '_blank');

          const msg = 'Hey! Did you complete Google Authorization? Hit OK to refresh';
          bootbox.confirm(msg, (result: boolean) => {
            if (result) {
              this.checkAccess();
            }
          });
        },
        (error) => { toastr.error(error, 'User Impersonation Failed'); }
      );
  }

  public revokeAnalyticsAccess(): void {
    bootbox.confirm('Are you sure you want to revoke Google Analytics Access?', (result: boolean) => {
      if (result) {
        this.googleAnalyticsService.revokeAccess(this.customer.CustomerId)
          .subscribe(() => {
            this.checkAccess();
          });
      }
    });
  }

  private checkAccess(): void {
    this.googleAnalyticsService.checkAuthorization(this.customer.CustomerId)
      .subscribe(hasAccess => {
        this.hasAccess = hasAccess;
        this.hasGoogleAnalyticsAccess.emit(this.hasAccess);
      });
  }
}
import { Component, Input, OnInit } from '@angular/core';
import Check from 'framework/Check';
import PresignedUrlRequest from 'app/models/typescript/PresignedUrlRequest';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import ITaskFilesPublicApi = app.components.interfaces.ITaskFilesPublicApi;
import { TaskFileService } from '../../../services/task-file.service';
import Utils from '../../../shared/utils';
import { finalize } from 'rxjs/operators';

type IUploadFileActivateParams = app.interfaces.IUploadFileActivateParams;

@Component({
  selector: 'app-components-specialist-taskfiles',
  templateUrl: './task-files.component.html'
})
export class TaskFilesComponent implements OnInit {
  @Input() options: TaskFilesOptions;
  allowUpload: boolean;

  files: ISourcedTaskFile[] = [];
  isLoading = false;
  useSmallTitle: boolean;

  taskId: number;
  taskIds: number[];
  isSourced: boolean;
  sources: ITaskFileSource[];

  private fileUploadCallback: () => void;
  private uploadType: Boo.Objects.Enums.TaskFileTypeEnum;

  constructor(private taskFileService: TaskFileService) { }

  ngOnInit() {
    Check.isNotEmpty(this.options, 'Options cannot be empty');
    Check.isNotNull(this.options.taskId, 'Task ID cannot be null');

    this.useSmallTitle = this.options.useSmallTitle || false;
    this.allowUpload = !!this.options.allowUpload;
    if (this.options.uploadType) {
      this.uploadType = this.options.uploadType;
    }

    this.isLoading = false;

    this.taskId = this.options.taskId;
    this.isSourced = Boolean(this.options.sources);
    this.sources = this.options.sources;

    if (this.isSourced) {
      // Set default source if primary task source isn't defined
      if (this.sources.every(x => x.taskId !== this.taskId)) {
        this.sources.push({ taskId: this.taskId, source: 'Task' });
      }
      this.taskIds = this.sources.map(x => x.taskId);
    } else {
      this.taskIds = [this.taskId];
    }

    this.isLoading = true;

    if (this.options.apiCallback) {
      this.options.apiCallback(this.getPublicApi());
    }

    this.taskFileService.selectByTaskIds(this.taskIds)
      .pipe(finalize(() => {
        this.isLoading = false;
      }))
      .subscribe((taskFiles: Boo.Objects.TaskFile[]) => {
        this.files = this.isSourced ? this.sourceTaskFiles(taskFiles) : taskFiles as ISourcedTaskFile[];
      }, (errorMessage: string) => {
        toastr.error(errorMessage);
      });
  }

  uploadFile(): void {
    this.isLoading = true;

    PriorityViewModel.show('app-components-shared-uploadfile', this.getUploadFileOptions())
      .then((data: any) => {
        const taskFile = {
          Name: data.FileName,
          TaskFileType: this.uploadType,
          IsDeleted: false,
          TaskId: this.taskId,
          S3Url: data.FullUrl
        } as Boo.Objects.TaskFile;

        return Utils.wrapDfd(this.taskFileService.insertUpdate(taskFile));
      }).then((taskFile: Boo.Objects.TaskFile) => {
        return Utils.wrapDfd(this.taskFileService.selectByTaskIds(this.taskIds));
      }).then((taskFiles: Boo.Objects.TaskFile[]) => {

        this.files = this.isSourced ? this.sourceTaskFiles(taskFiles) : taskFiles as ISourcedTaskFile[];

        if (this.fileUploadCallback) {
          this.fileUploadCallback();
        }
      })
      .fail((displayMessage: string) => {
        if (displayMessage) {
          toastr.error(displayMessage);
        }
      })
      .always(() => {
        this.isLoading = false;
      });
  }

  private sourceTaskFiles(taskFiles: Boo.Objects.TaskFile[]): ISourcedTaskFile[] {
    const sourced = taskFiles as ISourcedTaskFile[];
    sourced.forEach(x => x.source = this.sources.find(y => y.taskId === x.TaskId).source);
    return sourced;
  }

  private getPublicApi(): ITaskFilesPublicApi {
    return {
      getFiles: (): Boo.Objects.TaskFile[] => { return this.files; },
      setFileUploadCallback: (callBack): void => { this.fileUploadCallback = callBack; }
    } as ITaskFilesPublicApi;
  }


  private getUploadFileOptions(): IUploadFileActivateParams {
    const params: IUploadFileActivateParams = {
      preSignedURLRequest: new PresignedUrlRequest()
    };

    params.preSignedURLRequest.BucketName = launchpad.config.S3Buckets.s3staticfiles;
    params.preSignedURLRequest.Folder = 'taskfile/' + this.taskId;
    params.preSignedURLRequest.UseUniqueFileName = true;

    if (this.options.acceptedFileExtensions) {
      params.acceptedFileExtensions = this.options.acceptedFileExtensions;
    } else {
      params.acceptAllFileTypes = true;
    }

    return params;
  }
}

export interface ITaskFileSource {
  taskId: number;
  source: string;
}

interface TaskFilesOptions {
  taskId: number;
  sources?: ITaskFileSource[];
  allowUpload: boolean;
  uploadType: Boo.Objects.Enums.TaskFileTypeEnum;
  apiCallback: (api: ITaskFilesPublicApi) => void;
  useSmallTitle?: boolean;
  acceptedFileExtensions?: string[];
}

interface ISourcedTaskFile extends Boo.Objects.TaskFile {
  source?: string;
}

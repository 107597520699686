export default class ticket {
    public Csr = null as any
    public AssignedUser = null as any
    public AssignedUserId = 0
    public AssociatedTask = null as any
    public AssociatedTaskId = 0
    public CompletedMinutes = 0
    public CustomerId = 0
    public DescendantTickets = null as any
    public DueDate = null as any
    public EstimatedMinutes = 0
    public InsertUser = null as any
    public InsertUserId = 0
    public InsertedDate = "0001-01-01T00:00:00"
    public IsAppointment = false
    public IsApproved = null as any
    public NewNote = null as any
    public ParentTicketId = 0
    public Priority = 0
    public Reason = null as any
    public ScheduledDate = "0001-01-01T00:00:00"
    public ScheduledEndDate = "0001-01-01T00:00:00"
    public StatusDate = "0001-01-01T00:00:00"
    public StatusId = 0
    public StatusName = null as any
    public TicketId = 0
    public TicketNotes = null as any
    public TicketType = null as any
    public TicketTypeId = 0
    public UploadedFile = null as any
}
import { Component, Input, OnInit } from '@angular/core';
import { PartnerUserService } from '../../../services/partner-user.service';
import { OrganizationalTeamService } from '../../../services/organizational-team.service';
import { forkJoin } from 'rxjs';
import { UntypedFormControl, Validators } from '@angular/forms';
import IPriorityViewPublicApi = app.interfaces.IPriorityViewPublicApi;

@Component({
  selector: 'app-components-manager-organizationalteam',
  templateUrl: './OrganizationalTeam.component.html'
})
export class OrganizationalTeamComponent implements OnInit{
  @Input() public organizationalTeamId: number;
  @Input() public priorityViewApi: IPriorityViewPublicApi;
  
  public title = 'Organizational Team';
  public loading: boolean;
  public teams: Boo.OrganizationalTeams.Models.OrganizationalTeam[];
  public boostabilityUsers: Boo.Objects.PartnerUserFragment[];
  public team: Boo.OrganizationalTeams.Models.OrganizationalTeam;
  public activeWhenLoaded = false;
  public nameCtrl: UntypedFormControl;
  public parentTeamIdCtrl: UntypedFormControl;
  public organizationIdCtrl: UntypedFormControl;
  public organizations: any = [
    {
      Value: Boo.OrganizationalTeams.Models.Enums.Organizations.PartnerGrowthManagement,
      Name: 'Partner Growth Management'
    },
    {
      Value: Boo.OrganizationalTeams.Models.Enums.Organizations.CustomerService,
      Name: 'Customer Service'
    },
    {
      Value: Boo.OrganizationalTeams.Models.Enums.Organizations.Fulfillment,
      Name: 'Fulfillment'
    },
    {
      Value: Boo.OrganizationalTeams.Models.Enums.Organizations.Sales,
      Name: 'Sales'
    }
  ];

  private defaultTeam: Boo.OrganizationalTeams.Models.OrganizationalTeam = {
    OrganizationalTeamId: 0,
    Name: '',
    OrganizationId: null,
    ParentTeamId: null,
    ExecutiveUserId: null,
    ManagerUserId: null,
    TeamLeadUserId: null,
    IsActive: true,
    InsertedDate: null } as Boo.OrganizationalTeams.Models.OrganizationalTeam;

    constructor(
      private partnerUserService: PartnerUserService,
      private organizationalTeamService: OrganizationalTeamService) {
    }

    public ngOnInit(): void {
      this.loading = true;
      forkJoin([this.organizationalTeamService.getAllTeams(), this.partnerUserService.getActiveBoostabilityUsers()])
        .subscribe(([teams, boostabilityUsers]) => {
            this.teams = teams;
            this.boostabilityUsers = boostabilityUsers;
            this.team = teams.find(x => x.OrganizationalTeamId === this.organizationalTeamId) || this.defaultTeam;
            this.activeWhenLoaded = this.team.IsActive;
            this.nameCtrl = new UntypedFormControl(this.team.Name, [Validators.required, Validators.maxLength(50)]);
            this.parentTeamIdCtrl = new UntypedFormControl(this.team.ParentTeamId, []);
            this.parentTeamIdCtrl.valueChanges.subscribe(value => {
              if (value) {
                this.organizationIdCtrl.setValue(teams.find(x => x.OrganizationalTeamId === value).OrganizationId);
              }
            });
            this.organizationIdCtrl = new UntypedFormControl(this.team.OrganizationId, [Validators.required]);
            this.loading = false;
          });
    }

    public cancel(): void {
      this.priorityViewApi.reject();
    }

    public save(): void {
      if (!this.isFormValid) {
        return;
      }
      this.loading = true;
      this.team.Name = this.nameCtrl.value;
      this.team.ParentTeamId = this.parentTeamIdCtrl.value;
      this.team.OrganizationId = this.organizationIdCtrl.value;

      this.organizationalTeamService.saveTeam(this.team)
        .subscribe(
          _ => {
            toastr.success('Organizational Team Saved');
            this.priorityViewApi.resolve();
          },
          err => {
            this.loading = false;
            toastr.error(err);
          }
        );
    }

    get isFormValid(): boolean {
      return this.nameCtrl && this.nameCtrl.valid && this.organizationIdCtrl && this.organizationIdCtrl.valid;
    }

    get formIsReady(): boolean {
      return !!this.teams && !!this.boostabilityUsers;
    }

    get organizationIdIsReadOnly(): boolean {
      return !!this.parentTeamIdCtrl.value || !!this.team.OrganizationalTeamId;
    }
}

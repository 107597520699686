import { Directive, Input, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { AbstractControl, ValidationErrors, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ValidationMsgService } from '../validation-msg-service';
import { Position } from '../models/position';

@Directive({
  selector: '[formValidation]'
})
export class FormValidationDirective implements AfterViewInit, OnDestroy {

  @Input('formValidation') formElement: AbstractControl;
  @Input() placement: Position = Position.top;

  private $element: JQuery;
  private element: HTMLInputElement;
  private subscription: Subscription;

  constructor(
    private elementRef: ElementRef,
    private validationMsgService: ValidationMsgService) {
    this.element = this.elementRef.nativeElement;
    this.$element = $(this.element);
  }


  public ngAfterViewInit(): void {
    this.subscription = this.formElement.statusChanges.subscribe(status => {
      if (status === Status.invalid && (this.formElement.dirty || this.formElement.touched) && this.formElement.errors) {
        this.$element.tooltip('dispose');

        this.element.setAttribute('title', this.getErrorMessage());
        this.element.setAttribute('data-orig-title', this.$element.attr('data-orig-title') || this.element.title);

        this.element.classList.add('has-error');

        this.$element.tooltip({
          title: 'Validation Error',
          placement: this.placement,
          container: 'body'
        });
  
        if (this.$element.is(':focus')) {
          this.$element.tooltip('show');
        }
      } else if (status === Status.valid || status === Status.disabled || !this.formElement.touched) {
        this.element.classList.remove('has-error');
        this.$element.tooltip('dispose');

        this.element.setAttribute('title', this.$element.attr('data-orig-title') || this.element.title);
        this.element.removeAttribute('data-orig-title');
      }
    });
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private getErrorMessage() {
    const valErrors: ValidationErrors = this.formElement.errors;
    const errKey = Object.keys(valErrors)[0];
    let condition;
    let alternateMessage;
    switch (errKey) {
      case 'maxlength':
      case 'minlength':
        condition = valErrors[errKey].requiredLength;
        break;
      case 'min':
      case 'max':
        condition = valErrors[errKey][errKey]
        break;
      default:
        if (valErrors[errKey].alternateMessage) {
          alternateMessage = valErrors[errKey].alternateMessage;
        } else {
          condition = valErrors[errKey];
        }
    }

    const conditionStr: string = condition !== undefined ? condition.toString() : null;
    const alternateMessageStr: string = alternateMessage !== undefined ? alternateMessage.toString() : null;
    return this.validationMsgService.getValidationMsg(errKey, conditionStr, alternateMessageStr);
  }
}

enum Status {
  valid = 'VALID',
  invalid = 'INVALID',
  pending = 'PENDING',
  disabled = 'DISABLED'
}

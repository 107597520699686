export default class ActionTimer {
    private static FOUR_HOURS = 14400; // in seconds
    public startTime: moment.Moment = moment.utc();
    public lastTimeClickedContinue: moment.Moment = moment.utc();

    public inactiveSeconds = 0;
    public secondsRemainingToExpire: number = ActionTimer.FOUR_HOURS;
    public isTimeoutConfirmVisible = false;
    public actionTimerTimeoutId: number;
    public inactiveTimerTimeoutId: number;
    public onConfirmCanceled: () => void;

    public timerIsPaused: KnockoutObservable<boolean> = ko.observable(false);

    constructor(onConfirmCanceled: () => void, timerIsPaused?: KnockoutObservable<boolean>) {
        this.onConfirmCanceled = onConfirmCanceled;
        this.timerIsPaused = timerIsPaused;
    }

    /**
     * This is the initial start method for the first time only.
     */
    public start(): void {
        this.startTime = moment.utc();
        this.startTimer();
    }

    public pause(): void {
        if (_.isFunction(this.timerIsPaused)) {
            this.timerIsPaused(true);
        }
        this.inactiveTimerTimeoutId = window.setInterval(
            (): void => {
                this.inactiveSeconds++;
            },
            1000);
        this.stopTimer(this.actionTimerTimeoutId);
        this.secondsRemainingToExpire = this.secondsRemainingToExpire - moment.utc().diff(this.lastTimeClickedContinue, 'seconds');
    }

    public resume(): void {
        if (_.isFunction(this.timerIsPaused)) {
            this.timerIsPaused(false);
        }
        this.stopTimer(this.inactiveTimerTimeoutId);
        this.stopTimer(this.actionTimerTimeoutId);
        this.startTimer();
    }

    public stop(): void {
        if (_.isFunction(this.onConfirmCanceled)) {
            this.onConfirmCanceled();
        }
        this.stopTimer(this.actionTimerTimeoutId);
    }

    public getElapsedSeconds(): number {
        const startTime: moment.Moment = moment(this.startTime);
        const currentActionStartTimeWithOffset: moment.Moment = startTime.add(this.inactiveSeconds, 'seconds');
        const seconds: number = moment(moment.utc()).diff(currentActionStartTimeWithOffset, 'seconds');
        return seconds;
    }

    public getFormattedSeconds(): string {
        const secs: number = this.getElapsedSeconds();
        const hours: number = Math.floor(secs / (60 * 60));

        const divisor_for_minutes: number = secs % (60 * 60);
        const minutes: number = Math.floor(divisor_for_minutes / 60);

        const divisor_for_seconds: number = divisor_for_minutes % 60;
        const seconds: number = Math.ceil(divisor_for_seconds);

        return ((hours < 10) ? '0' : '') + hours + ':' + ((minutes < 10) ? '0' : '') + minutes + ':' + ((seconds < 10) ? '0' : '') + seconds;
    }

    private startTimer(): void {
        this.lastTimeClickedContinue = moment.utc();
        this.actionTimerTimeoutId = window.setTimeout(this.promptActive.bind(this), this.secondsRemainingToExpire * 1000);
    }

    private stopTimer(timerId: number): void {
        clearTimeout(timerId);
    }

    private promptActive(): void {
        this.isTimeoutConfirmVisible = true;
        this.pause();

        const confirmMessage: string = 'You have been inactive for four hours on this task' +
            '</br></br>Click \'OK\' to continue working.' +
            '</br> Click \'Cancel\' to close this task.';
        bootbox.confirm(confirmMessage, (result: boolean) => {
            this.isTimeoutConfirmVisible = false;
            if (result === true) {
                this.lastTimeClickedContinue = moment.utc();
                this.secondsRemainingToExpire = ActionTimer.FOUR_HOURS;
                this.resume();
            } else {
                this.stop();
            }
        });
    }
}

import { Component, ViewChild, OnInit } from '@angular/core';
import { WebsiteService } from '../../../services/website.service';
import { WordpressWebsiteService } from '../../../services/wordpresswebsite.service';
import { NgModel } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-components-managecustomer-wordpresswebsitereports',
  templateUrl: './WordpressWebsiteReports.component.html'
})
export class WordpressWebsiteReportsComponent implements OnInit {
  public window = window;
  public launchpad = launchpad;
  public isLoading = true;
  public wordpressWebsiteReportSummary: Boo.Objects.WordpressWebsiteReportingSummary = null;
  @ViewChild('previewUrl') previewUrlInput: NgModel;
  private customerId: number;

  constructor(private wordpressWebsiteService: WordpressWebsiteService, private websiteService: WebsiteService) {}

  ngOnInit(): void {
    this.wordpressWebsiteService.getReportingSummary(this.customerId)
      .subscribe(
        (data: Boo.Objects.WordpressWebsiteReportingSummary) => this.wordpressWebsiteReportSummary = data,
        (error: string) => toastr.error(error),
        () => this.isLoading = false
      );
  }

  public canActivate(_user: Boo.Objects.User, _partner: Boo.Objects.Partner, params: any): boolean {
    this.customerId = params.customer.CustomerId;
    return true;
  }

  public savePreviewUrl(): void {
    this.isLoading = true;
    this.websiteService.savePreviewUrl(this.wordpressWebsiteReportSummary.WebsiteId, this.wordpressWebsiteReportSummary.PreviewUrl)
      .subscribe(
        () => {
          toastr.success('Saved');
          this.previewUrlInput.reset(this.wordpressWebsiteReportSummary.PreviewUrl);
        },
        (error: string) => toastr.error(error),
        () => this.isLoading = false
      );
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { SessionStorageService } from '../../../services/session-storage.service';

@Component({
	selector: 'app-components-navigation-specialist',
	templateUrl: './Specialist.component.html'
})
export class SpecialistComponent implements OnInit, OnDestroy {
  partner: Boo.Objects.Partner;
  partnerUsers: Boo.Objects.PartnerUser[] = [];
  isBlogSitesVisible = false;
  showBlogSitesSubMenu = false;
  isLockedTasksVisible = false;
  isManager = false;
  blogSitesPages: any = [
    'specialist/blogsites',
    'specialist/awsblogsites',
    'specialist/awsblogsitesoverview',
    'specialist/awsavailabilityzones'
  ];
  canAccessInternalTasks = false;
  showInternalTasksSubMenu = false;
  internalTasksPages: string[] = [
    'specialist/internal-tasks',
    'specialist/lockedinternaltasks'
  ];
  showViewTaskWorkspaceSubMenu: any = ko.observable(false);
  viewTaskWorkspacePages: string[] = [
    'specialist/viewtaskworkspace',
    'specialist/changespecialistscore'
  ];

  private urlChangedHandler: (newHash: string) => void = (newHash: string) => {
    this.urlUpdated(newHash);
  };

  constructor(private sessionStorageService: SessionStorageService) { }

  ngOnInit(): void {
    amplify.subscribe(launchpad.config.events.urlChanged, this.urlChangedHandler);

    forkJoin([
      this.sessionStorageService.getPartner(),
      this.sessionStorageService.getUser(),
      this.sessionStorageService.getPartnerUsers()
    ])
    .subscribe(([partner, user, partnerUsers]) => {
      this.partnerUsers = partnerUsers;
      this.partner = partner;
      this.isManager = partnerUsers.some((pu: any) => pu.UserLevelId === window.launchpad.config.keys.managerUserLevelId);
      const hasAdminUser = user.PartnerUsers.some((item: any) => item.UserLevelId === window.launchpad.config.keys.adminUserLevelId);
      this.isBlogSitesVisible = hasAdminUser && partner.PartnerId === window.launchpad.config.partners.boostability;
      this.canAccessInternalTasks = launchpad.utils.canAccessInternalTasks(user.PartnerUsers);
      if (this.isManager && partner.PartnerId === 1) {
        this.isLockedTasksVisible = true;
      }
    });
  }

  ngOnDestroy(): void {
    amplify.unsubscribe(launchpad.config.events.urlChanged, this.urlChangedHandler);
  }

  canDoCopyTasks: any = ko.computed(() => {
    const partnerUser: any = _.findWhere(this.partnerUsers, { UserLevelId: window.launchpad.config.keys.seoSpecialistUserLevelId });
    return _.isObject(partnerUser) &&
      _.isArray(partnerUser.TaskTypes) &&
      _.some(partnerUser.TaskTypes,
        (taskType: any) => {
          return taskType.TaskTypeEnum === Boo.Objects.Enums.TaskTypeEnum.OnsiteCopyCreateContentStandard;
        });
  });

  private urlUpdated(newHash: any): void {
    if (this.blogSitesPages.indexOf(newHash) > -1) {
      this.showBlogSitesSubMenu = true;
    } else {
      this.showBlogSitesSubMenu = false;
    }
    if (this.internalTasksPages.indexOf(newHash) > -1) {
      this.showInternalTasksSubMenu = true;
    } else {
      this.showInternalTasksSubMenu = false;
    }
    if (this.viewTaskWorkspacePages.indexOf(newHash) > -1) {
      this.showViewTaskWorkspaceSubMenu = true;
    } else {
      this.showViewTaskWorkspaceSubMenu = false;
    }
  }
}

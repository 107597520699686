import { AfterViewInit, Component, Input, OnInit} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import CustomValidators from '../../../shared/custom-validators';

@Component({
  selector: 'app-components-manager-partner-email-settings',
  templateUrl: './partner-email-settings.component.html'
})
export class PartnerEmailSettingsComponent implements OnInit, AfterViewInit {
  @Input() partner: Boo.Objects.Partner;
  emailSettingsForm: UntypedFormGroup;
  
  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.emailSettingsForm = this.formBuilder.group({
        schedulingLink: [this.partner.SchedulingLink, [Validators.required, CustomValidators.validUrl()]],
        onboardingSchedulingLink: [this.partner.OnboardingSchedulingLink, [Validators.required, CustomValidators.validUrl()]]
    });

    this.emailSettingsForm.controls['schedulingLink'].valueChanges.subscribe(value => this.partner.SchedulingLink = value);
    this.emailSettingsForm.controls['onboardingSchedulingLink'].valueChanges.subscribe(value => this.partner.OnboardingSchedulingLink = value);
    this.emailSettingsForm.markAllAsTouched();
  }

  ngAfterViewInit(): void {
    this.emailSettingsForm.controls['onboardingSchedulingLink'].updateValueAndValidity();
    this.emailSettingsForm.controls['schedulingLink'].updateValueAndValidity();
  }

  setIsUserAndContactRestricted(isUserAndContactRestricted: boolean): void {
    this.partner.IsUserAndContactRestricted = isUserAndContactRestricted;
  }
}

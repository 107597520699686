import { Component } from '@angular/core';
import Check from 'framework/Check';
import permissions from 'app/models/Permissions';
import { TabPanelDisplayMode } from 'app/components/enums/TabPanelDisplayMode';
import { CustomerService } from '../../../services/customer.service';

@Component({
  selector: 'app-components-managecustomer-customerworkhistory',
  templateUrl: './CustomerWorkHistory.component.html'
})
export class CustomerWorkHistoryComponent {
  public customer: Boo.Objects.Customer;
  public showWorkflowTaskHistoryTab = false;
  public canViewPendingWork = false;
  public canCloseWorkflow = false;
  public tabPanelDisplayMode = TabPanelDisplayMode;

  constructor(private customerService: CustomerService) { }

  public canActivate(user: Boo.Objects.User, partner: Boo.Objects.Partner, params: any): boolean {
    Check.isNotEmpty(params, 'Params is empty');
    Check.isNotEmpty(params.customer, 'Customer is missing');

    const hasOnlyEverHadBusinessProfileManagement = this.customerService.hasOnlyEverHadProduct(params.customer as any, Boo.Objects.Enums.ProductEnum.BusinessProfileManagement);

    this.showWorkflowTaskHistoryTab = !hasOnlyEverHadBusinessProfileManagement && _.any(user.PartnerUsers, x => x.PartnerId === window.launchpad.config.partners.boostability);
    this.canViewPendingWork = launchpad.hasPermission(partner, user.PartnerUsers, permissions.CanViewCustomerPendingWork, user);
    this.canCloseWorkflow = !hasOnlyEverHadBusinessProfileManagement && launchpad.hasPermission(partner, user.PartnerUsers, permissions.CanCloseWorkflow, user);

    this.customer = ko.mapping.toJS(params.customer);

    return true;
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-components-manager-previewpartnerpricingtoolpricelevel',
  templateUrl: './preview-partner-pricing-tool-price-level.component.html'
})
export class PreviewPartnerPricingToolPriceLevelComponent {
  @Input() public partnerPricingToolPriceLevel: Boo.Objects.SEOPresence.PartnerPricingToolPriceLevel;
  @Input() public languageId: number;
  @Input() public partnerId: number;
  
  private dfd: any;

  close(): void {
    this.dfd.resolve();
  }
}

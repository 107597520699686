import { Component } from '@angular/core';
import permissions from 'app/models/Permissions';
import vertical from 'app/models/vertical';
import { LinkLocationService } from '../../../services/link-location.service';
import { LinkLocationExclusionService } from '../../../services/link-location-exclusion.service';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-manager-linklocation',
  templateUrl: './LinkLocation.component.html'
})
export class LinkLocationComponent {
  public title = 'Link Location';
  public _ = _;
  public dfd: any;
  private constant = {
    english: 1,
    unitedStates: 1,
    notSelectedId: 0
  };
  public links: any = ko.observableArray([]);
  public linkTypes: any = ko.observableArray([]);
  public languages: any = ko.observableArray([]);
  public countries: any = ko.observableArray([]);
  public filteredLinkTypes: any = ko.observableArray([]);
  public isLoading: any = ko.observable(false);
  public newLinkCultureIsActive: any = ko.observable(true);
  public newLinkCultureLanguageId: any = ko.observable(this.constant.notSelectedId);
  public newLinkCultureCountryId: any = ko.observable(this.constant.notSelectedId);
  public newLinkCultureSubmitOrder: any = ko.observable();
  public newLinkLocationPostExpirationDays: any = ko.observable();
  public selectedLinkTypeId: any = ko.observable();
  public selectedVerticalId: any = ko.observable();
  public lastResponseTimeInMilliseconds: any = ko.observable();
  public url: any = ko.observable();
  public isInEditMode: any = ko.observable(false);
  public canEditLinkLocationUrls: any = ko.observable(false);
  verticals: Boo.Objects.Vertical[] = [];

  public get activeLinks(): any[] {
    return _.filter(this.links(), x => x.IsActive());
  }

  public get inactiveLinks(): any[] {
    return _.filter(this.links(), x => !x.IsActive());
  }

  constructor(
    private linkLocationService: LinkLocationService,
    private linkLocationExclusionService: LinkLocationExclusionService,
    private sessionStorageService: SessionStorageService) {
  }

  public linkTypeName: any = ko.computed(() => {
    let result = '';
    if (_.isArray(this.links())) {
      const lt = _.find(this.linkTypes(),
        (link: any) => {
          return link.LinkTypeId === this.selectedLinkTypeId();
        });
      result = _.isObject(lt) ? lt.Name : '';
    }
    return result;
  });

  public verticalName: any = ko.computed(() => {
    let result = '';
    if (_.isArray(this.verticals)) {
      const foundVertical = _.find(this.verticals,
        (vertical: any) => {
          return vertical.VerticalId === this.selectedVerticalId();
        });
      result = _.isObject(foundVertical) ? foundVertical.Name.split('&nbsp;').join('') : '';
    }
    return $.trim(result) !== '' ? result : 'N/A';
  });

  public linkCultureCount: any = ko.computed(() => {
    return this.links().length;
  });

  public isClassifiedListing: any = ko.computed(() => {
    return this.selectedLinkTypeId() === Boo.Objects.Enums.LinkTypeEnum.ClassifiedBusinessListings;
  });

  public cancel(): void {
    // Priority View Logic
    if (this.dfd) {
      this.dfd.reject();
    }
  }

  private persistLinkLocation(): void {
    this.isLoading(true);
    const links = ko.mapping.toJS(this.links());
    for (const link of links) {
      link.Url = this.url();
      link.LinkTypeId = this.selectedLinkTypeId();
      link.VerticalId = this.selectedVerticalId();
    }
    Utils.wrapDfd(this.linkLocationService.saveBatch(links))
      .then(() => {
        toastr.success('Link Location Saved Successfully');
        if (this.dfd) {
          this.dfd.resolve(true);
        }
      })
      .fail((displayMessage) => {  
        toastr.error(displayMessage);
      })
      .always(() => {
        this.isLoading(false);
      });
  }

  private handleSaveBusinessProfileLinkType(): void {
    Utils.wrapDfd(this.linkLocationExclusionService.getAllByLinkTypeId(Boo.Objects.Enums.LinkTypeEnum.LocalBusinessCitations))
      .then((allExclusions: any) => {
        // Check if matches exclusion list
        let matchingDomainExclusions: any = [];
        const groups = _.groupBy(allExclusions,
          (item: any) => {
            return [item.PartnerName, item.Domain].sort();
          });
        _.each(groups,
          (group) => {
            matchingDomainExclusions.push({
              PartnerName: group[0].PartnerName,
              Domain: group[0].Domain
            });
          });
        matchingDomainExclusions = _.filter(matchingDomainExclusions,
          (exclusion: any) => {
            return exclusion.Domain === this.url() || exclusion.Domain.indexOf(this.url()) >= 0 || this.url().indexOf(exclusion.Domain) >= 0;
          });
        matchingDomainExclusions = _.sortBy(matchingDomainExclusions,
          (exclusion: any) => {
            // sort by partnername and domain
            return [exclusion.PartnerName, exclusion.Domain].join('_');
          });
        if (_.isArray(matchingDomainExclusions) && _.any(matchingDomainExclusions)) {
          let exclusionString = '';
          _.each(matchingDomainExclusions,
            (exclusion: any) => {
              exclusionString += `<tr><td>${exclusion.PartnerName}</td><td>${exclusion.Domain}</td></tr>`;
            });
          bootbox.confirm(`<span class='text-danger'>WARNING:</span> The Domain you're attempting to add matches a link location in the exclusion list for the following partners.<br/><br/>If you choose OK, the link location will be saved but will NOT be excluded for these partners. Please contact the Boostability product team with any questions.<br/><br/><table><tr><th>Partner Name</th><th>Domain</th></tr>${exclusionString}</table>`,
            (save: any) => {
              if (save.bind(this)) {
                this.persistLinkLocation();
              }
            });
        } else {
          this.persistLinkLocation();
        }
      })
      .fail((displayMessage) => {
        toastr.error(displayMessage);
      })
      .always(() => {
        this.isLoading(false);
      });
  }

  public pageValidation: any;
  public newLinkCultureValidation: any;
  public onCompleteCallBack: any;

  public save(): void {
    if (this.pageValidation.isValid()) {
      const bootBoxText = $('#linkLocationConfirmMessage').html();
      bootbox.confirm(bootBoxText,
        (saveLinkLocation: any) => {
          if (saveLinkLocation) {
            if (this.selectedLinkTypeId() === Boo.Objects.Enums.LinkTypeEnum.LocalBusinessCitations) {
              this.handleSaveBusinessProfileLinkType();
            } else {
              this.persistLinkLocation();
            }
          }
        });
    } else {
      toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
      this.newLinkCultureValidation.errors.showAllMessages(false);
      this.pageValidation.errors.showAllMessages();
    }
  }

  public canActivate(user: any, partner: any): any {
    this.canEditLinkLocationUrls(!!window.launchpad.hasPermission(partner, user.PartnerUsers, permissions.CanEditLinkLocationUrls, user));
    return _.any(user.PartnerUsers,
      (item: any) => {
        return item.UserLevelId === 1 || item.UserLevelId === 5;
      });
  }

  public activate(options: any): void {
    // Priority View Required
    if (options.dfd) {
      this.dfd = options.dfd;
    }
    this.clearUserForm();
    this.getStaticData();
    this.onCompleteCallBack = options.onCompleteCallBack;
    this.isInEditMode(options.isInEditMode);
    const selectedLinks = options.selectedLinks;
    this.filteredLinkTypes(options.filteredLinkTypes);
    this.selectedLinkTypeId(options.linkTypeId);
    if (options.isInEditMode && _.isArray(selectedLinks)) {
      for (const selectedLink of selectedLinks) {
        // if url is not set then set it based upon the first link.
        // Note: all links share the same linktypeId, Url, VerticalId
        this.url(selectedLink.Url);
        selectedLink.IsNew = false;
        const link = ko.mapping.fromJS(selectedLink);
        if (isNaN(this.selectedVerticalId())) {
          this.selectedVerticalId(selectedLink.VerticalId);
        }
        if (isNaN(this.lastResponseTimeInMilliseconds())) {
          this.lastResponseTimeInMilliseconds(selectedLink.LastResponseTimeInMilliseconds);
        }
        // All classified listings must have a post expiration
        if (this.isClassifiedListing() && !ko.utils.unwrapObservable(link.LinkLocationPostExpiration)) {
          link.LinkLocationPostExpiration = ko.mapping.fromJS({
            LinkLocationPostExpirationId: 0,
            LinkLocationId: link.LinkLocationId,
            ExpirationDays: 0
          });
        }
        this.links.push(link);
      }
    }
    this.setupValidation();
    this.linkTypes(options.linkTypes);
    this.newLinkCultureIsActive(true);
    this.pageValidation.errors.showAllMessages(false);
    this.newLinkCultureValidation.errors.showAllMessages(false);
  }

  private setupValidation(): void {
    this.linkCultureCount.extend({
      validation: {
        validator: (val: any) => {
          return val > 0;
        },
        message: 'At least one link culture is required'
      }
    });
    this.selectedVerticalId.extend({
      validation: [
        {
          validator: (val: any) => {
            return true;
          },
          message: 'Vertical is required'
        }
      ]
    });
    this.newLinkCultureSubmitOrder.extend({
      validation: [
        {
          validator: (val: any) => {
            return parseInt(val, 10) > 0;
          },
          message: 'Submit Order is required'
        }
      ]
    });
    this.newLinkLocationPostExpirationDays.extend({
      validation: [
        {
          validator: (val: any) => {
            let result = true;
            if (this.isClassifiedListing()) {
              result = parseInt(val, 10) > 0;
            }
            return result;
          },
          message: 'Expiration days must be a number greater than zero'
        }
      ]
    });
    this.newLinkCultureLanguageId.extend({
      validation: {
        validator: (val: any) => {
          return val > 0;
        },
        message: 'Please Select a Language'
      }
    });
    this.newLinkCultureCountryId.extend({
      validation: {
        validator: (val: any) => {
          return val > 0;
        },
        message: 'Please Select a Country'
      }
    });
    this.url.extend({
      required: {
        onlyIf: () => {
          return !this.isInEditMode();
        },
        message: 'The Link Location Url is required.'
      }
    });
    this.url.extend({
      validation: [
        {
          validator: (val: any) => {
            return window.launchpad.utils.isValidUrl(val);
          },
          message: 'Not a valid URL.'
        }
      ]
    });
    const validatedPageProperties = [this.url, this.linkCultureCount, this.selectedVerticalId];
    _.each(this.links(),
      (link: any) => {
        if (!link.LinkLocationPostExpiration || !link.LinkLocationPostExpiration.ExpirationDays) {
          return; // Only validate if has LinkLocationPostExpiration
        }
        link.LinkLocationPostExpiration.ExpirationDays.extend({
          validation: [
            {
              validator: (val: any) => {
                let result = true;
                if (this.isClassifiedListing()) {
                  result = parseInt(val, 10) > 0;
                }
                return result;
              },
              message: 'Expiration days must be number greater than zero'
            }
          ]
        });
        validatedPageProperties.push(link.LinkLocationPostExpiration.ExpirationDays);
      });
    this.pageValidation = ko.validatedObservable(validatedPageProperties);
    this.newLinkCultureValidation = ko.validatedObservable([this.newLinkCultureSubmitOrder, this.newLinkCultureLanguageId, this.newLinkCultureCountryId, this.newLinkLocationPostExpirationDays]);
  }

  public addNewLinkCulture(): void {
    if (this.newLinkCultureValidation.isValid()) {
      if (this.newLinkCultureIsDuplicate() === false) {
        const newLink: any = {};
        newLink.LinkTypeId = this.selectedLinkTypeId();
        newLink.LanguageId = this.newLinkCultureLanguageId();
        newLink.CountryId = this.newLinkCultureCountryId();
        newLink.IsActive = this.newLinkCultureIsActive();
        newLink.SubmitOrder = this.newLinkCultureSubmitOrder();
        newLink.LinkLocationPostExpiration = { ExpirationDays: this.newLinkLocationPostExpirationDays(), LinkLocationId: 0, LinkLocationPostExpirationId: 0 };
        newLink.IsNew = true;
        newLink.LinkLocationId = 0;
        const link = ko.mapping.fromJS(newLink);
        link.linkLanguage = ko.computed(() => {
          const foundLanguage = _.find(this.languages(), (language: any) => { return language.LanguageId === link.LanguageId(); });
          return _.isObject(foundLanguage) ? foundLanguage.Name : '';
        });
        link.linkCountry = ko.computed(() => {
          const foundCountry = _.find(this.countries(), (country: any) => { return country.CountryId === link.CountryId(); });
          return _.isObject(foundCountry) ? foundCountry.Name : '';
        });
        this.links.push(link);
      } else {
        toastr.error('Cannot insert a duplicate link culture.');
      }
    } else {
      toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
      this.pageValidation.errors.showAllMessages(false);
      this.newLinkCultureValidation.errors.showAllMessages();
    }
  }

  public setIsActive(link: any): void {
    link.IsActive(!link.IsActive());
  }

  public deleteLink(link: any): void {
    this.links.remove(link);
    this.newLinkCultureValidation.errors.showAllMessages(false);
    this.pageValidation.errors.showAllMessages(false);
  }

  public setNewLinkCultureIsActive(): void {
    this.newLinkCultureIsActive(!this.newLinkCultureIsActive());
  }

  private clearUserForm(): void {
    // Observables
    this.newLinkCultureLanguageId(this.constant.notSelectedId);
    this.newLinkCultureCountryId(this.constant.notSelectedId);
    this.selectedVerticalId(undefined);
    this.newLinkCultureIsActive(true);
    this.isInEditMode(false);
    this.selectedLinkTypeId('');
    this.url('');
    // Observable Arrays
    this.links([]);
    this.linkTypes([]);
    this.languages([]);
    this.countries([]);
  }

  private newLinkCultureIsDuplicate(): any {
    let isValid = false;
    // do not add a new link culture when one already exists with the same countryId and languageId
    const existingLinks = _.find(ko.mapping.toJS(this.links()),
      (link: any) => {
        return link.CountryId === this.newLinkCultureCountryId() && link.LanguageId === this.newLinkCultureLanguageId();
      });
    if (_.isObject(existingLinks)) {
      isValid = true;
    }
    return isValid;
  }

  private getStaticData(): void {
    $.when(Utils.wrapDfd(this.sessionStorageService.getStaticData())).done((staticData) => {
      this.languages(staticData.Languages.filter((x: any) =>
        x.SupportStatusId === window.launchpad.config.supportStatus.supported ||
        x.SupportStatusId === window.launchpad.config.supportStatus.settingUp));
      this.countries(staticData.Countries.filter(
        (x: any) => x.SupportStatusId === window.launchpad.config.supportStatus.supported ||
          x.SupportStatusId === window.launchpad.config.supportStatus.settingUp));
      this.verticals = staticData.Verticals;
    });
  }
}

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { QuillConfig } from '../../../../shared/quill/quill-config';
import Quill from 'quill';
import CustomValidators from '../../../../shared/custom-validators';
import Utils from '../../../../shared/utils';

@Component({
  selector: 'app-components-specialist-actioncomponents-standardbacklink',
  templateUrl: './standard-backlink.html'
})
export class StandardBacklinkComponent implements IActivatable, IActionWorkspace, OnInit {
  // Declared but intentionally not used in this component
  actionViewModel: KnockoutObservable<IActionWorkspace>;
  currentAction: KnockoutObservable<Boo.Objects.Action>;

  isLoading = false;
  showRevisionWarning = false;
  specialConsiderations = '';
  contentTitle = '';
  businessCategory = '';
  targetKeyword = '';
  linkStategyType = '';
  additionalKeywords: string[] = [];
  customer: Boo.Objects.Customer;
  taskDetails: Boo.Objects.ActionDetail;
  task: Boo.Objects.Task;
  taskPost: Boo.Objects.TaskPost;
  minimumWordsCount = 0;
  hasAdditionalKeywords = false;
  titleControl: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.maxLength(128)]);
  content: UntypedFormControl = new UntypedFormControl('', [Validators.required]);
  formGroup: UntypedFormGroup = new UntypedFormGroup({ title: this.titleControl, content: this.content });
  editorToolbar: any = {
    toolbar: [
      {
        name: 'document',
        items: ['Source', 'Maximize']
      },
      {
        name: 'clipboard',
        groups: ['clipboard', 'undo'],
        items: ['Cut', 'Copy', 'Paste', 'PasteText',
          'PasteFromWord', '-', 'Undo', 'Redo']
      },
      {
        name: 'editing',
        groups: ['find', 'selection', 'spellchecker'],
        items: ['Find', 'Replace', '-', 'SelectAll']
      },
      {
        name: 'links',
        items: ['Link', 'Unlink', 'LinkCustomer']
      },
      '/', // new row
      {
        name: 'basicstyles',
        groups: ['basicstyles', 'cleanup'],
        items: ['Bold', 'Italic', 'Underline', 'Strike',
          'Subscript', 'Superscript', '-',
          'RemoveFormat']
      },
      {
        name: 'paragraph',
        groups: ['list', 'indent', 'blocks', 'align', 'bidi'],
        items: ['NumberedList', 'BulletedList', '-', 'Outdent',
          'Indent', '-', 'Blockquote', '-',
          'JustifyLeft', 'JustifyCenter', 'JustifyRight',
          'JustifyBlock']
      }
    ]
  };

  quillModules: any = QuillConfig.GetDefaults().modules;

  canActivate?(user: Boo.Objects.User, partner: Boo.Objects.Partner, params: IActionWorkspaceActivationParams): boolean {
    this.currentAction = params.currentAction;
    this.customer = params.currentAction().Customer;
    this.taskDetails = params.currentAction().TaskDetails;
    this.task = params.currentAction().Task;
    this.taskPost = params.currentAction().TaskDetails.TaskPost || {} as Boo.Objects.TaskPost;
    this.quillModules.linkCustomerUrl = {
      customerUrl: this.currentAction().TaskDetails.WebsiteUrl.Url
    }
    params.controlViewModel(this);
    return true;
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.loadKeywords();
    this.specialConsiderations = this.customer.Considerations ? this.customer.Considerations : 'N/A';
    this.linkStategyType = this.taskDetails.TaskLinkStrategyDetail.LinkStrategy.Name;
    this.titleControl.setValue(this.taskPost.ContentTitle);
    this.minimumWordsCount = this.currentAction().Task.LanguageId === Boo.Objects.Enums.LanguageEnum.Finnish ? 300 : 400;
    this.content.setValue(this.taskPost.Content);    
    this.showRevisionWarning = this.task.ExternalEditCount >= 1;

    this.isLoading = false;
  }

  isStepReadyToSave(): boolean {
    this.currentAction().TaskDetails.TaskPost = this.taskPost;
    this.currentAction().TaskDetails.TaskPost.ContentTitle = this.titleControl.value ?? '';
    this.currentAction().TaskDetails.TaskPost.Content = this.content.value ?? '';
    return true;
  }

  isStepReadyToComplete(): boolean {
    if (!this.formGroup.valid) {
      Utils.showAllMessages(this.formGroup);
      return false;
    }

    this.currentAction().TaskDetails.TaskPost = this.taskPost;
    this.currentAction().TaskDetails.TaskPost.ContentTitle = this.titleControl.value;
    this.currentAction().TaskDetails.TaskPost.Content = this.content.value;
    return true;
  }

  isStepReadyToDelete(): boolean {
    return false;
  }

  isStepReadyToReject(): boolean {
    return false;
  }

  contentEditorCreated(editor: Quill) {
    if (this.content) {
      this.content.addValidators(CustomValidators.quillWordCountValidator(editor, this.minimumWordsCount));
    }
  }

  private loadKeywords(): void {
    this.targetKeyword = this.task.Keyword?.Name || 'N/A';

    this.additionalKeywords = this.taskDetails.WebsiteUrl.Keywords
      .filter(word => word.IsActive && (word.KeywordId === this.task.KeywordId && word.AreaId === this.task.AreaId))
      .map(word => launchpad.utils.getKeywordPhrase(word));

    this.hasAdditionalKeywords = this.additionalKeywords.length > 0;
  }
}

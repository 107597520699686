import { Component } from '@angular/core';
import System from 'framework/System';
import Check from 'framework/Check';
import { TabPanelDisplayMode } from 'app/components/enums/TabPanelDisplayMode';

@Component({
	selector: 'app-components-managecustomer-files',
	templateUrl: './Files.component.html'
})
export class FilesComponent implements IActivatable {
    public window = window;
    public launchpad = launchpad;
    public title = 'Files';
    public customer: CustomerObservable;
    public customerId: number;
    public tabPanelDisplayMode = TabPanelDisplayMode;

    public activate(params: any): JQueryPromise<any> {
        Check.isNotEmpty(params.customer, 'Customer is required');
        this.customer = params.customer;
        this.customerId = params.customer.CustomerId();
        return System.emptyPromise();
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TaskFeedbackService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/taskfeedback`;

  constructor(private http: HttpClient) { }

  get(): Observable<Boo.Objects.TaskFeedback[]> {
    return this.http.get<Boo.Objects.TaskFeedback[]>(`${this.baseUrl}/Get`);
  }
}

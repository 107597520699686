<div>
  <div class="card">
    <div class="card-header">
      Task Import/Export
    </div>
    <div class="card-body">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a href="#import" class="nav-link active" data-toggle="tab">Import File</a>
        </li>
        <li class="nav-item">
          <a href="#export" class="nav-link" data-toggle="tab">Export File</a>
        </li>
        <li class="nav-item">
          <a href="#failures" class="nav-link" data-toggle="tab">Failures</a>
        </li>
      </ul>
      <div class="tab-content">
        <div class="tab-pane active" id="import">
          <div class="input-group">
            <input [ngModel]="filename()" (ngModelChange)="filename($event)"
              [ngModelOptions]="{ updateOn: 'blur', standalone: true }" class="form-control" type="text" disabled="" />
            <span class="btn btn-primary btn-file">
              Select a File
              <input
                [fileReader]="{ data: content, accept: '.csv', method: 'readAsText', filename: filename, file: file }"
                type="file" class="btn btn-outline-secondary" style="width: 100%;" />
            </span>
          </div>
          <div class="row">
            <div class="col-md-4">
              <select [ngModel]="taskType()" (ngModelChange)="taskType($event)" [ngModelOptions]="{ standalone: true }"
                class="form-select float-end">
                <option *ngFor="let option of taskTypes()" [ngValue]="option">{{ option.Name }}</option>
              </select>
            </div>
            <div class="col-md-4">
              <a [attr.href]="csvFormatExamples[taskType().TaskTypeId]" target="_blank">Example CSV</a>
            </div>
            <div class="col-md-12">
              <button (click)="uploadFile()" class="btn btn-success float-end">
                <i class="fa fa-upload"></i>
                Upload CSV
              </button>
            </div>
          </div>
          <ng-container *ngIf="taskSubmissions.length">
            <hr />
            <div class="row">
              <div class="col-md-12">
                <button (click)="updateSubmisions()" class="btn btn-info float-end">
                  <i class="fas fa-sync"></i>
                  Refresh
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label>Failures</label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <app-table [items]="failedTaskSubmissions" [pageSize]="5">
                  <ng-template #tableHeader>
                    <tr>
                      <th sort field="Data">Data</th>
                      <th sort field="ProcessingStatus">Status</th>
                      <th sort field="FailureReason">Reason</th>
                    </tr>
                  </ng-template>
                  <ng-template #tableBody let-submission>
                    <tr>
                      <td>{{submission.Data}}</td>
                      <td>{{processingStatusLookup[submission.ProcessingStatus]}}</td>
                      <td>{{submission.FailureReason}}</td>
                    </tr>
                  </ng-template>
                </app-table>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label>Records</label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <app-table [items]="taskSubmissions" [pageSize]="5">
                  <ng-template #tableHeader>
                    <tr>
                      <th sort field="Data">Data</th>
                      <th sort field="ProcessingStatus">Status</th>
                    </tr>
                  </ng-template>
                  <ng-template #tableBody let-submission>
                    <tr>
                      <td>{{submission.Data.substring(0, 100)}}</td>
                      <td>{{processingStatusLookup[submission.ProcessingStatus]}}</td>
                    </tr>
                  </ng-template>
                </app-table>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="tab-pane" id="export">
          <div class="row">
            <div class="col-4">
              <label class="control-label">Task Type</label>
              <select [ngModel]="taskType()" (ngModelChange)="onTaskTypeChanged($event)"
                [ngModelOptions]="{ standalone: true }" class="form-select">
                <option *ngFor="let option of taskTypes()" [ngValue]="option">{{ option.Name }}</option>
              </select>
            </div>
            <div class="col-3">
              <label class="control-label">Languages</label>
              <select *ngIf="!allLanguages" [(ngModel)]="selectedLanguages" [ngModelOptions]="{ standalone: true }"
                [select2]="{ closeOnSelect: false }" multiple="multiple" class="form-control">
                <option *ngFor="let option of languageOptions" [ngValue]="option">{{ option.Name }}</option>
              </select>
              <select *ngIf="allLanguages" class="form-select">
                <option selected="selected">All</option>
              </select>
            </div>
            <div class="col-3" *ngIf="showTaskCount">
              <label class="control-label">Number to Export (Optional)</label>
              <input [(ngModel)]="taskCountInput" class="form-control" type="number" min="0" />
            </div>
            <div>
              <button (click)="export()" class="btn btn-primary" [disabled]="isLoading">Export</button>
            </div>
          </div>
          <ng-container *ngIf="downloadUrl()">
            <hr />
            <div class="row">
              <div class="col-md-12">
                You can now download the .CSV at
                <a [attr.href]="downloadUrl()" target="_blank">{{downloadUrl()}}</a>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="noTasks()">
            <hr />
            <div class="row">
              <div class="col-md-12">No tasks available</div>
            </div>
          </ng-container>
          <hr />
          <div class="row">
            <div class="col-md-12">
              <app-table [items]="exports" [pageSize]="5">
                <ng-template #tableHeader>
                  <tr>
                    <th sort desc field="InsertedDate">Export Date</th>
                    <th sort field="User.Username">User</th>
                    <th sort field="TaskTypeId">Task Type</th>
                    <th sort field="Language">Language</th>
                    <th sort field="Url">File</th>
                  </tr>
                </ng-template>
                <ng-template #tableBody let-export>
                  <tr>
                    <td>{{export.InsertedDate | dateText }}</td>
                    <td>{{export.User.FirstName + ' ' + export.User.LastName}}</td>
                    <td>{{taskTypeLookup[export.TaskTypeId]}}</td>
                    <td>{{export.Languages !== null ? displayLanguages(export.Languages) : '-'}}</td>
                    <td>
                      <a [attr.href]="export.Url" target="_blank" class="btn btn-primary">
                        <i class="fas fa-external-link-square-alt"></i>
                        Download
                      </a>
                    </td>
                  </tr>
                </ng-template>
              </app-table>
            </div>
          </div>
        </div>
        <div class="tab-pane" id="failures">
          <div class="row">
            <div class="col-md-12">
              <app-table [items]="allFailures" [pageSize]="5" [exportFilename]="'task-submission-failures'">
                <ng-template #tableHeader>
                  <tr>
                    <th export sort field="Data">TaskId</th>
                    <th export sort field="FailureReason">Reason</th>
                    <th export sort field="TaskTypeId">Selected Upload Task Type</th>
                    <th export sort field="InsertedDate">Upload Date</th>
                    <th export field="UploadUrl"></th>
                    <th></th>
                  </tr>
                </ng-template>
                <ng-template #tableBody let-submission>
                  <tr>
                    <td>{{JSON.parse(submission.Data).TaskId}}</td>
                    <td>{{submission.FailureReason}}</td>
                    <td>{{taskTypeLookup[submission.TaskTypeId]}}</td>
                    <td>{{submission.InsertedDate | dateText: 'MM/DD/YYYY h:mm a' : false }}</td>
                    <td>
                      <a [attr.href]="submission.UploadUrl" target="_blank" class="btn btn-primary">
                        <i class="fas fa-external-link-square-alt"></i>
                        Uploaded File
                      </a>
                    </td>
                    <td>
                      <button class="btn btn-success" (click)="completeSubmission(submission)"><i
                          class="fas fa-check-circle"></i> Mark
                        Complete</button>
                    </td>
                  </tr>
                </ng-template>
              </app-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
import { Component } from '@angular/core';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { ProTipService } from '../../../services/pro-tip.service';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-manager-protip',
  templateUrl: './ProTip.component.html'
})
export class ProTipComponent {
    public isLoading: KnockoutObservable<boolean>;
    public title: string;
    public partnerName: string;
    protected priorityViewApi: app.interfaces.IPriorityViewPublicApi;
    public languages: Boo.Objects.Language[] = null;
    public proTip: ProTipObservable;
    public startDate: KnockoutObservable<moment.Moment>;
    public endDate: KnockoutObservable<moment.Moment>;
    public dateFormat = 'M/DD/YYYY';
    public dateTimeFormat = 'M/DD/YYYY h:mm a';
    public pageValidation: KnockoutObservable<any>;

    private maxUploadSizeInMB = 3;

    constructor(
      private proTipService: ProTipService,
      private sessionStorageService: SessionStorageService) { }

    public activate(params: app.manager.IProTipViewModelActivateParams): JQueryPromise<void> {
        if (params.proTip === undefined) {
            this.proTip = this.buildNewProTip(params.partnerId, params.languageId);
            this.startDate = ko.observable(moment());
            this.endDate = ko.observable(moment());
        } else {
            this.proTip = ko.mapping.fromJS(params.proTip);
            this.startDate = ko.observable(moment(this.proTip.StartDate()));
            this.endDate = ko.observable(moment(this.proTip.EndDate()));
        }

        this.partnerName = params.partnerName;
        this.title = `${params.proTip ? 'Create' : 'Edit'} Pro Tip`;
        this.priorityViewApi = params.priorityViewApi;

        this.proTip.Hyperlink.extend({
            validation: {
                validator: (value: string): boolean => {
                    return this.validateUrl(value);
                },
                message: 'Value must be a valid Url'
            }
        });

        this.proTip.Body.extend({
            required: {
                message: 'Body is required to save Pro Tip.',
            }
        });

        this.proTip.HyperlinkText.extend({
            required: {
                message: 'Hyperlink Text is required if Hyperlink is provided',
                onlyIf: (): boolean => {
                    return this.proTip.Hyperlink() ? true : false;
                }
            }
        });

        this.pageValidation = ko.validatedObservable({
            Hyperlink: this.proTip.Hyperlink,
            Body: this.proTip.Body,
            HyperlinkText: this.proTip.HyperlinkText
        });

        return Utils.wrapDfd(this.sessionStorageService.getStaticData())
            .then((staticData: any) => {
                this.languages = staticData.Languages;
            }).fail((displayMessage: string) => {
                toastr.error(displayMessage);
            });
    }

    public close(): void {
        this.priorityViewApi.resolve();
    }

    public save(): void {
        if (!this.pageValidation.isValid()) {
            this.pageValidation.errors.showAllMessages();
            toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
            return;
        }

        if (this.startDate() > this.endDate()) {
            toastr.error('End Date must be after Start Date');
            return;
        }

        this.proTip.StartDate(this.startDate().toDate());
        this.proTip.EndDate(this.endDate().toDate());

        Utils.wrapDfd(this.proTipService.save(ko.mapping.toJS(this.proTip)))
            .then(() => {
                toastr.success('Pro Tip Saved');
                this.close();
            })
            .fail((displayMessage: string): void => {
                toastr.error(displayMessage);
            });
    }

    public removeImage(): void {
        this.proTip.ImageUrl('');
    }

    public uploadImage(): void {
        const request = {
            BucketName: launchpad.config.S3Buckets.s3staticfiles,
            Folder: `partners/${this.proTip.PartnerId()}/protips`,
            UseUniqueFileName: true,
            ContentSize: 0,
            ContentType: '',
            Filename: ''
        };
        PriorityViewModel.show('app-components-shared-uploadfile', { preSignedURLRequest: request, maxUploadInMB: this.maxUploadSizeInMB })
            .then((uploadResult: any) => {
                if (uploadResult.FullUrl) {
                    this.proTip.ImageUrl(uploadResult.FullUrl);
                } else {
                    toastr.error('Could not upload image');
                }
            });
    }

    private validateUrl(val: string): boolean {
        let result = true;

        if ($.trim(val)) {
            result = launchpad.utils.isValidUrl(val);
        }

        return result;
    }

    private buildNewProTip(partnerId: number, languageId: number): ProTipObservable {
        return {
            ProTipId: ko.observable(null),
            PartnerId: ko.observable(partnerId),
            LanguageId: ko.observable(languageId),
            Title: ko.observable(null),
            ImageUrl: ko.observable(null),
            Body: ko.observable(null),
            Hyperlink: ko.observable(null),
            HyperlinkText: ko.observable(null),
            StartDate: ko.observable(null),
            EndDate: ko.observable(null),
            InsertedDate: ko.observable(null)
        };
    }
}

import { Component } from '@angular/core';
import permissions from 'app/models/Permissions';
import Check from 'framework/Check';
import TaskTypeEnum = Boo.Objects.Enums.TaskTypeEnum;
import LanguageEnum = Boo.Objects.Enums.LanguageEnum;
import FileUploader from 'framework/FileUploader';
import { TaskImportExportService } from '../../../services/task-import-export.service';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';

@Component({
    selector: 'app-components-manager-taskimportexport',
    templateUrl: './TaskImportExport.component.html'
})
export class TaskImportExportComponent implements IActivatable {
    public isLoading = false;
    public file: KnockoutObservable<any>;
    public filename: KnockoutObservable<string>;
    public content: KnockoutObservable<string>;
    public exports: Boo.Objects.ExportedTasksDownload[] = [];
    public downloadUrl: KnockoutObservable<string>;
    public noTasks: KnockoutObservable<boolean>;
    public taskType: KnockoutObservable<Boo.Objects.TaskType>;
    public taskTypes: KnockoutObservable<Boo.Objects.TaskType[]>;
    public taskLanguages: KnockoutObservable<Boo.Objects.Language[]>;
    public taskCountInput: string;
    public showTaskCount: boolean;
    public failedTaskSubmissions: Boo.Objects.TaskExport.TaskSubmission[] = [];
    public taskSubmissions: Boo.Objects.TaskExport.TaskSubmission[] = [];
    public allFailures: Boo.Objects.TaskExport.TaskSubmission[] = [];
    public batchId: string;
    public languageOptions: LanguageOption[];
    public selectedLanguages: LanguageOption[] = [];
    public allLanguages: boolean;
    public JSON = JSON;

    public taskTypeLookup: Record<number, string>;
    public languageLookup: Record<number, string>;
    public csvFormatExamples: Record<number, string>;
    processingStatusLookup: Record<number, string> = {
      [Boo.Objects.TaskExport.TaskSubmissionProcessingStatuses.Complete]: 'Complete',
      [Boo.Objects.TaskExport.TaskSubmissionProcessingStatuses.Failed]: 'Failed',
      [Boo.Objects.TaskExport.TaskSubmissionProcessingStatuses.New]: 'New',
      [Boo.Objects.TaskExport.TaskSubmissionProcessingStatuses.InProgress]: 'InProgress'
    };

    private csvFormats: Record<number, string[]>;
    private user: Boo.Objects.User;

    private supportedTaskTypes: TaskTypeEnum[] = [
        TaskTypeEnum.StandardBacklink,
        TaskTypeEnum.OnsiteBloggingCreateContentStandard,
        TaskTypeEnum.OnsiteBloggingCreateContentPremium,
        TaskTypeEnum.OnsiteCopyCreateContentStandard,
        TaskTypeEnum.InternalTaskSiteThemesCreateContent,
        TaskTypeEnum.AuthoritativeArticle,
        TaskTypeEnum.AgedArticleInclusion,
        TaskTypeEnum.ProfessionalBacklink,
        TaskTypeEnum.ProfessionalInternationalBacklink
    ];

    private supportedLanguages: number[] = [
        LanguageEnum.German,
        LanguageEnum.Dutch,
        LanguageEnum.Finnish,
        LanguageEnum.French,
        LanguageEnum.FrenchCA,
        LanguageEnum.English,
        LanguageEnum.EnglishUK,
        LanguageEnum.EnglishAU,
        LanguageEnum.Spanish
    ];

    constructor(
        private fileUploader: FileUploader,
        private taskImportExportService: TaskImportExportService,
        private sessionStorageService: SessionStorageService) { }

    activate(params: any): JQueryPromise<void> {
        this.file = ko.observable(null);
        this.filename = ko.observable('');

        this.content = ko.observable('');

        this.downloadUrl = ko.observable('');
        this.noTasks = ko.observable(false);

        this.csvFormats = {};
        this.csvFormats[TaskTypeEnum.StandardBacklink] = ['TaskId', 'Title', 'Content'];
        this.csvFormats[TaskTypeEnum.OnsiteBloggingCreateContentStandard] = ['TaskId', 'Title', 'Content', 'ResearchSources'];
        this.csvFormats[TaskTypeEnum.OnsiteBloggingCreateContentPremium] = this.csvFormats[TaskTypeEnum.OnsiteBloggingCreateContentStandard];
        this.csvFormats[TaskTypeEnum.OnsiteCopyCreateContentStandard] = ['TaskId', 'Title', 'Content', 'Description', 'H1', 'H2'];
        this.csvFormats[TaskTypeEnum.InternalTaskSiteThemesCreateContent] = ['TaskId', 'Title', 'Purpose'];
        this.csvFormats[TaskTypeEnum.AuthoritativeArticle] = ['TaskId', 'TaskActionUrl'];
        this.csvFormats[TaskTypeEnum.ProfessionalBacklink] = ['TaskId', 'TaskActionUrl'];
        this.csvFormats[TaskTypeEnum.ProfessionalInternationalBacklink] = ['TaskId', 'TaskActionUrl'];
        this.csvFormats[TaskTypeEnum.AgedArticleInclusion] = ['TaskId', 'ActionUrl'];

        this.csvFormatExamples = {};
        this.csvFormatExamples[TaskTypeEnum.StandardBacklink] = 'https://s3.amazonaws.com/s3staticfiles/taskexport/upload/example/StandardBacklinkExample.csv';
        this.csvFormatExamples[TaskTypeEnum.OnsiteBloggingCreateContentStandard] = 'https://s3.amazonaws.com/s3staticfiles/taskexport/upload/example/OnsiteBlogPostExample.csv';
        this.csvFormatExamples[TaskTypeEnum.OnsiteBloggingCreateContentPremium] = 'https://s3.amazonaws.com/s3staticfiles/taskexport/upload/example/OnsiteBlogPostExample.csv';
        this.csvFormatExamples[TaskTypeEnum.OnsiteCopyCreateContentStandard] = 'https://s3.amazonaws.com/s3staticfiles/taskexport/upload/example/OnsiteCopyCreateContentExmple.csv';
        this.csvFormatExamples[TaskTypeEnum.InternalTaskSiteThemesCreateContent] = 'https://s3.amazonaws.com/s3staticfiles/taskexport/upload/example/SiteThemesCreateContentExample.csv';
        this.csvFormatExamples[TaskTypeEnum.AuthoritativeArticle] = 'https://s3.amazonaws.com/s3staticfiles/taskexport/upload/example/AuthoritativeArticleExample.csv';
        this.csvFormatExamples[TaskTypeEnum.ProfessionalBacklink] = 'https://s3.amazonaws.com/s3staticfiles/taskexport/upload/example/ProfessionalBacklinkExample.csv';
        this.csvFormatExamples[TaskTypeEnum.ProfessionalInternationalBacklink] = 'https://s3.amazonaws.com/s3staticfiles/taskexport/upload/example/ProfessionalInternationalBacklinkExample.csv';
        this.csvFormatExamples[TaskTypeEnum.AgedArticleInclusion] = 'https://s3.amazonaws.com/s3staticfiles/taskexport/upload/example/AgedArticleInclusionExample.csv';

        return $.when<any>(
          this.getExportHistory(),
          Utils.wrapDfd(this.sessionStorageService.getUser()),
          Utils.wrapDfd(this.sessionStorageService.getStaticData()),
          this.updateFailures())
            .then((
                exports: Boo.Objects.ExportedTasksDownload[],
                user: Boo.Objects.User,
                staticData: Boo.Objects.LaunchPadStaticData,
            ) => {
                this.exports = exports;
                this.user = user;
                this.taskLanguages = ko.observable(staticData.Languages.filter(x => this.supportedLanguages.indexOf(x.LanguageId) > -1 && x.SupportStatusId === 3));
                this.languageLookup = {};
                for (const language of this.taskLanguages()) {
                    this.languageLookup[language.LanguageId] = language.Name;
                }
                this.taskTypes = ko.observable(staticData.TaskTypes.filter(x => this.supportedTaskTypes.indexOf(x.TaskTypeEnum) > -1));
                this.taskType = ko.observable(this.taskTypes()[0]);
                this.taskTypeLookup = {};
                for (const taskType of this.taskTypes()) {
                    this.taskTypeLookup[taskType.TaskTypeEnum] = taskType.Name;
                }
                this.loadLanguageOptions();
            });
    }

    loadLanguageOptions(): void {
        if (this.taskType().TaskTypeEnum === TaskTypeEnum.AgedArticleInclusion
                || this.taskType().TaskTypeEnum === TaskTypeEnum.ProfessionalBacklink) {
            this.languageOptions = [];
            this.allLanguages = true;
            this.selectedLanguages = [];
        } else if (this.taskType().TaskTypeEnum === TaskTypeEnum.AuthoritativeArticle
                || this.taskType().TaskTypeEnum === TaskTypeEnum.ProfessionalInternationalBacklink) {
            this.languageOptions = this.taskLanguages().map(x => ({ LanguageId: x.LanguageId, Name: x.Name } as LanguageOption));
            this.allLanguages = false;
            this.selectedLanguages = this.languageOptions.filter(x => this.selectedLanguages.map(y => y.LanguageId).includes(x.LanguageId));
        } else {
            this.languageOptions = this.taskLanguages().map(x => ({ LanguageId: x.LanguageId, Name: x.Name } as LanguageOption)).filter(x => x.LanguageId != LanguageEnum.Spanish);
            this.allLanguages = false;
            this.selectedLanguages = this.languageOptions.filter(x => this.selectedLanguages.map(y => y.LanguageId).includes(x.LanguageId));
        }
    }

    displayLanguages(languageIds: number[]): string {
        return languageIds.map(x => this.languageLookup[x]).join(', ');
    }

    onTaskTypeChanged(taskType: Boo.Objects.TaskType): void {
        this.taskType(taskType);
        this.loadLanguageOptions();
        this.showTaskCount = taskType.TaskTypeEnum !== TaskTypeEnum.AgedArticleInclusion;
        this.taskCountInput = '';
    }

    canActivate(user: Boo.Objects.User, partner: Boo.Objects.Partner, params: any): boolean {
        const hasPermission = (launchpad as Launchpad).hasPermission(partner, user.PartnerUsers, permissions.CanManageTaskExport, user);
        return hasPermission;
    }

    export(): void {
        const taskCount = parseInt(this.taskCountInput);

        if (taskCount <= 0) {
            toastr.error("Please enter a number of tasks to export greater than zero.");
            return;
        }

        this.isLoading = true;
        this.noTasks(false);
        Utils.wrapDfd(this.taskImportExportService.export(
            this.user.UserId,
            this.taskType().TaskTypeId,
            this.selectedLanguages?.map(x => x.LanguageId) ?? null,
            taskCount))
            .then((url) => {
                if (url) {
                    window.open(url, '_blank');
                }

                this.downloadUrl(url);
                this.noTasks(!url);
                if (this.noTasks()) {
                    toastr.info('No tasks available');
                }

                this.isLoading = false;

                return this.getExportHistory();
            }).then((x) => {
                this.exports = x;
            }).fail((errorMessage: string) => {
                toastr.error(errorMessage);
            })
            .always(() => {
                this.isLoading = false;
            });
    }

    uploadFile(): JQueryPromise<void> {
        const csv: string = this.content();

        const result: PapaParse.ParseResult = Papa.parse(csv, { skipEmptyLines: true });

        try {
            Check.isNotNull(result, 'Cannot read CSV file');
            Check.isCsvFormatValid(this.csvFormats[this.taskType().TaskTypeEnum], result);

            return this.fileUploader.upload(this.file(), 'taskexport/upload')
                .then(x => {
                    return Utils.wrapDfd(this.taskImportExportService.import(x.FullUrl, this.taskType().TaskTypeEnum));
                })
                .then(x => {
                    this.batchId = x;
                    this.updateSubmisions();
                    toastr.info('Upload is complete');

                    // Clear file info
                    this.file(null);
                    this.filename('');
                    this.content('');
                });
        } catch (e) {
            toastr.error(e.message);
        }
    }

    updateSubmisions(): void {
        Utils.wrapDfd(this.taskImportExportService.getTaskSubmissions(this.batchId))
            .then(x => {
                this.taskSubmissions = x;
                this.failedTaskSubmissions = x.filter((y) => y.ProcessingStatus === Boo.Objects.TaskExport.TaskSubmissionProcessingStatuses.Failed);
                this.updateFailures();
            });
    }

    completeSubmission(taskSubmission: Boo.Objects.TaskExport.TaskSubmission): void {
        this.taskImportExportService.completeTaskSubmission(taskSubmission.TaskSubmissionId)
            .subscribe({
                complete: () => this.allFailures = this.allFailures.filter(x => x != taskSubmission),
                error: err => toastr.error(err)
            });
    }

    private getExportHistory(): JQueryPromise<Boo.Objects.ExportedTasksDownload[]> {
        return Utils.wrapDfd(this.taskImportExportService.getExports(this.supportedTaskTypes));
    }

    private updateFailures(): JQueryPromise<void> {
        return Utils.wrapDfd(this.taskImportExportService.getTaskSubmissionFailures())
            .then(x => {
                this.allFailures = x;
            });
    }
}

interface LanguageOption {
    LanguageId: number;
    Name: string;
}

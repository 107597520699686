import { Component } from '@angular/core';
import System from 'framework/System';
import TaskHelper from 'app/specialist/TaskHelper';

@Component({
	selector: 'app-components-specialist-actioncomponents-websitebookmarking',
	templateUrl: './WebsiteBookmarking.component.html'
})
export class WebsiteBookmarkingComponent implements IActionWorkspace, IActivatable {
    public accountNameUsed: KnockoutObservable<string>;
    public actionViewModel: KnockoutObservable<IActionWorkspace>;
    public currentAction: KnockoutObservable<Boo.Objects.Action>;
    public description: KnockoutObservable<string>;
    public keywords: KnockoutObservableArray<string>;
    public link: KnockoutObservable<string>;
    public previousAccountsUsed: KnockoutObservableArray<string>;
    public title: string;
    public url: KnockoutObservable<string>;
    public validation: KnockoutObservable<any>;

    public activate(params: IActionWorkspaceActivationParams): JQueryPromise<void> {

        params.controlViewModel(this);
        this.actionViewModel = ko.observable(null);
        this.currentAction = params.currentAction;

        this.url = ko.observable(this.currentAction().TaskDetails.WebsiteUrl.Url);
        this.title = this.currentAction().Customer.Heading;
        this.description = ko.observable(this.currentAction().Customer.Description);
        this.link = ko.observable(this.currentAction().TaskDetails.LinkLocation.Url);
        this.accountNameUsed = ko.observable(TaskHelper.getLinkBuildingAccountName(this.currentAction().Task));
        this.previousAccountsUsed = ko.observableArray(this.currentAction().TaskDetails.PreviousAccountsUsed);
        this.keywords = ko.observableArray(this.currentAction().TaskDetails.WebsiteUrl.Keywords.map(x => {
            return launchpad.utils.getKeywordPhrase(x);
        }));

        this.accountNameUsed.extend({
            required: { message: 'The Account Name is a required field.' },
            validation: { validator: this.accountNameNotAlreadyInUse.bind(this), message: 'The Account Name entered has already been used to bookmark this site.' }
        });

        this.validation = ko.validatedObservable([this.accountNameUsed]);

        return System.emptyPromise();
    }

    public isStepReadyToComplete(): boolean {
        if (!this.validation.isValid()) {
            this.validation.errors.showAllMessages(true);
            return false;
        }
        TaskHelper.setLinkBuildingAccountName(this.currentAction().Task, this.accountNameUsed(), this.currentAction().Task.AssignedUserId);

        return true;
    }

    public isStepReadyToDelete(): boolean {
        return true;
    }

    public isStepReadyToReject(): boolean {
        return false;
    }

    public isStepReadyToSave(): boolean {
        return false;
    }

    private accountNameNotAlreadyInUse(accountName: string): boolean {
        const accountNameHost = launchpad.utils.formatUrl(accountName, false, false, false);

        return !_.some(this.previousAccountsUsed(), (account: string) => {
            return launchpad.utils.formatUrl(account, false, false, false).indexOf(accountNameHost) >= 0;
        });
    }
}

import { Component, OnInit } from '@angular/core';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { finalize } from 'rxjs/operators';
import { CountryLanguageService } from "../../../services/countryLanguage.service";
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';

@Component({
    selector: 'app-components-admin-countryandlanguagemanagement',
    templateUrl: './CountryAndLanguageManagement.component.html'
})
export class CountryAndLanguageManagementComponent implements OnInit {
    countryLanguages: Boo.Objects.CountryLanguage[];
    isLoading: boolean;
    filterByCountry: boolean;
    filterAscending: boolean;

    constructor(
      private countryLanguageService: CountryLanguageService,
      private sessionStorageService: SessionStorageService) { }

    ngOnInit(): void {
        this.loadCountryLanguages();
    }

    addCountryLanguagePair(): void {
        PriorityViewModel.ngShow('app-components-admin-countryandlanguage', {}).done(() => {
            this.reloadCountryLanguages();
        });
    }

    loadCountryLanguages(): void {
        this.isLoading = true;
        Utils.wrapDfd(this.sessionStorageService.getStaticData())
          .then((staticData: Boo.Objects.LaunchPadStaticData) => {
            this.countryLanguages = staticData.CountryLanguages;
            this.filterByCountry = false;
            this.sort(0);
          })
          .fail((err: any) => toastr.error(err))
          .always(() => this.isLoading = false);
    }

    reloadCountryLanguages(): void {
        this.isLoading = true;
        Utils.wrapDfd(this.sessionStorageService.refreshStaticData())
          .then((staticData: Boo.Objects.LaunchPadStaticData) => {
            this.countryLanguages = staticData.CountryLanguages;
            this.filterByCountry = false;
            this.sort(0);
          })
          .fail((err: any) => toastr.error(err))
          .always(() => this.isLoading = false);
    }

    sort(column: number): void {
        switch (column) {
            case (0):
                if (this.filterByCountry) {
                    this.countryLanguages.reverse();
                    this.filterAscending = !this.filterAscending;
                } else {
                    this.countryLanguages.sort((a, b) => a.Country.Name > b.Country.Name ? 1 : -1);
                    this.filterByCountry = true;
                    this.filterAscending = true;
                }
                break;
            case (1):
                if (!this.filterByCountry) {
                    this.countryLanguages.reverse();
                    this.filterAscending = !this.filterAscending;
                } else {
                    this.countryLanguages.sort((a, b) => a.Language.Name > b.Language.Name ? 1 : -1);
                    this.filterByCountry = false;
                    this.filterAscending = true;
                }
                break;
        }
    }

    delete(countryLanguageId: number): void {
        this.isLoading = true;
        const countryLanguage = this.countryLanguages.find(x => x.CountryLanguageId === countryLanguageId);
        bootbox.confirm(`Are you sure you want to remove "${countryLanguage.Country.Name}/${countryLanguage.Language.Name}"`, (result: boolean) => {
            if (result) {
                this.countryLanguageService.delete(countryLanguageId)
                  .pipe(finalize(() => this.isLoading = false))
                    .subscribe(
                        () => {
                            this.reloadCountryLanguages();
                            toastr.success(`"${countryLanguage.Country.Name}/${countryLanguage.Language.Name}" has been deleted.`);
                        },
                        (err) => toastr.error(err)
                    );
            } else {
                toastr.info(`"${countryLanguage.Country.Name}/${countryLanguage.Language.Name}" was not deleted.`);
                this.isLoading = false;
            }
        })
    }
}
import { Component } from '@angular/core';
import Check from 'framework/Check';
import { WebsiteService } from '../../../services/website.service';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-websites-websitereporting',
  templateUrl: './WebsiteReporting.component.html'
})
export class WebsiteReportingComponent implements IActivatable {
  isLandingPageLaunched: boolean;
  landingPageLaunched: Date;
  isPausedLaunch: boolean;
  sitePausedLaunched: Date;
  isSiteLaunched: boolean;
  siteLaunched: Date;
  summaries: Boo.Objects.Websites.WebsiteBuildSummary[];

  constructor(private websiteService: WebsiteService) { }

  activate(params: app.components.websites.interfaces.IWebsiteComponentActivateParams): JQueryPromise<void> {
    Check.isNotEmpty(params.customerId, 'customerId cannot be empty');
    const websiteBuild = ko.utils.unwrapObservable(params.website.WebsiteBuild);
    this.isPausedLaunch = websiteBuild ? websiteBuild.IsPausedLaunch() : false;
    this.sitePausedLaunched = this.isPausedLaunch ? websiteBuild.PausedLaunchDate() : null;

    return Utils.wrapDfd(this.websiteService.getWebsiteBuildSummaries(params.website.WebsiteId()))
      .then((summaries: Boo.Objects.Websites.WebsiteBuildSummary[]) => {
        this.summaries = summaries;
        this.summaries.forEach(x => {
          x.CompletedDate = this.isTicketCompleted(x) ? x.CompletedDate : null;
        });

        const landingPageTask = summaries.filter(x => {
          return x.WorkType === Boo.Objects.Websites.Enums.WorkTypes.TaskType
            && x.WorkTypeId === Boo.Objects.Enums.TaskTypeEnum.CreateBoostSitesLandingPage;
        });
        const websiteLaunchTask = summaries.filter(x => {
          return x.WorkType === Boo.Objects.Websites.Enums.WorkTypes.TaskType
            && x.WorkTypeId === Boo.Objects.Enums.TaskTypeEnum.WebsiteLaunch;
        });

        this.isLandingPageLaunched = landingPageTask.length > 0 ? this.isTicketCompleted(_.first(landingPageTask)) : null;
        this.landingPageLaunched = this.isLandingPageLaunched ? _.first(landingPageTask).CompletedDate : null;
        this.isSiteLaunched = websiteLaunchTask.length > 0 ? this.isTicketCompleted(_.first(websiteLaunchTask)) : null;
        this.siteLaunched = this.isSiteLaunched ? _.first(websiteLaunchTask).CompletedDate : null;
      });
  }

  private isTicketCompleted(summary: Boo.Objects.Websites.WebsiteBuildSummary): boolean {
    return summary.StatusId === Boo.Objects.Enums.TicketStatusEnum.Completed;
  }
}

import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
    selector: '[loadingOverlay]'
})
export class LoadingOverlayDirective implements OnChanges {
    @Input('loadingOverlay') public isLoading: boolean;
    @Input() public message: string;
    constructor(private element: ElementRef) { }

    public ngOnChanges() {
        const $element = jQuery(this.element.nativeElement);
        if (this.isLoading) {
            const displayDelay = 350;
            $element.mask(displayDelay);
        } else {
            $element.unmask();
        }
    }
}
import { Component, OnInit } from '@angular/core';
import BaseComponent from '../BaseComponent';
import { BacklinkParametersCorrectionService } from '../../../services/backlink-parameters-correction.service';
import { CustomerCampaignService } from '../../../services/customer-campaign.service';
import { SaveTypes } from "app/models/enums/SaveTypes";
import System from 'framework/System';

@Component({
  selector: 'app-components-ticket-backlink-parameters',
  templateUrl: './backlink-parameters.component.html'
})
export class BacklinkParametersComponent extends BaseComponent implements OnInit {

  customerJS: Boo.Objects.Customer;
  customerCampaignRestrictions: Boo.Objects.CustomerCampaign.Enums.RestrictionTypes[] = [
    Boo.Objects.CustomerCampaign.Enums.RestrictionTypes.HideArchivedTab,
    Boo.Objects.CustomerCampaign.Enums.RestrictionTypes.DisableTaskActions,
    Boo.Objects.CustomerCampaign.Enums.RestrictionTypes.DisablePageUpdate,
    Boo.Objects.CustomerCampaign.Enums.RestrictionTypes.DisableCustomerCampaignType,
    Boo.Objects.CustomerCampaign.Enums.RestrictionTypes.HideStrategyUpdateRequestButton
  ];

  backlinkParametersCorrections: Boo.Objects.BacklinkParameterCorrection[] = [];
  websiteUrls: Boo.Objects.WebsiteUrl[] = [];

  constructor(private backlinkParametersCorrectionService: BacklinkParametersCorrectionService, private customerCampaignService: CustomerCampaignService) {
    super();
  }

  ngOnInit(): void {
    this.backlinkParametersCorrectionService.get(this.ticket.TicketId())
      .subscribe(backlinkParametersCorrections => {
        this.backlinkParametersCorrections = backlinkParametersCorrections;
      });

    this.loadWebsiteUrls();
  }

  loadWebsiteUrls(): void {
    this.customerCampaignService.getWebsiteUrls(this.customer.CustomerId())
      .subscribe(websiteUrls => {
        this.websiteUrls = websiteUrls.filter(w => w.StatusId === Boo.Objects.Enums.WebsiteUrlStatuses.Active && w.Keywords.some(x => x.IsActive));
        this.websiteUrls.forEach(websiteUrl => websiteUrl.Keywords = websiteUrl.Keywords.filter(x => x.IsActive));

        this.backlinkParametersCorrections.forEach(backlinkParameterCorrection => {
          backlinkParameterCorrection.WebsiteUrlId = websiteUrls.find(w => w.WebsiteUrlId === backlinkParameterCorrection.WebsiteUrlId)?.WebsiteUrlId;
          backlinkParameterCorrection.WebsiteUrlKeywordId = websiteUrls.find(w => w.WebsiteUrlId === backlinkParameterCorrection.WebsiteUrlId)?.Keywords.find(k => k.WebsiteUrlKeywordId === backlinkParameterCorrection.WebsiteUrlKeywordId)?.WebsiteUrlKeywordId;
        });
      });
  }

  save(saveType: SaveTypes): JQueryPromise<app.ticket.interfaces.ISaveData | void> {
    this.backlinkParametersCorrectionService.save(this.backlinkParametersCorrections)
    .subscribe(() => { System.noop; });
    return System.emptyPromise();
  }

  canActivate?(_user: Boo.Objects.User, _partner: Boo.Objects.Partner, params: any): boolean {
    this.customerJS = ko.mapping.toJS(params.customer);
    return true;
  }

  getWebsiteUrlKeywords(backlinkParameterCorrection: Boo.Objects.BacklinkParameterCorrection): Boo.Objects.WebsiteUrlKeyword[] {
    return this.websiteUrls.find(x => x.WebsiteUrlId === backlinkParameterCorrection.WebsiteUrlId)?.Keywords;
  }
}

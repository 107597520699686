import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import CustomValidators from '../../../../../shared/custom-validators';
import Utils from '../../../../../shared/utils';
import { Subscription } from 'rxjs';
import { CompletableWorkspace } from 'app/interfaces/completable-workspace';
import { UnlockableWorkspace } from 'app/interfaces/unlockable-workspace';
import { InternalActionToken } from '../../internal-action.token';
import { SavableWorkspace } from 'app/interfaces/savable-workspace';

@Component({
  selector: 'app-components-specialist-internal-tasks-site-themes-create-content',
  templateUrl: './site-themes-create-content.component.html'
})
export class SiteThemesCreateContentComponent implements OnInit, OnDestroy, CompletableWorkspace, UnlockableWorkspace, SavableWorkspace {
  showCompleteButton = true;
  showUnlockButton = true;

  siteTitle: UntypedFormControl = new UntypedFormControl('', [Validators.required, CustomValidators.minWordCountValidator(3), CustomValidators.maxWordCountValidator(10)]);
  sitePurpose: UntypedFormControl = new UntypedFormControl('', [Validators.required, CustomValidators.minWordCountValidator(100), CustomValidators.maxWordCountValidator(150)]);
  sitePurposeWordCount = 0;

  validation: UntypedFormGroup = new UntypedFormGroup({
    siteTitle: this.siteTitle,
    sitePurpose: this.sitePurpose
  });

  subscription: Subscription;

  constructor(@Inject(InternalActionToken) public internalAction: Boo.Objects.InternalAction) { }

  ngOnInit(): void {
    const blogThemeContent = this.internalAction.InternalTaskDetails.BlogThemeContent;
    this.siteTitle.setValue(blogThemeContent.SiteTitle);
    this.sitePurpose.setValue(blogThemeContent.SitePurpose);
    this.sitePurposeWordCount = Utils.wordCount(this.sitePurpose.value);

    this.subscription = this.sitePurpose.valueChanges.subscribe((value) => {
      this.sitePurposeWordCount = Utils.wordCount(value);
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  isReadyToSave(): boolean {
    this.persistBlogThemeContent();
    return true;
  }

  isReadyToComplete(): boolean {
    Utils.showAllMessages(this.validation);
    if (this.validation.valid) {
      this.persistBlogThemeContent();
      return true;
    } else {
      this.validation.markAllAsTouched();
      toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
      return false;
    }
  }

  private persistBlogThemeContent(){
    this.internalAction.InternalTaskDetails.BlogThemeContent.SiteTitle = this.siteTitle.value;
    this.internalAction.InternalTaskDetails.BlogThemeContent.SitePurpose = this.sitePurpose.value;
  }
}

import Constants from "../../app/shared/constants";

declare global {
    // eslint-disable-next-line no-var
    var parseBoolean: (val: any) => boolean;
    // eslint-disable-next-line no-var
    var testConfig: any;

    interface String {
        replaceAll: (search: string, replacement: string) => string;
        roundNumber: (precision: number, showZero: boolean) => string;
    }

    interface Number {
        roundNumber: (precision: number, showZero: boolean) => string;
        formatMoney: (precision: number) => string;
    }

    interface KnockoutComputedFunctions<T> {
        roundNumber: (precision: number, showZero: boolean) => string;
    }

    interface KnockoutObservableFunctions<T> {
        roundNumber: (precision: number, showZero: boolean) => string;
        readStringWriteNumber: () => any;
    }
}

class Utils {
    public static register() {
        if (!window.launchpad) {
            window.launchpad = {};
        }
        if (!window.launchpad.utils) {
            window.launchpad.utils = {};
        }

        launchpad.utils = launchpad.utils || {};
        launchpad.utils.helpers = launchpad.utils.helpers || {};

        // eslint-disable-next-line no-var
        var getRoundedNumber = function (value: number, precision: number, showZero: boolean) {
            if (_.isNumber(value)) {

                const multiplier = Math.pow(10, precision);
                const result = Math.round(value * multiplier) / multiplier;

                const zero = 0;
                return value === 0 ? (showZero === true ? zero.toFixed(precision) : '') : result.toFixed(precision);
            } else {
                return 'NAN';
            }
        };

        window.parseBoolean = function (val: any) {
            if (typeof val === 'boolean') {
                return val;
            } else if (typeof val === 'string') {
                if (!isNaN(val as any)) {
                    return !!val;
                }

                if (val.trim().length === 0) {
                    return false;
                }
    
                return !(val.trim().toLowerCase() === 'false');
            } else if (typeof val === 'number') {
                return !!val;
            }

            return Boolean(val);
        };

        window.launchpad.utils.isMinDate = function (date: any) {
            const minDate = new Date(0);
            const serverMinDate = new Date('0001-01-01T00:00:00.000Z');
            return minDate.getTime() === date.getTime() || serverMinDate.getTime() === date.getTime();
        };

        window.launchpad.addBusinessDays = function (date: any, numberOfDays: any) {
            const weeks = Math.floor(numberOfDays / 5);
            let days = ((numberOfDays % 5) + 5) % 5;
            let weekDay = date.getDay();
            if (weekDay === 6 && days > -1) {
                if (days === 0) {
                    days -= 2;
                    weekDay += 2;
                }
                days++;
                weekDay -= 6;
            }
            if (weekDay === 0 && days < 1) {
                if (days === 0) {
                    days += 2;
                    weekDay -= 2;
                }
                days--;
                weekDay += 6;
            }
            if (weekDay + days > 5) {
                days += 2;
            }
            if (weekDay + days < 1) {
                days -= 2;
            }
            return date.setDate(date.getDate() + weeks * 7 + days);
        };

        window.launchpad.utils.getKeywordPhrase = function (keyword: any) {
            let phrase = '';

            // if keyword.area != null set phrase to combination of keyword and area.  otherwise set phrase to keyword only
            if (_.isObject(keyword) &&
                _.isObject(keyword.Area) &&
                _.isString(keyword.Area.Name) &&
                _.isObject(keyword.Keyword) &&
                _.isString(keyword.Keyword.Name)) {
                if (keyword.IsAreaFirst) {
                    phrase = keyword.Area.Name + ' ' + keyword.Keyword.Name;
                } else {
                    phrase = keyword.Keyword.Name + ' ' + keyword.Area.Name;
                }
            } else if (_.isObject(keyword) &&
                _.isObject(keyword.Keyword) &&
                _.isString(keyword.Keyword.Name)) {
                phrase = keyword.Keyword.Name;
            }
            return phrase;
        };

        window.launchpad.hasPermission = function (partner: any, partnerUsers: any, permission: any, user: any) {
            if (!partner || !partnerUsers || !permission) {
                return false;
            }

            // filter partner users by partner
            const filteredPartnerUsers = _.filter(partnerUsers,
                function (p: any) {
                    return p.PartnerId === partner.PartnerId;
                });

            // get user's user levels.
            const userLevels = _.pluck(filteredPartnerUsers, 'UserLevelId');

            // if the user has a permission that matches one of their user levels.
            const hasPartnerUserLevelPermission = _.some(partner.PartnerUserLevelPermissions,
                function (p: any) {
                    return _.contains(userLevels, p.UserLevelId) && p.PermissionId == permission;
                });

            const hasUserLevelPermission = user &&
                user.UserPermissions &&
                _.some(user.UserPermissions,
                    function (p: any) {
                        return p.PermissionId == permission;
                    });

            return hasPartnerUserLevelPermission || hasUserLevelPermission;
        };

        window.launchpad.utils.canAccessInternalTasks = function (partnerUsers: any) {
            let result = false;
            _.each(partnerUsers,
                function (partnerUser: any) {
                    if (partnerUser.TaskTypes) {
                        const internalTaskTypeIds = [
                            Boo.Objects.Enums.TaskTypeEnum.InternalTaskBlogPostBuildQA,
                            Boo.Objects.Enums.TaskTypeEnum.InternalTaskSiteThemesCreateContent,
                            Boo.Objects.Enums.TaskTypeEnum.InternalTaskSiteThemesReviewContent,
                            Boo.Objects.Enums.TaskTypeEnum.InternalTaskBlogDomainPurchase,
                            Boo.Objects.Enums.TaskTypeEnum.InternalTaskQaContentReviewBlog,
                            Boo.Objects.Enums.TaskTypeEnum.InternalTaskQaClassifiedBusinessListings,
                            Boo.Objects.Enums.TaskTypeEnum.InternalTaskQaLocalBusinessCitations,
                            Boo.Objects.Enums.TaskTypeEnum.InternalTaskQaOnsiteBloggingReview,
                            Boo.Objects.Enums.TaskTypeEnum.InternalTaskQaOnsiteCopyReview,
                            Boo.Objects.Enums.TaskTypeEnum.InternalTaskQaPremiumMonthlyCampaignUpdate,
                            Boo.Objects.Enums.TaskTypeEnum.InternalTaskQaPremiumPreliminaryAudit,
                            Boo.Objects.Enums.TaskTypeEnum.InternalTaskQaProactiveUnderperformingKeyword,
                            Boo.Objects.Enums.TaskTypeEnum.InternalTaskBuildBlog,
                            Boo.Objects.Enums.TaskTypeEnum.InternalTaskQaPremiumBacklink,
                            Boo.Objects.Enums.TaskTypeEnum.InternalTaskQaOnsiteRecommendationReview
                        ];

                        _.each(partnerUser.TaskTypes,
                            function (taskType: any) {
                                if (!result) {
                                    result = internalTaskTypeIds.indexOf(taskType.TaskTypeEnum) >= 0;
                                }
                            });
                    }
                });

            return result;
        };

        window.launchpad.utils.log = function (...args) {
            if (window.testConfig.logToConsole) {
                if (arguments.length > 1) {
                    console.log(args.join(' -> '));
                } else {
                    console.log(args[0]);
                }
            }
        };

        window.launchpad.utils.partnerInPartnerGroup = function (partnerId: any, partnerGroup: any, groups: any) {
            if (!groups) {
                return false;
            }

            const group = groups.filter((x: any) => x.GroupId === partnerGroup)[0];

            if (!group) {
                return false;
            }

            return group.PartnerGroups.map((x: any) => x.PartnerId).some((x: any) => x === partnerId);
        };

        window.launchpad.utils.isValidDomain = function (str: any) {
            // NOT the global domain/url validation. Use `isValidUrl`
            // Used only in the WebsiteDomain component.
            return /^([\u0021-\u003f\u0041-\uffff]+\.)+[\u0021-\u003f\u0041-\uffff]{2,63}$/i.test(str);
        };

        window.launchpad.utils.isValidUrl = function (str: any) {
            if (!Constants.IsUrlRegex.test(str)) {
                return false;
            } else {
                return true;
            }
        };

        /**
        * Returns an object of query parameters from a location.

        * @param Window.Location location
        * @returns Object set of query parameters
        */
        window.launchpad.utils.getQueryParametersFromUrl = function (location: any) {
            let result = {};

            if (!(location && location.hash)) {
                return result;
            }

            const hash = location.hash;
            const pos = hash.indexOf('?');
            if (pos == -1) {
                return result;
            }

            const queryString = hash.substring(pos + 1) || null;
            const pairs = queryString.slice(0).split('&');

            result = {};
            _.each(pairs,
                function (pair: any) {
                    if (!pair) {
                        return;
                    }
                    pair = pair.split('=');
                    if (!pair[0] || !pair[1]) {
                        return;
                    }
                    result[pair[0]] = decodeURIComponent(pair[1] || '');
                });

            return JSON.parse(JSON.stringify(result));
        };

        window.launchpad.utils.formatUrl = function (input: any, returnScheme: any, returnSubdomain: any, returnPath: any, makeSecure: any, noWWW: any) {
            input = $.trim(input).toLowerCase();
            if (!noWWW) {
                input = input.replace('www.', '');
            }

            // Return empty string for falsy input
            if (!input) {
                return '';
            }

            // Add the scheme to the front if it's not there to ensure it's parsed correctly by URI.js
            if (input.indexOf('http') !== 0) {
                if (makeSecure) {
                    input = `https://${input}`;
                } else {
                    input = `http://${input}`;
                }
            } else {
                if (makeSecure) {
                    input = input.replace('http:', 'https:');
                }
            }

            const url = URI(input);

            const path = (returnPath === true ? (url.path() !== '/' ? url.path() : '') : '');
            const subdomain = (returnSubdomain === true ? (url.subdomain() !== '' ? url.subdomain() + '.' : '') : '');
            const scheme = (returnScheme === true ? (url.scheme() !== '' ? url.scheme() + (noWWW ? '://' : '://www.') : '') : '');

            if (url) {
                return scheme + subdomain + url.domain() + path;
            } else if (input.indexOf('http://') >= 0) {
                return input.replace('http://', '');
            } else if (input.indexOf('https://') >= 0) {
                return input.replace('https://', '');
            } else {
                return input;
            }
        };

        //Select Option
        window.launchpad.utils.Option = function (name: any, value: any) {
            this.name = name;
            this.value = value;
        };

        window.launchpad.utils.getCurrentMomentWithoutTime = function () {
            return moment(moment().format('L'), 'L');
        };

        window.launchpad.utils.getJsDateWithoutTime = function (val: any) {
            let result = undefined;
            const date = ko.utils.unwrapObservable(val);
            if (moment.isMoment(date) || _.isDate(date)) {
                result = moment(date).startOf('day').toDate();
            }
            return result;
        };

        window.launchpad.utils.getMomentWithoutTime = function (val: any) {
            return moment(moment(val).format('L'), 'L');
        };

        window.launchpad.utils.getMomentAsUTC = function (val: any) {
            return moment(moment(val).utc().format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm');
        };

        window.launchpad.utils.isDefaultDate = function (item: any) {
            return moment(item).year() <= 1;
        };

        window.launchpad.utils.timeFormatFromDuration = function (duration: any, format: any) {
            if (!format) {
                format = 'hh:mm:ss';
            }

            duration = Math.abs(duration);
            let seconds = parseInt(((duration / 1000) % 60) as any, 10), minutes = parseInt(((duration / (1000 * 60)) % 60) as any, 10), hours = parseInt(((duration / (1000 * 60 * 60)) % 24) as any, 10);

            hours = (((hours < 10) ? `0${hours}` : hours) as any);
            minutes = (((minutes < 10) ? `0${minutes}` : minutes) as any);
            seconds = (((seconds < 10) ? `0${seconds}` : seconds) as any);

            const result = format.replace('hh', hours).replace('mm', minutes).replace('ss', seconds);

            return result;
        };

        // These trim Functions shouldn't be used if you can use $.trim()
        // instead, which will trim whitespace from both sides of a string.
        window.launchpad.utils.trimEnd = function (str: any, chr: any) {
            const rgxtrim = (!chr) ? new RegExp('\\s+$') : new RegExp(chr + '+$');
            return str.replace(rgxtrim, '');
        };
        window.launchpad.utils.trimStart = function (str: any, chr: any) {
            const rgxtrim = (!chr) ? new RegExp('^\\s+') : new RegExp(`^${chr}+`);
            return str.replace(rgxtrim, '');
        };

        window.launchpad.utils.notNullOrEmpty = function (value: any) {
            return $.trim(value) !== '';
        };

        String.prototype.insertAtIndex = function (index, string) {
            if (index > 0) {
                return this.substring(0, index) + string + this.substring(index, this.length);
            } else {
                return string + this;
            }
        };

        // Found at this url http://stackoverflow.com/questions/1144783/replacing-all-occurrences-of-a-string-in-javascript
        String.prototype.replaceAll = function (search: string, replacement: string) {
            return this.replace(new RegExp(search, 'g'), replacement);
        };

        String.prototype.roundNumber = function (precision: number, showZero: boolean) {
            return getRoundedNumber(Number(this), precision, showZero);
        };

        window.launchpad.utils.containsNoHtml = function (val: any) {
            return _.isString(val) === false || _.isNull(val.match(/<[a-z][\s\S]*>/i));
        };

        window.launchpad.utils.escapeRegExp = function (str: any) {
            if (_.isString(str)) {
                return str.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
            } else {
                return '';
            }
        };

        window.launchpad.wordCount = function (textToCount: any) {
            if ($.trim(textToCount) == '') {
                return 0;
            }
            let normalizedText = textToCount.replace(/(\r\n|\n|\r)/gm, ' ').replace(/^\s+|\s+$/g, '').replace('&nbsp;', ' ');

            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = normalizedText;

            normalizedText = $(tempDiv).text();

            const words = normalizedText.split(/\s+/);

            for (let wordIndex = words.length - 1; wordIndex >= 0; wordIndex--) {
                if (words[wordIndex].match(/^([\s\t\r\n]*)$/)) {
                    words.splice(wordIndex, 1);
                }
            }

            return words.length;
        };

        window.launchpad.utils.selectText = function (data: any, event: any) {
            const doc = document;
            const elem = event.target;
            let range: Range,
                selection: Selection;

            if ((doc.body as any).createTextRange) {
                range = (doc.body as any).createTextRange();
                (range as any).moveToElementText(elem);
                (range as any).select();
            } else if (window.getSelection) {
                selection = window.getSelection();
                range = document.createRange();
                range.selectNodeContents(elem);
                selection.removeAllRanges();
                selection.addRange(range);
            }
        };

        Number.prototype.roundNumber = function (precision: number, showZero: boolean) {
            return getRoundedNumber(this, precision, showZero);
        };

        //Adapted from this url http://stackoverflow.com/questions/149055/how-can-i-format-numbers-as-money-in-javascript
        Number.prototype.formatMoney = function (precision: number) {
            let self = this;
            const c = isNaN(Math.abs(precision)) ? 2 : precision;
            const d = '.';
            const t = ',';
            const s = self < 0 ? '-' : '';
            const i = parseInt(self = Math.abs(+self || 0).toFixed(c), 10) + '';
            const j = i.length > 3 ? i.length % 3 : 0;
            return s + '$' + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${t}`) + (c ? d + Math.abs(self - (i as any)).toFixed(c).slice(2) : '');
        };

        // Parent child sort
        window.launchpad.utils.pSort = function () {
            const self = this;

            self.key = 'id';
            self.parentKey = 'parent';

            self.unsortedCollection = [];
            self.sortedCollection = [];

            self.deferred = $.Deferred();

            self.sort = function (collection: any) {
                self.unsortedCollection = collection;
                const startingItems = _.filter(self.unsortedCollection,
                    function (item) {
                        return item[self.parentKey] === 0;
                    });

                pSort(startingItems);
                return self.sortedCollection;
            };

            const aSort = function (a: any, b: any) {
                const aName = a.Name.replace(/\W/g, '').toLowerCase();
                const bName = b.Name.replace(/\W/g, '').toLowerCase();

                if (aName < bName) {
                    return -1;
                }
                if (aName > bName) {
                    return 1;
                }
                return 0;
            };

            const pSort = function (items: any) {
                items.sort(aSort);
                for (const item of items) {
                    const children = getChildItems(item[self.key]);

                    self.sortedCollection.push(item);
                    pSort(children);
                }
            };

            const getChildItems = function (parentId: any) {
                const children = [];

                if (parentId === 0) {
                    return [];
                }

                for (const child of self.unsortedCollection) {
                    if (child[self.parentKey] === parentId) {
                        children.push(child);
                    }
                }

                return children;
            };

            return self;
        };

        ko.observable.fn.roundNumber = function (precision: any, showZero: any) {
            return getRoundedNumber(this(), precision, showZero);
        };

        ko.computed.fn.roundNumber = function (precision: any, showZero: any) {
            return getRoundedNumber(this(), precision, showZero);
        };

        ko.observable.fn.readStringWriteNumber = function () {
            const self = this;
            if (!self._readStringWriteNumber) {
                self._readStringWriteNumber = ko.computed({
                    read: function () {
                        return String(self());
                    },
                    write: function (newValue) {
                        self(Number(newValue));
                    }
                });
            }
            return self._readStringWriteNumber;
        };

        window.launchpad.utils.validation = window.launchpad.utils.validation || {};

        window.launchpad.utils.validation.customerIdIsValid = function () {
            return {
                required: { message: 'Customer Id is required' },
                validation: {
                    message: 'Invalid Customer Id',
                    validator: function (val: any) {
                        const number = parseInt(val);
                        if (_.isNaN(number) || number > launchpad.config.maxInt || number <= 0) {
                            return false;
                        }

                        return true;
                    }
                }
            };
        };

        window.launchpad.utils.validation.emailAddressIsValid = function (str: any) {
            // Keep this regex in sync with Boo.Utility.ValidationUtility
            return /^[\u0021-\u003f\u0041-\uffff]+@[\u0021-\u003f\u0041-\uffff]+\.[\u0021-\u003f\u0041-\uffff]{2,63}$/i.test(str);
        };

        window.launchpad.utils.validation.nonNegativeIntValidationPattern = function () {
            /// <summary>Initializes a validation pattern where any non-negative int value is valid.</summary>
            /// <returns type="object">A new pattern for validation </returns>
            return { message: 'Must be a number greater than or equal to zero.', params: '^[0-9]*$' };
        };

        window.launchpad.utils.validation.validUrlValidationPattern = function (isRequired: any) {
            /// <summary>Initializes a validation pattern where any non-negative int value is valid.</summary>
            /// <returns type="object">A new pattern for validation </returns>

            const validationObject: any = {
                message: 'Must be a valid URL',
                params: '(https?):\\/\\/[^ "]+$'
            };

            if (isRequired) {
                validationObject.required = true;
            }

            return validationObject;
        };

        window.launchpad.utils.getRoundedFormattedDuration = function (value: any, precision: any) {
            // If value is greater than 1000 ms then convert to seconds, minutes, or hours rounded to 1 decimal place
            if (value >= 3600000) {
                return getRoundedNumber(value / 3600000, precision, true) + ' h';
            } else if (value >= 60000) {
                return getRoundedNumber(value / 60000, precision, true) + ' m';
            } else if (value >= 1000) {
                return getRoundedNumber(value / 1000, precision, true) + ' s';
            } else {
                return value + ' ms';
            }
        };

        _.mixin({
            skipTake: function (array: any, options: any) {
                options = _.extend({ skip: 0, take: 0 }, options || {});

                return _(array)
                    .chain()
                    .rest(options.skip)
                    .first(options.take || array.length - options.skip)
                    .value();
            }
        });
    }
}

Utils.register();

import { Directive, Input, ElementRef, AfterViewInit, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[returnKey]'
})
export class ReturnKeyDirective implements AfterViewInit {
  @Input('returnKey') public Function: () => void;

  private element: HTMLElement;
  constructor(elementRef: ElementRef, private viewContainerRef: ViewContainerRef) {
    this.element = elementRef.nativeElement;
  }

  public ngAfterViewInit(): void {
    this.element.addEventListener('keydown', (evt: any) => {
      if (evt.keyCode === 13) {
        evt.preventDefault();
        evt.target.blur();
        this.Function();
      }
    });
  }
}
import { Component } from '@angular/core';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { JobService } from '../../../services/job.service';
import { JobTypeService } from '../../../services/job-type.service';
import Utils from '../../../shared/utils';

@Component({
  selector: 'app-components-admin-applicationmanagement',
  templateUrl: './ApplicationManagement.component.html'
})
export class ApplicationManagementComponent implements IActivatable {
    public isLoading: KnockoutObservable<boolean>;
    public chart: KnockoutObservable<any>;
    public filterText: KnockoutObservable<string>;
    public jobsActivationParams: KnockoutObservable<app.components.interfaces.IJobListActivateParams>;
    public jobTypeSummaries: KnockoutObservableArray<IJobTypeSummaryByType>;

    public jobListPublicApi: KnockoutObservable<app.components.interfaces.IJobListPublicApi> = ko.observable(null);
    public jobListPublicApiCallback: app.components.interfaces.IJobListPublicApiCallback = (api: app.components.interfaces.IJobListPublicApi) => { this.jobListPublicApi(api); };

    constructor(
      private jobService: JobService,
      private jobTypeService: JobTypeService) { }

    public addJob(): void {
        PriorityViewModel.show('app-components-admin-addjob')
            .always((): void => {
                this.loadCurrentJobs();
            });
    }

    public activate(): JQueryPromise<any> {
        this.chart = ko.observable(null);
        this.isLoading = ko.observable(false);
        this.jobTypeSummaries = ko.observableArray([]);

        this.jobsActivationParams = ko.observable({
            selectedJobStatusId: Boo.Objects.Jobs.Enums.JobStatuses.Failed,
            apiCallback: this.jobListPublicApiCallback
        });

        this.filterText = ko.observable('')
            .extend({
                throttle: 1000
            });

        this.filterText.subscribe((filter: string) => {
            this.jobsActivationParams({
                filterText: filter
            });
        });

        return $.when(this.loadHistoryChart(), this.loadCurrentJobs());
    }

    public deleteJobs(): void {
        PriorityViewModel.show('app-components-admin-updatejobs', { deleteMode: true })
            .always((): void => {
                this.loadCurrentJobs();
            });
    }

    public requeueJobs(): void {
        PriorityViewModel.show('app-components-admin-updatejobs', { deleteMode: false })
            .always((): void => {
                this.loadCurrentJobs();
            });
    }

    public changeJobTypeStatus(jobType: IJobTypeSummaryByType): void {
        this.confirmChangeJobTypeActivity(jobType.Name, ko.utils.unwrapObservable(jobType.IsActiveObservable))
            .then(() => {
                jobType.IsActiveObservable(!jobType.IsActiveObservable());
            })
            .then(() => {
                return Utils.wrapDfd(this.jobTypeService.updateStatus(jobType.JobTypeId, jobType.IsActive));
            })
            .fail((message: string) => {
                toastr.error(message);
                jobType.IsActiveObservable(!jobType.IsActiveObservable());
            });
    }

    public confirmChangeJobTypeActivity(jobTypeName: string, isActive: boolean): JQueryPromise<boolean> {
        return $.Deferred<boolean>((dfd) => {
            const msg = 'Are you sure you would like to change Job Type <strong>' + jobTypeName + '</strong> status to ' + (isActive === false ? 'active' : 'not active') + '?';
            bootbox.confirm(msg, (result: boolean) => {
                if (result === true) {
                    dfd.resolve();
                } else {
                    dfd.reject();
                }
            });
        }).promise();
    }

    public refreshJobs(): void {
        if (this.jobListPublicApi()) {
            this.jobListPublicApi().refresh();
        }
    }

    public loadCurrentJobs(): JQueryPromise<any> {
        return Utils.wrapDfd(this.jobService.getJobStatusSummaryByType(moment().add(-1, 'd'), moment().add(1, 'd')))
            .then(this.handleGetJobStatusSummaryByType.bind(this))
            .fail((displayMessage: string): void => {
                toastr.error(displayMessage);
            })
            .always(() => {
                this.isLoading(false);
            });
    }

    public loadHistoryChart(): JQueryPromise<any> {
        return this.getJobStatusCounts()
            .then(this.fillHistoryChart.bind(this))
            .fail((displayMessage: string): void => {
                toastr.error(displayMessage);
            })
            .always(() => {
                this.isLoading(false);
            });
    }

    public openJobType(jobTypeSummary: IJobTypeSummaryByType): void {
        PriorityViewModel.show('app-components-admin-jobsbytype', { jobTypeId: jobTypeSummary.JobTypeId }, false)
            .then((): void => {
                this.loadCurrentJobs();
            });
    }

    private addSeries(data: Boo.Objects.Jobs.Projections.JobStatusDateTotal[], status: Boo.Objects.Jobs.Enums.JobStatuses, label: string, seriesData: any[]): void {
        const jobs: Boo.Objects.Jobs.Projections.JobStatusDateTotal[] = _.filter(data, (d: Boo.Objects.Jobs.Projections.JobStatusDateTotal) => {
            return d.JobStatus === status;
        });
        if (jobs.length > 0) {
            seriesData.push({
                name: label,
                data: _.map(jobs, (row: Boo.Objects.Jobs.Projections.JobStatusDateTotal) => {
                    return row.Count;
                })
            });
        }
    }

    private getJobStatusCounts(): JQueryPromise<any> {
        const startDate: moment.Moment = moment().add(-7, 'd');
        const endDate: moment.Moment = moment().add(1, 'd');
        return Utils.wrapDfd(this.jobService.getJobStatusCounts(
            [Boo.Objects.Jobs.Enums.JobStatuses.Completed],
            startDate,
            endDate
        ));
    }

    private fillHistoryChart(data: Boo.Objects.Jobs.Projections.JobStatusDateTotal[]): void {
        this.chart({
            title: {
                text: '',
                x: 0
            },
            xAxis: {
                categories: _.map(data, (row: Boo.Objects.Jobs.Projections.JobStatusDateTotal) => {
                    return moment(row.Date).format('MM/DD/YYYY');
                }),
                labels: {
                    rotation: 45
                }
            },
            yAxis: {
                title: {
                    text: ''
                },
                plotLines: [{
                    value: 0,
                    width: 1,
                    color: '#808080'
                }],
                min: 0
            },
            legend: {
                align: 'center',
                verticalAlign: 'bottom',
                borderWidth: 0
            },
            series: (() => {
                const result: any[] = [];

                this.addSeries(data, Boo.Objects.Jobs.Enums.JobStatuses.Pending, 'Pending Jobs', result);
                this.addSeries(data, Boo.Objects.Jobs.Enums.JobStatuses.Completed, 'Completed Jobs', result);
                this.addSeries(data, Boo.Objects.Jobs.Enums.JobStatuses.Failed, 'Failed Jobs', result);
                this.addSeries(data, Boo.Objects.Jobs.Enums.JobStatuses.Processing, 'Processing Jobs', result);

                return result;
            })(),
            credits: {
                enabled: false
            }
        });
    }

    private handleGetJobStatusSummaryByType(data: IJobTypeSummaryByType[]): void {
        data.forEach((jobTypeSummaryByType: IJobTypeSummaryByType) => {
            jobTypeSummaryByType.IsActiveObservable = ko.observable(jobTypeSummaryByType.IsActive);

            // Make sure IsActive is always the same and IsActiveObservable
            jobTypeSummaryByType.IsActiveObservable.subscribe((newValue: boolean) => {
                jobTypeSummaryByType.IsActive = newValue;
            });
        });

        this.jobTypeSummaries(data);
    }
}

interface IJobTypeSummaryByType extends Boo.Objects.JobStatusSummaryByType {
    IsActiveObservable: KnockoutObservable<boolean>;
    Delay: string;
    ProcessingTime: string;
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HelpTextService {
  allHelpTexts: Boo.Objects.HelpText[] = [];
  private baseUrl = `${environment.urlConfig.serviceUrl}/helptext`;

  constructor(private http: HttpClient) { }

  initialize(): void {
    this.getAll().subscribe(x => {
      this.allHelpTexts = x;
    });
  }

  getByKey(key: string): Boo.Objects.HelpText {
    if (!key) {
      return undefined;
    }

    let result = this.allHelpTexts.find(x => x.Key === key);
    if (!result) {
      result = {
        HelpTextId: 0,
        Key: key,
        Content: '',
        Title: '',
        InsertedDate: new Date()
      };
      this.save(result);
    }

    return result;
  }

  save(helpText: Boo.Objects.HelpText): Observable<void> {
    const existing = this.allHelpTexts.find(x => x.Key === helpText.Key);
    if (existing) {
      existing.Title = helpText.Title;
      existing.Content = helpText.Content;
    } else {
      this.allHelpTexts.push(helpText);
    }

    return this.http.post<void>(this.baseUrl, helpText);
  }

  private getAll(): Observable<Boo.Objects.HelpText[]> {
    return this.http.get<Boo.Objects.HelpText[]>(`${this.baseUrl}/GetAll`);
  }
}

import { Component, OnInit } from '@angular/core';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import PresignedUrlRequest from 'app/models/typescript/PresignedUrlRequest';
import { PartnerPricingToolDetailService } from '../../../services/partner-pricing-tool-detail.service';
import { PartnerPricingToolFeatureService } from '../../../services/partner-pricing-tool-feature.service';
import { FeatureGroupService } from '../../../services/feature-group.service';
import { PartnerPricingToolMarketService } from '../../../services/partner-pricing-tool-market.service';
import { PartnerPricingToolPartnerMarketService } from '../../../services/partner-pricing-tool-partner-market.service';
import { PartnerPricingToolPriceLevelService } from '../../../services/partner-pricing-tool-price-level.service';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';
import { StoreService } from '../../../services/store.service';
import { finalize } from 'rxjs/operators';
import { forkJoin, Observable, ReplaySubject } from 'rxjs';

type SubscriptionPriceLevel = Boo.ApisExternal.Store.Objects.SubscriptionPriceLevel

@Component({
    selector: 'app-components-manager-partnerpricingtooldetail',
    templateUrl: './PartnerPricingToolDetail.component.html'
})
export class PartnerPricingToolDetailComponent implements OnInit {
    _ = _;
    dfd: any;
    isLoading: any = ko.observable(false);
    title = 'Manage Partner Pricing Tool';
    partner: any = ko.observable();
    selectedPriceLevel: any = ko.observable();
    hasFeaturesChanged: any = ko.observable(false);
    partnerPricingToolDetail: any = ko.observable();
    priceLevels: SuggestablePriceLevel[];
    features: any = ko.observableArray([]);
    featureGroups: Boo.Objects.SEOPresence.FeatureGroup[];
    pageValidation: any = ko.validatedObservable();
    partnerPricingToolMarkets: any = ko.observableArray([]);
    userSelectedPartnerPricingToolMarketIds: any = ko.observableArray([]);
    partnerPricingToolDetailsList: any = ko.observableArray([]);
    partnerPricingToolCustomProposalTypes: any = ko.observableArray([]);
    includedFeatures: any = ko.observableArray([]);
    isSuggesting = false;
    subscriptionCategories: string[];
    subscriptionPriceLevels: SubscriptionPriceLevel[];
    selectedCategory: ReplaySubject<string> = new ReplaySubject<string>(1);
    selectedCategoryPriceLevels: ReplaySubject<SubscriptionPriceLevel[]> = new ReplaySubject<SubscriptionPriceLevel[]>(1);
    suggestionStatus: typeof SuggestionStatus = SuggestionStatus;

    selectedLanguageId: any = ko.computed(() => {
        if (_.isObject(this.partnerPricingToolDetail()) && _.isNumber(this.partnerPricingToolDetail().LanguageId())) {
            return this.partnerPricingToolDetail().LanguageId();
        } else {
            return 0;
        }
    });

    emailCustomProposalTypeIsSelected: any = ko.computed(() => {
        return this.partnerPricingToolDetail() &&
            this.partnerPricingToolDetail().ShowCustomProposal() &&
            this.partnerPricingToolDetail().PartnerPricingToolCustomProposalTypeId() === window.launchpad.config.PartnerPricingToolCustomProposalTypeEnum.Email;
    });

    textInstructionsCustomProposalTypeIsSelected: any = ko.computed(() => {
        return this.partnerPricingToolDetail() &&
            this.partnerPricingToolDetail().ShowCustomProposal() &&
            this.partnerPricingToolDetail().PartnerPricingToolCustomProposalTypeId() === window.launchpad.config.PartnerPricingToolCustomProposalTypeEnum.TextInstructions;
    });

    instaQuoteCustomProposalTypeIsSelected: any = ko.computed(() => {
        return this.partnerPricingToolDetail() &&
            this.partnerPricingToolDetail().ShowCustomProposal() &&
            this.partnerPricingToolDetail().PartnerPricingToolCustomProposalTypeId() === window.launchpad.config.PartnerPricingToolCustomProposalTypeEnum.InstaQuote;
    });

    constructor(
        private partnerPricingToolDetailService: PartnerPricingToolDetailService,
        private partnerPricingToolFeatureService: PartnerPricingToolFeatureService,
        private featureGroupService: FeatureGroupService,
        private partnerPricingToolMarketService: PartnerPricingToolMarketService,
        private partnerPricingToolPartnerMarketService: PartnerPricingToolPartnerMarketService,
        private partnerPricingToolPriceLevelService: PartnerPricingToolPriceLevelService,
        private sessionStorageService: SessionStorageService,
        private storeService: StoreService) { }

    ngOnInit(): void {
        this.selectedPriceLevel.subscribe(() => {
            this.reloadIncludedFeatures();
        });

        this.selectedCategory.subscribe((category: string) => {
            this.selectedCategoryPriceLevels.next(this.subscriptionPriceLevels.filter((level: SubscriptionPriceLevel) => level.Categories.includes(category)));
        });

        this.selectedCategoryPriceLevels.subscribe(this.updateSuggestions.bind(this));
    }

    savePartnerMarkets(): void {
        Utils.wrapDfd(this.partnerPricingToolPartnerMarketService.replaceAllForPartnerIdAndLanguageId(this.userSelectedPartnerPricingToolMarketIds(), this.partner().PartnerId, this.selectedLanguageId()))
            .then(() => {
                toastr.success('City information was successfully updated');
            })
            .fail((displayMessage) => {
                toastr.error(displayMessage);
            })
            .always(() => {
                this.isLoading(false);
            });
    }

    addPriceLevel(): void {
        PriorityViewModel.show('app-components-manager-addpartnerpricingtoolpricelevel',
            {
                'partnerId': this.partner().PartnerId,
                'languageId': this.selectedLanguageId()
            }).done(this.reloadPartnerPricingToolPriceLevels.bind(this));
    }

    editPriceLevel(partnerPricingToolPriceLevel: any): void {
        PriorityViewModel.show('app-components-manager-addpartnerpricingtoolpricelevel',
            {
                'partnerPricingToolPriceLevel': partnerPricingToolPriceLevel
            }).done(this.reloadPartnerPricingToolPriceLevels.bind(this));
    }

    removePriceLevel(partnerPricingToolPriceLevel: any): void {
        bootbox.confirm('Are you sure you want to remove this price level?',
            (response: any) => {
                this.selectedPriceLevel(undefined);
                if (response === true && _.isObject(partnerPricingToolPriceLevel)) {
                    if (_.isObject(this.selectedPriceLevel()) && this.selectedPriceLevel().PartnerPricingToolPriceLevelId === partnerPricingToolPriceLevel.PartnerPricingToolPriceLevelId) {
                        const newPriceLevelsList = _.without(this.priceLevels,
                            _.findWhere(this.priceLevels, { PartnerPricingToolPriceLevelId: partnerPricingToolPriceLevel.PartnerPricingToolPriceLevelId }));
                        this.priceLevels = newPriceLevelsList;
                    }
                    Utils.wrapDfd(this.partnerPricingToolPriceLevelService.delete(partnerPricingToolPriceLevel.PartnerPricingToolPriceLevelId))
                        .then(() => {
                            this.reloadPartnerPricingToolPriceLevels();
                            toastr.success('Price Level removed successfully.');
                        })
                        .fail((displayMessage) => {
                            toastr.error(displayMessage);
                        })
                        .always(() => {
                            this.isLoading(false);
                        });
                }
            });
    }

    previewPriceLevel(partnerPricingToolPriceLevelId: number) {
        this.isLoading(true);

        this.partnerPricingToolPriceLevelService
            .getDisplayablePriceLevel(partnerPricingToolPriceLevelId, this.partner().PartnerId, this.selectedLanguageId())
            .pipe(finalize(() => this.isLoading(false)))
            .subscribe((partnerPricingToolPriceLevel) => {

                PriorityViewModel.ngShow('app-components-manager-previewpartnerpricingtoolpricelevel', {
                    partnerPricingToolPriceLevel: partnerPricingToolPriceLevel,
                    partnerId: this.partner().PartnerId,
                    languageId: this.selectedLanguageId()
                });
            });
    }

    cancelFeatureChanges(): void {
        this.reloadFeatures();
        this.reloadIncludedFeatures();
    }

    afterPriceLevelFeatureChange(): void {
        this.availableFeatures.notifySubscribers();
        this.includedFeatures.notifySubscribers();
        this.hasFeaturesChanged(true);
    }

    includeAllFeatures(): void {
        this.hasFeaturesChanged(true);
        this.includedFeatures(this.features());
    }

    clearFeatures(): void {
        this.hasFeaturesChanged(true);
        this.includedFeatures([]);
    }

    savePriceLevelFeatures(): void {
        const includedFeatures: any = [];
        for (let i = 0; i < this.includedFeatures().length; i++) {
            includedFeatures.push({
                PartnerPricingToolPriceLevelFeatureId: 0,
                DisplayOrder: i + 1,
                PartnerPricingToolFeatureId: this.includedFeatures()[i].PartnerPricingToolFeatureId,
                PartnerPricingToolPriceLevelId: this.selectedPriceLevel().PartnerPricingToolPriceLevelId
            });
        }
        Utils.wrapDfd(this.partnerPricingToolPriceLevelService.replaceFeatures(this.selectedPriceLevel().PartnerPricingToolPriceLevelId, includedFeatures))
            .then(() => {
                this.hasFeaturesChanged(false);
                toastr.success('Features were updated successfully.');
            })
            .fail((displayMessage) => {
                toastr.error(displayMessage);
            })
            .always(() => {
                this.isLoading(false);
            });
    }

    uploadProposal(): void {
        const request: Boo.Objects.Amazon.PreSignedUrlRequest = new PresignedUrlRequest();
        request.BucketName = window.launchpad.config.S3Buckets.s3staticfiles;
        request.Folder = `seopresencedocs/${this.partner().PartnerId}-${this.selectedLanguageId()}`;
        request.UseUniqueFileName = false;

        const explicitFilename = 'Proposal.docx';
        PriorityViewModel.show('app-components-shared-uploadfile', { preSignedURLRequest: request, acceptedFileExtensions: ['docx'], explicitFilename: explicitFilename }).done((uploadResult: any) => {
            this.partnerPricingToolDetail().ProposalDocumentS3Url(uploadResult.FullUrl);
            this.savePartnerPricingToolDetail(false);
            toastr.success('Proposal document uploaded successfully.');
        });
    }

    uploadLogo(): void {
        const request: Boo.Objects.Amazon.PreSignedUrlRequest = new PresignedUrlRequest();
        request.BucketName = window.launchpad.config.S3Buckets.s3staticfiles;
        request.Folder = 'seopresencelogos';
        request.UseUniqueFileName = false;

        const logoIsValid: any = (aFile: any) => {
            if (aFile) {
                const fileSizeInMB = aFile.size / 131072;
                if (fileSizeInMB > 1) {
                    toastr.error('Cannot upload a file larger than 1 MB');
                    return false;
                }
                // check type.
                if (aFile.type !== 'image/png') {
                    toastr.error('Could not upload document.  Only Portable Network Graphics (.png) files are allowed');
                    return false;
                }
            } else {
                toastr.error('A file is required.');
                return false;
            }
            return true;
        };
        const explicitFilename = this.partner().PartnerId + '-' + this.selectedLanguageId() + '.png';
        PriorityViewModel.show('app-components-shared-uploadfile', { preSignedURLRequest: request, acceptedFileExtensions: ['png'], maxUploadInMB: 1, explicitFilename: explicitFilename }).done((uploadResult: any) => {
            this.partnerPricingToolDetail().LogoS3Url(uploadResult.FullUrl);
            this.savePartnerPricingToolDetail(false);
            toastr.success('Logo uploaded successfully.');
        });
    }

    removeLogo(): any {
        if (_.isObject(this.partnerPricingToolDetail()) === false || !this.partnerPricingToolDetail().LogoS3Url() || this.partnerPricingToolDetail().LogoS3Url().length === 0) {
            toastr.error('The selected partner doesn\'t have a logo.');
            return;
        }
        bootbox.confirm('Are you sure? This action cannot be undone.',
            (result: any) => {
                if (result === true) {
                    this.isLoading(true);
                    Utils.wrapDfd(this.partnerPricingToolDetailService.deleteLogo(this.partnerPricingToolDetail().PartnerPricingToolDetailId()))
                        .then(() => {
                            toastr.success('Logo has been removed.');
                            this.partnerPricingToolDetail().LogoS3Url('');
                            this.savePartnerPricingToolDetail(false);
                        })
                        .fail((displayMessage) => {
                            toastr.error(displayMessage, 'Error');
                        })
                        .always(() => {
                            this.isLoading(false);
                        });
                }
            });
    }

    availableFeatures: any = ko.computed(() => {
        const ids = _.difference(_.pluck(this.features(), 'PartnerPricingToolFeatureId'), _.pluck(this.includedFeatures(), 'PartnerPricingToolFeatureId'));
        const result = _.filter(this.features(), (feature: any) => { return ids.indexOf(feature.PartnerPricingToolFeatureId) >= 0; });
        return result;
    });

    savePartnerPricingToolDetail(showSuccessMessage: any): void {
        if (this.pageValidation.isValid()) {
            Utils.wrapDfd(this.partnerPricingToolDetailService.save(ko.mapping.toJS(this.partnerPricingToolDetail())))
                .then(() => {
                    if (showSuccessMessage) {
                        toastr.success('Pricing Tool Details successfully saved.');
                    }
                })
                .fail((displayMessage) => {
                    toastr.error(displayMessage);
                })
                .always(() => {
                    this.isLoading(false);
                });
        } else {
            this.pageValidation.errors.showAllMessages();
            toastr.error(launchpad.config.ErrorMessages.ValidationFailed);
        }
    }

    close(): void {
        if (this.dfd) {
            this.dfd.reject();
        }
    }

    suggestPricingLevels(): void {
        this.isLoading(true);

        this.storeService.getSeoMinutePriceLevels(this.partner().PartnerId)
            .pipe(finalize(() => this.isLoading(false)))
            .subscribe((levels) => {
                if (levels.length === 0) {
                    toastr.error('No suggestions available.');
                    return;
                }

                this.subscriptionPriceLevels = levels;
                this.subscriptionCategories = Array.from(new Set(levels.flatMap(x => x.Categories)));
                if (this.subscriptionCategories.length == 1) {
                    this.selectedCategory.next(this.subscriptionCategories[0]);
                }
                this.isSuggesting = true;
            });
    }

    saveSuggestions(): void {
        const changedPriceLevels: SuggestablePriceLevel[] = [];
        const deletedPriceLevels: number[] = [];

        this.priceLevels.forEach((priceLevel: SuggestablePriceLevel) => {
            switch (priceLevel.SuggestionStatus) {
                case SuggestionStatus.Accepted:
                    priceLevel.Price = priceLevel.SuggestedPrice;
                    priceLevel.SeoMinutes = priceLevel.SuggestedSeoMinutes;
                    changedPriceLevels.push(priceLevel);
                    break;
                case SuggestionStatus.Added:
                    changedPriceLevels.push(priceLevel);
                    break;
                case SuggestionStatus.Removed:
                    deletedPriceLevels.push(priceLevel.PartnerPricingToolPriceLevelId);
                    break;
            }
        });

        const requests: Observable<any>[] = [];

        if (changedPriceLevels.length > 0) {
            requests.push(this.partnerPricingToolPriceLevelService.save(changedPriceLevels));
        }

        if (deletedPriceLevels.length > 0) {
            requests.push(this.partnerPricingToolPriceLevelService.delete(deletedPriceLevels));
        }

        this.isLoading(true);

        forkJoin(requests)
            .pipe(finalize(() => this.isLoading(false)))
            .subscribe(() => {
                this.isSuggesting = false;
                this.reloadPartnerPricingToolPriceLevels();
                toastr.success('Price levels updated successfully.');
            });
    }

    cancelSuggesting(): void {
        this.isSuggesting = false;
        this.priceLevels = this.priceLevels.filter(x => x.PartnerPricingToolPriceLevelId !== 0);
        this.priceLevels.forEach((priceLevel: SuggestablePriceLevel) => {
            priceLevel.SuggestedPrice = null;
            priceLevel.SuggestedSeoMinutes = null;
            priceLevel.SuggestionStatus = null;
        });
    }

    setSuggestionStatus(level: SuggestablePriceLevel, status: SuggestionStatus): void {
        level.SuggestionStatus = status;
    }

    updateSuggestions(subscriptionPriceLevels: SubscriptionPriceLevel[]) {
        this.clearSuggestions();

        const newSuggestions = [];

        subscriptionPriceLevels.forEach((priceLevel: SubscriptionPriceLevel) => {
            const matchedPriceLevel = this.priceLevels.find((partnerPriceLevel: SuggestablePriceLevel) => {
                return partnerPriceLevel.Price === priceLevel.Price && (!partnerPriceLevel.SeoMinutes || partnerPriceLevel.SeoMinutes === priceLevel.Quantity);
            });

            if (!matchedPriceLevel) {
                newSuggestions.push({
                    PartnerPricingToolPriceLevelId: 0,
                    LanguageId: this.selectedLanguageId(),
                    Name: priceLevel.Name,
                    PartnerId: this.partner().PartnerId,
                    Price: priceLevel.Price,
                    SeoMinutes: priceLevel.Quantity,
                    SuggestionStatus: SuggestionStatus.AdditionSuggested
                });
                return;
            }

            if (matchedPriceLevel.Price == priceLevel.Price && matchedPriceLevel.SeoMinutes == priceLevel.Quantity) {
                matchedPriceLevel.SuggestionStatus = SuggestionStatus.NoSuggestion;
            } else {
                matchedPriceLevel.SuggestedPrice = priceLevel.Price;
                matchedPriceLevel.SuggestedSeoMinutes = priceLevel.Quantity;
                matchedPriceLevel.SuggestionStatus = SuggestionStatus.HasSuggestion;
            }
        });

        this.priceLevels = this.priceLevels
            .concat(Utils.filterDuplicates(newSuggestions, (a, b) => a.Price === b.Price && a.SeoMinutes === b.SeoMinutes))
            .sort((a, b) => a.Price - b.Price);

        this.priceLevels.filter(x => !x.SuggestionStatus).forEach((priceLevel: SuggestablePriceLevel) => {
            priceLevel.SuggestionStatus = SuggestionStatus.RemovalRecommended;
        });
    }

    private clearSuggestions(): void {
        this.priceLevels = this.priceLevels.filter((priceLevel: SuggestablePriceLevel) => priceLevel.PartnerPricingToolPriceLevelId !== 0);

        this.priceLevels.forEach((priceLevel: SuggestablePriceLevel) => {
            priceLevel.SuggestedPrice = null;
            priceLevel.SuggestedSeoMinutes = null;
            priceLevel.SuggestionStatus = null;
        });
    }

    private setupValidation(): void {
        if (_.isObject(this.partnerPricingToolDetail())) {
            this.partnerPricingToolDetail().SalesSupportContactNumber.extend({
                validation: {
                    validator: (val: any) => {
                        return !this.instaQuoteCustomProposalTypeIsSelected() || $.trim(val).length > 0;
                    },
                    message: 'Help contact number is required'
                }
            });
            this.partnerPricingToolDetail().ProposalEmailAddress.extend({
                validation: {
                    validator: (val: any, validate: any) => {
                        if (!this.emailCustomProposalTypeIsSelected()) {
                            return true;
                        }
                        if ($.trim(val).length <= 0) {
                            return false;
                        }
                        return ko.validation.rules.email.validator($.trim(val), validate);
                    },
                    message: 'Not a valid email address.'
                }
            });
            this.partnerPricingToolDetail().CustomMessage.extend({
                validation: {
                    message: 'Custom Message is required',
                    validator: (val: any) => {
                        return !this.textInstructionsCustomProposalTypeIsSelected() || $.trim(val).length > 0;
                    }
                }
            });
            this.partnerPricingToolDetail().ProposalDocumentS3Url.extend(({
                required: {
                    message: 'Proposal Document is required for the Email Custom Proposal Type.',
                    onlyIf: () => { return this.emailCustomProposalTypeIsSelected(); }
                }
            }));
            this.pageValidation = ko.validatedObservable([
                this.partnerPricingToolDetail().ProposalEmailAddress,
                this.partnerPricingToolDetail().CustomMessage,
                this.partnerPricingToolDetail().SalesSupportContactNumber,
                this.partnerPricingToolDetail().ProposalDocumentS3Url
            ]);
        }
    }

    private reloadPartnerPricingToolPriceLevels(): void {
        if (_.isObject(this.partner) && _.isNumber(ko.utils.unwrapObservable(this.partner).PartnerId)) {
            this.partnerPricingToolPriceLevelService.getByPartnerIdAndLanguageId(
                ko.utils.unwrapObservable(this.partner).PartnerId,
                this.selectedLanguageId())
                .pipe(finalize(() => this.isLoading(false)))
                .subscribe((priceLevels) => {
                    this.priceLevels = priceLevels;
                }, (err) => toastr.error(err));
        } else {
            this.priceLevels = [];
        }
    }

    private reloadFeatures(): void {
        if (_.isNumber(this.selectedLanguageId()) && this.selectedLanguageId() > 0) {
            this.featureGroupService.getTranslated(Boo.Objects.SEOPresence.FeatureGroups.General, this.selectedLanguageId())
                .pipe(finalize(() => this.isLoading(false)))
                .subscribe((featureGroup) => {
                    this.hasFeaturesChanged(false);
                    this.features(featureGroup.PartnerPricingToolFeatures);
                },
                    (err) => toastr.error(err))
        } else {
            this.features([]);
        }
    }

    private reloadIncludedFeatures(): void {
        if (_.isObject(this.selectedPriceLevel()) && _.isNumber(this.selectedPriceLevel().PartnerPricingToolPriceLevelId)) {
            Utils.wrapDfd(this.partnerPricingToolFeatureService.getByPriceLevelId(this.selectedPriceLevel().PartnerPricingToolPriceLevelId, this.selectedLanguageId()))
                .then(data => {
                    this.includedFeatures(data);
                })
                .fail((displayMessage) => toastr.error(displayMessage))
                .always(() => this.isLoading(false));
        }
    }

    canActivate(): boolean {
        return !this.isLoading();
    }

    activate(options: any): any {
        this.isLoading(true);
        const languageId = ko.utils.unwrapObservable(options.partnerPricingToolDetail.LanguageId);
        return forkJoin([this.partnerPricingToolMarketService.get(),
        this.partnerPricingToolPartnerMarketService.getByPartnerIdAndLanguageId(options.partner.PartnerId, languageId),
        this.partnerPricingToolPriceLevelService.getByPartnerIdAndLanguageId(options.partner.PartnerId, languageId),
        this.sessionStorageService.getStaticData(),
        this.featureGroupService.getAllTranslated(languageId)])
            .pipe(finalize(() => this.isLoading(false)))
            .subscribe({
                next: ([markets, partnerMarkets, priceLevels, staticData, featureGroups]) => {
                    this.partner(options.partner);
                    this.partnerPricingToolCustomProposalTypes(staticData.PartnerPricingToolCustomProposalTypes);
                    this.partnerPricingToolDetail(options.partnerPricingToolDetail);
                    this.partnerPricingToolMarkets(markets);
                    this.userSelectedPartnerPricingToolMarketIds(_.pluck(partnerMarkets, 'PartnerPricingToolMarketId'));
                    this.priceLevels = priceLevels;
                    // Clone the features here since they are manipulated in a separate screens
                    this.features(structuredClone(featureGroups.find(x => x.FeatureGroupId === Boo.Objects.SEOPresence.FeatureGroups.General).PartnerPricingToolFeatures));
                    this.setupValidation();
                    this.featureGroups = featureGroups;

                },
                error: (err) => toastr.error(err)
            });
    }
}

interface SuggestablePriceLevel extends Boo.Objects.SEOPresence.PartnerPricingToolPriceLevel {
    SuggestedPrice?: number;
    SuggestedSeoMinutes?: number;
    SuggestionStatus?: SuggestionStatus;
}

enum SuggestionStatus {
    HasSuggestion = 'Has Suggestion',
    Accepted = 'Accepted',
    Rejected = 'Rejected',
    AdditionSuggested = 'Addition Suggested',
    Added = 'Added',
    RemovalRecommended = 'Removal Recommended',
    Removed = 'Removed',
    NoSuggestion = 'No Suggestion'
}
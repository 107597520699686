export default class partneruser {
    public Categories = null as any;
    public IsCsr = false;
    public IsManager = false;
    public IsPrimary = false;
    public IsReadonly = false;
    public IsReseller = false;
    public IsWriter = false;
    public PartnerId = 0;
    public PartnerUserId = 0;
    public Teams = null as any;
    public TicketTypes = null as any;
    public UserLevelId = 0;
    public AccessToken = null as any;
    public CustomerUsers = null as any;
    public Customers = null as any;
    public FirstName = null as any;
    public FullName = null as any;
    public InsertedDate = '0001-01-01T00:00:00';
    public IsActive = false;
    public Languages = null as any;
    public LastName = null as any;
    public PartnerUsers = null as any;
    public Partners = null as any;
    public Password = null as any;
    public UserId = 0;
    public UserPermissions = null as any;
    public Username = null as any;
}
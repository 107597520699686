import { Component } from '@angular/core';
import permissions from 'app/models/Permissions';
import System from 'framework/System';
import Utils from '../../../shared/utils';
import { LeadSourceService } from '../../../services/lead-source.service';

@Component({
	selector: 'app-components-manager-leadsources',
	templateUrl: './LeadSources.component.html'
})
export class LeadSourcesComponent implements IActivatable {
    public isLoading: KnockoutObservable<boolean>;
    public isAdministrator: boolean;
    public filteredLeadSources: KnockoutComputed<LeadSourceObservable[]>;
    public filter: KnockoutObservable<string>;

    private leadSources: KnockoutObservableArray<LeadSourceObservable>;

    constructor(private leadSourceService: LeadSourceService) { }

    public activate(): JQueryPromise<any> {
        this.isLoading = ko.observable(true);
        this.filter = ko.observable();

        return Utils.wrapDfd(this.leadSourceService.get())
            .then(leadSources => {

                this.leadSources = ko.mapping.fromJS(leadSources);
                _.forEach(this.leadSources(), (leadSource) => {
                    leadSource.Editing = ko.observable(false);
                    leadSource.IsDirty = ko.observable(false);
                });

                this.filteredLeadSources = ko.computed(() => {
                     return _.filter(this.leadSources(), x => {
                        return $.trim(x.Name()).toLowerCase().indexOf($.trim(this.filter()).toLowerCase()) >= 0 || x.LeadSourceId() === 0;
                     });
                });

            }).fail((displayMessage: string) => {
                toastr.error(displayMessage);
            }).always(() => {
                this.isLoading(false);
            });
    }

    public canActivate(user: Boo.Objects.User, partner: Boo.Objects.Partner): boolean {
        return (launchpad as Launchpad).hasPermission(partner, user.PartnerUsers, permissions.CanManageLeadSources, user);
    }

    public addLeadSource(): void {
        const newLeadSource = {
            CategoryId: ko.observable(),
            CategoryName: ko.observable(''),
            CostPerSeat: ko.observable(0),
            LeadSourceId: ko.observable(0),
            Name: ko.observable(''),
            RetailPerHour: ko.observable(0),
            RevsharePercent: ko.observable(0),
            RevsharePercentSetup: ko.observable(0),
            WholesalePerHour: ko.observable(0),
            Editing: ko.observable(true),
            IsDirty: ko.observable(false)
            } as LeadSourceObservable;

        this.leadSources.unshift(newLeadSource);
    }

    public editLeadSource(leadSource: LeadSourceObservable): void {
       leadSource.Editing(!leadSource.Editing());
    }

    public deleteLeadSource(leadSourceId: number): void {
            this.leadSources.remove(leadsource => leadsource.LeadSourceId() === leadSourceId);
    }
}

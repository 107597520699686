import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { QuillConfig } from '../../../../shared/quill/quill-config';
import Quill from 'quill';
import CustomValidators from '../../../../shared/custom-validators';
import { TaskService } from '../../../../services/task.service';
import { CustomerFileService } from '../../../../services/customer-file.service';
import { Subscription, combineLatest, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import PresignedUrlRequest from 'app/models/typescript/PresignedUrlRequest';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { AmazonService } from '../../../../services/amazon.service';
import Utils from '../../../../shared/utils';
import OnsiteRecommendationConstants from '../../../../shared/onsite-recommendation-constants';

@Component({
  selector: 'app-components-specialist-actioncomponents-onsite-blogging-create-content',
  templateUrl: './onsite-blogging-create-content.component.html'
})
export class OnsiteBloggingCreateContentComponent implements OnInit, OnDestroy, IActionWorkspace {
  actionViewModel: KnockoutObservable<IActionWorkspace>;
  currentAction: KnockoutObservable<Boo.Objects.Action>;

  isLoading = false;
  pageTitle = new UntypedFormControl('',  [Validators.required, Validators.maxLength(OnsiteRecommendationConstants.TitleMaxLength)]);
  metaDescription = new UntypedFormControl('', [Validators.required, Validators.maxLength(OnsiteRecommendationConstants.MetaDescriptionMaxLength)]);
  contentTitle = new UntypedFormControl('', [Validators.required, Validators.maxLength(OnsiteRecommendationConstants.H1MaxLength)]);
  contentTitle2 = new UntypedFormControl('');
  content = new UntypedFormControl('', [Validators.required]);
  imageUrl = new UntypedFormControl('', [Validators.required]);
  pastImageUrls: string[] = [];
  files: Boo.Objects.CustomerFile[] = [];
  fileCount: number;
  otherContentPosts: Boo.Objects.OnsiteBloggingTaskHistory[] = [];
  completeValidation = new UntypedFormGroup({
    content: this.content,
    contentTitle: this.contentTitle,
    pageTitle: this.pageTitle,
    metaDescription: this.metaDescription,
    imageUrl: this.imageUrl
  });

  taskDetails: Boo.Objects.ActionDetail;
  customer: Boo.Objects.Customer;
  quillModules: any = QuillConfig.GetDefaults().modules;
  subscription: Subscription;

  private maxUploadSizeMB = 15;

  constructor(
    private taskService: TaskService,
    private customerFileService: CustomerFileService,
    private amazonService: AmazonService) { }

  ngOnInit() {
    this.quillModules.linkCustomerUrl = {
      customerUrl: this.currentAction().TaskDetails.WebsiteUrl.Url
    }

    if (this.taskDetails.TaskPost) {
      this.pageTitle.setValue(this.taskDetails.TaskPost.ContentTitle);
      this.contentTitle.setValue(this.taskDetails.TaskPost.ContentTitle);
      this.imageUrl.setValue(this.currentAction().TaskDetails.TaskPost.ImageUrl);
      this.content.setValue(this.taskDetails.TaskPost.Content);
    }

    if (this.taskDetails.OnsiteRecommendation) {
      this.contentTitle2.setValue(this.taskDetails.OnsiteRecommendation.H2New);
      this.pageTitle.setValue(this.taskDetails.OnsiteRecommendation.TitleNew);
      if (this.taskDetails.OnsiteRecommendation.DescriptionNew) {
        this.metaDescription.setValue(this.taskDetails.OnsiteRecommendation.DescriptionNew);
        this.metaDescription.markAsDirty();
      }
    }

    this.subscription = combineLatest([this.contentTitle.valueChanges, this.content.valueChanges]).subscribe(([contentTitle, content]) => {
      if (!this.metaDescription.dirty) {
        this.metaDescription.setValue(this.getMetaDescription(content, contentTitle));
      }
    });

    this.isLoading = true;
    forkJoin([
      this.taskService.getOnsiteTaskDefinitionHistory(this.customer.CustomerId),
      this.customerFileService.getMany(this.customer.CustomerId, launchpad.config.FileSectionTypeEnum.Marketing)
    ])
    .pipe(finalize(() => this.isLoading = false))
    .subscribe(([history, files]) => {
      this.files = files ? files : [];
      this.fileCount = this.files.length;
      this.otherContentPosts = history;
      const eightMonthsAgo = new Date();
      eightMonthsAgo.setMonth(eightMonthsAgo.getMonth() - 8);
      this.pastImageUrls = history.filter((item) => {
        return eightMonthsAgo < item.TaskDate;
      })
      .map(item => item.ImageUrl).filter(item => item);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  canActivate?(user: Boo.Objects.User, partner: Boo.Objects.Partner, params: IActionWorkspaceActivationParams): boolean {
    this.currentAction = params.currentAction;
    this.taskDetails = params.currentAction().TaskDetails;
    this.customer = params.currentAction().Customer;
    params.controlViewModel(this);
    return true;
  }

  contentEditorCreated(editor: Quill) {
    if (this.content) {
      this.content.addValidators(CustomValidators.quillWordCountValidator(editor, this.requiredWordCount()));
    }
  }

  removeImage(): void {
    this.imageUrl.setValue('');
  }

  uploadImage(): void {
    const request = {
      BucketName: launchpad.config.S3Buckets.queuedForResize,
      Folder: 'tickets/customers/' + this.customer.CustomerId,
      UseUniqueFileName: true,
      ContentSize: 0,
      ContentType: '',
      Expiration: null,
      Filename: ''
    } as PresignedUrlRequest;

    PriorityViewModel.show('app-components-shared-uploadfile', { preSignedURLRequest: request, maxUploadInMB: this.maxUploadSizeMB })
        .then((uploadResult: any) => {
            // we are using the queuedForResize bucket to upload the image, but we want to show the image in the s3staticfiles bucket
            const imgUrl = uploadResult.FullUrl.replace(launchpad.config.S3Buckets.queuedForResize, launchpad.config.S3Buckets.s3staticfiles);
            uploadResult.FullUrl = imgUrl;
            this.isLoading = true;
            return Utils.wrapDfd(this.amazonService.checkIfFileExists(uploadResult.FullUrl));
        })
        .then((data) => {
            if (data) {
                this.imageUrl.setValue(data);
            } else {
                toastr.error('Image upload is taking longer than expected');
            }
        })
        .fail((error: any) => toastr.error('Could not upload image'))
        .always(() => this.isLoading = false);
  }

  isStepReadyToReject(): boolean {
    return false;
  }

  isStepReadyToSave(): boolean | JQueryPromise<boolean> {
    this.persistContent();
    return true;
  }

  isStepReadyToComplete(): boolean | JQueryPromise<boolean> {
    Utils.showAllMessages(this.completeValidation);
    if (this.completeValidation.valid) {
      this.persistContent();
    }

    return this.completeValidation.valid;
  }

  isStepReadyToDelete(): boolean {
    return true;
  }

  requiredWordCount(): number {
    if (this.currentAction().Task.LanguageId === Boo.Objects.Enums.LanguageEnum.Finnish
        && this.currentAction().Task.TaskTypeEnum === Boo.Objects.Enums.TaskTypeEnum.OnsiteBloggingCreateContentStandard) {
      return 400;
    }

    if (this.currentAction().Task.LanguageId === Boo.Objects.Enums.LanguageEnum.Finnish
        && this.currentAction().Task.TaskTypeEnum === Boo.Objects.Enums.TaskTypeEnum.OnsiteBloggingCreateContentPremium) {
      return 550;
    }

    if (this.currentAction().Task.TaskTypeEnum === Boo.Objects.Enums.TaskTypeEnum.OnsiteBloggingCreateContentPremium) {
      return 800;
    }

    return 600;
  }

  private persistContent() {
    this.currentAction().TaskDetails.TaskPost.ContentTitle = this.contentTitle.value;
    this.currentAction().TaskDetails.TaskPost.Content = this.content.value;
    this.currentAction().TaskDetails.TaskPost.ImageUrl = this.imageUrl.value;
    this.currentAction().TaskDetails.OnsiteRecommendation.TitleNew = this.pageTitle.value;
    this.currentAction().TaskDetails.OnsiteRecommendation.H2New = this.contentTitle2.value;
    this.currentAction().TaskDetails.OnsiteRecommendation.DescriptionNew = this.metaDescription.value;
  }

  private getMetaDescription(content: string, contentTitle: string): string {
    const placeToCut = content.indexOf('</p>');

    // + 4 because we want to include the ending </p> tag
    let metaDescription = placeToCut !== -1 ? content.substring(0, placeToCut + 4) : `<p>${content}</p>`;
    metaDescription = jQuery(metaDescription).text();

    // If JQuery couldn't find valid html it will return empty string. If it returns empty string set again to content.
    if (!metaDescription) {
      metaDescription = content;
    }

    metaDescription = contentTitle + ' ' + metaDescription;

    // Ensure metaDescription is not more than 200 characters. If so then cut at the space before 200
    const maxLength = 200;
    if (metaDescription.length > maxLength) {
      const placeToCut2 = metaDescription.lastIndexOf(' ', maxLength);
      if (placeToCut2 !== -1) {
        metaDescription = metaDescription.substring(0, placeToCut2);
      }
    }

    return metaDescription;
  }
}

export default class linklocation {
    public Country = null as any
    public CountryId = 0
    public Description = null as any
    public Domain = null as any
    public ExpirationDate = null as any
    public InsertedDate = "0001-01-01T00:00:00"
    public IsActive = false
    public IsRetired = false
    public IsUrlAccessible = false
    public Language = null as any
    public LanguageId = 0
    public LastResponseTimeInMilliseconds = 0
    public LinkLocationId = 0
    public LinkLocationSearchTermId = 0
    public LinkTypeId = 0
    public MajesticRank = 0
    public Name = null as any
    public SpecialNotes = null as any
    public SubmitOrder = 0.0
    public Title = null as any
    public Url = null as any
    public Vertical = null as any
    public VerticalId = 0
    public RetirementDate = "0001-01-01T00:00:00"
}

enum Permissions {
    CanInsertTickets = 100,
    CanDeleteTickets = 101,
    CanUpdateTickets = 105,
    CanCompleteTickets = 110,
    CanAssignTickets = 115,
    CanViewMyTickets = 120,
    CanViewCriticalTickets = 125,
    CanViewTotalTickets = 130,
    CanViewUnassignedTickets = 140,
    CanChooseAnyTicketType = 145,
    CanMassAssignTicketTypes = 150,
    CanCreateTicketBatch = 151,
    HasAllTicketTypes = 155,
    CanUpdateAdvancedSettings = 200,
    CanEditActionConfiguration = 205,
    CanAdjustFreeSeoMinutes = 231,
    CanResetSeoMinutes = 232,
    CanEditWebsiteIds = 233,
    CanViewCustomReports = 300,
    CanUploadCustomReports = 305,
    CanViewOnsiteRecommendations = 400,
    CanEditOnsiteRecommendations = 405,
    CanCreateOnsiteRecommendationTasks = 410,
    CanViewCustomActions = 500,
    CanInsertCustomActions = 505,
    CanInsertCustomActionOther = 506,
    CanDeleteCustomActions = 510,
    CanEditCustomActions = 515,
    CanPrioritizeTaskTypeFromQueue = 550,
    CanUpdateSynopsis = 700,
    CanAssignCsr = 705,
    CanSetHighProfile = 725,
    HasAllTeams = 730,
    CanChangeBillingAndProducts = 750,
    CanManageGoogleAccess = 760,
    CanReviseNotes = 805,
    CanViewCustomerPendingWork = 810,
    CanCreatePartners = 900,
    CanTransferAccounts = 905,
    CanUpdateSeoContentSurvey = 1000,
    CanAddMarketingFiles = 1005,
    CanDeleteMarketingFiles = 1010,
    CanEditOnsiteBloggingCredentials = 1020,
    CanAccessCMSAdmin = 1021,
    CanUploadCustomerFile = 1100,
    CanDeleteCustomerFile = 1105,
    CanPickSecondSalesRecipient = 1200,
    CanViewCustomWebsiteAccessInfo = 1300,
    CanDeleteWebsiteAdjustments = 1400,
    CanManagePartners = 5000,
    CanAssignUserPermissions = 5001,
    CanSetTopWorkPriority = 5003,
    IsLaunchPadAdmin = 5004,
    CanViewKeywordBudget = 5005,
    CanLookupCreditCards = 5010,
    CanPerformManualBilling = 5011,
    CanSearchKeywordCustomers = 5012,
    CanManageLeadSources = 5013,
    CanChangePartnersBilling = 5014,
    CanChangeRenewalDateOnInvoiceCustomers = 5016,
    CanReconcileCompletePendingWork = 5017,
    CanManageOrganizationalTeams = 5018,
    CanManageBlogSites = 6000,
    CanAccessAwsBlogSites = 6001,
    CanRepublishPost = 6002,
    CanManageProxies = 6003,
    CanRetireBlogs = 6004,
    CanRepublishBlogs = 6005,
    CanManagePremiumBacklink = 7000,
    CanManageMerchantFields = 7001,
    CanManagePartnerTicketTypes = 7002,
    CanAddGuaranteeViolation = 7003,
    CanUseOnboardingTicketTeamFilter = 7004,
    CanEditLinkLocationUrls = 7006,
    CanManageTaskExport = 7007,
    CanManageBlacklistDomains = 7008,
    CanConfigurePartnerPricingTool = 7009,
    CanConfigureQaTasks = 7010,
    CanManageStorefronts = 8000,
    CanManageStorefrontsLimited = 8001,
    CanInvoiceDirectCustomers = 8002,
    CanScheduleCampaignEndDate = 8003,
    CanCloseWorkflow = 8004,
    CanEditHelpText = 8005,
    CanRecordSalesSupport = 8006,
    CanViewTaskWorkspaces = 8007,
    CanOnboardStorefronts = 8008,
    CanManagePartnerDefaultAllocations = 8009,
    CanViewPartnerDefaultAllocations = 8010,
    CanViewPartnerEmails = 8012,
    CanManagePartnerEmails = 8013,
    CanViewNotifications = 8014,
    CanAssignFrontTeammate = 8015,
    CanEditPartnerNotificationTemplates = 8016,
    CanEditDefaultNotificationTemplates = 8017,
    CanManageCustomerCampaign = 8018
}
export default Permissions;

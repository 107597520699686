import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PartnerPricingToolHeadingService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/partnerpricingtoolheading`;

  constructor(private http: HttpClient) { }

  save(heading: Boo.Objects.SEOPresence.PartnerPricingToolHeading): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/Save`, heading);
  }

  searchGroups(term: string, groupIds: number[], take = 100): Observable<Boo.Objects.SEOPresence.PartnerPricingToolHeading[]> {
    return this.http.post<Boo.Objects.SEOPresence.PartnerPricingToolHeading[]>(`${this.baseUrl}/SearchGroups`, {
      term: term,
      groupIds: groupIds,
      take: take
    });
  }
}
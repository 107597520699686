import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[passwordToggle]'
})
export class PasswordToggleDirective {
  constructor(elementRef: ElementRef) {
    const $element = $(elementRef.nativeElement);
    $element.wrap('<div class="input-group"></div>');

    const btnHtml = '<button class="btn btn-info" type="button">' +
      '    <i class="far fa-eye"></i>Show' +
      '</button>';

    $element.after(btnHtml);

    $element.siblings('.btn').click((e) => {
      const $e = $(e.target);

      $e.parent().children('.form-control').each((i, value) => {
        const $v = $(value);
        if ($v.attr('type') === 'password') {
          $e.html('<i class="far fa-eye-slash"></i>Hide');
          $v.attr('type', 'text');
        } else {
          $e.html('<i class="far fa-eye"></i>Show');
          $v.attr('type', 'password');
        }
      });
    });
  }
}

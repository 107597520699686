export default class partner {
    public AccountExecutiveUserId = null as any
    public Address1 = null as any
    public Address2 = null as any
    public AllowsTextMessaging = false
    public BccEmailAddress = null as any
    public BccEmailAddressDisplayName = null as any
    public BillingContactEmailAddress = null as any
    public BillingContactName = null as any
    public BillingContactPhone = null as any
    public City = null as any
    public CountryId = 0
    public CurrencyId = 0
    public DefaultLanguageId = 0
    public FaviconS3Url = null as any
    public FromEmailAddress = null as any
    public FromEmailAddressDisplayName = null as any
    public GoogleConsumerKey = null as any
    public GoogleConsumerSecret = null as any
    public HasFinanceApproved = false
    public InsertedDate = null as any
    public IsActive = false
    public IsBillingDateChangeEnabled = false
    public IsBillingVisible = false
    public IsFinancialIntegrationNeeded = false
    public IsLive = false
    public IsSingleSignonRequired = false
    public IsUserAndContactRestricted = false
    public IsWhiteLabel = false
    public LaunchDate = null as any
    public LogoLargeS3Url = null as any
    public LogoSmallS3Url = null as any
    public LogoJpgS3Url = null as any
    public MyMarketInfoSubdomain = null as any
    public Name = null as any
    public Net = 0
    public PartnerId = 0
    public PartnerManagerUserId = 0
    public PartnerChannelId = 0
    public PartnerUserLevelPermissions = null as any
    public PostalCode = null as any
    public ReportingUrl = null as any
    public ReportsEmailAddress = null as any
    public ResellerAccessToken = null as any
    public ResellerId = null as any
    public RevsharePercentSetup = 0.0
    public SalesPageUrl = null as any
    public SalesPhoneNumber = null as any
    public SalesUserId = 0
    public ServiceUrl = null as any
    public SmtpHost = null as any
    public SmtpPassword = null as any
    public SmtpPort = null as any
    public SmtpUsername = null as any
    public StateId = 0
    public SupportEmailAddress = null as any
    public SystemNotificationEmailAddress = null as any
    public SupportLevelId = 0
    public SupportPhoneNumber = null as any
    public TechnicalContactEmail = null as any
    public TechnicalContactName = null as any
    public TechnicalContactPhone = null as any
    public TextMessagingPhoneNumber = null as any
    public TimezoneOffset = 0
    public Users = null as any
    public WwwUrl = null as any
    public ReportIncludeSSOLink = false
    public LogoJpg = null as any
    public BusinessEntityId = null as any
    public IsSmtpPasswordSet = false
    public SetSmtpPassword = ""
    public BusinessDevelopmentRepUserId = null as any
    public StorefrontId = null as any
    public DeactivationDate = null as any
    public PartnerTicketTypes = null as any
    public UsesDiscoveryProcess = false
    public SchedulingLink = null as any
    public OnboardingSchedulingLink = null as any
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TaskNoteService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/tasknote`;

  constructor(private http: HttpClient) { }

  save(taskNote: Boo.Objects.TaskNote): Observable<any> {
    return this.http.post(`${this.baseUrl}/Save`, taskNote);
  }

  getByTaskId(taskId: number): Observable<Boo.Objects.TaskNote[]> {
    return this.http.post<Boo.Objects.TaskNote[]>(`${this.baseUrl}/GetByTaskId`, taskId);
  }

  getByTicketId(ticketId: number): Observable<Boo.Objects.TaskNote[]> {
    return this.http.get<Boo.Objects.TaskNote[]>(`${this.baseUrl}/ticket/${ticketId}`)
  }
}

import { Component } from '@angular/core';
import System from 'framework/System';
import BaseComponent from '../BaseComponent';
import descendantticket from 'app/models/descendantticket';
import IValidatedResult = app.interfaces.IValidatedResult;
import { SaveTypes } from 'app/models/enums/SaveTypes';
import { SessionStorageService } from '../../../services/session-storage.service';
import Utils from '../../../shared/utils';


@Component({
	selector: 'app-components-ticket-descendants',
	templateUrl: './Descendants.component.html'
})
export class DescendantsComponent extends BaseComponent {
    public isEnabled: KnockoutComputed<boolean>;
    public descendantTickets: IDescendantTicketRowObservable[];
    public isApprovalRequired: boolean;
    public descendantTicketsLength: KnockoutObservable<number>;
    isOpen = false;

    constructor(private sessionStorageService: SessionStorageService) {
      super();
    }

    public activate(params: app.ticket.components.interfaces.IDescendantsViewModelActivateParams): JQueryPromise<void> {
        this.isApprovalRequired = params.featureConfig.isApprovalRequired;
        return super.activate(params).then(() => { 
            // note: all validation is setup in this.loadDescendantTickets()
            this.shouldValidateOnUpdate = false;
            return this.loadDescendantTickets();
        }).then(() => {
            this.descendantTicketsLength = ko.observable(this.descendantTickets.length);
            this.isEnabled = ko.computed(() => {
                return this.isApprovalRequired &&
                    !this.ticket.IsApproved() &&
                    this.descendantTickets &&
                    this.descendantTickets.length > 0;
            });
        });
    }

    public validate(saveType: SaveTypes): JQueryPromise<IValidatedResult> {
        if (!this.isEnabled()) {
            return System.resolvedPromise<IValidatedResult>({isValid: true, errorMessages: []});
        }

        return super.validate(saveType).then((validationResult) => {
            // when the ticket is not approved and will be completed, make sure there is a descendant to create
            // this is non-ko-validation that requires a toast message, so it's not in this.validation
            if (validationResult.isValid && saveType === SaveTypes.Complete && this.isApprovalRequired && !this.ticket.IsApproved()) {
                const willCreateDescendant = _.any(this.descendantTickets, (row) => {
                    return row.CreateDescendantTicket() === true;
                });
                if (!willCreateDescendant) {
                    validationResult.isValid = false;
                    toastr.error('Because you have marked this ticket as not approved, you need to select the next descendant ticket(s) in line to be created.');
                }
            }

            // if invalid, open panel up
            if (!validationResult.isValid) {
                this.isOpen = true;
            }

            return validationResult;
        });
    }

    public save(saveType: SaveTypes): JQueryPromise<void | app.ticket.interfaces.ISaveData> {
        if (!this.isEnabled()) {
            return System.emptyPromise();
        }

        // only if completing, upate this.ticket.DescendantTickets
        if (saveType === SaveTypes.Complete) {
            if (this.ticket.IsApproved()) {
                // if the ticket is already approved, make sure we clear out descendant tickets
                return System.resolvedPromise(({
                    ticket: {
                        DescendantTickets: null
                    }
                } as app.ticket.interfaces.ISaveData));
            } else {
                // convert descendant row observables to Boo.Objects.DescendantTicket and add to this.ticket
                const newDescendants: Boo.Objects.DescendantTicket[] = [];
                _.each(this.descendantTickets, (row) => {
                    if (row.CreateDescendantTicket() === true) {
                        const descendant: Boo.Objects.DescendantTicket = new descendantticket();
                        descendant.TicketTypeId = row.TicketTypeId();
                        descendant.CustomerId = this.customer.CustomerId();
                        descendant.InitialNote = row.InitialNote();
                        newDescendants.push(descendant);
                    }
                });
                return System.resolvedPromise(({
                    ticket: {
                        DescendantTickets: newDescendants
                    }
                } as app.ticket.interfaces.ISaveData));
            }
        }

        return System.emptyPromise();
    }

    protected loadDescendantTickets(): JQueryPromise<void> {
        return Utils.wrapDfd(this.sessionStorageService.getStaticData())
          .then((staticData: any) => {
            if (!_.isArray(staticData.TicketTypes)) {
                throw new Error('Cannot load ticket types from static data.');
            }

            this.descendantTickets = [];
            const allTicketTypes = staticData.TicketTypes;
            const ticketTypeId = this.ticket.TicketTypeId();
            const fullTicketType = _.find(allTicketTypes, (tt: any) => {
                return ticketTypeId === tt.TicketTypeId;
            });
            const validation: KnockoutObservable<any>[] = [];

            _.each(fullTicketType.TicketTypeIdsAvailableWhenNotApproved, (id) => {
                const ticketType: any = _.findWhere(allTicketTypes, { TicketTypeId: id });
                const descendant = {
                    CreateDescendantTicket: false,
                    InitialNote: '',
                    TicketTypeId: id,
                    DisplayText: ticketType.Name
                } as IDescendantTicketRow;

                // match with descendant created previously
                const existingDescendant = _.filter(this.ticket.DescendantTickets(), (t) => {
                    return t.TicketTypeId() === id;
                });

                if (_.isArray(existingDescendant) && existingDescendant.length === 1) {
                    descendant.CreateDescendantTicket = true;
                    descendant.InitialNote = existingDescendant[0].InitialNote();
                }

                // setup observable with validation
                const descendantObservable: IDescendantTicketRowObservable = ko.mapping.fromJS(descendant);
                validation.push(descendantObservable.InitialNote.extend({
                    required: {
                        onlyIf: (): boolean => {
                            return descendantObservable.CreateDescendantTicket() === true;
                        },
                        message: 'First Note is a required field.'
                    }
                }));

                // finally, add to observable array
                this.descendantTickets.push(descendantObservable);
            });

            // set component validation
            this.validation = ko.validatedObservable(validation);
        });
    }
}

interface IDescendantTicketRow extends Boo.Objects.DescendantTicket {
    CreateDescendantTicket: boolean;
    DisplayText: string;
}

interface IDescendantTicketRowObservable {
    CreateDescendantTicket: KnockoutObservable<boolean>;
    InitialNote: KnockoutObservable<string>;
    TicketTypeId: KnockoutObservable<Boo.Objects.Enums.TicketTypeEnum>;
    DisplayText: KnockoutObservable<string>;
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PartnerPricingToolPartnerMarketService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/partnerpricingtoolpartnermarkets`;

  constructor(private http: HttpClient) { }

  getByPartnerIdAndLanguageId(partnerId: number, languageId: number): Observable<Boo.Objects.SEOPresence.PartnerPricingToolPartnerMarket[]> {
    const params = new HttpParams()
      .set('partnerId', partnerId)
      .set('languageId', languageId);

    return this.http.get<Boo.Objects.SEOPresence.PartnerPricingToolPartnerMarket[]>(`${this.baseUrl}/GetByPartnerIdAndLanguageId`, { params: params });
  }

  replaceAllForPartnerIdAndLanguageId(partnerPricingToolMarketIds: number[], partnerId: number, languageId: number): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/ReplaceAllForPartnerIdAndLanguageId`, {
        partnerPricingToolMarketIds: partnerPricingToolMarketIds,
        partnerId: partnerId,
        languageId: languageId
    });
  }
}

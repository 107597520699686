export default class Check {
    public static isHtml(content: string, message: string): void {
        try {
            const contentElem: JQuery = $(content);
            if (!contentElem.prop('tagName')) {
                throw new Error(message);
            }
        } catch (ex) {
            throw new Error(message);
        }
    }

    /**
     *  Throws an exception if the condition is not true. 
     * @param {any} condition
     * @param {string} message
     */
    public static isTrue(condition: boolean, message: string): void {
        if (condition !== true) {
            throw new Error(message);
        }
    }

    /**
     *  Throws an exception if the value is falsey. 
     * @param {any} item
     * @param {string} message
     */
    public static isNotEmpty(item: any, message: string): void {
        if (!item) {
            throw new Error(message);
        }
    }

    public static isNotEmptyObservable(item: any, message: string): void {
        if (ko.isObservable(item) === false) {
            throw new Error(message);
        }
        Check.isNotEmpty(ko.utils.unwrapObservable(item), message);
    }

    /**
     * Checks if the value is null. 
     * @param {any} item
     * @param {string} message
     */
    public static isNotNull(item: any, message: string): void {
        if (_.isNull(item)) {
            throw new Error(message);
        }
    }

    public static isArray(obj: any, message: string): void {
        if (!_.isArray(obj)) {
            throw new Error(message);
        }
    }

    /**
     * Checks if the array is null or empty. 
     * @param {any[]} array
     * @param {string} message
     */
    public static isNonEmptyArray(array: any[], message: string): void {
        if (_.isNull(array)) {
            throw Error(message);
        }
        if (!_.isArray(array)) {
            throw new Error(message);
        }
        if (array.length <= 0) {
            throw new Error(message);
        }
    }

    /**
     * Does the array have any values.
     * @param {any[]} item
     * @param {string} message
     */
    public static hasSequence(item: any[], message: string): void {
        Check.isNotNull(item, message);
        if (item.length === 0) {
            throw new Error(message);
        }
    }
    
    /**
     * Throws a not implemented exception.
     */
    public static notImplemented(): void {
        throw new Error('Not implemented');
    }

    /**
     * Checks that a number is not null or negative
     * @param {number} value
     * @param {string} message?
     */
    public static notNegative(value: number, message?: string): void {
        const m = message || 'Value cannot be negative or null';
        Check.isNotEmpty(value, m);

        if (value < 1) {
            throw new Error(m);
        }
    }
    
    /**
     * Checks if a string is a URL
     * @param {string} url
     * @param {string} message?
     * @returns void
     */
    public static isUrl(url: string, message?: string): void {
        const m: string = message || 'URL is not valid';
        Check.isNotEmpty(url, m);

        if (!launchpad.utils.isValidUrl(url)) {
            throw new Error(m);
        }
    }

    /**
     * Checks if a string is a valid email address
     * @param {string} email
     * @param {string} message?
     * @returns void
     */
    public static isEmail(email: string, message?: string): void {
        const m: string = message || 'Email is not valid';
        Check.isNotEmpty(email, m);

        if (!launchpad.utils.validation.emailAddressIsValid(email)) {
            throw new Error(m);
        }
    }

    /**
     * Checks the format of a CSV
     * @param {Array<string>} expectedFormat
     * @param {PapaParse.ParseResult} csv
     */
    public static isCsvFormatValid(expectedFormat: string[], csv: PapaParse.ParseResult): void {
        const m = 'The CSV does not match the expected format!';
        const headerRow: any = _.object(expectedFormat, csv.data[0]);
        let headerMatchesFormat = true;
        let rowsMatchFormat = true;
        for (const format of expectedFormat) {
            if (!headerMatchesFormat) {
                break;
            }

            headerMatchesFormat = $.trim(headerRow[format]) === $.trim(format) &&
                typeof headerRow[format] !== 'undefined';
        }

        for (const record of csv.data) {
            if (!rowsMatchFormat) {
                break;
            }

            rowsMatchFormat = record.length === expectedFormat.length;
        }

        if (!headerMatchesFormat || !rowsMatchFormat) {
            throw new Error(m);
        }
    }

    /**
     * Checks the format of a CSV by keys
     * @param {Array<string>} expectedFormat
     * @param {PapaParse.ParseResult} csv
     */
    public static csvFormatByKeys(expectedFormat: string[], csv: PapaParse.ParseResult): void {
        const m = 'The CSV does not match the expected format!';
        let matchesFormat = true;
        const keys: string[] = Object.keys(csv.data[0]);

        if (expectedFormat.length !== keys.length) {
            matchesFormat = false;
        }

        if (matchesFormat) {
            for (let i = 0; i < expectedFormat.length; i++) {
                if (!matchesFormat) {
                    break;
                }
                matchesFormat = $.trim(keys[i]) === $.trim(expectedFormat[i]);
            }
        }

        if (!matchesFormat) {
            throw new Error(m);
        }
    }

    /**
     * Check is two objects are Equivalant. This is not recurive and should only be use for simple value objects. 
     * @param a
     * @param b
     */
    public static areEquivalent<T>(a: T, b: T): boolean {
        const aProperties = Object.getOwnPropertyNames(a);
        const bProperties = Object.getOwnPropertyNames(b);

        // We can immediately short-circuit if they don't have the same number of props. 
        if (aProperties.length !== bProperties.length) {
            return false;
        }

        // We need to loop through each prop and check it. 
        for (const prop of aProperties) {
            if ((a as any)[prop] !== (b as any)[prop]) {
                return false;
            }
        }

        return true;
    }
}

import { Component, OnInit } from '@angular/core';
import { TaskFeedbackService } from '../../../services/task-feedback.service';
import { TaskHistoryService } from '../../../services/task-history.service';
import { PriorityViewModel } from 'app/models/PriorityViewModel';
import { forkJoin } from 'rxjs';
import { SessionStorageService } from '../../../services/session-storage.service';
import { finalize } from 'rxjs/operators';
import { Dictionary } from 'underscore';

@Component({
  selector: 'app-components-specialist-viewtaskfeedback',
  templateUrl: './ViewTaskFeedback.component.html',
  styleUrls: ['./ViewTaskFeedback.component.scss']
})
export class ViewTaskFeedbackComponent implements OnInit {
  public isLoading = true;
  public filteredFeedback: Boo.Objects.TaskFeedback[] = [];
  public taskIdFilter = '';
  public noFeedback = 'No feedback provided.';

  private feedback: Boo.Objects.TaskFeedback[] = [];
  private taskTypes: Boo.Objects.TaskType[];

  private taskTypeControlStrings: Partial<Record<Boo.Objects.Enums.TaskTypeEnum, string>> = {
    [Boo.Objects.Enums.TaskTypeEnum.StandardBacklink]: 'app-components-taskfeedback-standardbacklink',
    [Boo.Objects.Enums.TaskTypeEnum.OnsiteRecommendationCreateContent]: 'app-components-onsite-recommendations-feedback'
  };

  constructor(
    private taskFeedbackService: TaskFeedbackService,
    private taskHistoryService: TaskHistoryService,
    private sessionStorageService: SessionStorageService) { }

  ngOnInit(): void {
    forkJoin([this.sessionStorageService.getStaticData(), this.taskFeedbackService.get()])
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(([staticData, feedback]) => {
        this.taskTypes = staticData.TaskTypes
        this.feedback = feedback;
        this.filterFeedback();
      });
  }

  public filterFeedback() {
    this.filteredFeedback = this.feedback.filter(x => !this.taskIdFilter || x.TaskId.toString().startsWith(this.taskIdFilter));
  }

  public displayTaskType(type: Boo.Objects.Enums.TaskTypeEnum): string {
    return this.taskTypes.find(x => x.TaskTypeEnum == type)?.Name ?? "N/A";
  }

  public show(taskHistoryId: number, taskType: Boo.Objects.Enums.TaskTypeEnum) {
    this.taskHistoryService
      .get(taskHistoryId)
      .subscribe(history => {
        const controlString = this.taskTypeControlStrings[taskType];
        if (controlString) {
          PriorityViewModel.show(controlString, { history: history});
        } else {
          toastr.error('Task type not supported.');
        }
      });
  }
}
import { NgModule } from '@angular/core';
import { QaGradingAreaComponent } from './qa-grading-area/qa-grading-area.component';
import { QaGradingAreaPanelComponent } from './qa-grading-area-panel/qa-grading-area-panel.component';
import { QaGradingAreaRowComponent } from './qa-grading-area-row/qa-grading-area-row.component';
import { QaCriteriaControlComponent } from './qa-criteria-control/qa-criteria-control.component';
import { QaCriteriaButtonGroupComponent } from './qa-criteria-button-group/qa-criteria-button-group.component';
import { QaCriteriaSelectComponent } from './qa-criteria-select/qa-criteria-select.component';
import { QaStockCommentSelectComponent } from './qa-stock-comment-select/qa-stock-comment-select.component';
import { SharedModule } from '../../shared/shared.module';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    QaGradingAreaComponent,
    QaGradingAreaPanelComponent,
    QaGradingAreaRowComponent,
    QaCriteriaControlComponent,
    QaCriteriaButtonGroupComponent,
    QaCriteriaSelectComponent,
    QaStockCommentSelectComponent,
  ],
  exports: [
    QaGradingAreaComponent,
    QaGradingAreaPanelComponent,
    QaGradingAreaRowComponent,
    QaCriteriaControlComponent,
    QaCriteriaButtonGroupComponent,
    QaCriteriaSelectComponent,
    QaStockCommentSelectComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class QaModule { }

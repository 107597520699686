import { Component } from '@angular/core';
import system from 'framework/System';
import check from 'framework/Check';
import { JobService } from '../../../services/job.service';
import { JobTypeService } from '../../../services/job-type.service';
import Utils from '../../../shared/utils';

class JobStatus {
    public Name: string;
    public JobStatusId: number;

    constructor(name: string, jobStatusId: number) {
        this.Name = name;
        this.JobStatusId = jobStatusId;
    }
}

class JobPriority {
    public Name: string;
    public JobPriorityId: number;

    constructor(name: string, jobPriorityId: number) {
        this.Name = name;
        this.JobPriorityId = jobPriorityId;
    }
}

export const enum JobUpdateMode {
    Delete = 1,
    Requeue = 2
}

@Component({
  selector: 'app-components-admin-updatejobs',
  templateUrl: './UpdateJobs.component.html'
})
export class UpdateJobsComponent implements IActivatable {
    public title = 'Delete Jobs';
    public startDate: KnockoutObservable<moment.Moment> = ko.observable(moment().add(-1, 'd'));
    public endDate: KnockoutObservable<moment.Moment> = ko.observable(moment().add(1, 'd'));
    public selectedJobTypeId: KnockoutObservable<number> = ko.observable(undefined);
    public selectedJobStatusId: KnockoutObservable<number> = ko.observable(undefined);
    public selectedJobPriorityId: KnockoutObservable<number> = ko.observable(undefined);
    public jobTypes: KnockoutObservableArray<Boo.Objects.Jobs.Enums.JobTypes> = ko.observableArray([]);
    public jobStatuses: JobStatus[] = [];
    public jobPriorities: JobPriority[] = [
        new JobPriority('Default', Boo.Objects.Jobs.Enums.JobPriorities.DefaultInternalUse),
        new JobPriority('High', Boo.Objects.Jobs.Enums.JobPriorities.High),
        new JobPriority('Low', Boo.Objects.Jobs.Enums.JobPriorities.Low),
        new JobPriority('Monitor', Boo.Objects.Jobs.Enums.JobPriorities.Monitor),
        new JobPriority('Normal', Boo.Objects.Jobs.Enums.JobPriorities.Normal),
        new JobPriority('Single Threaded', Boo.Objects.Jobs.Enums.JobPriorities.SingleThreaded),
        new JobPriority('Long Run', Boo.Objects.Jobs.Enums.JobPriorities.LongRun),
        new JobPriority('Static Sites', Boo.Objects.Jobs.Enums.JobPriorities.StaticSites),
        new JobPriority('Store', Boo.Objects.Jobs.Enums.JobPriorities.Store),
        new JobPriority('Local Listings', Boo.Objects.Jobs.Enums.JobPriorities.LocalListings)
    ];
    public isLoading: KnockoutObservable<boolean> = ko.observable(false);
    public currentMode: KnockoutObservable<JobUpdateMode> = ko.observable(JobUpdateMode.Delete);
    public jobCount: KnockoutObservable<number> = ko.observable(undefined);
    protected priorityViewApi: app.interfaces.IPriorityViewPublicApi;
    public persistButtonStyle: KnockoutComputed<string> = ko.computed(() => {
        return this.currentMode() === JobUpdateMode.Delete ? 'btn-danger' : 'btn-primary';
    });
    public persistButtonIcon: KnockoutComputed<string> = ko.computed(() => {
        return this.currentMode() === JobUpdateMode.Delete ? 'fa-times' : 'fa-sync';
    });

    public alertStyle: KnockoutComputed<string> = ko.computed(() => {
        return this.currentMode() === JobUpdateMode.Delete ? 'alert-danger' : 'alert-info';
    });

    public requeueJob(job: Boo.Objects.Jobs.Job): void {
      const msg = 'Are you sure you would like to requeue the selected job?';
      bootbox.confirm(msg, (result: boolean) => {
        if (result === true) {
          Utils.wrapDfd(this.jobService.requeue(job.HangfireJobId, job.JobId))
            .then((() => {
              toastr.success('Job successfully requeued');
            }).bind(this))
            .fail((displayMessage: string): void => {
              toastr.error(displayMessage);
            });
        }
      });
    }

    public deleteJob(job: Boo.Objects.Jobs.Job): void {
      const msg = 'Are you sure you would like to delete the selected job?';
      bootbox.confirm(msg, (result: boolean) => {
        if (result === true) {
          Utils.wrapDfd(this.jobService.delete(job.JobId))
            .then((() => {
              toastr.success('Job successfully deleted');
            }).bind(this))
            .fail((displayMessage: string): void => {
              toastr.error(displayMessage);
            });
        }
      });
    }

    public handleGetJobTypes(jobTypes: Boo.Objects.Jobs.Enums.JobTypes[]): JQueryPromise<any> {
        this.jobTypes(jobTypes);
        return system.emptyPromise();
    }

    constructor(
      private jobService: JobService,
      private jobTypeService: JobTypeService) { }

    public activate(params: any): JQueryPromise<any> {
        check.isNotEmpty(params.priorityViewApi, 'PriorityViewApi cannot be null');

        this.currentMode(params.deleteMode === true ? JobUpdateMode.Delete : JobUpdateMode.Requeue);

        if (this.currentMode() === JobUpdateMode.Delete) {
            this.title = 'Delete Jobs'; 
            this.jobStatuses.push(new JobStatus('Failed', Boo.Objects.Jobs.Enums.JobStatuses.Failed));
            this.jobStatuses.push(new JobStatus('Pending', Boo.Objects.Jobs.Enums.JobStatuses.Pending));
            this.jobStatuses.push(new JobStatus('Scheduled', Boo.Objects.Jobs.Enums.JobStatuses.Scheduled));
          } else if (this.currentMode() === JobUpdateMode.Requeue) {
            this.title = 'Requeue Jobs';
            this.jobStatuses.push(new JobStatus('Completed', Boo.Objects.Jobs.Enums.JobStatuses.Completed));
            this.jobStatuses.push(new JobStatus('Deleted', Boo.Objects.Jobs.Enums.JobStatuses.Deleted));
            this.jobStatuses.push(new JobStatus('Failed', Boo.Objects.Jobs.Enums.JobStatuses.Failed));
        }

        // default selected status to "failed"
        this.selectedJobStatusId(Boo.Objects.Jobs.Enums.JobStatuses.Failed);

        // default selected queue to "normal"
        this.selectedJobPriorityId(Boo.Objects.Jobs.Enums.JobPriorities.Normal);

        this.priorityViewApi = params.priorityViewApi;

        return Utils.wrapDfd(this.jobTypeService.getAll())
            .then(this.handleGetJobTypes.bind(this))
            .then((() => {
                this.selectedJobTypeId(ko.utils.unwrapObservable(params.jobTypeId));
            }).bind(this))
            .fail((displayMessage: string): void => {
                toastr.error(displayMessage);
            });
    }

    public closeView(): void {
        this.priorityViewApi.reject();
    }

    public updateJobs(): void {
        bootbox.confirm('The operation you are performing cannot be undone. Would you like to continue?', (result: boolean) => {
            if (result) {
                if (this.currentMode() === JobUpdateMode.Delete) {
                  Utils.wrapDfd(this.jobService.deleteMany(this.selectedJobTypeId(), this.selectedJobStatusId(), this.selectedJobPriorityId(), this.startDate(), this.endDate()))
                    .then((count: number) => {
                        toastr.success(count + ' jobs have been deleted');
                        this.jobCount(undefined);
                    });
                } else if (this.currentMode() === JobUpdateMode.Requeue) {
                  Utils.wrapDfd(this.jobService.requeueMany(this.selectedJobTypeId(), this.selectedJobStatusId(), this.selectedJobPriorityId(), this.startDate(), this.endDate()))
                    .then((count: number) => {
                        toastr.success(count + ' jobs have been requeued');
                        this.jobCount(undefined);
                    });
                }
            }
        });
    }

    public getJobCount(): void {
      Utils.wrapDfd(this.jobService.getCount(this.selectedJobTypeId(), this.selectedJobStatusId(), this.selectedJobPriorityId(), this.startDate(), this.endDate()))
        .then((count: number) => {
            this.jobCount(count);
        });
    }
}

import { Component } from '@angular/core';
import Check from 'framework/Check';
import { WordpressWebsiteService } from '../../../services/wordpresswebsite.service';
import { CustomerService } from '../../../services/customer.service';
import { WebsiteService } from '../../../services/website.service';
import Utils from '../../../shared/utils';
import ProductEnum = Boo.Objects.Enums.ProductEnum;
import { TabPanelDisplayMode } from 'app/components/enums/TabPanelDisplayMode';

@Component({
  selector: 'app-components-websites-website',
  templateUrl: './Website.component.html'
})
export class WebsiteComponent implements IActivatable {
    public website: app.tsmodels.interfaces.IWebsiteObservable;
    public componentParams: app.components.websites.interfaces.IWebsiteComponentActivateParams;
    public hasHadAnyWebsiteProduct = false;
    public areBoostSiteTabsVisible = false;
    public isAdjustmentTabVisible = false;
    public isCustomWebsiteReportingVisible = false;
    public customer: Boo.Objects.Customer;
    public tabPanelDisplayMode = TabPanelDisplayMode;

    constructor(
      private customerService: CustomerService,
      private websiteService: WebsiteService,
      private wordpressWebsiteService: WordpressWebsiteService) { }

    public activate(params: IWebsiteViewModelActivationParams): JQueryPromise<void> {
        Check.isNotEmpty(params.website, 'Website cannot be empty');
        this.website = ko.mapping.fromJS(params.website);
        this.customer = ko.mapping.toJS(params.customer);
/* TODO: Remove  params.isAdjustmentTabVisible */
        return $.when(Utils.wrapDfd(this.websiteService.isBuildComplete(params.customer.CustomerId())), Utils.wrapDfd(this.wordpressWebsiteService.isBuildComplete(params.customer.CustomerId())))
            .then((boostSiteBuildComplete, wordpressBuildComplete) => {
                this.componentParams = {
                    customerId: params.customer.CustomerId(),
                    website: this.website,
                    isReadOnly: params.isReadOnly
                };
                const hasCustomWebsiteMaintenance = (this.customerService.hasEverHadProduct(params.customer, [ProductEnum.CustomWebsite])
                    && !this.customerService.hasEverHadProduct(params.customer, [ProductEnum.WebsiteMaintenancePlan]))
                    || this.customerService.hasHadProductRecently(params.customer, ProductEnum.WebsiteMaintenancePlan);
                this.isAdjustmentTabVisible =
                    ((this.customerService.hasEverHadProduct(params.customer, [ProductEnum.BoostSite])
                    || this.customerService.hasEverHadProduct(params.customer, [ProductEnum.BoostSiteMonthlySupport])))
                    || hasCustomWebsiteMaintenance;
                this.areBoostSiteTabsVisible = this.customerService.hasEverHadProduct(params.customer, [ProductEnum.BoostSiteMonthlySupport, Boo.Objects.Enums.ProductEnum.BoostSite]);
                this.isCustomWebsiteReportingVisible = this.customerService.hasEverHadProduct(params.customer, [ProductEnum.CustomWebsite])
                || hasCustomWebsiteMaintenance;

                this.hasHadAnyWebsiteProduct = this.areBoostSiteTabsVisible || this.isAdjustmentTabVisible || this.isCustomWebsiteReportingVisible;
            });
    }
}

interface IWebsiteViewModelActivationParams {
    website: Boo.Objects.Websites.Website;
    customer: CustomerObservable;
    isReadOnly: boolean;
    isAdjustmentTabVisible: boolean;
}

interface IWordpressTabActivateParams {
    customer: Boo.Objects.Customer;
}

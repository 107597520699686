import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { map, switchMap } from 'rxjs/operators';
import { SessionStorageService } from './session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class BusinessCitationService {
  private baseUrl = `${environment.urlConfig.serviceUrl}/businesscitations`;

  constructor(private http: HttpClient,
    private sessionStorageService: SessionStorageService) {}

  getDefaultBusinessCitationProvider(customerId: number): Observable<Boo.BusinessCitations.Models.Enums.Providers> {
    const params = new HttpParams().set('customerId', customerId);

    return this.http.get<Boo.BusinessCitations.Models.Enums.Providers>(`${this.baseUrl}/GetDefaultBusinessCitationProvider`, { params: params });
  }

  modifySubscriptions(subscriptionModifications: Boo.BusinessCitations.Models.SubscriptionModification[]): Observable<any> {
    return this.http.post(`${this.baseUrl}/ModifySubscriptions`, subscriptionModifications);
  }

  generateBusinessCitationProviderSSOUrl(localProfileId: number): Observable<string> {
    const params = new HttpParams().set('localProfileId', localProfileId);

    return this.http.get<string>(`${this.baseUrl}/GenerateBusinessCitationProviderSSOUrl`, { params: params });
  }

  getBusinessCitationSubscriptionSummary(customerId: number, manageableTaskTypes: Boo.Objects.Enums.TaskTypeEnum[], getValidationAndAugmentationStatus: boolean): Observable<Boo.BusinessCitations.Models.BusinessCitationSubscriptionSummary> {
    return this.http.post<Boo.BusinessCitations.Models.BusinessCitationSubscriptionSummary>(`${this.baseUrl}/GetBusinessCitationSubscriptionSummary`, {
        customerId: customerId,
        manageableTaskTypes: manageableTaskTypes,
        getValidationAndAugmentationStatus: getValidationAndAugmentationStatus
    });
  }

  getUnresolvedLocalProfileIssues(localProfileId: number): Observable<Boo.BusinessCitations.Models.LocalProfileIssue[]> {
    const params = new HttpParams().set('localProfileId', localProfileId);

    return this.http.get<Boo.BusinessCitations.Models.LocalProfileIssue[]>(`${this.baseUrl}/UnresolvedLocalProfileIssues`, { params: params });
  }

  getBusinessCitationProviderCategories(businessCitationProviderId: Boo.BusinessCitations.Models.Enums.Providers): Observable<Boo.BusinessCitations.Models.BusinessCitationProviderCategory[]> {
    return this.sessionStorageService.getBusinessCitationProviderCategories()
      .pipe(
        switchMap(categories => categories && categories.some(x => x.BusinessCitationProviderId === businessCitationProviderId)
        ? of(categories)
        : this.fetchCategories(businessCitationProviderId))
      );
  }

  getBusinessCitationProviderProfileData(businessCitationProviderId: Boo.BusinessCitations.Models.Enums.Providers): Observable<Boo.BusinessCitations.Models.BusinessCitationProviderProfileData> {
    return this.sessionStorageService.getBusinessCitationProviderProfileData()
      .pipe(
        switchMap(profileData => profileData && profileData.BusinessCitationProviderId === businessCitationProviderId
        ? of(profileData)
        : this.fetchProfileData(businessCitationProviderId))
      );
  }

  orderValidationAndAugmentation(customerId: number, localProfileId: number): Observable<any> {
    return this.http.post(`${this.baseUrl}/OrderValidationAndAugmentation`, {
        customerId: customerId,
        localProfileId: localProfileId
    });
  }

  private fetchCategories(businessCitationProviderId: Boo.BusinessCitations.Models.Enums.Providers): Observable<Boo.BusinessCitations.Models.BusinessCitationProviderCategory[]> {
    const params = new HttpParams().set('businessCitationProviderId', businessCitationProviderId);
    
    return this.http.get<Boo.BusinessCitations.Models.BusinessCitationProviderCategory[]>(`${this.baseUrl}/GetCategoriesFor`, { params: params })
      .pipe(
          map(categories => {
            this.sessionStorageService.setBusinessCitationProviderCategories(categories);
            return categories;
          })
      );
  }

  private fetchProfileData(businessCitationProviderId: Boo.BusinessCitations.Models.Enums.Providers): Observable<Boo.BusinessCitations.Models.BusinessCitationProviderProfileData> {
    const params = new HttpParams().set('businessCitationProviderId', businessCitationProviderId);

    return this.http.get<Boo.BusinessCitations.Models.BusinessCitationProviderProfileData>(`${this.baseUrl}/GetProfileDataFor`, { params: params })
      .pipe(
        map(profileData => {
          this.sessionStorageService.setBusinessCitationProviderProfileData(profileData);
          return profileData;
        })
      );
  }
}

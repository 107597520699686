import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { SessionStorageService } from '../services/session-storage.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {
  unauthorizedCounter = 0;
  constructor(
    private sessionStorageService: SessionStorageService,
    private router: Router
    ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isLaunchpadUrl = req.url.includes(environment.urlConfig.serviceUrl);
    let newHeaders = req.headers;
    let authReq = req;

    if (isLaunchpadUrl) {
      const bearerToken = this.sessionStorageService.getBearerTokenValue();
      if (bearerToken) {
        this.sessionStorageService.handleBearerTokenExpiration();
        newHeaders = newHeaders.append('authorization', `Bearer ${bearerToken}`);
        newHeaders = newHeaders.append('PartnerUserAccessToken', this.sessionStorageService.getAccessTokenValue());
      }

      authReq = req.clone({ headers: newHeaders });
    }

    return next.handle(authReq).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          if (this.unauthorizedCounter > 0) {
            return of(null);
          }

          this.unauthorizedCounter++;
          this.sessionStorageService.clear();
          this.sessionStorageService.logout();
          this.router.navigateByUrl('/login');

          return throwError('Please login again.');
        }
        return throwError(error);
      }),
      map(resp => {
        if (resp instanceof HttpResponse) {
          // service always returns a 200 response so we need to filter out any errors here
          if (resp.body.BooError) {
            // we throw here in order for rxjs to be able to 'catchError'
            throw resp.body.BooError.DisplayMessage;
          }

          // if its lp then return Data, otherwise return the traditional response body
          return isLaunchpadUrl ? resp.clone({ body: this.convertStringDatesToDate(resp.body.Data) }) : resp;
        }

        return resp;
      })
    );
  }

  convertStringDatesToDate(data: any) {
    const fixObjectFunc = (obj: any) => {
      for (const propName in obj) {
        const propertyValue = obj[propName];
        if (_.isNull(propertyValue) === false && _.isUndefined(propertyValue) === false && _.isString(propertyValue)) {
          const propMoment = moment(propertyValue, 'YYYY-MM-DDTHH:mm:ssZ', true);
          if (propMoment && propMoment.isValid() === true) {
            obj[propName] = propMoment.toDate();
          }
        } else if (typeof (propertyValue) === 'object') {
          fixObjectFunc(propertyValue);
        }
      }
    };

    fixObjectFunc(data);

    return data;
  }
}
